import React, { useEffect, useState } from "react";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconTrashRed } from "assets/images/svgs";
import TableHeaderSupport from "./components/TableHeaderSupport";
import Table from "layout/table/Table";
import DeleteModal from "shared/DeleteModal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { allDisputeTickets } from "appstate/helpandsupport/helpandsupportSlice";
import { useNavigate } from "react-router-dom";

const Support = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(allDisputeTickets());
  }, [dispatch]);

  const [modalOpen, setModalOpen] = useState(false);
  const { allDisputeTicketsData } = useSelector(
    (state) => state?.helpAndSupport
  );

  console.log(allDisputeTicketsData);
  const column = [
    {
      name: "TICKET NO",
      selector: (row) => row?.ticketNo,
      width: "8rem",
    },
    {
      name: "FROM",
      selector: (row) => row?.creatorName,
      width: "8rem",
    },
    {
      name: "CONVERSATION",
      selector: (row) => <span className=" font-bold">RE: {row?.subject}</span>,
      width: "20rem",
    },
    {
      name: "DATE",
      selector: (row) => row?.createdDate,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            row.status === "Open" ? "text-primaryColor" : "text-secondaryColor"
          }
        >
          {row.status}
        </p>
      ),
    },
    {
      name: <span className="pl-10">Action</span>,
      selector: (row) => row?.delete,
      cell: (row) => (
        <div className="flex pl-10 items-center">
          <PAIconTrashRed onClick={() => setModalOpen(true)} />
        </div>
      ),
    },
  ];

  const options = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Active",
    },
    {
      id: 3,
      name: "Unavailable",
    },
    {
      id: 4,
      name: "Deactivated",
    },
  ];

  let path = "createcomplaint";
  // let viewMorePath = "usercommunication/1";

  // const data = [
  //   {
  //     id: 1,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <Link to="usercommunication/1">
  //           <p className="text-[14px] font-[600]">
  //             RE: Invoice Payment incomplete
  //           </p>
  //         </Link>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     status: "Open",
  //     delete: <PAIconTrashRed />,
  //   },
  //   {
  //     id: 2,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     status: "Close",
  //     delete: <PAIconTrashRed />,
  //   },
  //   {
  //     id: 3,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     status: "Close",
  //     delete: <PAIconTrashRed />,
  //   },
  //   {
  //     id: 4,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     status: "Close",
  //   },
  //   {
  //     id: 5,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     status: "Open",
  //   },
  //   {
  //     id: 6,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     status: "Open",
  //   },
  //   {
  //     id: 7,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     status: "Open",
  //   },
  //   {
  //     id: 8,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     status: "Close",
  //   },

  //   {
  //     id: 3,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     delete: <PAIconTrashRed />,
  //   },
  //   {
  //     id: 4,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     delete: <PAIconTrashRed />,
  //   },
  //   {
  //     id: 5,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     delete: <PAIconTrashRed />,
  //   },
  //   {
  //     id: 6,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     delete: <PAIconTrashRed />,
  //   },
  //   {
  //     id: 7,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     delete: <PAIconTrashRed />,
  //   },
  //   {
  //     id: 8,
  //     from: "Support Team",
  //     conversation: (
  //       <div>
  //         <p className="text-[14px] font-[600]">
  //           RE: Invioce Payment incomplete{" "}
  //         </p>
  //         <p className="font-[400]">
  //           Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
  //           officia deserunt mollit anim id es
  //         </p>
  //       </div>
  //     ),
  //     date: "2023-05-23",
  //     delete: <PAIconTrashRed />,
  //   },
  // ];

  return (
    <DashboardLayout section="Support" subHeader="Having problems - help">
      <DeleteModal
        isShown={modalOpen}
        onClose={() => setModalOpen(false)}
        supportCancel
        warning="This product will be deleted from PayEdge and this action cannot be undone."
      />

      <div className="bg-white p-[24px] rounded-[5px]">
        <TableHeaderSupport
          options={options}
          support={true}
          buyer={true}
          path={path}
        />
        <Table
          columns={column}
          data={allDisputeTicketsData?.data}
          selectableRows
          onRowClicked={(row) => navigate(`usercommunication/${row?.id}`)}
          pointer
          tableHeader
          className="mt-5"
          pagination
        />
      </div>
    </DashboardLayout>
  );
};

export default Support;
