import { PAIconQuillSend1, PAIconSearch } from "assets/images/svgs";
import React, { useState } from "react";
import Tab from "./Tabs/Tabs";

const RecipientPartyModal = ({ isOpen, onClose, onEmailClick, setGetName }) => {
  const [recipientName, setRecipientName] = useState("");

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg">
      <div className="bg-white p-5 px-5 rounded-md max-w-xs md:max-w-sm w-full">
        <div className="flex justify-between items-center mb4">
          <div className="text-black text-md font-bold font-['SF Pro Display'] mt-2 mb-5">
            Choose the recipient for the agreement{" "}
          </div>
          <button
            onClick={onClose}
            className="text-black font-sans text-2xl cursor-pointer flex justify-center items-center mt-[-1rem]"
          >
            &times;
          </button>
        </div>
        <div className="border border-gray"></div>
        <div className="flex justify-between">
          <div className="flex items-center mt-4">
            <PAIconSearch className="ml-2 w-5 h-5" />
            <input
              type="text"
              placeholder="Search for recipient"
              className="outline-none px-3 py-1 rounded-md"
            />
          </div>
          <div className="flex items-center mt-4">
            <PAIconQuillSend1 className="cursor-pointer" />
          </div>
        </div>
        {/* tabs */}
        <div className="mt-5">
          <Tab
            onEmailClick={onEmailClick}
            setGetName={setGetName}
            recipientName={recipientName}
            setRecipientName={setRecipientName}
          />
        </div>
      </div>
    </div>
  );
};

export default RecipientPartyModal;
