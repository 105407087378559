import { PAIconMDISuccess } from "assets/images/svgs";
import React, { useEffect } from "react";

const SuccessModal = ({ isOpen, onClose, message }) => {
  useEffect(() => {
    let timeoutId;

    if (isOpen) {
      timeoutId = setTimeout(() => {
        onClose();
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="flex">
        <div className="bg-[#07593D] p-10 shadow-md rounded-s text-center text-white">
          <PAIconMDISuccess />
        </div>

        <div className="bg-white py-10 px-3 flex flex-col items-center justify-center shadow-md text-center rounded-e">
          {/* <PAIconDelete2 /> */}
          {/* <p className="text-[#07593D]">Congratulations!!!</p> */}
          <p className="text-[#959595] text-center text-xs font-normal leading-normal tracking-[0.321px] mb-3 mt-3">
            {message}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
