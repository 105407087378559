import React, { useState } from "react";

import DirectFunding from "./directFunding";

const ReinbursementDashboardContent = () => {
  const [activeTab, setActiveTab] = useState("myinvoices");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "myinvoices":
        return <DirectFunding />;

      default:
        return null;
    }
  };

  return (
    <div>
      <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white min-h-[40px] items-center py-4">
        <div className="gap1 flex-col items-center justify-between gap-10  px-4 md:flex md:flex-row">
          <div className="flex-col gap-10 md:flex md:flex-row">
            <div
              className={
                activeTab === "myinvoices"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4  py-3"
                  : "text-gray500 text-base font-normal  py-6"
              }
              onClick={() => handleTabClick("myinvoices")}
            >
              Funder
            </div>
          </div>
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default ReinbursementDashboardContent;
