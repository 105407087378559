import React, { useState, useEffect } from "react";
import { PAIconInfo } from "assets/images/svgs";
import Loading from "shared/Loading";
import AcceptModal from "shared/AcceptModal";

import TransferInfoModal from "./DisbursementTranferInfoModal";
import TransferInfoPinModal from "./DisbursementTransferInfoPinModal";
import SelectPaymentModal from "./SelectPaymentModal";
import SelectedPaymentModal from "./SelectedPaymentModal";

const InvoiceDisbursementModal = ({ isOpen }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isTransferInfoModalOpen, setIsTransferInfoModalOpen] = useState(false);
  const [isTransferInfoPinOpen, setIsTransferInfoPinOpen] = useState(false);
  const [selectPaymentModal, setSelectPaymentModal] = useState(false);
  const [selectedPaymentModal, setSelectedPaymentModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const dummyData = [
        {
          funderId: 1,
          buyerCompanyName: "Company A",
          invoiceReference: "INV-001",
          invoiceDate: "2024-05-24",
          dueDate: "2024-05-24",
          invoiceAmount: 1000.0,
        },
        {
          funderId: 2,
          buyerCompanyName: "Company B",
          invoiceReference: "INV-002",
          invoiceDate: "2024-05-25",
          dueDate: "2024-05-24",
          invoiceAmount: 1500.0,
        },
        {
          funderId: 3,
          buyerCompanyName: "Company B",
          invoiceReference: "INV-002",
          invoiceDate: "2024-05-25",
          dueDate: "2024-05-24",
          invoiceAmount: 1500.0,
        },
        {
          funderId: 4,
          buyerCompanyName: "Company B",
          invoiceReference: "INV-002",
          invoiceDate: "2024-05-25",
          dueDate: "2024-05-24",
          invoiceAmount: 1500.0,
        },
      ];
      setData(dummyData);
      setIsLoading(false);
    }, 1000);
  }, []);

  const handlePayment = () => {
    setIsAcceptModalOpen(true);
  };
  const handleAcceptConfirm = () => {
    setIsAcceptModalOpen(false);
    setSelectPaymentModal(true);
  };
  const handleSelectConfirm = () => {
    setSelectPaymentModal(false);
    setSelectedPaymentModal(true);
  };
  const handleSelectedConfirm = () => {
    setSelectedPaymentModal(false);
    setIsTransferInfoModalOpen(true);
  };

  if (!isOpen) return null;

  return (
    <>
      {isAcceptModalOpen && (
        <AcceptModal
          isOpen={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          message={
            <>
              Are you sure you would like to make Payment for this selected
              invoices?
            </>
          }
          onConfirm={handleAcceptConfirm}
        />
      )}
      {isTransferInfoModalOpen && (
        <TransferInfoModal
          isOpen={isTransferInfoModalOpen}
          onClose={() => setIsTransferInfoModalOpen(false)}
          onConfirm={() => {
            setIsTransferInfoModalOpen(false);
            setIsTransferInfoPinOpen(true);
          }}
        />
      )}
      {isTransferInfoPinOpen && (
        <TransferInfoPinModal
          isOpen={isTransferInfoPinOpen}
          onClose={() => setIsTransferInfoPinOpen(false)}
        />
      )}
      {selectPaymentModal && (
        <SelectPaymentModal
          isOpen={selectPaymentModal}
          onClose={() => setSelectPaymentModal(false)}
          message={<>Please select payment method!</>}
          onConfirm={handleSelectConfirm}
        />
      )}

      {selectedPaymentModal && (
        <SelectedPaymentModal
          isOpen={selectedPaymentModal}
          onClose={() => setSelectedPaymentModal(false)}
          message={<>Select your preferred third party funding method </>}
          title={"Third party Funding"}
          onConfirm={handleSelectedConfirm}
        />
      )}
      {isOpen &&
        !isAcceptModalOpen &&
        !isTransferInfoModalOpen &&
        !isTransferInfoPinOpen &&
        !selectPaymentModal &&
        !selectedPaymentModal && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
            <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center max-w-[600px] w-[500px]">
              {/* Header */}
              <header className="flex justify-between  w-full">
                <h1 className="text-textColor text-base">
                  Invoice Disbursement
                </h1>
              </header>

              {/* Sub Header */}
              <div className="flex justify-between items-center w-full mt-3">
                <div className="text-textColor text-base font-extrabold">
                  Bulk invoice payment <br />
                  <span className="text-sm text-[#7A7A7A]">
                    78 invoices | Agba dev, 6 more...
                  </span>
                </div>
                <div
                  className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px] w-32 text-center"
                  onClick={handlePayment}
                >
                  Make Payment
                </div>
              </div>

              {/* Payment Amount */}
              <div className="flex justify-between items-start w-full mt-3">
                <div>
                  <h1 className="text-textColor text-base font-normal">
                    Payment Amount
                  </h1>
                  <h1 className="text-[#07593D] font-medium text-xl">
                    NGN 1,890,400
                  </h1>
                </div>
                <div className="flex flex-col items-end mt-4">
                  <div>
                    <p className="text-textColor text-xs">
                      Total Invoice Amount:{" "}
                      <span className="text-primaryColor text-xs font-extrabold border-b border-[#AFAFAF] pb-[2px]">
                        1000.00
                      </span>
                    </p>
                  </div>
                  <div className="flex flex-col items-end">
                    <p className="text-textColor text-xs font-medium border-b border-[#AFAFAF] w-[84px] mt-2">
                      Discount:{" "}
                      <span className="text-[#17191C] text-xs">0.05</span>
                    </p>
                    <div className="flex items-center mt-[2px]">
                      <PAIconInfo />
                      <p className="text-[#F08000] text-[10px] font-medium">
                        Tap to view more
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Supplier/Buyer */}
              <div className="w-full mt-3">
                <header className="border-b-[5px] border-[#F6F5FA] pb-2">
                  <h1 className="text-[18px] font-bold">
                    Buyer Bulk Invoice details
                  </h1>
                </header>
                <div className="flex  justify-between my-3">
                  <div className="flex gap-2">
                    <div className="flex flex-col">
                      <span className="text-[#979797] h-5 my-1 text-neutral-800 text-sm font-thin">
                        Supplier
                      </span>
                      <span className="text-[#7A7A7A] text-xs">
                        <ul className="list-none">
                          <li>ABC Cooperation</li>
                          <li>Dangote Inc</li>
                          <li>Nestle Inc</li>
                          <li>Total Oets</li>
                          <li>Giga Tech, 6 more ...</li>
                        </ul>
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="flex flex-col">
                      <span className="text-[#979797] h-5 my-1 text-neutral-800 text-sm font-thin">
                        Buyer
                      </span>
                      <span className="text-[#7A7A7A] text-xs">
                        Big Buyer Coop
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Content */}
              <div className="flex flex-col items-center w-full">
                <div className="w-full">
                  {isLoading ? (
                    <div className="flex items-center justify-center w-full h-[200px]">
                      <Loading />
                    </div>
                  ) : (
                    <div className="w-full overflow-x-auto">
                      <table className="w-full overflow-x-auto">
                        <thead className="bg-subtleGray">
                          <tr className="bg-unusualGray p2.5">
                            <td className="text-[#11192A] p2.5 text-xs font-bold">
                              <img
                                className=" pr1 inline"
                                src="/group-icon.svg"
                                alt=""
                              />
                              Invoice Reference
                            </td>
                            <td className="p2.5 text-xs font-bold">
                              <img
                                className=" pr1 inline"
                                src="/group-icon.svg"
                                alt=""
                              />
                              Buyer
                            </td>
                            <td className="p2.5 text-xs font-bold">
                              Invoice Date
                            </td>
                            <td className="p2.5 text-xs font-bold">Due Date</td>
                            <td className="p2.5 text-xs font-bold">
                              Invoice Amount
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, index) => (
                            <tr
                              key={index}
                              className="py-[3px] px1.5 border-b-[1.5px] border-[#F6F5FA]"
                            >
                              <td className="text-xs text-[#11192A] py-2.5">
                                {item.invoiceReference}
                              </td>
                              <td className="text-xs text-[#11192A] py-2.5">
                                {item.buyerCompanyName}
                              </td>
                              <td className="text-xs text-[#11192A] py-2.5">
                                {item.invoiceDate}
                              </td>
                              <td className="text-xs text-[#11192A] py-2.5">
                                {item?.dueDate}
                              </td>
                              <td className="text-xs text-[#11192A] py-2.5">
                                {item?.invoiceAmount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default InvoiceDisbursementModal;
