import React, { useState } from "react";
import walletBlack from "../../../../assets/images/svgs/walletBlack.svg";
import walletGreen from "../../../../assets/images/svgs/walletGreen.svg";

const SelectAccountModal = ({
  isOpen,
  onClose,
  confirm,
  isLoading,
  setSelectedAccount,
}) => {
  const [checkedAccounts, setCheckedAccounts] = useState({});

  const handleCheckboxChange = (accountIndex) => {
    const updatedCheckedAccounts = {};
    updatedCheckedAccounts[accountIndex] = true;
    setCheckedAccounts(updatedCheckedAccounts);
    setSelectedAccount(accounts[accountIndex]);
  };

  if (!isOpen) {
    return null;
  }

  const accounts = [
    {
      index: 1,
      accountName: "Praise Akobundu",
      accountNumber: "00123456789",
      accountType: "Virtual Account",
      bankName: "Payedge MFB",
    },
    {
      index: 2,
      accountName: "Praise Akobundu",
      accountNumber: "00123456789",
      accountType: "Virtual Account",
      bankName: "Payedge MFB",
    },
    {
      index: 3,
      accountName: "Praise Akobundu",
      accountNumber: "00123456789",
      accountType: "Virtual Account",
      bankName: "Payedge MFB",
    },
    {
      index: 4,
      accountName: "Praise Akobundu",
      accountNumber: "00123456789",
      accountType: "Virtual Account",
      bankName: "Payedge MFB",
    },
  ];

  return (
    <div
      //   onClick={handleCloseConfirm}
      className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0"
    >
      <div>
        <div className="flex flex-col justify-between items-center bg-white rounded-[8px]  w-[600px]">
          <div className="h-[60px] px-[5%] rounded-t-[8px] bg-[#FAFAFA] flex items-center w-[100%] justify-start">
            <h1 className="font-bold text-[20px]">Virtual Accounts</h1>
          </div>

          <div className="flex items-center w-[100%] px-[5%] mt-[2%]">
            <p className="text-left text-[16px] font-medium">
              Select a virtual account you would like to use to fund this Early
              Payment Request
            </p>
          </div>

          <div className="mt-[3%]">
            {accounts.map((account, index) => (
              <div key={index} className="flex items-start w-[100%] mb-[3%]">
                <input
                  checked={checkedAccounts[index]}
                  onChange={() => handleCheckboxChange(index)}
                  style={{ accentColor: "#F08000" }}
                  type="checkbox"
                />
                <div
                  className={`border-[1px] p-[4%] w-[270px] h-[100px] rounded-[5px] ml-[2%]`}
                  style={
                    checkedAccounts[index]
                      ? { backgroundColor: "#F1F9F6", color: "#076344" }
                      : { backgroundColor: "white", color: "black" }
                  }
                >
                  <div className="flex items-center justify-start">
                    {checkedAccounts[index] ? (
                      <img src={walletGreen} alt="" />
                    ) : (
                      <img src={walletBlack} alt="" />
                    )}

                    <p className="ml-[2%] font-semibold text-[14px]">
                      {account.accountName}
                    </p>
                  </div>

                  <div className="mt-[2%]">
                    <p className="font-bold text-[16px] italic">
                      {account.accountNumber}
                    </p>
                  </div>

                  <div className="flex items-center justify-between mt-[2%]">
                    <p className="text-[14px] font-light">{account.bankName}</p>
                    <p className="text-[13px]">{account.accountType}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="p-[5%] w-[100%]">
            <div className="mt-[3%] flex items-center justify-between w-[100%]">
              <button
                onClick={onClose}
                className="border-[#EE0816] text-[#EE0816] border-[1px] w-[120px] rounded-[4px] h-[45px]"
              >
                Cancel
              </button>
              <button
                onClick={confirm}
                className="bg-[#F08000] text-[#fff] w-[120px] rounded-[4px] h-[45px]"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectAccountModal;
