import React from 'react'
import Headers from '../headers'
import FundingOffersTable from './Table'

const FundingOffers = () => {
  return (
    <div>
    <Headers />
    <FundingOffersTable />
  </div>
  )
}

export default FundingOffers