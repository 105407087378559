import Modal from 'layout/modal/Modal';
import React from 'react';
import Delete from '../Delete';

function DeleteModal({
  isShown,
  onClose,
  handleDelete,
  warning,
  isLoading,
  status,
  options,
  handleChange,
  changeStatus
}) {
  return (
    <Modal
      header={status ? 'Change Status' : 'Delete Message'}
      contrast
      isShown={isShown}
      hide={onClose}
      showClose
    >
      <Delete
        onClose={onClose}
        status={status}
        isLoading={isLoading}
        handleDelete={handleDelete}
        handleChange={handleChange}
        changeStatus={changeStatus}
        options={options}
        warning={warning}
      />
    </Modal>
  );
}

export default DeleteModal;
