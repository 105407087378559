import { PAIconFluentBox } from "assets/images/svgs";
import React from "react";

const FundAccount = ({ isOpen, onClose, onSubmit, virtualClicked }) => {
  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  const handleVirtual = () => {
    virtualClicked();
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-md w-full pb-5">
        <div className="flex justify-between items-center mb4">
          <p className="text-black font-sans text-base font-bold p-3 px-5 rounded-sm italic">
            Fund Account
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        <div className="border border-gray mb-5 px-5"></div>

        {/* FORM */}
        <div>
          <div className="w-full px-7">
            <p className="text-[#7A7A7A] font-sans text-sm font-normal mb6">
              Select funding method
            </p>

            <div className="bg-[#07593D] p-3 mt-3 text-white flex justify-between cursor-pointer" onClick={handleSubmit}>
              <p className="text-white text-xs">
                Fund account from an <br />
                <span className="font-semibold">External source</span>
              </p>
              <br />
              <div className="mt-2">
                <PAIconFluentBox />
              </div>
            </div>

            <div className="bg-[#07593D] p-3 mt-3 text-white flex justify-between cursor-pointer" onClick={handleVirtual}>
              <p className="text-white text-xs">
                Transfer funds to <br />
                <span className="font-semibold">
                  Virtual account
                </span>
              </p>
              <br />
              <div className="mt-2">
                <PAIconFluentBox />
              </div>
            </div>

            {/* FOOTER */}
            <div className="flex justify-center mt-10 mb-3">
              <p className="text-[#7A7A7A] font-sans text-sm font-normal">
                Select your preferred funding method
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundAccount;
