import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PAIconCancel } from "assets/images/svgs";
import {
  allDisbursedInvoice,
  getAssociatedFunderId,
} from "appstate/paymentExtension/paymentExtensionSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "shared/Loading";

const SelectFunderForBulkRequest = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { disbursedInvoiceData, isLoading } = useSelector(
    (state) => state?.paymentExtension
  );

  useEffect(() => {
    dispatch(allDisbursedInvoice());
  }, [dispatch]);

  useEffect(() => {
    setData(disbursedInvoiceData?.data);
  }, [disbursedInvoiceData]);

  const navigate = useNavigate();

  const handleNavigate = (invoiceId) => {
    // dispatch(getInvoiceId(invoiceId));
    dispatch(getAssociatedFunderId(invoiceId));
    let path = "/payment-extension/bulkextensioninvoicelist";
    navigate(path);
    // console.log("Invoice ID:", invoiceId);
  };

  // if (!isOpen) {
  //   return null;
  // }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center max-w-[600px] w-[450px]">
        {/* Header */}
        <header className="flex justify-between border-b border-[#F6F5FA] w-full">
          <h1 className="text-textColor text-base italic font-extrabold">
            Bulk Payment Request
          </h1>
          <div
            onClick={onClose}
            className="flex items-center gap-x-[5px] cursor-pointer"
          >
            <PAIconCancel className="w-[8px]" />
            <p className="text-gray800 text-sm font-medium">Close</p>
          </div>
        </header>

        {/* Content */}
        <div className="flex flex-col itemscenter w-full">
          <p className="text-black text-sm mt-2">
            Request bulk payment from{" "}
            <span className="text-black font-extrabold italic">Funder</span>
          </p>
          <div className="w-full">
            {isLoading ? (
              <div className="flex items-center justify-center w-full h-[200px]">
                <Loading />
              </div>
            ) : (
              data?.map((buyer) => (
                <div
                  onClick={() => handleNavigate(buyer?.funderId)}
                  key={buyer?.invoice?.id}
                  className="flex justify-between items-center border-b border-primaryColor pb-[10px] cursor-pointer w-full mt-2"
                >
                  <div className="flex gap-x-[12px] items-center">
                    <p className="text-jaguarBlack text-sm font-bold">
                      {buyer.buyerCompanyName}
                    </p>
                  </div>
                  <div>
                    <p className="text-jaguarBlack text-sm font-medium">
                      {/* Total Invoice - {buyer?.invoice?.id?.length} */}
                      Total Invoice - 2
                    </p>
                    <p className="text-primaryColor text-sm italic font-extrabold text-right">
                      View
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <p className="text-lightGray text-sm text-center mt-[10px]">
          Select your preferred payment extension request method
        </p>
      </div>
    </div>
  );
};

export default SelectFunderForBulkRequest;
