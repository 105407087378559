import { AnimatePresence } from "framer-motion";
import { useState } from "react";

import FunderInvoiceHeader from "./components/FunderInvoiceHeader";
import DirectFunding from "./components/pages/directFunding";
import NewDirectFunding from "./components/pages/newdirectfunding/directFundingNew";
function FunderInvoice() {
  const [activeTab, setActiveTab] = useState(
    parseInt(sessionStorage.getItem("activeTabSupplier")) || 1
  );
  const [searchQuery, setSearchQuery] = useState(
    sessionStorage.getItem("searchQuerySupplier") || ""
  );
  return (
    <div className="flex flex-col">
      <>
        <FunderInvoiceHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <main className="w-full">
          <AnimatePresence mode="wait">
            {activeTab === 1 ? (
              <div>1</div>
            ) : activeTab === 2 ? (
              <div>1</div>
            ) : activeTab === 3 ? (
              <DirectFunding />
            ) : (
              <NewDirectFunding />
            )}
          </AnimatePresence>
        </main>
      </>
    </div>
  );
}

export default FunderInvoice;
