import React from "react";
import PurchaseOrderContents from "features/purchaseOrder";


const PurchaseOrder = () => {
  return (
    <PurchaseOrderContents />
  )
}

export default PurchaseOrder;  


