import React from "react";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import Headers from "features/invoice/buyerInvoice/headers";
import Table from "layout/table/Table";
import { Link, useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useSelector } from "react-redux";
// import LoadingScreen from "shared/LoadingScreen";

const Details = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const { paymentExtensionData, isLoading } = useSelector((state) => state?.paymentExtension);
  const { paymentExtensionData, invoiceIds } = useSelector(
    (state) => state?.paymentExtension
  );

  const invoiceData = paymentExtensionData?.data?.find(
    (extension) => extension.invoiceIds === invoiceIds
  );

  // const [searchQuery, setSearchQuery] = useState("");
  // const [page, setPage] = useState(0);
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(10);
  // const pageSize = 10;

  // useEffect(() => {
  //   dispatch(
  //     allPaymentExtension({
  //       page,
  //       pageSize,
  //       search: searchQuery,
  //       bulk: "false",
  //       status: "",
  //     })
  //   );
  // }, [dispatch, page, pageSize, searchQuery]);

  // useEffect(() => {
  //   dispatch(allInvoiceById({ id }));
  // }, [dispatch, id]);

  const invoice = (id) => {
    let path = `/payment-extension/bulk/invoice/${id}`;
    navigate(path);
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Reference
        </span>
      ),
      selector: (row) => row.reference,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Due Date
        </span>
      ),
      selector: (row) => row.invoiceDueDate,
      sortable: true,
    },
    {
      name: "Extension Date",
      selector: (row) => row.newRequestedDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.invoiceAmount}</p>,
    },
    {
      name: "Interest Amount",
      selector: (row) => row.newPaymentAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.newPaymentAmount}</p>,
    },
    {
      name: "Interest Percent",
      selector: (row) => row.discountRate,
      sortable: true,
    },
  ];

  return (
    <div>
      <header className="rounded-md w-full bg-white mb-[17px] cursor-pointer items-center">
        <Link
          to="/payment-extension"
          className="flex items-center justifycenter gap-x-[3px] mb-[20px] cursor-pointer py-3 ml-2 my-auto"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>
      </header>
      <Headers />
      {/* Table */}
      {!invoiceData?.data?.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Approved Payment Extension
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[90px] rounded-t-[10px] mt-3">
            <div className="py-5">
              <div className="text-black text-md font-bold mt5">
                XYZ Ventures -{" "}
                <span className="text-secondaryColor">(Funder)</span>
              </div>

              <div className="text-xs font-thin flex gap-8 mt-1">
                <span className="text-[#07A04A]">
                  Request ref no. : R343t3xy
                </span>
                <span className="text-gray300">
                  2 invoices-
                  <span className="text-[#07593D] font-bold">
                    (Bulk Extension Requests)
                  </span>
                </span>
              </div>

              <div className="text-xs font-thin flex gap-8 mt-1 mb5">
                <span className="text-gray300">
                  Total invoice amount-
                  <span className="text-[#07593D] font-bold">NGN 83,000</span>
                </span>
                <span className="text-gray300">
                  Total interest amount-
                  <span className="text-[#07593D] font-bold">NGN 22,000</span>
                </span>
              </div>
            </div>
            <div className="flex items-center my-5 gap-5 mt-5">
              <button className="rounded-md bg-[#07593D] text-white px-6 py-2">
                Make Payment
              </button>
            </div>
          </div>
          <div className="pb-[24px]">
            <Table
              columns={columns}
              data={invoiceData?.data}
              onRowClicked={(row) => invoice(row.invoiceIds[0])}
              pointer
              tableHeader
              className="mt-5"
            />
            {/* <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {invoiceData?.data?.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={invoiceData?.meta?.totalElements}
                />
              )}
            </div> */}
          </div>
        </Card>
      )}
    </div>
  );
};

export default Details;
