import request from "apiInstance";
import toast from "react-hot-toast";

export const getAllOpenMarketInvoices = async ({
  page = 0,
  pageSize = 10,
  searchQuery = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/list-invoices?page=${page}&size=${pageSize}&search=${searchQuery}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const placeBidOnInvoice = async ({ id, body }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/open-market/place-offer?invoiceID=${id}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const lockAnInvoice = async ({ id }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/open-market/lock-invoice?invoiceID=${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateAnInvoice = async ({ id, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/open-market/update-offer?offerID=${id}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const cancelInvoiceBid = async ({ offerId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/open-market/cancel-bid?offerID=${offerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const invoiceByStatus = async ({
  page = 0,
  pageSize = 10,
  status,
  searchQuery = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/list-offers?size=${pageSize}&search=${searchQuery}&page=${page}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const invoiceByID = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/view-invoice?invoiceID=${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const invoiceBidByID = async ({ offerId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/view-offer?offerID=${offerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderDashBoard = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/dashboard`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierOpenMarket = async ({
  page = 0,
  pageSize = 20,
  search = "",
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/market-requests?page=${page}&size=${pageSize}&search=${search} `,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierOpenMarketByStatus = async ({ status, search = "" }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/market-requests?search=${search}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateBidOfferStatus = async ({ status, offerId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/open-market/offer/modify-status/${offerId}?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBidOffers = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/open-market/invoices/${id}/bids`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const invoiceImportIntoOpenMarket = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/fund-request/open-market",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
