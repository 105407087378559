import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllOpenMarketInvoices,
  placeBidOnInvoice,
  lockAnInvoice,
  updateAnInvoice,
  invoiceByStatus,
  funderDashBoard,
  cancelInvoiceBid,
  invoiceByID,
  supplierOpenMarket,
  supplierOpenMarketByStatus,
  invoiceBidByID,
  updateBidOfferStatus,
  getAllBidOffers,
  invoiceImportIntoOpenMarket,
} from "appstate/api/openMarket";

const initialState = {
  isLoading: false,
  allOpenMarketInvoicesData: [],
  getInvoiceBidByStatusData: [],
  funderDashboardData: [],
  invoiceByIdData: [],
  getAllSupplierOpenMarketData: [],
  getAllSupplierOpenMarketByStatusData: [],
  getBidInvoiceByIdData: [],
  getAllBidsData: [],
  importLoading: false,
};

export const allOpenMarketInvoices = createAsyncThunk(
  "openMarket/allOpenMarketInvoices",
  getAllOpenMarketInvoices
);

export const getAllSupplierOpenMarket = createAsyncThunk(
  "openMarket/getAllSupplierOpenMarket",
  supplierOpenMarket
);

export const getAllBids = createAsyncThunk(
  "openMarket/getAllBids",
  getAllBidOffers
);

export const getAllSupplierOpenMarketByStatus = createAsyncThunk(
  "openMarket/getAllSupplierOpenMarketByStatus",
  supplierOpenMarketByStatus
);

export const funderDashboard = createAsyncThunk(
  "openMarket/funderDashboard",
  funderDashBoard
);

export const placeInvoiceBid = createAsyncThunk(
  "openMarket/placeInvoiceBid",
  placeBidOnInvoice
);

export const lockInvoice = createAsyncThunk(
  "openMarket/lockInvoice",
  lockAnInvoice
);

export const updateInvoice = createAsyncThunk(
  "openMarket/updateInvoice",
  updateAnInvoice
);

export const cancelBid = createAsyncThunk(
  "openMarket/cancelBid",
  cancelInvoiceBid
);

export const updateBidStatus = createAsyncThunk(
  "openMarket/updateBidStatus",
  updateBidOfferStatus
);

export const getInvoiceBidByStatus = createAsyncThunk(
  "openMarket/getInvoiceBidByStatus",
  invoiceByStatus
);
export const getInvoiceById = createAsyncThunk(
  "openMarket/getInvoiceById",
  invoiceByID
);

export const getBidInvoiceById = createAsyncThunk(
  "openMarket/getBidInvoiceById",
  invoiceBidByID
);

export const importInvoiceIntoOpenMarket = createAsyncThunk(
  "openMarket/importInvoiceIntoOpenMarket",
  invoiceImportIntoOpenMarket
);

export const openMarketSlice = createSlice({
  name: "openMarket",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allOpenMarketInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allOpenMarketInvoices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allOpenMarketInvoicesData = action.payload;
    });
    builder.addCase(allOpenMarketInvoices.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAllBids.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBids.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getAllBidsData = action.payload;
    });
    builder.addCase(getAllBids.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAllSupplierOpenMarket.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllSupplierOpenMarket.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getAllSupplierOpenMarketData = action.payload;
    });
    builder.addCase(getAllSupplierOpenMarket.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAllSupplierOpenMarketByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllSupplierOpenMarketByStatus.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.getAllSupplierOpenMarketByStatusData = action.payload;
      }
    );
    builder.addCase(getAllSupplierOpenMarketByStatus.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getInvoiceBidByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInvoiceBidByStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getInvoiceBidByStatusData = action.payload;
    });
    builder.addCase(getInvoiceBidByStatus.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getInvoiceById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInvoiceById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.invoiceByIdData = action.payload;
    });
    builder.addCase(getInvoiceById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getBidInvoiceById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBidInvoiceById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getBidInvoiceByIdData = action.payload;
    });
    builder.addCase(getBidInvoiceById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(cancelBid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(cancelBid.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(cancelBid.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(funderDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderDashboardData = action.payload;
    });
    builder.addCase(funderDashboard.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(placeInvoiceBid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(placeInvoiceBid.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(placeInvoiceBid.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(lockInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(lockInvoice.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(lockInvoice.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateInvoice.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateInvoice.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateBidStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateBidStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateBidStatus.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(importInvoiceIntoOpenMarket.pending, (state) => {
      state.importLoading = true;
    });
    builder.addCase(importInvoiceIntoOpenMarket.fulfilled, (state) => {
      state.importLoading = false;
    });
    builder.addCase(importInvoiceIntoOpenMarket.rejected, (state) => {
      state.importLoading = false;
    });
  },
});

export default openMarketSlice.reducer;
