export const data = [
  {
    id: 1,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Admin',
  },
  {
    id: 2,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Admin',
  },
  {
    id: 3,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Member',
  },
  {
    id: 4,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Member',
  },
  {
    id: 14,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Member',
  },
  {
    id: 24,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Member',
  },
  {
    id: 34,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Member',
  },
  {
    id: 44,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Member',
  },
  {
    id: 55,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Member',
  },
  {
    id: 6,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Member',
  },
  {
    id: 7,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Member',
  },
  {
    id: 8,
    name: 'Eleanor Pena',
    email: 'eleanor@gmail.com',
    phone: '(704) 555-0127',
    role: 'Member',
  },
];
