import { PAIconFrame } from "assets/images/svgs";
import LandingpageCard from "features/landing/components/LandingpageCard";
import React from "react";
import Bar from "shared/Bar";
import Button from "shared/Button";
import ServicesCard from "./components/ServicesCard";

const SectionThree = () => {
  const serviceContents = [
    {
      heading: "Funding",
      content: "You get an increased access to funding",
    },
    {
      heading: "Financial control",
      content: "With PayEdge you get improved financial control",
    },
    {
      heading: "Reduced administrative costs",
      content: "Reduced administrative costs",
    },
  ];
  const cardContents = [
    {
      heading: "Delivering inclusivity",
      content:
        "From large to mid-sized suppliers and SMEs, Marco Polo Network provides a more inclusive supply chain solution",
    },
    {
      heading: "Delivering inclusivity",
      content:
        "From large to mid-sized suppliers and SMEs, Marco Polo Network provides a more inclusive supply chain solution",
    },
    {
      heading: "Delivering inclusivity",
      content:
        "From large to mid-sized suppliers and SMEs, Marco Polo Network provides a more inclusive supply chain solution",
    },
    {
      heading: "Delivering inclusivity",
      content:
        "From large to mid-sized suppliers and SMEs, Marco Polo Network provides a more inclusive supply chain solution",
    },
    {
      heading: "Delivering inclusivity",
      content:
        "From large to mid-sized suppliers and SMEs, Marco Polo Network provides a more inclusive supply chain solution",
    },
  ];
  return (
    <div className="bg-flashWhite px-[24px] pt-[30px] pb-[50px] lg:px-[42px] lg:pt-[50px]">
      <div className="lg:flex justify-between gap-x-[92px]">
        <div className="flex flex-col justify-center lg:w-[501px] ">
          <h3 className="text-[24px] font-normal text-black mb-[20px]">
            Our Services
          </h3>
          <h1 className="font-extrabold text-[50px] text-textColor leading-tight">
            What Can We Do for Your Business
          </h1>
          <p className="font-normal text-lg text-textColor mt-[20px]">
            As experts in supply chain finance, we understand the challenges
            that businesses face when managing cash flow. That's why we offer a
            wide range of customized financial solutions designed to help our
            clients manage their cash flow, reduce risk, and improve efficiency.
          </p>
          <Button className="mt-[30px] w-[219px]"> Find out more </Button>
        </div>
        <div className="flex justify-center ">
          <div className="mb-[100px] md:grid grid-cols-2 gap-x-10">
            <div className="flex justify-center">
              <PAIconFrame className="w-[270.05px] bottom-[0px] right-[0px] left-[0px] lg:w-[320px] lg:h-[297.82px]" />
            </div>
            {serviceContents.map((serviceContent, idx) => (
              <ServicesCard
                key={idx}
                heading={serviceContent.heading}
                text={serviceContent.content}
              />
            ))}
          </div>
        </div>
      </div>
      <Bar />
      <h1 className="font-extrabold text-[24px] text-textColor mt-[30px]">
        Your inclusive supply chain solution
      </h1>
      <p className="font-medium text-lg text-textColor mt-[10px]">
        No supplier gets behind.
      </p>
      <div className="mt-[30px] lg:grid grid-cols-2 gap-x-20">
        {cardContents.map((cardContent, idx) => (
          <LandingpageCard
            key={idx}
            heading={cardContent.heading}
            content={cardContent.content}
          />
        ))}
        <div className="text-center">
          <h1 className="font-extrabold text-[24px] text-textColor">
            A solution for all suppliers
          </h1>
          <p className="font-medium text-lg text-textColor mb-[30px]">
            See how PayEdge works
          </p>
          <Button>Find out more </Button>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
