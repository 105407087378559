import { PAIconUndrawAddFiles } from 'assets/images/svgs'
import React from 'react'
import Button from 'shared/Button'
import Card from 'shared/Card'
import InputField from 'shared/InputField'

const ImportModalComp = ({ onClose }) => {
  const inputClassName = "w-full pl-[15px] pr-[100px] !bg-[#FAFAFA] border !border-[#CCCCCC] rounded-[5px] focus:outline-none"
  return (
    <div className='flex flex-col justify-center'>
      <Card className="rounded-t-[10px] px-[20px] md:px-[40px] py-[25px] md:py-[30px]">
        <label className='bg-[#FAFAFA] border-dashed border-2 flex justify-center my-[30px] h-[150px] md:h-[300px]'>
          <div className='flex flex-col items-center justify-center'>
            <PAIconUndrawAddFiles />
            <p className='text-xs'>Drag files to</p>
            <p className='text-xs'>upload or choose file</p>
          </div>
          <input type='file' className='w-0 h-0' />
        </label>
        <div className='flex justify-center items-center mt-[30px]'>
          <hr className='w-full' />
          <p className='px-[20px]'>OR</p>
          <hr className='w-full' />
        </div>
        <div className='relative'>
          <InputField type="text" label="Upload from URL" placeholder="Add file URL" className={`${inputClassName}`} />
          <Button className="absolute right-[3px] w-[70px] bottom-[4px] bg-white rounded-[5px] px-[6px] py-[6px] border !border-[#CCCCCC] text-sm">Upload</Button>
        </div>
      </Card>
      <Card className="rounded-b-[10px] px-[20px] md:px-[40px]">
        <div className='border-t flex justify-end'>
          <div className='flex my-[30px] gap-4'>
            <Button className='!bg-[#EEEEEE] w-full rounded-[5px] h-[41px] px-[8px] py-[6px] text-sm font-medium shadow-1px border !border-[#CCCCCC]' onClick={onClose}>Discard</Button>
            <Button onClick={onClose} className="!bg-[#2D0432] text-white w-full rounded-[5px] h-[41px] px-[8px] py-[6px] text-sm font-medium shadow-1px border !border-[#CCCCCC]">Approve</Button>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ImportModalComp