import React, { useState } from 'react';
import ArrowDown from "../../../assets/images/svgs/Vector.svg";
import Search from "../../../assets/images/svgs/search-normal.svg";

const BuyerModal = ({ isOpen, onRequestClose, buyers, onSelectBuyer, anchorRef }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredBuyers = buyers.filter(buyer =>
    buyer.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!isOpen) return null;

  const modalStyle = {
    WebkitOverflowScrolling: 'touch',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none'  
  };

  const modalContentStyle = {
    '::-webkit-scrollbar': {
      display: 'none',
    },
  };

  return (
    <div
      className="absolute z-50 mt-20 bg-white rounded-lg shadow-lg w-full max-h-60"
      style={{ ...modalStyle, ...modalContentStyle }}
    >
      <div className='flex justify-between'>
        <h1 style={{fontSize: 16}} className="font-large text-sm mb-1 pl-3.5 text-[#BCBCBC]">Select Buyers</h1>
        <img
          src={ArrowDown}
          alt='Arrow Down'
          className="cursor-pointer p-2"
          onClick={onRequestClose}
        />
      </div>
      <div className="p-2 relative">
        <input
          type="text"
          placeholder="Search for buyers"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 pr-10"
        />
        <img
          src={Search}
          alt="Search"
          className="absolute right-2 top-1/2 transform -translate-y-1/2 w-4 h-4"
        />
      </div>
      <ul className="p-2">
        {filteredBuyers.length > 0 ? (
          filteredBuyers.map((buyer) => (
            <li
              key={buyer.id}
              onClick={() => onSelectBuyer(buyer)}
              className="p-2 cursor-pointer hover:bg-gray-100"
            >
              {buyer.label}
            </li>
          ))
        ) : (
          <li className="p-2 text-gray-500">No buyers found</li>
        )}
      </ul>
    </div>
  );
};

export default BuyerModal;
