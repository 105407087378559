import { assignTiers } from "appstate/tier/tierSlice";
import {
  PAIconDetailsPlaceholder,
  PAIconInventoryArrowLeft,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import InvoiceModalContainer from "shared/invoiceModal";

const BuyerSupplierDetails = () => {
  const [open, setOpen] = useState(false);
  const [assignTier, setAssignTier] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }

  const { allTiersData } = useSelector((state) => state?.tier);

  const { associatedSupplierId, buyerAssociatedSuppliersData } = useSelector(
    (state) => state.buyer
  );

  const details = buyerAssociatedSuppliersData?.data?.find(
    (curr) => curr?.id === associatedSupplierId
  );

  const userId = details?.userId;
  const handleTierChange = (e) => {
    const tierId = e.target.value;
    setAssignTier(tierId);
    dispatch(assignTiers({ userId, tierId })).then((data) => {
      if (data?.payload?.success) {
        toast.success("Tier assigned successfully");
      }
    });
  };

  return (
    <DashboardLayout section="Supplier Details">
      <InvoiceModalContainer isShown={open} onClose={() => setOpen(false)} />
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
          <div className="flex gap-x-[395px] flex-wrap md:gap-x-[200px] lg:gap-x-[280px] xl:gap-x-[395px]">
            <div>
              <p className="text-black text-sm font-medium">Details</p>
              <p className="text-textColor text-xs font-normal">
                Basic information about the associate
              </p>
            </div>
            <div>
              <div>
                <label className="text-black text-sm font-medium" htmlFor="">
                  Account{" "}
                </label>
              </div>
              <select
                className="w-[159.998px] p-[5px] outline-none border border-silver rounded mt-[5px]"
                name="assignTier"
                id="assignTier"
                onChange={handleTierChange}
                value={assignTier}
              >
                <option disabled value="">
                  Select Tier
                </option>
                {allTiersData?.data?.map((data) => (
                  <option key={data?.id} value={data?.id}>
                    {data?.tierName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </header>
        <Card className="px-[20px] md:px-[40px] pb-[40px] rounded-b-[10px] 2xl:pl-[100px]">
          <div>
            <div className="md:flex md:justify-between  pt-[24px] 2xl:justify-start 2xl:gap-x-[385px] ">
              <div>
                <h2 className="text-black text-sm font-semibold">
                  Personal Information
                </h2>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">Name</p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {details?.fullName}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">Email</p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {details?.email}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Contact number
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {details?.phoneNumber}
                  </p>
                </div>
              </div>

              <div className="mt-[24px] md:mt-0">
                <h2 className="text-black text-sm font-semibold">
                  Company Information
                </h2>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Company Name
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {details?.companyName}
                  </p>
                </div>
                <div className="mt-[24px]">
                  <p className="text-sm font-normal text-lightGray">
                    Company Email
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {details?.companyEmail}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-[48px]">
              <h1 className="text-sm font-semibold md:justify-start text-textColor flex items-center">
                Invoice
                <span className="w-[25px] h-[25px] flex justify-center items-center bg-conch ml-[10px] rounded-[50%]">
                  2
                </span>
              </h1>
              <div className="mt-[24px] flex gap-x-[24px]">
                <div className="cursor-pointer" onClick={() => setOpen(true)}>
                  <PAIconDetailsPlaceholder />
                  <p className="text-xs font-normal text-textColor">
                    #INV-2012
                  </p>
                </div>
                <div className="cursor-pointer" onClick={() => setOpen(true)}>
                  <PAIconDetailsPlaceholder />
                  <p className="text-xs font-normal text-textColor">
                    #INV-2012
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default BuyerSupplierDetails;
