import request from "apiInstance";
import toast from "react-hot-toast";

export const viewSupplierInventories = async ({ supplierId, page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/purchase-order/view-inventory/${supplierId}?${page}&${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const createPurchaseOrder = async ({ supplierId, body }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/purchase-order?supplier=${supplierId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllPurchaseOrders = async ({page, pageSize}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/purchase-order/buyer?${page}&${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const searchSupplierInventories = async ({
  supplierId,
  productName,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/purchase-order/${supplierId}/search?name=${productName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const searchPurchaseOrder = async ({ productName }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/purchase-order/buyer/po-ref/${productName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const filterPoByStatus = async ({ status }) => {
  try {
    const response = await request({
      method: 'get',
      url: `/api/v1/purchase-order/buyer/status/${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const changeStatus = async ({ poId, status }) => {
  try {
    const response = await request({
      method: 'put',
      url: `/api/v1/purchase-order/${poId}/${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};
