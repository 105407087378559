import { PAIconFile, PAIconJamWrite, PAIconPrint } from 'assets/images/svgs'
import { PURCHASETABDES, PURCHASETABDESCCOLUMN } from 'features/invoice/utilities/tableData'
import Table from 'layout/table/Table'
import React from 'react'
import Card from 'shared/Card'

const PurchaseOrderTab = ({ singleInvoice }) => {

  return (
    <Card className="px-12 max-[500px]:px-4 py-4 flex flex-col gap-6">
      <div className='border rounded-[5px] border-black flex flex-wrap items-center gap-3 justify-between p-2'>
        <p className='text-[20px] font-semibold'>PO-2002</p>
        <div className='flex flex-wrap items-center gap-2'>
          <div className="flex gap-2">
            <PAIconJamWrite className='hover:cursor-pointer' />
            <PAIconFile className='hover:cursor-pointer' />
            <PAIconPrint className='hover:cursor-pointer' />
          </div>
          <p className='bg-[#FFA500] text-white p-2 rounded-[3px] hover:cursor-pointer whitespace-nowrap hover:bg-[#F57200]'>
            Convert to Bill
          </p>
        </div>
      </div>
      <div className='w-1/5'>
        <p className='font-semibold whitespace-nowrap'>Purchase Order | PO-2002</p>
        <div className='flex justify-between gap-4 items-center'>
          <p className='text-[12px] whitespace-nowrap'>Created Date:</p>
          <p className='text-[#7A7A7A] text-[12px] whitespace-nowrap'>23-Dec-2024</p>
        </div>
        <div className='flex justify-between gap-4 items-center'>
          <p className='text-[12px] whitespace-nowrap'>Payment Due Date:</p>
          <p className='text-[#7A7A7A] text-[12px] whitespace-nowrap'>23-Dec-2024</p>
        </div>
      </div>
      <div className='flex flex-wrap gap-4 justify-between'>
        <div className='flex gap-3'>
          <div className='w-[50px] h-[50px] bg-[#C4C4C4] rounded-xl'></div>
          <div>
            <p className='font-semibold whitespace-nowrap'>Anastasya Gordon</p>
            <p className='text-[#626679] text-[12px]'>Company</p>
          </div>
        </div>
        <div>
          <p className={"rounded-[5px] py-2 px-8 text-[12px] " + (singleInvoice.status === "Paid" ? "bg-[#CDECDB] text-[#07A04A]" : singleInvoice.status === "Cancelled" ? "bg-red-300 text-red-900" : singleInvoice.status === "Open" ? "bg-[#574D61]" : "bg-slate-300")}>{singleInvoice.status}</p>
        </div>
      </div>
      <div className='flex flex-wrap gap-4 justify-between pb-8 border-b-[5px] border-[#F6F6FA]'>
        <div className='flex flex-col text-[#626679] text-[12px]'>
          <p>From:</p>
          <div className='flex flex-col'>
            <p>The Holding Center</p>
            <p>7 North Road</p>
            <p>Tabemono</p>
            <p>Asia</p>
            <p>AB 123 F</p>
            <p>gordon@gmail.com</p>
            <p>+1234-456-789</p>
          </div>
        </div>
        <div className='flex flex-col text-[#626679] text-[12px]'>
          <p>Bill to:</p>
          <div className='flex flex-col'>
            <p>The Holding Center</p>
            <p>7 North Road</p>
            <p>Tabemono</p>
            <p>Asia</p>
            <p>AB 123 F</p>
            <p>gordon@gmail.com</p>
            <p>+1234-456-789</p>
          </div>
        </div>
      </div>
      <div>
        <p className='text-[#3F4254] font-semibold'>Descriptions</p>
        <Table
          columns={PURCHASETABDESCCOLUMN}
          data={PURCHASETABDES}
          className="mt-4"
        />
        <div className='flex justify-end mt-4'>
          <div className='w-1/2 flex flex-col gap-2 font-medium text-[#3F4254]'>
            <div className='flex gap-4 justify-between'>
              <p>Subtotal</p>
              <p>$0.00</p>
            </div>
            <div className='flex gap-4 justify-between border-b'>
              <p>Includes VAT</p>
              <p>7.5%</p>
            </div>
            <div className='flex gap-4 justify-between border-b'>
              <p>Total</p>
              <p>$0.00</p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default PurchaseOrderTab