import {
  PAIconPencilLine,
  PAIconSettingsAdd,
} from "../../../assets/images/svgs";
import styled from "styled-components";
import ProfilePic from "./ProfilePic";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "shared/InputField";
import { useFormik } from "formik";
import { updateCompanyDetailsSchema } from "validation/settings/UpdateCompanyDetailsSchema";
import PhoneNumber from "shared/PhoneNumber";
import Button from "shared/Button";
import Spinner from "shared/Spinner/Spinner";
import { updateSupplierCompanyDetailsSchema } from "validation/settings/UpdateSupplierCompanySchema";
import { updatePlayerCompany } from "appstate/settings/settingsSlice";
import { toast } from "react-hot-toast";
import { getCurrentUser } from "appstate/auth/authSlice";

const CompanyDetails = ({ currentUser }) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.settings);

  const formik = useFormik({
    initialValues: {
      registeredAddress: currentUser?.registeredAddress || "",
      companyWebsite: currentUser?.companyWebsite || "",
      companyPhoneNumber: currentUser?.companyPhoneNumber || "",
      companyEmail: currentUser?.companyEmail || "",
    },
    validationSchema:
      currentUser?.role.toLowerCase() === "supplier"
        ? updateSupplierCompanyDetailsSchema
        : updateCompanyDetailsSchema,
    onSubmit: (values) => {
      const body =
        currentUser?.role.toLowerCase() === "supplier"
          ? {
              registeredAddress: values.registeredAddress,
              companyWebsite: values.companyWebsite,
              companyPhoneNumber: values.companyPhoneNumber,
            }
          : {
              registeredAddress: values.registeredAddress,
              companyWebsite: values.companyWebsite,
              companyEmail: values.companyEmail,
              companyPhoneNumber: values.companyPhoneNumber,
            };
      dispatch(
        updatePlayerCompany({ body, role: currentUser?.role.toLowerCase() })
      ).then((data) => {
        if (data?.payload?.status === 200) {
          toast.success("Company details edited successfully!");
          dispatch(getCurrentUser(data?.payload?.data));
          setIsEditing(false);
        }
      });
    },
  });

  return (
    <div className="flex flex-col py-[18px] px-[25px] space-y-5">
      <ProfilePic currentUser={currentUser} />
      <div className="bg-gallery h-[1px] pb-1 w-full"></div>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-5">
          <p className="font-bold">Company Details</p>
        </div>
        <div className="flex justify-between flex-wrap gap-4 items-start">
          <div className="flex md:w-[350px] w-full flex-col gap-5">
            <div className="w-full">
              <InputField
                disabled={!isEditing}
                className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="www.company.com"
                label="Company Website"
                id="companyWebsite"
                name="companyWebsite"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyWebsite}
              />
              {formik.touched.companyWebsite && formik.errors.companyWebsite ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyWebsite}
                </p>
              ) : null}
            </div>
            <div className="w-full">
              <PhoneNumber
                disabled={!isEditing}
                id="companyPhoneNumber"
                name="companyPhoneNumber"
                className="!md:w-[350px] !w-full h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyPhoneNumber}
              />
              {formik.touched.companyPhoneNumber &&
              formik.errors.companyPhoneNumber ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyPhoneNumber}
                </p>
              ) : null}
            </div>
            <div className="w-full">
              <InputField
                disabled={!isEditing}
                className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="3rd Avenue F1 close"
                label="Registered Address"
                id="registeredAddress"
                name="registeredAddress"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.registeredAddress}
              />
              {formik.touched.registeredAddress &&
              formik.errors.registeredAddress ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.registeredAddress}
                </p>
              ) : null}
            </div>
            {currentUser?.role === "BUYER" ||
            currentUser?.role === "CORPORATE_FUNDER" ? (
              <div className="w-full">
                <InputField
                  disabled={!isEditing}
                  className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                  placeholder="example@test.com"
                  label="Company Email"
                  id="companyEmail"
                  name="companyEmail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyEmail}
                />
                {formik.touched.companyEmail && formik.errors.companyEmail ? (
                  <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.companyEmail}
                  </p>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="flex md:w-[350px] w-full flex-col gap-5">
            <div className="w-full">
              <InputField
                disabled
                className="text-[#CCCCCC] !max-sm:w-full h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="Registered Company Name"
                label="Registered Company Name"
                id="companyName"
                name="companyName"
                value={currentUser?.companyName || ""}
              />
            </div>
            <div className="w-full">
              <InputField
                disabled
                className="text-[#CCCCCC] !max-sm:w-full h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="exampleforyou@info.com"
                label="Company Tax ID"
                id="companyTaxID"
                name="companyTaxID"
                value={currentUser?.companyTaxId || ""}
              />
            </div>
            <div className="w-full">
              <InputField
                disabled
                className="text-[#CCCCCC] !max-sm:w-full h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="RC12234444"
                label="Registered Company Number (RC Number)"
                id="registeredCompanyNumber"
                name="registeredCompanyNumber"
                value={currentUser?.rcNumber || ""}
              />
            </div>
          </div>
        </div>
        <div className="py-6">
          {isEditing ? (
            <div className="flex gap-2 justify-start">
              <Button
                type="button"
                onClick={() => setIsEditing(!isEditing)}
                className="flex !text-[14px] !px-6 !py-3 items-center bg-wildSand border-none !rounded-[10px] h-[40px]"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="flex !text-[14px] !px-6 !py-3 items-center !bg-emerald border-none !rounded-[10px] h-[40px] hover:bg-green-400"
              >
                {isLoading ? <Spinner /> : "Save"}
              </Button>
            </div>
          ) : (
            <p
              onClick={() => setIsEditing(!isEditing)}
              className="cursor-pointer inline-flex items-center px-4 py-1 gap-[2px] hover:bg-blue-50 text-gray-800 text-sm font-medium rounded-full border border-[#213874] -mt-3"
            >
              <PAIconPencilLine />
              Edit
            </p>
          )}
        </div>
      </form>
      <div className="bg-gallery h-[1px] pb-1 w-full"></div>
      <div className="space-y-3.5">
        <p className="font-bold">Associates</p>
        <Layout>
          <div className="flex flex-col gap-1">
            <Label htmlFor="sku">Associate’s Company Name</Label>
            <Input type="text" placeholder="ABC Ltd" />
          </div>
        </Layout>
        <p className="inline-flex items-center px-4 py-1 gap-[2px] hover:bg-blue-50 text-gray-800 text-sm font-medium rounded-full border border-[#213874] -mt-3">
          <PAIconSettingsAdd />
          Add Associate
        </p>
      </div>
    </div>
  );
};

export default CompanyDetails;

const Layout = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  /* justify-content: center */
`;

const Label = styled.label`
  color: var(--norm-text, #7a7a7a);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Input = styled.input`
  display: flex;
  width: 320px;
  height: 40px;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: rgba(246, 246, 246, 0.96);
  ::placeholder {
    font-size: 14px;
    color: black;
    font-family: BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
