import request from "apiInstance";
import toast from "react-hot-toast";

export const createSupplier = async ({ body }) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/suppliers/create",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const verifyEmail = async (token) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/2fa/verify-email?token=${token}`,
    });
    toast.success("Email verified successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const ResendVerificationEmail = async (email) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/global/email/resend-link?email=${encodeURIComponent(
        email
      )}`,
    });
    toast.success("Link sent successfully!");
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierResendEmail = async ({ email }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/suppliers/resend-link?email=${email}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierById = async ({ supplierId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers/user/id/${supplierId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getBuyerById = async ({ buyerId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/user/id/${buyerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getBuyers = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const searchBuyer = async ({ companyName }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/rc-name?company=${companyName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const associateBuyer = async (data) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/company?buyer=${data.associateBuyerId}`,
      data: data.body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const addBank = async (data) => {
  try {
    const response = await request({
      method: "put",
      url: "api/v1/suppliers/account",
      data: data.body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const createBuyer = async (body) => {
  try {
    const response = await request({
      method: "POST",
      url: "/api/v1/buyers/create",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerResendEmail = async ({ email }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/buyers/resend-link?email=${email}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const CompanyDetails = async (data) => {
  try {
    const response = await request({
      method: "PUT",
      url: "/api/v1/buyers/company",
      data: data.body,
    });

    if (
      response?.data &&
      response?.data?.message &&
      response?.data?.status === 400
    ) {
      toast.error(response?.data?.message + "!");
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const addBuyerBank = async (data) => {
  try {
    const response = await request({
      method: "PUT",
      url: "/api/v1/buyers/account",
      data: data.body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const createFunderCompany = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: "/api/v1/funders/company",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const createFunder = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/funders/create",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getFunderDataById = async ({ funderId }) => {
  try {
    // console.log(funderId)
    const response = await request({
      method: "get",
      url: `/api/v1/funders/user/id/${funderId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderResendEmail = async ({ email }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/funders/resend-link?email==${email}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const corporateFunderResendEmail = async ({ email }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/funders/corporate/resend-link?email=${email}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const addFunderBank = async ({ body }) => {
  try {
    const response = await request({
      method: "put",
      url: "/api/v1/funders/account",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const setCorporateFunderPassword = async (data) => {
  try {
    const response = await request({
      method: "put",
      url: `/admin/users/${data.userId}/set-password`,
      data: data.body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getAllFunders = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/${data.funder}?page=0&size=10`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getFunderById = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/${data.funder}/${data.funderId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getCorporateFunderByCompany = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/rc-name?company=${data.companyName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getPlayerByUserId = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/${data.type}s/user/id/${data.userId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getPlayerById = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/${data.type}s/id/${data.id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllSuppliers = async (page, pageSize) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllFundersAccounts = async (page, pageSize) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/view-all?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getBuyersAssociatedWithSupplier = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers/buyers`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getSuppliersAssociatedWithBuyer = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/suppliers`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getFunderByUserId = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/${data.variant}/user-id/${data.userId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
