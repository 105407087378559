import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Tabs from "./tabs";

const FundingSectionPage = () => {
  return (
    <DashboardLayout
      section="Funding Section"
      subHeader="Send your funding processes"
    >
      <Tabs />
    </DashboardLayout>
  );
};

export default FundingSectionPage;
