import Modal from "layout/modal/Modal";
import React from "react";
import TierModalContents from "./TierModalContents";

const TierModal = ({
  isShown,
  onClose,
  fetchTiers,
  edit,
  tierId,
  isDetails,
  setIsDetails,
  getId
}) => {
  return (
    <Modal accountModal isShown={isShown} hide={onClose}>
      <TierModalContents
        edit={edit}
        fetchTiers={fetchTiers}
        tierId={tierId}
        onClose={onClose}
        isDetails={isDetails}
        setIsDetails={setIsDetails}
        getId={getId}
      />
    </Modal>
  );
};

export default TierModal;
