import { allSupplierAssociatedBuyer } from "appstate/supplier/supplierSlice";
import { PAIconCancel, PAIconCocacola } from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/Pagination";

const SelectBuyerForBulkRequest = ({ hide }) => {
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const { supplierAssociatedBuyersData, isLoading } = useSelector(
    (state) => state?.supplier
  );
  const [data, setData] = useState([]);
  const search = ""
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allSupplierAssociatedBuyer({ page, pageSize, search }));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    setData(supplierAssociatedBuyersData?.data);
  }, [supplierAssociatedBuyersData]);

  const navigate = useNavigate();
  const handleNavigate = () => {
    let path = "/invoice/fundingSection/bulkrequestinvoicelist";
    navigate(path);
  };
  const totalPages = supplierAssociatedBuyersData?.meta?.totalElements;
  return (
    <div className="px-[21px] py-[23px]">
      <header className="flex justify-between border-b border-[#F6F5FA] pb-[16px]">
        <h1 className="text-textColor text-base italic font-extrabold">
          Bulk Payment Request
        </h1>
        <div
          onClick={hide}
          className="flex items-center gap-x-[5px] cursor-pointer"
        >
          <PAIconCancel className="w-[8px]" />
          <p className="text-gray800 text-sm font-medium">Close</p>
        </div>
      </header>
      <div>
        <p className="text-black text-sm mt-[36px]">
          Request bulk payment from{" "}
          <span className="text-black font-extrabold italic">Buyer</span>
        </p>
        <p className="text-primaryColor text-xs mb-[34.5px]">
          Switch to funder
        </p>
        <div>
          {data?.map((buyer, index) => (
            <div
              onClick={handleNavigate}
              key={index}
              className="flex justify-between items-center border-b border-primaryColor pb-[10px] cursor-pointer"
            >
              <div className="flex gap-x-[12px] items-center">
                <div className="w-[43px] h-[43px] rounded-[100%] flex justify-center items-center border-textColor border">
                  <PAIconCocacola />
                </div>
                <p className="text-jaguarBlack text-sm">{buyer?.companyName}</p>
              </div>
              <div>
                <p className="text-jaguarBlack text-sm font-medium">
                  Total Invoice - 123
                </p>
                <p className="text-primaryColor text-xs italic font-extrabold text-right">
                  View
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <p className="text-lightGray text-xs text-center mt-[10px]">
        Select your preferred early payment request method
      </p>
      <div className="flex justify-center gap-x-[8px] mt-[20px]">
        {!isLoading && totalPages > 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
};

export default SelectBuyerForBulkRequest;
