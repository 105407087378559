import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import AccountModalContainer from "shared/accountModal/Modal";
import DashboardLayout from "layout/dashboardlayout";
import { allApprovedInvoices } from "appstate/supplier/supplierSlice";
import { extractDate } from "utilities/helper/dateFormater";
import Button from "shared/Button";
import { importInvoiceIntoOpenMarket } from "appstate/openMarket/openMarketSlice";

const ImportInvoice = () => {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [getIds, setGetIds] = useState(null);
  const pageSize = 10;
  const { approvedInvoicesData, isLoading } = useSelector(
    (state) => state?.supplier
  );

  const { importLoading } = useSelector((state) => state?.openMarket);

  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allApprovedInvoices({ page, pageSize, status: "APPROVED" }));
  }, [dispatch, page, pageSize]);
  useEffect(() => {
    setData(approvedInvoicesData?.data);
  }, [approvedInvoicesData]);

  const columns = [
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceReference,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyerCompanyName,
    },
    {
      name: "Invoice Date",
      selector: (row) => extractDate(row.createdDate),
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.total,
      cell: (row) => <p className="text-success">{row.total}</p>,
    },
  ];

  const totalPages = approvedInvoicesData?.meta?.totalElements;

  const handleRowSelect = (row) => {
    const checkedRows = row.selectedRows.map((curr) => curr.id);
    setGetIds(checkedRows);
  };

  const handleImport = () => {
    const body = getIds;
    dispatch(importInvoiceIntoOpenMarket({ invoiceIds: body })).then((data) => {
      if (data?.payload?.success) {
        setOpen(false);
      }
    });
  };

  return (
    <DashboardLayout>
      <AccountModalContainer
        accontModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        handleAssociate={handleImport}
        isLoading={importLoading}
        success={success}
        warning="Are you sure you would like to import these invoices"
      />
      <Card className="rounded-lg">
        <div className="px-[1.5rem] pt-[1.75rem] flex justify-between items-center">
          <div>
            <h1 className="text-[0.875rem] font-bold text-black">
              All Available Invoices
            </h1>
            <p className="text-textColor text-[0.75rem]">Transaction Summary</p>
          </div>
          <Button
            neutral
            onClick={() => setOpen(true)}
            className="w-[10.6875rem] h-[2.3125rem] bg-primaryColor rounded !text-[0.875rem] text-white"
          >
            Upload Selected Inoives
          </Button>
        </div>
        <div className="rounded-b-lg px-[24px] pb-[24px]">
          <Table
            columns={columns}
            data={data}
            pointer
            progressPending={isLoading}
            selectableRows
            handleRowSelect={handleRowSelect}
            progressComponent={<Loading />}
            tableHeader
            className="mt-5"
          />
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading && totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default ImportInvoice;
