import React from "react";
import NewSingleBulkOption from "./option";
import DirectFundingHeader from "../../directFunding/headers/DirectFundingHeader";

const NewDirectFunding = () => {
  return (
    <div>
      <DirectFundingHeader />
      <NewSingleBulkOption />
    </div>
  );
};

export default NewDirectFunding;
