import request from "apiInstance";
import toast from "react-hot-toast";

export const createWorkflowApi = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/workflow`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllWorkflowApi = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflows`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getWorkflowByIDApi = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/workflow/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const modifyWorkflowStatusApi = async ({ id, status }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/workflow/modify-status/${id}?status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
