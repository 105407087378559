import * as Yup from "yup";
const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])/;
const resetPasswordValidationSchema = Yup.object({
  oldPassword: Yup.string().required("Current Password is required"),
  newPassword: Yup.string()
    .min(6, "Password must be at least minimum of 6 characters")
    .matches(
      passwordRegex,
      "Password must contain at least one number, one special character, one uppercase letter, and be 6 characters long."
    )
    .required("New Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Please confirm password")
    .required("Passwords do not match"),
});

export { resetPasswordValidationSchema, passwordRegex };
