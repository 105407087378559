import React, { useState, useRef, useEffect } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import DownloadModal from "./DownloadModal";
import PermissionModal from "./PermissionModal";
import DeleteModal from "./DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAgreement,
  getAgreement,
  getAgreementId,
} from "appstate/toolsAndAgreement/toolsAndAgreementSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import toast from "react-hot-toast";

const AgreementTable = (props) => {
  // const { searchQuery } = props;
  // const [selectedRows, setSelectedRows] = useState([]);

  const [dropdown, setDropdown] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isPermissionModalOpen, setPermissionModalOpen] = useState(false);
  const [getId, setGetId] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const dropdownRef = useRef();
  const pageSize = 10;

  const { isLoading, allAgreementData } = useSelector(
    (state) => state?.toolsAndAgreements
  );
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const togglePermissionModal = () => {
    setPermissionModalOpen(!isPermissionModalOpen);
  };

  // const toggleDeleteModal = () => {
  //   setDeleteModalOpen(!isDeleteModalOpen);
  // };

  const handleSelect = (row) => {
    setDeleteModalOpen(true);
    setGetId(row?.id);
  };

  const handleDelete = () => {
    const agreementId = getId;
    dispatch(deleteAgreement({ agreementId })).then((data) => {
      if (data?.payload?.success === true) {
        dispatch(getAgreement({ page, pageSize }));
        setDeleteModalOpen(false);
        toast.success("Agreement Deleted");
      }
    });
  };

  const handleDropdown = (row) => {
    if (dropdown === row.id) {
      setDropdown(null);
    } else {
      setDropdown(row.id);
    }
  };

  useEffect(() => {
    dispatch(getAgreement({ page, pageSize }));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    setData(allAgreementData?.data?.data);
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown, allAgreementData]);

  const totalPages = allAgreementData?.meta?.totalElements;

  const formatDate = (dateString) => {
    const date = new Date(dateString.replace(" PM", ""));
    if (isNaN(date)) {
      return "Invalid Date";
    }
    return date.toISOString().split("T")[0];
  };

  const columns = [
    // {
    //   cell: (row) => (
    //     <input
    //       type="checkbox"
    //       checked={selectedRows.includes(row.id)}
    //       className="w-2.5"
    //     />
    //   ),
    //   width: "2rem",
    // },
    {
      name: "Agreement Name",
      selector: (row) => (
        <p
          className={`cursor-pointer ${
            row.status === "APPROVED"
              ? "text-success"
              : row.status === "REJECTED"
              ? "text-red"
              : row.status === "PENDING"
              ? "text-yellow-400"
              : row.status === "UPDATED"
              ? "text-[#28B6E3]"
              : ""
          }`}
        >
          {row.name}
        </p>
      ),
      sortable: true,
      width: "w-full",
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.createdDate),
      sortable: true,
      width: "13rem",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "APPROVED" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "PENDING" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-[#28B6E3]">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
      width: "7rem",
    },
    {
      selector: (row) => row.option,
      cell: (row) => (
        <div>
          <img
            src="/option-btn.svg"
            alt="/"
            onClick={() => handleDropdown(row)}
          />
          {dropdown === row.id && row && (
            <div className={`text-xs relative`} ref={dropdownRef}>
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && "bottom-[15px]"
                }  absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb[2px]  pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-xs font-normal">Preview</p>
                </div>
                <div className="z-[99999] flex items-center gap-x-[10px] mb[2px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-xs font-normal">Print</p>
                </div>
                <div
                  className="z-[99999] flex items-center gap-x-[10px] mb[2px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                  onClick={toggleModal}
                >
                  <p className="font-xs font-normal">Download</p>
                </div>
                <div className="z-[99999] flex items-center gap-x-[10px] mb[2px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-xs font-normal">Share</p>
                </div>
                <div className="z-[99999] flex items-center gap-x-[10px] mb[2px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-xs font-normal">Export data</p>
                </div>
                <div className="z-[99999] flex items-center gap-x-[10px] mb[2px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p
                    className="font-xs font-normal"
                    onClick={togglePermissionModal}
                  >
                    Permission settings
                  </p>
                </div>
                <div className="border border-silver"></div>
                <div
                  className="flex items-center gap-x-[10px] mt[10px] pl-[10px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                  onClick={() => handleSelect(row)}
                >
                  <span></span>
                  <p className="text-[#DE1515]">Delete agreement</p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "3rem",
    },
  ];

  // const filteredData = data?.filter((row) =>
  //   row.AgreementName.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const handleRowSelect = (row) => {
    props.onAgreementClick();
    dispatch(getAgreementId(row?.id));
  };

  return (
    <>
      <Card className="rounded-[10px] mt-[24px]" style={{ width: "100%" }}>
        <div className="px-[24px] pb-[24px]">
          <Table
            columns={columns}
            data={data}
            pointer
            tableHeaderPink
            onRowClicked={handleRowSelect}
            progressPending={isLoading}
            progressComponent={<Loading />}
            className="mt-5"
          />
        </div>
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
      <DownloadModal isOpen={isModalOpen} onClose={toggleModal} />
      <PermissionModal
        isOpen={isPermissionModalOpen}
        onClose={togglePermissionModal}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        handleDeleteAgreement={handleDelete}
        onClose={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default AgreementTable;
