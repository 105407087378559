import TransitionOpacity from "shared/animation/TransitionOpacity";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllSupplierOpenMarketByStatus } from "appstate/openMarket/openMarketSlice";
import { AnimatePresence, motion } from "framer-motion";
import TopContestedInvoice from "features/openMarket/funder/components/TopContestedInvoice";
import FunderMetricModal from "features/openMarket/funder/components/modal/FunderMetricModal";

function RequestInvoice({ requestQuery }) {
  const dispatch = useDispatch();
  const [funderMetric, setFunderMetric] = useState(false);
  // const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      getAllSupplierOpenMarketByStatus({
        status: "Pending",
        search: requestQuery,
      })
    );
  }, [dispatch, requestQuery]);

  return (
    <TransitionOpacity className="flex flex-col gap-5 min-h-[75vh] rounded-[10px] bg-unusualGray  p-6">
      <div className="pt-4 pb-9 flex flex-col gap-4 bg-[#ffffff88] px-3 rounded-[5px]">
        <section className="flex flex-col lg:flex-row justify-between gap-4 min-h-fit rounded-b-[5px]">
          <div className="text-center flex flex-col gap-2.5 bg-primaryColor text-white w-full lg:w-fit py-[18px] px-16 rounded-b-[10px]">
            <span className="font-bold text-[53px]">53</span>
            <span>Invoices in the Open market</span>
          </div>

          <div className="rounded-[10px] w-full lg:w-[65%] h-full text-white">
            <header className="bg-secondaryColor rounded-t-[10px] px-6 py-4">
              OPEN MARKET
            </header>

            <div className="flex flex-col items-center md:flex-row justify-between py-7 px-6 text-black bg-[#fff]">
              <div className="flex flex-col gap-2">
                <span className="text-[#7A7A7A] text-sm">
                  Total Invoice Amount
                </span>
                <span className="font-bold text-xl ">N340,090,000</span>
              </div>

              <span className="py-4  px-6 font-bold">-</span>

              <div className="flex flex-col gap-2">
                <span className="text-[#7A7A7A] text-sm">
                  Total Amount in Lock Bids
                </span>
                <span className="font-bold text-xl ">N23,090,000</span>
              </div>

              <span className="py-4  px-6 font-bold">-</span>

              <div className="flex flex-col items-center gap-2">
                <span className="text-[#7A7A7A] text-sm">Total Funders</span>
                <span className="font-bold text-xl ">12</span>
              </div>
            </div>
          </div>
        </section>

        <section className="flex lg:flex-row items-center flex-col justify-between gap-6">
          <div className="flex px-3 rounded-b-[10px] lg:w-1/2 w-full flex-wrap bg-white border-t-2 border-t-[#f08000] flex-col pt-5 pb-4 gap-4">
            <div className="flex  flex-wrap justify-between">
              <span className="text-[20px] text-primaryColor">Invoices</span>
              <motion.button
                whileTap={{ scale: 0.9 }}
                className=" bg-[#f08000] text-white rounded-[5px] px-2 py-1"
              >
                Insight
              </motion.button>
            </div>

            <div className="flex">
              <div className="flex w-1/2 border-r border-primaryColor flex-col px-5">
                <span className="text-[40px] text-center font-bold text-primaryColor">
                  {13}
                </span>
                <span className=" text-center text-sm text-[#565656]">
                  Posted
                </span>
              </div>

              <div className="flex w-1/2 border-r border-primaryColor flex-col px-5">
                <span className="text-[40px] text-center font-bold text-secondaryColor">
                  {24}
                </span>
                <span className=" text-center text-sm text-[#565656]">
                  Bid Active
                </span>
              </div>

              <div className="flex w-1/2 px-5 flex-col">
                <span className="text-[40px] text-center font-bold text-[#F08000]">
                  {16}
                </span>
                <span className=" text-center text-sm text-[#565656]">
                  Locked
                </span>
              </div>
            </div>
          </div>

          <div className="rounded-[10px] overflow-x-clip w-full lg:w-[50%] h-full text-white">
            <header className="flex justify-between items-center bg-primaryColor rounded-t-[10px] px-6 py-4">
              <span>TOP PERFORMING FUNDER</span>
              <motion.button
                whileTap={{ scale: 0.96 }}
                onClick={() => setFunderMetric(true)}
                className="bg-[#F9F9F9] p-2.5 text-sm text-[#222] font-medium rounded "
              >
                View Details
              </motion.button>
            </header>

            <div className="flex flex-col items-center md:flex-row justify-between py-7 px-6 text-black bg-[#fff]">
              <div className="flex flex-col gap-2">
                <span className="text-[#7A7A7A] text-sm">
                  Total Invoice Amount
                </span>
                <span className="font-bold text-xl ">N340,090,000</span>
              </div>

              <span className="py-4  px-6 font-bold">-</span>

              <div className="flex flex-col gap-2">
                <span className="text-[#7A7A7A] text-sm">
                  Total Amount in Lock Bids
                </span>
                <span className="font-bold text-xl ">N23,090,000</span>
              </div>

              <span className="py-4  px-6 font-bold">-</span>

              <div className="flex flex-col items-center gap-2">
                <span className="text-[#7A7A7A] text-sm">Total Funders</span>
                <span className="font-bold text-xl ">12</span>
              </div>
            </div>
          </div>
        </section>

        <TopContestedInvoice />
      </div>

      <AnimatePresence>
        {funderMetric && (
          <FunderMetricModal onClose={() => setFunderMetric(false)} />
        )}
      </AnimatePresence>
    </TransitionOpacity>
  );
}

export default RequestInvoice;
