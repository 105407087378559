import React, { useState, useEffect } from "react";
import { PAIconVieweye } from "assets/images/svgs";
import TableHeaderTrans from "features/transactions/components/TableHeaderTrans";
import Table from "layout/table/Table";
import Loading from "shared/Loading";
import TransactionModal from "features/transactions/transactionDashboard/TransactionModal";
import TransactionsModal from "features/transactions/modal/tablemodal";

const Transactions = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  const dummyTransactions = [
    {
      transactionId: "TXN001",
      funderFullName: "John Doe",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-01",
      currency: "USD",
      amount: "+9,000,000",
      status: "SUCCESSFUL",
    },
    {
      transactionId: "TXN002",
      funderFullName: "Jane Smith",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-02",
      currency: "USD",
      amount: "+4,000,000",
      status: "FAILED",
    },
    {
      transactionId: "TXN003",
      funderFullName: "Alice Johnson",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-03",
      currency: "USD",
      amount: "+2,000,000",
      status: "PENDING",
    },
    // Add more dummy data as needed
  ];

  useEffect(() => {
    setData(dummyTransactions);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //   function handleViewMore(transactionId) {
  //     setSelectedTransactionId(transactionId);
  //   }

  const closeModal = () => {
    setModalOpen(false);
  };

  const options = [
    { id: 1, name: "All" },
    { id: 2, name: "Open" },
    { id: 3, name: "Fulfilled" },
    { id: 4, name: "Cancelled" },
    { id: 5, name: "Closed" },
  ];

  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: "Funder",
      selector: (row) => row.funderFullName,
      sortable: true,
    },
    {
        name: "Invoice Date",
        selector: (row) => row.invoiceDate,
        sortable: true,
      },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.amount === "+9,000,000" ? (
          <p className="text-success">{row.amount}</p>
        ) : row.amount === "+4,000,000" ? (
          <p className="text-red-600">{row.amount}</p>
        ) : (
          <p>{row.amount}</p>
        ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "FAILED" ? (
          <p className="text-red-600">{row.status}</p>
        ) : row.status === "PENDING" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
    //   name: "Details",
      selector: (row) => row.view,
      cell: (row) => (
        <div className="flex">
          <PAIconVieweye height="15px" width="30px" />
          <p className="text-[#626679] cursor-pointer">View</p>
        </div>
      ),
    },
  ];

  return (
    <>
      <TransactionsModal isShown={modalOpen} onClose={closeModal} />
      <div className="bg-white h-auto rounded-[5px]">
        <TableHeaderTrans
          options={options}
          transactions={true}
          className="mt-5"
        />
        <Table
          columns={columns}
          data={data}
          pointer
          className="mt-5 px-4"
          //   selectableRows
          //   onRowClicked={() => setModalOpen(true)}
          progressPending={false}
          progressComponent={<Loading />}
        />
        {selectedTransactionId && (
          <TransactionModal
            transactionId={selectedTransactionId}
            onClose={() => setSelectedTransactionId(null)}
          />
        )}
      </div>
    </>
  );
};

export default Transactions;
