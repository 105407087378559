import React from "react";
import Heading from "./Heading";

const OnboardingLayout = ({ children, cancel, nohelp }) => {
  return (
    <div className="min-h-screen pb-10 flex flex-col">
      <Heading cancel={cancel} />
      <div className="flex flex-1 h-full w-full md:justify-center flex-col overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default OnboardingLayout;
