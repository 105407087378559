import Card from 'shared/Card'
import Controls from './Controls'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import NewInvoiceTable from './NewInvoiceTable'
import DashboardLayout from 'layout/dashboardlayout'

const NewInvoiceDashboardContents = () => {
  const location = useLocation()
  let isEditing = location.state ? location.state.editing : false;
  const singleInvoice = location.state ? location.state.singleInvoice : false;

  const [editing, setEditing] = useState(isEditing)
  const [controls, setControls] = useState({
    to: singleInvoice ? singleInvoice.buyerId : "",
    dueDate: singleInvoice ? singleInvoice.dueDate : "",
    currency: singleInvoice ? singleInvoice.currency : "",
    account: singleInvoice ? singleInvoice.account : "",
    taxInclusive: singleInvoice ? singleInvoice.taxInclusive : true
  })
  const [toggleTaxInclusive, setToggleTaxInclusive] = useState(controls.taxInclusive)

  return (
    <DashboardLayout section="Invoice" subHeader={`${editing ? "Edit" : "Create an"} invoice`}>
      <Card className="rounded-md">
        <div className='bg-[#FAFAFA] rounded-[10px] px-[25px] py-[10px]'>
          <Controls
            singleInvoice={singleInvoice}
            setEditing={setEditing}
            controls={controls}
            setControls={setControls}
            editing={editing}
            toggleTaxInclusive={toggleTaxInclusive}
            setToggleTaxInclusive={setToggleTaxInclusive}
          />
        </div>
        <NewInvoiceTable
          singleInvoice={singleInvoice}
          controls={controls}
          setControls={setControls}
          editing={editing}
          setEditing={setEditing}
          toggleTaxInclusive={toggleTaxInclusive}
        />
      </Card>
    </DashboardLayout>
  )
}

export default NewInvoiceDashboardContents