import {
  allInventories,
  filterProductByStatus,
  removeProduct,
  searchInventories,
} from 'appstate/inventory/inventorySlice';
import { PAIconDelete } from 'assets/images/svgs';
import DashboardLayout from 'layout/dashboardlayout';
import Table from 'layout/table/Table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteModal from 'shared/DeleteModal/Modal';
import Loading from 'shared/Loading';
import Pagination from 'shared/Pagination';
import TableHeader from 'shared/tableHeader/TableHeader';
import Image from './components/Image';

const InventoryContents = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [productName, setProductName] = useState('');
  const [getId, setGetId] = useState(null);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState('');
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const onRowClicked = (row) => {
    const id = row?.id;
    let path = `product/${id}`;
    navigate(path, { state: id });
  };
  useEffect(() => {
    dispatch(allInventories({ page, pageSize }));
  }, [page, pageSize, dispatch]);
  const { inventories, filterData, isLoading } = useSelector(
    (state) => state?.inventory
  );
  const [inventoryDatas, setInventoriesDatas] = useState(inventories?.data);
  const [filter, setFilter] = useState(false);

  useEffect(() => {
    setInventoriesDatas(inventories?.data);
    if (filter) {
      setInventoriesDatas(filterData?.data);
    }
  }, [inventories, filterData, filter, productName]);
  const handleChange = (e) => {
    if (e.target.value !== 'All') {
      dispatch(filterProductByStatus({ status: e.target.value }));
      setStatus(e.target.value);
    }
    setFilter(true);
    if (e.target.value === 'All') {
      setFilter(false);
    }
  };
  const totalPage = filter === false ? inventories : filterData;
  const totalPages = totalPage?.meta?.totalElements;

  const select = (id) => {
    setGetId(id);
    setDeleteModal(true);
  };

  const handleDelete = () => {
    const body = {
      id: getId,
    };
    dispatch(removeProduct(body)).then(() => {
      setDeleteModal(false);
      dispatch(allInventories({ page, pageSize }));
      if (status) {
        dispatch(filterProductByStatus({ status: status }));
      }
    });
  };
  const handleSearch = () => {
    dispatch(searchInventories({ productName })).then((data) => {
      setInventoriesDatas(data?.payload?.data);
    });
  };
  const INVENTORYTABLECOLUMN = [
    {
      name: 'Image',
      selector: (row) => row?.imageUrl,
      cell: (row) => (
        <Image
          className="w-[51px] h-[34px] object-contain"
          src={row.imageUrl[0]}
        />
      ),
      width: '5rem',
    },
    {
      name: 'SKU',
      selector: (row) => row?.productSku,
    },
    {
      name: 'Product Name',
      selector: (row) => row?.productName,
    },
    {
      name: 'Unit',
      selector: (row) => row?.unit,
    },
    {
      name: 'Unit Price',
      selector: (row) => row?.unitPrice,
    },
    {
      name: 'Active',
      selector: (row) => row.status,
      cell: (row) =>
        row.status === 'Active' ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === 'Deactivated' ? (
          <p className="text-red-600">{row.status}</p>
        ) : row.status === 'Unavailable' ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      name: 'Action',
      cell: (row) => (
        <div
          className=" flex justify-center w-[40px] h-full items-center"
          onClick={() => select(row.id)}
        >
          <PAIconDelete title="Delete" />
        </div>
      ),
    },
  ];

  const totalProducts = inventoryDatas?.length;

  let path = 'createproduct';

  const options = [
    {
      id: 1,
      name: 'All',
    },
    {
      id: 2,
      name: 'Active',
    },
    {
      id: 3,
      name: 'Unavailable',
    },
    {
      id: 4,
      name: 'Deactivated',
    },
  ];

  return (
    <DashboardLayout section="Inventory">
      <DeleteModal
        isShown={deleteModal}
        handleDelete={handleDelete}
        isLoading={isLoading}
        onClose={() => setDeleteModal(false)}
        warning="Warning: This will delete this product permanently from your PayEdge. This
        action cannot be undone"
      />
      <div className="bg-white p-[24px] rounded-[5px]">
        <TableHeader
          total={`${totalProducts} Products`}
          handleChange={handleChange}
          setProductName={setProductName}
          productName={productName}
          search={handleSearch}
          path={path}
          options={options}
        />
        <Table
          columns={INVENTORYTABLECOLUMN}
          data={inventoryDatas}
          selectableRows
          onRowClicked={onRowClicked}
          pointer
          progressPending={!deleteModal && isLoading}
          progressComponent={<Loading />}
          tableHeader
          className="mt-5"
        />
        <div className="flex justify-center mt-[20px]">
          {isLoading || inventoryDatas?.length < 20 ? null : (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default InventoryContents;
