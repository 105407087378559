import React, { useEffect, useState } from "react";
import {
  PAIconDownload,
  PAIconDropdown,
  PAIconFilter,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import { useDispatch, useSelector } from "react-redux";
import { allPaymentExtension } from "appstate/paymentExtension/paymentExtensionSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";

const SingleExtensionRequest = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { paymentExtensionData, isLoading } = useSelector(
    (state) => state?.paymentExtension
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const handleSearch = () => {
    dispatch(allPaymentExtension({ page, pageSize, search: searchQuery }));
  };

  useEffect(() => {
    dispatch(
      allPaymentExtension({
        page,
        pageSize,
        search: searchQuery,
        bulk: "false",
        status: "",
      })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSingle = () => {
    let path = "/payment-extension/singleextensioninvoicelist";
    navigate(path);
  };

  const singleDetails = (id, status) => {
    // let path = "/collections/recievables";
    let path = "";

    switch (status) {
      case "Updated":
        path = `/payment-extension/single/pending/${id}`;
        break;
      case "Approved":
        path = `/payment-extension/single/approved/${id}`;
        break;
      case "Pending":
        path = `/payment-extension/single/pending/${id}`;
        break;
      case "Rejected":
        path = `/payment-extension/single/rejected/${id}`;
        break;
      default:
        break;
    }

    navigate(path);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  // FILTER
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const columns = [
    {
      name: "Request Ref No",
      selector: (row) => row.reference,
      sortable: true,
    },
    {
      name: "Funder",
      selector: (row) => row.funderCompanyName,
      sortable: true,
    },
    {
      name: "Created",
      selector: (row) => row.createdDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(parseFloat(row.invoiceAmount))}</p>
      ),
    },
    {
      name: "Interest Amount",
      selector: (row) => row.interestAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(1000)}</p>
      ),
    },
    {
      name: "Interest Rate",
      selector: (row) => row.discountRate,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Approved" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Rejected" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "Updated" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "Pending" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          {/* FILTER */}
          <div>
            <div
              className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
              onClick={handleDropdownToggle}
            >
              Filter{" "}
              {selectedFilters.length > 0 && (
                <span className="bg-gray rounded-lg p-1 px-2">
                  {selectedFilters.length}
                </span>
              )}{" "}
              Selected <PAIconDropdown />
            </div>
            {isDropdownOpen && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                <label className="block">
                  <input
                    type="checkbox"
                    value="date"
                    checked={selectedFilters.includes("date")}
                    onChange={handleFilterChange}
                  />
                  Date
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="name"
                    checked={selectedFilters.includes("name")}
                    onChange={handleFilterChange}
                  />
                  Name
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="currency"
                    checked={selectedFilters.includes("currency")}
                    onChange={handleFilterChange}
                  />
                  Currency
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="amount"
                    checked={selectedFilters.includes("amount")}
                    onChange={handleFilterChange}
                  />
                  Amount
                </label>
              </div>
            )}
          </div>

          {/* EXPORT */}
          <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for request"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              <PAIconFilter /> Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!paymentExtensionData?.data?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                List of all single payment extension request you've made
              </p>
              <div className="flex items-center justify-center gap-3">
                <button
                  className="bg-secondaryColor py-2 px-4 rounded text-white"
                  onClick={handleSingle}
                >
                  Create single request
                </button>
              </div>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-black text-lg font-bold">
                  Single extension table
                </p>
                <span className="text-xs font-thin">
                  List of all single payment extension request you've made
                </span>
              </div>

              <div className="flex items-center justify-center gap-3">
                <button
                  className="bg-secondaryColor py-2 px-4 rounded text-white"
                  onClick={handleSingle}
                >
                  Create single request
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <Table
                columns={columns}
                data={paymentExtensionData?.data}
                // onRowClicked={(row) => receivablesDetails(row.invoiceId)}
                // onRowClicked={(row) => singleDetails(row.invoiceIds)}
                onRowClicked={(row) => singleDetails(row.invoiceIds[0], row.status)}
                progressPending={isLoading}
                progressComponent={<Loading />}
                pointer
                tableHeader
                className="mt-5"
              />
              <div className="flex justify-center gap-x-[8px] mt-[20px]">
                {paymentExtensionData?.data?.length > 0 && (
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={paymentExtensionData?.meta?.totalElements}
                  />
                )}
              </div>
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default SingleExtensionRequest;
