import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";

const DateRangePickerModal = ({ isOpen, onClose, onApply }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isSelectingStartDate, setIsSelectingStartDate] = useState(true);

  const handleDateChange = (date) => {
    if (isSelectingStartDate) {
      setStartDate(date);
      if (endDate && date > endDate) {
        setEndDate(null);
      }
    } else {
      setEndDate(date);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="absolute top-[540%] inset-0 bg-gray-500 z-[9999] bg-opacity-75 flex items-center justify-center">
      <div className="bg-white py-6 px-4 rounded-lg overflow-hidden shadow-2xl border border-primaryColor transform transition-all sm:max-w-96 sm:w-full">
        <div className="bg-white p6">
          <h2 className="text-lg font-medium text-gray-900 mb-4">
            Select Date Range
          </h2>
          <div className="flex items-center flex-col space-y-4">
            <div>
              <DatePicker
                selected={isSelectingStartDate ? startDate : endDate}
                onChange={handleDateChange}
                selectsStart={isSelectingStartDate}
                startDate={startDate}
                endDate={endDate}
                minDate={isSelectingStartDate ? null : startDate}
                className="p2 border rounded w-full mt-2"
                inline
              />
            </div>
            <div className="flex text-xs gap-2 ">
              <label>
                From:
                <input
                  type="text"
                  value={startDate ? startDate.toLocaleDateString() : ""}
                  onFocus={() => setIsSelectingStartDate(true)}
                  className="p-2 text-sm border-b w-fit outline-none"
                  placeholder="Start Date"
                  // readOnly
                />
              </label>

              <label className="">
                To:
                <input
                  type="text"
                  value={endDate ? endDate.toLocaleDateString() : ""}
                  onFocus={() => startDate && setIsSelectingStartDate(false)}
                  className={`p-2 text-sm border-b w-fit outline-none ${
                    !startDate ? "bg-gray-200 cursor-not-allowed" : ""
                  }`}
                  placeholder="End Date"
                  // readOnly
                  disabled={!startDate}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 py-4 px16 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-secondaryColor text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => onApply({ startDate, endDate })}
          >
            Apply Filter
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateRangePickerModal;
