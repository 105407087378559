import Table from "layout/table/Table";
import React, { useState } from "react";
import Button from "shared/Button";
import LockModal from "./lockModal";
import toast from "react-hot-toast";
import { PAIconConfirm, PAIconMDISuccess } from "assets/images/svgs";

const Bidinvoice = () => {
  const [open, setOpen] = useState(false);
  const column = [
    {
      name: "Name",
      selector: (row) => row?.funderName,
    },
    {
      name: "Company",
      selector: (row) => row?.amountBid,
    },
    {
      name: "Email",
      selector: (row) => row?.date,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Button
            neutral
            onClick={() => setOpen(true)}
            className="flex border border-primaryColor text-sm justify-center gap-2 bg-white text-primaryColor rounded-[5px] items-center px-2.5 py-1.5 "
          >
            lock
          </Button>
        </div>
      ),
    },
  ];
  const data = [
    {
      funderName: "ABC Limited",
      amountBid: "9,000,000",
      date: "11-02-2024",
    },
  ];

  function handleLockInvoice() {
    setOpen(false);
    toast.custom(
      <div className="flex mt-[200px]">
        <div className="bg-primaryColor w-[147.791px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
          <PAIconMDISuccess />
        </div>
        <div className="bg-white flex flex-col justify-center items-center w-[394.538px] h-[160px] rounded-r-[7px] shadow-lg">
          <PAIconConfirm />
          <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
            The invoice has been locked
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-[10px] pl-[24px] pt-[32px]">
      <LockModal
        handleLock={handleLockInvoice}
        onClose={() => setOpen(false)}
        isShown={open}
      />
      <h1 className="text-black text-[20px] font-bold ">Bid Invoice</h1>
      <h2 className="text-black text-base font-medium">
        Bid on invoice from Funders
      </h2>
      <div>
        <Table
          columns={column}
          data={data}
          pointer
          tableHeader
          className="mt-5"
        />
      </div>
    </div>
  );
};

export default Bidinvoice;
