import NotificationHeader from "./components/NotificationHeader";
import NotificationContent from "./components/NotificationContent";

const NotificationSetting = () => {
  return (
    <div className='h-full flex flex-col gap-5 px-6 pt-6'>
      <NotificationHeader />
      <NotificationContent />
    </div>
  );
};

export default NotificationSetting;
