import request from 'apiInstance';
import toast from 'react-hot-toast';

export const createInventory = async (data) => {
  try {
    const formData = new FormData();
    data.image.forEach((item) => {
      formData.append('image', item);
    });
    for (const [key, value] of Object.entries(data.body)) {
      formData.append(key, value);
    }
    const response = await request({
      method: 'post',
      url: '/api/v1/inventory/create',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const getAllInventories = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: 'get',
      url: `api/v1/inventory?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const searchInventoriesByName = async ({ productName }) => {
  try {
    const response = await request({
      method: 'get',
      url: `/api/v1/inventory/search?name=${productName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const filterByStatus = async ({ status }) => {
  try {
    const response = await request({
      method: 'get',
      url: `api/v1/inventory/status/${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const deleteProduct = async ({ id }) => {
  try {
    const response = await request({
      method: 'delete',
      url: `/api/v1/inventory/delete/${id}`,
    });
    const deletedItem = response;
    if (deletedItem) {
      toast.success('Product deleted successfully!');
    }
    return deletedItem?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const updateProduct = async (data) => {
  try {
    const formData = new FormData();
    data.image.forEach((item) => {
      formData.append('image', item);
    });
    for (const [key, value] of Object.entries(data.body)) {
      formData.append(key, value);
    }
    const response = await request({
      method: 'put',
      url: `/api/v1/inventory/update/${data.productId}`,
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: formData,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};
