import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/Button';
import InputField from 'shared/InputField';
import OnboardingLayout from 'features/onboarding/category/components/OnboardingLayout';
import PasswordLength from 'shared/PasswordLength';


const BuyerOnboardSupplierSignup = () => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
    let path = '/buyer-onboard-supplier/sign-in';
    navigate(path);
  };
  return (
    <OnboardingLayout>
      <div className="pt-[100px] grid place-content-center h-[100%] overflow-auto md:pt-0 lg:pt-[100px]">
        <h1 className="font-semibold text-[28px] text-primaryColor text-center">
          Welcome to PayEdge
        </h1>
        <p className="font-semibold text-[20px] text-textColor mt-[35px] text-center">
          Before you get started
        </p>
        <p className="font-normal text-sm text-textColor mt-[10px] text-center mb-[35px]">
          Please create an account below
        </p>
        <form action="" className="flex justify-center flex-col">
          <InputField
            className="w-[300px] py-[13px] outline-none mb-[30px] md:w-[424px]"
            placeholder="exampleforyou@info.com"
            label="Email Address"
          />
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px]"
              placeholder="........"
              label="Password"
            />
            <div className="flex justify-between mt-[10px]">
              <PasswordLength />
              <PasswordLength />
              <PasswordLength />
              <PasswordLength />
            </div>
          </div>
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="........"
              label="Confirm Password"
            />
            <div className="flex justify-between mt-[10px]">
              <PasswordLength />
              <PasswordLength />
              <PasswordLength />
              <PasswordLength />
            </div>
          </div>
          <Button
            onClick={handleClick}
            auth
            className="mb-[30px] w-full md:w-[424px]"
          >
            Create Account
          </Button>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default BuyerOnboardSupplierSignup;
