import { PAIconFilterIcon } from "assets/images/svgs";

const TableHeaderSelectTrans = ({
  label,
  htmlFor,
  onChange,
  disabledSelect,
  options,
}) => {
  return (
    <div className="w-full flex flex-col justify-center">
      {label && (
        <label
          htmlFor={htmlFor}
          className="font-medium text-sm text-textColor mb-[2px]"
        >
          {label}
        </label>
      )}
      <div className="relative flex items-center justify-center">

        <div className="flex gap-2 pr-2">
          <PAIconFilterIcon className="my-auto"/>
          Filter Transactions:
        </div>



        <select
          disabled={disabledSelect}
          onChange={onChange}
          defaultValue={'default'}
          className="appearance-none w-[180px] h-[40px] pl-3 font-medium text-sm text-gray-500 bg-white rounded-[5px] border border-[#CCCCCC] outline-none focus:outline-none"
        >
          <option value="default" disabled>
            filter by..
          </option>
          {options.map((option) => (
            <option key={option.id}>{option.name}</option>
          ))}
        </select>

        <div className="absolute inset-y-0 right-0 flex items-center px-2 top-[8px] pointer-events-none">
          <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
            <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default TableHeaderSelectTrans;
