import { motion } from "framer-motion";

function TransitionOpacity({ children, className }) {
  return (
    <motion.div
      className={className}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        delay: 0.2,
      }}
    >
      {children}
    </motion.div>
  );
}

export default TransitionOpacity;
