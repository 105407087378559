import { setExternalAccountFormData } from "appstate/wallet/walletSlice";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import Spinner from "shared/Spinner/Spinner";
import { externalAccountValidationSchema } from "validation/wallet/externalAccount";

const LinkExternalAccountFormModal = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      currency: "",
      bankName: "",
      accountNumber: "",
      accountHolderName: "",
      bvn: "",
    },
    validationSchema: externalAccountValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(setExternalAccountFormData(values));
      setSubmitting(false);
      onSubmit(values);
    },
  });

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white p5 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Link External Account
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        {/* FORM */}
        <div>
          <form className="w-full px-7" onSubmit={formik.handleSubmit}>
            <p className="text-[#7A7A7A] text-sm font-normal mb-6">Step 1</p>
            <p className="mb-4 text-[#222] text-sm font-bold">
              Account Information
            </p>
            <div className="mb-4">
              <label className="text-[#222] text-sm font-normal mb-2">
                Currency
              </label>
              <select
                id="currency"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.currency}
                className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
              >
                <option value="">Select Currency</option>
                <option value="NGN">NGN</option>
                <option value="USD">USD</option>
              </select>
              {formik.touched.currency && formik.errors.currency ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.currency}
                </p>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="text-[#222] text-sm font-normal mb-2">
                Bank Name
              </label>
              <input
                type="text"
                id="bankName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bankName}
                className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
              />
              {formik.touched.bankName && formik.errors.bankName ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.bankName}
                </p>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="text-[#222] text-sm font-normal mb-2">
                Bank Account Number
              </label>
              <input
                type="number"
                id="accountNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.accountNumber}
                className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
              />
              {formik.touched.accountNumber && formik.errors.accountNumber ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.accountNumber}
                </p>
              ) : null}
            </div>
            {formik.values.bankName && formik.values.accountNumber ? (
              <div className="mb-4">
                <label className="text-[#222] text-sm font-normal mb-2">
                  Account Holder Name
                </label>
                <input
                  type="text"
                  id="accountHolderName"
                  value={formik.values.accountHolderName}
                  className="border text-sm w-full bg-gray500 py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
                  readOnly
                />
              </div>
            ) : null}

            <div className="mb-4">
              <label className="text-[#222] text-sm font-normal mb-2">
                BVN (Bank Verification Number)
              </label>
              <input
                type="number"
                id="bvn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bvn}
                className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
              />
              <p className="text-orange-600 text-xs mt-[8px]">
                Enter BVN associated with this account for verification purposes
              </p>
              {formik.touched.bvn && formik.errors.bvn ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.bvn}
                </p>
              ) : null}
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-10">
              <div className="w-[105px] h-[35px] px-6 py-2 rounded-[5px] justify-center items-center gap-2.5 inline-flex mx-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="text-center text-gray text-base font-normal tracking-wide"
                >
                  Cancel
                </button>
              </div>
              <div className="w-[105px] h-[35px] px-6 py-2 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]">
                <button
                  className="text-center text-white text-base font-normal tracking-wide"
                  type="submit"
                  disabled={formik.isSubmitting ? true : null}
                >
                  {formik.isSubmitting ? <Spinner /> : "Next"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LinkExternalAccountFormModal;
