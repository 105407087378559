import { allVirtualCards, deleteVirtualCard } from "appstate/wallet/walletSlice";
import { PAIconAlert24 } from "assets/images/svgs";
import React from "react";
import { useDispatch } from "react-redux";

const DeleteCardModal = ({ isOpen, onClose, onConfirm, cardId }) => {
  const dispatch = useDispatch();

  const handleDeleteConfirm = () => {
    dispatch(deleteVirtualCard({ cardId })).then((data) => {
      if (data?.meta?.requestStatus === "fulfilled") {
        dispatch(allVirtualCards());
      }
    });
    onClose();
    onConfirm();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center">
        <PAIconAlert24 />
        <p className="text-[#979797] text-center font-sans text-base font-normal leading-normal tracking-wide mb-3 my-5">Are you sure you want to delete this card?</p>
        <div className="flex justify-center">
          <button
            className="px-4 py-2 mr-5 bg-[#07593D] text-white rounded-md"
            onClick={handleDeleteConfirm}
          >
            Yes
          </button>
          <button
            className="px-4 py-2 bg-red-500 border border-red text-red rounded-md"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteCardModal;
