import React from "react";

const PermissionModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg'>
      <div className='bg-white p-5 px5 rounded-md max-w-xs md:max-w-md w-full'>
        <div className='flex justify-between items-center mb-4'>
          <p className='text-black font-sans text-base font-semibold'>
            Agreement Permissions
          </p>
          <button
            onClick={onClose}
            className='text-black font-sans text-xl cursor-pointer flex justify-center items-center'
          >
            &times; <span className='text-sm mx-2 mt-1'>Close</span>
          </button>
        </div>
        <div className='border border-gray my-5'></div>

        {/* FILE BUTTONS */}
        <div className='w-full'>
          <div className=''>
            {/* First Div */}
            <div className='flex items-center mb'>
              <input
                type='checkbox'
                id='updateAgreement'
                className='mr-2 '
                defaultChecked
              />

              <label
                htmlFor='updateAgreement'
                className='text-Black font-SFProDisplay text-base font-medium'
              >
                Give all parties access to update agreement{" "}
              </label>
            </div>
            <p className='text-[#7A7A7A] font-SFProDisplay text-xs font-normal ml-5'>
              Only initiating party would have update access if left unchecked{" "}
            </p>

            {/* Second Div */}
            <div className='flex items-center mt-2'>
              <input
                type='checkbox'
                id='editAgreement'
                className='mr-2'
                defaultChecked
              />{" "}
              <label
                htmlFor='editAgreement'
                className='text-Black font-SFProDisplay text-base font-medium'
              >
                Give all parties access to edit agreement details
              </label>
            </div>
            <p className='text-[#7A7A7A] font-SFProDisplay text-xs font-normal ml-5'>
              Only initiating party would have edit access if left unchecked
            </p>
          </div>
        </div>
        {/* DOWNLOAD BUTTON */}
        <div className='mt-4'>
          <button className='px-4 py-2 bg-[#07593D] text-white rounded-md my-3'>
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default PermissionModal;
