import { PAIconInventoryArrowLeft, PAIconFilterIcon } from "assets/images/svgs";
import React from "react";
import { useNavigate } from "react-router-dom";

const TableHeaderTrans = ({
  total,
  path,
  buyer,
  title,

  options,
  transactions,
}) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(path);
  };
  return (
    <div className="mb-[33px]    bg-[#FAFAFA] rounded-t-md">
      {buyer && (
        <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
      )}
      {transactions && (
        <div className="flex items-center justify-between pt-4  ">
          <div className="flex-col pl-[20px] ">
            <p className="font-bold">All Transactions</p>
            <p className="text-[13px] text-[#626679]">Transactions summary</p>
          </div>
          <div className="mr-[20px] ">
            <div className="w-full flex flex-col justify-center">
              <label
                htmlFor={""}
                className="font-medium text-sm text-textColor mb-[2px]"
              ></label>

              <div className="relative flex items-center justify-center">
                <div className="flex gap-2 pr-2">
                  <PAIconFilterIcon className="my-auto" />
                  Filter Transactions:
                </div>

                <select
                  disabled={false}
                  onChange={() => {}}
                  defaultValue={"default"}
                  className="appearance-none w-[180px] h-[40px] pl-3 font-medium text-sm text-gray-500 bg-white rounded-[5px]  outline-none focus:outline-none"
                >
                  <option value="default" disabled>
                    filter by..
                  </option>
                  {options.map((option) => (
                    <option key={option.id}>{option.name}</option>
                  ))}
                </select>

                <div className="absolute inset-y-0 right-0 flex items-center px-2 top-[8px] pointer-events-none">
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                    <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                  </svg>
                </div>
              </div>
            </div>

            {/* ENDS HERE */}
          </div>
        </div>
      )}
      <div className="md:flex justify-between items-center  pb-[12px]">
        <p className="mb-[10px] text-black text-sm font-medium md:mb-0">
          {total}
        </p>
        {buyer && (
          <p className="text-sm font-medium mb-[10px] md:mb-0">{title}</p>
        )}
      </div>
    </div>
  );
};

export default TableHeaderTrans;
