import InputField from 'shared/InputField';
import { currencies } from 'utilities/Currencies';
import InvoiceSelect from '../components/InvoiceSelect';
import { useState, useRef } from 'react';
import ArrowDown from "../../../assets/images/svgs/Vector.svg";
import BuyerModal from './BuyerModal';

const Controls = ({
  toggleTaxInclusive,
  setToggleTaxInclusive,
  editing,
  controls,
  setControls,
  singleInvoice,
}) => {
  const inputClassName = "rounded-[5px] border !border-[#CCCCCA] px-4 py-2 focus:outline-none w-full h-[51px] box-border";
  const selectClassName = "rounded-[5px] border !border-[#CCCCCA] px-4 py-2 focus:outline-none w-full h-[51px] box-border";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBuyer, setSelectedBuyer] = useState(null);
  const inputRef = useRef(null);

  const optionsOne = [
    { id: 1, label: 'Praise Akubundu', value: 'Praise Akubundu' },
    { id: 2, label: 'Praise Akubundu', value: 'Praise Akubundu' },
    { id: 3, label: 'Praise Akubundu', value: 'Praise Akubundu' },
    { id: 4, label: 'Praise Akubundu', value: 'Praise Akubundu' },
    { id: 5, label: 'Praise Akubundu', value: 'Praise Akubundu' },
  ];

  const Accounts = [
    { id: 1, label: 'Select Account', value: '' },
    { id: 2, label: '920201010211 - Praise Akubundu', value: 'Praise Akubundu' },
    { id: 3, label: '938389299272 - Praise James', value: 'Praise James' },
  ];

  const options = [
    { id: 1, title: 'Tax Inclusive', value: true },
    { id: 2, title: "Not Inclusive", value: false },
  ];

  const handleSelectBuyer = (buyer) => {
    setSelectedBuyer(buyer);
    setIsModalOpen(false);
  };

  return (
    <div className="relative">
      <div className='flex max-md:flex-col justify-between pb-[30px] gap-2 border-b'>
        <div className="flex flex-col relative w-full">
          <label className="font-medium text-sm mb-1">To</label>
          <div className="flex items-center border rounded-lg overflow-hidden relative border-[#DADAD9] w-full h-[51px]">
            <input
              type="text"
              placeholder='Select buyer'
              className={selectClassName}
              value={selectedBuyer?.label || ''}
              readOnly
              ref={inputRef}
            />
            <img
              src={ArrowDown}
              alt='Arrow Down'
              className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={() => setIsModalOpen(!isModalOpen)}
            />
          </div>
          {isModalOpen && (
            <BuyerModal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              buyers={optionsOne}
              onSelectBuyer={handleSelectBuyer}
            />
          )}
        </div>
        <div className="flex flex-col w-full">
          <label className="font-medium text-sm mb-1">Date</label>
          <InputField 
            type="date" 
            className={inputClassName} 
            value={controls.date || ''} 
            onChange={(e) => setControls({ ...controls, date: e.target.value })}
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="font-medium text-sm mb-1">Due Date</label>
          <InputField 
            type="date" 
            className={inputClassName} 
            value={controls.dueDate || ''} 
            onChange={(e) => setControls({ ...controls, dueDate: e.target.value })}
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="font-medium text-sm mb-1">Invoice</label>
          <InputField 
            type="text" 
            className={inputClassName} 
            value={controls.invoice || ''} 
            onChange={(e) => setControls({ ...controls, invoice: e.target.value })}
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="font-medium text-sm mb-1">Reference</label>
          <InputField 
            type="text" 
            className={inputClassName} 
            value={controls.reference || ''} 
            onChange={(e) => setControls({ ...controls, reference: e.target.value })}
          />
        </div>
      </div>
      <div className='flex max-lg:flex-col justify-between py-[10px] mt-[10px]'>
        <div className='flex'>
          <div className='min-w-[230px]'>
            <InvoiceSelect 
              defaultValue={singleInvoice?.currency} 
              onChange={(e) => { setControls({ ...controls, currency: e.target.value }) }} 
              options={currencies} 
              optionsHolder="Currency"
              height="51px" 
            />
          </div>
          <div className='min-w-[230px] pl-3'>
            <InvoiceSelect 
              defaultValue={singleInvoice?.account} 
              onChange={(e) => { setControls({ ...controls, account: e.target.value }) }} 
              options={Accounts} 
              optionsHolder="Account"
              height="51px" 
            />
          </div>
        </div>
        <div className='flex flex-wrap items-center gap-1 max-lg:mt-[10px]'>
          <p className='font-medium text-sm whitespace-nowrap pr-[3px]'>Amounts are</p>
          <div className='flex flex-1 min-w-[130px]'>
            <InvoiceSelect 
              disabledSelect={editing} 
              defaultValue={singleInvoice?.taxInclusive} 
              onChange={(e) => { setToggleTaxInclusive(!toggleTaxInclusive); setControls({ ...controls, taxInclusive: e.target.value }) }} 
              options={options} 
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Controls;