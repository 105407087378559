import React from 'react'
import Headers from '../headers'
import DirectFundingTable from './Table'

const DirectFundingRequest = () => {
  return (
    <div>
      <Headers />
      <DirectFundingTable />
    </div>
  )
}

export default DirectFundingRequest