import OnboardingLayout from "features/onboarding/category/components/OnboardingLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import PhoneNumber from "shared/PhoneNumber";

const BuyerOnboardSupplierCompanydetails = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = (e) => {
    e.preventDefault();
    if (checked) {
      let path = "/buyer-onboard-supplier/terms";
      navigate(path);
    }
  };
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  return (
    <OnboardingLayout>
      <div className="pt-[60px] flex items-center flex-col h-[100%] overflow-auto md:pt-[30px]">
        <div className="mb-[50px] mt-[30px] flex justify-center items-center gap-x-[20px]">
          <div className="relative bottom-[28px] md:bottom-[30px]">
            <p className="font-normal text-xs text-textColor mb-[10px] md:text-sm">
              Company Details
            </p>
            <div className="w-[100px] h-[2px] bg-primaryColor rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[15px]">
            <div className="w-[100px] h-[2px]   bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[15px]">
            <div className="w-[100px] h-[2px] bg-[#D9D9D9]  rounded-[100px] md:w-[220px]"></div>
          </div>
        </div>
        <h1 className="font-semibold text-[28px] text-primaryColor text-center mb-[30px]">
          Please enter and confirm your company details
        </h1>
        <form action="" className="flex justify-center flex-col">
          <InputField
            className="w-[300px] py-[13px] outline-none mb-[30px] md:w-[424px]"
            placeholder="Coca Cola"
            label="Associated Buyer"
          />
          <InputField
            className="w-[300px] mb-[30px] py-[13px] outline-none  md:w-[424px]"
            placeholder="ABC"
            label="Registered Company Name"
          />
          <InputField
            className="w-[300px] mb-[30px] py-[13px] outline-none md:w-[424px]"
            placeholder="Enter RC Number "
            label="Registered Company Number (RC Number)"
          />
          <InputField
            className="w-[300px] mb-[30px] py-[13px] outline-none md:w-[424px]"
            placeholder="www.abc.com"
            label="Company Website"
          />
          <div className="mb-[30px]">
            <PhoneNumber />
          </div>
          <InputField
            className="w-[300px] py-[13px] outline-none mb-[30px] md:w-[424px]"
            placeholder="www.abc.com"
            label="Registered Address"
          />
          <InputField
            className="w-[300px] py-[13px] outline-none mb-[30px] md:w-[424px]"
            placeholder="T-098765431"
            label="Company Tax ID"
          />
          <InputField
            className="w-[300px] py-[13px] outline-none mb-[30px] md:w-[424px]"
            placeholder="123456"
            label="Vendor ID with ABC Coporation"
          />
          <div className="mb-[24.5px]">
            <p className="font-medium text-xs text-textColor mb-[10px]">
              Please confirm if this data is correct
            </p>
            <label className="flex cursor-pointer" onClick={handleChange}>
              <input
                type="checkbox"
                className="accent-primaryColor mr-[10px]"
              />
              <p className="text-xs font-normal text-textColor">
                Yes, this data is correct
              </p>
            </label>
          </div>
          <Button
            onClick={handleNavigate}
            auth
            className="mb-[30px] w-full md:w-[424px]"
          >
            Apply
          </Button>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default BuyerOnboardSupplierCompanydetails;
