import { useEffect, useState } from "react";
import SettingsWithToggle from "./SettingsWithToggle";
import { useDispatch, useSelector } from "react-redux";
import {
  editNotificationSetting,
  getNotificationSetting,
} from "appstate/settings/settingsSlice";

function NotificationContent() {
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getNotificationSetting());
  // }, [dispatch]);
  const { notificationPreferenceData } = useSelector(
    (state) => state?.settings
  );
  const { emailNotification, inAppNotification, smsNotification } =
    notificationPreferenceData?.data || "";
  const [inAppsToggled, setInAppsToggled] = useState(inAppNotification);
  const [emailNotificationToggled, setEmailNotificationToggled] =
    useState(emailNotification);
  const [smsNotificationToggled, setSmsNotificationToggled] =
    useState(smsNotification);

  useEffect(() => {
    const body = {
      emailNotification: emailNotificationToggled,
      inAppNotification: inAppsToggled,
      smsNotification: smsNotificationToggled,
    };

    dispatch(editNotificationSetting(body)).then((data) => {
      dispatch(getNotificationSetting());
    });
  }, [
    dispatch,
    inAppsToggled,
    emailNotificationToggled,
    smsNotificationToggled,
  ]);

  return (
    <div className='flex pb-4 flex-col gap-8'>
      <div className='flex flex-col gap-2.5'>
        <div className='font-bold '>Action Notifications</div>
        <div className='text-lightGray text-sm'>
          We will notifications to inform you of any updates and/or changes as
          events occur for you or your business in PayEdge. Select
          notifications you would want to receive below;
        </div>
      </div>

      <SettingsWithToggle
        title='In-apps Notifications'
        info='We will need to send you notifications to inform you of any updates and /or changes as events occur for you or your business in PayEdge.'
        isToggled={inAppsToggled}
        setIsToggled={setInAppsToggled}
      />
      <SettingsWithToggle
        title='Email Notifications'
        info='We will need to send you notifications to inform you of any updates and /or changes as events occur for you or your business in PayEdge.'
        isToggled={emailNotificationToggled}
        setIsToggled={setEmailNotificationToggled}
      />
      <SettingsWithToggle
        title='SMS Notifications'
        info='We will need to send you notifications to inform you of any updates and /or changes as events occur for you or your business in PayEdge.'
        isToggled={smsNotificationToggled}
        setIsToggled={setSmsNotificationToggled}
      />
    </div>
  );
}

export default NotificationContent;
