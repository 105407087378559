import { PAIconCancel, PAIconIconAcceptedBid } from "assets/images/svgs";
import React from "react";

import Modal from "layout/modal/Modal";

function AcceptedBidSuccessfulModal({ onClose, isShown }) {
  return (
    <>
      <Modal
        isShown={isShown}
        hide={onClose}
        accountModal
        className={"lg:w-[569px]"}
      >
        <div className="px-[27px] py-[38px] ">
          <header className="flex justify-between border-b-2 border-whisper200 pb-[16px]">
            <p class="text-[#222222] text-base font-bold ">
              Funder’s bid accepted
            </p>
            <div
              onClick={onClose}
              className="flex justify-between items-center gap-x-[10px] cursor-pointer"
            >
              <PAIconCancel className="w-[8px]" />

              <p class="text-gray800 text-sm font-medium f">Close</p>
            </div>
          </header>
          <div className=" flex flex-col flex-1 w-full">
            <div className="  flex justify-center items-center p-5">
              <PAIconIconAcceptedBid />
            </div>

            <div class=" text-neutral-800 text-base font-normal  tracking-wide">
              Congratulations! Bid Accepted. You've successfully agreed to the
              terms. Expect further details on the transaction shortly. Thank
              you!
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AcceptedBidSuccessfulModal;
