import { PAIconCancelMini } from "assets/images/svgs";
import { motion } from "framer-motion";
import { useState } from "react";

function TransferFundsModal({ onClose, onAction }) {
  const [selectedAccount, setSelectedAccount] = useState("#");
  const [recipientAccount, setRecipientAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const isDisabled =
    selectedAccount === "#" ||
    recipientAccount === "" ||
    amount === "" ||
    description === "";

  const formatAmountWithCommas = (amount) => {
    return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, ""); // Remove commas
    if (!isNaN(value) && value !== "") {
      setAmount(formatAmountWithCommas(value));
    } else {
      setAmount("");
    }
  };

  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>

        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="flex flex-col gap-12 bg-white w-[40%]    rounded-[10px]"
        >
          <header className="flex bg-[#FAFAFA] items-center justify-between px-6 py-5 rounded ">
            <span className="text-sm font-thin text-[#222]">
              Transfer Funds
            </span>
            <span
              onClick={() => onClose()}
              className="flex gap-2 items-center text-sm text-[#565656] font-medium"
            >
              <PAIconCancelMini />
              Close
            </span>
          </header>

          <main className="flex px-12 text-[#222] flex-col gap-5">
            <header className=" text-[#222] font-bold text-xs">
              Transfer Information
            </header>

            <section className="flex flex-col gap-4">
              {/* Bit */}
              <div className="flex flex-col gap-2.5">
                <span className="text-xs">Select account</span>

                <select
                  value={selectedAccount}
                  onChange={(e) => setSelectedAccount(e.target.value)}
                  className="outline-none cursor-pointer text-sm py-[13px] border-b-[0.6px] border-[#222] placeholder:text-xs placeholder:text-[#86888B]"
                >
                  <option disabled value="#" key="">
                    Select an Account
                  </option>
                  <option value="a1" key="">
                    Adewale Favour Adediwura - USD
                  </option>

                  <option value="a2" key="">
                    Adewale Favour Adediwura - GBP
                  </option>

                  <option value="a3" key="">
                    Adewale Favour Adediwura - CAD
                  </option>

                  <option value="a4" key="">
                    Adewale Favour Adediwura - EUR
                  </option>

                  <option value="a5" key="">
                    Adewale Favour Adediwura - NGN
                  </option>

                  <option value="a6" key="">
                    Adewale Favour Adediwura - ZAR {"(Virtual Card)"}
                  </option>
                </select>
              </div>
              {/* Bit */}
              <div className="flex flex-col gap-2.5">
                <span className="text-xs">Recipient Account</span>

                <input
                  type="number"
                  value={recipientAccount}
                  onChange={(e) => setRecipientAccount(e.target.value)}
                  placeholder="Enter Recipient Account"
                  className=" outline-none text-sm py-[13px] border-b-[0.6px] border-[#222] placeholder:text-xs placeholder:text-[#86888B]"
                />
              </div>
              {/* Bit */}
              <div className="flex flex-col gap-2.5">
                <span className="text-xs">Amount</span>

                <input
                  placeholder="Enter Amount"
                  value={amount}
                  onChange={handleAmountChange}
                  type="text"
                  className=" outline-none text-sm py-[13px] border-b-[0.6px] border-[#222] placeholder:text-xs placeholder:text-[#86888B]"
                />
              </div>
              {/* Bit */}
              <div className="flex flex-col gap-2.5">
                <span className="text-xs">Description</span>

                <input
                  placeholder="Enter Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  className=" outline-none py-[13px] border-b-[0.6px]  text-sm border-[#222] placeholder:text-xs placeholder:text-[#86888B]"
                />
              </div>
            </section>
          </main>

          <footer className="px-12 py-6">
            <div className="flex items-start justify-end gap-4">
              <button
                onClick={() => onClose()}
                className="rounded-[5px] text-[#7A7A7A] py-2 px-4"
              >
                Cancel
              </button>

              <button
                disabled={isDisabled}
                onClick={() => onAction()}
                className={`rounded-[5px] ${
                  isDisabled
                    ? "bg-[#EDEDED] text-[#7A7A7A]"
                    : "bg-primaryColor text-white"
                }  py-2 px-4`}
              >
                Next
              </button>
            </div>
          </footer>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default TransferFundsModal;
