import { PAIconAlertIcon } from "assets/images/svgs";
import React from "react";
import Button from "shared/Button";

const LockModalContent = ({ handleLock, hide }) => {
  return (
    <div className="flex flex-col items-center gap-y-[24px] py-[28px]">
      <div>
        <PAIconAlertIcon />
      </div>
      <p className="text-base font-normal  text-lightGray">
        Are you sure you would like to Lock this invoice for this Funder?
      </p>
      <div className="overflow-hidden flex">
        <Button
          onClick={handleLock}
          neutral
          className="bg-primaryColor text-white w-[76px] flex items-center justify-center h-[43px] rounded-[5px] text-base font-normal"
        >
          {/* {isLoading ? <Spinner /> : 'Yes'} */}
          Yes
        </Button>
        <div>
          <Button
            onClick={hide}
            neutral
            className="w-[76px] h-[43px] !text-red border-[1px] border-red rounded-[5px] ml-[24px]"
          >
            No
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LockModalContent;
