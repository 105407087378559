import { allBuyerAssociatedSuppliers } from "appstate/buyer/buyerSlice";
import { getFunderAssociatedBuyers } from "appstate/funder/funderSlice";
import { allSupplierAssociatedBuyer } from "appstate/supplier/supplierSlice";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "shared/Pagination";

const Recipient1 = ({ onEmailClick, setGetName, recipientName }) => {
  const { supplierAssociatedBuyersData } = useSelector(
    (state) => state?.supplier
  );

  const { buyerAssociatedSuppliersData } = useSelector((state) => state?.buyer);

  const { funderAssociatedBuyersData } = useSelector((state) => state?.funder);

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  const { userDetails } = useSelector((state) => state.auth);
  const userRole = userDetails?.data?.role;

  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const search = "";
    switch (userRole) {
      case "SUPPLIER":
        dispatch(allSupplierAssociatedBuyer({ page, pageSize, search }));
        break;
      case "BUYER":
        dispatch(allBuyerAssociatedSuppliers({ page, pageSize, search }));
        break;
      case "FUNDER":
        dispatch(getFunderAssociatedBuyers({ page, pageSize, search }));
        break;
      default:
        toast.error("Invalid user role:", userRole);
    }
  }, [dispatch, userRole, page]);

  useEffect(() => {
    switch (userRole) {
      case "SUPPLIER":
        setData(supplierAssociatedBuyersData?.data);
        break;
      case "BUYER":
        setData(buyerAssociatedSuppliersData?.data);
        break;
      case "FUNDER":
        setData(funderAssociatedBuyersData?.data);
        break;
      default:
        toast.error("Invalid user role:", userRole);
    }
  }, [
    userRole,
    supplierAssociatedBuyersData,
    buyerAssociatedSuppliersData,
    funderAssociatedBuyersData,
  ]);

  const totalPagesToRender =
    userRole === "SUPPLIER"
      ? supplierAssociatedBuyersData
      : userRole === "BUYER"
      ? buyerAssociatedSuppliersData
      : funderAssociatedBuyersData;

  const totalPages = totalPagesToRender?.meta?.totalElements;

  const handleEmailClick = (associate) => {
    if (onEmailClick) {
      onEmailClick(associate?.email);
      setGetName(associate?.fullName);
    }
  };

  // const handleSearch = () => {
  //   const search = recipientName;
  //   switch (userRole) {
  //     case "SUPPLIER":
  //       dispatch(allSupplierAssociatedBuyer({ page, pageSize, search })).then(
  //         (data) => {
  //           setData(data?.payload?.data);
  //         }
  //       );
  //       break;
  //     case "BUYER":
  //       dispatch(allBuyerAssociatedSuppliers({ page, pageSize, search })).then(
  //         (data) => {
  //           setData(data?.payload?.data);
  //         }
  //       );
  //       break;
  //     case "FUNDER":
  //       dispatch(getFunderAssociatedBuyers({ page, pageSize, search })).then(
  //         (data) => {
  //           setData(data?.payload?.data);
  //         }
  //       );
  //       break;
  //     default:
  //       toast.error("Invalid user role:", userRole);
  //   }
  // };

  return (
    <div className="max-h-[300px] overflow-y-auto px2">
      {data?.map((associate, index) => (
        <div
          key={index}
          className="flex items-center justify-between mb-3 cursor-pointer pl-5"
          onClick={() => handleEmailClick(associate)}
        >
          <div className="flex items-center">
            <div className="flex flex-col">
              <p className="text-black font-sans font-medium">
                {associate.fullName}
              </p>
              <p className="text-gray500 font-sans text-sm">
                Email: {associate.email}
              </p>
            </div>
          </div>
        </div>
      ))}
      <div className="flex justify-center gap-x-[8px] mt-[20px]">
        {totalPages > 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
};

export default Recipient1;
