import React from 'react';
import ArrowIcon from './ArrowIcon';

const ReusableCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-4 rounded-md shadow-md flex flex-col h-full">
      {/* Icon */}
      <div className="mb-2">
          <div className="w-8 h-8">{icon}</div>
      </div>

      {/* Title and Arrow Icon */}
      <div className="flex justify-between items-center mb-2 mt-12">
        {/* Title */}
        <h2 className="text-lg font-semibold h-12 md:h-20 lg:h-12">{title}</h2>

        {/* Arrow Icon */}
        <ArrowIcon />
      </div>

      {/* Description */}
      <p className="text-gray font-sans text-base font-normal leading-normal tracking-wide flex-grow">{description}</p>
    </div>
  );
};

export default ReusableCard;
