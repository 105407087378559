import { addInventory } from "appstate/inventory/inventorySlice";
import { PAIconAdd } from "assets/images/svgs";
import imagePlaceHolder from "assets/images/svgs/imageupload.png";
import { useFormik } from "formik";
import DashboardLayout from "layout/dashboardlayout";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import Spinner from "shared/Spinner/Spinner";
import { createInventoryValidationSchema } from "validation/inventory/createInventory";

const Createproduct = () => {
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state?.inventory);

  const formik = useFormik({
    initialValues: {
      image: "",
      productName: "",
      description: "",
      unit: "",
      unitPrice: "",
    },
    validationSchema: createInventoryValidationSchema,
    onSubmit: (values) => {
      const image = values.image;
      const body = {
        productName: values.productName,
        productDescription: values.description,
        unit: values.unit,
        unitPrice: values.unitPrice,
      };
      dispatch(addInventory({ body, image })).then((data) => {
        if (data?.payload?.status === 200) {
          toast.success("inventory created successfully");
          let path = -1;
          navigate(path);
        }
      });
    },
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages([reader.result, ...images]);
        formik.setFieldValue("image", [file, ...formik.values.image]);
      };
      reader.readAsDataURL(file);
    }
  };

  function cancel() {
    navigate(-1);
  }

  const placeholder = images?.length !== 0 ? images[0] : imagePlaceHolder;

  return (
    <DashboardLayout>
      <form onSubmit={formik.handleSubmit} className="bg-white pb-[40px]">
        <div className="px-[24px] py-[20px] bg-alabasterHeader">
          <h1 className="text-black text-sm font-medium">Add Product</h1>
        </div>
        <div className="mt-[40px] px-[90px] flex justify-between">
          <div>
            <label className="text-sm font-normal text-black mb-[12px]">
              Product variant Images
            </label>
            <div className="flex justify-center items-center h-[300px] w-[300px] mt-[12px] outline-dashed outline-2 outline-offset-2 outline-royalBlue">
              <img
                src={placeholder}
                alt=""
                className={`${
                  images.length !== 0 ? "h-[300px] w-[300px]" : ""
                }`}
              />
            </div>
            {formik.touched.image && formik.errors.image ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.image}
              </p>
            ) : null}
            <div className="flex gap-x-[20px] mt-[24px] items-center">
              <div className="flex justify-center items-center h-[65px] w-[65px] outline-dashed outline-altoGray">
                <img
                  src={images?.length !== 0 ? images[0] : imagePlaceHolder}
                  alt=""
                  className={`${
                    images?.length !== 0 ? "h-[65px] w-[65px]" : "w-[30px]"
                  }`}
                />
              </div>
              <div className="flex justify-center items-center h-[65px] w-[65px] outline-dashed outline-altoGray">
                <img
                  src={images[1] ? images[1] : imagePlaceHolder}
                  alt=""
                  className={`${images[1] ? "h-[65px] w-[65px]" : "w-[30px]"}`}
                />
              </div>
              <div className="flex justify-center items-center h-[65px] w-[65px] outline-dashed outline-altoGray">
                <img
                  src={images[2] ? images[2] : imagePlaceHolder}
                  alt=""
                  className={`${images[2] ? "h-[65px] w-[65px]" : "w-[30px]"}`}
                />
              </div>
              <label className="border-[0.8px] border-altoGray rounded-full w-[35px] h-[35px] flex justify-center items-center">
                <PAIconAdd />
                <input
                  className="hidden"
                  id="image"
                  type="file"
                  accept="image/png, image/jpeg"
                  name="file"
                  multiple
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          </div>
          <div>
            <div className="mb-[24px]">
              <InputField
                label="Product Name"
                type="text"
                placeholder="Enter item name"
                id="productName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.productName}
                className="border !border-gray600 rounded-[5px] w-[500px] px-[16px] outline-none"
              />
              {formik.touched.productName && formik.errors.productName ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.productName}
                </p>
              ) : null}
            </div>
            <div>
              <div>
                <label className="font-normal text-xs text-textColor  mb-[10px]">
                  Description
                </label>
              </div>
              <div className="mb-[24px]">
                <textarea
                  id="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  className="border border-gray600 rounded-[5px] w-[500px] h-[113px] text-xs py-[10px]  px-[16px] outline-none"
                />
                {formik.touched.description && formik.errors.description ? (
                  <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.description}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="mb-[24px]">
              <InputField
                label="Unit"
                type="number"
                placeholder="Enter quantity"
                id="unit"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.unit}
                className="border !border-gray600 rounded-[5px] w-[500px] px-[16px] outline-none"
              />
              {formik.touched.unit && formik.errors.unit ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.unit}
                </p>
              ) : null}
            </div>
            <InputField
              label="Unit Price"
              type="number"
              placeholder="Enter price for the product"
              id="unitPrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.unitPrice}
              className="border !border-gray600 rounded-[5px] w-[500px] px-[16px] outline-none"
            />
            {formik.touched.unitPrice && formik.errors.unitPrice ? (
              <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.unitPrice}
              </p>
            ) : null}
            <div className="flex justify-end gap-x-[10px] mt-[34px]">
              <Button
                neutral
                type="button"
                onClick={cancel}
                className="flex justify-center items-center rounded-[5px] w-[96px] h-[41px] text-black text-sm font-medium border-[0.5px] border-gray600"
              >
                Cancel
              </Button>
              <Button
                neutral
                type="submit"
                disabled={isLoading}
                className="flex justify-center items-center w-[133px] h-[41px] text-white text-sm font-medium bg-success rounded-[5px] hover:text-black"
              >
                {isLoading ? <Spinner /> : "Add Product"}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </DashboardLayout>
  );
};

export default Createproduct;
