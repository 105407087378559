import React, { useEffect, useState, useRef } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";

import Loading from "shared/Loading";
import Pagination from "shared/Pagination";

import { PAIconOption } from "assets/images/svgs";

const BulkTableDirectFund = () => {
  const [atBottom, setAtBottom] = useState(false);

  const [dropdown, setDropdown] = useState(false);

  const dropdownRef = useRef();

  const dummyData = [
    {
      bidAmount: 1,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "1",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 2,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "2",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 3,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "2",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 4,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "1",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 5,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "3",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 6,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "3",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 7,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "1",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 8,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "2",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
  ];
  const navigate = useNavigate();

  const singleEarlyPaymentDetails = (id) => {
    let path = `/reinbursement/detail/bulkdirectfundingpagedetails/${id}`;
    navigate(path);
  };

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);

  const handleDropdown = (row) => {
    if (dropdown === row.id) {
      setDropdown(null);
    } else {
      setDropdown(row.id);
    }
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref. No.
        </span>
      ),
      selector: (row) => row.RequestRefNo,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Funder
        </span>
      ),
      selector: (row) => row.InvoiceNumber,
      sortable: true,
    },
    {
      name: <span className="whitespace-nowrap">Request Date</span>,
      selector: (row) => row.Supplier,
      sortable: true,
    },
    {
      name: "No. of invoices",
      selector: (row) => row.DueDate,
      sortable: true,
    },
    {
      name: "Tier",
      selector: (row) => row.Requestdate,
      sortable: true,
    },
    {
      name: "Invoice amount",
      selector: (row) => row.Currency,
      sortable: true,
      cell: (row) => <p className="text-success">{row.Currency}</p>,
    },
    {
      name: "Discount amount",
      selector: (row) => row.InvoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-[#F08000]">{row.InvoiceAmount}</p>,
    },

    {
      name: "Discount Rate",
      selector: (row) => row.DiscountAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.DiscountAmount}</p>,
    },

    {
      name: "Payment Amount",
      selector: (row) => row.PaymentAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.PaymentAmount}</p>,
    },
    {
      cell: (row) => (
        <div>
          <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
          {dropdown === row.id && row && (
            <div className="relative" ref={dropdownRef}>
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && "bottom-[20px]"
                } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb-[12px]  pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Preview</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Initiate Disbursement</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <p className="font-sm font-normal">Export request</p>
                </div>
                <div className="border border-silver"></div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  return (
    <>
      {!dummyData.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]"></div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="px[15px] pb-[24px]">
            <Table
              columns={columns}
              data={dummyData}
              onRowClicked={(row) => singleEarlyPaymentDetails(row.bidAmount)}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {dummyData.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={dummyData.length}
                />
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default BulkTableDirectFund;
