import {
  PAIconCancel,
  PAIMsIcon,
  PAIPdfIcon,
  PAICsvIcon,
  PAIExcelIcon,
} from "assets/images/svgs";
import React, { useEffect, useState } from "react";

import Modal from "layout/modal/Modal";

function FileFormatModal({ onClose, isShown, onContinue }) {
  const [pdfMode, setPdfMode] = useState(false);
  const [excelMode, setExcelMode] = useState(false);
  const [msMode, setMsMode] = useState(false);
  const [csvMode, setCsvMode] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handlePdf = () => {
    setPdfMode(true);
    setCsvMode(false);
    setExcelMode(false);
    setMsMode(false);
  };

  const handleMsMode = () => {
    setPdfMode(false);
    setCsvMode(false);
    setExcelMode(false);
    setMsMode(true);
  };

  const handleExcelMode = () => {
    setPdfMode(false);
    setCsvMode(false);
    setExcelMode(true);
    setMsMode(false);
  };
  const handleCsvMode = () => {
    setPdfMode(false);
    setCsvMode(true);
    setExcelMode(false);
    setMsMode(false);
  };

  useEffect(() => {
    if (pdfMode || excelMode || msMode || csvMode) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [csvMode, excelMode, msMode, pdfMode]);
  return (
    <>
      <Modal
        isShown={isShown}
        hide={onClose}
        accountModal
        className={"lg:w-[569px]"}
      >
        <div className="px-[27px] py-[45px] ">
          <header className="flex justify-between   border-b-4 border-whisper200 pb-[16px]">
            <div class="text-neutral-800 text-base font-medium ">
              Choose file format
            </div>
            <div
              onClick={onClose}
              className="flex justify-between items-center gap-x-[10px] cursor-pointer"
            >
              <PAIconCancel className="w-[8px]" />

              <p class="text-gray800 text-sm font-medium f">Close</p>
            </div>
          </header>

          <div className=" flex flex-row items-center justify-between flex-wrap my-12">
            <div
              class={`w-[117px] h-9 px-5 py-2.5 rounded-[5px] border  justify-start items-center gap-2 inline-flex ${
                msMode ? "border-blue-600" : "border-[#909090]"
              }`}
              onClick={handleMsMode}
            >
              <div class="w-4 h-4 relative">
                <PAIMsIcon />
              </div>
              <div class="text-center text-black text-xs font-normal ">
                WORD file
              </div>
            </div>

            <div
              class={`w-[117px] h-9 px-5 py-2.5 rounded-[5px] border  justify-start items-center gap-2 inline-flex ${
                pdfMode ? "border-blue-600" : "border-[#909090]"
              }`}
              onClick={handlePdf}
            >
              <div class="w-4 h-4 relative">
                <PAIPdfIcon />
              </div>
              <div class="text-black text-xs font-normal ">PDF file</div>
            </div>

            <div
              class={`w-[117px] h-9 px-5 py-2.5 rounded-[5px] border  justify-start items-center gap-2 inline-flex ${
                excelMode ? "border-blue-600" : "border-[#909090]"
              }`}
              onClick={handleExcelMode}
            >
              <div class="w-4 h-4 relative">
                <PAIExcelIcon />
              </div>
              <div class="text-black text-xs font-normal ">Excel file</div>
            </div>

            <div
              class={`w-[117px] h-9 px-5 py-2.5 rounded-[5px] border  justify-start items-center gap-2 inline-flex ${
                csvMode ? "border-blue-600" : "border-[#909090]"
              }`}
              onClick={handleCsvMode}
            >
              <div class="w-4 h-4 relative">
                {" "}
                <PAICsvIcon />
              </div>
              <div class="text-black text-xs font-normal ">CSV file</div>
            </div>
          </div>

          {/* for button */}
          <div className=" w-full justify-end flex pb-6">
            <button
              class=" h-[39px] px-5 py-2.5 bg-[#07593D] rounded-[5px] justify-center items-center gap-2.5 inline-flex"
              disabled={disabled}
              onClick={onContinue}
            >
              <div class="text-center text-white text-base font-normal ">
                Download
              </div>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default FileFormatModal;
