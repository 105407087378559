import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Details from "./details";

const BulkNewExtensionRequestDetails = () => {
  return (
    <DashboardLayout
      section="Payment Extension Requests"
      subHeader="Send and track payment extension processes"
    >
      <Details />
    </DashboardLayout>
  );
};

export default BulkNewExtensionRequestDetails;
