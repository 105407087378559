import DashboardLayout from "layout/dashboardlayout";

import FunderOpenMarket from "./funder";
import SupplierOpenMarket from "./supplier";

import { useSelector } from "react-redux";

const OpenMarket = () => {
  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const ruleConfigPlayer =
    userRole === "supplier" ? <SupplierOpenMarket /> : <FunderOpenMarket />;

  return (
    <DashboardLayout
      section="Open Market"
      subHeader="Here is what is happening in your finance"
    >
      {ruleConfigPlayer}
    </DashboardLayout>
  );
};

export default OpenMarket;
