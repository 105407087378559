import { PAIconAlert24 } from "assets/images/svgs";
import React from "react";

const BidProceedModal = ({ isOpen, onClose, handleYes, refNum }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center px-10 md:min-w-[614px] py-7">
        <PAIconAlert24 />
        <p className="text-[#979797] text-center font-sans text-base font-normal leading-normal tracking-wide mb-3 my-5">
          Would you like to proceed to use this account <br />{" "}
          <span className="text-[#07593D] font-bold">{refNum}</span> to bid on
          invoice?
        </p>
        <div className="flex justify-center mt-3 gap-4">
          <button
            className=" px-6 py-2 bg-[#07593d] rounded-[5px] border border-[#07593d] justify-center items-center gap-2.5 inline-flex"
            onClick={handleYes}
          >
            <div class="text-center text-white text-base font-semibold  tracking-wide">
              Yes Proceed
            </div>
            {/* </div> */}
          </button>
          <button
            className="px-5 py-2 bg-red-500 border border-red text-red rounded-md"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default BidProceedModal;
