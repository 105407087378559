import request from "apiInstance";
import toast from "react-hot-toast";

export const PutIgnoreOffer = async ({ offerId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/payable-finance/ignore-offer/${offerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const PutAcceptOffer = async ({ offerId, invIds }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/payable-finance/accept-offer/${offerId}`,
      params: { invIds: [invIds].join(",") },
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const viewOfferById = async ({ offerId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/view-offer/${offerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllPfpOffer = async ({ pageSize, page, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/offers?size=${pageSize}&page=${page}&search=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const viewCampaignInvoiceById = async ({ offerId, invoiceId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/early-payment-campaign/${offerId}/invoice/${invoiceId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const viewAllInvoicesInCampaign = async ({
  offerId,
  pageSize,
  page,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/early-payment-campaign-invoices/${offerId}?size=${pageSize}&page=${page}&search=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
