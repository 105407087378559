import React, { useState, useEffect } from "react";
import {
  PACircleArrow,
  PAOpenEye,
  PATrash,
  PAIconEditMain,
} from "assets/images/svgs";
import ViewDocumentModal from "./ViewDocumentModal";
import DeleteDocumentModal from "./DeleteDocumentModal";
import UpdateDocumentModal from "./UpdateDocumentModal";
import {
  viewAllUploadLoadDocs,
  viewAllUploadLoadDocById,
  removeUploadedDocumentById,
} from "appstate/buyer/buyerSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

const DocumentList = ({ onBackClick }) => {
  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(""); // Store the selected document name
  const [id, setId] = useState("");
  const [updateId, setUpdateId] = useState("");

  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  const dispatch = useDispatch();

  const { viewAllUploadLoadDocsData } = useSelector((state) => state?.buyer);
  const { viewAllUploadLoadDocByIdData } = useSelector((state) => state?.buyer);

  const handleModal = (documentName, fetchId) => {
    setSelectedDocument(documentName); // Set the selected document name
    setOpen(true);

    dispatch(viewAllUploadLoadDocById(fetchId));
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    setShowDeleteModal(true);
    setId(id);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleRemoveDoc = () => {
    dispatch(removeUploadedDocumentById(id)).then((data) => {
      if (data?.type === "buyer/deleteUploadedDocumentById/fulfilled") {
        dispatch(viewAllUploadLoadDocs());
        toast.success(" Document removed Successfully");
      }
    });
  };

  const handleUpdateModal = (id) => {
    setShowUpdateModal(!showUpdateModal);
    setUpdateId(id);
  };

  const handleFileSelect = (fileName) => {
    setUploadedDocuments([...uploadedDocuments, fileName]);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
  };

  const handleBack = () => {
    onBackClick();
  };

  useEffect(() => {
    dispatch(viewAllUploadLoadDocs());
  }, [dispatch]);

  return (
    <div>
      <div>
        <ViewDocumentModal
          isShown={open}
          onClose={() => setOpen(false)}
          name={selectedDocument}
          onCloseModal={handleCloseModal}
          url={
            viewAllUploadLoadDocByIdData && viewAllUploadLoadDocByIdData.data
          }
        />
        {showDeleteModal && (
          <DeleteDocumentModal
            onCloseDeleteModal={handleCloseDeleteModal}
            deleteDoc={handleRemoveDoc}
          />
        )}
        {showUpdateModal && (
          <UpdateDocumentModal
            id={updateId}
            onCloseModal={handleCloseUpdateModal}
            onFileSelect={handleFileSelect}
          />
        )}

        <div onClick={handleBack} className="flex cursor-pointer">
          <PACircleArrow onClick={handleCloseDeleteModal} />
          <p className="ml-2">Back</p>
        </div>
        <div className=" mt-[3%] mb-4">
          <p className="mb-4 font-semibold">Uploaded Document</p>
          <div className="bg-gallery h-[1px] pb-1 w-full"></div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse table-auto">
            <thead>
              <tr className="bg-[#EEEEEE]">
                <th className=" text-left font-semibold text-sm text-[#7A7A7A] px-4 py-2 w-2/4">
                  Document
                </th>
                <th className=" text-sm font-medium text-[#7A7A7A] px-4 py-2">
                  Uploaded Date
                </th>
                <th className=" text-sm font-medium text-[#7A7A7A] px-4 py-2">
                  Status
                </th>
                <th className=" text-sm font-medium text-[#7A7A7A] px-4 py-2">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {viewAllUploadLoadDocsData &&
                viewAllUploadLoadDocsData.data &&
                viewAllUploadLoadDocsData.data.length > 0 &&
                viewAllUploadLoadDocsData.data.map((doc) => (
                  <tr key={doc.id}>
                    <td className=" text-sm font-medium text-[#7A7A7A] px-4 py-2">
                      {doc.documentType}
                    </td>
                    <td className=" text-center text-sm font-medium text-[#7A7A7A] px-4 py-2">
                      {doc.uploadedDate}
                    </td>
                    <td
                      className={`text-center text-sm font-medium px-4 py-2  ${
                        doc.status === "pending_validation"
                          ? " text-[#F08000]"
                          : "text-[#7A7A7A]"
                      }`}
                    >
                      {doc.status}
                    </td>
                    <td className=" text-center px-4 py-2">
                      <div className="flex justify-center">
                        <PAOpenEye
                          onClick={() => handleModal(doc.document, doc.id)}
                          className="mr-[5%] cursor-pointer"
                        />
                        <PATrash
                          className="cursor-pointer"
                          onClick={() => handleDelete(doc.id)}
                        />
                        <div className=" ml-1">
                          <PAIconEditMain
                            className="cursor-pointer"
                            onClick={() => handleUpdateModal(doc.id)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DocumentList;
