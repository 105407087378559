import { setHasCompleted2FA } from "appstate/auth/authSlice";
import { verifyUserOTP } from "appstate/settings/settingsSlice";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import InputField from "shared/InputField";
import Spinner from "shared/Spinner/Spinner";

const TwoFactorModalContent = ({ onClose, setOpenModal }) => {
  const { isLoading } = useSelector((state) => state.settings);
  const [inputOTP, setInputOTP] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(verifyUserOTP({ code: inputOTP })).then((data) => {
      if (data?.payload?.status === 200) {
        dispatch(setHasCompleted2FA());
        toast.success("2FA verification passed!");
        setOpenModal(false);
      }
    });
  };

  return (
    <div className="pb-4 px-8 flex justify-center items-center">
      <div className="w-4/5 flex flex-col gap-4 justify-center items-center">
        <form
          onSubmit={handleSubmit}
          className="w-full flex flex-col gap-4 py-8"
        >
          <div className="w-full">
            <InputField
              label="Enter OTP generated from mobile phone"
              id="verifyOTP"
              name="verifyOTP"
              onChange={(e) => setInputOTP(e.target.value)}
              placeholder="******"
              className="h-[45px] !border bg-wildSand rounded-md p-[13px]"
              value={inputOTP}
            />
          </div>
          <div className="flex justify-end gap-3">
            <Button
              type="button"
              onClick={onClose}
              className="flex !text-[14px] w-[120px] !px-6 !py-3 justify-center items-center bg-wildSand border-none !rounded-[10px] h-[40px]"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="whitespace-nowrap flex !text-[14px] w-[120px] !px-6 !py-3 justify-center  items-center !bg-emerald border-none !rounded-[10px] h-[40px] hover:bg-green-400"
            >
              {isLoading ? <Spinner /> : "Verify OTP"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TwoFactorModalContent;
