import { associateWithBuyer } from "appstate/auth/authSlice";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import { useEffect } from "react";

import Spinner from "shared/Spinner/Spinner";
import { companyDetailsValidationSchema } from "validation/supplier/CompanyDetailsSchema";
import OnboardingLayout from "../category/components/OnboardingLayout";
import MultiSelectDropdown from "shared/BuyerMultiSelectDropdown";
import { PAIconOnboardArrowLeftIcon } from "assets/images/svgs";
import Contact from "../components/Contact";

import { industries } from "utilities/Industries";

const Companydetails = () => {
  const [selectedCountryCode, setSelectedCountryCode] = useState("+234");
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const companyName = location?.state;
  const { isLoading, associateBuyerId } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      companyName: "",
      rcNumber: "",
      companyWebsite: "",
      companyNumber: "",
      registeredAddress: "",
      companyTaxId: "",
      vendorId: "",
      industry: [],
      CompanyEmailaddress: "",
    },
    validationSchema: companyDetailsValidationSchema(selectedCountryCode),
    onSubmit: (values) => {
      const phoneNumberWithCountryCode = `${selectedCountryCode}${values.companyNumber}`;
      const body = {
        companyName: values.companyName,
        rcNumber: values.rcNumber,
        companyWebsite: values.companyWebsite,
        companyPhoneNumber: phoneNumberWithCountryCode,
        industryType: values.industry,
        registeredAddress: values.registeredAddress,
        companyTaxId: values.companyTaxId,
        vendorId: values.vendorId,
      };
      dispatch(associateWithBuyer({ body, associateBuyerId })).then((data) => {
        if (data?.payload?.status === 200) {
          let path = "/supplier/terms";
          navigate(path);
        }
      });
    },
  });
  const handlePhoneCountryChange = (selectedCountryCode) => {
    setSelectedCountryCode(selectedCountryCode);
  };

  useEffect(() => {
    if (
      formik.values.companyName &&
      formik.values.rcNumber &&
      formik.values.companyWebsite &&
      formik.values.companyNumber &&
      formik.values.registeredAddress &&
      formik.values.companyTaxId &&
      formik.values.vendorId &&
      formik.values.CompanyEmailaddress &&
      formik.values.industry.length > 0
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [
    formik.values.CompanyEmailaddress,
    formik.values.companyName,
    formik.values.companyNumber,
    formik.values.companyTaxId,
    formik.values.companyWebsite,
    formik.values.industry.length,
    formik.values.rcNumber,
    formik.values.registeredAddress,
    formik.values.vendorId,
  ]);

  return (
    <OnboardingLayout nohelp>
      <div className=" px-[18px] lg:px-[42px]  mt-6">
        <PAIconOnboardArrowLeftIcon
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>{" "}
      <div className=" flex items-center flex-col h-[100%] overflow-auto">
        <div className="mb-[50px] mt-[30px] flex justify-center items-center gap-x-[20px] lg:gap-x-[50px]">
          <div className="relative bottom-[15px]">
            <div className="w-[100px] h-[2px] bg-[#F08000]  rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[28px] md:bottom-[30px]">
            <p className="font-normal text-xs text-textColor mb-[10px] md:text-sm">
              Company Details
            </p>
            <div className="w-[100px] h-[2px] bg-[#F08000]  rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[15px]">
            <div className="w-[100px] h-[2px] bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
          </div>
        </div>

        <div class=" text-[#222222] text-2xl  font-medium  mb-11">
          Please enter and confirm your company details
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center flex-col"
        >
          <InputField
            className="w-[300px] py-[13px] outline-none mb-[30px] md:w-[424px]"
            disabled
            label="Associated Buyer"
            value={companyName}
          />
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px]"
              placeholder="Enter Company Name"
              label="Registered Company Name"
              type="text"
              id="companyName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyName}
            />
            {formik.touched.companyName && formik.errors.companyName ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.companyName}
              </p>
            ) : null}
          </div>
          <div className=" mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="Enter RC Number "
              label="Registered Company Number (RC Number)"
              type="text"
              id="rcNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.rcNumber}
            />
            {formik.touched.rcNumber && formik.errors.rcNumber ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.rcNumber}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="www.abc.com"
              label="Company Website"
              type="text"
              id="companyWebsite"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyWebsite}
            />
            {formik.touched.companyWebsite && formik.errors.companyWebsite ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.companyWebsite}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <Contact
              title="Contact Number"
              name="companyNumber"
              id="companyNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyNumber}
              onCountryCodeChange={handlePhoneCountryChange}
            />
            {formik.touched.companyNumber && formik.errors.companyNumber ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.companyNumber}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <MultiSelectDropdown
              options={industries}
              selectedOptions={formik.values.industry}
              setSelectedOptions={(selected) =>
                formik.setFieldValue("industry", selected)
              }
            />
            {formik.touched.industry && formik.errors.industry ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.industry}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="abubakareze@info.com"
              label="Company Email address"
              type="email"
              id="CompanyEmailaddress"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.CompanyEmailaddress}
            />
            {formik.touched.CompanyEmailaddress &&
            formik.errors.CompanyEmailaddress ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.CompanyEmailaddress}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px]"
              placeholder="Registered Address"
              label="Registered Address"
              id="registeredAddress"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.registeredAddress}
            />
            {formik.touched.registeredAddress &&
            formik.errors.registeredAddress ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.registeredAddress}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px]"
              placeholder="2039484839"
              label="Company Tax ID"
              id="companyTaxId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyTaxId}
            />
            {formik.touched.companyTaxId && formik.errors.companyTaxId ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.companyTaxId}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="123456"
              label={
                <span>
                  Vendor Id with{" "}
                  <span className="text-[#959595]">{companyName}</span>{" "}
                </span>
              }
              id="vendorId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.vendorId}
            />
            {formik.touched.vendorId && formik.errors.vendorId ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.vendorId}
              </p>
            ) : null}
          </div>

          <Button
            // onClick={handleTest}
            type="submit"
            disabled={isLoading ? isLoading : !checked}
            auth
            className="mb-[30px] w-full md:w-[424px]"
          >
            {isLoading ? <Spinner /> : "Continue"}
          </Button>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default Companydetails;
