import { PAIconKitEyeclose, PAIconMasterCardLogo } from "assets/images/svgs";
import React, { useState } from "react";
import BackgroundCard from "shared/Cardbackground";
import OverviewCard from "./components/OverviewCard";
import FundingOverview from "./components/FundingOverview";

const Overview = ({ cardData }) => {
  const [isBalanceVisible, setBalanceVisibility] = useState(true);

  const toggleBalanceVisibility = () => {
    setBalanceVisibility((prevVisibility) => !prevVisibility);
  };

  return (
    <>
      <div className="mb-[30px] lg:flex justify-between gap-x-[10px]">
        <BackgroundCard className="bg-cover rounded-[10px] lg:w-[400px] xl:w-[497px] 2xl:w-[600px]  pt-[15px] px-[16px] bg-no-repeat lg:pt-[20px] xl:pt-[30px]">
          <div className="text-xs font-semibold text-white ">
            My Wallet -{" "}
            <span className=" font-normal">
              <span>{cardData?.cardName || "unregistered card"}</span> -{" "}
              {cardData?.cardNumber || "0000 **** **** 0000"}
            </span>
          </div>
          <div className="text-[9.5px]  text-white">Transactions Summary</div>

          <div className=" flex justify-center items-center w-full mt-8">
            <div className="flex justify-center cursor-pointer  w-80 transition duration-[350ms] transform hover:scale-105 ease-in-out ">
              <div className="flex justify-between w-full shadow-card-mod py-[16px] px-[17px] relative rounded-[10px]">
                <div className=" w-full">
                  <h2 className="text-sm font-semibold text-white mb-[27px]">
                    Card Balance
                  </h2>
                  <div className=" flex gap-1 items-center text-4xl mb-[27px] text-white">
                    <span>{cardData?.cardCurrency === "USD" ? "$" : "₦"}</span>
                    {isBalanceVisible ? `${cardData?.balance || "0"}` : "****"}
                  </div>
                  <p className="text-sm font-semibold text-white mb-[5px]">
                    {cardData?.cardName || "unregistered card"}
                  </p>
                  <p className="text-[10px] text-white">
                    {cardData?.cardNumber || "0000 **** **** 0000"}
                  </p>
                </div>
                <div className="flex flex-col justify-between items-center">
                  <p className="text-xs font-[300] text-white ">
                    {cardData?.expiryDate || "N/A"}
                  </p>
                  <PAIconKitEyeclose
                    onClick={toggleBalanceVisibility}
                    className=" cursor-pointer "
                  />
                  <PAIconMasterCardLogo />
                </div>
              </div>
            </div>
          </div>

          <div className=" gap-5 flex justify-center mt-5">
            <button className=" bg-white rounded-[5px] text-[#07593D] font-light text-xs px-5 py-2.5 transition duration-300 transform hover:scale-105 ease-in-out hover:bg-slate-100">
              Collect Payment
            </button>
            <button className=" bg-[#FF8E0D] rounded-[5px] text-white text-xs font-light px-5 py-2.5 transition duration-300 transform hover:scale-105 ease-in-out hover:bg-[#ff8e0de5] ">
              Fund Account
            </button>
          </div>
        </BackgroundCard>
        <div>
          <OverviewCard />
        </div>
        <div>
          <FundingOverview />
        </div>
      </div>
    </>
  );
};

export default Overview;
