import React, { useState, useRef } from "react";
import { PAIconCancel, PAAddFileImg } from "assets/images/svgs";
import { uploadDocument } from "appstate/buyer/buyerSlice";
import { useDispatch } from "react-redux";
import { PARadioChecked, PARadioUncheked } from "assets/images/svgs";

const UploadDocumentModal = ({ onCloseModal, onFileSelect }) => {
  const [selectedFileName, setSelectedFileName] = useState(null);
  const fileInputRef = useRef(null);
  const [docType, setDocType] = useState("");
  const dispatch = useDispatch();
  const itemsToDispatch = [
    { id: 0, type: "ID_CARD" },
    { id: 1, type: "UTILITY_BILL" },
    { id: 2, type: "UTILITY_BILL" },
  ];
  const [count, setCount] = useState();

  const handleTextClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setSelectedFileName(selectedFile);
    }
  };

  const handleUpload = () => {
    onCloseModal();
    if (selectedFileName) {
      onFileSelect(selectedFileName?.name);
    }
    handleUploadDocuments();
  };

  const handleUploadDocuments = () => {
    const image = selectedFileName;
    const body =
      docType === "UTILITY_BILL"
        ? {
            documentType: "UTILITY_BILL",
          }
        : {
            documentType: "ID_CARD",
            cardType: "NIN",
          };
    dispatch(uploadDocument({ body, image }));
  };

  const handleCloseModal = () => {
    onCloseModal();
  };

  const handleDispatchType = (type, itemNum) => {
    setDocType(type);
    setCount(itemNum);
  };

  return (
    <div className="">
      <div>
        <div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
          <div className="modal-overlay"></div>
          <div className="modal-container z-[9999] mt-14 ml-16 bg-white w-2/5 p-4 rounded-lg shadow-lg">
            <div className="modal-header mx-4 mb-10 flex justify-between items-center">
              <h2 className="text-l font-semibold">Upload Document</h2>
              <PAIconCancel
                className="cursor-pointer"
                onClick={handleCloseModal}
              />
            </div>
            <div className="bg-[#F8FAFB] rounded-md mb-10 modal-body border-dashed border-2 border-blue-200 p-24 text-center flex flex-col items-center justify-center">
              <PAAddFileImg />
              {selectedFileName ? (
                <div>
                  <p className="mt-4">
                    Uploaded file: <strong>{selectedFileName?.name}</strong>
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    Drag file here or click to upload or{" "}
                    <span
                      onClick={handleTextClick}
                      className="text-blue-500 cursor-pointer"
                    >
                      choose a file
                    </span>
                  </p>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              )}
            </div>

            {/* Added Design */}
            <div className=" w-full justify-between items-center px-2 flex flex-row py-6">
              <div
                className="flex items-top"
                onClick={() =>
                  handleDispatchType(
                    itemsToDispatch[0].type,
                    itemsToDispatch[0].id
                  )
                }
              >
                {count === 0 ? (
                  <PARadioChecked className="mt-1" />
                ) : (
                  <PARadioUncheked className="mt-1" />
                )}

                <div className="ml-4">
                  <h2 className="text-base font-medium">National ID card</h2>
                </div>
              </div>
              <div
                className="flex items-top"
                onClick={() =>
                  handleDispatchType(
                    itemsToDispatch[1].type,
                    itemsToDispatch[1].id
                  )
                }
              >
                {count === 1 ? (
                  <PARadioChecked className="mt-1" />
                ) : (
                  <PARadioUncheked className="mt-1" />
                )}

                <div className="ml-4">
                  <h2 className="text-base font-medium">Driver’s license</h2>
                </div>
              </div>
              <div
                className="flex items-top"
                onClick={() =>
                  handleDispatchType(
                    itemsToDispatch[2].type,
                    itemsToDispatch[2].id
                  )
                }
              >
                {count === 2 ? (
                  <PARadioChecked className="mt-1" />
                ) : (
                  <PARadioUncheked className="mt-1" />
                )}

                <div className="ml-4">
                  <h2 className="text-base font-medium">NIN</h2>
                </div>
              </div>
            </div>
            {/* Ended Design */}

            <div className="modal-footer text-right border-t border-slate-400 pt-6 pb-4">
              <button
                onClick={handleCloseModal}
                className="btn bg-[#CCCCCC] text-black mr-4 rounded-md px-4 py-2"
              >
                Discard
              </button>
              <button
                onClick={handleUpload}
                className="btn bg-[#07593D] text-white rounded-md px-6 py-2"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocumentModal;
