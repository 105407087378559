import { useState } from "react";
import MailIcon from "../../../assets/images/svgs/mailicon.svg";
import toast from "react-hot-toast";
import { ResendVerificationEmail } from "appstate/api/auth/onboarding";

const MailsentConfirmationModal = ({email}) => {

     // eslint-disable-next-line
    const [user, setUser] = useState(null);

    const handleResendLink = async () => {
        try {
            const response = await ResendVerificationEmail(email);
            setUser(response?.data);
        } catch (error) {
            toast.error("Error resending verification email");
        }
    };


    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
                <div className="flex justify-center mb-4">
                    <img src={MailIcon} alt="mail icon" className="w-16 h-16" />
                </div>
                <div className="text-center">
                    <h2 className="text-md text-[#7A7A7A]">
                        Thanks for signing up to PayEdge. A mail has been sent to 
                    </h2>
                    <p className="text-[#7A7A7A] mt-0">
                        your email address to get you verified.
                    </p>
                </div>
                <div className="text-center mt-4">
                    <p className="text-[#7A7A7A]">
                        Didn't get the mail? <span onClick={handleResendLink} className="font-bold cursor-pointer">Resend mail</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MailsentConfirmationModal;
