import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Heading from "shared/heading/Heading";

function DirectFundingInvoiceList() {
  const navigate = useNavigate();

  const onRowClicked = () => {
    let path =
      "/invoice/fundingSection/directfundingbulkrequest/directfundingbulklist";
    navigate(path);
  };
  const recentTransactions = [
    {
      invoiceNumber: "INV2022001",
      buyer: "Nestle PLC",
      invoiceDueDate: "2024-01-31",
      invoiceDate: "2024-01-01",
      currency: "USD",
      invoiceAmount: "$9,000.00",
      status: "Approved",
    },
    {
      invoiceNumber: "INV2022002",
      buyer: "Nestle PLC",
      invoiceDueDate: "2024-02-04",
      invoiceDate: "2024-01-05",
      currency: "USD",
      invoiceAmount: "$4,000.00",
      status: "Approved",
    },
    {
      invoiceNumber: "INV2022001",
      buyer: "Nestle PLC",
      invoiceDueDate: "2024-01-31",
      invoiceDate: "2024-01-01",
      currency: "USD",
      invoiceAmount: "$9,000.00",
      status: "Approved",
    },
    {
      invoiceNumber: "INV2022002",
      buyer: "Nestle PLC",
      invoiceDueDate: "2024-02-04",
      invoiceDate: "2024-01-05",
      currency: "USD",
      invoiceAmount: "$4,000.00",
      status: "Approved",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceNumber,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyer,
      sortable: true,
    },
    {
      name: "Invoice Due Date",
      selector: (row) => row.invoiceDueDate,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Approved" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Rejected request" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "Updated request" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "Pending request" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];
  return (
    <DashboardLayout>
      <Card className="rounded-lg">
        <div>
          <Heading
            heading="78 Approved Invoices"
            info="Available for payment"
          />
          <div className="rounded-b-lg px-[24px] pb-[24px]">
            <Table
              columns={columns}
              data={recentTransactions}
              onRowClicked={onRowClicked}
              pointer
              // progressPending={isLoading}
              // progressComponent={<Loading />}
              tableHeader
              className="mt-5"
            />
            {/* <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading && totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div> */}
          </div>
        </div>
      </Card>
    </DashboardLayout>
  );
}

export default DirectFundingInvoiceList;
