import React, { useState } from "react";
import DashboardLayout from "layout/dashboardlayout";
import MarketCards from "./components/cards/MarketCards";
import OpenMarketCard from "./components/cards/OpenMarketCard";
import InvoiceTabbedCard from "./components/cards/InvoiceTabbedCard";
import SimpleCard from "./components/cards/SimpleCard";
import "./components/styles/tabbedinvoice.css";
import RecentTransactionTable from "./components/tables/RecentTransactionTable";
import DebitCardNew from "./components/cards/DebitCardNew";
import { AnimatePresence } from "framer-motion";
import OverviewHeader from "shared/overviewHeader";
import { useSelector } from "react-redux";
import PayableFinanceModal from "features/payableFinancing/components/Modal";


const BuyerDashboard = () => {
  const [toggleModal, setToggleModal] = useState(false);
  const { buyerDashboardData } = useSelector((state) => state.buyer);
  const { allVirtualCardsData } = useSelector((state) => state?.wallet);

  const formatCardNumber = (cardNumber) => {
    if (!cardNumber) {
      return "";
    }

    const sanitizedCardNumber = cardNumber.replace(/\D/g, "");
    const maskedCardNumber = sanitizedCardNumber.replace(
      /(\d{4})(\d{4})(\d{4})(\d{4})/,
      (_, firstPart, middlePart1, middlePart2, lastPart) => {
        const maskedMiddlePart = `${middlePart1.replace(
          /\d/g,
          "*"
        )} ${middlePart2.replace(/\d/g, "*")}`;
        return `${firstPart} ${maskedMiddlePart} ${lastPart}`;
      }
    );

    return maskedCardNumber.trim();
  };

  const formatExpiryDate = (expiryDate) => {
    if (!expiryDate) {
      return "";
    }

    const options = { month: "2-digit", year: "2-digit" };
    return new Date(expiryDate).toLocaleDateString(undefined, options);
  };

  const getFirstCardData = () => {
    const cardData = Array.isArray(allVirtualCardsData?.data)
      ? allVirtualCardsData.data.map((card) => ({
          id: card?.id,
          cardName: card?.cardName,
          cardNumber: formatCardNumber(card?.cardNumber),
          cardCurrency: card?.cardCurrency,
          paymentMethod: card?.paymentMethod,
          balance: card?.balance,
          expiryDate: formatExpiryDate(card?.expiryDate),
        }))
      : [];
    return cardData?.length > 0 ? cardData[0] : null;
  };

  const firstCardData = getFirstCardData();

  function toggleModalOn() {
    setToggleModal(true);
  }

  function toggleModalOff() {
    setToggleModal(false);
  }
  return (
    <DashboardLayout subHeader='Here is what is happening in your finance'>
      <AnimatePresence>
        <PayableFinanceModal isShown={toggleModal} hide={toggleModalOff} />
      </AnimatePresence>
      <div className=' w-full'>
        <OverviewHeader toggleModalOn={toggleModalOn} activateButton />
        <div className='w-full flex flex-col gap-3 md:flex-row md: justify-between mt-5'>
          {/* Content for the first div */}
          <section className='md:w-[48%] '>
            <DebitCardNew cardData={firstCardData} />
            <SimpleCard />
            <RecentTransactionTable />
          </section>
          {/* Content for the second div */}
          <section className=' md:w-[50%] '>
            <div className=' lg:flex-1 py-4  pl-8 rounded-md bg-white w-full pr-6'>
              {/* Card */}
              <OpenMarketCard />

              <div className='flex md:justify-center flex-wrap flex-row gap-4 flex-1 py-5'>
                <MarketCards
                  amount={buyerDashboardData?.totalPayables}
                  title='Payables'
                />
                <MarketCards
                  amount={buyerDashboardData?.totalReceivables}
                  title='Recievables'
                />
                <MarketCards
                  amount={buyerDashboardData?.totalSettledInvoices}
                  title='Settled Invoice'
                />
              </div>
            </div>

            <InvoiceTabbedCard />
          </section>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default BuyerDashboard;
