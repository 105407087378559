import Modal from "layout/modal/Modal";

const ViewDocumentModal = ({ url, onClose, isShown }) => {
  return (
    <>
      <Modal noWidth showClose isShown={isShown} hide={onClose}>
        <iframe
          src={url}
          title="PDF Document"
          width="100%"
          className="bg-none border-none h-screen overflow-hidden"
        />
      </Modal>
    </>
  );
};

export default ViewDocumentModal;
