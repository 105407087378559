import React from 'react';
import Bar from '../../../shared/Bar';

const LandingpageCard = ({heading, content}) => {
  return (
    <div className="bg-white pb-[30px] mb-[30px] hover:scale-105 ease-in-out duration-300">
      <Bar cardBar />
      <div className="px-[24px] mt-[20px]">
        <h1 className="font-medium text-2xl text-black mb-[20px]">
          {heading}
        </h1>
        <p style={{color: "#1D0220"}} className="font-low">
         {content}
        </p>
      </div>
    </div>
  );
};

export default LandingpageCard;
