import Table from "layout/table/Table";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import Card from "shared/Card";
import Loading from "shared/Loading";
import SuccessModal from "shared/SuccessModal";
import TableHeader from "shared/tableHeader/TableHeader";

const SupplierEarlyPaymentBulkInvoice = () => {
  const navigate = useNavigate();
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [productName, setProductName] = useState("");

  const dummyData = [
    {
      invoiceId: 1,
      invoiceReference: "INV-001",
      currency: "USD",
      dueDate: "2023-12-31",
      invoicedAmount: 1000,
      discountedAmount: 900,
      receivableAmount: 950,
      status: "Pending",
    },
    {
      invoiceId: 2,
      invoiceReference: "INV-001",
      currency: "USD",
      dueDate: "2023-12-31",
      invoicedAmount: 1000,
      discountedAmount: 900,
      receivableAmount: 950,
      status: "Pending",
    },
    {
      invoiceId: 3,
      invoiceReference: "INV-002",
      currency: "USD",
      dueDate: "2023-11-30",
      invoicedAmount: 2000,
      discountedAmount: 1800,
      receivableAmount: 1900,
      status: "Accepted",
    },
    {
      invoiceId: 4,
      invoiceReference: "INV-002",
      currency: "USD",
      dueDate: "2023-11-30",
      invoicedAmount: 2000,
      discountedAmount: 1800,
      receivableAmount: 1900,
      status: "Accepted",
    },
    {
      invoiceId: 5,
      invoiceReference: "INV-002",
      currency: "USD",
      dueDate: "2023-11-30",
      invoicedAmount: 2000,
      discountedAmount: 1800,
      receivableAmount: 1900,
      status: "Rejected",
    },
  ];

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setIsSuccessModalOpen(true);
    setSuccessMessage("Offer accepted from Payable Flex");
  };

  const onRowClicked = (id, status) => {
    let path = "";

    switch (status) {
      case "Accepted":
        path = `/supplier-earlypayment/invoice/accepted/${id}`;
        break;
      case "Pending":
        path = `/supplier-earlypayment/invoice/pending/${id}`;
        break;
      case "Rejected":
        path = `/supplier-earlypayment/invoice/rejected/${id}`;
        break;
      default:
        break;
    }

    navigate(path);
  };

  const columns = [
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoicedAmount,
      sortable: true,
    },
    {
      name: "Discount Amount",
      selector: (row) => row.discountedAmount,
      sortable: true,
    },
    {
      name: "Receivable Amount",
      selector: (row) => row.receivableAmount,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Accepted" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Rejected" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "Pending" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  return (
    <>
      <div>
        <Card className="p-[24px] rounded-[10px]">
          <div>
            <TableHeader
              total={`${dummyData?.length || 0} invoices`}
              details={
                <p>
                  List of invoices in{" "}
                  <span className="font-bold">Payable flex</span> Payable
                  finance program
                </p>
              }
              download
              // btnName="Accept payment"
              // handleAccept={handleAccept}
              productName={productName}
              setProductName={setProductName}
              // search={() =>
              //   dispatch(
              //     allInvoicesInCampaign({ page, pageSize, search: productName })
              //   )
              // }
              // accept
            />
          </div>
          <Table
            columns={columns}
            data={dummyData}
            onRowClicked={(row) => onRowClicked(row.invoiceId, row.status)}
            // progressPending={isLoading}
            progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5 min-h-[60vh]"
          />
          <AcceptModal
            isOpen={isAcceptModalOpen}
            onClose={() => setIsAcceptModalOpen(false)}
            message="Are you sure you would want to accept this offer from Payable flex pfp?"
            onConfirm={handleConfirm}
            loading={false}
          />
          <SuccessModal
            isOpen={isSuccessModalOpen}
            onClose={() => setIsSuccessModalOpen(false)}
            message={successMessage}
          />
        </Card>
      </div>
    </>
  );
};

export default SupplierEarlyPaymentBulkInvoice;
