function OpenMarketCard() {
  return (
    <div>
      <div className='py-4'>
        <p className='text-green-800  font-[500]'>Open Market</p>
      </div>

      <div className='flex justify-between  md:flex-row md:items-center md:justify-between'>
        <span className=' flex items-center gap-3 mb-[2px]'>
          <span className=' inline'>
            <img className=' inline' src='/market.svg' alt='market' />
          </span>
          <span className=' flex lg:items-center lg:justify-center lg:gap-2 flex-col md:flex-col lg:flex-row  whitespace-nowrap text-[0.75rem]  lg:text-sm font-[500] '>
            <span>Total Invoices</span>
            <span> Number - 23</span>
          </span>
        </span>
        <button className=' w-[150px] mt-1 border mr-5 border-green-800 rounded-md flex justify-center p-2 items-center  transition duration-500 transform hover:scale-105 ease-in-out hover:bg-whisper200'>
          <span className=' flex justify-center items-center text-sm whitespace-nowrap pr-1.5 '>
            Go to market
          </span>
          <img src='/square-arrow-up.png' alt='market' />
        </button>
      </div>
    </div>
  );
}

export default OpenMarketCard;
