import { PAIconArrowLeftGreen } from "assets/images/svgs";
import Headers from "features/invoice/buyerInvoice/headers";
import Table from "layout/table/Table";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import RejectModal from "../rejectModal";
import { useSelector } from "react-redux";


const Details = () => {
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const navigate = useNavigate();

  const { paymentExtensionData, invoiceIds } = useSelector(
    (state) => state?.paymentExtension
  );

  const invoiceData = paymentExtensionData?.data?.find(
    (extension) => extension.invoiceIds === invoiceIds
  );

  const handleCloseModal = () => {
    setRejectModalOpen(false);
  };

  const handleReject = () => {
    setRejectModalOpen(true);
  };

  const invoice = (id) => {
    let path = `/payment-extension/bulk/invoice/${id}`;
    navigate(path);
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Reference
        </span>
      ),
      selector: (row) => row.reference,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Due Date
        </span>
      ),
      selector: (row) => row.invoiceDueDate,
      sortable: true,
    },
    {
      name: "Extension Date",
      selector: (row) => row.newRequestedDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.invoiceAmount}</p>,
    },
  ];

  return (
    <div>
      <header className="rounded-md w-full bg-white mb-[17px] cursor-pointer items-center">
        <Link
          to="/payment-extension"
          className="flex items-center justifycenter gap-x-[3px] mb-[20px] cursor-pointer py-3 ml-2 my-auto"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>
      </header>
      <Headers />
      <div className="my-3">
        <div className="p-3 px-4 border border-red border-x-[1px] border-t-[0.2px] border-b-0 bg-white rounded-md">
          <h2 className="text-red font-bold my-2">
            Payment Extension Rejected
          </h2>
          <p className="text-sm text-[#959595] mb-2">
            At the current juncture, it has been observed that there is a lack
            of adequate financial viability and a limited scope in the market
            potential, rendering the proposition unfavorable for investment. The
            prevailing conditions suggest that the economic feasibility and
            growth prospects are not conducive for committing resources at this
            point in time.
          </p>
        </div>
      </div>
      {/* Table */}
      {!invoiceData?.data?.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Approved Payment Extension Request
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[90px] rounded-t-[10px] mt-3">
            <div className="py-5">
              <div className="text-black text-md font-bold mt5">
                XYZ Ventures -{" "}
                <span className="text-secondaryColor">(Funder)</span>
              </div>

              <div className="text-xs font-thin flex gap-8 mt-1">
                <span className="text-[#07A04A]">
                  Request ref no. : R343t3xy
                </span>
                <span className="text-gray300">
                  2 invoices-
                  <span className="text-[#07593D] font-bold">
                    (Bulk Extension Requests)
                  </span>
                </span>
              </div>

              <div className="text-xs font-thin flex gap-8 mt-1 mb5">
                <span className="text-gray300">
                  Total invoice amount-
                  <span className="text-[#07593D] font-bold">NGN 83,000</span>
                </span>
                <span className="text-gray300">
                  Total interest amount-
                  <span className="text-[#07593D] font-bold">NGN 22,000</span>
                </span>
              </div>
            </div>
            <div className="flex items-center my-5 gap-5 mt-5">
              <button className="rounded-md bg-[#07593D] text-white px-6 py-2">
                Edit
              </button>
              <button
                className="rounded-md bg-white border border-red text-red px-3 py-2"
                onClick={handleReject}
              >
                Close Request
              </button>
            </div>
          </div>
          <div className="pb-[24px]">
            <Table
              columns={columns}
              data={invoiceData?.data}
              onRowClicked={(row) => invoice(row.invoiceIds)}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
          <RejectModal isOpen={isRejectModalOpen} onClose={handleCloseModal} />
        </Card>
      )}
    </div>
  );
};

export default Details;
