import { PAIconHamburger, PAIconPayEdgeLogo } from "assets/images/svgs";
import React, { useState } from "react";
import Button from "shared/Button";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from "react-redux";
import { getKeycloakInstance, getUserId } from "appstate/auth/authSlice";
import { useEffect } from "react";
import Spinner from "shared/Spinner/Spinner";

const Header = () => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);

  const handleLogin = () => {
    setisLoading(true);
    keycloak.login();
  };

  useEffect(() => {
    if (keycloak.authenticated) {
      dispatch(
        getKeycloakInstance({
          token: keycloak.token,
          authenticated: keycloak.authenticated,
        })
      );
      dispatch(getUserId(keycloak.tokenParsed));
      const path = "/dashboard";
      navigate(path);
      setisLoading(false);
    }
  }, [dispatch, keycloak.authenticated, keycloak, navigate]);

  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const links = [
    {
      id: 1,
      name: "About Us",
    },
    {
      id: 2,
      name: "Careers",
    },
    {
      id: 3,
      name: "Contact Us",
    },
  ];
  return (
    <div className="flex justify-between bg-bgHeader items-center  h-[68px] drop-shadow-lg">
      <div
        onClick={() => {
          navigate("/");
        }}
        className="pl-[24px] cursor-pointer lg:pl-[42px] xl:pl-[75px]"
      >
        <PAIconPayEdgeLogo />
      </div>
      <div className="flex justify-center items-end flex-col relative w-1/2">
        <PAIconHamburger
          onClick={handleClick}
          className="mr-[24px] md:hidden"
        />
        <div
          className={
            !nav
              ? "hidden md:block md:pr-[24px] lg:pr-[42px] xl:pr-[75px]"
              : "absolute top-10 right-0 bg-bgHeader h-40 flex justify-center w-52 md:relative md:w-full md:bg-transparent md:h-0 md:top-0 md:mb-5"
          }
        >
          <ul className="flex flex-col space-y-3 p-2 text-center w-full md:flex-row md:gap-x-[32px] md:space-y-0 md:p-0">
            {links.map((link) => (
              <li
                key={link.id}
                style={{color: "#222222"}}
                className="transition border-b w-full  text-base font-low pb-2 md:pb-0 md:border-0 whitespace-nowrap lg:flex items-center"
              >
                {link.name}
              </li>
            ))}
            <li>
              <Button
                onClick={handleLogin}
                plain
                className=" flex justify-center items-center w-[93px] h-[43px] mt-[10px] text-secondaryColor bg-flashWhite border border-secondaryColor font-medium text-base md:mt-0 whitespace-nowrap hover:bg-primaryColor hover:border-primaryColor"
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Login"}
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
