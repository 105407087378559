import DashboardLayout from "layout/dashboardlayout";
import React from "react";

const SingleUpdated = () => {
  return (
    <DashboardLayout
      section="Payment Extension"
      subHeader="Send and track payment extension processes"
    >
      <div>Updated</div>
    </DashboardLayout>
  );
};

export default SingleUpdated;
