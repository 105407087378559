import React from "react";

const ArrowIcon = () => {
  return (
    <div className="flex h-9 md:h-16 lg:h-9 justify-start items-start">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="w-4 h12 flex justify-start items-start"
      >
        <line x1="5" y1="12" x2="19" y2="12"></line>
        <polyline points="12 5 19 12 12 19"></polyline>
      </svg>
    </div>
  );
};

export default ArrowIcon;
