import {
  PAIconArrowLeftGreen,
  PAIconBasilOutline,
  PAIconOutlineFunds,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import { Link } from "react-router-dom";
import ConnectedPlayersTable from "./connectedPlayersTable";

const ConnectedPlayers = () => {
  return (
    <DashboardLayout
      section="Portfolio Dashboard"
      subHeader="Here is an overview of your invoices"
    >
      <header className="rounded-md w-full bg-white mb-4 cursor-pointer flex items-center px-4">
        <Link
          to="/funds&portfolio"
          className="flex items-center gap-1 py-3 ml-2"
        >
          <PAIconArrowLeftGreen />
          <span className="text-primaryColor">Go Back</span>
        </Link>
      </header>
      <div className="bg-white rounded-md p-3 px-7 pt-4">
        <h5 className="flex items-center justify-between text-base font-bold text-[#4B4B4B]">
          <span className="bg-[#AC83541A] p-1 rounded">
            <PAIconOutlineFunds />
          </span>
          <h3>Connected Players</h3>
          <span className="ml-2 cursor-pointer">
            <PAIconBasilOutline />
          </span>
        </h5>

        <div className="flex flex-wrap justify-between items-center mt-4 text-[#4B4B4B] mb-5">
          <div className="w-full sm:w-auto mb-2 sm:mb-0">
            <p className="text-[16px]">Total Number of Funded Suppliers</p>
            <h5 className="text-[32px] font-bold leading-normal">
              35 <span className="text-[10px]">invoices</span>
            </h5>
            <p className="mt-2 text-[14px]">Total Number of Funded Buyers</p>
            <h5 className="text-[20px] font-bold leading-normal">
              12 <span className="text-[10px]">invoices</span>
            </h5>
          </div>
          <div className="w-full sm:w-auto mb-2 sm:mb-0">
            <p className="text-[14px]">Funded Supplier Invoice Duration</p>
            <h5 className="text-[14px] font-bold leading-normal">
              19th Jun - 17th Dec 2023
            </h5>
            <p className="mt-2 text-[14px] font-normal">
              Funded Buyer Invoice Duration
            </p>
            <h5 className="text-[14px] font-bold leading-normal">
              19th Jun - 17th Dec 2023
            </h5>
          </div>
        </div>

        <ConnectedPlayersTable />
      </div>
    </DashboardLayout>
  );
};

export default ConnectedPlayers;
