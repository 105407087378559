import { motion } from "framer-motion";
import { useSelector } from "react-redux";
function NotificationModal({ toggleModalOff, id }) {
  const { userDetails } = useSelector((state) => state.auth);
  const userRole = userDetails?.data?.role;
  const buyer = useSelector((state) => state?.buyer);
  const supplier = useSelector((state) => state?.supplier);
  const funder = useSelector((state) => state?.funder);
  const { notification } =
    userRole === "BUYER"
      ? buyer
      : userRole === "SUPPLIER"
      ? supplier
      : userRole === "FUNDER"
      ? funder
      : null;
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          delay: 0.3,
        },
      }}
      onClick={() => toggleModalOff()}
      className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[9999] flex justify-center items-center'
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          scale: 0,
          transition: {
            delay: 0.3,
          },
        }}
        className='bg-white w-[50%] h-[76%]  rounded-lg z-[9999]  '
      >
        <motion.div className=' h-full'>
          <motion.div
            exit={{
              x: 20,
              opacity: 0,
              transition: {
                duration: 0.3,
              },
            }}
            className='z-[9999]  h-full flex flex-col justify-start w-full pt-[5%] pb-5 px-[4%] '
          >
            <div className='w-full flex justify-between'>
              <span className=' font-bold '>Upload Document</span>
              <span
                onClick={() => toggleModalOff()}
                className='cursor-pointer '
              >
                <img src='/close.svg' alt='' />
              </span>
            </div>

            <div className='py-7 h-full'>
              <div className=' w-full h-4/5 rounded  bg-[#F2F2F2]'>
                <div className=' w-full flex gap-4 p-4'>
                  <div className=' '>
                    <div className=' flex justify-center items-center w-11 h-11 rounded-[50%] bg-white'>
                      <img
                        className='w-11 h-11 rounded-[50%] text-sm'
                        src={`${notification?.data?.imgUrl}`}
                        alt='dp'
                      />
                    </div>
                  </div>

                  <div className='gap-4  flex flex-col'>
                    <div className=' flex flex-col'>
                      <div className='w-full  flex justify-between'>
                        <span className=' text-[#333135] text-[13px]'>
                          {notification?.data?.notificationType}
                        </span>
                        <span className=' text-[#333135] text-[9px] font-light'>
                          {notification?.data?.createdDate}
                        </span>
                      </div>
                    </div>
                    <div className='text-[#7A7A7A] text-justify overflow-y-auto h-52  text-[11px]'>
                      <p>{notification?.data?.message}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='border-t border-[#CCC] items-center py-[18px] px-5 md:px-8 text-sm w-full flex gap-7 justify-end'>
                <button
                  onClick={() => toggleModalOff()}
                  className='whitespace-nowrap w-24 py-2.5 px-2.5 rounded text-[#7A7A7A] border border-activation transition duration-500 transform hover:scale-105 ease-in-out hover:bg-whisper200'
                >
                  Close
                </button>
                <button className='whitespace-nowrap w-28 py-2.5 rounded text-white bg-green-900 border border-activation transition duration-500 transform hover:scale-[1.04] hover:font-[500] ease-out hover:bg-green-950'>
                  Mark as read
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default NotificationModal;
