import {
  acceptOffer,
  viewOffer,
} from "appstate/supplierEarlyPayment/supplierEarlyPaymentSlice";
import {
  PAIconAccept,
  PAIconInventoryArrowLeft,
  PAIconVieweye,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import Card from "shared/Card";
import LoadingScreen from "shared/LoadingScreen";
import SuccessModal from "shared/SuccessModal";

function SupplierEarlypaymentDetails() {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const navigate = useNavigate();
  const { offerId } = useParams();

  function handleGoback() {
    let path = -1;
    navigate(path);
  }

  const onRowClicked = (row) => {
    let path = `/supplier-earlypayment/invoice/${offerId}/${row.invoiceId}`;
    navigate(path);
  };

  const onClicked = (offerId, invoiceId) => {
    let path = `/supplier-earlypayment/invoice/${offerId}/${invoiceId}`;
    navigate(path);
  };

  const onViewClick = () => {
    let path = `/supplier-earlypayment/bulk-invoice/${offerId}`;
    navigate(path);
  };

  const dispatch = useDispatch();
  const { offerDataById, isLoading } = useSelector(
    (state) => state?.supplierEarlyPaymentOffer
  );

  useEffect(() => {
    dispatch(viewOffer({ offerId }));
  }, [dispatch, offerId]);

  const handleAcceptClick = (row) => {
    setSelectedInvoiceId(row.invoiceId);
    setIsAcceptModalOpen(true);
  };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    dispatch(acceptOffer({ offerId, invIds: selectedInvoiceId })).then(
      (response) => {
        if (response?.payload?.status === 200 && response?.payload?.success) {
          setIsSuccessModalOpen(true);
          setSuccessMessage(response?.payload?.message);
        }
      }
    );
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  const columns = [
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) =>
        Number(row.invoicedAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Discount Amount",
      selector: (row) =>
        Number(row.discountedAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Receivable Amount",
      selector: (row) =>
        Number(row.receivableAmount).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },

    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row.status === "Funded" ? "green" : "orange",
            fontWeight: "bold",
          }}
        >
          {row.status}
        </span>
      ),
      sortable: true,
    },
    {
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div
          className="flex items-center justify-between"
          onClick={() => onClicked(offerId, row.invoiceId)}
        >
          <PAIconVieweye className="w-4 gap-5" /> View
        </div>
      ),
    },
    {
      cell: (row) => (
        <div
          className="flex items-center justify-between"
          onClick={() => handleAcceptClick(row)}
        >
          <PAIconAccept className="w-4 gap-5" /> Accept
        </div>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <div>
        <header className="bg-alabasterHeader px-[20px] py-[10px] rounded-t-[5px]">
          <div
            onClick={handleGoback}
            className="flex items-center gap-x-[3px] mb-[10px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>

          <div className="flex gap-x-[295px] flex-wrap md:gap-x-[100px] lg:gap-x-[180px] xl:gap-x-[295px]">
            <div>
              <p className="text-black text-sm font-medium">Details</p>
              <p className="text-textColor text-xs font-normal">
                Information about the Payable finance program
              </p>
            </div>
          </div>
        </header>

        <Card className="px-[20px] md:px-[40px] pb-[40px] rounded-b-[10px] 2xl:pl-[100px]">
          <div>
            <div className="md:flex md:gap-[40%]  pt-[24px] 2xl:justify-start 2xl:gap-x-[370px] ">
              <div>
                <h2 className="text-lightGray text-sm">Name of Campaign</h2>

                <p className="mt-[8px] text-sm font-normal text-textColor">
                  {offerDataById?.data?.programName}
                </p>
                <p className="mt-[8px] text-lightGray text-sm font-normal">
                  Buyer
                </p>
                <p className="text-sm font-normal text-textColor">
                  {offerDataById?.data?.buyerName}
                </p>
                <div className="mt-[16px]">
                  <p className="text-sm font-normal text-lightGray">
                    Start Date
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {offerDataById?.data?.startDate}
                  </p>
                </div>
                <div className="mt-[16px]">
                  <p className="text-sm font-normal text-lightGray">End Date</p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {offerDataById?.data?.endDate}
                  </p>
                </div>
              </div>

              <div className="mt-[24px] md:mt-0">
                <div>
                  <p className="text-sm font-normal text-lightGray">
                    Amount Range
                  </p>
                  <p className="mt-[8px] text-textColor text-xs font-normal">
                    Min: {parseInt(offerDataById?.data?.minAmount).toLocaleString()}
                  </p>
                </div>
                <div className="mt-[8px]">
                  <p className="mt-[8px] text-textColor text-xs font-normal w-[150px] md:w-full">
                    Max: {parseInt(offerDataById?.data?.maxAmount).toLocaleString()}
                  </p>
                </div>
                <div className="mt-[8px]">
                  <p className="text-sm font-normal text-lightGray">
                    Discount Rate
                  </p>
                  <p className="mt-[4px] mb-2 text-textColor text-sm font-normal">
                    {offerDataById?.data?.discountRate}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-normal text-lightGray">
                    Payment Period
                  </p>
                  <p className="mt-[4px] text-textColor text-sm font-normal">
                    {offerDataById?.data?.paymentPeriod}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-[48px]">
              <div className="flex justify-between items-center">
                <h1 className="text-sm font-semibold md:justify-start text-textColor flex items-center">
                  Your invoice in Campaign
                  <span className="w-[25px] h-[25px] flex justify-center items-center bg-conch ml-[10px] rounded-[50%]">
                    {offerDataById?.data?.campaignInvoices?.length || "0"}
                  </span>
                </h1>
                <h1 onClick={onViewClick} className="underline cursor-pointer">
                  View all
                </h1>
              </div>
              <div className="mt-[24px] pr-[30px]">
                <Table
                  columns={columns}
                  data={offerDataById?.data?.campaignInvoices?.slice(0, 5)}
                  onRowClicked={onRowClicked}
                  pointer
                  tableHeader
                  className="min-h-[35vh]"
                />
              </div>
            </div>
          </div>
          <AcceptModal
            isOpen={isAcceptModalOpen}
            onClose={() => setIsAcceptModalOpen(false)}
            message="Are you sure you would want to accept this offer from Payable flex pfp?"
            onConfirm={handleConfirm}
            loading={false}
          />
          <SuccessModal
            isOpen={isSuccessModalOpen}
            onClose={() => setIsSuccessModalOpen(false)}
            message={successMessage}
          />{" "}
        </Card>
      </div>
    </DashboardLayout>
  );
}

export default SupplierEarlypaymentDetails;
