import OnboardingLayout from "features/onboarding/category/components/OnboardingLayout";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import Select from "shared/Select";

const BuyerOnboardSupplierAddAccount = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <OnboardingLayout cancel={handleClick}>
      <div className="pt-[100px] grid place-content-center h-[100%] overflow-auto md:pt-0 lg:pt-[150px]">
        <h1 className="font-semibold text-[28px] text-primaryColor text-center mb-[50px]">
          Add a bank account
        </h1>
        <form action="" className="flex justify-center flex-col">
          <InputField
            className="w-[300px] py-[13px] outline-none mb-[30px] md:w-[424px]"
            placeholder=""
            label="Bank name"
          />
          <div className="mb-[30px]">
            <Select label="Country of bank" />
          </div>
          <div className="mb-[30px]">
            <Select label="Currency" />
          </div>
          <InputField
            className="w-[300px] mb-[30px] py-[13px] outline-none  md:w-[424px]"
            placeholder="ABC Corporation"
            label="Account holder"
          />
          <InputField
            className="w-[300px] mb-[30px] py-[13px] outline-none md:w-[424px]"
            placeholder=""
            label="Account number"
          />
          <Button auth className="mb-[30px] w-full md:w-[424px]">
            Verify Account
          </Button>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default BuyerOnboardSupplierAddAccount;
