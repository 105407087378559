import React, { useState, useEffect } from "react";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";
import Trash from "../../../../../assets/images/svgs/redtrash.svg";
import Enabled from "../../../../../assets/images/svgs/enable.svg";
import Disenabled from "../../../../../assets/images/svgs/disenable.svg";
import Edit from "../../../../../assets/images/svgs/edit-main.svg";
import Backbutton from "../../../../../assets/images/svgs/backbtnplaceholder.svg";
import { useDispatch, useSelector } from "react-redux";
import Loading from "shared/Loading";
import { getAllCreatedTiers } from "appstate/api/tier";
import { useNavigate } from "react-router-dom";
// import NewPagination from "shared/newpagination";
import { changeTierStatusById, fetchTierById, searchTierByName } from "appstate/tier/tierSlice";

const Tier = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state?.buyer);
  const [openTierModal, setOpenTierModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [tierId, setTierId] = useState("");
  // const {  allTiersData } = useSelector((state) => state.tier);
  // eslint-disable-next-line
  const [getId, setGetId] = useState("");
  const [isDetails, setIsDetails] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // const fetchTiers = async () => {
  //   if (searchTerm) {
  //     await dispatch(searchTierByName(searchTerm));
  //   } else {
  //     const result = await getAllCreatedTiers();
  //     setData(result?.data || []);
  //   }
  // };
  

  useEffect(() => {
    fetchTiers();
    // eslint-disable-next-line
  }, []);




  const fetchTiers = async () => {
    try {
      if (searchTerm) {
        const result = await dispatch(searchTierByName(searchTerm));
        if (result?.payload?.data) {
          setData([result.payload.data]);
        }
      } else {
        const result = await getAllCreatedTiers();
        setData(result?.data || []);
      }
    } catch (error) {
      console.error("Error fetching tiers:", error);
    }
  };
  

  useEffect(() => {
    fetchTiers();
    // eslint-disable-next-line
  }, [searchTerm]);

  const handleSearch = async () => {
    // await fetchTiers();
  };
  
  const GoBack = () => {
    navigate(-1);
  };

  const onRowClicked = async (row) => {
    setTierId(row?.id);
    setIsDetails(true);
    await dispatch(fetchTierById(row?.id));
    setOpenTierModal(true);
  };


  const handleEdit = (row) => {
    setTierId(row?.id);
    setOpenTierModal(true);
    setEdit(true);
  };

  const toggleEnabled = async (tierId) => {
    try {
      await dispatch(changeTierStatusById(tierId));
      fetchTiers(); 
    } catch (error) {
      console.error("Error changing tier status:", error);
    }
  };
  

  const column = [
    { name: "Tier Name", selector: (row) => row?.tierName },
    { name: "Description", selector: (row) => row?.tierDescription },
    { name: "Updated Date", selector: (row) => row?.lastModifiedDate },
    { name: "Status", selector: (row) => !row?.status && "Enabled" },
    {
      cell: (row) => (
        <div className="flex items-center gap-x-[10px]">
          <img
            src={Edit}
            onClick={() => handleEdit(row)}
            alt="Edit"
            className="w-[20px] h-[20px] cursor-pointer"
          />
          <img
            src={Trash}
            alt="Delete"
            className="w-[20px] h-[20px] cursor-pointer"
          />
          {row?.status ? (
            <img
              src={Enabled}
              alt="Enabled"
              className="w-[20px] h-[20px] cursor-pointer"
              onClick={() => toggleEnabled(row?.id)}
            />
          ) : (
            <img
              src={Disenabled}
              alt="Disabled"
              className="w-[20px] h-[20px] cursor-pointer"
              onClick={() => toggleEnabled(row?.id)}
            />
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  // const paginatedData = data.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );

  return (
    <DashboardLayout
      section="Tiers"
      subHeader="Manage all your tiers on the account"
    >
      <div style={{ cursor: "pointer" }} onClick={GoBack}>
        <img src={Backbutton} alt="Go Back" />
      </div>
      <br />
      <Card className="p-[24px] rounded-[10px]">
        <TableHeader
          fetchTiers={fetchTiers}
          tierId={tierId}
          setEdit={setEdit}
          edit={edit}
          openTierModal={openTierModal}
          setOpenTierModal={setOpenTierModal}
          total="All Tiers"
          details="Access details of tiers"
          tier={true}
          isDetails={isDetails}
          setIsDetails={setIsDetails}
          getId={getId}
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <Table
          columns={column}
          data={Array.isArray(data) ? data : []} 
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
        <br />
        <div>
          {/* <NewPagination
            totalItems={data.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
          /> */}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default Tier;
