import React from "react";

import {
  PAIconFilter,
  PAIconOutlineSearch,
  PAIconArrowLeftGreen,
  PAIconDropdown,
} from "assets/images/svgs";

import { PAIconOption } from "assets/images/svgs";
import Table from "layout/table/Table";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import { Link } from "react-router-dom";

import AcceptModal from "shared/AcceptModal";

import TransferInfoModal from "../../components/modals/DisbursementTranferInfoModal";
import TransferInfoPinModal from "../../components/modals/DisbursementTransferInfoPinModal";
import BulkBuyerModal from "../../components/modals/DisbursementBulkBuyerModal";
const Detail = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isTransferInfoModalOpen, setIsTransferInfoModalOpen] = useState(false);
  const [isTransferInfoPinOpen, setIsTransferInfoPinOpen] = useState(false);
  const [isBulkBuyerModalOpen, setIsBulkBuyerModalOpen] = useState(false);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const navigate = useNavigate();

  const handleNavigateToeachInvoice = (id) => {
    navigate(
      `/disbursement/detail/disbursementbulkdirectfundinginnerpagedetails/:id${id}`
    );
  };

  const goBack = () => {
    navigate(-1);
  };
  const dummyData = [
    {
      bidAmount: 1,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 2,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 3,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 4,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 5,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 6,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref. No.
        </span>
      ),
      selector: (row) => row.RequestRefNo,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.InvoiceNumber,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row.Supplier,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.DueDate,
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row.Requestdate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.Currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.InvoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.InvoiceAmount}</p>,
    },

    {
      name: "Discount Amount",
      selector: (row) => row.DiscountAmount,
      sortable: true,
      cell: (row) => <p className="text-[#F08000]">{row.DiscountAmount}</p>,
    },
    {
      name: "Discount Rate",
      selector: (row) => row.DiscountRate,
      sortable: true,
      cell: (row) => <p className="text-success">{row.DiscountRate}</p>,
    },
    {
      name: "Payment Amount",
      selector: (row) => row.PaymentAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.PaymentAmount}</p>,
    },
    {
      cell: (row) => (
        <div>
          <PAIconOption title="Options" onClick={() => {}} />
        </div>
      ),
      width: "5rem",
    },
  ];
  const handleDisburse = () => {
    setIsAcceptModalOpen(true);
  };
  const handleBulkBuyerModal = () => {
    setIsBulkBuyerModalOpen(false);
    setIsTransferInfoPinOpen(true);
  };
  const handleAcceptedModalConfirm = () => {
    setIsAcceptModalOpen(false);
    setIsTransferInfoModalOpen(true);
  };

  return (
    <>
      {/* Parent element */}{" "}
      {isAcceptModalOpen && (
        <AcceptModal
          isOpen={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          message={
            <>Would you like to Initiate Disbursement for this Bulk request?</>
          }
          onConfirm={handleAcceptedModalConfirm}
        />
      )}
      {isTransferInfoModalOpen && (
        <TransferInfoModal
          isOpen={isTransferInfoModalOpen}
          onClose={() => setIsTransferInfoModalOpen(false)}
          onConfirm={() => {
            setIsTransferInfoModalOpen(false);
            setIsBulkBuyerModalOpen(true);
          }}
        />
      )}
      {isTransferInfoPinOpen && (
        <TransferInfoPinModal
          isOpen={isTransferInfoPinOpen}
          onClose={() => setIsTransferInfoPinOpen(false)}
        />
      )}
      {isBulkBuyerModalOpen && (
        <BulkBuyerModal
          isOpen={isBulkBuyerModalOpen}
          onConfirm={handleBulkBuyerModal}
        />
      )}
      <div>
        <div>
          <Link
            onClick={goBack}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full bg-white py-4 rounded-md px-4"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>
        </div>

        <div className="my-2 grid md:grid-cols-2 items-center">
          <div className="gap-3 flex relative">
            <div>
              <div
                className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
                onClick={handleDropdownToggle}
              >
                Filter{" "}
                {selectedFilters.length > 0 && (
                  <span className="bg-gray rounded-lg p-1 px-2">
                    {selectedFilters.length}
                  </span>
                )}{" "}
                Selected <PAIconDropdown />
              </div>
              {isDropdownOpen && (
                <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                  <label className="block">
                    <input
                      type="checkbox"
                      value="date"
                      checked={selectedFilters.includes("date")}
                      onChange={handleFilterChange}
                    />
                    Date
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="name"
                      checked={selectedFilters.includes("name")}
                      onChange={handleFilterChange}
                    />
                    Name
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="currency"
                      checked={selectedFilters.includes("currency")}
                      onChange={handleFilterChange}
                    />
                    Currency
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="amount"
                      checked={selectedFilters.includes("amount")}
                      onChange={handleFilterChange}
                    />
                    Amount
                  </label>
                </div>
              )}
            </div>
            <div>
              <select
                className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
                onChange={(e) => {}}
              >
                <option value="">Sort by</option>
                <option value="date">Date</option>
                <option value="name">Name</option>
              </select>
            </div>
            <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 ">
              Export
            </button>
          </div>
          <div className="flex md:col-span-1 justify-end">
            {/* SAERCH SECTION */}

            <div>
              <div className="flex items-center justify-between gap-5">
                <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                  <div className="items-center flex bg-white">
                    <PAIconOutlineSearch />
                    <input
                      className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                      type="text"
                      placeholder="Search for due invoices awaiting reimbursement"
                    />
                  </div>
                  <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div>
                </div>
                <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                  <PAIconFilter /> Filter
                </button>
              </div>
            </div>
          </div>
        </div>

        <Card className="rounded-[10px] mt-[24px] w-full bg-red pb-11">
          <div class="w-full p-6  justify-center items-center gap-2.5 inline-flex">
            <div class="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
              <div class="self-stretch justify-start items-center gap-6 inline-flex">
                <div class="w-[652px] flex-col justify-start items-start gap-1 inline-flex">
                  <div class="w-[652px] justify-start items-end gap-[33px] inline-flex">
                    <div class="w-[652px] flex-col justify-start items-start gap-2.5 inline-flex">
                      <div>
                        <span className="text-zinc-900 text-base font-bold ">
                          XYZ Ventures -{" "}
                        </span>
                        <span className="text-amber-600 text-sm font-normal ">
                          (Supplier)
                        </span>
                      </div>
                      <div class="h-3.5 justify-center items-end gap-2.5 inline-flex">
                        <div class="w-64 h-[17px] text-green-600 text-sm font-normal ">
                          Request ref no.: R3456X89Y -
                        </div>
                        <div class="w-[247px] h-[17px]">
                          <span className="text-[#949494] text-sm font-normal ">
                            5 invoices -{" "}
                          </span>
                          <span className="text-[#07593D] text-sm font-medium ">
                            (Approved Bulk Requests)
                          </span>
                        </div>
                      </div>
                      <div class=" justify-center items-end gap-2.5 inline-flex">
                        <div class="w-[257px] h-[17px]">
                          <span className="text-[#949494] text-sm font-normal ">
                            Total invoices amounts -
                          </span>
                          <span className="text-[#07593D] text-sm font-normal ">
                            NGN
                          </span>
                          <span className="text-[#949494] text-sm font-normal "></span>
                          <span className="text-amber-600 text-sm font-normal ">
                            83,373,000
                          </span>
                        </div>
                        <div class="w-[231px] h-[17px]">
                          <span className="text-[#949494] text-sm font-normal ">
                            Total Request amount -
                          </span>
                          <span className="text-[#07593D] text-sm ormal ">
                            NGN
                          </span>
                          <span className="text-[#949494] text-sm font-normal ">
                            {" "}
                          </span>
                          <span className="text-amber-600 text-sm font-normal ">
                            546,047
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="justify-start items-start gap-4 flex">
              <button
                class=" px-2.5 py-[12px] bg-green-600 rounded-[5px] justify-center items-center  flex"
                onClick={handleDisburse}
              >
                <div class="text-white text-base font-normal ">
                  Initiate Disbursement
                </div>
              </button>
            </div>
          </div>
          <div className="px-[24px] pb-[24px]">
            <Table
              columns={columns}
              data={dummyData}
              onRowClicked={(row) => handleNavigateToeachInvoice(row.bidAmount)}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default Detail;
