import { PAIconCancel } from "assets/images/svgs";
import React from "react";

import Modal from "layout/modal/Modal";

function ViewMoreInvoiceDetailModal({ onClose, id, isShown, getId }) {
  return (
    <>
      <Modal isShown={isShown} hide={onClose} accountModal>
        <div className="px-[27px] py-[38px]">
          <header className="flex justify-between border-b-2 border-whisper200 pb-[16px]">
            <p class="text-[#222222] text-base font-bold ">About Funder</p>
            <div
              onClick={onClose}
              className="flex justify-between items-center gap-x-[10px] cursor-pointer"
            >
              <PAIconCancel className="w-[8px]" />

              <p class="text-gray800 text-sm font-medium f">Close</p>
            </div>
          </header>
          <h2 className="text-sm font-semibold text-black mt-[32px] mb-[24px]">
            Personal Information
          </h2>
          <div>
            <p className="text-sm font-normal text-lightGray mb-[8px]">Name</p>
            <p className="text-sm font-normal text-textColor mb-[16px]">
              {"Cooper Cooperation Ltd"}
            </p>
            <p className="text-sm font-normal text-lightGray mb-[8px]">Email</p>
            <p className="text-sm font-normal text-textColor mb-[16px]">
              {"www.skyflockltd.com"}
            </p>
            <p className="text-sm font-normal text-lightGray mb-[8px]">
              Contact number
            </p>
            <p className="text-sm font-normal text-textColor mb-[16px]">
              {"+2348974998387"}
            </p>
            <p className="text-sm font-normal text-lightGray mb-[8px]">
              Account Type
            </p>
            <p className="text-sm font-normal text-textColor">
              {"Open Market Funder"}
            </p>
          </div>
          <h2 className="text-sm font-semibold text-black mt-[32px] mb-[24px]">
            Company Information
          </h2>
          <div>
            <p className="text-sm font-normal text-lightGray mb-[8px]">
              Company Address
            </p>
            <p className="text-sm font-normal text-textColor mb-[16px]">
              {"4b Utomi Aire Ave, off Freedom way, Lekki Phase 1"}
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ViewMoreInvoiceDetailModal;
