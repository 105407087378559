import DashboardLayout from "layout/dashboardlayout";
import Details from "../components/carddetails/DirectPaymentRequestDetail";

const DirectEarlyPaymentRequestsDetailContainer = () => {
  return (
    <>
      <DashboardLayout
        section={"Closed Market"}
        subHeader={"Manage your invoices on the closed market"}
      >
        <Details />
      </DashboardLayout>
    </>
  );
};

export default DirectEarlyPaymentRequestsDetailContainer;
