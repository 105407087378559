import request from "apiInstance";
import toast from "react-hot-toast";

export const deleteNotification = async ({ notificationId }) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/notification/delete/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const deleteSelectedNotification = async (body) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/notification/delete`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const markAllNotificationAsRead = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/notification/mark-all-as-read`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const markSelecetedNotificationAsRead = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/notification/mark-as-read`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

