import Table from "layout/table/Table";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";

const RecentTransactionTable = () => {
  const navigate = useNavigate();
  const { buyerDashboardData } = useSelector((state) => state.buyer);
  function handleViewMore() {
    let path = "/wallet/transactions";
    navigate(path);
  }
  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction ID
        </span>
      ),
      selector: (row) => row.transactionId,
      sortable: true,
      width: "8.75rem",
    },

    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
      width: "7.9rem",
    },

    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.amount === "+9,000,000" ? (
          <p className="text-success">{row.amount}</p>
        ) : row.amount === "+4,000,000" ? (
          <p className="text-red">{row.amount}</p>
        ) : (
          <p>{row.status}</p>
        ),
      width: "7rem",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Completed" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Failed" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "Pending" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
      width: "7rem",
    },
    {
      selector: (row) => row.option,
      cell: (row) => (
        <div className="flex">
          <img src="/option-btn.svg" alt="" />
        </div>
      ),
      width: "3rem",
    },
  ];

  return (
    <>
      <Card className="rounded-[10px] mt-[24px]">
        <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
          <div>
            <p className="text-black text-lg font-bold">Recent Transactions</p>
          </div>
          {!buyerDashboardData?.recentTransactions && (
            <span
              onClick={handleViewMore}
              className="text-black text-sm font-medium underline cursor-pointer"
            >
              <img src="/ellipse.svg" alt="" />
            </span>
          )}
        </div>
        <div className="px-[24px] pb-[24px]">
          <Table
            columns={columns}
            data={buyerDashboardData?.recentTransactions}
            pointer
            tableHeader
            className="mt-5"
          />
        </div>
      </Card>
    </>
  );
};

export default RecentTransactionTable;
