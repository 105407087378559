import styled from "styled-components";
import DashboardHeader from "./header/DashboardHeader";
import Sidebar from "./sidebar/Sidebar";
import { COLORS } from "constants/colors";
import { useState } from "react";

function DashboardLayout({ children, section, subHeader, ums }) {
  const [mobileMenu, setMobileMenu] = useState(true);

  function handleMobileMenu() {
    setMobileMenu(!mobileMenu);
  }
  return (
    <DashboardContainer>
      {mobileMenu && <Sidebar ums={ums} />}
      <DashboardContentWrapper>
        <DashboardHeader
          handleMobileMenu={handleMobileMenu}
          section={section}
          subHeader={subHeader}
        />
        <DashboardContent>{children}</DashboardContent>
      </DashboardContentWrapper>
    </DashboardContainer>
  );
}

export default DashboardLayout;

const DashboardContainer = styled.div.attrs({ className: "bg-bgGray" })`
  height: 100vh;
  width: 100%;
  display: flex;
`;

const DashboardContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const DashboardContent = styled.div.attrs({
  className: "p-[15px] md:p-[20px] xl:p-[32px]",
})`
  flex: 1;
  overflow-y: scroll;
  height: 100%;
  background-color: ${COLORS.brightGray};
  .profile-tab {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: red;
  }
`;
