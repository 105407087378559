import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  assignTiersToPlayer,
  changeTierStatus,
  createTiers,
  editTiers,
  getAllCreatedTiers,
  getTierById,
  getTierByName,
} from "appstate/api/tier";

const initialState = {
  isLoading: false,
  allTiersData: [],
  assignTiersLoading: false,
};

export const createNewTier = createAsyncThunk(
  "/admin/tiers/create",
  createTiers
);

export const allTiers = createAsyncThunk("tier/allTiers", getAllCreatedTiers);

export const assignTiers = createAsyncThunk(
  "tier/assignTiers",
 assignTiersToPlayer
);

export const editTier = createAsyncThunk(
  "tier/editTier",
  async ({ tierId, body }) => {
    const response = await editTiers({ tierId, body });
    return response;
  }
);

export const fetchTierById = createAsyncThunk(
  "tier/fetchById",
  async (tierId) => {
    const response = await getTierById(tierId);
    return response;
  }
);

export const searchTierByName = createAsyncThunk(
  "tier/searchByName",
  async (tierName) => {
    const response = await getTierByName(tierName);
    return response;
  }
)

export const changeTierStatusById = createAsyncThunk(
  "tier/changeTierStatus",
  async (tierId) => {
    const response = await changeTierStatus(tierId);
    return response;
  }
);


export const tierSlice = createSlice({
  name: "tier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createNewTier.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createNewTier.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createNewTier.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allTiers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allTiers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allTiersData = action.payload;
    });
    builder.addCase(allTiers.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(assignTiers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignTiers.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignTiers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(editTier.pending, (state) => {
      state.editTiersLoading = true;
    });
    builder.addCase(editTier.fulfilled, (state) => {
      state.editTiersLoading = false;
    });
    builder.addCase(editTier.rejected, (state) => {
      state.editTiersLoading = false;
    });
    builder.addCase(fetchTierById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTierById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tierDetails = action.payload;
    });
    builder.addCase(fetchTierById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(searchTierByName.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchTierByName.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allTiersData = action.payload;
    });
    builder.addCase(searchTierByName.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changeTierStatusById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeTierStatusById.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(changeTierStatusById.rejected, (state) => {
      state.isLoading = false;
    });
    
  },
});

export default tierSlice.reducer;
