import request from "apiInstance";
import toast from "react-hot-toast";

export const createNewRole = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/admin/permissions/role/create",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllRoles = async ({ page = 0, pageSize = 10, search = "" }) => {
  try {
    const response = await request({
      method: "get",
      url: `/admin/permissions/roles?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const assignRolePermission = async (body) => {
  try {
    const response = await request({
      method: "put",
      url: "/admin/permissions/role/assign",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllPermissionsAssigned = async ({ roleId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/admin/permissions/assigned/${roleId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllPermissions = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/admin/permissions",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllSubPermissions = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/admin/permissions/sub",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const inviteTeamMember = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/admin/permissions/invite-member",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const onboardNewInvite = async (body, memberId) => {
  try {
    const response = await request({
      method: "put",
      url: `/admin/permissions/complete-onboarding/${memberId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const deleteMember = async (memberId) => {
  try {
    const response = await request({
      method: "delete",
      url: `/admin/permissions/member/${memberId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const roleUpdate = async ({ memberId, roleId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/admin/permissions/member/${memberId}?roleId=${roleId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllMembers = async ({ page = 0, pageSize = 10 }) => {
  try {
    const response = await request({
      method: "get",
      url: `/admin/permissions/members?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
