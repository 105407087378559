import { PAIconArrowLeftGreen, PAIconHappy } from "assets/images/svgs";
import Headers from "features/invoice/buyerInvoice/headers";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import FeedbackModal from "../../FeedbackModal";
import RejectModal from "features/ToolsAgreement/components/Agreement/AgreementDetails/Modal/RejectModal";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  allNewDirectFunding,
  approveOrDeclineDirectFunding,
  getDirectFundingInvoiceId,
} from "appstate/funder/funderSlice";
import { extractDate } from "utilities/helper/dateFormater";

const Details = () => {
  const [open, setOpen] = useState(false);
  const [reject, setReject] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [openFeedback, setOpenFeedbackModal] = useState(false);
  const [page] = useState(0);
  const pageSize = 10;
  const { newDirectFundingData, directFundingApproveLoading } = useSelector(
    (state) => state?.funder
  );

  const [response, setResponse] = useState([
    { id: 1, name: "Unreliable History" },
    { id: 2, name: "Financial Instability" },
    { id: 3, name: "Repeated Extensions Requests" },
    { id: 4, name: "Violation of Terms" },
  ]);

  const [selectedResponse, setSelectedResponse] = useState([]);

  const handleResponseClick = (res) => {
    const itemIndex = response.findIndex((i) => i.id === res.id);
    if (itemIndex !== -1) {
      const updatedItems = [...response];
      updatedItems.splice(itemIndex, 1);
      setResponse(updatedItems);

      setSelectedResponse([...selectedResponse, res]);
    } else {
      const selectedItemIndex = selectedResponse.findIndex(
        (i) => i.id === res.id
      );
      if (selectedItemIndex !== -1) {
        const updatedSelectedItems = [...selectedResponse];
        updatedSelectedItems.splice(selectedItemIndex, 1);
        setSelectedResponse(updatedSelectedItems);

        setResponse([...response, res]);
      }
    }
  };

  const [data, setData] = useState([]);

  function handleCloseModal() {
    setOpen(false);
    setShowRejectModal(false);
    setOpenFeedbackModal(false);
  }

  const handleAcceptModalOpen = () => {
    setOpen(true);
    setReject(false);
  };

  const handleOpenFeedbackModal = () => {
    setOpenFeedbackModal(true);
    setOpen(false);
  };

  const handleOpenRejectModal = () => {
    setOpen(true);
    setReject(true);
  };

  const dispatch = useDispatch();

  const search = "";

  useEffect(() => {
    dispatch(
      allNewDirectFunding({
        page,
        pageSize,
        bulk: true,
        search,
        status: "PENDING",
      })
    );
  }, [page, pageSize, dispatch]);

  useEffect(() => {
    setData(newDirectFundingData?.data);
  }, [newDirectFundingData]);

  const invoicesArray = data[0]?.invoice;

  const ids = invoicesArray?.map((invoiceId) => invoiceId?.id);

  // console.log()

  const handleConfirm = () => {
    dispatch(
      approveOrDeclineDirectFunding({
        id: ids,
        comment: "",
        status: "APPROVED",
      })
    ).then((data) => {
      if (data?.payload?.success) {
        setOpen(false);
        toast.custom(
          <div className="bg-white shadow-lg w-[529px] p-[27px] rounded-[10px] mt-[50px]">
            <header>
              <h1 className="text-textColor text-base font-bold">
                Direct Funding request accepted
              </h1>
            </header>
            <div className="flex justify-center my-[24px]">
              <PAIconHappy />
            </div>
            <p className="text-textColor text-base ">
              Congratulations! The Direct Funding request has been approved.
              Your understanding and cooperation are greatly appreciated. Thank
              you.
            </p>
          </div>
        );
      }
    });
  };

  const comments = selectedResponse.map((selected) => selected.name).join(", ");

  const handleRejectDirectFunding = () => {
    dispatch(
      approveOrDeclineDirectFunding({
        id: ids,
        comment: comments,
        status: "DECLINED",
      })
    ).then((data) => {
      if (data?.payload?.success) {
        setShowRejectModal(true);
        setOpen(false);
        setOpenFeedbackModal(false);
      }
    });
  };

  const navigate = useNavigate();

  const onRowClicked = (row) => {
    dispatch(getDirectFundingInvoiceId(row?.id));
    let path =
      "/invoice/invoice/newbulkdirectfundingypaymeninvoicetdetails/approved";
    navigate(path);
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref.No.
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => extractDate(row.dueDate),
      sortable: true,
    },
    {
      name: "Request date",
      selector: (row) => extractDate(row.createdDate),
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.total}</p>,
    },
    {
      name: "Discount Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-yellow-400">{row.subTotal}</p>,
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
      sortable: true,
      cell: (row) => <p className="text-success">{row.discountRate}</p>,
    },
  ];

  return (
    <div>
      <AcceptModal
        message={
          reject
            ? "Would you like to proceed with Rejecting this Direct Funding request?"
            : "Would you like to proceed with Approving this Direct Funding request?"
        }
        isOpen={open}
        onClose={handleCloseModal}
        onConfirm={!reject ? handleConfirm : handleOpenFeedbackModal}
        loading={directFundingApproveLoading}
      />
      <FeedbackModal
        setOpenFeedbackModal={setOpenFeedbackModal}
        setShowRejectModal={setShowRejectModal}
        isOpen={openFeedback}
        onClose={handleCloseModal}
        loading={directFundingApproveLoading}
        response={response}
        selectedResponse={selectedResponse}
        handleSubmit={handleRejectDirectFunding}
        handleSelect={handleResponseClick}
      />
      <RejectModal isOpen={showRejectModal} onClose={handleCloseModal} />
      <header className="rounded-md w-full bg-white mb-[17px] cursor-pointer items-center">
        <Link
          to="/invoice/invoice"
          className="flex items-center justifycenter gap-x-[3px] mb-[20px] cursor-pointer py-3 ml-2 my-auto"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>
      </header>
      <Headers />

      {/* Table */}
      {!data?.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Approved Early Payment Request
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[90px] rounded-t-[10px] mt-3">
            {data.map((detail, idx) => (
              <div key={idx} className="py-5">
                <div className="text-black text-md font-bold mt5">
                  {detail?.invoice[0]?.supplierCompanyName} -{" "}
                  <span className="text-secondaryColor">(Supplier)</span>
                </div>
                <div className="text-xs font-thin flex gap-8 mt-1">
                  <span className="text-[#07A04A]">
                    Request ref no. : {detail?.reference}
                  </span>
                  <span className="text-gray300">
                    {invoicesArray?.length} invoices-
                    <span className="text-[#07593D] font-bold">
                      (Bulk New Requests)
                    </span>
                  </span>
                </div>

                <div className="text-xs font-thin flex gap-8 mt-1 mb5">
                  <span className="text-gray300">
                    Total invoice amount-
                    <span className="text-[#07593D] font-bold">NGN 83,000</span>
                  </span>
                  <span className="text-gray300">
                    Total request amount-
                    <span className="text-[#07593D] font-bold">
                      NGN {detail?.newRequestedAmount}
                    </span>
                  </span>
                </div>
              </div>
            ))}
            <div className="flex gap-x-[16px]">
              <div
                onClick={handleAcceptModalOpen}
                className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
              >
                Approve Request
              </div>

              <div
                onClick={handleOpenRejectModal}
                className="rounded-[5px] cursor-pointer bg-white border border-secondaryColor  shadow-md text-secondaryColor text-xs font-medium py-[10px] px-[20px]"
              >
                Reject Request
              </div>
            </div>
          </div>
          <div className="pb-[24px]">
            <Table
              columns={columns}
              data={invoicesArray}
              onRowClicked={onRowClicked}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default Details;
