import React from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/Pagination";
import { useDispatch } from "react-redux";
import { getDirectFundingInvoiceId } from "appstate/funder/funderSlice";
import Loading from "shared/Loading";

const SingleNewDirectFunding = ({
  handleSelectAll,
  selectAll,
  handleCheckboxChange,
  SingleDirectFundingPaymentList,
  selectedRows,
  setPage,
  startPage,
  setStartPage,
  endPage,
  setEndPage,
  totalPages,
  page,
  isLoading,
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const singleDirectFundingDetails = (row) => {
    dispatch(getDirectFundingInvoiceId(row?.id));
    let path = "/invoice/invoice/newsingledirectfunding/approved";
    navigate(path);
  };

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      selector: "",
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.id)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Reference
        </span>
      ),
      selector: (row) => row.reference,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice No.
        </span>
      ),
      selector: (row) => row.invoiceNumber,
      sortable: true,
    },
    // {
    //   name: (
    //     <span className="whitespace-nowrap">
    //       <img className=" pr1 inline" src="/group-icon.svg" alt="" />
    //       Supplier
    //     </span>
    //   ),
    //   selector: (row) => row.buyerCompanyName,
    //   sortable: true,
    // },
    {
      name: "Due Date",
      selector: (row) => row.invoiceDueDate,
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row.newRequestedDate,
      sortable: true,
    },
    {
      name: "Tier",
      selector: (row) => row.tierName,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.invoiceAmount}</p>,
    },
    {
      name: "Discount Amount",
      selector: (row) => row.newRequestedAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-yellow-400">{row.newRequestedAmount}</p>
      ),
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
      sortable: true,
      cell: (row) => <p className="text-success">{row.discountRate}</p>,
    },
  ];

  return (
    <>
      {!SingleDirectFundingPaymentList?.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Approved Early Payment Request
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="px[15px] pb-[24px]">
            <Table
              columns={columns}
              data={SingleDirectFundingPaymentList}
              onRowClicked={(row) => singleDirectFundingDetails(row)}
              pointer
              progressPending={isLoading}
              progressComponent={<Loading />}
              tableHeader
              className="mt-5"
            />
          </div>
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading && totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </Card>
      )}
    </>
  );
};

export default SingleNewDirectFunding;
