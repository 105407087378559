import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import Button from "shared/Button";
import BuyerDetailsModal from "../modal";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AccountModalContainer from "shared/accountModal/Modal";
import {
  acceptSupplierRequest,
  buyerRejectRequestSupplier,
  getBuyerPendingAssociateRequest,
  onboardingInviteSupplier,
} from "appstate/buyer/buyerSlice";
import toast from "react-hot-toast";
// import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import DeclineModal from "shared/delineModal/Modal";
import UserAdd from "../../../../../assets/images/svgs/useradd.svg";
import InviteModal from "shared/inviteModal";
import NewPagination from "shared/newpagination";

const dummyInviteData = [
  {
    email: "dummy.supplier1@example.com",
    companyName: "Dummy Supplier 1",
    notificationId: "1",
  },
  {
    email: "dummy.supplier2@example.com",
    companyName: "Dummy Supplier 2",
    notificationId: "2",
  },
  {
    email: "dummy.supplier3@example.com",
    companyName: "Dummy Supplier 3",
    notificationId: "3",
  },
];

const dummyPendingRequestData = [
  {
    name: "John Doe",
    company: "ABC Corp",
    email: "john.doe@abc.com",
    vendorId: "V12345",
    phone: "+1234567890",
    status: "Pending",
  },
  {
    name: "Jane Smith",
    company: "XYZ Ltd",
    email: "jane.smith@xyz.com",
    vendorId: "V67890",
    phone: "+0987654321",
    status: "Pending",
  },
  {
    name: "Tom Brown",
    company: "Acme Inc",
    email: "tom.brown@acme.com",
    vendorId: "V11223",
    phone: "+5647382910",
    status: "Pending",
  },
];

const dummyPendingAssociationData = [
  {
    name: "Alice Johnson",
    company: "Global Tech",
    email: "alice.johnson@globaltech.com",
    vendorId: "A12345",
    phone: "+1415926535",
  },
  {
    name: "Bob White",
    company: "Innovative Solutions",
    email: "bob.white@innovativesolutions.com",
    vendorId: "A67890",
    phone: "+9876543210",
  },
  {
    name: "Charlie Davis",
    company: "Future Enterprises",
    email: "charlie.davis@futureenterprises.com",
    vendorId: "A99887",
    phone: "+4455667788",
  },
];

const BuyerSuppliersPendingRequest = () => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Pending Requests");
  const {
    pendingAssociateRequestData,
    isLoading,
    declineLoading,
    inviteLoading,
    associateLoading,
  } = useSelector((state) => state.buyer);
  const [getId, setGetId] = useState(null);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [success, setSuccess] = useState(false);
  const [companyName, setCompanyName] = useState("");
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [data, setData] = useState([]);
  const [invite, setInvite] = useState([]);
  const [invitee, setInvitee] = useState([]);
  const [newInvite, setNewInvite] = useState("");

  const dispatch = useDispatch();
  const supplierDetails = pendingAssociateRequestData?.data?.find(
    (cur) => cur?.userId === getId
  );
  const sender = "supplier";
  useEffect(() => {
    const search = "";
    dispatch(
      getBuyerPendingAssociateRequest({ sender, search, page, pageSize })
    );
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    if (selectedTab === "Pending Requests" && data.length === 0) {
      setData(dummyPendingRequestData);
    }
  }, [data, selectedTab]);

  useEffect(() => {
    if (selectedTab === "Pending Invites") {
      setData(dummyInviteData);
    } else if (selectedTab === "Pending Association") {
      setData(dummyPendingAssociationData);
    } else if (selectedTab === "Pending Requests") {
      setData(dummyPendingRequestData);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (companyName.length <= 0) {
      setData(pendingAssociateRequestData?.data);
    }
  }, [pendingAssociateRequestData, companyName]);

  const onRowClicked = (row) => {
    setOpenDetailsModal(true);
    setGetId(row?.userId);
  };
  // const handleOpenModal = (row) => {
  //   setGetId(row?.notificationId);
  //   setOpened(true);
  // };
  // const handleOpenDeclineModal = (row) => {
  //   setGetId(row?.notificationId);
  //   setOpenDeclineModal(true);
  // };
  const handleAssociate = () => {
    const notificationId = getId;
    const search = "";

    dispatch(acceptSupplierRequest(notificationId)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(false);
        toast.success(data?.payload?.message);
        dispatch(
          getBuyerPendingAssociateRequest({ sender, search, page, pageSize })
        );
        setOpened(false);
      }
    });
  };
  const handleSearch = () => {
    const search = companyName;
    dispatch(
      getBuyerPendingAssociateRequest({ sender, search, page, pageSize })
    ).then((data) => {
      setData(data?.payload?.data);
    });
  };

  const handleDecline = () => {
    const notificationId = getId;
    const search = "";

    dispatch(buyerRejectRequestSupplier({ notificationId })).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(false);
        setOpenDeclineModal(false);
        dispatch(
          getBuyerPendingAssociateRequest({ sender, search, page, pageSize })
        );
      }
    });
  };

  // const totalPages = pendingAssociateRequestData?.meta?.totalElements;

  const column = [
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      cell: (row) => {
        return (
          <div className="flex gap-x-[12px]">
            <Button
              // onClick={() => handleOpenModal(row)}
              neutral
              className="flex px-[16px] py-[8px] border-[1px] border-gray rounded-[5px] bg-primaryColor"
            >
              <p className="text-xs font-normal text-white whitespace-nowrap">
                Resend Invite
              </p>
            </Button>
          </div>
        );
      },
    },
  ];

  const columnsPendingAssociation = [
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "VendorId",
      selector: (row) => row?.vendorId,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
    },
    {
      cell: (row) => (
        <div className="flex gap-x-[12px]">
          <Button
            // onClick={() => handleOpenModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-gray rounded-[5px] bg-primaryColor"
          >
            <p className="text-xs font-normal text-white whitespace-nowrap">
              Accept
            </p>
          </Button>
          <Button
            // onClick={() => handleOpenModal(row)}
            neutral
            className="flex px-[16px] py-[8px] border-[1px] border-[red] rounded-[5px] bg-[white]"
          >
            <p className="text-xs font-normal  text-[red] whitespace-nowrap">
              Decline
            </p>
          </Button>
        </div>
      ),
    },
  ];

  const columnsPendingRequest = [
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
    },
    {
      name: "VendorId",
      selector: (row) => row?.vendorId,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{
            color: row?.status === "Pending" ? "orange" : "inherit",
          }}
        >
          {row?.status}
        </span>
      ),
    },
  ];

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleSubmitEmails = () => {
    const body = invite;
    dispatch(onboardingInviteSupplier(body)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(true);
        setInvite([]);
      }
    });
  };

  return (
    <DashboardLayout
      section="Suppliers"
      subHeader="Manage all your associated suppliers account"
    >
      <InviteModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        message=" The Supplier would received a mail that your are inviting to join
          PayEdge."
        heading="Invite Supplier"
        info="You can add one or more supplier at once with ease"
        role="Supplier"
        invite={invitee}
        setInvite={setInvitee}
        newInvite={newInvite}
        setNewInvite={setNewInvite}
        success={success}
        setSuccess={setSuccess}
        handleAdd={handleSubmitEmails}
        isLoading={inviteLoading}
      />
      <div
        style={{ padding: 15, borderRadius: 5 }}
        className="flex justify-between items-center bg-white"
      >
        <div className="w-[40%] flex justify-between">
          <h3
            className={`cursor-pointer ${
              selectedTab === "Pending Requests" ? "text-[#07593D]" : ""
            }`}
            onClick={() => handleTabClick("Pending Requests")}
            style={{
              borderBottom:
                selectedTab === "Pending Requests"
                  ? "2px solid #07593D"
                  : "none",
              paddingBottom: "5px",
              fontSize: "14px",
              color: "#959595",
            }}
          >
            Pending Requests
          </h3>
          <h3
            className={`cursor-pointer ${
              selectedTab === "Pending Association" ? "text-[#07593D]" : ""
            }`}
            onClick={() => handleTabClick("Pending Association")}
            style={{
              borderBottom:
                selectedTab === "Pending Association"
                  ? "2px solid #07593D"
                  : "none",
              paddingBottom: "5px",
              fontSize: "14px",
              color: "#959595",
            }}
          >
            Pending Association
          </h3>
          <h3
            className={`cursor-pointer ${
              selectedTab === "Pending Invites" ? "text-[#07593D]" : ""
            }`}
            onClick={() => handleTabClick("Pending Invites")}
            style={{
              borderBottom:
                selectedTab === "Pending Invites"
                  ? "2px solid #07593D"
                  : "none",
              paddingBottom: "5px",
              fontSize: "14px",
              color: "#959595",
            }}
          >
            Pending Invites
          </h3>
        </div>
        <div>
          <Button
            onClick={() => setOpen(true)}
            neutral
            className="flex text-[#F08000] text-xs font-medium items-center mb-[10px] md:mb-0 md:px-[5px] bg-[#07593D] py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
          >
            <img src={UserAdd} alt="" />
            <p style={{ color: "white" }} className="whitespace-nowrap">
              Invite Supplier
            </p>
          </Button>
        </div>
      </div>

      <br />
      <div>
        {selectedTab === "Pending Requests" && (
          <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Go Back
              </p>
            </div>
            <div className="flex justify-between flex-wrap ">
              <div>
                <p className="text-black text-sm font-medium">
                  Pending requests to associate with suppliers
                </p>
                <p className="text-textColor text-xs font-normal">
                  List of association request sent out to suppliers
                </p>
              </div>
              <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                  <PAIconSearch />
                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="Search request"
                    className="placeholder:text-xs outline-none"
                  />
                </div>
                <Button
                  neutral
                  onClick={handleSearch}
                  disabled={data < 1}
                  className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
                >
                  Search
                </Button>
              </div>
            </div>
          </header>
        )}

        {selectedTab === "Pending Association" && (
          <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Go Back
              </p>
            </div>
            <div className="flex justify-between flex-wrap ">
              <div>
                <p className="text-black text-sm font-medium">
                  Pending requests to associate
                </p>
                <p className="text-textColor text-xs font-normal">
                  List of suppliers wanting to associate
                </p>
              </div>
              <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                  <PAIconSearch />
                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="Search request"
                    className="placeholder:text-xs outline-none"
                  />
                </div>
                <Button
                  neutral
                  onClick={handleSearch}
                  disabled={data < 1}
                  className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
                >
                  Search
                </Button>
              </div>
            </div>
          </header>
        )}

        {selectedTab === "Pending Invites" && (
          <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Go Back
              </p>
            </div>
            <div className="flex justify-between flex-wrap ">
              <div>
                <p className="text-black text-sm font-medium">
                  Pending invites to suppliers
                </p>
                <p className="text-textColor text-xs font-normal">
                  List of invites sent out to suppliers
                </p>
              </div>
              <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
                <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                  <PAIconSearch />
                  <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder="Search request"
                    className="placeholder:text-xs outline-none"
                  />
                </div>
                <Button
                  neutral
                  onClick={handleSearch}
                  disabled={data < 1}
                  className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
                >
                  Search
                </Button>
              </div>
            </div>
          </header>
        )}

        <BuyerDetailsModal
          isShown={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          data={supplierDetails}
        />
        <DeclineModal
          isShown={openDeclineModal}
          onClose={() => setOpenDeclineModal(false)}
          success={success}
          handleAssociate={handleDecline}
          isLoading={declineLoading}
          warning="Are you sure you would like to Decline this request?"
        />
        <AccountModalContainer
          isShown={opened}
          onClose={() => setOpen(false)}
          success={success}
          handleAssociate={handleAssociate}
          isLoading={associateLoading}
          warning="Are you sure you would like to associate with the supplier?"
        />
        <div className="p-[24px] bg-white rounded-b-[10px]">
          {selectedTab === "Pending Association" && (
            <Table
              columns={columnsPendingAssociation}
              data={data}
              pointer
              onRowClicked={onRowClicked}
              progressPending={isLoading}
              progressComponent={<Loading />}
              tableHeader
            />
          )}

          {selectedTab === "Pending Requests" && (
            <Table
              columns={columnsPendingRequest}
              data={data}
              pointer
              onRowClicked={onRowClicked}
              progressPending={isLoading}
              progressComponent={<Loading />}
              tableHeader
            />
          )}

          {selectedTab === "Pending Invites" && (
            <Table
              columns={column}
              data={data}
              pointer
              onRowClicked={onRowClicked}
              progressPending={isLoading}
              progressComponent={<Loading />}
              tableHeader
            />
          )}
          <br />
          <div className="">
            <NewPagination />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default BuyerSuppliersPendingRequest;
