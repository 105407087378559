import PayEdge from "../../assets/images/svgs/PayEdge13.svg";
import Illustration from "../../assets/images/svgs/IllustrationG.svg";
import Button from "shared/Button";
import { verifyEmail } from "appstate/api/auth/onboarding";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";

const VerifyEmail = () => {
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const verifyUserEmail = async () => {
            const params = new URLSearchParams(window.location.search);
            const tokenFromUrl = params.get("token");
            if (tokenFromUrl) {
                try {
                    const response = await verifyEmail(tokenFromUrl);
                    if (response?.message === "The link is expired, visit the application to resend the link") {
                        navigate(`/resend-verification-link?email=${response?.data?.email}&fullName=${response?.data?.fullName}`);
                    }
                    setUser(response?.data);
                } catch (error) {
                    toast.error("Error verifying email");
                }
            }
        };

        verifyUserEmail();
    }, [navigate]);

    const GoToHome = () => {
        keycloak.login();
    };

    if (!user?.fullName) {
        return null;
    }

    return (
        <div className="w-full">
            <div className="flex justify-center">
                <img src={PayEdge} alt='' />
            </div>
            <div className="bg-[#07593D] p-[30px] mt-[50px]">
                <h2 className="text-center text-white font-bold text-xl">Welcome to Payedge</h2>
            </div>
            <div className="mt-20 flex justify-center">
               <img src={Illustration} alt='' />
            </div>
            <div className="mt-10 w-[95%] mx-auto">
                <h3 className="font-bold text-black text-xl">Hi {user?.fullName},</h3>
            </div>
            <div className="mt-2 w-[95%] mx-auto">
                <p>Congratulations!</p>
            </div>
            <div className="mt-3 w-[95%] mx-auto flex flex-col">
                 <span style={{fontSize: 15, fontWeight: 300, color: "#565656"}}>Your email account, <span style={{fontSize: 15, fontWeight: 800}}>{user?.email}</span>, has been successfully verified.</span>
                 <span style={{fontSize: 15, fontWeight: 300, color: "#565656"}}>Please note that this email address will be used as your username to log in to your</span>
                 <span style={{fontSize: 15, fontWeight: 300, color: "#565656"}}>account</span>
            </div>
            <div className="mt-20 flex justify-center">
                <Button onClick={GoToHome}>Continue to your account</Button>
            </div>
            <div className="mt-10">
                <h2 className="font-bold text-black text-center text-xl">The PayEdge Team.</h2>
            </div>
        </div>
    );
};

export default VerifyEmail;
