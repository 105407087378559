import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  createWorkflowApi,
  getAllWorkflowApi,
  getWorkflowByIDApi,
  modifyWorkflowStatusApi,
} from "appstate/api/workflow";

const initialState = {
  isLoading: false,
  getAllWorkflowData: [],
  getWorkflowByIdData: [],
};

export const createWorkflow = createAsyncThunk(
  "workflow/createWorkflow",
  createWorkflowApi
);

export const getAllWorkflow = createAsyncThunk(
  "workflow/getAllWorkflow",
  getAllWorkflowApi
);

export const getWorkflowById = createAsyncThunk(
  "workflow/getWorkflowById",
  getWorkflowByIDApi
);

export const modifyWorkflowStatus = createAsyncThunk(
  "workflow/modifyWorkflowStatus",
  modifyWorkflowStatusApi
);

export const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createWorkflow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createWorkflow.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(createWorkflow.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAllWorkflow.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllWorkflow.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getAllWorkflowData = action.payload;
    });
    builder.addCase(getAllWorkflow.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getWorkflowById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWorkflowById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getWorkflowByIdData = action.payload;
    });
    builder.addCase(getWorkflowById.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(modifyWorkflowStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(modifyWorkflowStatus.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(modifyWorkflowStatus.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default workflowSlice.reducer;
