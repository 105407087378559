import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllRecievables,
  getSupplierOverview,
  getAllTransactions,
  sendRemainder,
  getFunderOverview,
} from "appstate/api/collections/collections";

const initialState = {
  activeTab: "overview",
  isLoading: false,
  supplierOverviewData: null,
  funderOverviewData: null,
  recievablesData: [],
  transactionData: [],
  remainderMessage: null,
};

export const supplierOverview = createAsyncThunk(
  "supplierOverview",
  getSupplierOverview
);

export const funderOverview = createAsyncThunk(
  "funderOverview",
  getFunderOverview
);

export const allRecievables = createAsyncThunk(
  "allRecievables",
  getAllRecievables
);

export const allTransactions = createAsyncThunk(
  "allTransactions",
  getAllTransactions
);

export const remainder = createAsyncThunk("remainder", sendRemainder);

const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    // supplier overview
    builder.addCase(supplierOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(supplierOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.supplierOverviewData = action.payload;
    });
    builder.addCase(supplierOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // funder overview
    builder.addCase(funderOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderOverviewData = action.payload;
    });
    builder.addCase(funderOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // RECIEVABLES TABLE DATA
    builder.addCase(allRecievables.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allRecievables.fulfilled, (state, action) => {
      state.isLoading = false;
      state.recievablesData = action.payload;
    });
    builder.addCase(allRecievables.rejected, (state) => {
      state.isLoading = false;
    });
    // TRANSACTION TABLE DATA
    builder.addCase(allTransactions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionData = action.payload;
    });
    builder.addCase(allTransactions.rejected, (state) => {
      state.isLoading = false;
    });
    // SEND REMAINDER
    builder.addCase(remainder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(remainder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.remainderMessage = action.payload;
    });
    builder.addCase(remainder.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setActiveTab } = collectionSlice.actions;
export default collectionSlice.reducer;
