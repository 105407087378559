import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Details from "./details";

const BulkDirectFundingPageDetails = () => {
  return (
    <DashboardLayout
      section="Reimbursement"
      subHeader="Track invoices due for reimbursement"
    >
      <Details />
    </DashboardLayout>
  );
};

export default BulkDirectFundingPageDetails;
