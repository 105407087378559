import Modal from "layout/modal/Modal";
import React from "react";
import SingleRequestModalContent from "./SingleRequestModalContent";
import SingleRequestModalContentBuyer from "./SingleRequestModalContentBuyer";

const SingleRequestModal = ({ isShown, onClose, data, fromBuyer }) => {
  return (
    <Modal largeModal accountModal isShown={isShown} hide={onClose}>
      {fromBuyer === true ? (
        <SingleRequestModalContentBuyer data={data} onClose={onClose} />
      ) : (
        <SingleRequestModalContent data={data} onClose={onClose} />
      )}
    </Modal>
  );
};

export default SingleRequestModal;
