import { getCsvFileData } from "appstate/invoice/invoiceSlice";
import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";

const ValidationMaping = () => {
  const { savedCsvData } = useSelector((state) => state?.invoice);
  const [mappedData, setMappedData] = useState(savedCsvData);
  const [validationStatus, setValidationStatus] = useState({});
  const dispatch = useDispatch();

  const keys = Object.keys(savedCsvData[0] || {});

  const camelCaseKeys = keys.map((key) => key);

  const navigate = useNavigate();

  const mappedDataKeys = Object.keys(mappedData[0] || {});

  const mappedDataKeysWithoutQuotes = mappedDataKeys.map((key) =>
    key.replace(/\b(')\b|['"`]/g, "$1")
  );

  function handleNavigate() {
    const keysMatch = dropdownData.every(({ key }) =>
      mappedDataKeysWithoutQuotes.includes(key)
    );

    if (!keysMatch) {
      toast.error("CSV column fields must match invoice column fields");
      return;
    }

    dispatch(getCsvFileData(mappedData));

    let path =
      "/invoice/invoice/fundingSection/invoiceupload/validation/invoice-table";
    navigate(path);
  }

  function objectsAreEqual(obj1, obj2) {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }

    for (let key of obj1Keys) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    // Check if all keys in dropdownData exist in mappedData when component mounts or mappedData changes
    const keysMatch = dropdownData.every(({ key }) =>
      mappedDataKeysWithoutQuotes.includes(key)
    );
    if (!keysMatch) {
      toast.error("Please upload a CSV file with matching headers.");
    }
    // Update validationStatus accordingly
    const newValidationStatus = {};
    for (let i = 0; i < dropdownData.length; i++) {
      newValidationStatus[i] =
        dropdownData[i].key === mappedDataKeysWithoutQuotes[i];
    }
    // Update validationStatus only if it has changed
    if (!objectsAreEqual(validationStatus, newValidationStatus)) {
      setValidationStatus(newValidationStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedData, mappedDataKeysWithoutQuotes, validationStatus]);

  const dropdownData = [
    {
      key: "From",
    },
    {
      key: "To",
    },
    {
      key: "Date",
    },
    {
      key: "Due Date",
    },
    {
      key: "Item",
    },
    {
      key: "Qty",
    },
    {
      key: "Unit Price",
    },
    {
      key: "Amount",
    },
  ];

  const handleDropdownChange = (index, newKey) => {
    const updatedData = mappedData.map((item, itemIndex) => {
      const newItem = { [newKey]: item[camelCaseKeys[index]], ...item };
      delete newItem[camelCaseKeys[index]];
      return newItem;
    });
    setMappedData(updatedData);

    const isValid = newKey === dropdownData[index].key;
    setValidationStatus((prevState) => ({
      ...prevState,
      [index]: isValid,
    }));
  };

  return (
    <DashboardLayout>
      <header className="px-[24px] py-[16px] bg-alabasterHeader rounded-t-[5px]">
        <h1 className="text-black text-sm font-medium">
          Invoice Validation and Mapping
        </h1>
      </header>
      <div className="bg-white pt-[24px] px-[32px] pb-[20px]">
        <p className="text-lightGray text-sm font-medium pb-[24px]">
          Review the column headers in your CSV file with the invoice data.
        </p>
        <div className="border-t-[.7px] border-b-[.7px] border-silver bg-alabasterHeader px-[20px] py-[10px] flex gap-x-[135px]">
          <p className="text-textColor text-xs font-bold">
            Invoice Column Fields
          </p>
          <p className="text-textColor text-xs font-bold">CSV Column Fields</p>
        </div>
        <div>
          {camelCaseKeys.map((key, index) => (
            <div
              key={index}
              className="border-b-[.7px] border-silver px-[20px] py-[10px] flex gap-x-[135px] items-center"
            >
              <p className="text-lightGray text-xs w-[119px]">
                {dropdownData[index] && dropdownData[index].key}
              </p>
              <select
                key={index}
                className={`w-[200px] h-[43px] py-[12px] px-[16px] border-[.8px] rounded-[5px] outline-none text-[0.875rem] text-lightGray ${
                  validationStatus[index] ? "border-green-500" : "border-red"
                }`}
                name=""
                defaultValue={key.replace(/"/g, "")}
                onChange={(e) => handleDropdownChange(index, e.target.value)}
                disabled={
                  camelCaseKeys.length === dropdownData.length &&
                  camelCaseKeys.every((key, i) => key === dropdownData[i].key)
                }
                id=""
              >
                <option value={key.replace(/"/g, "")}>
                  {key.replace(/"/g, "")}
                </option>
                {dropdownData.map((data, index) => (
                  <option key={index} value={data.key.replace(/"/g, "")}>
                    {data.key.replace(/"/g, "")}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>

        <div className="flex justify-end px-[30px] pt-[30px]">
          <div>
            <Button
              neutral
              className="bg-[#F8F8F8] border-[1px] border-silver rounded-[5px] py-[12px] px-[24px] !text-black font-medium !text-sm"
            >
              Discard
            </Button>

            <Button
              neutral
              onClick={handleNavigate}
              className="bg-primaryColor border-[1px] border-silver rounded-[5px] py-[12px] px-[24px] !text-white font-medium !text-sm ml-[10px]"
            >
              Save & Next
            </Button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ValidationMaping;
