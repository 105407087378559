//This is where Tier table is
import {
  allSupplierAssociatedBuyer,
  getAssociatedBuyerId,
  supplierBuyerDisassociate,
} from "appstate/supplier/supplierSlice";
// import {
//   PAIconCancel,
//   PAIconDoc,
//   PAIconDropUser,
//   PAIconEditlight,
//   PAIconLink,
//   PAIconOption,
// } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import AccountModalContainer from "shared/accountModal/Modal";
import TableHeader from "shared/tableHeader/TableHeader";
import Trash from "../../../../../assets/images/svgs/redtrash.svg";
import Enabled from "../../../../../assets/images/svgs/enable.svg";
import Edit from "../../../../../assets/images/svgs/edit-main.svg";
import { getAllCreatedTiers } from "appstate/api/tier";

const Members = () => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  // eslint-disable-next-line
  const [atBottom, setAtBottom] = useState(false);
  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line
  const [getId, setGetId] = useState(false);
  const [openDisassociateModal, setOpenDisAssociateModal] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const { isLoading, disAssociateLoading } = useSelector((state) => state?.supplier);
  
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  //remember
  // const handleDropdown = (row) => {
  //   if (dropdown === row.id) {
  //     setDropdown(null);
  //   } else {
  //     setDropdown(row.id);
  //   }
  // };
  const navigate = useNavigate();


  const fetchTiers = async () => {
    const result = await getAllCreatedTiers();
    setData(result?.data || []);
  };

  useEffect(() => {
    fetchTiers();
     // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const search = "";
    if (companyName.length <= 0) {
      dispatch(allSupplierAssociatedBuyer({ page, pageSize, search }));
    }
  }, [dispatch, page, pageSize, companyName]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const onRowClicked = (row) => {
    let path = "/accounts/accountdetails";
    dispatch(getAssociatedBuyerId(row?.id));
    navigate(path);
  };

  const handleSearch = () => {
    const search = companyName;
    dispatch(allSupplierAssociatedBuyer({ page, pageSize, search })).then(
      (data) => {
        setData(data?.payload?.data);
      }
    );
  };

  // const handleOpenModal = (row) => {
  //   setGetId(row?.userId);
  //   setOpenDisAssociateModal(true);
  // };

  const handleDisAssociate = () => {
    const buyerId = getId;

    const search = "";
    dispatch(supplierBuyerDisassociate(buyerId)).then((data) => {
      if (data?.payload?.success === true) {
        setOpenDisAssociateModal(false);
        setSuccess(false);
        dispatch(allSupplierAssociatedBuyer({ page, pageSize, search }));
      }
    });
  };

  const column = [
    {
      name: "Tier Code",
      selector: (row) => row?.tierCode,
    },
    {
      name: "Tier Name",
      selector: (row) => row?.tierName,
    },
    {
      name: "Description",
      selector: (row) => row?.tierDescription,
    },
    {
      name: "Updated Date",
      selector: (row) => row?.lastModifiedDate,
    },
    {
      name: "Status",
      selector: (row) => !row?.status && "Enabled",
    },
    {
      cell: (row) => (
        <div className="flex items-center gap-x-[10px]">
          <img
            src={Edit}
            alt="Edit"
            className="w-[20px] h-[20px] cursor-pointer"
            onClick={() => console.log('Edit clicked', row)}
          />
          <img
            src={Trash}
            alt="Delete"
            className="w-[20px] h-[20px] cursor-pointer"
            onClick={() => console.log('Delete clicked', row)}
          />
          <img
            src={Enabled}
            alt="Enabled"
            className="w-[20px] h-[20px] cursor-pointer"
            onClick={() => console.log('Enabled clicked', row)}
          />
        </div>
      ),
      width: "5rem",
    },
  ];

  const totalPages = 50;

  let path = "associate";

  return (
    <DashboardLayout section="Buyers">
      <Card className="p-[24px] rounded-[10px]">
        <AccountModalContainer
          accontModal
          isShown={openDisassociateModal}
          onClose={() => {
            setOpenDisAssociateModal(false);
          }}
          isLoading={disAssociateLoading}
          success={success}
          handleAssociate={handleDisAssociate}
          warning="Are you sure you would like to disassociate with this member?"
        />
        <TableHeader
          path={path}
          setProductName={setCompanyName}
          productName={companyName}
          fetchTiers={fetchTiers}
          search={handleSearch}
          associateBtn
          data={data}
          accounts
          total="Members"
          tier
        />
        <Table
          columns={column}
          data={data}
          selectableRows
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
          dropdown={dropdown}
        />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default Members;
