import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eachTransaction } from "appstate/wallet/walletSlice";
import Spinner from "shared/Spinner/Spinner";

const TransactionModal = ({ transactionId, onClose }) => {
  const dispatch = useDispatch();
  const { transactionDetails, isLoading } = useSelector(
    (state) => state.wallet
  );

  useEffect(() => {
    dispatch(eachTransaction({ transactionId }));
  }, [dispatch, transactionId]);

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Transaction Details
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        {isLoading ? (
          <Spinner color />
        ) : (
          <div className="w-full px-7  items-center justify-center py-3">
            <h1 className="font-bold">
              Transaction Reference:
              <span className="font-normal">
                {" "}
                {transactionDetails?.data?.transactionReference}
              </span>
            </h1>
            <h1 className="my-3 font-bold">
              Beneficiary Name:
              <span className="font-normal">
                {" "}
                {transactionDetails?.data?.beneficiaryName}
              </span>
            </h1>
            <h1 className="font-bold">
              Sender Name:
              <span className="font-normal">
                {" "}
                {transactionDetails?.data?.sourceAccountName}
              </span>
            </h1>
            <h1 className="my-3 font-bold">
              Beneficiary Account:
              <span className="font-normal">
                {" "}
                {transactionDetails?.data?.beneficiary}
              </span>
            </h1>
            <h1 className="font-bold">
              Sender Account:
              <span className="font-normal">
                {" "}
                {transactionDetails?.data?.source}
              </span>
            </h1>
            <h1 className="my-3 font-bold">
              Amount:
              <span className="font-normal">
                {" "}
                {transactionDetails?.data?.amount}
              </span>
            </h1>
            <h1 className="font-bold">
              Description:
              <span className="font-normal">
                {" "}
                {transactionDetails?.data?.narration}
              </span>
            </h1>
            <h1 className="my-3 font-bold">
              Transaction Type:
              <span className="font-normal">
                {" "}
                {transactionDetails?.data?.transactionType}
              </span>
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionModal;
