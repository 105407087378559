import { PAIconProfile } from "assets/images/svgs";
import OnboardingLayout from "features/onboarding/category/components/OnboardingLayout";

import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";

const BuyerInviteSupplier = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    let path = "/buyer-onboard-supplier/next-steps";
    navigate(path);
  };
  return (
    <OnboardingLayout>
      <div className="px-[24px] pt-[40px] pb-[20px] md:px-[60px] h-[100%] overflow-auto md:pt-[100px] ">
        <div className="mb-[30px]">
          <h1 className="font-[800] text-[36px] text-textColor">
            You've been invited to join Big
          </h1>
          <h1 className="font-[800] text-[36px] text-textColor">
            Buyer Corp on
          </h1>
          <h1 className="font-[800] text-[36px] text-headingText">
          PayEdge SCF
          </h1>
        </div>

        <p className="font-semibold text-[18px] text-textColor mt-[20px] md:mt-0 mb-[16px]">
          Confirm your identity
        </p>
        <p className="font-normal text-[12px]  text-textColor  md: mb-3">
          Vendor ID with Big Buyer Corp
        </p>
        <div>
          <InputField
            className="w-[300px] mb-[30px] outline-none  md:w-[424px] md:mb-[38px]"
            placeholder="123456789"
          />
        </div>
        <div className="md:flex gap-x-[20px] mb-[28px]">
          <Button
            className="w-[280px] mb-[30px] md:w-[250px] "
            onClick={handleClick}
          >
            Join Supplier Pay
          </Button>
          <Button pill className="mb-[30px] md:w-[280px] ">
            What is Supplier Pay?
          </Button>
        </div>
        <div className="border-b"></div>
        <div className="flex  mt-[48px]">
          <div className="mr-8">
            <PAIconProfile />
          </div>
          <div>
            <p className="text-[#888888] font-[400] text-[16px]">
              Invitation sent by
            </p>
            <p className="text-primaryColor font-[600] text-[18px] ">
              John Benzy
            </p>
            <em className="text-[#000000] font-[400] text-[16px]">
              Accounts Payables Big Buyer Corp
            </em>
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default BuyerInviteSupplier;
