import React, { useState } from "react";
import documentIcon from "../../../assets/images/svgs/documentIcon.svg";
import {
  PARadioChecked,
  PARadioUncheked,
  PAIconNewArrowRight,
  PAInoUploadImage,
} from "assets/images/svgs";
import UploadDocumentModal from "./UploadDocumentModal";
import DocumentList from "./DocumentList";

const Documents = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDocList, setShowDocList] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFileNameTwo, setSelectedFileNameTwo] = useState("");
  const [selectedFileNameThree, setSelectedFileNameThree] = useState("");
  const [uploadedDocuments, setUploadedDocuments] = useState([]);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFileSelect = (fileName) => {
    setUploadedDocuments([...uploadedDocuments, fileName]);
    !selectedFileName
      ? setSelectedFileName(fileName)
      : !selectedFileNameTwo
      ? setSelectedFileNameTwo(fileName)
      : setSelectedFileNameThree(fileName);
  };

  const currentDate = new Date().toLocaleDateString();

  const handleDocumentList = () => {
    setShowDocList(true);
  };

  const handleDocumentCloseList = () => {
    setShowDocList(false);
  };

  return (
    <div>
      <div className="flex flex-col py-[30px] px-[25px] space-y-5">
        {showDocList ? (
          <DocumentList onBackClick={handleDocumentCloseList} />
        ) : (
          <div>
            {showModal && (
              <UploadDocumentModal
                onCloseModal={handleCloseModal}
                onFileSelect={handleFileSelect}
              />
            )}
            <h1 className="text-xl font-bold pb-[2%]">Documents</h1>
            <div className="bg-gallery h-[1px] pb-1 w-full"></div>
            <div className="flex justify-between mt-[3%] mb-6">
              <p>Uploaded Document</p>
              <p
                className="text-[blue] cursor-pointer"
                onClick={handleDocumentList}
              >
                View All
              </p>
            </div>
            <div
              className={`${
                uploadedDocuments?.length > 0
                  ? "hidden"
                  : "items-center  flex flex-row justify-center m-8"
              }`}
            >
              <div>
                <PAInoUploadImage />

                <p className="text-[#7A7A7A]">No document uploaded yet</p>
              </div>
            </div>
            {uploadedDocuments?.map((fileName, index) => (
              <div
                key={index}
                className="bg-[#E9E9E9] flex py-2 px-3 rounded w-fit mt-3"
              >
                <img src={documentIcon} alt="document Icon"></img>
                <p className="ml-2 text-sm font-medium">{fileName}</p>
              </div>
            ))}
            <div className="flex justify-between mt-8 items-top">
              <div className="flex items-top">
                {selectedFileName ? (
                  <PARadioChecked className="mt-1" />
                ) : (
                  <PARadioUncheked className="mt-1" />
                )}

                <div className="ml-4">
                  <h2 className="text-base font-medium">
                    Add National ID card/ NIN/ Driver’s license
                  </h2>
                  <p className="text-[#7A7A7A]">
                    It is essential to upload one of the above for
                    identification and security purposes{" "}
                  </p>
                </div>
              </div>
              {selectedFileName ? (
                <p>{currentDate}</p>
              ) : (
                <div
                  onClick={handleModal}
                  className="bg-[#E9E9E9] p-2 rounded items-center h-fit flex cursor-pointer"
                >
                  <p className="text-xs mr-3">Add Document</p>
                  <PAIconNewArrowRight />
                </div>
              )}
            </div>
            <div className="flex justify-between mt-8 ">
              <div className="flex content-center">
                {selectedFileNameTwo ? (
                  <PARadioChecked className="mt-1" />
                ) : (
                  <PARadioUncheked className="mt-1" />
                )}
                <div className="ml-4">
                  <h2 className="text-base font-medium">Add a Utility Bill</h2>
                  <p className="text-[#7A7A7A]">
                    It is essential to upload your utility bill so as to confirm
                    your address
                  </p>
                </div>
              </div>
              {selectedFileNameTwo ? (
                <p>{currentDate}</p>
              ) : (
                <div
                  onClick={handleModal}
                  className="bg-[#E9E9E9] p-2 rounded items-center h-fit flex cursor-pointer"
                >
                  <p className="text-xs mr-3">Add Document</p>
                  <PAIconNewArrowRight />
                </div>
              )}
            </div>
            <div className="flex justify-between mt-8 ">
              <div className="flex content-center">
                {selectedFileNameThree ? (
                  <PARadioChecked className="mt-1" />
                ) : (
                  <PARadioUncheked className="mt-1" />
                )}
                <div className="ml-4">
                  <h2 className="text-base font-medium">Add a CAC document</h2>
                  <p className="text-[#7A7A7A]">
                    It is essential to upload your CAC document for
                    identification purposes
                  </p>
                </div>
              </div>
              {selectedFileNameThree ? (
                <p>{currentDate}</p>
              ) : (
                <div
                  onClick={handleModal}
                  className="bg-[#E9E9E9] p-2 rounded items-center h-fit flex cursor-pointer"
                >
                  <p className="text-xs mr-3">Add Document</p>
                  <PAIconNewArrowRight />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Documents;
