import { PAIconCancel, PAIconSmallWallet } from "assets/images/svgs";

import React from "react";

function BidSelectAccountModal({ onClose, isOpen, handleProceed }) {
  const data = [1, 2, 3];
  if (!isOpen) {
    return null;
  }
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
        <div className="bg-white  rounded-[10px] shadow-md flex flex-col   md:w-[600px]  ">
          <header className="flex justify-between  pb-[16px] w-full bg-[#fafafa] py-6 px-5  rounded-t-[10px]">
            <p class="text-[#222222] text-base font-bold ">Select Account</p>
            <div
              onClick={onClose}
              className="flex justify-between items-center gap-x-[10px] cursor-pointer"
            >
              <PAIconCancel className="w-[8px]" />

              <p
                class="text-[#565656] text-sm
font-semibold"
              >
                Close
              </p>
            </div>
          </header>
          <div class="text-[#5f4d61] text-center font-semibold mt-8 ">
            Select which account you want to use for this bid
          </div>
          <div className=" mt-10  px-5 py-6">
            <div class="text-[#5f4d61] text-base font-medium text-justify ">
              Accounts
            </div>

            {/* card scrollabe section */}
            <div className=" overflow-y-auto h-[150px] mt-4">
              <div className=" flex justify-between flex-row flex-wrap gap-5">
                {/* This is first card */}
                {data.map((item) => (
                  <div
                    class="lg:w-[48%] md:w-full w-full p-3 bg-[#07593d] rounded-[5px] flex-col justify-start items-start gap-2.5 inline-flex"
                    key={item}
                    onClick={handleProceed}
                  >
                    <div class="justify-between items-end inline-flex w-full">
                      <div class="flex-col justify-start items-start gap-1.5 inline-flex">
                        <div class=" justify-start items-center gap-1.5 inline-flex">
                          <div class=" h-6 justify-center items-center flex">
                            {" "}
                            <PAIconSmallWallet />
                          </div>
                          <div class=" text-white text-sm font-semibold ">
                            Praise Akubundu
                          </div>
                        </div>
                        <div class="text-white text-base font-medium  ">
                          7364738921
                        </div>
                        <div class="text-white text-[13px] font-semibold ">
                          Payassyst MFB
                        </div>
                      </div>
                      <div class="flex-col justify-start items-start gap-3 inline-flex">
                        <div class="text-white text-[10px] font-semibold ">
                          Virtual Account
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BidSelectAccountModal;
