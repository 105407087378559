import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen, PAIconDownload } from "assets/images/svgs";
import React from "react";
import { Link } from "react-router-dom";
// import AccountModalContainer from "shared/accountModal/Modal";

function BulkDirectFundingInvoiceDetails() {
  //   const [open, setOpen] = useState(false);
  //   const [success] = useState(false);
  return (
    <DashboardLayout>
      <div className="flex flex-col gap-8  pb-24  bg-white rounded-[10px]">
        {/* <AccountModalContainer
          success={success}
          isShown={open}
          warning="Are you sure you would like to proceed to disbursement on this invoice Direct Funding Invoice?"
          onClose={() => setOpen(false)}
        /> */}
        {/* first header */}
        <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
          <div>
            <Link
              to="/invoice/invoice"
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconArrowLeftGreen />
              <span className="flex items-center text-primaryColor">
                Go Back
              </span>
            </Link>
          </div>
          <div className="flex justify-between items-center">
            <h1 className="text-black font-medium underline">Invoices</h1>
          </div>
        </header>

        <div className="px-6">
          <div className="flex flex-col gap-3">
            {/* <header className="flex justify-between items-center">
              <h1 className="text-[18px] font-bold">Invoice | INV-2002</h1>
              <div
                onClick={() => setOpen(true)}
                className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
              >
                Initiate Disbursement
              </div>
            </header> */}

            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex gap-x-[5px]">
                  <span>Reference No:</span>
                  <span>45678JHGF</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">23-Dec-2024</span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs">Payment Due Date:</span>
                    <span className="text-[#979797]  text-xs">23-Dec-2024</span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="text-sm flex flex-col justify-end items-end px-[35px] italic">
                  <p className="italic font-bold">New Invoice Amount</p>
                  <p className="text-[#07A04A] font-bold">NGN 355,600</p>
                  <p className="text-secondaryColor font-semibold line-through">
                    NGN 385,600
                  </p>
                  <p className="text-secondaryColor">+1.67%</p>
                </div>
              </div>
            </main>
          </div>

          <div className="flex justify-between my-5">
            <div className="flex flex-col">
              <span className="font-bold text-sm">Payment</span>
              <span className="text-[#979797]  text-xs">60 Days</span>
            </div>
            <div className="flex flex-col">
              <span className=" font-bold text-sm">Currency</span>
              <span className="text-[#979797]  text-xs">NGN</span>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-[18px] font-bold">Supplier/buyer details</h1>
            </header>
            <div className="flex  justify-between mb-5">
              <div className="flex gap-2">
                {/* <PAIconCocacola /> */}
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">Supplier</span>
                  <span className="text-[#979797]  text-xs">Coca Cola</span>
                </div>
              </div>
              <div className="flex gap-2">
                {/* <PAIconModelOne /> */}
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">Buyer</span>
                  <span className="text-[#979797]  text-xs">ABC Limited</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Line Items</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item Number
                    </td>
                    <td className=" p-2.5 text-xs font-bold">PO Number</td>
                    <td className=" p-2.5 text-xs font-bold">
                      Product/Service Number
                    </td>
                    <td className=" p-2.5 text-xs font-bold">
                      Product/Service Description
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                    <td className=" p-2.5 text-xs font-bold">
                      Line Item Extended Amount
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#11192A] p-2.5">INV-2002</td>
                    <td className=" text-xs text-[#11192A] p-2.5">PO-2002</td>
                    <td className=" text-xs text-[#11192A] p-2.5">ITM3201</td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      Dangote Cement
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">20</td>
                    <td className=" text-xs text-[#11192A] p-2.5">$30</td>
                    <td className=" text-xs text-[#11192A] p-2.5">$600</td>
                  </tr>
                  <tr className=" py-1.5 px-1.5">
                    <td className=" text-xs text-[#11192A] p-2.5">INV-2002</td>
                    <td className=" text-xs text-[#11192A] p-2.5">PO-2002</td>
                    <td className=" text-xs text-[#11192A] p-2.5">ITM3201</td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      Dangote Cement
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">20</td>
                    <td className=" text-xs text-[#11192A] p-2.5">$30</td>
                    <td className=" text-xs text-[#11192A] p-2.5">$600</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-5">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Attachments</h1>
            </header>
            <div className="w-full overflow-x-auto">
              <table className="w-full overflow-x-auto">
                <thead className="  bg-subtleGray w-">
                  <tr className="px-4  bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                      Document Name
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">
                      File Type
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">
                      Upload Date / Time
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                      DOC-29384.pdf
                    </td>
                    <td className=" text-xs text-[#11192A] px-4 py-2.5">PDF</td>
                    <td className=" text-xs text-[#11192A] px-4 py-2.5">
                      2021-09-14 13:25
                    </td>
                    <td className="cursor-pointer text-xs text-[#11192A] px-4 py-2.5">
                      <PAIconDownload />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default BulkDirectFundingInvoiceDetails;
