import React, { useState } from "react";

import FunderDetailsSegment from "./FunderDetailsSegment";
import MoreInvoiceIdSegment from "./MoreInvoiceIdSegment";

const FundDetailSide = () => {
  const [activeTab, setActiveTab] = useState("Funder Details");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Funder Details":
        return <FunderDetailsSegment />;
      case "More invoice bids":
        return <MoreInvoiceIdSegment />;
      default:
        return null;
    }
  };

  return (
    <div className=" gap-2">
      <div className="  ">
        <div className="text-sm ">
          <div className="flex gap-3  px-7  border-b-[1px]  border-b-[#C2C2C2]">
            <div
              className={`cursor-pointer py-4 ${
                activeTab === "Funder Details"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D]  md:border-b-4"
                  : "text-gray500  text-base font-normal md:my-0 text-neutral-400"
              }`}
            >
              <p onClick={() => handleTabClick("Funder Details")}>
                Funder Details
              </p>
            </div>

            <div
              className={`cursor-pointer  py-4 ${
                activeTab === "More invoice bids"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D]  md:border-b-4"
                  : "text-gray500  text-base font-normal md:my-0 text-neutral-400"
              }`}
            >
              <p onClick={() => handleTabClick("More invoice bids")}>
                More invoice bids
              </p>
            </div>
          </div>

          <div className="mt-3">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default FundDetailSide;
