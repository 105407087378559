import Modal from 'layout/modal/Modal'
import React from 'react'
import BuyerDetails from './BuyerDetails'

function BuyerDetailsModal({isShown, onClose, getId}) {
  return (
    <Modal isShown={isShown} hide={onClose} accountModal>
    <BuyerDetails onClose={onClose} id={getId}/>
    </Modal>
  )
}

export default BuyerDetailsModal