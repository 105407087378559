import { PAIconFluentBox } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

const EarlyPaymentOption = ({ isOpen, onClose, setOpen }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleSingle = () => {
    let path = "/invoice/fundingSection/singlerequestinvoicelist";
    navigate(path);
  };

  const handleBulk = () => {
    navigate("/invoice/fundingSection/bulkrequestinvoicelist")
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
        <div className="bg-white rounded-md max-w-xs md:max-w-md w-full pb-5">
          <div className="flex justify-between items-center mb4">
            <p className="text-black font-sans text-base font-bold p-3 px-5 rounded-sm italic">
              Early Payment
            </p>
            <button
              onClick={onClose}
              className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
            >
              &times; <span className="text-sm mx-2 mt-1">Close</span>
            </button>
          </div>
          <div className="border border-gray mb-5 px-5"></div>

          <div>
            <div className="w-full px-7">
              <p className="text-[#7A7A7A] flex items-center justify-center text-sm font-normal mb-3">
                Select request method
              </p>

              <div className="flex justify-between gap-5">
                <div
                  className="bg-[#07593D] p-3 mt-3 text-white flex items-center gap-1 justify-between cursor-pointer rounded-sm"
                  onClick={handleSingle}
                >
                  <p className="text-white text-xs">
                    Request <span className="font-bold">Single</span> payment
                    from <span className="italic font-bold">Buyer</span>
                  </p>
                  <br />
                  <div className="mt-2">
                    <PAIconFluentBox />
                  </div>
                </div>

                <div
                  className="bg-[#07593D] p-3 mt-3 text-white flex items-center gap-1 justify-between cursor-pointer rounded-sm"
                  onClick={handleBulk}
                >
                  <p className="text-white text-xs">
                    Request <span className="font-bold">Bulk</span> payment from{" "}
                    <span className="italic font-bold">Buyer</span>
                  </p>
                  <br />
                  <div className="mt-2">
                    <PAIconFluentBox />
                  </div>
                </div>
              </div>
              {/* FOOTER */}
              <div className="flex justify-center mt-10 mb-3">
                <p className="text-[#7A7A7A] font-sans text-sm font-normal">
                  Select your preferred early payment method
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EarlyPaymentOption;
