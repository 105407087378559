import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import Button from "shared/Button";
import BuyerDetailsModal from "../modal";
import DashboardLayout from "layout/dashboardlayout";
import { PAIconInventoryArrowLeft, PAIconSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptSupplierRequestFunder,
  declineSupplierRequestFunder,
  viewFunderPendingRequest,
} from "appstate/funder/funderSlice";
import Pagination from "shared/Pagination";
import toast from "react-hot-toast";
import AccountModalContainer from "shared/accountModal/Modal";
import DeclineModal from "shared/delineModal/Modal";
import Loading from "shared/Loading";

const FunderSupplierPendingRequest = () => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  // eslint-disable-next-line
  const [getSupId, setGetSupId] = useState();
  const [open, setOpen] = useState(false);
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [success, setSuccess] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const {
    funderPendingRequestData,
    isLoading,
    associateLoading,
    declineLoading,
  } = useSelector((state) => state.funder);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const sender = "supplier";
  useEffect(() => {
    const search = "";
    dispatch(viewFunderPendingRequest({ sender, search, page, pageSize }));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    if (companyName.length <= 0) {
      setData(funderPendingRequestData?.data);
    }
  }, [funderPendingRequestData, companyName]);

  const [getId, setGetId] = useState(null);
  const onRowClicked = (row) => {
    setOpenDetailsModal(true);
    setGetId(row?.userId);
  };
  const handleOpenModal = (row) => {
    setGetId(row?.notificationId);
    setGetSupId(row?.userId);
    setOpen(true);
  };
  const totalPages = funderPendingRequestData?.meta?.totalElements;
  const handleSearch = () => {
    const search = companyName;
    dispatch(viewFunderPendingRequest({ sender, search, page, pageSize })).then(
      (data) => {
        setData(data?.payload?.data);
      }
    );
  };
  const handleOpenDeclineModal = (row) => {
    setGetId(row?.notificationId);
    setOpenDeclineModal(true);
  };
  const column = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className="flex gap-x-[12px]">
            <Button
              onClick={() => handleOpenModal(row)}
              neutral
              className="flex px-[16px] py-[8px] border-[1px] border-gray rounded-[5px] bg-primaryColor"
            >
              <p className="text-xs font-normal text-white whitespace-nowrap">
                Accept
              </p>
            </Button>
            <Button
              onClick={() => handleOpenDeclineModal(row)}
              neutral
              className="flex px-[16px] py-[8px]  border-[1px] border-red rounded-[5px] "
            >
              <p className="text-xs font-normal text-red whitespace-nowrap">
                Decline
              </p>
            </Button>
          </div>
        );
      },
    },
  ];

  const handleAssociate = () => {
    const notificationId = getId;
    // const supplierId = getSupId;

    dispatch(acceptSupplierRequestFunder({ notificationId })).then(
      (data) => {
        if (data?.payload?.success === true) {
          setSuccess(false);
          setOpen(false);
          toast.success(data?.payload?.message);
          dispatch(viewFunderPendingRequest({ page, pageSize }));
        }
      }
    );
  };

  
  const handleDecline = () => {
    const notificationId = getId;
    const search = "";
    dispatch(declineSupplierRequestFunder({ notificationId })).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(false);
        setOpenDeclineModal(false);
        toast.success(data?.payload?.message);
        dispatch(viewFunderPendingRequest({ sender, search, page, pageSize }));
      }
    });
  };

  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }
  return (
    <DashboardLayout section="Suppliers">
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
          <div className="flex justify-between flex-wrap ">
            <div>
              <p className="text-black text-sm font-medium">
                Pending requests to associate
              </p>
              <p className="text-textColor text-xs font-normal">
                List of suppliers wanting to associate
              </p>
            </div>
            <div className="flex gap-x-[10px] mt-[10px] md:mt-0 flex-wrap">
              <div className="flex items-center  bg-white p-[10px] gap-x-[8px] rounded-[5px] border-[0.5px] border-silver xl:w-[350px]">
                <PAIconSearch />
                <input
                  type="text"
                  placeholder="Search request"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="placeholder:text-xs outline-none"
                />
              </div>
              <Button
                neutral
                disabled={data < 1}
                onClick={handleSearch}
                className="py-[12px] mt-[10px] md:mt-0 px-[24px] border-[0.5px] border-silver bg-brightGray text-sm !text-black rounded-[5px] font-medium"
              >
                Search
              </Button>
            </div>
          </div>
        </header>
        <BuyerDetailsModal
          isShown={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          getId={getId}
          isPendingRequest
        />
        <DeclineModal
          isShown={openDeclineModal}
          onClose={() => setOpenDeclineModal(false)}
          success={success}
          handleAssociate={handleDecline}
          isLoading={declineLoading}
          warning="Are you sure you would like to Decline this request?"
        />
        <AccountModalContainer
          isShown={open}
          onClose={() => setOpen(false)}
          success={success}
          handleAssociate={handleAssociate}
          isLoading={associateLoading}
          warning=" Are you sure you would like to associate with the supplier?"
        />
        <div className="p-[24px] bg-white rounded-b-[10px]">
          <Table
            columns={column}
            data={data}
            pointer
            onRowClicked={onRowClicked}
            progressPending={isLoading}
            progressComponent={<Loading />}
            tableHeader
          />
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading && totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default FunderSupplierPendingRequest;
