import DashboardLayout from "layout/dashboardlayout";
import DashboardContent from "./dashboardContent";

const BuyerDirectFundingDashBoard = () => {
  return (
    <DashboardLayout
      section="Direct Funding"
      subHeader="Monitor your invoices under direct funding"
    >
      <DashboardContent />
    </DashboardLayout>
  );
};

export default BuyerDirectFundingDashBoard;
