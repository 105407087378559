export const countries = [
  {
    name: 'Algeria',
    capital: 'Algiers',
    population: 40400000,
    area: 2381741,
  },
  {
    name: 'Egypt',
    capital: 'Cairo',
    population: 91207000,
    area: 1002450,
  },
  {
    name: 'Kenya',
    capital: 'Nairobi',
    population: 49699862,
    area: 580367,
  },
  {
    name: 'Morocco',
    capital: 'Rabat',
    population: 35276786,
    area: 446550,
  },
  {
    name: 'Nigeria',
    capital: 'Abuja',
    population: 186053386,
    area: 923768,
  },
  {
    name: 'South Africa',
    capital: 'Pretoria',
    population: 55653654,
    area: 1221037,
  },
  {
    name: 'Tanzania',
    capital: 'Dodoma',
    population: 53470000,
    area: 945087,
  },
  {
    name: 'Uganda',
    capital: 'Kampala',
    population: 41487965,
    area: 241550,
  },
  {
    name: 'Ethiopia',
    capital: 'Addis Ababa',
    population: 105350020,
    area: 1104300,
  },
  {
    name: 'Ghana',
    capital: 'Accra',
    population: 27670174,
    area: 238535,
  },
];
