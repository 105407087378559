import React from "react";
import Tabs from "./Tabs/Tabs";

const TemplateDetails = () => {

  return (
    <div className="mt-10">
      <Tabs />
    </div>
  );
};

export default TemplateDetails;
