import { AnimatePresence, motion } from "framer-motion";
import {
  PAIconArrowLeftGreen,
  PAIconCocacola,
  PAIconDownload,
  PAIconLockYellow,
  PAIconModelOne,
  PAIconSabModel,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import toast from "react-hot-toast";

import FunderRequestAcceptedModal from "./modals/FunderRequestAcceptedModal";
import RejectBidReasonModal from "./modals/RejectBidReasonModal";
import FunderRequestDeclineModal from "./modals/FunderRequestDeclineModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSupplierOpenMarketByStatus,
  getBidInvoiceById,
  getInvoiceById,
  updateBidStatus,
} from "appstate/openMarket/openMarketSlice";
import RadioCard from "./cards/RadioCard";

function LockedInvoiceDetails() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offerId = searchParams.get("offerId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [acceptBidModalOpen, setAcceptBidModalOpen] = useState(false);
  const [rejectBidModalOpen, setRejectBidModalOpen] = useState(false);
  const [rejectBidReasonModal, setRejectBidReasonModal] = useState(false);
  const [invoiceDeclineSuccessModalOpen, setInvoiceDeclineSuccessModalOpen] =
    useState(false);
  const [invoiceSuccessModalOpen, setInvoiceSuccessModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardSelect = (cardTitle) => {
    setSelectedCard(cardTitle);
  };
  function handleGoBack() {
    navigate(-1); // Navigate back one step
  }

  useEffect(() => {
    dispatch(getInvoiceById({ id }));
    dispatch(getBidInvoiceById({ offerId }));
    // dispatch(getAllBids({ id }));
  }, [dispatch, id, offerId]);

  const { invoiceByIdData, getBidInvoiceByIdData } = useSelector(
    (state) => state?.openMarket
  );

  // Handle Accept Bid

  const handleAcceptBid = () => {
    setAcceptBidModalOpen(false);

    dispatch(updateBidStatus({ offerId, status: "Accepted" })).then((data) => {
      if (data?.payload?.success) {
        setInvoiceSuccessModalOpen(true);
        setTimeout(() => {
          toast.success("Funder Request Accepted");
        }, 50);

        dispatch(getAllSupplierOpenMarketByStatus({ status: "Locked" }));
      }
    });

    // setTimeout(() => {
    //   // toast.success("Invoice Requested Successfully");
    // }, 600);

    // setTimeout(() => {
    //   toast.success("Funder Request Accepted");
    // }, 650);
  };

  // const handleAcceptBid = () => {
  //   setAcceptBidModalOpen(false);
  //   setTimeout(() => {
  //     setInvoiceSuccessModalOpen(true);
  //     // toast.success("Invoice Locked Successfully");
  //   }, 600);

  //   setTimeout(() => {
  //     toast.success("Funder Request Accepted");
  //   }, 650);
  // };

  // Handle Reject Bid
  const handleRejectBid = () => {
    setRejectBidModalOpen(false);
    setTimeout(() => {
      setRejectBidReasonModal(true);
      //   // toast.success("Invoice Requested Successfully");
    }, 600);
  };

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-8 w-full ">
          <motion.section
            initial={{
              x: 20,
            }}
            animate={{
              x: 0,
            }}
            exit={{
              x: -20,
            }}
            transition={{
              delay: 0.6,
            }}
            className="flex flex-col gap-8 px-6 pt-10 pb-24 w-full semi-lg:w-[55%] bg-white rounded-[10px]"
          >
            <div className=" flex flex-col gap-3 ">
              <header>
                <h1 className=" text-[18px] font-bold">
                  Invoice | INV-{invoiceByIdData?.data?.invoiceNumber}
                </h1>
              </header>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex justify-between">
                    <span>Reference No:</span>
                    <span>{invoiceByIdData?.data?.invoiceReference}</span>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div className="flex justify-between">
                      <span className=" text-xs">Created Date:</span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceByIdData?.data?.createdDate.slice(0, 10)}
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <span className=" text-xs">Payment Due Date:</span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceByIdData?.data?.dueDate.slice(0, 10)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col justify-between">
                  <motion.button
                    // onClick={() => setLockModalOpen(true)}
                    whileTap={{ scale: 0.9 }}
                    className="flex border border-primaryColor text-sm justify-center gap-2 bg-white text-primaryColor rounded-[5px] items-center px-2.5 py-1.5 "
                  >
                    Locked Invoice <PAIconLockYellow />
                  </motion.button>

                  <span className="flex justify-end text-xs text-[#F08000]">
                    {invoiceByIdData?.data?.status}
                  </span>
                </div>
              </main>
            </div>

            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Payment</span>
                <span className="text-[#979797]  text-xs">60 Days</span>
              </div>
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Currency</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.currency}
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-[18px] font-bold">
                  Supplier/buyer details
                </h1>
              </header>
              <div className="flex  justify-between">
                <div className="flex gap-2">
                  <PAIconCocacola />
                  <div className="flex flex-col">
                    <span className=" font-bold text-sm">
                      {invoiceByIdData?.data?.supplierCompanyName}
                    </span>
                    <span className="text-[#979797]  text-xs">Supplier</span>
                  </div>
                </div>

                <div className="flex gap-2">
                  <PAIconModelOne />
                  <div className="flex flex-col">
                    <span className=" font-bold text-sm">
                      {invoiceByIdData?.data?.buyerCompanyName}
                    </span>
                    <span className="text-[#979797]  text-xs">Buyer</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Line Items</h1>
              </header>

              <div className=" w-full overflow-x-auto">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-subtleGray w-">
                    <tr className=" bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item Number
                      </td>
                      <td className=" p-2.5 text-xs font-bold">PO Number</td>
                      <td className=" p-2.5 text-xs font-bold">
                        Product/Service Number
                      </td>
                      <td className=" p-2.5 text-xs font-bold">
                        Product/Service Description
                      </td>
                      <td className=" p-2.5 text-xs font-bold">Quantity</td>
                      <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                      <td className=" p-2.5 text-xs font-bold">
                        Line Item Extended Amount
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {invoiceByIdData?.data?.items?.map((item, index) => (
                      <tr key={index} className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192a] p-2.5">
                          INV-{invoiceByIdData?.data?.invoiceNumber}
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">
                          PO-2002
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">
                          {item?.quantity}
                        </td>
                        <td className=" whitespace-nowrap text-xs text-[#11192a] p-2.5">
                          {invoiceByIdData?.data?.currency === "NGN"
                            ? "₦"
                            : "$"}{" "}
                          {item?.unitPrice}
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">$600</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Attachments</h1>
              </header>

              <div className="w-full overflow-x-auto">
                <table className="w-full overflow-x-auto">
                  <thead className="  bg-subtleGray w-">
                    <tr className="px-4  bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                        Document Name
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">
                        File Type
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">
                        Upload Date / Time
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr className=" py-[3px] px-1.5">
                      <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                        DOC-29384.pdf
                      </td>
                      <td className=" text-xs text-[#11192a] px-4 py-2.5">
                        PDF
                      </td>
                      <td className=" text-xs text-[#11192a] px-4 py-2.5">
                        2021-09-14 13:25
                      </td>
                      <td className="cursor-pointer text-xs text-[#11192a] px-4 py-2.5">
                        <PAIconDownload />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </motion.section>

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt-10 pb-24 w-full bg-white rounded-[10px]"
          >
            {/* Section */}
            <div className="px-6 flex flex-col gap-3 ">
              <header>
                <h1 className=" text-[18px] font-bold">Funder Details</h1>
              </header>

              <div className="w-full pb-5 flex flex-col">
                <section className="flex flex-col gap-6 ">
                  <div className="flex py-2 justify-between">
                    <span className="flex gap-6 items-center ">
                      <PAIconSabModel />
                      <div className="flex flex-col justify-center">
                        <span className="font-[500] text-sm">
                          {getBidInvoiceByIdData?.data?.buyerCompanyName ||
                            "Cocacola PLC"}
                        </span>
                        <span className=" text-[#959595] text-[10px]">
                          Requested by funder
                        </span>
                      </div>
                    </span>

                    {/* <span className="flex gap-6 ">
                      <div className="flex flex-col justify-center">
                        <span className="font-[500] text-sm">
                          ₦{getBidInvoiceByIdData?.data?.invoiceAmount}
                        </span>
                        <span className=" text-[#959595] text-[10px]">
                          Invoice Amount
                        </span>
                      </div>
                    </span> */}

                    <span className="flex gap-6 ">
                      <div className="flex flex-col justify-center">
                        <span className="font-[500] text-sm">
                          {getBidInvoiceByIdData?.data?.lastModifiedDate?.slice(
                            0,
                            10
                          ) || "2 months Ago"}
                        </span>
                        <span className=" text-[#959595] text-[10px]">
                          Time
                        </span>
                      </div>
                    </span>
                  </div>
                </section>
              </div>
            </div>
            {/* Section */}
            <div className="px-6 pb-5 flex flex-col itemscenter gap-6">
              <RadioCard
                title="With Recourse"
                // description="This is the description for Card 1."
                isSelected={selectedCard === "With Recourse"}
                onSelect={() => handleCardSelect("With Recourse")}
              />

              <div
                style={{ background: "rgba(193, 193, 193, 0.60)" }}
                className=" w-[85%] h-[1px]"
              ></div>
              <RadioCard
                title="Without Recourse"
                // description="This is the description for Card 2."
                isSelected={selectedCard === "Without Recourse"}
                onSelect={() => handleCardSelect("Without Recourse")}
              />
            </div>
            <div>
              <div
                style={{
                  background: "rgba(193, 193, 193, 0.6)",
                  boxShadow: "0px 2px 4px 0px rgba(138, 211, 186, 0.38)",
                }}
                className=" h-[1px]"
              ></div>
              {/* <div className=" px-6 py-7 flex flex-col gap-2.5">
                <h2 className="font-semibold text-base">Note from Funder: </h2>
                <span className=" w-fit text-sm text-[#959595]">
                  {getBidInvoiceByIdData?.data?.note}
                </span>
              </div>
              <div
                style={{
                  background: "rgba(193, 193, 193, 0.6)",
                  boxShadow: "0px 2px 4px 0px rgba(138, 211, 186, 0.38)",
                }}
                className=" h-[1px]"
              ></div> */}
            </div>
            {/* Section */}
            <div className="flex flex-col gap-8 p-6">
              <div className=" w-fit font-semibold ">Action Buttons</div>

              <span className=" w-fit text-sm text-[#959595]">
                Supplier's decisive actions—accept or reject bids—ensuring
                seamless transaction management in the open market
              </span>

              <div className="flex flex-col gap-4 semi-lg:flex-row justify-between">
                <motion.button
                  onClick={() => setAcceptBidModalOpen(true)}
                  whileTap={{ scale: 0.9 }}
                  className="rounded-[5px] semi-lg:w-full bg-primaryColor text-white py-2.5 px-[70px]"
                >
                  Accept
                </motion.button>

                <motion.button
                  onClick={() => setRejectBidModalOpen(true)}
                  whileTap={{ scale: 0.9 }}
                  className="rounded-[5px] semi-lg:w-full border border-[#F08000] bg-white text-[#F08000] py-2.5 px-[70px]"
                >
                  Reject
                </motion.button>
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>

      {/* Accept Bid Modal */}
      <AnimatePresence>
        {acceptBidModalOpen && (
          <ViewDocumentModal
            onAction={handleAcceptBid}
            onClose={() => setAcceptBidModalOpen(false)}
          >
            <span className=" text-center">
              Would you like to proceed with {` `}
              <span className="font-bold text-center text-primaryColor">
                Accepting
                {/* {selectedData?.name} */}
              </span>{" "}
              {` `}
              request bid
              <div>offer?</div>
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Reject Bid Modal */}
      <AnimatePresence>
        {rejectBidModalOpen && (
          <ViewDocumentModal
            onAction={handleRejectBid}
            onClose={() => setRejectBidModalOpen(false)}
          >
            <span className=" text-center">
              Would you like to proceed with {` `}
              <span className="font-bold text-center text-red">
                Rejecting
                {/* {selectedData?.name} */}
              </span>{" "}
              {` `}
              request bid
              <div>offer?</div>
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Invoice Accept Success Modal */}
      <AnimatePresence>
        {invoiceSuccessModalOpen && (
          <FunderRequestAcceptedModal
            onClose={() => setInvoiceSuccessModalOpen(false)}
          />
        )}
      </AnimatePresence>

      {/* Invoice Decline Success Modal */}
      <AnimatePresence>
        {invoiceDeclineSuccessModalOpen && (
          <FunderRequestDeclineModal
            onClose={() => setInvoiceDeclineSuccessModalOpen(false)}
          />
        )}
      </AnimatePresence>

      {/* Reject Bid Reject Reason Modal */}
      <AnimatePresence>
        {rejectBidReasonModal && (
          <RejectBidReasonModal
            onClose={() => setRejectBidReasonModal(false)}
            setRejectBidReasonModal={setRejectBidReasonModal}
            setInvoiceDeclineSuccessModalOpen={
              setInvoiceDeclineSuccessModalOpen
            }
          />
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
}

export default LockedInvoiceDetails;
