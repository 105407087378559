import { PAIconSearch, PAIconUmsExport } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import InputField from "shared/InputField";
import styled from "styled-components";
import TableHeaderSelect from "shared/tableHeader/TableHeaderSelect";
import Button from "shared/Button";

const TableHeaderAccounts = ({
  handleChange,
  search,
  path,
  options,
  support
}) => {

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(path);
  };

  return (
    <div className="mb-[33px]">
      {support && (
        <div>
          <div className="flex items-center justify-between">
            <div className="flex text-center items-center gap-6">
                <h2 className="font-bold">People Management</h2>
            </div>
            <div className="justify-end">
              <Button
                neutral
                onClick={handleNavigate}
                className="flex w-[157px] h-[44px] border-[1px] rounded-[5px] border-silver justify-center items-center"
              >
                <PAIconUmsExport />
                <p className="text-sm pl-2 font-bold text-black">Export</p>
              </Button>
            </div>
          </div>
          <hr className="block w-[100%] my-3 border-t-2 bg-[#ccc] border-silver" />
        </div>
      )}

      <div className="flex flex-col gap-y-[10px] md:gap-x-[10px] md:flex-row md:flex-wrap pt-[12px]">
        <div className="flex items-center">
          <InputContainer>
            <Search>
              <SearchIcon>
                <PAIconSearch />
              </SearchIcon>
              <InputField className="border border-[#d1d1d1] rounded-[5px] focus:outline-none pl-[28px] pr-[140px]" type='text' placeholder='Search for people, company and more' />
            </Search>
          </InputContainer>
        </div>
        <SelectContainer>
          <TableHeaderSelect options={options}  onChange={handleChange} />
        </SelectContainer>
        <Button
          neutral
          onClick={search}
          className="w-[96px] h-[41px] bg-headerbtnBg flex justify-center items-center border-[0.5px] border-gray rounded-[5px] text-sm font-bold !text-black"
        >
          Search
        </Button>
      </div>
    </div>
  );
  };
  
export default TableHeaderAccounts;
  

const Search = styled.div`
  position: relative;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 30%;
`;

const SearchIcon = styled.div`
  position: absolute;
  bottom: 30%;
  left: 8px;
`;

const SelectContainer = styled.div`
  min-width: 180px;
  @media screen and (max-width: 470px) {
    min-width: auto;
  }
`;
