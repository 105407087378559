import React, { useEffect } from "react";
import Graph from "./Graph";
// import Notification from "./Notification";
import RecentRequest from "./RecentRequest";
import { useDispatch, useSelector } from "react-redux";
import { fundingSectionOverview } from "appstate/invoice/invoiceSlice";

const Overview = () => {
  const { fundingSectionOverviewData } = useSelector((state) => state?.invoice);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fundingSectionOverview());
  }, [dispatch]);

  const {
    totalEarlyPaymentRequests,
    directFundingRequests,
    openMarketRequests,
    approvedRequests,
    pendingRequests,
    declinedRequests,
  } = fundingSectionOverviewData || 0;

  // Calculate the sum of approved, declined, and pending requests
  const newlyUpdatedEarlyPaymentRequest =
    approvedRequests + pendingRequests + declinedRequests;

  return (
    <div className="roundedmd w-full mt5 mx6 mb[17px]">
      <div className="lg:flex gap-2 mt-5">
        {/* FIRST GRID */}
        <div className="py7 px2 py3 w-full lg:w-[60%] bg-[#FAFAFAFB] rounded-md">
          {/* CARDS */}
          <div className="grid grid-cols-2 m3 gap-2 gap-y-4 p1 m-7">
            <div className="flex flex-col w-300 h-114 px-[20px] flexcol justify-between items-start bg-white rounded-[5.184px] shadow-md pb-2">
              <p className="text-[#11192A] font-normal text-base mt-3">
                Total Early Payment Request
              </p>
              <h1 className="text-[24px] font-semibold py[13px] mt7 h14 pt[10%] text-[#000] mb3">
                {totalEarlyPaymentRequests}
              </h1>
            </div>

            <div className="flex flex-col w-300 h-114 px-[20px] flexcol justify-between items-start bg-white rounded-[5.184px] shadow-md pb-2">
              <p className="text-[#11192A] font-normal text-base mt-3">
                Newly Updated Early Payment Request
              </p>
              <h1 className="text-[24px] font-semibold py[13px] mt7 text-[#3E0445] mb3">
                {newlyUpdatedEarlyPaymentRequest}
              </h1>
            </div>

            <div className="flex flex-col w-300 h-114 px-[20px] flexcol justify-between items-start bg-white rounded-[5.184px] shadow-md pb-2">
              <p className="text-[#11192A] font-normal text-base mt-3">
                Total Funding Request
              </p>
              <h1 className="text-[24px] font-semibold py[13px] mt-4 text-[#F08000]">
                {directFundingRequests}
              </h1>
            </div>

            <div className="flex flex-col w-300 h-114 px-[20px] flexcol justify-between items-start bg-white rounded-[5.184px] shadow-md pb-2">
              <p className="text-[#11192A] font-normal text-base mt-3">
                Total Open Market Funding Request
              </p>
              <h1 className="text-[24px] font-semibold py[13px] mt-4 text-[#2EA923]">
                {openMarketRequests}
              </h1>
            </div>
          </div>
        </div>
        {/* SECOND GRID */}
        <div className="w-full lg:w-[40%] rounded-2xl border border-transparent bg-white shadow-lg p-4 text-black  items-center justify-center">
          <div className="text-black font-bold text-lg tracking-wide flex items-center justify-center">
            Requests Status
          </div>
          <div className="mt-5 flex items-center justify-center wfull">
            <Graph />
          </div>
        </div>
      </div>

      <div className="lg:flex gap-6 mt-5">
        {/* FIRST GRID */}
        {/* <div className="p-3 w-full lg:w-[45%] bg-white rounded-md">
          <Notification />
        </div> */}
        {/* SECOND GRID */}
        <div className="p-3 w-full lg:w[55%] bg-white rounded-md">
          <RecentRequest />
        </div>
      </div>
    </div>
  );
};

export default Overview;
