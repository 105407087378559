import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import TopContestedInvoiceCard from "../cards/TopContestedInvoiceCard";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import Card from "shared/Card";

const TopContestedInvoices = (navigation) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const dummyData = [
    {
      bidAmount: 3,
      buyerCompanyName: "Coca Cola",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      invoiceDueDate: "2024-03-01",
      invoiceId: "65dfc0942b607753910b022e",
      invoiceReference: "INV-001",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      note: "Wale",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      supplierCompanyName: "Lancoan",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      userType: "Supplier",
    },
    {
      bidAmount: 2,
      buyerCompanyName: "Coke Plc.",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f22",
      discountRate: 2,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa2",
      invoiceAmount: 32164082.78,
      invoiceDueDate: "2024-03-01",
      invoiceId: "65dfc0942b607753910b022e2",
      invoiceReference: "INV-345",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa2",
      lockedDate: "2024-03-07",
      note: "Wale",
      offerId: "65e1eda9e789120b67af15b42",
      numberofBidders: "235,490",
      supplierCompanyName: "Lancoan",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3802",

      userType: "Buyer",
    },
    {
      bidAmount: 4,
      buyerCompanyName: "Dangote",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f24",
      discountRate: 4,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa4",
      invoiceAmount: 32164082.78,
      invoiceDueDate: "2024-03-01",
      invoiceId: "65dfc0942b607753910b022e4",
      invoiceReference: "INV-937",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa4",
      lockedDate: "2024-03-07",
      note: "Wale",
      offerId: "65e1eda9e789120b67af15b44",
      numberofBidders: "235,490",
      supplierCompanyName: "Lancoan",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3804",

      userType: "Buyer",
    },
    {
      bidAmount: 5,
      buyerCompanyName: "Nestle",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f25",
      discountRate: 5,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa5",
      invoiceAmount: 32164082.78,
      invoiceDueDate: "2024-03-01",
      invoiceId: "65dfc0942b607753910b022e5",
      invoiceReference: "INV-892",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa5",
      lockedDate: "2024-03-07",
      note: "Wale",
      offerId: "65e1eda9e789120b67af15b45",
      numberofBidders: "235,490",
      supplierCompanyName: "Lancoan",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3805",

      userType: "Buyer",
    },
    {
      bidAmount: 6,
      buyerCompanyName: "Shell  Ltd.",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f26",
      discountRate: 6,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa6",
      invoiceAmount: 32164082.78,
      invoiceDueDate: "2024-03-01",
      invoiceId: "65dfc0942b607753910b022e6",
      invoiceReference: "INV-826",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa6",
      lockedDate: "2024-03-07",
      note: "Wale",
      offerId: "65e1eda9e789120b67af15b46",
      numberofBidders: "Accepted",
      supplierCompanyName: "Lancoan",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3806",

      userType: "Buyer",
    },
    {
      bidAmount: 7,
      buyerCompanyName: "Trust",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f27",
      discountRate: 7,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa7",
      invoiceAmount: 32164082.78,
      invoiceDueDate: "2024-03-01",
      invoiceId: "65dfc0942b607753910b022e7",
      invoiceReference: "INV-123",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa7",
      lockedDate: "2024-03-07",
      note: "Wale",
      offerId: "65e1eda9e789120b67af15b47",
      numberofBidders: "Locked",
      supplierCompanyName: "Lancoan",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3807",

      userType: "Buyer",
    },
    {
      bidAmount: 8,
      buyerCompanyName: "Brewery",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f28",
      discountRate: 7,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa8",
      invoiceAmount: 32164082.78,
      invoiceDueDate: "2024-03-01",
      invoiceId: "65dfc0942b607753910b022e8",
      invoiceReference: "INV-048",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa8",
      lockedDate: "2024-03-07",
      note: "Wale",
      offerId: "65e1eda9e789120b67af15b48",
      numberofBidders: "Bid Active",
      supplierCompanyName: "Lancoan",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c3808",

      userType: "Buyer",
    },
  ];

  return (
    <>
      <Link
        onClick={goBack}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full bg-white px-3 py-5 rounded-[8px]"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </Link>

      <TransitionOpacity className="min-h-[75vh] rounded-b-[10px] bg-unusualGray rounded-tl-[10px]  rounded-tr-[10px] ">
        <Card className="  rounded-tl-[10px]  rounded-tr-[10px] p-[0.5px] ">
          <div className=" px-[24px]  rounded-tl-[10px]  rounded-tr-[10px]  ">
            <div className="my-2 grid md:grid-cols-2 items-center">
              <div className=" flex flex-col relative">
                <div className="  flex ">
                  <div class="text-black text-base font-semibold ">
                    Top Contested Invoices
                  </div>
                </div>

                <div class="text-neutral-400 text-xs font-normal ">
                  Invoices with the highest number of bidders
                </div>
              </div>

              <div className="flex md:col-span-1 justify-end"></div>
            </div>
          </div>
        </Card>

        <div className=" p-6 flex flex-col gap-5 ">
          {dummyData.map((invoice) => {
            return (
              <TopContestedInvoiceCard
                key={invoice?.invoiceId}
                id={invoice?.invoiceId}
                numberofBidders={invoice.numberofBidders}
                supplierCompanyName={invoice.buyerCompanyName}
                invoiceNumber={invoice?.invoiceReference}
                doerType={invoice.userType}
                invoiceAmount={invoice?.invoiceAmount}
                invoiceDueDate={invoice?.invoiceDueDate}
              />
            );
          })}
        </div>
      </TransitionOpacity>
    </>
  );
};

export default TopContestedInvoices;
