import {
  PAIconArrowDown,
  PAIconArrowLeftMini,
  PAIconEditGreen,
} from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  allPermissionAssigned,
  getRoleId,
} from "appstate/roleManager/roleManagerSlice";
import LoadingScreen from "shared/LoadingScreen";

function ViewRoleFullDetails({
  toggleRoleFullDetailsOff,
  toggleEditPermissionOn,
  selectedId,
}) {
  return (
    <div className=' h-full w-full'>
      <div className='flex flex-col gap-6 pb-8 overflow-auto bg-white rounded-lg w-full h-full'>
        <>
          <ViewRoleManagerHeader
            toggleRoleFullDetailsOff={toggleRoleFullDetailsOff}
          />
          <ViewRoleManagerContent
            selectedId={selectedId}
            toggleEditPermissionOn={toggleEditPermissionOn}
          />
        </>
      </div>
    </div>
  );
}

function ViewRoleManagerHeader({ toggleRoleFullDetailsOff }) {
  const handleBackClick = () => {
    toggleRoleFullDetailsOff();
  };
  return (
    <div className='flex flex-col gap-4 sticky top-0 bg-[#FAFAFA] rounded-t-lg justify-between px-6 py-6'>
      <div
        onClick={() => {
          handleBackClick();
        }}
        className='flex gap-1 text-[15px] text-[#7A7A7A] items-center  cursor-pointer'
      >
        <PAIconArrowLeftMini />
        <span>back</span>
      </div>

      <div className=' flex flex-col gap-1'>
        <span className=' text-sm font-[500]'>Details</span>
        <span className=' text-xs'>Basic information about the role</span>
      </div>
    </div>
  );
}

function ViewRoleManagerContent({ toggleEditPermissionOn, selectedId }) {
  const { allRolesData, roleId, isLoading } = useSelector(
    (state) => state?.roleManager
  );
  const [invcArrowActive, setInvcArrowActive] = useState(true);
  // const [paymentArrowActive, setPaymentArrowActive] = useState(false);
  const dispatch = useDispatch();
  const idData = allRolesData?.data?.data?.find(
    (item) => item.id === selectedId
  );

  const { allPermissionAssignedData } = useSelector(
    (state) => state?.roleManager
  );

  const assignedPermissions = allPermissionAssignedData?.data;

  const permissionName = assignedPermissions?.map(
    (name) => name.permissionName
  );

  const handleInvcArrowActive = () => {
    setInvcArrowActive(!invcArrowActive);
    // setPaymentArrowActive(false);
  };

  // const handlePaymentArrowActive = () => {
  //   setPaymentArrowActive(!paymentArrowActive);
  //   setInvcArrowActive(false);
  // };

  useEffect(() => {
    dispatch(allPermissionAssigned({ roleId }));
  }, [dispatch, roleId]);

  const handleRoleEditClick = () => {
    dispatch(getRoleId(selectedId));
    toggleEditPermissionOn();
  };
  const SubHeaders = ({
    permissions,
    toggleArrowfunction,
    handleToggleArrowfunction,
  }) => {
    return (
      <>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <div className='flex flex-col gap-2'>
            {permissions?.length > 0 && (
              <div className='flex py-2.5 justify-between items-center  px-7 bg-[#F9F9F9] w-full border-t border-b border-[#DADADA]'>
                <span className='font-bold'>{permissionName}</span>
                <div
                  onClick={() => handleToggleArrowfunction()}
                  className='cursor-pointer'
                >
                  <PAIconArrowDown />
                </div>
              </div>
            )}
            {toggleArrowfunction && (
              <div>
                {permissions?.map((permission, index) => (
                  <React.Fragment key={index}>
                    <AnimatePresence>
                      <motion.div
                        initial={{ opacity: 0, y: "-25%" }}
                        animate={{ opacity: 1, y: "0%" }}
                        exit={{
                          opacity: 1,
                          y: "25%",
                          transition: { delay: "0.35" },
                        }}
                        transition={{
                          type: "spring",
                          stiffness: "100",
                          duration: "0.75",
                        }}
                        key={index}
                        className='flex flex-col py-2.5  px-7 bg-white w-full'
                      >
                        {permission?.subPermissions &&
                          permission?.subPermissions?.map((sub) => (
                            <span className='text-[15.5px] py-3 shadow-sm '>
                              {sub.subPermissionName}
                            </span>
                          ))}
                      </motion.div>
                    </AnimatePresence>
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  return (
    <div className='flex flex-col gap-12 bg-white justify-between px-12 md:px-14 lg:px-20'>
      <section className='flex flex-col gap-4'>
        <div className='flex justify-between'>
          <div className=' flex flex-col gap-2'>
            <span className=' text-sm  text-[#7A7A7A] '>Role Title</span>
            <span className=' text-sm'>{idData?.roleName}</span>
          </div>

          <div className=' w-1/2 flex flex-col gap-2'>
            <span className=' text-sm  text-[#7A7A7A] '>
              No of Assigned Permissions
            </span>
            <span className=' text-sm'>{idData?.subPermissionIds?.length}</span>
          </div>
        </div>

        <div className=' flex flex-col gap-2'>
          <span className=' text-sm  text-[#7A7A7A] '>Reporting To</span>
          <span className=' text-sm'>{idData?.reportingTo || "N/A"}</span>
        </div>

        <div className=' flex flex-col gap-2'>
          <span className=' text-sm  text-[#7A7A7A] '>Created</span>
          <span className=' text-sm'>{idData.createdDate.slice(0, 11)}</span>
        </div>
      </section>

      <section className='flex flex-col gap-6'>
        <header className='flex justify-between'>
          <span className='text-sm font-bold'>Assigned Permissions</span>
          <button
            onClick={() => handleRoleEditClick()}
            className='flex items-center justify-center gap-1 border rounded-[5px] border-[#07593D] px-4 py-2'
          >
            <span>
              <PAIconEditGreen />
            </span>
            <span className=' text-xs'>Edit</span>
          </button>
        </header>

        <div>
          <SubHeaders
            permissions={assignedPermissions}
            title='Invoice Management'
            toggleArrowfunction={invcArrowActive}
            handleToggleArrowfunction={handleInvcArrowActive}
          />
        </div>
      </section>
    </div>
  );
}

export default ViewRoleFullDetails;
