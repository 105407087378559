import Card from 'shared/Card';
import { useState } from 'react';
import Button from 'shared/Button';
import styled from 'styled-components'
import Table from 'layout/table/Table';
import { useDispatch, useSelector } from 'react-redux';
import InputField from 'shared/InputField';
import Spinner from 'shared/Spinner/Spinner';
import { PAIconSearch } from 'assets/images/svgs';
import InvoiceSelect from '../components/InvoiceSelect';
import { useLocation, useNavigate } from 'react-router-dom';
import { BUYERINVOICETABLECOLUMN, SUPPLIERINVOICETABLECOLUMN } from '../utilities/tableData';
import { getPlayerInvoiceById, getSingleInvoice } from 'appstate/invoice/invoiceSlice';

const AllInvoiceHeader = ({ setOpen }) => {
  const { isLoading, playerInvoices } = useSelector((state) => state.invoice);
  const dispatch = useDispatch()
  const approvedInvoices = playerInvoices?.data?.filter((x) => x?.status === "Approved")

  const { currentUser } = useSelector((state) => state.auth)

  const location = useLocation()
  const [invoiceData, setInvoiceData] = useState(location?.state ? approvedInvoices : playerInvoices?.data)
  const [currentInvoiceTitle, setCurrentInvoiceTitle] = useState("All")
  const [searchString, setSearchString] = useState("")

  const handleSingleInvoice = (id) => {
    dispatch(getPlayerInvoiceById({ invoiceId: id, player: currentUser?.role?.toLowerCase() })).then((data) => {
      getSingleInvoice(data?.payload)
      let path = `/invoice/${id}`;
      navigate(path);
    })
  };

  const options = [
    {
      id: 1,
      title: 'All',
      value: ""
    },
    {
      id: 2,
      title: "Cancelled",
      value: "Cancelled"
    },
    {
      id: 3,
      title: "Awaiting Approval",
      value: "Awaiting_Approval"
    },
    {
      id: 4,
      title: "Awaiting Payment",
      value: "Awaiting_Payment"
    },
    {
      id: 5,
      title: 'Open',
      value: "Open"
    },
    {
      id: 6,
      title: 'Approved',
      value: "Approved"
    },
    {
      id: 7,
      title: 'Expired',
      value: "Expired"
    },
    {
      id: 8,
      title: 'Paid',
      value: "Paid"
    },
  ];

  const navigate = useNavigate()
  const handleNewInvoice = (e) => {
    e.preventDefault()
    let path = '/invoice/new-invoice';
    navigate(path);
  };

  return (
    <Card className="rounded-md">
      <HeaderContainer>
        <UtilsWrapper>
          <div className='flex flex-col p-[10px]'>
            <InvoicesCount>{location?.state ? approvedInvoices?.length : invoiceData?.length} {location?.state ? "Approved" : currentInvoiceTitle} Invoices</InvoicesCount>
            {location?.state && <p className='text-[12px] text-[#5F4D61]'>Available for payment</p>}
          </div>
          <UtilsBtnContainer>
            <UtilsBtn>
              <Button className='mr-2 max-[449px]:m-1 !bg-[#EEEEEE] w-full rounded-[5px] h-[41px] px-[8px] py-[6px] text-xs md:text-sm font-medium shadow-1px border !border-[#d8d0d0] whitespace-nowrap' onClick={handleNewInvoice}>New Invoice</Button>
            </UtilsBtn>
            <UtilsBtn>
              <Button className='mx-2 max-[449px]:m-1 !bg-[#EEEEEE] min-w-[80px] w-full rounded-[5px] h-[41px] px-[8px] py-[6px] text-xs md:text-sm font-medium shadow-1px border !border-[#d8d0d0]'>Export</Button>
            </UtilsBtn>
            <UtilsBtn>
              <Button className='mx-2 max-[449px]:m-1 !bg-[#EEEEEE] min-w-[80px] w-full rounded-[5px] h-[41px] px-[8px] py-[6px] text-xs md:text-sm font-medium shadow-1px border !border-[#d8d0d0]' onClick={() => setOpen(true)}>Import</Button>
            </UtilsBtn>
          </UtilsBtnContainer>
        </UtilsWrapper>
        <SearchWrapper>
          <InputContainer>
            <Search>
              <SearchIcon>
                <PAIconSearch />
              </SearchIcon>
              <InputField onChange={(e) => setSearchString(e.target.value)} className="border !border-[#CCCCCC] rounded-[5px] focus:outline-none pl-[28px] pr-[20px]" type='text' placeholder='Search invoice' />
            </Search>
          </InputContainer>
          <SelectContainer>
            <InvoiceSelect
              onChange={(e) => {
                if (e?.target?.value === "") {
                  setInvoiceData(playerInvoices?.data)
                } else {
                  setInvoiceData(playerInvoices?.data?.filter((data) => data?.status === e?.target?.value))
                }
                if (location?.state) {
                  setCurrentInvoiceTitle((options[e?.target?.selectedIndex - 1]?.title))
                } else {
                  setCurrentInvoiceTitle((options[e?.target?.selectedIndex]?.title))
                }
                location.state = false
              }}
              selected={location.state}
              options={location.state ? options?.filter((option) => option?.title !== "Approved") : options} />
          </SelectContainer>
          <div className='flex h-[40px] justify-center items-center'>
            <Button
              onClick={() => setInvoiceData(playerInvoices?.data?.filter((data) =>
                data?.invoiceReference?.includes(searchString) ||
                data?.buyerId?.includes(searchString) ||
                data?.createdDate?.includes(searchString) ||
                data?.dueDate?.includes(searchString) ||
                data?.currency?.includes(searchString) ||
                data?.status?.includes(searchString)
              ))}
              className='mx-2 !bg-[#EEEEEE] w-full rounded-[5px] h-[41px] px-[16px] py-[6px] text-xs md:text-sm font-medium shadow-1px border !border-[#d8d0d0] hover:bg-[#CCCCCC]'>Search</Button>
          </div>
        </SearchWrapper>
      </HeaderContainer>
      <Card className="p-[15px] md:p-[30px] rounded-[10px]">
        {isLoading ? <Spinner /> : (
          <Table
            columns={currentUser?.role?.toLowerCase() === "supplier" ? SUPPLIERINVOICETABLECOLUMN : BUYERINVOICETABLECOLUMN}
            data={invoiceData}
            onRowClicked={(row) => handleSingleInvoice(row?.id)}
            pagination
            pointer
          />
        )}
      </Card>
    </Card>
  )
}

export default AllInvoiceHeader

const HeaderContainer = styled.div`
  border-radius: 10px;
  padding: 10px 25px;
  background: #FAFAFA;

  @media screen and (max-width: 600px) {
   padding: 10px;
  }
`;

const InvoicesCount = styled.p`
  /* padding: 10px; */
  font-weight: 500;
  font-size: 1rem;
  white-space: nowrap;
`;
const UtilsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #CCCCCC;

  @media screen and (max-width: 575px) {
   flex-direction: column;
   align-items: flex-start;
  }
`;
const UtilsBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;

  @media screen and (max-width: 449px) {
    flex-direction: column;
   justify-content: flex-start;
   margin-left: 0;
  }
`;
const UtilsBtn = styled.div`
  margin: 0 5px;
`;
const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  padding: 10px 0;
  gap: 10px;

  @media screen and (max-width: 690px) {
  flex-direction: column;
  justify-content: flex-start;
  }
`;
const Search = styled.div`
  position: relative;
  width: 100%;
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 30%;
`;
const SearchIcon = styled.div`
  position: absolute;
  bottom: 30%;
  left: 8px;
`;
const SelectContainer = styled.div`
  min-width: 180px;
  /* margin-bottom: 5px; */

  @media screen and (max-width: 470px) {
    min-width: auto;
  }
`;