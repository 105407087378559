import { invoiceUpload } from "appstate/invoice/invoiceSlice";
import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import Spinner from "shared/Spinner/Spinner";

const InvoiceTable = () => {
  const { savedCsvData, isLoading } = useSelector((state) => state?.invoice);
  const keys = Object.keys(savedCsvData[0] || {});
  const dispatch = useDispatch();
  const handleSubmit = () => {
    const body = savedCsvData;
    dispatch(invoiceUpload({ invoiceUploadDTO: body })).then((data) => {
      if (data?.payload?.success) {
        toast.success("Invoice Uploaded successfully");
      }
    });
  };
  return (
    <DashboardLayout>
      <div>
        <header className="flex flex-col items-start w-full  self-stretch py-4 px-6 bg-[#FAFAFA] rounded-t-[10px]">
          <div className="flex justify-between items-center w-full">
            <div className="Pro Display  text-black font-[sf text-sm font-medium leading-[normal]">
              Review Invoice content
            </div>
            <div className="flex items-center  py-3 px-6 rounded-[0.3125rem] border border-[#ccc] bg-[#f8f8f8] Pro Display text-black font-[sf text-sm font-medium leading-[normal]">
              Compare Invoices
            </div>
          </div>
        </header>
        <div className="p-[1.5rem] rounded-b-[10px]  bg-white">
          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right">
              <thead className="text-xs uppercase border-t border-b border-silver">
                <tr>
                  {keys.map((key, index) => (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-[0.75rem] font-bold text-lightGray"
                    >
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {savedCsvData.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className="bg-white border-b border-silver"
                  >
                    {keys.map((key, cellIndex) => (
                      <td
                        key={cellIndex}
                        className="px-6 py-4 font-medium text-[0.75rem] text-black whitespace-nowrap "
                      >
                        {row[key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end px-[30px] pt-[30px]">
            <div className="flex gap-x-[10px]">
              <Button
                neutral
                className="bg-red border-[1px] border-silver rounded-[5px] w-[5.6875rem] h-[2.5625rem] !text-white font-medium !text-sm"
              >
                Cancel
              </Button>

              <Button
                neutral
                disabled={isLoading}
                onClick={handleSubmit}
                className="bg-primaryColor border-[1px] border-silver rounded-[5px] h-[2.5625rem] w-[8.5625rem] !text-white font-medium !text-sm"
              >
                {isLoading ? <Spinner /> : " Save & Upload"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default InvoiceTable;
