import {
  PAIconEye,
  PAIconPencilLine,
  PAIconSecurityLock,
  PAIconUnlock,
  PAIconVieweye,
} from "../../../assets/images/svgs";
import { useState } from "react";
import ProfilePic from "./ProfilePic";
import { useDispatch, useSelector } from "react-redux";
import InputField from "shared/InputField";
import { useFormik } from "formik";
import PasswordLength from "shared/PasswordLength";
import Button from "shared/Button";
import Spinner from "shared/Spinner/Spinner";
import { resetPasswordValidationSchema } from "validation/settings/ResetPasswordSchema";
import { enableUser2FA, resetPassword } from "appstate/settings/settingsSlice";
import { toast } from "react-hot-toast";
import { getCurrentUser } from "appstate/auth/authSlice";

const Security = ({ currentUser }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.settings);

  const calculateStrength = (value) => {
    let strength = 0;
    if (/\d/.test(value)) {
      strength++;
    }
    if (/[!@#$%^&*]/.test(value)) {
      strength++;
    }
    if (/[A-Z]/.test(value)) {
      strength++;
    }
    if (value.length >= 6) {
      strength++;
    }
    return strength;
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: (values) => {
      const body = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      dispatch(resetPassword(body)).then((data) => {
        if (data?.payload?.status === 200) {
          toast.success("Password edited successfully!");
          setIsEditing(false);
        }
      });
    },
  });

  const toggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div>
      <div className="flex flex-col py-[18px] px-[25px] space-y-5">
        <ProfilePic currentUser={currentUser} />
        <div className="bg-gallery h-[1px] pb-1 w-full"></div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col">
            <div className="mb-5">
              <p className="font-bold">Security Details</p>
            </div>
            <div className="flex flex-col w-[450px] max-sm:w-full gap-6">
              <div className="w-full">
                <InputField
                  disabled={!isEditing}
                  className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                  placeholder="********"
                  label="Current Password"
                  type={showOldPassword ? "text" : "password"}
                  id="oldPassword"
                  name="oldPassword"
                  onChange={formik.handleChange}
                  onBlur={() => setIsFocused(false)}
                  value={formik.values.oldPassword}
                />
                {showOldPassword ? (
                  <PAIconVieweye
                    onClick={toggleShowOldPassword}
                    className="float-right mr-[6px] mt-[-35px] relative z-2 hover:cursor-pointer"
                  />
                ) : (
                  <PAIconEye
                    onClick={toggleShowOldPassword}
                    className="float-right mr-[6px] mt-[-35px] relative z-2 hover:cursor-pointer"
                  />
                )}
                {formik.touched.oldPassword && formik.errors.oldPassword ? (
                  <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.oldPassword}
                  </p>
                ) : null}
              </div>
              <div className="w-full">
                <InputField
                  disabled={!isEditing}
                  className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                  placeholder="********"
                  label="New Password"
                  type={showNewPassword ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  onChange={formik.handleChange}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  value={formik.values.newPassword}
                />
                {showNewPassword ? (
                  <PAIconVieweye
                    onClick={toggleShowNewPassword}
                    className="float-right mr-[6px] mt-[-35px] relative z-2 hover:cursor-pointer"
                  />
                ) : (
                  <PAIconEye
                    onClick={toggleShowNewPassword}
                    className="float-right mr-[6px] mt-[-35px] relative z-2 hover:cursor-pointer"
                  />
                )}
                <div className="flex justify-between mt-[10px]">
                  <PasswordLength
                    className={`strength-bar ${
                      isFocused ? "block" : "hidden"
                    } ${
                      calculateStrength(formik.values.newPassword) === 1
                        ? "bg-red-500"
                        : calculateStrength(formik.values.newPassword) === 2
                        ? "bg-yellow-500"
                        : calculateStrength(formik.values.newPassword) === 3
                        ? "bg-yellow-500"
                        : calculateStrength(formik.values.newPassword) >= 4
                        ? "bg-green-500"
                        : "bg-red-500"
                    }`}
                  />
                  <PasswordLength
                    className={`strength-bar ${
                      isFocused ? "block" : "hidden"
                    } ${
                      calculateStrength(formik.values.newPassword) === 2
                        ? "bg-yellow-500"
                        : calculateStrength(formik.values.newPassword) === 3
                        ? "bg-yellow-500"
                        : calculateStrength(formik.values.newPassword) >= 4
                        ? "bg-green-500"
                        : ""
                    }`}
                  />
                  <PasswordLength
                    className={`strength-bar ${
                      isFocused ? "block" : "hidden"
                    } ${
                      calculateStrength(formik.values.newPassword) === 3
                        ? "bg-yellow-500"
                        : calculateStrength(formik.values.newPassword) >= 4
                        ? "bg-green-500"
                        : ""
                    }`}
                  />
                  <PasswordLength
                    className={`strength-bar ${
                      isFocused ? "block" : "hidden"
                    } ${
                      calculateStrength(formik.values.newPassword) >= 4
                        ? "bg-green-500"
                        : ""
                    }`}
                  />
                </div>
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.newPassword}
                  </p>
                ) : null}
              </div>
              <div className="w-full">
                <InputField
                  disabled={!isEditing}
                  className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                  placeholder="********"
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={() => setIsFocused(false)}
                  value={formik.values.confirmPassword}
                />
                {showConfirmPassword ? (
                  <PAIconVieweye
                    onClick={toggleShowConfirmPassword}
                    className="float-right mr-[6px] mt-[-35px] relative z-2 hover:cursor-pointer"
                  />
                ) : (
                  <PAIconEye
                    onClick={toggleShowConfirmPassword}
                    className="float-right mr-[6px] mt-[-35px] relative z-2 hover:cursor-pointer"
                  />
                )}
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.confirmPassword}
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="py-6">
            {isEditing ? (
              <div className="flex gap-2 justify-start">
                <Button
                  type="button"
                  onClick={() => setIsEditing(!isEditing)}
                  className="flex !text-[14px] !px-6 !py-3 items-center bg-wildSand border-none !rounded-[10px] h-[40px]"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="flex !text-[14px] !px-6 !py-3 items-center !bg-emerald border-none !rounded-[10px] h-[40px] hover:bg-green-400"
                >
                  {isLoading ? <Spinner /> : "Save"}
                </Button>
              </div>
            ) : (
              <p
                onClick={() => setIsEditing(!isEditing)}
                className="cursor-pointer inline-flex items-center px-4 py-1 gap-[2px] hover:bg-blue-50 text-gray-800 text-sm font-medium rounded-full border border-[#213874] -mt-3"
              >
                <PAIconPencilLine />
                Edit
              </p>
            )}
          </div>
        </form>
        <div className="bg-gallery h-[1px] pb-1 w-full"></div>

        {currentUser?.enabled2FA ? (
          isLoading ? (
            <Spinner color />
          ) : (
            <div className="space-y-2.5 pb-2">
              <p className="font-bold">Two-factor authentication</p>
              <div className="flex flex-col justify-center mx-auto items-center text-center space-y-2 px-4 pt-6">
                <PAIconUnlock />
                <div className="space-y-1 pb-1.5">
                  <p className="text-md">
                    Two-factor authentication is enabled.
                  </p>
                  <p className="text-[13px] text-[#7a7a7a] leading-none">
                    Two-factor authentication adds an additional layer of
                    security to your account by requiring more than just a
                    password to sign in.
                  </p>
                </div>
                <button
                  className="inline-flex items-center px-4 py-1 gap-[2px] hover:bg-blue-50 text-[#213874] text-sm font-medium rounded-full border border-[#213874]"
                  onClick={() => {
                    dispatch(enableUser2FA({ enabled2FA: false })).then(
                      (data) => {
                        toast.success("Two factor authentication disabled!");
                        dispatch(getCurrentUser(data?.payload));
                      }
                    );
                  }}
                >
                  Disable 2FA
                </button>
              </div>
            </div>
          )
        ) : isLoading ? (
          <Spinner color />
        ) : (
          <div className="space-y-2.5 pb-2">
            <p className="font-bold">Two-factor authentication</p>
            <div className="flex flex-col justify-center mx-auto items-center text-center space-y-2 px-4 pt-6">
              <PAIconSecurityLock />
              <div className="space-y-1 pb-1.5">
                <p className="text-md">
                  Two-factor authentication is not enabled yet.
                </p>
                <p className="text-[13px] text-[#7a7a7a] leading-none">
                  Two-factor authentication adds an additional layer of security
                  to your account by requiring more than just a password to sign
                  in.
                </p>
              </div>
              <button
                className="inline-flex items-center px-4 py-1 gap-[2px] hover:bg-blue-50 text-[#213874] text-sm font-medium rounded-full border border-[#213874]"
                onClick={() => {
                  dispatch(enableUser2FA({ enabled2FA: true })).then((data) => {
                    toast.success(
                      "Two factor authentication enabled successfully!"
                    );
                    dispatch(getCurrentUser(data?.payload));
                  });
                }}
              >
                Enable 2FA
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Security;
