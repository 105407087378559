import request from "apiInstance";
import toast from "react-hot-toast";

export const supplierAssociateWithBuyer = async (buyerId) => {
  try {
    const response = await request({
      method: "POST",
      url: `/api/v1/suppliers/send-association-request?buyer=${buyerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierSendAssociationReqeustToFunder = async (funderId) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/suppliers/associate-with-funder/${funderId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllSupplierAssociatedBuyers = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/accountTier/supplier-associated-buyers?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierSendOnboardingInviteToFunder = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/suppliers/onboarding-invites/send",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const supplierAssociatedFunder = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/accountTier/supplier-associated-funders?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllSuppliers = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const suppliersSearch = async ({ fullName }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/suppliers/search?fullName=${fullName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const disassociateSupplierBuyer = async (buyerId) => {
  // /api/v1/suppliers/dissociate-buyer/
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/dissociate-buyer/${buyerId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const disassociateSupplierFunder = async (funderId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/suppliers/dissociate-funder/${funderId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
