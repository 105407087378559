import React from "react";

const GeneralComment = () => {
  return (
    <div className="w-full h-[167px] bg-stone-50 rounded-tr-[5px] rounded-bl-[5px] rounded-br-[5px] border border-gray300">
      <div className="w-full h-[43px] pl-[15px] pr-[22px] pt-[15px] pb-3.5 rounded-tr-[5px] border-b border-emerald border-opacity-20 justify-start items-center inline-flex">
        <div className="w-full text-emerald text-xs font-normal font-['SF Pro Display']">
          Comments posted here goes to the specified update section
        </div>
      </div>
      <textarea
        className="w-full h-[122px] bg-stone-50 p-2 outline-none rounded-md resize-none"
        placeholder="Type your comment here..."
      ></textarea>
    </div>
  );
};

export default GeneralComment;
