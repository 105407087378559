// import { PAIconVieweye } from "assets/images/svgs";
import {
  PAIconFiller,
  PAIconFilter,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import TransactionModal from "features/transactions/transactionDashboard/TransactionModal";
import Table from "layout/table/Table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";

// Dummy data
const dummyTransactions = [
  {
    transactionId: "001",
    buyerFullName: "John Doe",
    paymentDate: "2024-06-25",
    dueDate: "2024-07-25",
    currency: "USD",
    amount: "$500",
    status: "Successfull",
  },
  {
    transactionId: "002",
    buyerFullName: "Jane Smith",
    paymentDate: "2024-06-24",
    dueDate: "2024-07-24",
    currency: "EUR",
    amount: "€300",
    status: "Pending",
  },
  {
    transactionId: "003",
    buyerFullName: "Alice Johnson",
    paymentDate: "2024-06-23",
    dueDate: "2024-07-23",
    currency: "GBP",
    amount: "£200",
    status: "Failed",
  },
  {
    transactionId: "004",
    buyerFullName: "Bob Brown",
    paymentDate: "2024-06-22",
    dueDate: "2024-07-22",
    currency: "NGN",
    amount: "₦150,000",
    status: "Pending",
  },
];

const PortfolioMangementTable = () => {
  const navigate = useNavigate();
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  //   function handleViewMore(transactionId) {
  //     navigate(`/funds&portfolio/portfolioinvoice/${transactionId}`);
  //   }
  function handleViewMore(transactionId) {
    navigate(`/funds&portfolio/portfolioinvoice/${transactionId}`);
  }

  const columns = [
    {
      name: "Invoice Reference",
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyerFullName,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Successfull" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Failed" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "Pending" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  return (
    <>
      <Card className="rounded-[10px] mt[24px]">
        <div className="flex flex-col md:flex-row justify-between items-center px-[24px] bg-alabasterHeader p-2 h-auto lg:h[60px] rounded-t-[10px]">
          <div className="flex flexrow lg:flex-row flex-wrap items-center lg:items-center justify-between gap3 lg:gap-0">
            <div>
              <p className="text-black text-sm font-medium">Funded Invoices</p>
              <p className="text-[10px]">All funded invoices</p>
            </div>
            <div className="border border-[#7A7A7A] text-[14px] px-4 py-2 rounded-md flex items-center justify-center cursor-pointer shadow-md ml-5">
              <span className="mr-2">
                <PAIconFiller />
              </span>
              All
            </div>
          </div>
          {/* SEARCH */}
          <div className="flex justify-end mt-4 md:mt-0 w-full md:w-auto">
            <div className="flex flex-col lg:flex-row items-center justify-between gap-3 lg:gap-5 w-full lg:w-auto">
              <div className="border border-transparent lg:border-gray lg:shadow-sm rounded-[5px] px-2 w-full lg:w-[300px] bg-white flex items-center">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for request"
                  // value={searchQuery}
                  // onChange={(e) => setSearchQuery(e.target.value)}
                  // onKeyPress={(e) => {
                  //   if (e.key === "Enter") {
                  //     handleSearch();
                  //   }
                  // }}
                />
                <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                  <PAIconFilter className="bg-[#2FA06A]" />
                </div>
              </div>
              <button className="hidden lg:flex bg-[#F2F2F2] border border-1 border-[#CCC] rounded-md py-2 px-4 items-center gap-2">
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="px-[24px] pb-[24px]">
          <Table
            columns={columns}
            data={dummyTransactions}
            onRowClicked={(row) => handleViewMore(row.transactionId)}
            pointer
            tableHeader
            className="mt-5"
          />
        </div>
      </Card>
      {selectedTransactionId && (
        <TransactionModal
          transactionId={selectedTransactionId}
          onClose={() => setSelectedTransactionId(null)}
        />
      )}
    </>
  );
};

export default PortfolioMangementTable;
