import React from "react";
import { PAIconRoundedCheck } from "assets/images/svgs";
import { motion } from "framer-motion";

const SavedRuleModal = ({
  onClose,
  // toastMessage = "User Removed Successfully",
  dataInfo,
  title = "Success: Rules Saved",
  children,
  icon = <PAIconRoundedCheck />,
}) => {
  // const handleCloseDeleteModal = () => {
  //   onClose();
  // };

  // const handleDelete = () => {
  //   toast.success(toastMessage);
  //   onClose();
  // };

  return (
    <motion.div>
      <motion.div className='bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50'>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className='modal-overlay'
        ></motion.div>
        <motion.div
          onClick={(e) => e.stopPropagation()}
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className='modal-container ml-16 bg-white p-4 rounded-lg shadow-lg z-50 w-2/6'
        >
          <div className='flex px-7  py-6 flex-col gap-6'>
            <section className=' flex items-center gap-6'>
              <div className='flex flex-col gap-3'>
                <span className=' text-primaryColor font-bold'>{title}</span>

                <div className=' w-full  text-sm text-[#979797]'>
                  {children}
                </div>
              </div>

              <div className=' w-[15%]'>{icon}</div>
            </section>

            <footer className=' flex justify-end'>
              <button
                onClick={() => onClose()}
                className='btn  bg-primaryColor text-white rounded-md px-6 py-2'
              >
                Close
              </button>
            </footer>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default SavedRuleModal;
