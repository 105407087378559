import Modal from 'layout/modal/Modal'
import React from 'react'
import AgreementModalContent from './AgreementModalContent'

const AgreementModal = ({isShown, onClose}) => {
  return (
    <Modal accountModal isShown={isShown} hide={onClose}>
      <AgreementModalContent onClose={onClose} />
    </Modal>
  )
}

export default AgreementModal