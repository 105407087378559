import React, { useEffect } from "react";
import Overview from "./Overview";
import DashboardTable from "./Table";
import DashboardLayout from "layout/dashboardlayout";
import BuyerOverview from "../../../shared/overviewHeader";
import { useSelector } from "react-redux";

const SupplierDashboard = () => {
  const { allVirtualCardsData } = useSelector((state) => state?.wallet);

  const formatCardNumber = (cardNumber) => {
    if (!cardNumber) {
      return "";
    }

    const sanitizedCardNumber = cardNumber?.replace(/\D/g, "");
    const maskedCardNumber = sanitizedCardNumber.replace(
      /(\d{4})(\d{4})(\d{4})(\d{4})/,
      (_, firstPart, middlePart1, middlePart2, lastPart) => {
        const maskedMiddlePart = `${middlePart1.replace(
          /\d/g,
          "*"
        )} ${middlePart2.replace(/\d/g, "*")}`;
        return `${firstPart} ${maskedMiddlePart} ${lastPart}`;
      }
    );

    return maskedCardNumber.trim();
  };

  const formatExpiryDate = (expiryDate) => {
    if (!expiryDate) {
      return "";
    }

    const options = { month: "2-digit", year: "2-digit" };
    return new Date(expiryDate).toLocaleDateString(undefined, options);
  };

  const getFirstCardData = () => {
    const cardData = Array.isArray(allVirtualCardsData?.data)
      ? allVirtualCardsData?.data?.map((card) => ({
          id: card?.id,
          cardName: card?.cardName,
          cardNumber: formatCardNumber(card?.cardNumber),
          cardCurrency: card?.cardCurrency,
          paymentMethod: card?.paymentMethod,
          balance: card?.balance,
          expiryDate: formatExpiryDate(card?.expiryDate),
        }))
      : [];
    return cardData?.length > 0 ? cardData[0] : null;
  };

  const firstCardData = getFirstCardData();

  useEffect(() => {
    // Clean up sessionStorage if necessary

    sessionStorage.removeItem("activeTab");
    sessionStorage.removeItem("searchQuery");
    sessionStorage.removeItem("activeTabSupplier");
    sessionStorage.removeItem("searchQuerySupplier");
  }, []);

  return (
    <DashboardLayout subHeader='Here is what is happening in your finance'>
      <BuyerOverview />
      <Overview cardData={firstCardData} />
      <DashboardTable />
    </DashboardLayout>
  );
};

export default SupplierDashboard;
