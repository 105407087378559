import React, { useState } from "react";
import SearchInput from "./searchInput";
import { PAIconDropdown } from "assets/images/svgs";

const Headers = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="my-2 grid md:grid-cols-2 items-center">
      <div className="gap-3 flex relative">
        <div>
          <div
            className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
            onClick={handleDropdownToggle}
          >
            Filter{" "}
            {selectedFilters.length > 0 && (
              <span className="bg-gray rounded-lg p-1 px-2">
                {selectedFilters.length}
              </span>
            )}{" "}
            Selected <PAIconDropdown />
          </div>
          {isDropdownOpen && (
            <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
              <label className="block">
                <input
                  type="checkbox"
                  value="date"
                  checked={selectedFilters.includes("date")}
                  onChange={handleFilterChange}
                />
                Date
              </label>
              <label className="block">
                <input
                  type="checkbox"
                  value="name"
                  checked={selectedFilters.includes("name")}
                  onChange={handleFilterChange}
                />
                Name
              </label>
              <label className="block">
                <input
                  type="checkbox"
                  value="currency"
                  checked={selectedFilters.includes("currency")}
                  onChange={handleFilterChange}
                />
                Currency
              </label>
              <label className="block">
                <input
                  type="checkbox"
                  value="amount"
                  checked={selectedFilters.includes("amount")}
                  onChange={handleFilterChange}
                />
                Amount
              </label>
            </div>
          )}
        </div>
        <div>
          <select
            className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
            onChange={(e) => {}}
          >
            <option value="">Sort by</option>
            <option value="date">Date</option>
            <option value="name">Name</option>
          </select>
        </div>
        <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 ">
          Export
        </button>
      </div>
      <div className="flex md:col-span-1 justify-end">
        <SearchInput />
      </div>
    </div>
  );
};

export default Headers;
