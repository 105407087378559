import React from "react";

const InvoiceModal = () => {
  return (
    <div>
      <div className="max-w-[40rem] px-4 pb-4 md:px-[32px] md:pb-[32px] mx-auto">
        {/* <!-- Grid --> */}
        <div className="mb-4 flex justify-between items-center">
          <div>
            <h2 className="text-lg font-bold text-gray-800 ">
              Purchase Order | INV-2002
            </h2>
          </div>
        </div>

        {/* <!-- Grid --> */}
        <div className="grid md:grid-cols-2 gap-3">
          <div>
            <div className="grid space-y-3">
              <dl className="grid sm:flex gap-x-3 text-xs">
                <dt className="min-w-[150px] max-w-[200px] text-gray-500 font-bold text-xs">
                  Created Date:
                </dt>
                <dd className="text-gray-800 text-xs">23-Dec-2024</dd>
              </dl>

              <dl className="grid sm:flex gap-x-3 text-sm">
                <dt className="min-w-[150px] max-w-[200px] text-gray-500 font-bold text-xs">
                  Payment Due Date:
                </dt>
                <dd className="text-gray-800 text-xs">23-Dec-2024</dd>
              </dl>
            </div>
          </div>
          {/* <!-- Col --> */}
        </div>

        <div className="flex justify-between md:grid grid-cols-2 items-center pt-5 md:space-x-40">
          <div className="flex items-center">
            <div className="flex bg-[#c4c4c4] p-4 px-6 py-5 rounded-lg m-3 cursor-pointer -ml-0"></div>
            <div>
              <h2 className="font-semibold text-xs md:text-sm">Anastasya Gordon</h2>
              <h3 className="text-[#626679] text-xs">Company</h3>
            </div>
          </div>
          <div className="flex">
            <button className="bg-greenIce text-greenHaze font-bold py-2 px-8 rounded-md text-xs">
              Paid
            </button>
          </div>
        </div>

        <div className="md:grid grid-cols-2 pt-5 md:space-x-40">
          <div className="md:w-[150px]">
            <h2 className="font-semibold text-sm mb-1">From:</h2>
            <h3 className="text-[#626679] text-xs">
              <p className="mb-[5px]">The Holding Center</p>
              <p className="mb-[5px]">7 North Road </p>
              <p>Tabemono Asia AB 123 F</p>
              <p>gordon@gmail.com</p>
              <p>+1234-456-789</p>
            </h3>
          </div>

          <div className="mt-[30px] md:mt-0 md:w-[150px]">
            <h2 className="font-semibold text-sm mb-1">Bill to:</h2>
            <h3 className="text-[#626679] text-xs">
              <p className="mb-[5px]">The Holding Center</p>
              <p className="mb-[5px]">7 North Road </p>
              <p>Tabemono Asia AB 123 F</p>
              <p>gordon@gmail.com </p>
              <p>+1234-456-789</p>
            </h3>
          </div>
        </div>

        <div className="h-[5px] w-full bg-[#F6F5FA] mt-[38px]"></div>

        <h1 className="mb-[20px] mt-[38px] font-bold text-md">Descriptions</h1>

        {/* <!-- Table --> */}
        <div className="rounded-lg space-y-4">
          <div className=" grid grid-cols-3  md:grid-cols-5 bg-[#efefef] h-full gap-2 py-2 px-3 ">
            <div className="md:col-span-2 text-xs font-medium text-black uppercase">
              Item
            </div>
            <div className="text-left text-xs font-medium text-black uppercase">
              Quantity
            </div>
            <div className="text-left text-xs font-medium text-black uppercase">
            Unit Price
            </div>
          </div>
          <div className="hidden sm:block"></div>
            <div  className=" grid grid-cols-3 md:grid-cols-5  gap-2">
              <div className="md:col-span-2">
                <p className="font-medium text-gray-800 text-xs">
                Smart watch
                </p>
              </div>
              <div>
                <p className="text-gray-800 text-xs">2</p>
              </div>
              <div>
                <p className="text-gray-800 text-xs">$30</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceModal;
