import React from "react";

const DeleteModal = ({ isOpen, onClose, handleDeleteAgreement }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg">
      <div className="bg-white p-5 px5 rounded-md max-w-xs md:max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <p className="text-black font-sans text-base font-semibold">
            Delete Agreement
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        <div className="border border-gray my-5"></div>

        {/* FILE BUTTONS */}
        <div className="w-full">
          <div className="text-[#AA1515] font-sans text-sm font-normal tracking-wide">
            Mutual agreement is necessary to delete an agreement or its
            versions. Initiating deletion through 'Request Deletion' notifies
            the other party's consent for removal and once accepted, the
            agreement would thereby be deleted
          </div>
        </div>
        {/* DOWNLOAD BUTTON */}
        <div className="mt-4">
          <button className="px-4 py-2 bg-[#CC170C] text-white rounded-md my-3" onClick={handleDeleteAgreement}>
            Request Deletion   
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
