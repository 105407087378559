import {
  PAIconAlertIcon,
  PAIconCancel,
  PAIconEmailIcon,
} from "assets/images/svgs";
import React from "react";
import Button from "shared/Button";
import Spinner from "shared/Spinner/Spinner";

const DeclineModalContent = ({
  handleAssociate,
  warning,
  isLoading,
  success,
  info,
  hide,
}) => {
  return (
    <>
      {success === false ? (
        <div className="flex flex-col items-center gap-y-[24px] py-[28px]">
          <div>
            <PAIconAlertIcon />
          </div>
          <p className="text-base font-normal  text-lightGray">{warning}</p>
          <div className="overflow-hidden flex">
            <Button
              onClick={handleAssociate}
              neutral
              className="bg-primaryColor text-white w-[76px] flex items-center justify-center h-[43px] rounded-[5px] text-base font-normal"
            >
              {isLoading ? <Spinner /> : "Yes"}
            </Button>
            <div>
              <Button
                onClick={hide}
                neutral
                className="w-[76px] h-[43px] !text-red border-[1px] border-red rounded-[5px] ml-[24px]"
              >
                No
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="px-[27px] py-[38px]">
          <header className="flex justify-between border-b-2 border-whisper200 pb-[16px]">
            <p>Request to associate</p>
            <div
              onClick={hide}
              className="flex justify-between items-center gap-x-[10px] cursor-pointer"
            >
              <PAIconCancel className="w-[8px]" />
              <p className="text-sm font-medium text-gray800">Close</p>
            </div>
          </header>
          <div className="flex justify-center mt-[32px] mb-[10px]">
            <PAIconEmailIcon />
          </div>
          <p className="text-center px-[56px]">{info}</p>
        </div>
      )}
    </>
  );
};

export default DeclineModalContent;
