import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Card from "shared/Card";
import BuyerRulesandConfig from "./buyer";
import { useSelector } from "react-redux";
import SupplierRulesandConfig from "./supplier";
import FunderRulesandConfig from "./funder";

const RulesConfig = () => {
  const { userId } = useSelector((state) => state.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const ruleConfigPlayer =
    userRole === "supplier" ? (
      <SupplierRulesandConfig />
    ) : userRole === "funder" ? (
      <FunderRulesandConfig />
    ) : (
      <BuyerRulesandConfig />
    );
  return (
    <DashboardLayout subHeader='Here is what is happening in your finance'>
      <Card className='w-full h-full rounded-md px-10 py-10 flex flex-col gap-5'>
        <header>
          <h1 className='text-jaguarBlack text-xl font-bold'>
            Rules and Configuration Management
          </h1>
          <span
            className='text-sm text-dustyGray font-[500]]
        '
          >
            Configure and manage rules for various aspects of PayEdge
          </span>
        </header>

        <main className='w-full h-full overflow-auto'>{ruleConfigPlayer}</main>
      </Card>
    </DashboardLayout>
  );
};

export default RulesConfig;
