import React, { useState, useRef, useEffect } from "react";

const VirtualBvnModal = ({ isOpen, onClose, onSubmit }) => {
  const [otpInputs, setOtpInputs] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, otpInputs.length);
  }, [otpInputs.length]);

  const handleInputChange = (index, value) => {
    if (!isNaN(value) || value === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index] = value;
      setOtpInputs(newOtpInputs);

      if (value !== "" && index < otpInputs.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otpInputs[index] === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index - 1] = "";
      setOtpInputs(newOtpInputs);

      inputRefs.current[index - 1].focus();
    }
  };

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Create Virtual Account
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        {/* BVN Form */}
        <form className="w-full px-7 pt-7" onSubmit={handleSubmit}>
          <p className="mb-2 flex justify-center text-[#222] font-sans italic text-xl font-extrabold leading-normal">
            Bvn verification
          </p>
          <p className="text-[#86888B] font-sans text-xs font-normal leading-normal flex justify-center mb-5">
            Enter the verification OTP that was sent to this number
            +23490****987
          </p>
          <div className="mb-4 flex flex-row justify-center gap-2">
            {otpInputs.map((value, index) => (
              <input
                key={index}
                type="text"
                value={value}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                maxLength="1"
                className="border rounded p-2 border-green-900 focus:border-[#222] focus:outline-none w-10 h-10 flex-shrink-0 text-center"
                ref={(ref) => (inputRefs.current[index] = ref)}
              />
            ))}
          </div>

          {/* Buttons */}
          <div className="flex justify-end mt-10">
            <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-gray300 mx-2">
              <button
                type="button"
                onClick={onClose}
                className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
              >
                Cancel
              </button>
            </div>
            <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]">
              <button
                className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                type="submit"
              >
                Confirm
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VirtualBvnModal;
