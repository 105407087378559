import request from "apiInstance";
import toast from "react-hot-toast";

export const supplierTransactions = async () => {
  try {
    const response = await request({
      method: "GET",
      url: "/api/v1/suppliers/transactions?page&size",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
