import {
  allVirtualAccounts,
  allVirtualCards,
} from "appstate/wallet/walletSlice";
import { PAIconCardOutline, PAIconPaymnet } from "assets/images/svgs";
import RequestVirtualCardOption from "features/wallet/accountManagement/dashboad/virtualCard/modal";
import StepOne from "features/wallet/accountManagement/dashboad/virtualCard/virtualDebitCard/stepOne";
import StepTwo from "features/wallet/accountManagement/dashboad/virtualCard/virtualDebitCard/stepTwo";
import VirtualBvnModal from "features/wallet/createVirtualAccount/bvn";
import VirtualAccountModal from "features/wallet/createVirtualAccount/modal";
import CongratsModal from "features/wallet/linkExternalAccount/congrats/congratsModal";
import React, { useEffect, useState } from "react";
// import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

const WithdrawOption = ({ isOpen, onClose, onSubmit }) => {
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [isCreateVirtualAccountOpen, setCreateVirtualAccountOpen] =
    useState(false);
  const [showVirtualAccountBvn, setShowVirtualAccountBvn] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isVirtualCardOptionOpen, setVirtualCardOptionOpen] = useState(false);
  const [isStepOne, setStepOne] = useState(false);
  const [isStepTwo, setStepTwo] = useState(false);
  const [currency, setCurrency] = useState("NGN");
  const [congratsModal, setCongratsModal] = useState(false);

  const toggleCreateVirtualAccount = () => {
    setCreateVirtualAccountOpen(!isCreateVirtualAccountOpen);
  };

  const handleVirtualAccountSubmit = () => {
    setCreateVirtualAccountOpen(false);
    setShowVirtualAccountBvn(true);
  };

  const handleVirtualBvnSubmit = () => {
    setShowVirtualAccountBvn(false);
    setShowCongratsModal(true);
  };

  const handleClose = () => {
    setCreateVirtualAccountOpen(false);
    setShowVirtualAccountBvn(false);
    setShowCongratsModal(false);

    setVirtualCardOptionOpen(false);
    setStepOne(false);
    setStepTwo(false);
    setCongratsModal(false);
  };

  const handleVirtualCardOption = (account) => {
    setSelectedAccount(account);
    setVirtualCardOptionOpen(!isVirtualCardOptionOpen);
  };

  const handleVirtualCardOptionSubmit = () => {
    setVirtualCardOptionOpen(false);
    setStepOne(true);
  };

  const handleStepOneSubmit = () => {
    setStepOne(false);
    setStepTwo(true);
  };

  const handleStepTwoSubmit = () => {
    setStepTwo(false);
    setCongratsModal(true);
  };

  const { allVirtualAccountsData, allVirtualCardsData } = useSelector(
    (state) => state?.wallet
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allVirtualAccounts());
    dispatch(allVirtualCards());
  }, [dispatch]);

  const formatCardNumber = (cardNumber) => {
    if (!cardNumber) {
      return "";
    }

    const formattedCardNumber = cardNumber?.replace(/(\d{4})/g, "$1 ");
    return formattedCardNumber.trim();
  };

  const accountData = Array.isArray(allVirtualAccountsData?.data)
    ? allVirtualAccountsData.data.map((account) => ({
        id: account?.id,
        name: account?.accountName,
        accountNumber: account?.accountNumber,
        bankName: account?.provider || "N/A",
        accountType: "Virtual Account",
        bvn: account?.bvn,
      }))
    : [];

  const cardData = Array.isArray(allVirtualCardsData?.data)
    ? allVirtualCardsData?.data?.map((card) => ({
        id: card?.id,
        cardName: card?.cardName,
        cardNumber: formatCardNumber(card?.cardNumber),
        cardCurrency: card?.cardCurrency,
        paymentMethod: card?.paymentMethod,
        balance: card?.balance,
        expiryDate: card?.expiryDate,
      }))
    : [];

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit(selectedAccounts);
      setSelectedAccounts([]);
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
        <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
          <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
            <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
              Withdraw Funds
            </p>
            <button
              onClick={onClose}
              className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
            >
              &times; <span className="text-sm mx-2 mt-1">Close</span>
            </button>
          </div>

          {/* ACCOUNTS */}
          <div>
            <div className="w-full px-7">
              {selectedAccounts?.map((selectedAccount, index) => (
                <div
                  key={index}
                  className="text-[#7A7A7A] font-sans text-sm font-normal mb-6 flex items-center"
                >
                  <p className="text-zinc-600 text-sm font-extrabold flex justify-center">
                    {/* {selectedAccounts?.length > 1 ? "To" : "From"} */}To
                  </p>
                  <div className="border border-[#F08000] ml-3 p-1 w-[80%] flex items-center rounded text-xs">
                    <PAIconCardOutline className="mr-2 w-4" />
                    <p>{selectedAccount?.name}</p> -{" "}
                    <p>{selectedAccount?.accountNumber}</p> -{" "}
                    <p>{selectedAccount?.bankName}</p>
                  </div>
                </div>
              ))}

              <p className="text-zinc-600 text-sm font-extrabold flex justify-center">
                Select which account or card to transfer from
              </p>
              <h1 className="text-zinc-600 text-base font-extrabold mt-5">
                Accounts
              </h1>

              {/* ACCOUNTS */}

              <div className="md:flex md:gap-5 flex-wrap">
                {accountData.map((account, index) => (
                  <div
                    key={index}
                    className="bg-[#07593D] p-3 mt-3 cursor-pointer text-white text-xs md:w-[calc(50%-10px)] md:mb-0 mb-3 rounded"
                    onClick={() => {
                      setSelectedAccounts([account]);
                    }}
                  >
                    <div className="text-white text-xs flex items-center">
                      <PAIconPaymnet className="mr-2" /> <p>{account.name}</p>
                    </div>
                    <p className="mt-2">{account.accountNumber}</p>
                    <div className="flex items-center justify-between mt-2 text-[10px]">
                      <p>{account.bankName}</p>
                      <p>{account.accountType}</p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Buttons */}
              <div
                className="flex justify-end mt-10 mb-3"
                onClick={toggleCreateVirtualAccount}
              >
                <button className="bg-[#F08000] flex items-center rounded p-2 text-xs text-white">
                  <PAIconCardOutline className="mr-2 w-4" />
                  <p>Add new account</p>
                </button>
              </div>

              {/* CARDS */}
              <h1 className="text-zinc-600 text-base font-extrabold mt-5">
                Cards
              </h1>
              <div className="overflow-scroll max-h-32">
                {cardData.map((card, index) => (
                  <div
                    key={index}
                    className="bg-[#07593D] p-2 text-white dlex items-center justify-between w[80%] flex itemscenter rounded text-xs my-3 mr-3"
                  >
                    <p className="flex items-center">
                      {" "}
                      <PAIconCardOutline className="mr-2 w-4" />{" "}
                      {card?.cardName}
                    </p>{" "}
                    - <p>{card?.cardNumber}</p> - <p>{card?.balance}</p>
                  </div>
                ))}
              </div>

              {/* Buttons */}
              <div
                className="flex justify-end mt-10 mb-3"
                onClick={handleVirtualCardOption}
              >
                <button className="bg-[#F08000] flex items-center rounded p-2 text-xs text-white">
                  <PAIconCardOutline className="mr-2 w-4" />
                  <p>Add new card</p>
                </button>
              </div>

              <div className="flex justify-end mt-5 mb3 px-7">
                <button
                  className="bg-[#07593D] flex items-center rounded px-4 p-3 text-xs text-white"
                  onClick={handleSubmit}
                >
                  <p>Proceed</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VirtualAccountModal
        isOpen={isCreateVirtualAccountOpen}
        onClose={() => handleClose()}
        handleSubmit={handleVirtualAccountSubmit}
      />
      <VirtualBvnModal
        isOpen={showVirtualAccountBvn}
        onClose={() => handleClose()}
        onSubmit={handleVirtualBvnSubmit}
      />
      <CongratsModal
        isOpen={showCongratsModal}
        onClose={() => handleClose(false)}
        message="Your Virtual Account has been created"
      />
      <RequestVirtualCardOption
        isOpen={isVirtualCardOptionOpen}
        onClose={() => handleClose()}
        onSubmit={handleVirtualCardOptionSubmit}
        onSelectAccount={handleVirtualCardOption}
      />
      <StepOne
        isOpen={isStepOne}
        account={selectedAccount}
        onClose={() => handleClose()}
        onSubmit={(currency) => {
          setCurrency(currency);
          handleStepOneSubmit();
        }}
      />
      <StepTwo
        isOpen={isStepTwo}
        onClose={() => handleClose()}
        account={selectedAccount}
        currency={currency}
        onSubmit={handleStepTwoSubmit}
      />
      <CongratsModal
        isOpen={congratsModal}
        onClose={() => handleClose(false)}
        message="Your Virtual Debit Card has been created"
      />
    </>
  );
};

export default WithdrawOption;
