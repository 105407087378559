import React, { useEffect, useState } from "react";
import Card from "shared/Card";
import styled, { css } from "styled-components";
import { FONTSIZES, FONTWEIGHTS } from "constants/font-spec";
import { useNavigate } from "react-router-dom";

const Tabs = ({
  users,
  setOpen,
  app,
  title,
  state,
  tabs,
  actionComponent,
  stickyTab,
  className,
  actionComponentTitle,
  approved,
  setWrap,
  setContain,
  flexTabCol,
  setButton,
  setContnent,
  setBackground,
  ...rest
}) => {
  const getRoute = sessionStorage.getItem("route");
  // const [activeTab, setActiveTab] = useState();
  const [activeTab, setActiveTab] = useState();

  const navigate = useNavigate();
  useEffect(() => {
    getRoute === "0"
      ? setActiveTab(0)
      : getRoute === "1"
      ? setActiveTab(1)
      : getRoute === "2"
      ? setActiveTab(2)
      : getRoute === "4"
      ? setActiveTab(4)
      : getRoute === "7"
      ? setActiveTab(7)
      : setActiveTab(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClick = (index) => {
    setActiveTab(index);
    sessionStorage.setItem("route", index);
  };

  const handleTools = () => {
    // sessionStorage.setItem("route", index);
    navigate("/settings/tools&agreement");
  };

  // const handleBack = () => {
  //   navigate("/settings")
  // }

  return (
    <TabWrapper setWrap={setWrap}>
      <TabContainer
        className={stickyTab ? "sticky-header" : ""}
        {...rest}
        setContain={setContain}
        flexTabCol={flexTabCol}
      >
        <div className='header'>
          {/* <button onClick={handleBack}>back</button> */}

          {tabs?.map((tab, index) => (
            <TabButton
              key={index}
              active={activeTab === index}
              onClick={
                tab.title === "Tools & Agreement"
                  ? () => handleTools(index)
                  : () => handleClick(index)
              }
              setButton={setButton}
              setBackground={setBackground}
            >
              {tab.title}
            </TabButton>
          ))}
          {approved && actionComponent && (
            <p
              onClick={() => setOpen(true)}
              className='min-w-[120px] cursor-pointer flex justify-center rounded-lg py-2 px-4 text-[#F08000] border border-[#F08000] text-[12px] hover:bg-[#F08000] hover:text-white'
            >
              {actionComponentTitle}
            </p>
          )}
        </div>
      </TabContainer>
      <TabContent setContnent={setContnent}>
        {tabs && tabs[activeTab]?.component}
      </TabContent>
    </TabWrapper>
  );
};

const TabWrapper = styled.div`
  width: 100%;
  gap: 20px;
  .sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: -20px;
    z-index: 100;
  }

  ${({ setWrap }) =>
    setWrap &&
    css`
      /* height: 100%; */
      display: flex;
      border-radius: 30px;

      @media screen and (max-width: 900px) {
        flex-direction: column;
      }
    `}
`;

const TabContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px 8px;
  justify-content: space-between;
  background: #fafafa;
  padding: 0 32px 16px 32px;
  border-bottom: 1px solid black;

  @media screen and (max-width: 500px) {
    padding: 0 32px 16px 4px;
  }
  /* margin-top: 15px; */
  /* height: 0px; */
  // overflow-x: auto;
  .header {
    display: flex;
    flex-direction: ${({ flexTabCol }) => (flexTabCol ? "column" : "row")};
    justify-content: start;
    gap: 32px;

    /* width: 100%; */

    @media screen and (max-width: 500px) {
      gap: 0;
    }
    /* background: #FAFAFA; */
    &__header-btn {
      padding: 0;
      border-radius: 5px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
      &--icon {
        width: 20px;
      }
    }
  }

  ${({ setContain }) =>
    setContain &&
    css`
      border-radius: 10px;
      padding-top: 30px;
      border-bottom: 0px;
      flex-direction: column;
    `}
`;

const TabButton = styled.p`
  /* min-width: 90px; */
  /* border-bottom: 1px solid black; */
  cursor: pointer;
  background: transparent;
  /* padding: 4px 16px; */
  font-weight: ${FONTWEIGHTS.medium};
  font-size: ${FONTSIZES.small};
  text-transform: capitalize;
  ${({ active }) =>
    active &&
    css`
      /* font-weight:${FONTWEIGHTS.medium}; */
      /* border-bottom: 3px solid black; */
      cursor: default;
    `};

  @media screen and (max-width: 500px) {
    font-size: ${FONTSIZES.xsmall};
    padding: 0 4px;
  }

  ${({ setButton }) =>
    setButton &&
    css`
      white-space: nowrap;
      display: flex;
      padding: 10px 20px;
      align-items: flex-start;
      gap: 6px;
      align-self: stretch;
      border-radius: 5px;
      color: black;
      ${({ active }) =>
        active &&
        css`
          background-color: #07593d;
          cursor: default;
          color: white;
        `};
    `}

  ${({ setBackground }) =>
    setBackground &&
    css`
      white-space: nowrap;
      display: flex;
      padding: 10px 20px;
      align-items: flex-start;
      gap: 6px;
      align-self: stretch;
      border-radius: 5px;
      color: black;
      ${({ active }) =>
        active &&
        css`
          background-color: #07593d;
          cursor: default;
          color: white;
        `};
    `}
`;

const TabContent = styled.div`
  width: 100%;
  height: 100%;

  ${({ setContnent }) =>
    setContnent &&
    css`
      border-radius: 10px;
      background-color: white;
      min-height: 652px;
    `}
`;

export default Tabs;
