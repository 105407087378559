import "./capsule.css";
function Capsule({ isToggled, onToggle, width = "49px", height = "28px" }) {
  return (
    <label style={{ height, width }} className='switch-flow'>
      <input
        className=''
        type='checkbox'
        checked={isToggled}
        onChange={onToggle}
      />
      <span className='slider-flow'></span>
    </label>
  );
}

export default Capsule;
