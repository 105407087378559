import Modal from "layout/modal/Modal";
import TwoFactorModalContent from "./TwoFactorContent";
import ConfirmEnrollContent from "./ConfirmEnrollContent";
import { useState } from "react";

const TwoFactorModal = ({ onClose, isShown, setOpenModal, enrolled }) => {
  const [currentStep, setCurrentStep] = useState(enrolled ? 1 : 0);

  const handleNextStep = () => {
    if (currentStep === 0) {
      return setCurrentStep((prev) => prev + 1);
    }
  };

  const steps = [
    <ConfirmEnrollContent onClose={onClose} next={handleNextStep} />,
    <TwoFactorModalContent setOpenModal={setOpenModal} onClose={onClose} />,
  ];

  return (
    <Modal isShown={isShown} hide={onClose}>
      {steps[currentStep]}
    </Modal>
  );
};

export default TwoFactorModal;
