import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import {
  PAIconArrowLeftGreen,
  PAIconDownload,
  PAIconFilter,
  PAIconInvoiceIcon,
  PAIconOutlineSearch,
  PAIconVieweye,
} from "assets/images/svgs";
import { Link, useNavigate } from "react-router-dom";
import Loading from "shared/Loading";
import DashboardLayout from "layout/dashboardlayout";
import InvoicePayment from "./single/invoicePayment/invoiceModal";
import BulkInvoicePayment from "./bulk/bulkInvoicePayment";
import AcceptModal from "shared/AcceptModal";
import TransferInfo from "./single/transferInfo/transferInfo";
import TransferInfoPin from "./single/pin/pin";
import DateRangePickerModal from "shared/datePicker/DateRangePicker";
import * as XLSX from "xlsx";
import { AnimatePresence } from "framer-motion";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";

const ReimbursementDirectFunding = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllText, setSelectAllText] = useState("Select All");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isBulkInvoiceModalOpen, setIsBulkInvoiceModalOpen] = useState(false);
  const [isInvoicePaymentModalOpen, setIsInvoicePaymentModalOpen] =
    useState(false);
  const [isTransferInfoModalOpen, setIsTransferInfoModalOpen] = useState(false);
  const [isTransferInfoPinOpen, setIsTransferInfoPinOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("single");
  const [selectDownloadType, setSelectDownloadType] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: null,
    endDate: null,
  });

  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");

  const handleGoBack = () => {
    navigate(-1);
  };

  const formatAmount = (amount) => {
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const singleDetails = (invoiceId, id) => {
    let path = `/reimbursement/singlereimbursement/${invoiceId}`;
    navigate(path);
    // dispatch(setSelectedRowId(id));
  };

  const bulkDetails = (invoiceId, id) => {
    let path = `/reimbursement/bulkreimbursement/${invoiceId}`;
    navigate(path);
    // dispatch(setSelectedRowId(id));
  };

  const closeModal = () => setIsModalOpen(false);
  const applyFilter = (range) => {
    setSelectedRange(range);
    closeModal();
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      singleRequestData?.map((row) => ({
        "Program Ref No": row?.refNo,
        "Invoice Number": `INV-${row?.invoiceNumber}`,
        Supplier: row?.supplierCompanyName,
        "Due Date": row?.invoiceDueDate,
        "Payment Date": row?.newRequestedDate,
        Currency: row?.currency,
        "Invoice Amount": formatAmount(row?.invoiceAmount),
        "Discount Amount": formatAmount(row?.discountAmount),
        "Discount Rate": formatAmount(row?.discountRate),
        "Payment Amount": formatAmount(row?.paymentAmount),
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Direct Funding(Reimbursement)");
    XLSX.writeFile(wb, "Direct Funding(Reimbursement)Data.xlsx");
  };

  //   const handleSendReminder = () => {
  //     setIsAcceptModalOpen(true);
  //   };
  const handleSendReminder = () => {
    if (selectedTab === "single") {
      setIsInvoicePaymentModalOpen(true); // Open single invoice payment modal
    } else if (selectedTab === "bulk") {
      setIsBulkInvoiceModalOpen(true); // Open bulk invoice payment modal
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      let allIds = [];
      if (selectedTab === "single") {
        allIds = singleRequestData.map((row) => row.invoiceIds);
      } else {
        allIds = bulkRequestData.map((row) => row.invoiceIds);
      }
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    // console.log(selectedRows);
  }, [selectedRows]);

  const singleRequestData = [
    {
      invoiceIds: "1",
      reference: "REQ-2022003",
      invoiceNumber: "2",
      supplierCompanyName: "123 Company",
      invoiceDueDate: "2024-01-31",
      newRequestedDate: "2024-01-31",
      currency: "USD",
      invoiceAmount: "$2,000.00",
      discountAmount: "$100.00",
      discountRate: "5%",
      paymentAmount: "$2,000.00",
    },
    {
      invoiceIds: "2",
      reference: "REQ-2022004",
      invoiceNumber: "2",
      supplierCompanyName: "456 Corp.",
      invoiceDueDate: "2024-01-31",
      newRequestedDate: "2024-01-31",
      currency: "USD",
      invoiceAmount: "$5,000.00",
      discountAmount: "$250.00",
      discountRate: "5%",
      paymentAmount: "$2,000.00",
    },
  ];

  const bulkRequestData = [
    {
      invoiceIds: "1",
      requestRefNo: "REQ-2022003",
      buyer: "123 Company",
      createdDate: "2024-01-31",
      numOfInvoice: "2",
      currency: "USD",
      invoiceAmount: "$2,000.00",
      interestAmount: "$100.00",
      interestRate: "5%",
    },
    {
      invoiceIds: "2",
      requestRefNo: "REQ-2022004",
      buyer: "456 Corp.",
      createdDate: "2024-01-31",
      numOfInvoice: "2",
      currency: "USD",
      invoiceAmount: "$5,000.00",
      interestAmount: "$250.00",
      interestRate: "5%",
    },
  ];

  const singleColumns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.invoiceIds)}
        />
      ),
      width: "50px",
    },
    {
      name: "Request Ref No",
      selector: (row) => row.reference,
      sortable: true,
    },
    {
      name: "Invoice number",
      selector: (row) => row.invoiceNumber,
      sortable: true,
    },
    {
      name: "Supplier",
      selector: (row) => row.supplierCompanyName,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.invoiceDueDate,
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row.newRequestedDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.invoiceAmount)}</p>
      ),
    },
    {
      name: "Discount Amount",
      selector: (row) => row.interestAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.newPaymentAmount)}</p>
      ),
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
      sortable: true,
    },
    {
      name: "Payment Amount",
      selector: (row) => row.interestAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.newPaymentAmount)}</p>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconVieweye
              className="accent-gray300 w-4"
              onClick={() => singleDetails(row.invoiceIds[0])}
            />
          }
          {<PAIconDownload className="accent-gray300 w-4" />}
        </div>
      ),
    },
  ];

  const bulkColumns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.invoiceIds)}
        />
      ),
      width: "50px",
    },
    {
      name: "Request Ref No",
      selector: (row) => row.requestRefNo,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyer,
      sortable: true,
    },
    {
      name: "Created",
      selector: (row) => row.createdDate,
      sortable: true,
    },
    {
      name: "No. of invoices",
      selector: (row) => row.numOfInvoice,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.invoiceAmount)}</p>
      ),
    },
    {
      name: "Interest Amount",
      selector: (row) => row.interestAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.interestAmount)}</p>
      ),
    },
    {
      name: "Interest Rate",
      selector: (row) => row.interestRate,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconVieweye
              className="accent-gray300 w-4"
              onClick={() => bulkDetails(row.invoiceIds)}
            />
          }
          {<PAIconDownload className="accent-gray300 w-4" />}
        </div>
      ),
    },
  ];

  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.invoiceIds);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.invoiceIds);
    } else {
      newSelectedRows = [...selectedRows, row.invoiceIds];
    }

    setSelectedRows(newSelectedRows);
  };

  return (
    <>
      <DashboardLayout
        section="Reimbursement"
        subHeader="Track invoices due for reimbursement"
      >
        {/* <Headers /> */}
        <header className="rounded-md w-full bg-white mb-[17px] cursor-pointer items-center">
          <Link
            onClick={handleGoBack}
            className="flex items-center justifycenter gap-x-[3px] mb-[20px] cursor-pointer py-3 ml-2 my-auto"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>
        </header>

        <div className="my-2 grid md:grid-cols-2 items-center">
          <div className="gap-3 flex relative">
            {/* FILTER */}
            <div className="flex bg-white items-center rounded shadow-md">
              <button
                className="px-5  text-xs py-4 bg-white rounded"
                onClick={() => setIsModalOpen(!isModalOpen)}
              >
                Filters{" "}
                {selectedRange.startDate
                  ? selectedRange.startDate.toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : "Not selected"}{" "}
                -{" "}
                {selectedRange.endDate
                  ? selectedRange.endDate.toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : "Not selected"}
              </button>
            </div>
            <DateRangePickerModal
              isOpen={isModalOpen}
              onClose={closeModal}
              onApply={applyFilter}
            />

            {/* EXPORT */}
            <button
              className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5"
              onClick={() => {
                setSelectDownloadType(true);
              }}
            >
              Export {<PAIconDownload className="accent-gray300 w-4" />}
            </button>
          </div>

          {/* SEARCH */}
          <div className="flex md:col-span-1 justify-end">
            <div className="flex items-center justify-between gap-5">
              <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                <div className="items-center flex bg-white">
                  <PAIconOutlineSearch />
                  <input
                    className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                    type="text"
                    placeholder="Search for request"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        // handleSearch();
                      }
                    }}
                  />
                </div>
                <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                  <PAIconFilter className="bg-[#2FA06A]" />
                </div>
              </div>
              <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                <PAIconFilter /> Search
              </button>
            </div>
          </div>
        </div>

        {/* TABLE */}
        <div>
          {!singleRequestData?.length && !bulkRequestData?.length ? (
            <div>
              <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                <p className="text-black text-sm font-medium">
                  List of all approved payment extension requests you've
                  accepted
                </p>
              </div>
              <TableEmptyState dashboard />
            </div>
          ) : (
            <Card className="rounded-[10px] mt-[15px]">
              <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                <div>
                  <p className="text-black text-lg font-bold">
                    New payment extension request table
                  </p>
                  <span className="text-xs font-thin">
                    List of all new and updated payment extension requests for
                    you to review
                  </span>
                </div>
                <div className="flex items-center justify-center gap-3">
                  <div
                    className="border border-gray300 rounded-md p-2 text-white bg-[#2EA923] cursor-pointer"
                    onClick={handleSelectAll}
                  >
                    <p>{selectAllText}</p>
                  </div>
                  <button
                    className={`border border-gray300 rounded-md p-2 text-white ${
                      selectedRows.length === 0
                        ? "cursor-not-allowed bg-gray300"
                        : "cursor-pointer bg-secondaryColor"
                    }`}
                    disabled={selectedRows.length === 0}
                    onClick={handleSendReminder}
                  >
                    <p>Make Payment</p>
                  </button>
                </div>
              </div>

              <div className="pb-[24px]">
                <div className="flex px-[24px] gap-5 my-5">
                  <p
                    className={`cursor-pointer font-bold relative ${
                      selectedTab === "single"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => setSelectedTab("single")}
                  >
                    Single Request
                    {selectedTab === "single" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>

                  <p
                    className={`cursor-pointer font-bold relative ${
                      selectedTab === "bulk"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => setSelectedTab("bulk")}
                  >
                    Bulk Request
                    {selectedTab === "bulk" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>
                </div>
                {selectedTab === "bulk" ? (
                  <Table
                    columns={bulkColumns}
                    data={bulkRequestData}
                    onRowClicked={(row) => bulkDetails(row.invoiceIds, row?.id)}
                    // progressPending={isLoading}
                    progressComponent={<Loading />}
                    pointer
                    tableHeader
                    className="mt-5"
                  />
                ) : (
                  <Table
                    columns={singleColumns}
                    data={singleRequestData}
                    onRowClicked={(row) =>
                      singleDetails(row.invoiceIds, row?.id)
                    }
                    // progressPending={isLoading}
                    progressComponent={<Loading />}
                    pointer
                    tableHeader
                    className="mt-5"
                  />
                )}
                {/* <div className="flex justify-center gap-x-[8px] mt-[20px]">
                  {singleRequestData?.data?.length > 0 ||
                    (bulkRequestData?.data?.length > 0 && (
                      <Pagination
                        page={page}
                        startPage={startPage}
                        setEndPage={setEndPage}
                        endPage={endPage}
                        setStartPage={setStartPage}
                        setPage={setPage}
                        totalPages={paymentExtensionData?.meta?.totalElements}
                      />
                    ))}
                </div> */}
              </div>
            </Card>
          )}
        </div>
        {/* Accept Modal */}
        <AnimatePresence>
          {selectDownloadType && (
            <ViewDocumentModal
              icon={<PAIconInvoiceIcon className=" animate-pulse" />}
              onAction={exportToExcel}
              onClose={() => setSelectDownloadType(false)}
            >
              <span className=" text-center">
                Would you like to proceed with exporting this invoice list
              </span>
            </ViewDocumentModal>
          )}
        </AnimatePresence>
        {isInvoicePaymentModalOpen && (
          <InvoicePayment
            isOpen={isInvoicePaymentModalOpen}
            onClose={() => setIsInvoicePaymentModalOpen(false)}
          />
        )}
        {isBulkInvoiceModalOpen && (
          <BulkInvoicePayment
            isOpen={isBulkInvoiceModalOpen}
            onClose={() => setIsBulkInvoiceModalOpen(false)}
            onConfirm={() => setIsAcceptModalOpen(true)}
          />
        )}
        {isAcceptModalOpen && (
          <AcceptModal
            isOpen={isAcceptModalOpen}
            onClose={() => setIsAcceptModalOpen(false)}
            message={
              <>
                Would you like to proceed with Initiating <br />
                <span className="text-[#07593D]">Reimbursement</span> on all
                selected Invoices?
              </>
            }
            onConfirm={() => setIsTransferInfoModalOpen(true)}
          />
        )}
        {isTransferInfoModalOpen && (
          <TransferInfo
            isOpen={isTransferInfoModalOpen}
            onClose={() => setIsTransferInfoModalOpen(false)}
            onConfirm={() => setIsTransferInfoPinOpen(true)}
          />
        )}
        {isTransferInfoPinOpen && (
          <TransferInfoPin
            isOpen={isTransferInfoPinOpen}
            onClose={() => setIsTransferInfoPinOpen(false)}
          />
        )}
      </DashboardLayout>
    </>
  );
};

export default ReimbursementDirectFunding;
