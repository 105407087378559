import React from "react";
import Headers from "../headers";
import SingleBulkOption from "./option";

const EarlyPayments = ({ route }) => {
  return (
    <div>
      <Headers />
      <SingleBulkOption route={route} />
    </div>
  );
};

export default EarlyPayments;
