import {
  PAIconAddLine,
  PAIconEditLight,
  PAIconPayassyst1,
  PAIconPrimeSave,
  PAIconSendLight,
  PAIconTemplateEmail,
  PAIconView,
} from "assets/images/svgs";
import React, { useState } from "react";
import Modal from "react-modal";
import { PAIconCloseLine } from "assets/images/svgs";
import Preview from "./Preview";
import Edit from "./Edit";
import Test from "./Test";
import SaveAs from "./SaveAs";

const Default = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (divId) => {
    setIsModalOpen(true);

    if (divId === "a") {
      setActiveLink(1);
    } else if (divId === "b") {
      setActiveLink(0);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [activeLink, setActiveLink] = useState(0);

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  const renderModalContent = () => {
    switch (activeLink) {
      case 0:
        return <Preview />;
      case 1:
        return <Edit />;
      case 2:
        return <Test />;
      case 3:
        return <SaveAs />;

      default:
        return null;
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [/* selectedDropdownOption, */ setSelectedDropdownOption] =
    useState(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSaveOptionClick = (option) => {
    setSelectedDropdownOption(option);
    setIsDropdownOpen(false);
  };

  const renderDropdownOptions = () => (
    <div className="absolute mt-2 bg-white p-2 rounded-md shadow-md">
      <div
        onClick={() => handleSaveOptionClick("save")}
        className="cursor-pointer py-1 px-3 hover:bg-gray-200"
      >
        Save
      </div>
      <div
        // onClick={() => handleSaveOptionClick("save as")}
        onClick={() => handleLinkClick(3)}
      >
        Save as
      </div>
    </div>
  );

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 lg:grid-cols4 xl:grid-cols4 gap-4 mt-3">
      <div
        id="a"
        className="rounded-md border border-solid border-green-800 border-opacity-30 bg-white p-4 text-gray cursor-pointer"
        onClick={() => openModal("a")}
      >
        <div className="flex flex-col items-center justify-center mt-5">
          <div>
            <PAIconAddLine />
          </div>
          <div className="text-gray-600 text-center font-sans text-base font-medium my-5">
            Create & Customize your Email
          </div>
        </div>
      </div>
      <div
        id="b"
        className="flex flex-col max-wxl mb-4 rounded-md shadow-md cursor-pointer"
        onClick={() => openModal("b")}
      >
        <div className="bg-[#07593D] py-10 flex items-center justify-center rounded-md">
          <img src="/payAssyst-04.svg" alt="CardImage" />
        </div>
        <div className="bg-white p-4 flex justify-between items-center mb-[-10px] mt-[-20px]">
          <div className="flex items-center">
            <PAIconTemplateEmail className="w6 h6 mr-2" />
            <span className="text-black font-sans font-bold text-xs">
            PayEdge - Default template
            </span>
          </div>
        </div>
        <p className="text-[#959595] font-sans text-xs font-normal px-4 mb-5 pb10">
          Last Updated: 2 days
        </p>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className={`fixed top-0 right-0 bg-[#EEE] bottom-0 w-3/4 bg-gray-200 p-5 transform transition-transform duration-300 ease-in-out ${
          isModalOpen ? "translate-x-30" : "translate-x-0"
        }`}
      >
        <div className="bg-white mx-[-20px]">
          <div className="bg-white p-3 flex flex-col sm:flex-row justify-between items-center w-full mt-[-25px]">
            <PAIconPayassyst1 />

            {/* Navigation Links */}
            <div className="flex flex-col gap2 lg:gap-12 sm:flex-row space-y-4 sm:space-y-0 sm:space-x4 text-[#07593D] font-sans text-base">
              <div
                onClick={() => handleLinkClick(0)}
                className={`cursor-pointer flex items-center gap-1 ${
                  activeLink === 0 ? "border-b-2 border-[#07593D]" : ""
                }`}
              >
               <PAIconView /> Preview
              </div>
              <div
                onClick={() => handleLinkClick(1)}
                className={`cursor-pointer flex items-center gap-1 ${
                  activeLink === 1 ? "border-b-2 border-[#07593D]" : ""
                }`}
              >
                <PAIconEditLight /> Edit
              </div>
              <div
                onClick={() => handleLinkClick(2)}
                className={`cursor-pointer flex items-center gap-1 ${
                  activeLink === 2 ? "border-b-2 border-[#07593D]" : ""
                }`}
              >
                <PAIconSendLight /> Test
              </div>
              <div
                onClick={toggleDropdown}
                className={`cursor-pointer flex items-center gap-1 relative ${
                  activeLink === 3 ? "border-b-2 border-[#07593D]" : ""
                }`}
              >
                <PAIconPrimeSave /> Save
                {isDropdownOpen && renderDropdownOptions()}
              </div>
            </div>
            <div
              className="pr-5 flex items-center mt-4 sm:mt-0"
              onClick={closeModal}
            >
              <button className="rounded-md border border-solid border-orange-500 p-1 px-2 text-md flex items-center">
                <PAIconCloseLine />
                <span className="text-[#07593D] font-sans text-base font-normal leading-normal ml-2">Close</span>
              </button>
            </div>
          </div>
        </div>
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default Default;
