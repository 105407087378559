import { PAIconAddRole, PAIconArrowLeftGreen } from "assets/images/svgs";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";
import { AnimatePresence, motion } from "framer-motion";
import CreateNewRule from "./modals/CreateNewRule";
import CreateRuleFinalise from "./modals/CreateRuleFinalise";
import RulesConfigSearchInput from "./RulesConfigSearchInput";
// import toast from "react-hot-toast";

function RulesConfigHeader({
  setRuleSavedModal,
  handleSearch,
  searchBy,
  setSearchBy,
  searchQuery,
  setSearchQuery,
}) {
  const { ruleType } = useParams();
  const [createNewRuleModal, setCreateNewRuleModal] = useState(false);
  const [createNewRuleModalFinal, setCreateNewRuleModalFinal] = useState(false);

  // Create New Rule

  return (
    <div>
      {/* Create New Rule Modal */}
      <AnimatePresence>
        {createNewRuleModal && (
          <CreateNewRule
            closeModal={() => setCreateNewRuleModal(false)}
            setCreateNewRuleModalFinal={setCreateNewRuleModalFinal}
          />
        )}
      </AnimatePresence>

      {/* Rule Modal Finalise  */}
      <AnimatePresence>
        {createNewRuleModalFinal && (
          <CreateRuleFinalise
            closeModal={() => setCreateNewRuleModalFinal(false)}
            setCreateNewRuleModal={setCreateNewRuleModal}
            setRuleSavedModal={setRuleSavedModal}
          />
        )}
      </AnimatePresence>

      <header className=' flex flex-col gap-6 w-full'>
        <Link to='/settings/rules&config' className='flex gap-1.5 text-sm '>
          <PAIconArrowLeftGreen />
          <span className='flex items-center text-primaryColor'>Go Back</span>
        </Link>

        <div>
          <h1 className='text-xl'>
            {formatCapitaliseString(ruleType)} Configuration
          </h1>
        </div>

        <div className='flex md:flex-row justify-between flex-row-reverse'>
          <motion.div whileTap={{ scale: 0.95 }} className='flex items-center'>
            <button
              onClick={() => setCreateNewRuleModal(true)}
              className='px-6 gap-1.5 text-sm border-[0.5px] border-[#ccc] rounded-[5px] text-white py-3 flex justify-center items-center bg-brilliantYellow transform transition duration-500'
            >
              <PAIconAddRole />
              <span className='hidden md:inline-block '>Create new rule</span>
            </button>
          </motion.div>

          <div>
            <div>
              <RulesConfigSearchInput
                onSearch={handleSearch}
                searchBy={searchBy}
                setSearchBy={setSearchBy}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
              />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default RulesConfigHeader;
