import { PAIconEditGreen, PAIconSmallClose } from "assets/images/svgs";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRole } from "appstate/roleManager/roleManagerSlice";
import Spinner from "shared/Spinner/Spinner";

function ViewMemberModal({
  toggleViewModalOff,
  editActive,
  setEditActive,
  data,
}) {
  const { allRolesData, memberId, updateRoleLoading, allMembersData } =
    useSelector((state) => state?.roleManager);
  const [roleId, setRoleId] = useState(null);
  const [assignedRole, setAssignedRole] = useState("");

  const handleSelectChange = (event) => {
    setRoleId(event.target.options[event.target.selectedIndex].id);
    setAssignedRole(event.target.value);
  };

  const memberDetails = allMembersData?.data?.data?.find(
    (curr) => curr.id === memberId
  );

  const dispatch = useDispatch();

  const handleEdit = () => {
    setEditActive(true);
  };
  const handleSave = () => {
    if (assignedRole === "") {
      toast.error("Select a valid option");
    } else {
      dispatch(updateRole({ memberId, roleId })).then((data) => {
        if (data?.payload?.success) {
          toast.success("New role saved successfully");
        }
      });
      setTimeout(() => {
        // Perform additional action here after 3 seconds
        setEditActive(false);
      }, 1200);
    }
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          delay: 0.3,
        },
      }}
      onClick={() => toggleViewModalOff()}
      className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[9999] flex justify-center items-center'
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          scale: 0,
          transition: {
            delay: 0.3,
          },
        }}
        className={`bg-white w-[35%] ${
          editActive ? "h-[85%]" : "h-[70%]"
        } overflow-auto rounded-lg z-[9999]`}
      >
        <motion.div>
          <motion.div
            exit={{
              x: 20,
              opacity: 0,
              transition: {
                duration: 0.3,
              },
            }}
            className='z-[9999]  h-full flex flex-col justify-start w-full gap-8 pt-[3%] px-[4%] '
          >
            <div className='w-full sticky bg-white top-0 z-[999999] flex px-4 py-4 border-b-[3px] border-[#F6F5FA] justify-between'>
              <span className=' font-bold '>
                <em>About Member</em>
              </span>
              <span
                onClick={() => toggleViewModalOff()}
                className='cursor-pointer gap-2 flex items-center justify-center '
              >
                <PAIconSmallClose />
                <span className=' text-[#565656] text-[14px]'>Close</span>
              </span>
            </div>

            <div className='h-full overflow-auto'>
              <div className='w-full h-4/5 rounded overflow-auto '>
                <div className='overflow-auto flex flex-col gap-8'>
                  <div className=' w-full flex flex-col gap-4 px-4'>
                    <div className=' font-bold'>Information</div>

                    <div className=' flex flex-col gap-4'>
                      <div className=' flex flex-col gap-2 w-full'>
                        <span className=' text-[14px] text-[#7a7a7a]'>
                          Name
                        </span>
                        <span className=' text-[14px]'>
                          {memberDetails?.fullName
                            ? memberDetails?.fullName
                            : "N/A"}
                        </span>
                      </div>

                      <div className=' flex flex-col gap-2 w-full'>
                        <span className=' text-[14px] text-[#7a7a7a]'>
                          Email
                        </span>
                        <span className=' text-[14px]'>
                          {memberDetails?.email}
                        </span>
                      </div>

                      <div className=' flex flex-col gap-2 w-full'>
                        <span className=' text-[14px] text-[#7a7a7a]'>
                          Contact Number
                        </span>
                        <span className=' text-[14px]'>
                          {memberDetails?.phoneNumber
                            ? memberDetails?.phoneNumber
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className=' w-full flex flex-col gap-4 px-4'>
                    <div className=' font-bold'>Role</div>

                    {editActive ? (
                      <div className='flex flex-col gap-2 pb-10'>
                        <span className=' text-[#7A7A7A text-[14px]'>
                          Current Role
                        </span>

                        <select
                          className='overflow-auto w-full text-[#7a7a7a] text-sm px-4 py-3 rounded outline-[#7a7a7a] border border-[#7a7a7a] '
                          name='assignedRole'
                          id='assignedRole'
                          onChange={handleSelectChange}
                          value={assignedRole}
                        >
                          <option className='py-3' value='#'>
                            Select User Role
                          </option>
                          {allRolesData?.data?.data.map((role) => (
                            <option
                              className=' py-4'
                              key={role?.id}
                              id={role?.id}
                              value={role?.roleName}
                            >
                              {role?.roleName}
                            </option>
                          ))}
                        </select>

                        <div className=' flex pt-6  justify-end'>
                          <button
                            disabled={updateRoleLoading}
                            onClick={() => {
                              handleSave();
                            }}
                            className='flex items-center justify-end text-white rounded-[5px] bg-[#07593D] px-4 py-2'
                          >
                            {updateRoleLoading ? (
                              <Spinner />
                            ) : (
                              <span className=' text-xs'> Save</span>
                            )}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className=' flex items-center justify-between'>
                        <div className=' flex flex-col gap-4'>
                          <div className=' flex flex-col gap-2 w-full'>
                            <span className=' text-[14px] text-[#7a7a7a]'>
                              Name
                            </span>
                            <span className=' text-[14px]'>
                              {memberDetails?.fullName
                                ? memberDetails?.fullName
                                : "N/A"}
                            </span>
                          </div>
                        </div>

                        <div>
                          {!editActive && (
                            <button
                              onClick={() => {
                                handleEdit();
                              }}
                              className='flex items-center justify-center gap-1 border rounded-[5px] border-[#07593D] px-4 py-2'
                            >
                              <span>
                                <PAIconEditGreen />
                              </span>
                              <span className=' text-xs'>Edit</span>
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default ViewMemberModal;
