import { PAIconCancel } from "assets/images/svgs";
import React, { useState } from "react";

import Modal from "layout/modal/Modal";

function ReasonRequiredModal({ onClose, isShown, handleSubmit }) {
  const buttonData = [
    { id: "1", title: "Price misalignment" },
    { id: "2", title: "Funding constraints" },
    { id: "3", title: "Preferred terms not met" },
    { id: "4", title: "Seeking better offers" },
    { id: "5", title: "Invoice already funded" },
  ];
  const [active, setActive] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const handleClick = () => {
    setActive(true);
    setDisabled(false);
  };
  const Submit = (e) => {
    e.preventDefault();
    handleSubmit();
  };
  return (
    <>
      <Modal
        isShown={isShown}
        hide={onClose}
        accountModal
        className={"lg:w-[630px]"}
      >
        <div className=" py-[30px] ">
          <div className="px-[27px]">
            <header className="flex justify-between border-b-4 border-whisper200 pb-[16px]">
              <p class="text-[#222222] text-base font-bold ">Reason Required</p>
              <div
                onClick={onClose}
                className="flex justify-between items-center gap-x-[10px] cursor-pointer"
              >
                <PAIconCancel className="w-[8px]" />

                <p class="text-gray800 text-sm font-medium f">Close</p>
              </div>
            </header>
          </div>
          <div className=" flex flex-col flex-1 w-full ">
            <div class=" text-[#949494] text-base font-normal font-['SF Pro Display'] py-10  border-b-[1px] border-[#8B8B8B] px-[27px]">
              Please provide a detailed explanation for rejecting the bid. Your
              insights assist in improving our platform and understanding user
              preferences. Thank you for your valuable feedback
            </div>
          </div>

          <div className="px-[27px]  w-full py-4 ">
            <div class="text-[#07593D] text-sm font-medium font-['SF Pro Display']">
              Input your reply or choose from our predefined responses
            </div>

            <div class=" min-h-[87px] rounded-[5px] border-[1px] border-[#07593D] mt-4 flex flex-wrap"></div>
            {/* Button Section */}
            <div className=" flex flex-row gap-4 flex-wrap w-full mt-8">
              {buttonData.map((item) => {
                return (
                  <div
                    class="min-w-[147px] h-[39px] p-2.5 rounded-[5px] border border-[#949494] justify-center items-center gap-2.5 inline-flex"
                    key={item.id}
                    onClick={handleClick}
                  >
                    <div class="text-green-600 text-base font-normal font-['SF Pro Display']">
                      {item.title}
                    </div>
                  </div>
                );
              })}
            </div>

            {/*  */}

            <div className=" mt-10">
              <button
                class={`w-full h-[49px] px-2.5 py-[15px] ${
                  active ? "bg-[#07593D]" : "bg-[#D2D2D2]"
                } rounded-[5px] justify-center items-center gap-2.5 inline-flex`}
                disabled={disabled}
                onClick={Submit}
              >
                <div className="text-white text-base font-normal font-['SF Pro Display']">
                  Submit
                </div>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ReasonRequiredModal;
