import PAIconCircle from 'assets/images/svgs/circle.png';
import React from 'react';
import Bar from 'shared/Bar';
import Button from 'shared/Button';

const SectionOne = () => {
  return (
    <div className="bg-flashWhite pt-[77px] px-[24px] lg:pb-[30px] lg:grid lg:grid-cols-2 lg:pl-[42px] ">
      <div>
        <Bar />
        <h1 className="font-bold text-2xl mb-[30px] mt-[35px] text-textColor">
          Transact more efficiently with your entire supply chain
        </h1>
        <p style={{color: "#7A7A7A"}} className="font-normal text-base text-textColor">
          A first-of-its-kind, supplier-friendly solution combining open account
          automation (AP and AR automation) with financing, B2B payments and ESG
          visibility.
        </p>
        <p style={{color: "#7A7A7A"}} className="font-normal text-base text-textColor mt-[20px]">
          Built for fast moving businesses who need to collaborate with
          thousands of suppliers to achieve their efficiency, inclusivity and
          working capital goals.
        </p>
        <p style={{color: "#7A7A7A"}} className="font-normal text-base text-textColor mt-[20px] mb-[30px]">
          360° visibility for complex supply chain transactions Digitised,
          automated processes that build confidence Early payment options for
          every supplier – even SMEs Reducing costs, risks, and optimising
          working capital Robust data privacy, security and synchronisation
          Driving forward your ESG and CSR strategies
        </p>
        <div className="flex justify-center w-full flex-col items-center gap-y-[30px] md:flex-row md:gap-x-[30px] md:justify-start">
          <Button className="w-[213px]"> Explore more </Button>
          <Button className="w-[213px]" pill>
            Get in touch
          </Button>
        </div>
      </div>
      <img src={PAIconCircle} alt='#' className="w-full lg:relative lg:bottom-[30px] xl:bottom-[60px]" />
    </div>
  );
};

export default SectionOne;
