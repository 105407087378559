import {
  PAIconInventoryArrowLeft,
  PAIconUnlockPad,
  PAIconViewMini,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";

function PfpTableDetails() {
  const navigate = useNavigate();
  const onRowClicked = () => {
    let path = "1";
    navigate(path);
  };

  function handleGoback() {
    let path = -1;
    navigate(path);
  }

  const columns = [
    {
      name: "Inv Reference",
      selector: (row) => row.Name,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Currency",
      selector: (row) => row.FinancingType,
      sortable: true,
      width: "9rem",
    },
    {
      name: "Due Date",
      selector: (row) => row.startDate,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Inv Amount",
      selector: (row) => row.noInvoices,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Funder Share",
      selector: (row) => row.totalAmounts,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Fundable Amt",
      selector: (row) => row.totalAmounts,
      sortable: true,
      width: "8rem",
    },
    {
      name: "Status",
      selector: (row) => row.totalAmounts,
      sortable: true,
      width: "6rem",
    },
    {
      name: "ACTION",
      selector: (row) => row?.pfpId,
      width: "10rem",
      cell: (row) => (
        <div className="flex text-[#1D0220] gap-4 items-center">
          <span
            onClick={() => {
              navigate("1");
            }}
            className="flex gap-1 items-center"
          >
            <PAIconViewMini />
            View
          </span>

          <span className="flex gap-1 items-center">
            <PAIconUnlockPad />
            Unlock
          </span>
        </div>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      Name: "Eleanor Pena",
      FinancingType: "Dynamic Discounting",
      startDate: "11-02-2023",
      noInvoices: "20",
      totalAmounts: " 100000",
    },
    {
      id: 2,
      Name: "Eleanor Pena",
      FinancingType: "Dynamic Discounting",
      startDate: "11-02-2023",
      noInvoices: "10",
      totalAmounts: " 500",
    },
    {
      id: 3,
      Name: "Eleanor Pena",
      FinancingType: "Dynamic Discounting",
      startDate: "11-02-2023",
      noInvoices: "11",
      totalAmounts: " 3000",
    },
    {
      id: 4,
      Name: "Eleanor Pena",
      FinancingType: "Dynamic Discounting",
      startDate: "11-02-2023",
      noInvoices: "13",
      totalAmounts: " 1000",
    },

    {
      id: 5,
      Name: "Eleanor Pena",
      FinancingType: "Dynamic Discounting",
      startDate: "11-02-2023",
      noInvoices: "14",
      totalAmounts: " 40000",
    },
  ];
  return (
    <DashboardLayout>
      <div className="min-h-full">
        <div
          onClick={handleGoback}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
        <div className="mt-[17px] min-h-full">
          <Card className="shadow-md min-h-[80%] p-[20px] rounded-[10px]">
            <div>
              <TableHeader
                total="20 Invoices"
                btnName="Fund Invoices"
                details={
                  <span className="text-[#5F4D61]">
                    List of invoices in{" "}
                    <span className="font-bold">Payable flex</span> Payable
                    finance program
                  </span>
                }
                // setOpen={setOpen}
                payableFinance
              />
            </div>
            <Table
              columns={columns}
              data={data}
              selectableRows
              onRowClicked={onRowClicked}
              //   progressPending={isLoading}
              //   progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default PfpTableDetails;
