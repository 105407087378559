const RadioButton = ({
  icon,
  name,
  select,
  setSelect,
  route,
  active,
  setRole,
  idx,
  onClick,
  title,
  funder,
}) => {
  const handleChange = (e) => {
    setSelect(e.target.value);
    setRole(e.target.name);
  };

  return (
    <label
      onClick={onClick}
      className={`border cursor-pointer border-brightGray200 w-full md:w-[424px] flex justify-between px-[20px] mb-[10px] rounded-[5px] items-center h-[45px]`}
    >
      <div className="flex gap-x-[10px] items-center">
        {icon}
        <p className={`font-medium text-sm ${active === idx ? "text-primaryColor" : "text-textColor"}`}>
          {funder ? title : name}
        </p>
      </div>
      <div className="flex items-center justify-center">
        <input
          type="radio"
          name={name}
          value={route}
          checked={select === route}
          onChange={handleChange}
          className="hidden"
        />
        <div
          style={{border: '1px solid #D1D6DD'}}
          className={`w-5 h-5 rounded-full  border-[#D1D6DD] flex items-center justify-center ${
            select === route ? "bg-[#F08000]" : "bg-white"
          } focus:outline-none`}
        >
          {select === route && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </div>
      </div>
    </label>
  );
};


export default RadioButton;
