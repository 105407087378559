import React from "react";
import { motion } from "framer-motion";
import { PAIconArrowDownGreen, PAIconSabModel } from "assets/images/svgs";
import CardLoading from "features/openMarket/funder/components/cards/CardLoading";
import TransitionOpacity from "shared/animation/TransitionOpacity";

function ViewMoreBidders({ setIsOpen, allbidsData, isLoading }) {
  return (
    <div>
      <div
        style={{
          background: "rgba(193, 193, 193, 0.6)",
          boxShadow: "0px 2px 4px 0px rgba(138, 211, 186, 0.38)",
        }}
        className=' h-[1px]'
      ></div>
      <div className=' px-6 pt-6 flex flex-col gap-4'>
        <h2 className=''>More bids on this invoice </h2>

        <div className='flex flex-col gap-4'>
          <h2 className='text-sm px-2.5'>
            Total number of bids: {allbidsData?.numberOfBids}
          </h2>

          <div className='p-2.5 gap-2.5'>
            {isLoading ? (
              <ul>
                <CardLoading />
                <CardLoading />
                <CardLoading />
              </ul>
            ) : (
              <TransitionOpacity>
                <li
                  key={allbidsData?.bidResponses[0]?.offerId}
                  className='flex cursor-pointer justify-between shadow-sm  items-center text-xs py-1.5 mb-2'
                >
                  <span className='flex items-center gap-2'>
                    <PAIconSabModel />
                    <p>{allbidsData?.bidResponses[0]?.buyerCompanyName}</p>
                  </span>

                  <span>
                    {allbidsData?.bidResponses[0]?.currency || "₦"}
                    {allbidsData?.bidResponses[0]?.invoiceAmount}
                  </span>

                  <span>{allbidsData?.bidResponses[0]?.discountRate}%</span>
                  <span>{allbidsData?.bidResponses[0]?.lastModifiedDate}</span>
                </li>
                <li
                  key={allbidsData?.bidResponses[1]?.offerId}
                  className='flex cursor-pointer justify-between shadow-sm  items-center text-xs py-1.5 mb-2'
                >
                  <span className='flex items-center gap-2'>
                    <PAIconSabModel />
                    <p>{allbidsData?.bidResponses[1]?.buyerCompanyName}</p>
                  </span>

                  <span>
                    {allbidsData?.bidResponses[1]?.currency || "₦"}
                    {allbidsData?.bidResponses[1]?.invoiceAmount}
                  </span>

                  <span>{allbidsData?.bidResponses[1]?.discountRate}%</span>
                  <span>{allbidsData?.bidResponses[1]?.lastModifiedDate}</span>
                </li>
                <li
                  key={allbidsData?.bidResponses[2]?.offerId}
                  className='flex cursor-pointer justify-between shadow-sm  items-center text-xs py-1.5 mb-2'
                >
                  <span className='flex items-center gap-2'>
                    <PAIconSabModel />
                    <p>{allbidsData?.bidResponses[2]?.buyerCompanyName}</p>
                  </span>

                  <span>
                    {allbidsData?.bidResponses[2]?.currency || "₦"}
                    {allbidsData?.bidResponses[2]?.invoiceAmount}
                  </span>

                  <span>{allbidsData?.bidResponses[2]?.discountRate}%</span>
                  <span>{allbidsData?.bidResponses[2]?.lastModifiedDate}</span>
                </li>
              </TransitionOpacity>
            )}

            <div className='flex justify-center'>
              <motion.button
                whileTap={{ scale: 0.95 }}
                className='bg-white hover:bg-whisper200 flex gap-2.5 text-primaryColor text-xs border border-primaryColor rounded-[10px] py-3  px-8 mt-2'
                // onClick={handleViewMore}
                onClick={() => setIsOpen(true)}
              >
                View all bids on this invoice
                <PAIconArrowDownGreen />
              </motion.button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewMoreBidders;
