import styled, { keyframes } from "styled-components";
import React from "react";
import payEdgeIcon from "assets/images/PayEdgeLogoC.png";

const SplashScreen = () => {
  return (
    <div className="h-full w-full absolute top-0 left-0 bg-white  ">
      <div className="flex justify-center items-center h-full w-full">
        <Animated className="animate-fade  font-extrabold text-5xl text-[#07593D] flex items-center justify-center">
          <img src={payEdgeIcon} alt="" className="relative left-[7px] w-[30%]" />
        </Animated>
      </div>
    </div>
  );
};

export default SplashScreen;

const fade = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const Animated = styled.h1`
  background: linear-gradient(to bottom, white 0%, transparent 100%);
  animation: ${fade} 2s infinite;
`;
