import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import {
  PAIconArrowLeftGreen,
  PAIconDownload,
  PAIconDropdown,
  PAIconFilter,
  PAIconOutlineSearch,
  PAIconOption,
} from "assets/images/svgs";
import { Link, useNavigate } from "react-router-dom";
import Loading from "shared/Loading";
import DashboardLayout from "layout/dashboardlayout";

import OpenMarketReimbursementModal from "../../components/modals/OpenMarketReimbursementModal";

const OpenMarketSelectableDetail = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllText, setSelectAllText] = useState("Select All");
  const [paymentText, setPaymentText] = useState("Make Payment");

  const [isDisburseOpen, setIsDisburseOpen] = useState(false);

  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");

  const handleGoBack = () => {
    navigate(-1);
  };

  const openIsDisburseModal = () => {
    setIsDisburseOpen(true);
  };
  const details = (invoiceId, id) => {
    let path = `/reinbursement/detail/openmarketdirectfundingdetai/${invoiceId}`;
    navigate(path);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      let allIds = [];
      allIds = dummyData.map((row) => row.bidAmount);
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
  };

  const dummyData = [
    {
      bidAmount: "1",
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: "2",
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: "3",
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: "4",
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: "5",
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: "6",
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
  ];

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.bidAmount)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref. No.
        </span>
      ),
      selector: (row) => row.RequestRefNo,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.InvoiceNumber,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row.Supplier,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.DueDate,
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row.Requestdate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.Currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.InvoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.InvoiceAmount}</p>,
    },

    {
      name: "Discount Amount",
      selector: (row) => row.DiscountAmount,
      sortable: true,
      cell: (row) => <p className="text-[#F08000]">{row.DiscountAmount}</p>,
    },
    {
      name: "Discount Rate",
      selector: (row) => row.DiscountRate,
      sortable: true,
      cell: (row) => <p className="text-success">{row.DiscountRate}</p>,
    },
    {
      name: "Payment Amount",
      selector: (row) => row.PaymentAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.PaymentAmount}</p>,
    },
    {
      cell: (row) => (
        <div>
          <PAIconOption title="Options" onClick={() => {}} />
        </div>
      ),
      width: "5rem",
    },
  ];

  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.bidAmount);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.bidAmount);
    } else {
      newSelectedRows = [...selectedRows, row.bidAmount];
    }

    setSelectedRows(newSelectedRows);
  };
  useEffect(() => {
    if (selectedRows.length > 0) {
      setPaymentText("Initiate Disbursement");
    }

    if (selectedRows.length < 1) {
      setPaymentText("Make Payment");
    }
  }, [selectedRows.length]);

  return (
    <>
      <DashboardLayout
        section="Reimbursement"
        subHeader="Track invoices due for reimbursement"
      >
        {/* <Headers /> */}
        <header className="rounded-md w-full bg-white mb-[17px] cursor-pointer items-center">
          <Link
            onClick={handleGoBack}
            className="flex items-center justifycenter gap-x-[3px] mb-[20px] cursor-pointer py-3 ml-2 my-auto"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>
        </header>

        <div className="my-2 grid md:grid-cols-2 items-center">
          <div className="gap-3 flex relative">
            {/* FILTER */}
            <div>
              <div
                className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
                onClick={handleDropdownToggle}
              >
                Filter{" "}
                {selectedFilters.length > 0 && (
                  <span className="bg-gray rounded-lg p-1 px-2">
                    {selectedFilters.length}
                  </span>
                )}{" "}
                Selected <PAIconDropdown />
              </div>
              {isDropdownOpen && (
                <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                  <label className="block">
                    <input
                      type="checkbox"
                      value="date"
                      checked={selectedFilters.includes("date")}
                      onChange={handleFilterChange}
                    />
                    Date
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="name"
                      checked={selectedFilters.includes("name")}
                      onChange={handleFilterChange}
                    />
                    Name
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="currency"
                      checked={selectedFilters.includes("currency")}
                      onChange={handleFilterChange}
                    />
                    Currency
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="amount"
                      checked={selectedFilters.includes("amount")}
                      onChange={handleFilterChange}
                    />
                    Amount
                  </label>
                </div>
              )}
            </div>

            {/* EXPORT */}
            <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
              Export {<PAIconDownload className="accent-gray300 w-4" />}
            </button>
          </div>

          {/* SEARCH */}
          <div className="flex md:col-span-1 justify-end">
            <div className="flex items-center justify-between gap-5">
              <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                <div className="items-center flex bg-white">
                  <PAIconOutlineSearch />
                  <input
                    className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                    type="text"
                    placeholder="Search for due invoices awaiting disbursement"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                      }
                    }}
                  />
                </div>
                <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                  <PAIconFilter className="bg-[#2FA06A]" />
                </div>
              </div>
              <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                <PAIconFilter /> Search
              </button>
            </div>
          </div>
        </div>

        {/* TABLE */}
        <div>
          {!dummyData?.length ? (
            <div>
              <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                <p className="text-black text-sm font-medium">
                  List of all Open Market invoices
                </p>
              </div>
              <TableEmptyState dashboard />
            </div>
          ) : (
            <Card className="rounded-[10px] mt-[15px]">
              <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                <div>
                  <p className="text-black text-lg font-bold">Open Market</p>
                  <span className="text-xs font-thin">
                    List of all Open Market invoices
                  </span>
                </div>
                <div className="flex items-center justify-center gap-3">
                  <div
                    className="border border-gray300 rounded-md p-2 text-white bg-[#2EA923] cursor-pointer"
                    onClick={handleSelectAll}
                  >
                    <p>{selectAllText}</p>
                  </div>
                  <button
                    className={`border border-gray300 rounded-md p-2 text-white ${
                      selectedRows.length === 0
                        ? "cursor-not-allowed bg-gray300"
                        : "cursor-pointer bg-secondaryColor"
                    }`}
                    disabled={selectedRows.length === 0}
                    onClick={openIsDisburseModal}
                  >
                    <p>{paymentText}</p>
                  </button>
                </div>
              </div>

              <div className="pb-[24px]">
                <Table
                  columns={columns}
                  data={dummyData}
                  onRowClicked={(row) => details(row.bidAmount, row?.id)}
                  // progressPending={isLoading}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              </div>
            </Card>
          )}
        </div>

        <OpenMarketReimbursementModal
          isOpen={isDisburseOpen}
          onClose={() => setIsDisburseOpen(false)}
        />
      </DashboardLayout>
    </>
  );
};

export default OpenMarketSelectableDetail;
