import { PAIconOutlineSearch } from "assets/images/svgs";

const RulesConfigSearchInput = ({
  onSearch,
  searchBy,
  setSearchBy,
  searchQuery,
  setSearchQuery,
}) => {
  // const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    onSearch(searchQuery);
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    // setSearchTerm(value);
    onSearch(value);
  };

  return (
    <div className='flex'>
      <div className='border border-transparent border-gray shadow-sm my-4 rounded-lg flex items-center justify-between px-2 lg:w-[300px]'>
        <div className='items-center flex'>
          <PAIconOutlineSearch />
          <input
            className='bg-transparent p-2 focus:outline-none text-sm'
            type='text'
            placeholder='Search for rules'
            value={searchQuery}
            onChange={handleChange}
          />
        </div>
        <div
          className='lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer'
          onClick={handleSearch}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-[17px] h-[17px] text-white'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z'
            />
          </svg>
        </div>
      </div>
      <button
        className='hidden lg:flex bg-[#2FA06A] rounded-md my-4 px-6 mx-3  items-center gap-2 text-white'
        onClick={handleSearch}
      >
        Search
      </button>

      <select
        className='w-[30%] outline-none cursor-pointer border mr-2 md:mr-0  border-[#ccc] shadow-sm my-4 rounded-lg flex items-center justify-between px-2'
        value={searchBy}
        onChange={(e) => {
          setSearchQuery("");
          setSearchBy(e.target.value);
        }}
      >
        <option value='name'>Name</option>
        <option value='priority'>Priority</option>
      </select>
    </div>
  );
};

export default RulesConfigSearchInput;
