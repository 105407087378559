import { PAIconCancel } from "assets/images/svgs";
import Button from "shared/Button";
import Spinner from "shared/Spinner/Spinner";

const FeedbackModal = ({
  isOpen,
  onClose,
  loading,
  response,
  selectedResponse,
  handleSubmit,
  handleSelect,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white w-[612px] p-5 rounded-md shadow-md flex flex-col  px-10">
        <header className="flex justify-between border-b border-[#F6F5FA] pb-[16px]">
          <h1 className="text-black text-base font-bold">Reason Required</h1>
          <div
            onClick={onClose}
            className="flex gap-x-[8px] items-center cursor-pointer"
          >
            <PAIconCancel className="w-[10px]" />
            <p className="text-sm">Cancel</p>
          </div>
        </header>
        <p className="text-base text-[#959595] mt-[24px] pb-[32px] border-b border-[#F6F5FA]">
          Please provide a detailed explanation for rejecting the request. Your
          insights assist in improving our platform and understanding user
          preferences. Thank you for your valuable feedback
        </p>
        <p className="text-primaryColor text-sm font-[500] mt-[24px] mb-[16px]">
          Input your reply or choose from our predefined responses
        </p>
        <div>
          <div className="border border-primaryColor min-h-[4.1875rem] rounded-[5px] w-full outline-none p-[10px] flex flex-wrap gap-x-[16px] gap-y-[16px]">
            {selectedResponse.map((res) => (
              <div
                key={res.id}
                className="p-[10px] rounded-[5px] border border-secondaryColor cursor-pointer flex items-center gap-x-[0.62rem]"
              >
                <p className="text-base text-[#2FA06A] whitespace-nowrap">
                  {res.name}
                </p>
                <PAIconCancel
                  onClick={() => handleSelect(res)}
                  className="w-[1rem] pill-[#000]"
                />
              </div>
            ))}
          </div>

          <div className="flex flex-wrap gap-x-[16px] gap-y-[16px] mt-[30px]">
            {response.map((res) => (
              <div
                key={res.id}
                className="p-[10px] rounded-[5px] border border-[#959595] cursor-pointer"
                onClick={() => handleSelect(res)}
              >
                <p className="text-base text-[#2FA06A] whitespace-nowrap">
                  {res.name}
                </p>
              </div>
            ))}
          </div>
          <Button
            neutral
            disabled={!selectedResponse.length || loading}
            onClick={handleSubmit}
            className="bg-primaryColor text-white w-full h-[3.0625rem] rounded-[5px] mt-[32px]"
          >
            {loading ? <Spinner /> : "Submit"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
