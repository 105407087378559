import DashboardLayout from "layout/dashboardlayout";
import ClosedMarketTabs from "../closedMarketInvoice/closeMarketTabs";
import { useSelector } from "react-redux";
import TableEmptyState from "shared/TableEmptyState";
import Overview from "features/collections/funder/overview";
import InvoiceDashboardTable from "features/invoice/InvoiceDashboard/Tables/InvoiceDashboardTable";
import RecentTables from "features/invoice/InvoiceDashboard/Tables/RecentTables";
import FunderInvoice from "features/invoice/funder";
//some development to be done on this section, still in progress

const ClosedMarketDashboardContents = () => {
  const { dashboardData } = useSelector((state) => state?.invoice);

  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const invoicePlayer =
    userRole === "supplier" ? (
      <>
        <Overview />
        {!!dashboardData ? (
          <div className="mt-5 w-full">
            <TableEmptyState />
          </div>
        ) : (
          <div className="flex">
            <InvoiceDashboardTable />
            <RecentTables />
          </div>
        )}
      </>
    ) : userRole === "funder" ? (
      <FunderInvoice />
    ) : (
      <div>
        <ClosedMarketTabs />
      </div>
    );

  return (
    <DashboardLayout
      section="Closed Market"
      subHeader="View invoices uploaded to the closed market by your associated buyers"
    >
      {invoicePlayer}
    </DashboardLayout>
  );
};

export default ClosedMarketDashboardContents;
