import {
  searchSupplierProducts,
  selectedProductData,
  viewSupplierProducts,
} from "appstate/purchaseOrder/poSlice";
import Image from "features/inventory/components/Image";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "shared/Button";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import TableHeader from "shared/tableHeader/TableHeader";

const SelectProducts = () => {
  const [storeSelectedData, setStoreSelectedData] = useState([]);
  const { products, isLoading } = useSelector((state) => state?.purchaseOrder);
  const [productDatas, setProductDatas] = useState(products?.data);
  const [productName, setProductName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 2;
  const supplierName = location?.state.name;
  const supplierId = location?.state.supplierId;
  const storedValue = localStorage.getItem("selectedSupplier");
  useEffect(() => {
    dispatch(
      viewSupplierProducts({
        supplierId: storedValue,
        page: page,
        pageSize: pageSize,
      })
    );
  }, [page, storedValue, pageSize, dispatch]);
  const handleCheckedRow = (row) => {
    const selectedProduct = productDatas?.find(
      (selected) => selected?.id === row?.id
    );
    if (selectedProduct) {
      setStoreSelectedData([...storeSelectedData, selectedProduct]);
    }
  };

  const handleRemoveProduct = (row) => {
    const updatedSelectedData = storeSelectedData?.filter(
      (product) => product?.id !== row?.id
    );
    setStoreSelectedData(updatedSelectedData);
  };

  const handleExport = () => {
    if (storeSelectedData?.length === 0) {
      return toast.custom(
        <div
          class='bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4'
          role='alert'
        >
          <p className='font-bold'>Can't export</p>
          <p>
            Please select a product or click the back button to go back to the
            previous page.
          </p>
        </div>
      );
    }
    dispatch(selectedProductData(storeSelectedData));
    let path = -1;
    navigate(path);
  };

  const handleAddQty = (rowIndex, columnId, value) => {
    const updatedData = [...productDatas];
    const updatedProduct = { ...updatedData[rowIndex] };
    updatedProduct[columnId] = value;
    updatedData[rowIndex] = updatedProduct;
    setProductDatas(updatedData);
  };

  const handleSearch = () => {
    dispatch(searchSupplierProducts({ supplierId, productName })).then(
      (data) => {
        setProductDatas(data?.payload?.data);
      }
    );
  };

  useEffect(() => {
    setProductDatas(products?.data);
    if (productName.length === 0) {
      setProductDatas(products?.data);
    }
  }, [products, productName]);

  const columns = [
    {
      name: "Image",
      selector: (row) => row?.imageUrl,
      cell: (row) => (
        <Image
          className='w-[51px] h-[34px] object-contain'
          src={row?.imageUrl[0]}
        />
      ),
      width: "5rem",
    },
    {
      name: "SKU",
      selector: (row) => row?.productSku,
    },
    {
      name: "Product Name",
      selector: (row) => row?.productName,
    },
    {
      name: "Unit",
      selector: (row) => row?.unit,
    },
    {
      name: "Unit Price",
      selector: (row) => row?.unitPrice,
    },
    {
      name: "Quantity",
      cell: (row) => {
        return (
          <input
            className='[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-[60px] border border-black rounded outline-none py-[5px] text-center no-spin:no-spin no-scroll:pointer-events-none'
            type='number'
            onWheel={(e) => e.target.blur()}
            value={row.quantity || ""}
            onChange={(e) => {
              const updatedValue = parseInt(e.target.value, 10);
              const rowIndex = productDatas.findIndex(
                (item) => item.id === row.id
              );
              handleAddQty(rowIndex, "quantity", updatedValue);
            }}
          />
        );
      },
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div>
            {storeSelectedData?.some((cur) => cur.id === row.id) ? (
              <Button
                className='border border-red-500 bg-red-500 text-white p-[10px] text-xs whitespace-nowrap active:scale-95 hover:bg-red-500 rounded-[5px]'
                onClick={() => handleRemoveProduct(row)}
                neutral
              >
                Remove product
              </Button>
            ) : (
              <Button
                className='border border-success bg-success text-white p-[10px] text-xs whitespace-nowrap active:scale-95 hover:bg-success rounded-[5px]'
                onClick={() => handleCheckedRow(row)}
                neutral
                disabled={!row.quantity}
              >
                Add product
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const totalPages = products?.meta?.totalElements;
  const totalProducts = productDatas?.length;
  let path = -1;

  return (
    <DashboardLayout>
      <div className='bg-white p-[10px] md:p-[24px] rounded-[5px]'>
        <TableHeader
          buyer
          total={`${totalProducts} Products`}
          title={`${supplierName}'s Inventory`}
          setProductName={setProductName}
          productName={productName}
          path={path}
          handleClick={handleExport}
          search={handleSearch}
        />
        <Table
          columns={columns}
          data={productDatas}
          progressPending={isLoading}
          progressComponent={<Loading />}
          tableHeader
          className='mt-5'
        />
        <div className='flex justify-center mt-[20px]'>
          {isLoading || productDatas?.length < 10 ? null : (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SelectProducts;
