import Modal from "layout/modal/Modal";
import React from "react";
import ModalContents from "./ModalContents";

const PayableFinanceModal = ({ isShown, hide }) => {
  return (
    <Modal isShown={isShown} hide={hide} accountModal>
      <ModalContents hide={hide} />
    </Modal>
  );
};

export default PayableFinanceModal;
