import React from "react";
import Transactions from "./transaction";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  const directFunding = () => {
    navigate("/reimbursement/directfunding");
  };

  const earlyPayment = () => {
    navigate("/reimbursement/earlypayment");
  };

  const openMarket = () => {
    navigate("/reimbursement/openmarket");
  };

  const closedMarket = () => {
    navigate("/reimbursement/closedmarket");
  };

  return (
    <main className="p4">
      <section className="grid grid-cols-2 gap-4 mb-6">
        <article className="bg-white shadow-md rounded-lg">
          <header className="p-2 border-b rounded-t-lg flex items-center justify-between bg-[#07593D] text-white">
            <h2 className="text-sm font-semibold px-5">Direct funding</h2>
            <button
              className="bg-white text-black rounded-[4px] p-2 text-sm mr-2"
              onClick={directFunding}
            >
              Make Payment
            </button>
          </header>
          <div className="p-4 px-7 flex justify-between items-center">
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Due Payment Amount</p>
              <span className="font-bold">₦1,340,090.00</span>
            </div>
            <span className="px-4">-</span>
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Total Invoices</p>
              <span className="font-bold">
                32 <span className="text-xs font-normal">invoices</span>
              </span>
            </div>
            <span className="px-4">-</span>
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Total Funders</p>
              <span className="font-bold">6</span>
            </div>
          </div>
        </article>

        <article className="bg-white shadow-md rounded-lg">
          <header className="p-2 border-b rounded-t-lg flex items-center justify-between bg-secondaryColor text-white">
            <h2 className="text-sm font-semibold px-5">Closed Market</h2>
            <button className="bg-white text-black rounded-[4px] p-2 text-sm mr-2" onClick={closedMarket}>
              Make Payment
            </button>
          </header>
          <div className="p-4 px-7 flex justify-between items-center">
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Due Payment Amount</p>
              <span className="font-bold">₦1,340,090.00</span>
            </div>
            <span className="px-4">-</span>
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Total Invoices</p>
              <span className="font-bold">
                32 <span className="text-xs font-normal">invoices</span>
              </span>
            </div>
            <span className="px-4">-</span>
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Total Funders</p>
              <span className="font-bold">6</span>
            </div>
          </div>
        </article>

        <article className="bg-white shadow-md rounded-lg">
          <header className="p-2 border-b rounded-t-lg flex items-center justify-between bg-[#F08000CC] text-white">
            <h2 className="text-sm font-semibold px-5">Open Market</h2>
            <button className="bg-white text-black rounded-[4px] p-2 text-sm mr-2" onClick={openMarket}>
              Make Payment
            </button>
          </header>
          <div className="p-4 px-7 flex justify-between items-center">
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Due Payment Amount</p>
              <span className="font-bold">₦1,340,090.00</span>
            </div>
            <span className="px-4">-</span>
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Total Invoices</p>
              <span className="font-bold">
                32 <span className="text-xs font-normal">invoices</span>
              </span>
            </div>
            <span className="px-4">-</span>
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Total Funders</p>
              <span className="font-bold">6</span>
            </div>
          </div>
        </article>

        <article className="bg-white shadow-md rounded-lg">
          <header className="p-2 border-b rounded-t-lg flex items-center justify-between bg-[#07593DCC] text-white">
            <h2 className="text-sm font-semibold px-5">
              Early Payment Program
            </h2>
            <button className="bg-white text-black rounded-[4px] p-2 text-sm mr-2" onClick={earlyPayment}>
              Make Payment
            </button>
          </header>
          <div className="p-4 px-7 flex justify-between items-center">
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Due Payment Amount</p>
              <span className="font-bold">₦1,340,090.00</span>
            </div>
            <span className="px-4">-</span>
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Total Invoices</p>
              <span className="font-bold">
                32 <span className="text-xs font-normal">invoices</span>
              </span>
            </div>
            <span className="px-4">-</span>
            <div className="flex flex-col gap2">
              <p className="text-[#7A7A7A]">Total Funders</p>
              <span className="font-bold">6</span>
            </div>
          </div>
        </article>
      </section>

      <Transactions />
    </main>
  );
};

export default Dashboard;
