import {
  PAIconMarkRead,
  PAIconTrashMini,
  PAIconViewMini,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useRef, useState } from "react";
import Card from "shared/Card";
import { AnimatePresence, motion } from "framer-motion";
import "./components/styles/notificationTab.css";
import EllipsisText from "utilities/helper/Ellipsis";
import NotificationModal from "./components/modals/NotificationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBuyerNotifications,
  getNotificationById,
} from "appstate/buyer/buyerSlice";
import toast from "react-hot-toast";
import {
  getAllSupplierNotifications,
  getSupplierNotificationById,
} from "appstate/supplier/supplierSlice";
import {
  getAllFunderNotifications,
  getFunderNotificationById,
} from "appstate/funder/funderSlice";
import Pagination from "shared/Pagination";

const Notifications = () => {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const { userDetails } = useSelector((state) => state.auth);
  const userRole = userDetails?.data?.role;
  const buyer = useSelector((state) => state?.buyer);
  const supplier = useSelector((state) => state?.supplier);
  const funder = useSelector((state) => state?.funder);
  const { notifications } =
    userRole === "BUYER"
      ? buyer
      : userRole === "SUPPLIER"
      ? supplier
      : userRole === "FUNDER"
      ? funder
      : null;

  const changeTab = (index) => {
    setActiveTab(index);
  };

  // View Full Message Modal
  const [toggleModal, setToggleModal] = useState(false);
  const [getId, setGetId] = useState(null);
  const [notificationData, setNotificationData] = useState([]);

  function toggleModalOn() {
    setToggleModal(true);
  }

  function toggleModalOff() {
    setToggleModal(false);
    switch (userRole) {
      case "SUPPLIER":
        dispatch(getAllSupplierNotifications());
        break;
      case "BUYER":
        dispatch(getAllBuyerNotifications());
        break;
      case "FUNDER":
        dispatch(getAllFunderNotifications());
        break;
      default:
        toast.error("Invalid user role:", userRole);
    }
  }

  const handleCardClick = (id) => {
    const clickedCard = notifications?.data?.find((item) => item.id === id);
    setGetId(clickedCard?.id);
    switch (userRole) {
      case "SUPPLIER":
        dispatch(getSupplierNotificationById({ notificationId: getId }));
        break;
      case "BUYER":
        dispatch(getNotificationById({ notificationId: getId }));
        break;
      case "FUNDER":
        dispatch(getFunderNotificationById({ notificationId: getId }));
        break;
      default:
        toast.error("Invalid user role:", userRole);
    }
    toggleModalOn();
  };

  useEffect(() => {
    switch (userRole) {
      case "SUPPLIER":
        dispatch(getAllSupplierNotifications({ page, pageSize }));
        break;
      case "BUYER":
        dispatch(getAllBuyerNotifications({ page, pageSize }));
        break;
      case "FUNDER":
        dispatch(getAllFunderNotifications({ page, pageSize }));
        break;
      default:
        toast.error("Invalid user role:", userRole);
    }
  }, [dispatch, userRole, page, pageSize]);

  useEffect(() => {
    setNotificationData(notifications?.data);
  }, [notifications]);

  const unreadNotifications = notificationData?.filter(
    (notification) => !notification.read
  );
  const heading =
    "You have " + unreadNotifications?.length + " unread notifications.";
  const totalPages = notifications?.meta?.totalElements;
  return (
    <DashboardLayout>
      {/* Modal Popup */}
      <AnimatePresence>
        {toggleModal && (
          <NotificationModal
            className=" z-[9999]"
            id={getId}
            toggleModalOff={toggleModalOff}
          />
        )}
      </AnimatePresence>

      <div className=" w-full pb-6 bg-white rounded-[10px]	">
        <header className="bg-[#FAFAFA] flex flex-col gap-4 px-6 py-5 rounded-t-[10px]">
          <div className=" flex items-center  justify-between">
            <p className="text-[#565656] text-sm flex items-center">
              {heading}
            </p>
            <button className="px-6 py-3 text-[#07593D] border border-[#07593d] rounded-[5px] font-[500] text-sm transition duration-[350ms] transform hover:scale-[0.95] ease-out hover:bg-whisper200">
              Mark all as read
            </button>
          </div>

          <div className=" border-[0.8px] w-full border-[#CCC]"></div>

          <div className="flex gap-7">
            <span
              onClick={() => changeTab(0)}
              className={`flex items-center gap-1.5 font-[600]   ${
                activeTab === 0
                  ? "active-not-tab not-tab-button"
                  : "inactive-not-tab not-tab-button"
              }`}
            >
              <span className="flex items-center justify-center h-full">
                All
              </span>
              <span className="rounded-[50%] flex justify-center items-center h-[20px]  w-[20px] text-[10px] font-[600] bg-[#FBEDDD]">
                {notifications?.data?.length}
              </span>
            </span>

            <span
              onClick={() => changeTab(1)}
              className={` flex items-center ${
                activeTab === 1
                  ? "active-not-tab not-tab-button"
                  : "inactive-not-tab not-tab-button"
              }`}
            >
              Unread
            </span>
          </div>
        </header>

        <main className=" my-1">
          {/* Switch tab based on selection */}
          {activeTab === 0 ? (
            <AnimatePresence>
              <motion.div
                initial={{
                  y: 20,
                  opacity: 0,
                }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                }}
                exit={{
                  x: 20,
                  opacity: 0,
                  transition: {
                    duration: 0.3,
                  },
                }}
                className="tab-content py-1.5  active w-full"
              >
                <Cards />
              </motion.div>
            </AnimatePresence>
          ) : (
            <motion.div
              initial={{
                y: -20,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              }}
              exit={{
                x: 20,
                opacity: 0,
                transition: {
                  duration: 0.3,
                },
              }}
              className="tab-content py-1.5  active w-full"
            >
              <UnreadCards />
            </motion.div>
          )}
        </main>
      </div>
    </DashboardLayout>
  );

  // All Messages

  function Cards() {
    return (
      <div className=" my-3">
        <div className="flex flex-col gap-3 mx-8">
          {notificationData?.map((data) => (
            <NotificationCard key={data?.id} data={data} />
          ))}
        </div>
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    );
  }

  // Unread Messages

  function UnreadCards() {
    const unread = (val) => !val.read;
    return (
      <div className=" my-3">
        <div className="flex flex-col gap-3 mx-8">
          {notificationData?.filter(unread).map((item) => (
            <NotificationCard key={item.id} data={item} />
          ))}
        </div>
      </div>
    );
  }

  function NotificationCard({ data }) {
    const dropdownRef = useRef();
    const [dropdown, setDropdown] = useState(false);

    const handleDropdown = (data) => {
      if (dropdown === data.id) {
        setDropdown(null);
      } else {
        setDropdown(data.id);
      }
    };
    const [atBottom, setAtBottom] = useState(false);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdown &&
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setDropdown(false);
        }
      };

      const handleDocumentClick = (e) => {
        const screenHeight = window.innerHeight;
        const clickY = e.clientY;
        const bottomThreshold = 200;
        if (screenHeight - clickY <= bottomThreshold) {
          setAtBottom(true);
        } else {
          setAtBottom(false);
        }
      };
      document.addEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleDocumentClick);
        document.addEventListener("mousedown", handleClickOutside);
      };
    }, [dropdown]);

    return (
      <div
        className={`flex justify-between rounded cursor-pointer ${
          data?.read === true ? "bg-white shadow-sm" : "bg-[#f2f2f2]"
        } w-full p-4`}
      >
        <div className=" flex justify-center items-center gap-4 ">
          <div className=" flex justify-center items-center w-11 h-11 rounded-[50%] bg-white">
            <img
              className="w-11 h-11 rounded-[50%] text-sm"
              src={data?.imgUrl}
              alt="dp"
            />
          </div>

          <div className=" flex justify-center flex-col gap-1 text-[#333135] ">
            <span
              className={`text-[#333135] flex items-center text-sm ${
                data?.read === true ? "" : "font-medium"
              }`}
            >
              {data?.notificationType}
            </span>
            <span className=" text-xs flex items-center font-[300]">
              <span className=" hidden sm:inline md:hidden ">
                <EllipsisText text={data?.message} maxLength={40} />
              </span>

              <span className=" hidden md:inline lg:hidden ">
                <EllipsisText text={data?.message} maxLength={50} />
              </span>

              <span className="hidden  lg:inline">{data?.message}</span>
            </span>
          </div>
        </div>

        <div className="flex items-center gap-4 text-[#757474]">
          <div className="flex  items-end flex-col gap-2">
            <span className="  text-[9px] font-light">{data?.receiptTime}</span>
            {!data?.read && (
              <div className=" bg-green-600 w-2 h-2 rounded-[50%]"></div>
            )}
          </div>

          <div ref={dropdownRef} className=" p-2.5 ">
            <img
              onClick={() => handleDropdown(data)}
              src="/option-btn.svg"
              alt="option"
            />

            {dropdown === data?.id && data && (
              <div className={`text-xs relative z-[9999]`}>
                <Card
                  className={`w-[160px] z-[99999]  right-[-10px] ${
                    atBottom && "bottom-[20px]"
                  }  absolute drop-shadow-md rounded-md py-[12px]`}
                >
                  <div
                    onClick={() => handleCardClick(data?.id)}
                    className="flex z-[99999]  items-center gap-x-[10px] mb-[12px]  pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                  >
                    <span>
                      <PAIconViewMini />
                    </span>
                    <p className="font-xs font-normal">View Details</p>
                  </div>
                  <div className="z-[99999] flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                    <span>
                      <PAIconMarkRead />
                    </span>
                    <p className="font-xs font-normal">Mark as read</p>
                  </div>
                  <div className="border border-silver"></div>
                  <div className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                    <span>
                      <PAIconTrashMini />
                    </span>
                    <p className="text-[#DE1515]">Delete</p>
                  </div>
                </Card>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};
export default Notifications;
