import LandingpageCard from 'features/landing/components/LandingpageCard';
import React from 'react';
import Bar from 'shared/Bar';
import Button from 'shared/Button';

const SectionTwo = () => {
  const cardContents = [
    {
      heading: 'Invoice Financing',
      content:
        'Our invoice financing solutions help our clients to access working capital by advancing funds against their outstanding invoices. This helps to improve cash flow and reduce the risk of late payment.',
    },
    {
      heading: 'Inventory Financing',
      content:
        'Our inventory financing solutions provide our clients with the funds they need to purchase inventory. This helps to improve cash flow and reduce the risk of stockouts',
    },
    {
      heading: 'Working Capital Management',
      content:
        'Our working capital management solutions help our clients to optimize their cash flow by managing their payables, receivables, and inventory.',
    },
  ];
  return (
    <div className="bg-primaryColor px-[24px] py-[30px] lg:px-[42px]">
      <Bar />
      <h1 className="font-extrabold text-[24px] text-white mt-[30px]">
        Your inclusive supply chain solution
      </h1>
      <p className="font-medium text-lg text-brightGray mt-[10px]">
        No supplier gets behind.
      </p>
      <div className="mt-[30px] lg:grid grid-cols-2 gap-x-20">
        {cardContents.map((cardContent, idx) => (
          <LandingpageCard
            key={idx}
            heading={cardContent.heading}
            content={cardContent.content}
          />
        ))}
        <div className="text-center">
          <h1 className="font-extrabold text-[24px] text-white">
            A solution for all suppliers
          </h1>
          <p className="font-medium text-lg text-brightGray mb-[30px]">
            See how PayEdge works
          </p>
          <Button>Find out more</Button>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
