import {
  allBuyerAssociatedFunders,
  creatNewPfp,
} from "appstate/buyer/buyerSlice";
import {
  PAIconCancel,
  PAIconConfirm,
  PAIconMDISuccess,
} from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import InputField from "shared/InputField";
import AccountModal from "shared/accountModal/AccountModal";

const ModalContents = ({ hide }) => {
  const [searchName, setSearchName] = useState("");
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [dynamic, setDynamic] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [next, setNext] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [errors, setErrors] = useState({});
  const initialOptions = [
    { label: " Tier 1", value: "TIER_1", checked: false },
    { label: " Tier 2", value: "TIER_2", checked: false },
    { label: " Tier 3", value: "TIER_3", checked: false },
    { label: " Tier 4", value: "TIER_4", checked: false },
    { label: " Tier 5", value: "TIER_5", checked: false },
    { label: " Tier 6", value: "TIER_6", checked: false },
    { label: " Tier 7", value: "TIER_7", checked: false },
    { label: " Tier 8", value: "TIER_8", checked: false },
    { label: " Tier 9", value: "TIER_9", checked: false },
    { label: " Tier 10", value: "TIER_10", checked: false },
  ];
  const [options, setOptions] = useState(initialOptions);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const selectedOptions = options.filter((option) => option.checked);

  const [formData, setFormData] = useState({
    programName: "",
    startDate: "",
    endDate: "",
    tiers: "",
    financingOptions: "",
    discount: "",
    minAmount: "",
    maxAmount: "",
    buyerShare: "",
    funderIds: "",
  });

  const dispatch = useDispatch();

  const { createPfpLoading } = useSelector((state) => state?.buyer);

  const handleDynamic = (e) => {
    const { checked, value } = e.target;
    setDynamic(checked);

    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        financingOptions: [...prevData.financingOptions, value],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        financingOptions: prevData.financingOptions.filter(
          (option) => option !== value
        ),
      }));
    }

    if (!checked) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        financingOptions: "Select Finance option",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        financingOptions: "",
      }));
    }
  };

  const handleReverse = (e) => {
    const { checked, value } = e.target;
    setReverse(checked);

    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        financingOptions: [...prevData.financingOptions, value],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        financingOptions: prevData.financingOptions.filter(
          (option) => option !== value
        ),
      }));
    }

    if (!checked) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        financingOptions: "Select Finance option",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        financingOptions: "",
      }));
    }
  };

  function handleNext() {
    setNext(true);
  }

  const page = 0;

  const pageSize = 10;

  const handleSearch = (searchQuery) => {
    dispatch(
      allBuyerAssociatedFunders({ page, pageSize, search: searchQuery })
    ).then((response) => {
      setFilteredData(response?.payload?.data);
    });
  };

  const handleChange = (event) => {
    const searchQuery = event.target.value;
    setSearchName(searchQuery);
    handleSearch(searchQuery);
  };

  const handleSelect = (funder) => {
    setFormData((prevData) => ({
      ...prevData,
      funderIds: [...prevData.funderIds, funder.userId],
    }));
    setStoreData([funder, ...storeData]);
    setFilteredData([]);
    setSearchName("");
  };

  useEffect(() => {
    if (formData.funderIds.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        funderIds: "Please select at least one funder",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        funderIds: "",
      }));
    }
  }, [formData]);

  const handleRemoveEmail = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      funderIds: prevData.financingOptions.filter((_, i) => i !== index),
    }));
    const updatedFunder = storeData.filter((_, i) => i !== index);
    setStoreData(updatedFunder);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors({
      ...errors,
      [name]: "",
    });

    if (name === "startDate") {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const selectedDate = new Date(value);
      selectedDate.setHours(0, 0, 0, 0);

      if (selectedDate < today) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please select a date on or after today.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }

    if (name === "endDate") {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(value);

      if (endDate < startDate) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "End date cannot be before the start date",
        }));
      }
    }

    if (name === "minAmount" || name === "maxAmount") {
      const minAmount =
        name === "minAmount"
          ? parseFloat(value)
          : parseFloat(formData.minAmount);
      const maxAmount =
        name === "maxAmount"
          ? parseFloat(value)
          : parseFloat(formData.maxAmount);

      if (!isNaN(minAmount) && !isNaN(maxAmount) && minAmount >= maxAmount) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Amount can not be below minimum",
        }));
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim() === "") {
      const formattedName = name
        .split(/(?=[A-Z])/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      setErrors({
        ...errors,
        [name]: `${formattedName} is required`,
      });
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.programName) {
      newErrors.programName = "Programe is required";
      isValid = false;
    }

    if (!formData.startDate) {
      newErrors.startDate = "Start Date is required";
      isValid = false;
    }

    if (!formData.endDate) {
      newErrors.endDate = "End Date is required";
      isValid = false;
    }

    if (formData.tiers.length === 0) {
      newErrors.tiers = "Tiers is required";
      isValid = false;
    }

    if (formData.financingOptions.length === 0) {
      newErrors.financingOptions = "Financing options is required";
      isValid = false;
    }

    if (!formData.discount) {
      newErrors.discount = "Discount is required";
      isValid = false;
    }

    if (!formData.minAmount) {
      newErrors.minAmount = "Minimum amount is required";
      isValid = false;
    }

    if (!formData.maxAmount) {
      newErrors.maxAmount = "Maximum amount is required";
      isValid = false;
    }

    if (!dynamic && !formData.buyerShare) {
      newErrors.buyerShare = "Buyer share is required";
      isValid = false;
    }

    if (!dynamic && formData.funderIds.length === 0) {
      newErrors.funderIds = "Please select funder";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (dynamic && reverse) {
        const body = {
          programName: formData.programName,
          startDate: formData.startDate,
          endDate: formData.endDate,
          tierCategory: formData.tiers,
          financingOptions: formData.financingOptions,
          discountRate: parseFloat(formData.discount),
          minimumAmount: parseFloat(formData.minAmount),
          maximumAmount: parseFloat(formData.maxAmount),
          funderPercentage: parseFloat(formData.buyerShare),
          funderIds: formData.funderIds,
        };
        dispatch(creatNewPfp(body)).then((data) => {
          if (data?.payload?.success) {
            hide();
            toast.custom(
              <div className="flex mt-[200px]">
                <div className="bg-primaryColor w-[147.791px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
                  <PAIconMDISuccess />
                </div>
                <div className="bg-white flex flex-col justify-center items-center w-[394.538px] h-[160px] rounded-r-[7px] shadow-lg">
                  <PAIconConfirm />
                  <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                    Payable Flex PFP has been created
                  </p>
                </div>
              </div>
            );
          }
        });
      } else if (dynamic) {
        const body = {
          programName: formData.programName,
          startDate: formData.startDate,
          endDate: formData.endDate,
          tierCategory: formData.tiers,
          financingOptions: formData.financingOptions,
          discountRate: parseFloat(formData.discount),
          minimumAmount: parseFloat(formData.minAmount),
          maximumAmount: parseFloat(formData.maxAmount),
        };
        dispatch(creatNewPfp(body)).then((data) => {
          if (data?.payload?.success) {
            hide();
            toast.custom(
              <div className="flex mt-[200px]">
                <div className="bg-primaryColor w-[147.791px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
                  <PAIconMDISuccess />
                </div>
                <div className="bg-white flex flex-col justify-center items-center w-[394.538px] h-[160px] rounded-r-[7px] shadow-lg">
                  <PAIconConfirm />
                  <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                    Payable Flex PFP has been created
                  </p>
                </div>
              </div>
            );
          }
        });
      } else if (reverse) {
        const body = {
          programName: formData.programName,
          startDate: formData.startDate,
          endDate: formData.endDate,
          tierCategory: formData.tiers,
          financingOptions: formData.financingOptions,
          discountRate: parseFloat(formData.discount),
          minimumAmount: parseFloat(formData.minAmount),
          maximumAmount: parseFloat(formData.maxAmount),
          funderPercentage: parseFloat(formData.buyerShare),
          funderIds: formData.funderIds,
        };
        dispatch(creatNewPfp(body)).then((data) => {
          if (data?.payload?.success) {
            hide();
            toast.custom(
              <div className="flex mt-[200px]">
                <div className="bg-primaryColor w-[147.791px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
                  <PAIconMDISuccess />
                </div>
                <div className="bg-white flex flex-col justify-center items-center w-[394.538px] h-[160px] rounded-r-[7px] shadow-lg">
                  <PAIconConfirm />
                  <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                    Payable Flex PFP has been created
                  </p>
                </div>
              </div>
            );
          }
        });
      } else {
        toast.error("No finance option selected");
      }
    } else {
      toast.error("Fill all required field");
    }
  };

  function handleModalOpen(e) {
    e.preventDefault();
    if (validateForm()) {
      setOpen(true);
    } else {
      toast.error("Fill all required fields");
    }
  }

  const handleTiersChange = (option) => {
    const updatedOptions = options.map((opt) =>
      opt.value === option.value ? { ...opt, checked: !opt.checked } : opt
    );
    setOptions(updatedOptions);

    const selectedTiers = updatedOptions
      .filter((opt) => opt.checked)
      .map((opt) => opt.value);

    setFormData((prevData) => ({
      ...prevData,
      tiers: selectedTiers,
    }));

    if (selectedTiers.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tiers: "Tiers is required",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tiers: "",
      }));
    }
  };

  const isNextDisabled =
    !formData.programName ||
    !formData.startDate ||
    !formData.endDate ||
    !formData.tiers ||
    !formData.financingOptions ||
    !formData.discount ||
    !formData.minAmount ||
    !formData.maxAmount ||
    new Date(formData.endDate) < new Date(formData.startDate) ||
    new Date(formData.startDate).setHours(0, 0, 0, 0) <
      new Date().setHours(0, 0, 0, 0);

  return (
    <>
      {open ? (
        <AccountModal
          handleAssociate={handleSubmit}
          isLoading={createPfpLoading}
          warning="Are you sure you would like to create this payable finance program?"
          hide={hide}
          success={false}
        />
      ) : (
        <div className="pb-[30px]">
          <div className="flex justify-between px-[32px] py-[21px] bg-alabasterHeader">
            <h1 className="text-sm font-extrabold italic">
              Payable Finance Program
            </h1>
            <div
              onClick={hide}
              className="flex gap-x-[8px] items-center cursor-pointer"
            >
              <PAIconCancel className="w-[10px]" />
              <p className="text-sm">Cancel</p>
            </div>
          </div>
          <div className="px-[32px]">
            <p className="text-sm text-lightGray my-[24px]">
              Create your payable finance program
            </p>
            <form className="overflow-hidden">
              {next ? (
                <div>
                  <div>
                    <label
                      htmlFor=""
                      className="font-normal text-sm text-textColor mb-[8px]"
                    >
                      Sharing ratio
                    </label>

                    <div className="flex justify-between items-center">
                      <div>
                        <InputField
                          labelFontSize
                          label="Buyer"
                          type="number"
                          id="buyerShare"
                          name="buyerShare"
                          placeholder="buyer share"
                          value={formData.buyerShare}
                          onChange={handleInputChange}
                          className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border rounded-[8px] px-[16px] py-[12px] w-[235px] outline-none"
                        />
                      </div>
                      <p className="text-primaryColor text-[20px] relative top-3">
                        {" "}
                        :
                      </p>
                      <InputField
                        labelFontSize
                        label="Funder"
                        placeholder="100% - BS"
                        disabled
                        className="border bg-[#EFEFEF] rounded-[8px] px-[16px] py-[12px] w-[235px] outline-none "
                      />
                    </div>
                    {errors.buyerShare && (
                      <p className="text-red text-xs relative top-2 mb-[10px]">
                        {errors.buyerShare}
                      </p>
                    )}
                  </div>
                  <p className="text-gray800 text-sm font-[300] italic mb-[20px]">
                    <span className="text-[#F08000] text-sm font-semibold">
                      Note
                    </span>
                    : Sharing ratio is less service charge of 25%
                  </p>
                  <div>
                    <InputField
                      labelFontSize
                      type="text"
                      label="Invite Funder"
                      placeholder="Search associated funders"
                      value={searchName}
                      onChange={handleChange}
                      className="border rounded-[8px] px-[16px] py-[12px] outline-none mb-[10px]"
                    />
                    {searchName.trim() !== "" && (
                      <div className="bg-[#EFEFEF] text-sm p-[10px] rounded">
                        {filteredData?.map((funder, index) => (
                          <div
                            className="cursor-pointer"
                            onClick={() => handleSelect(funder)}
                            key={index}
                          >
                            <p>{funder?.fullName}</p>
                          </div>
                        ))}
                      </div>
                    )}
                    {storeData?.map((funder, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center mb-[18px]"
                      >
                        <p className="text-sm text-textColor">
                          {funder.fullName}
                        </p>
                        <Button
                          neutral
                          onClick={() => handleRemoveEmail(index)}
                          className="py-[8px] px-[16px] border-[1px] border-primaryColor !text-primaryColor text-xs rounded-[5px]"
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                    {errors.funderIds && (
                      <p className="text-red text-xs  w-[300px] md:w-[424px]">
                        {errors.funderIds}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className="mb-[20px]">
                    <InputField
                      labelFontSize
                      type="text"
                      label="Program Name:"
                      id="programName"
                      name="programName"
                      placeholder="Enter name of program"
                      className="border rounded-[8px] px-[16px] py-[12px] outline-none"
                      onChange={handleInputChange}
                      value={formData.programName}
                    />
                    {errors.programName && (
                      <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                        {errors.programName}
                      </p>
                    )}
                  </div>
                  <div>
                    <div className="relative mb-[20px]">
                      <div>
                        <InputField
                          labelFontSize
                          id="dynamic"
                          type="checkbox"
                          label="Select financing option"
                          placeholder="Enter name of program"
                          onChange={handleDynamic}
                          checked={dynamic}
                          className=" h-[16px] absolute top-3  outline-none mb-[10px]"
                          value="DYNAMIC_DISCOUNTING"
                        />
                        <label
                          htmlFor="dynamic"
                          className="absolute top-6 left-5 text-xs text-gray800"
                        >
                          Dynamic Discounting
                        </label>
                      </div>

                      <div>
                        <InputField
                          labelFontSize
                          id="reverse"
                          type="checkbox"
                          onChange={handleReverse}
                          checked={reverse}
                          value="REVERSE_FACTORING"
                          placeholder="Enter name of program"
                          className=" h-[16px] absolute top-3 left-[150px] outline-none mb-[20px]"
                        />
                        <label
                          htmlFor="reverse"
                          className="absolute top-6 left-[170px] text-xs text-gray800"
                        >
                          Reverse Factoring
                        </label>
                      </div>
                    </div>
                    {!dynamic && !reverse && (
                      <>
                        {errors.financingOptions && (
                          <p className="text-red text-xs w-[300px] md:w-[424px] relative bottom-[5px]">
                            {errors.financingOptions}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                  <div className="mb-[20px]">
                    <InputField
                      labelFontSize
                      type="date"
                      label="Start Date:"
                      id="startDate"
                      name="startDate"
                      placeholder="Enter name of program"
                      className="border rounded-[8px] px-[16px] py-[12px] outline-none"
                      onChange={handleInputChange}
                      value={formData.startDate}
                    />
                    {errors.startDate && (
                      <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                        {errors.startDate}
                      </p>
                    )}
                  </div>

                  <div className="mb-[20px]">
                    <InputField
                      labelFontSize
                      type="date"
                      label="End Date:"
                      id="endDate"
                      name="endDate"
                      placeholder="Enter name of program"
                      className="border rounded-[8px] px-[16px] py-[12px] outline-none "
                      onChange={handleInputChange}
                      value={formData.endDate}
                    />
                    {errors.endDate && (
                      <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                        {errors.endDate}
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <label
                      htmlFor=""
                      className="font-normal text-sm text-textColor mb-[10px]"
                    >
                      Tier Selection
                    </label>
                    <div
                      className={`dropdown relative ${
                        isDropdownOpen ? "open" : ""
                      }`}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <div
                        className={`selected-options border border-gray-300 ${
                          isDropdownOpen ? "rounded-t-[8px]" : "rounded-[8px]"
                        } p-2 cursor-pointer flex justify-between items-center`}
                      >
                        {selectedOptions.length === 0 ? (
                          <span className="text-sm">
                            What tier qualifies for program{" "}
                          </span>
                        ) : selectedOptions.length === 1 ? (
                          <span className="text-sm">
                            {selectedOptions[0].label}
                          </span>
                        ) : (
                          <span className="text-sm">
                            {selectedOptions.length} Tiers selected
                          </span>
                        )}
                        <svg
                          className="h-4 w-4 ml-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          {isDropdownOpen ? (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 15l7-7 7 7"
                            />
                          ) : (
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          )}
                        </svg>
                      </div>
                      {errors.tiers && (
                        <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                          {errors.tiers}
                        </p>
                      )}
                      <div
                        className={`dropdown-menu bg-white border flex flex-wrap border-gray-800 border-t-0 w-full z-10 ${
                          isDropdownOpen ? "block" : "hidden"
                        } transition-all duration-500 ${
                          isDropdownOpen ? "ease-in" : "ease-out"
                        }`}
                      >
                        {options.map((option) => (
                          <div
                            onClick={(e) => e.stopPropagation()}
                            className="option"
                            key={option.value}
                          >
                            <label className="block cursor-pointer p-2">
                              <input
                                type="checkbox"
                                checked={option.checked}
                                onClick={(e) => e.stopPropagation()}
                                id="tiers"
                                name="tiers"
                                value={formData.tiers}
                                onChange={() => handleTiersChange(option)}
                              />
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </div>
                      <p className="text-sm mt-[10px] mb-[10px]">
                        Selected Option :
                        {selectedOptions
                          .map((option) => option.label)
                          .join(", ")}
                      </p>
                    </div>
                  </div>
                  {dynamic ? (
                    <div>
                      <InputField
                        labelFontSize
                        type="number"
                        label="Discount Rate"
                        id="discount"
                        name="discount"
                        placeholder="Discount rate"
                        className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border rounded-[8px] px-[16px] py-[12px]  outline-none"
                        onChange={handleInputChange}
                        value={formData.discount}
                      />
                      {errors.discount && (
                        <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                          {errors.discount}
                        </p>
                      )}
                    </div>
                  ) : reverse ? (
                    <div>
                      <InputField
                        labelFontSize
                        type="number"
                        label="Discount Rate"
                        id="discount"
                        name="discount"
                        placeholder="Discount rate"
                        className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border rounded-[8px] px-[16px] py-[12px]  outline-none"
                        onChange={handleInputChange}
                        value={formData.discount}
                      />
                      {errors.discount && (
                        <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                          {errors.discount}
                        </p>
                      )}
                    </div>
                  ) : null}
                  <div className="mt-[10px]">
                    <label
                      htmlFor=""
                      className="font-normal text-sm text-textColor mb-[8px]"
                    >
                      Amount Range
                    </label>
                    <div className="flex justify-between">
                      <div className="mb-[20px] w-[235px]">
                        <InputField
                          labelFontSize
                          type="number"
                          label="Minimum Amount"
                          id="minAmount"
                          name="minAmount"
                          placeholder="min"
                          onChange={handleInputChange}
                          value={formData.minAmount}
                          className="border [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none rounded-[8px] px-[16px] py-[12px]  outline-none w-full"
                        />
                        {errors.minAmount && (
                          <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                            {errors.minAmount}
                          </p>
                        )}
                      </div>
                      <div className="mb-[20px] w-[235px]">
                        <InputField
                          labelFontSize
                          type="number"
                          label="Maximum Amount"
                          id="maxAmount"
                          name="maxAmount"
                          placeholder="max"
                          onChange={handleInputChange}
                          value={formData.maxAmount}
                          className="border [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none rounded-[8px] px-[16px] py-[12px]  outline-none w-full"
                        />
                        {errors.maxAmount && (
                          <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                            {errors.maxAmount}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="text-gray800 text-sm font-[300]">
                    <span className="text-[#F08000] text-sm font-semibold">
                      Note
                    </span>
                    : A service charge/convivence fee would be add to this
                    program of 25%. The service charge is Net discount amount -
                    Service charge
                  </p>
                </>
              )}

              <div className="flex justify-end gap-x-[16px] mt-[32px]">
                <Button
                  onClick={hide}
                  type="Button"
                  neutral
                  className="!text-lightGray text-base !font-normal"
                >
                  Cancel
                </Button>
                {reverse ? (
                  <>
                    {next ? (
                      <Button
                        type="Button"
                        neutral
                        onClick={handleModalOpen}
                        className="!text-white !font-normal bg-primaryColor py-[8px] px-[24px] rounded-[5px]"
                      >
                        Create
                      </Button>
                    ) : (
                      <Button
                        type="Button"
                        neutral
                        onClick={handleNext}
                        disabled={isNextDisabled}
                        className="!text-white !font-normal bg-primaryColor py-[8px] px-[24px] rounded-[5px]"
                      >
                        Next
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    type="Button"
                    onClick={handleModalOpen}
                    neutral
                    className="!text-white !font-normal bg-primaryColor py-[8px] px-[24px] rounded-[5px]"
                  >
                    Create
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalContents;
