import { allSupplierInvoiceData } from "appstate/invoice/invoiceSlice";
import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import SelectAccountModal from "features/supplierEarlyPayment/payment";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import SuccessModal from "shared/SuccessModal";
import TableHeader from "shared/tableHeader/TableHeader";

const AllSupplierInvoices = () => {
  const navigate = useNavigate();
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [productName, setProductName] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [hasSelected, setHasSelected] = useState(false);
  const [isSelectAccountModalOpen, setIsSelectAccountModalOpen] = useState("");
  const [isProceedModalOpen, setIsProceedModalOpen] = useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const dispatch = useDispatch();

  const handleNavigate = () => {
    navigate(-1);
  };

  const { supplierInvoiceData, isLoading } = useSelector(
    (state) => state?.invoice
  );

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;
  // const [status, setStatus] = useState("");
  const [status, ] = useState("");

  useEffect(() => {
    dispatch(allSupplierInvoiceData({ page, pageSize, status }));
  }, [dispatch, page, pageSize, status]);

  
  const InvoiceDetails = (id) => {
    let path = `/invoice/supplier-invoice-details/${id}`;
    navigate(path);
  };

  const options = [
    { id: 1, name: "All" },
    { id: 2, name: "CREATED" },
    { id: 3, name: "PAID" },
    { id: 4, name: "PENDING" },
    { id: 5, name: "AWAITING PAYMENT" },
    { id: 6, name: "APPROVED" },
  ];

  const handleFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  const handleAccept = () => {
    setIsAcceptModalOpen(false);
    setIsSelectAccountModalOpen(true);
    // setIsSuccessModalOpen(true);
    // setSuccessMessage("thanks");
    // dispatch(acceptOffer({ offerId, invIds: invoiceId })).then((response) => {
    //   if (response?.payload?.status === 200 && response?.payload?.success) {
    //     setIsSuccessModalOpen(true);
    //     setSuccessMessage(response?.payload?.message);
    //   }
    // });
  };

  const handleAccountSubmit = () => {
    setIsSelectAccountModalOpen(false);
    setIsProceedModalOpen(true);
  };

  const handleProceedSubmit = () => {
    setIsProceedModalOpen(false);
    setIsSuccessModalOpen(true);
    setSuccessMessage("Invoice submitted for approval");
  };

  const handleApproval = () => {
    if (hasSelected) {
      setIsAcceptModalOpen(true);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedInvoices((prevSelected) => {
      const newSelected = prevSelected.includes(id)
        ? prevSelected.filter((invoiceId) => invoiceId !== id)
        : [...prevSelected, id];

      setHasSelected(newSelected.length > 0);
      return newSelected;
    });
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setIsSelectAllChecked(isChecked);
    if (isChecked) {
      setSelectedInvoices(filteredData.map((invoice) => invoice.id));
      setHasSelected(true);
    } else {
      setSelectedInvoices([]);
      setHasSelected(false);
    }
  };
  

  const filteredData = supplierInvoiceData?.data?.filter((invoice) => {
    if (selectedFilter === "All") return true;
    return invoice.status === selectedFilter;
  });

  const columns = [
    ...(selectedFilter === "CREATED"
      ? [
          {
            name: (
              <input type="checkbox" onChange={handleSelectAllChange} checked={isSelectAllChecked} />
            ),
            cell: (row) => (
              <input
                type="checkbox"
                checked={selectedInvoices.includes(row.id)}
                onChange={() => handleCheckboxChange(row.id)}
              />
            ),
            width: "50px",
          },
        ]
      : []),
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceRef,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyer,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoicedAmount,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        const successStatuses = ["CREATED", "APPROVED", "PAID", "FULLY PAID"];
        const rejectedStatuses = ["PARTIALLY PAID", "REJECTED"];
        const awaitingStatuses = [
          "PENDING",
          "AWAITING APPROVAL",
          "AWAITING PAYMENT",
        ];
        return successStatuses.includes(row.status) ? (
          <p className="text-success">{row.status}</p>
        ) : rejectedStatuses.includes(row.status) ? (
          <p className="text-red">{row.status}</p>
        ) : awaitingStatuses.includes(row.status) ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        );
      },
    },
  ];

  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <div
        onClick={handleNavigate}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
      >
        <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
      </div>
      <div>
        <Card className="p-[24px] rounded-[10px]">
          <div>
            <TableHeader
              total={`${
                supplierInvoiceData?.meta?.totalElements || 0
              } invoices`}
              details={
                <p>
                  Filter by saved invoices to select multiple saved invoices for
                  approval
                </p>
              }
              productName={productName}
              setProductName={setProductName}
              showSelectContainer={true}
              options={options}
              handleChange={handleFilterChange}
              approval
              approvalName="Submit for approval"
              active={hasSelected}
              nonActive={!hasSelected}
              handleApproval={handleApproval}
            />
          </div>
          <Table
            columns={columns}
            data={filteredData}
            onRowClicked={(row) => InvoiceDetails(row.id)}
            progressPending={isLoading}
            progressComponent={<Loading />}
            pointer
            tableHeader
            className="mt-5 min-h-[60vh]"
          />
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {supplierInvoiceData?.data?.length >= 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={supplierInvoiceData?.meta?.totalElements}
              />
            )}
          </div>
          <AcceptModal
            isOpen={isAcceptModalOpen}
            onClose={() => setIsAcceptModalOpen(false)}
            message="Would you like to submit these invoices for approval"
            onConfirm={handleAccept}
            loading={false}
          />
          <SuccessModal
            isOpen={isSuccessModalOpen}
            onClose={() => setIsSuccessModalOpen(false)}
            message={successMessage}
          />
          <SelectAccountModal
            isOpen={isSelectAccountModalOpen}
            onClose={() => setIsSelectAccountModalOpen(false)}
            onSubmit={handleAccountSubmit}
          />
          <AcceptModal
            isOpen={isProceedModalOpen}
            onClose={() => setIsProceedModalOpen(false)}
            message={
              <>
                Would you like to proceed with this account{" "}
                <span className="text-[#07593D] font-bold">6767876789?</span>
              </>
            }
            onConfirm={handleProceedSubmit}
            loading={false}
          />
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default AllSupplierInvoices;
