import { PAIconImportArrrowDown } from "assets/images/svgs";
const ClosedMarketHeaderSelect = ({
  label,
  htmlFor,
  onChange,
  disabledSelect,
  options,
}) => {
  return (
    <div className="w-full flex flex-col justify-center">
      {label && (
        <label
          htmlFor={htmlFor}
          className=" text-black text-sm font-normal  mb-[2px]"
        >
          {label}
        </label>
      )}
      <div className="relative flex items-center justify-center">
        <select
          disabled={disabledSelect}
          onChange={onChange}
          defaultValue={"default"}
          className="appearance-none w-full h-[40px] pl-3 font-medium text-sm text-gray-500 bg-white rounded-[5px] border border-[#CCCCCC] outline-none focus:outline-none"
        >
          {/* <option value="default" disabled>
              Filter
            </option> */}
          {options?.map((option) => (
            <option key={option?.id}>{option?.name}</option>
          ))}
        </select>

        <div className="absolute inset-y-0 right-0 flex items-center px-2 top-[8px] pointer-events-none">
          <PAIconImportArrrowDown />
        </div>
      </div>
    </div>
  );
};

export default ClosedMarketHeaderSelect;
