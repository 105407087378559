import { PAIconArrowUpGreen, PAIconSabModel } from "assets/images/svgs";
import { motion } from "framer-motion";
import Drawer from "./Drawer";
import CardLoading from "features/openMarket/funder/components/cards/CardLoading";
import TransitionOpacity from "shared/animation/TransitionOpacity";
// import { useState } from "react";

function NewAllBidsModal({ isLoading, isOpen, setIsOpen, allbidsData }) {
  // const [isLoading, setIsLoading] = useState(true);

  // setTimeout(() => {
  //   setIsLoading(false);
  // }, 2000);
  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='px-4 py-6'>
        <div className='flex flex-col gap-8'>
          <section className='flex justify-center'>
            <motion.button
              whileTap={{ scale: 0.95 }}
              className='bg-white flex gap-2.5 items-center hover:bg-whisper200 text-primaryColor text-xs border border-primaryColor rounded-[10px] py-3  px-8 mt-2'
              onClick={() => setIsOpen(false)}
            >
              Go back to previous Bid details
              <PAIconArrowUpGreen />
            </motion.button>
          </section>

          <section className='flex flex-col gap-6'>
            <header className=' text-center font-bold'>
              All Bid Offers for{" "}
              <span className='text-primaryColor'>
                {allbidsData?.bidResponses[0]?.invoiceReference}
              </span>
            </header>

            <main className=' px-3'>
              {isLoading ? (
                <ul>
                  <CardLoading />
                  <CardLoading />
                  <CardLoading />
                  <CardLoading />
                  <CardLoading />
                  <CardLoading />
                  <CardLoading />
                  <CardLoading />
                </ul>
              ) : (
                <TransitionOpacity>
                  {allbidsData?.bidResponses?.map((bidOffers) => (
                    <li className='flex justify-between shadow-sm cursor-pointer hover:bg-whisper200  items-center text-xs py-1.5 mb-2'>
                      <span className='flex items-center gap-2'>
                        <PAIconSabModel />

                        <p>{bidOffers?.buyerCompanyName}</p>
                      </span>

                      <span>N{bidOffers?.bidAmount}.00</span>

                      <span>{bidOffers?.discountRate}%</span>
                      <span>2 days ago</span>
                    </li>
                  ))}
                </TransitionOpacity>
              )}

              {/* 
                <li className='flex justify-between shadow-sm cursor-pointer hover:bg-whisper200  items-center text-xs py-1.5 mb-2'>
                  <span className='flex items-center gap-2'>
                    <PAIconSabModel />

                    <p>ABC Limited</p>
                  </span>

                  <span>N986,049</span>

                  <span>2.5%</span>
                  <span>2 days ago</span>
                </li>

                <li className='flex justify-between shadow-sm cursor-pointer hover:bg-whisper200  items-center text-xs py-1.5 mb-2'>
                  <span className='flex items-center gap-2'>
                    <PAIconSabModel />

                    <p>ABC Limited</p>
                  </span>

                  <span>N986,049</span>

                  <span>2.5%</span>
                  <span>2 days ago</span>
                </li>

                <li className='flex justify-between shadow-sm cursor-pointer hover:bg-whisper200  items-center text-xs py-1.5 mb-2'>
                  <span className='flex items-center gap-2'>
                    <PAIconSabModel />

                    <p>ABC Limited</p>
                  </span>

                  <span>N986,049</span>

                  <span>2.5%</span>
                  <span>2 days ago</span>
                </li>

                <li className='flex justify-between shadow-sm cursor-pointer hover:bg-whisper200  items-center text-xs py-1.5 mb-2'>
                  <span className='flex items-center gap-2'>
                    <PAIconSabModel />

                    <p>ABC Limited</p>
                  </span>

                  <span>N986,049</span>

                  <span>2.5%</span>
                  <span>2 days ago</span>
                </li> */}
            </main>
          </section>
        </div>
      </div>
    </Drawer>
  );
}

export default NewAllBidsModal;
