import { PAIconWallet } from "assets/images/svgs";
import React from "react";
import { useNavigate } from "react-router-dom";

// Dummy data array
const dummyInvoices = [
  {
    id: 1,
    companyName: "Nestle Plc",
    amount: "NG 450,000",
    invoice: "INV-2202",
    status: "View",
  },
  {
    id: 2,
    companyName: "Coca Cola",
    amount: "NG 350,000",
    invoice: "INV-2203",
    status: "View",
  },
  {
    id: 3,
    companyName: "PepsiCo",
    amount: "NG 300,000",
    invoice: "INV-2204",
    status: "View",
  },
  {
    id: 4,
    companyName: "Unilever",
    amount: "NG 400,000",
    invoice: "INV-2205",
    status: "View",
  },
  {
    id: 5,
    companyName: "Procter & Gamble",
    amount: "NG 500,000",
    invoice: "INV-2206",
    status: "View",
  },
];

const LockedInvoice = () => {
  const navigate = useNavigate(); 

  const handleInvoiceClick = (id) => {
    let path = `portfolioinvoice/${id}`;
    navigate(path); 
  };

  return (
    <div className="bg-white rounded-md px-3 py-2">
      <header className="flex items-center justify-between border border-t-0 border-l-0 border-r-0 border-b-1 border-gray mt3 mb-2 pb-2">
        <h5 className="font-bold text-sm text-[#1D0220] my-3 mb-2">
          Locked Invoices
        </h5>
        <h5 className="underline font-semibold text-[12px] text-[#1D0220]">
          View all
        </h5>
      </header>
      {dummyInvoices.map((invoice) => (
        <div
          key={invoice.id}
          className="flex flex-wrap justify-between cursor-pointer border border-t-0 border-l-0 border-r-0 border-b-1 border-gray mb-2 pb-2"
          onClick={() => handleInvoiceClick(invoice?.id)}
        >
          <div className="flex flex-row items-center">
            <div className="bg-[#376130] p-2 rounded-md">
              <PAIconWallet />
            </div>
            <div className="ml-2">
              <h3 className="font-semibold text-sm text-[#1D0220]">
                {invoice.companyName}
              </h3>
              <span className="block text-xs italic text-[#5F4D61]">
                {invoice.amount}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <h3 className="font-semibold text-sm text-[#1D0220]">
              Invoice | {invoice.invoice}
            </h3>
            <span className="block text-xs italic text-[#5F4D61]">
              {invoice.status}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LockedInvoice;
