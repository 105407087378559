import { useKeycloak } from "@react-keycloak/web";
import { allRoles, createRole } from "appstate/roleManager/roleManagerSlice";
import { PAIconSmallClose } from "assets/images/svgs";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "shared/Spinner/Spinner";

function AddNewRoleModal({ toggleNewRoleOff, page, pageSize }) {
  const { isLoading } = useSelector((state) => state?.roleManager);
  const { keycloak } = useKeycloak();
  const id = keycloak?.tokenParsed?.sub;
  const dispatch = useDispatch();
  const search = "";
  const formik = useFormik({
    initialValues: {
      roleName: "",
      selectedRole: "#",
    },
    onSubmit: async (values) => {
      if (values.selectedRole === "#" || values.roleName === "") {
        toast.error("Fields cannot be left empty");
      } else {
        const body = {
          roleName: values.roleName,
          reportingTo: values.selectedRole,
        };
        dispatch(createRole(body)).then((data) => {
          if (data?.payload?.success) {
            toast.success("Role created successfully");
            dispatch(allRoles({ page, pageSize, search }));
          }
        });
        setTimeout(() => {
          toggleNewRoleOff();
        }, 1200);
      }
    },
  });

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          delay: 0.3,
        },
      }}
      onClick={() => toggleNewRoleOff()}
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[9999] flex justify-center items-center"
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          scale: 0,
          transition: {
            delay: 0.3,
          },
        }}
        className={`bg-white w-[35%] h-[55%] overflow-auto rounded-lg z-[9999]`}
      >
        <motion.div>
          <motion.div
            exit={{
              x: 20,
              opacity: 0,
              transition: {
                duration: 0.3,
              },
            }}
            className="z-[9999]  h-full flex flex-col justify-start w-full gap-8 pt-[3%] px-[4%] "
          >
            <div className="w-full sticky bg-white top-0 z-[999999] flex px-4 py-4 border-b-[3px] border-[#F6F5FA] justify-between">
              <span className=" font-bold ">
                <em>Add new role</em>
              </span>
              <span
                onClick={() => toggleNewRoleOff()}
                className="cursor-pointer gap-2 flex items-center justify-center "
              >
                <PAIconSmallClose />
                <span className=" text-[#565656] text-[14px]">Close</span>
              </span>
            </div>

            <div className="h-full overflow-auto">
              <div className="w-full h-4/5 rounded overflow-auto ">
                <div className="overflow-auto flex flex-col gap-8">
                  <div className=" w-full flex flex-col gap-4 px-4">
                    <form
                      onSubmit={formik.handleSubmit}
                      className="flex flex-col gap-2 pb-10"
                    >
                      <label className=" text-sm" htmlFor="email">
                        Role Name
                      </label>
                      <input
                        className=" w-full text-sm text-[#7a7a7a] px-4 py-3 rounded outline-[#7a7a7a] border border-[#7a7a7a] "
                        type="text"
                        name="roleName"
                        id="roleName"
                        placeholder="Enter role name"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      <label className=" text-sm" htmlFor="role-dropdown">
                        Report to
                      </label>
                      <select
                        className=" w-full text-xs text-[#7a7a7a] px-4 py-3 rounded outline-[#7a7a7a] border border-[#7a7a7a] "
                        name="selectedRole"
                        id="selectedRole"
                        onChange={formik.handleChange}
                        value={formik.values.selectedRole}
                      >
                        <option className=" py-3" value="#">
                          Select role this role is reporting to
                        </option>
                        {/* <option value='member'>Member</option>
                        <option value='manager'>Manager</option> */}
                        <option value={id}>Admin</option>
                      </select>

                      <div className=" flex pt-6  justify-end">
                        <button
                          disabled={isLoading}
                          type="submit"
                          className="flex items-center justify-end text-white rounded-[5px] bg-[#07593D] px-6 py-3"
                        >
                          {isLoading ? (
                            <Spinner />
                          ) : (
                            <span className=" px-2 text-sm">Create</span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default AddNewRoleModal;
