import {
  PAIconConfirm,
  PAIconInfo,
  PAIconMDISuccess,
} from "assets/images/svgs";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ConfirmModal from "./ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { createPaymentExtension } from "appstate/paymentExtension/paymentExtensionSlice";

const SingleRequestModalContent = ({ onClose, data }) => {
  const dispatch = useDispatch();
  // const { earlyPaymentLoading, approvedInvoiceId } = useSelector(
  //   (state) => state?.supplier
  // );
  const { isLoading, associatedFunderId } = useSelector(
    (state) => state?.paymentExtension
  );
  const [enableDiscount, setEnableDiscount] = useState(false);
  const [applied, setApplied] = useState(false);
  const [open, setOpen] = useState(false);
  const [newPaymentDate, setNewPaymentDate] = useState("");
  const [description, setDescription] = useState("");

  const handleSetNewAmount = () => {
    setApplied(false);
    // setAmount("");
    // setDiscountedAmount("");
    setEnableDiscount(false);
  };

  function handleSendRequest() {
    const body = {
      invoiceIds: [data?.invoice?.id],
      discountRate: 0.05,
      description: description,
      newPaymentDate: newPaymentDate,
      newPaymentAmount: 1000.0,
      funderId: associatedFunderId,
    };

    dispatch(createPaymentExtension(body)).then((data) => {
      if (data?.payload?.success) {
        setOpen(false);
        toast.custom(
          <div className="flex mt-[200px]">
            <div className="bg-primaryColor w-[147.791px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
              <PAIconMDISuccess />
            </div>
            <div className="bg-white flex flex-col justify-center items-center w-[394.538px] h-[160px] rounded-r-[7px] shadow-lg">
              <PAIconConfirm />
              <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                Payment extension request successful
              </p>
            </div>
          </div>
        );
        onClose();
      }
    });
  }

  function handleOpenModal() {
    if (!description || !newPaymentDate) {
      toast.error("Description or new payment date are required.");
      return;
    }
    setOpen(true);
  }

  return (
    <>
      {open && (
        <div className="absolute mt-[20px] top-1/2 left-2/3 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <ConfirmModal
            hide={() => setOpen(false)}
            isLoading={isLoading}
            handleAssociate={handleSendRequest}
            warning="Would you like to proceed with the payment extension?"
          />
        </div>
      )}
      <div className="p-[39px]">
        <div className="flex justify-between">
          <div className="flex-1">
            <div className="flex items-center gap-x-[23px]">
              <h1 className="text-textColor text-[20px] font-bold">
                Invoice | INV-{data?.invoice?.invoiceNumber}
              </h1>
              <p className="text-textColor text-sm">
                Reference No: {data?.invoice?.invoiceReference}
              </p>
            </div>
            <div>
              <span className="text-textColor text-xs mr-[10px]">
                Created Date:{" "}
              </span>
              <span className="text-lightGray text-xs border-r pr-[10px]">
                {data?.invoice?.createdDate?.slice(0, 10)}
              </span>
              <span className="text-textColor text-xs ml-[10px]">
                Payment Due Date:{" "}
              </span>
              <span className="text-lightGray text-xs ml-[10px]">
                {data?.invoice?.dueDate}
              </span>
            </div>
          </div>
          <Button
            neutral
            type="Button"
            disabled={!newPaymentDate}
            onClick={handleOpenModal}
            className="border flex justify-center items-center border-[#AFAFAF] rounded-[5px] text-xs text-[#616161] w-[135px]  h-[30px]"
          >
            Send Request
          </Button>
        </div>
        <div className="mt-[25px]">
          <p className="text-textColor font-extrabold text-base italic">
            Invoice Amount
          </p>
          <h1 className="text-primaryColor text-2xl font-extrabold">
            NG-{data?.invoice?.total}
          </h1>
          <h1 className="text-textColor text-sm italic font-extrabold mt-[19px]">
            Payment Extension Request
          </h1>
        </div>
        <div className="mt-[20px]">
          <div className="flex justify-between">
            <InputField
              label="New Payment Date:"
              type="date"
              value={newPaymentDate}
              onChange={(e) => setNewPaymentDate(e.target.value)}
              className=" w-[369px] h-[39px] rounded-[5px] outline-none !border border-[#AFAFAF] px-[5px]"
            />
            <div>
              {!applied ? (
                <>
                  {!enableDiscount && (
                    <Button
                      onClick={() => setEnableDiscount(true)}
                      typeof="Button"
                      disabled={!newPaymentDate}
                      neutral
                      className="!text-secondaryColor text-sm mt-[5px] float-right"
                    >
                      Check for available discount
                    </Button>
                  )}
                  {enableDiscount && (
                    <div className="mt-[10px] flex justify-between">
                      <div>
                        <p className="text-textColor text-xs font-medium border-b border-[#AFAFAF] w-[84px]">
                          Interest:{" "}
                          <span className="text-[#17191C] text-xs">0.05</span>
                        </p>
                        <div className="flex items-center mt-[2px]">
                          <PAIconInfo />
                          <p className="text-[#F08000] text-[10px] font-medium">
                            Tap to view more
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-textColor text-xs">
                          New Invoice Amount:{" "}
                          <span className="text-primaryColor text-xs font-extrabold border-b border-[#AFAFAF] pb-[2px]">
                            1000.00
                          </span>
                        </p>
                        <div className="flex justify-end mt-[5px]">
                          <Button
                            neutral
                            onClick={() => setApplied(true)}
                            disabled={!newPaymentDate}
                            type="Button"
                            className="w-[87px] h-[24px] text-white bg-secondaryColor text-xs rounded-[5px]"
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-end">
                  <div>
                    <p className="text-textColor text-base italic font-extrabold">
                      New Invoice Amount
                    </p>
                    <h1 className="text-primaryColor text-2xl italic font-semibold text-right">
                      {data?.invoice?.total + 1000}
                    </h1>
                    <p className="text-secondaryColor text-sm italic font-semibold text-right line-through">
                      NGN {data?.invoice?.total}
                    </p>
                    <p className="text-secondaryColor text-xs text-right"></p>
                    <p
                      onClick={handleSetNewAmount}
                      type="Button"
                      className="text-secondaryColor text-xs text-right cursor-pointer"
                    >
                      Set New Amount
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-textColor mb-[10px] text-xs" htmlFor="">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border outline-none border-[#AFAFAF] w-full rounded-[5px] h-[67px] p-[10px]"
            ></textarea>
          </div>

          <div className="mt-[28px] border-b border-[#F6F5FA] pb-[12px]">
            <h1 className="text-textColor text-lg font-bold">
              Supplier/buyer details
            </h1>
          </div>
          <div className="flex justify-between mt-[16px]">
            <div>
              <p className="text-textColor text-sm italic font-extrabold">
                Supplier
              </p>
              <p className="text-lightGray text-xs">
                {data?.invoice?.supplierCompanyName}
              </p>
            </div>
            <div>
              <p className="text-textColor text-sm italic font-extrabold">
                Buyer
              </p>
              <p className="text-lightGray text-xs">
                {data?.invoice?.buyerCompanyName}
              </p>
            </div>
          </div>
          <div className="mt-[32px] border-b border-[#F6F5FA] pb-[12px]">
            <h1 className="text-[#11192A] text-sm font-bold">Line Items</h1>
          </div>
          <div className=" w-full overflow-x-auto">
            <table className=" w-full overflow-x-auto">
              <thead className="bg-subtleGray w-">
                <tr className=" bg-unusualGray p-2.5 ">
                  <td className="text-[#11192A] p-2.5 text-xs font-bold">
                    Tax rate
                  </td>
                  <td className=" p-2.5 text-xs font-bold">Item name</td>
                  <td className=" p-2.5 text-xs font-bold">
                    Product/Service Description
                  </td>
                  <td className=" p-2.5 text-xs font-bold">Quantity</td>
                  <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                  <td className=" p-2.5 text-xs font-bold">
                    Line Item Extended Amount
                  </td>
                </tr>
              </thead>
              {data?.invoice?.items?.map((item, index) => (
                <tbody key={index}>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.taxRate}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.itemName}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.description}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.quantity}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.unitPrice}
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      {item?.amount}
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleRequestModalContent;
