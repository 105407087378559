import Modal from "layout/modal/Modal";
import React from "react";
import AccountModal from "./AccountModal";

function AccountModalContainer({
  isShown,
  onClose,
  handleAssociate,
  warning,
  isLoading,
  handleChange,
  success,
  info,
  pfp
}) {
  return (
    <Modal contrast isShown={isShown} hide={onClose} accountModal>
      <AccountModal
        isLoading={isLoading}
        success={success}
        info={info}
        hide={onClose}
        handleAssociate={handleAssociate}
        handleChange={handleChange}
        warning={warning}
        pfp
      />
    </Modal>
  );
}

export default AccountModalContainer;
