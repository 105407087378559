import React from "react";

const FundersDetail = () => {
  return (
    <>
      <div className="px-[37px]  h-full ">
        <h2 className="text-sm font-semibold text-black mt-[32px] mb-[24px]">
          Personal Information
        </h2>
        <div>
          <p className="text-sm font-normal text-lightGray mb-[8px]">Name</p>
          <p className="text-sm font-normal text-textColor mb-[16px]">
            {"Cooper Cooperation Ltd"}
          </p>
          <p className="text-sm font-normal text-lightGray mb-[8px]">Email</p>
          <p className="text-sm font-normal text-textColor mb-[16px]">
            {"www.skyflockltd.com"}
          </p>
          <p className="text-sm font-normal text-lightGray mb-[8px]">
            Contact number
          </p>
          <p className="text-sm font-normal text-textColor mb-[16px]">
            {"+2348974998387"}
          </p>
          <p className="text-sm font-normal text-lightGray mb-[8px]">
            Account Type
          </p>
          <p className="text-sm font-normal text-textColor">
            {"Open Market Funder"}
          </p>
        </div>
        <h2 className="text-sm font-semibold text-black mt-[32px] mb-[24px]">
          Company Information
        </h2>
        <div>
          <p className="text-sm font-normal text-lightGray mb-[8px]">
            Company Address
          </p>
          <p className="text-sm font-normal text-textColor mb-[16px]">
            {"4b Utomi Aire Ave, off Freedom way, Lekki Phase 1"}
          </p>
        </div>
      </div>
    </>
  );
};

export default FundersDetail;
