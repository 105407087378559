import { PAIconAlert24 } from "assets/images/svgs";
import React, { useState } from "react";
import UploadAgreementModal from "./UploadAgreementModal";
import Agreement from "../Agreement";
import RouteHeader from "../../RouteHeader";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { agreementValidationSchema } from "validation/agreement/agreementValidation";
import { toolsAndAgreementCreateAgreement } from "appstate/toolsAndAgreement/toolsAndAgreementSlice";
import Spinner from "shared/Spinner/Spinner";
import Button from "shared/Button";
import { useKeycloak } from "@react-keycloak/web";
import RecipientPartyModal from "./RecipientPartyModal";

const UploadAgreement = ({ getBack }) => {
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);
  const [isRecipientPartyModalOpen, setRecipientPartyModalOpen] =
    useState(false);
  const [checked, setChecked] = useState(false);
  const [getName, setGetName] = useState(null);
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const close = () => {
    setShowAgreement(true);
  };

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  const toggleRecipientPartyModal = () => {
    setRecipientPartyModalOpen(!isRecipientPartyModalOpen);
  };

  const handleRecipientEmailClick = (email) => {
    formik.setFieldValue("email", email);
    setRecipientPartyModalOpen(false);
  };

  const { isLoading, getUploadURL } = useSelector(
    (state) => state?.toolsAndAgreements
  );

  const formik = useFormik({
    initialValues: {
      agreementName: "",
      email: "",
      description: "",
      recipientEditAccess: false,
      recipientUpdateAccess: false,
      recipientSignatureStatus: false,
    },
    validationSchema: agreementValidationSchema,
    onSubmit: (values) => {
      const body = {
        name: values.agreementName,
        recipientEmailAddress: values.email,
        description: values.description,
        url: getUploadURL,
        initiatorName: getName,
        recipientUpdateAccess: values.recipientUpdateAccess,
        recipientEditAccess: values.recipientEditAccess,
        recipientSignatureStatus: values.recipientSignatureStatus,
        recipientUserId: keycloak?.tokenParsed?.sub,
      };
      dispatch(toolsAndAgreementCreateAgreement(body)).then((data) => {
        if (data?.payload?.success === true) {
          setUploadModalOpen(true);
        }
      });
    },
  });

  return (
    <>
      {showAgreement ? (
        <Agreement />
      ) : (
        <>
          <div className="px-7 mb-5">
            <RouteHeader
              Route1="Agreements"
              Heading="Upload agreement"
              handleBack={getBack}
            />
          </div>
          <h1 className="px-7 text-black font-bold text-lg tracking-wide mt-10">
            Upload agreement
          </h1>
          <div className="px-7 mt-5">
            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <h1 className="text-black font-SFProDisplay text-base font-medium">
                  Agreement Details
                </h1>
                <div className=" w-full">
                  <div className="mb-4">
                    <label
                      htmlFor="agreementName"
                      className="text-[#7A7A7A] font-SFProDisplay text-base font-normal"
                    >
                      Agreement Name
                    </label>
                    <input
                      type="text"
                      className="border border-[#CCC] hover:border-blue-300 outline-none rounded bg-[#FAFAFA] p-2 w-full mt-1"
                      placeholder="Enter Agreement Name"
                      id="agreementName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.agreementName}
                    />
                    {formik.touched.agreementName &&
                    formik.errors.agreementName ? (
                      <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                        {formik.errors.agreementName}
                      </p>
                    ) : null}
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="recipientEmail"
                      className="text-[#7A7A7A] font-SFProDisplay text-base font-normal"
                    >
                      Email of Recipient Party
                    </label>
                    <input
                      type="email"
                      className="border border-[#CCC] hover:border-blue-300 outline-none rounded bg-[#FAFAFA] p-2 w-full mt-1"
                      placeholder="Enter Recipient's Email"
                      id="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onClick={toggleRecipientPartyModal}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                        {formik.errors.email}
                      </p>
                    ) : null}
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="agreementDescription"
                      className="text-[#7A7A7A] font-SFProDisplay text-base font-normal"
                    >
                      Agreement Description
                    </label>
                    <textarea
                      className="border border-[#CCC] hover:border-blue-300 outline-none rounded bg-[#FAFAFA] p-2 w-full mt-1"
                      placeholder="Enter Agreement Description"
                      rows="4"
                      id="description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                    ></textarea>
                    {formik.touched.description && formik.errors.description ? (
                      <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                        {formik.errors.description}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* AGREEMENT PERMISSIONS */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <h1 className="text-black font-SFProDisplay text-base font-medium">
                  Agreement Permissions
                </h1>
                <div className="w-full">
                  <div className="">
                    {/* First Div */}
                    <div className="flex items-center mb">
                      <label
                        htmlFor="updateAgreement"
                        className="text-Black font-SFProDisplay text-base font-medium"
                      >
                        <input
                          type="checkbox"
                          id="recipientUpdateAccess"
                          className="mr-2 accent-green-500 "
                          name="recipientUpdateAccess"
                          checked={formik.values.recipientUpdateAccess}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          // defaultChecked
                        />
                        Give all parties access to update agreement
                      </label>
                    </div>
                    <p className="text-[#7A7A7A] font-SFProDisplay text-xs font-normal ml-5">
                      Only initiating party would have update access if left
                      unchecked
                    </p>

                    {/* Second Div */}
                    <div className="flex items-center mt-2">
                      <label
                        htmlFor="editAgreement"
                        className="text-Black font-SFProDisplay text-base font-medium"
                      >
                        <input
                          type="checkbox"
                          id="recipientEditAccess"
                          className="mr-2 accent-green-500"
                          name="recipientEditAccess"
                          checked={formik.values.recipientEditAccess}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          // defaultChecked
                        />
                        Give all parties access to edit agreement details
                      </label>
                    </div>
                    <p className="text-[#7A7A7A] font-SFProDisplay text-xs font-normal ml-5">
                      Only initiating party would have edit access if left
                      unchecked
                    </p>
                  </div>
                </div>
              </div>

              {/* SIGNATURE */}
              <div className="mb-10">
                <h1 className="text-black font-SFProDisplay text-base font-medium mb-2">
                  Signature
                </h1>
                <div className="flex items-center mb-2">
                  <label
                    htmlFor="agreeAgreement"
                    className="text-[#7A7A7A] font-SFProDisplay text-sm font-normal cursor-pointer"
                    onClick={handleChange}
                  >
                    <input
                      type="checkbox"
                      id="recipientSignatureStatus"
                      className="mr-2 accent-green-500"
                      name="recipientSignatureStatus"
                      checked={formik.values.recipientSignatureStatus}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    I agree to this agreement
                  </label>
                </div>
                <div className="flex items-center justifycenter mt-2 gap-2">
                  <PAIconAlert24 />
                  <p className="text-orange-500 text-xs font-bold flex">
                    By selecting "I agree to this agreement," you are hereby
                    acknowledging your agreement to the aforementioned contract,
                    establishing a binding agreement between yourself and the
                    counterparty. You may opt to execute the contract either
                    immediately or at such time as the other party has completed
                    their review or formalized their signature.
                  </p>
                </div>
              </div>
              {/* BUTTONS */}
              <div className="flex items-center justify-center gap-x-8 mb-10">
                <Button
                  plain
                  type="Submit"
                  className="bg-[#07593D] text-white rounded-md hover:bg-[#07593ee6] p-2 px-4"
                  disabled={isLoading ? isLoading : !checked}
                >
                  {isLoading ? <Spinner /> : "Upload agreement"}
                </Button>
              </div>
            </form>
            {/* modal */}
            <UploadAgreementModal isOpen={isUploadModalOpen} onClose={close} />
            <RecipientPartyModal
              isOpen={isRecipientPartyModalOpen}
              onClose={toggleRecipientPartyModal}
              onEmailClick={handleRecipientEmailClick}
              setGetName={setGetName}
            />
          </div>
        </>
      )}
    </>
  );
};

export default UploadAgreement;
