import { PAIconSpinner } from "assets/images/svgs";
import React from "react";
import styled from "styled-components";

function Spinner({ color }) {
  return (
    <SpinnerContainer>
      <PAIconSpinner className={`spinner ${color && "stroke-primaryColor"}`} />
    </SpinnerContainer>
  );
}

export default Spinner;

const SpinnerContainer = styled.span`
  .spinner {
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
