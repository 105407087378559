import { COLORS } from 'constants/colors';
import { FONTSIZES, FONTWEIGHTS } from 'constants/font-spec';
import styled from 'styled-components';

export const DeleteWrapper = styled.div`
  padding-bottom: 2rem;
`;

export const WarningWrapper = styled.div`
  padding: 37px 30px 10px 30px;
`;

export const WarningText = styled.p`
  font-size: 18px;
  font-weight: ${FONTWEIGHTS.medium};
  color: ${COLORS['shim-black']};
  line-height: 25px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 2px;
  gap: 16px;
  margin-right: 20px;
  
  .save-btn {
    width: 91px;
    height: 45px;
    border: 1px solid ${COLORS.red};
    border-radius: 3px;
    font-weight: ${FONTWEIGHTS.xbold};
    font-size: ${FONTSIZES.small};
    background-color: ${COLORS.red};
    color: white;
  }
  .submit-btn {
    width: 91px;
    height: 41px;
    border-radius: 3px;
    font-weight: ${FONTWEIGHTS.xbold};
    font-size: ${FONTSIZES.small};
    background-color: #34a853;
    color: white;
  }
`;

export const SelectContainer = styled.div`
  min-width: 180px;
  margin: 10px 0 5px 0;
  @media screen and (max-width: 470px) {
    min-width: auto;
  }
`;
