/* eslint-disable react-hooks/exhaustive-deps */
import {
  PAIconConfirm,
  //   PAIconInfo,
  PAIconDownload,
  PAIconMDISuccess,
  PAIconModelOne,
  PAIconCocacola,
} from "assets/images/svgs";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ConfirmModal from "./ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { directFundingRequest } from "appstate/supplier/supplierSlice";

const SingleRequestModalContentBuyer = ({ onClose, data }) => {
  const dispatch = useDispatch();
  const { directFundingLoading, approvedInvoiceId, associatedFunderId } =
    useSelector((state) => state?.supplier);

  const mockData = {
    requestAmount: "10000",
    newPaymentDate: "2024-12-31",
    description: "Requesting early payment due to project deadlines.",
  };

  //   const [enableDiscount, setEnableDiscount] = useState(false);
  //   const [applied, setApplied] = useState(false);
  //   const [amount, setAmount] = useState(mockData.requestAmount);
  //   const [discountedAmount, setDiscountedAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [newPaymentDate, setNewPaymentDate] = useState(mockData.newPaymentDate);
  const [description, setDescription] = useState(mockData.description);

  const discountRate = 1.67;

  //   useEffect(() => {
  //     handleAmountChange({ target: { value: amount } });
  //     setEnableDiscount(true);
  //   }, []);

  //   const handleAmountChange = (event) => {
  //     const value = event.target.value;
  //     setAmount(value);

  //     const parsedAmount = parseFloat(value);
  //     if (!isNaN(parsedAmount)) {
  //       const discountedValue =
  //         parsedAmount - (parsedAmount * discountRate) / 100;
  //       setDiscountedAmount(discountedValue.toFixed(2));
  //     } else {
  //       setDiscountedAmount("");
  //     }
  //   };

  //   const handleSetNewAmount = () => {
  //     setApplied(false);
  //     setAmount("");
  //     setDiscountedAmount("");
  //     setEnableDiscount(false);
  //   };

  function handleSendRequest() {
    const body = {
      invoiceIds: [approvedInvoiceId],
      discountRate: discountRate,
      description: description,
      newPaymentDate: newPaymentDate,
      //   requestedAmount: parseFloat(amount),
      funderId: associatedFunderId,
    };
    // console.log(body);
    dispatch(directFundingRequest(body)).then((data) => {
      if (data?.payload?.success) {
        setOpen(false);
        toast.custom(
          <div className="flex mt-[200px]">
            <div className="bg-primaryColor w-[147.791px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
              <PAIconMDISuccess />
            </div>
            <div className="bg-white flex flex-col justify-center items-center w-[394.538px] h-[160px] rounded-r-[7px] shadow-lg">
              <PAIconConfirm />
              <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                Direct funding request successfully sent.
              </p>
            </div>
          </div>
        );
        onClose();
      }
    });
  }

  function handleOpenModal() {
    if (!description || !newPaymentDate) {
      toast.error("Description or new payment date are required.");
      return;
    }
    setOpen(true);
  }

  return (
    <>
      {open && (
        <div className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0">
          <div className="absolute mt-[20px] transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
            <ConfirmModal
              hide={() => setOpen(false)}
              isLoading={directFundingLoading}
              handleAssociate={handleSendRequest}
              warning="Would you like to proceed with sending the Fund request?"
            />
          </div>
        </div>
      )}
      <div className="p-[39px]">
        <div className="flex justify-between">
          <div className="flex-1">
            <div className="flex items-center gap-x-[23px]">
              <h1 className="text-textColor text-[20px] font-bold">
                Invoice | INV-{data?.invoiceNumber || "INV-2002"}
              </h1>
              <p className="text-textColor text-sm">
                Reference No: {data?.invoiceReference || "123484BH"}
              </p>
            </div>
            <div>
              <span className="text-textColor text-xs mr-[10px]">
                Created Date:{" 23 Dec 2024"}
              </span>
              <span className="text-lightGray text-xs border-r pr-[10px]">
                {data?.createdDate}
              </span>
              <span className="text-textColor text-xs ml-[10px]">
                Payment Due Date:{" 23-Dec-2024"}
              </span>
              <span className="text-lightGray text-xs ml-[10px]">
                {data?.dueDate}
              </span>
            </div>
          </div>
          <Button
            neutral
            type="Button"
            // disabled={!amount}
            onClick={handleOpenModal}
            className="border flex justify-center items-center border-[#AFAFAF] rounded-[5px] text-xs text-[#616161] w-[135px]  h-[30px]"
          >
            Send Request
          </Button>
        </div>
        <div className="mt-[25px]">
          <p className="text-textColor font-extrabold text-base italic">
            Invoice Amount
          </p>
          <h1 className="text-primaryColor text-2xl font-extrabold">
            NG-{data?.total || "340000"}
          </h1>
          <h1 className="text-textColor text-sm italic font-extrabold mt-[19px]">
            Fund Request
          </h1>
        </div>
        <div className="">
          <div className="flex justify-between items-end">
            <InputField
              label="New Payment Date:"
              type="date"
              value={newPaymentDate}
              onChange={(e) => setNewPaymentDate(e.target.value)}
              className=" w-[369px] h-[39px] rounded-[5px] outline-none !border border-[#AFAFAF] px-[5px]"
            />
            <div>
              <div>
                <div className="text-right">
                  <h2 className="font-semibold">Discount Request Amount</h2>
                  <h1 className="text-[24px] text-[#07593D] font-semibold">
                    NGN 340,000
                  </h1>
                  <p className="text-[16px]">NGN 390,400</p>
                  <p className="text-textColor text-xs font-medium">
                    Discount:{" "}
                    <span className="text-[#17191C] text-xs">
                      {discountRate}%
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[27px]">
            <p className="text-textColor text-xs font-medium mb-[5px]">
              Description
            </p>
            <textarea
              className="w-full border border-[#AFAFAF] rounded-[5px] outline-none px-[5px]"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4"
            />
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-[18px] font-bold">Supplier/buyer details</h1>
            </header>
            <div className="flex  justify-between mb-5">
              <div className="flex gap-2">
                <PAIconCocacola />
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">Supplier</span>
                  <span className="text-[#979797]  text-xs">Testing Buyer</span>
                </div>
              </div>
              <div className="flex gap-2">
                <PAIconModelOne />
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">Buyer</span>
                  <span className="text-[#979797]  text-xs">Agba Dev</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Line Items</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Tax rate
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Item name</td>
                    <td className=" p-2.5 text-xs font-bold">
                      Product/Service Description
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                    <td className=" p-2.5 text-xs font-bold">
                      Line Item Extended Amount
                    </td>
                  </tr>
                </thead>
                {/* {invoiceData?.items?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxRate}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.quantity}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.unitPrice}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.amount}
                        </td>
                      </tr>
                    </tbody>
                  ))} */}
              </table>
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-5">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Attachments</h1>
            </header>
            <div className="w-full overflow-x-auto">
              <table className="w-full overflow-x-auto">
                <thead className="  bg-subtleGray w-">
                  <tr className="px-4  bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                      Document Name
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">
                      File Type
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">
                      Upload Date / Time
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                      DOC-29384.pdf
                    </td>
                    <td className=" text-xs text-[#11192A] px-4 py-2.5">PDF</td>
                    <td className=" text-xs text-[#11192A] px-4 py-2.5">
                      2021-09-14 13:25
                    </td>
                    <td className="cursor-pointer text-xs text-[#11192A] px-4 py-2.5">
                      <PAIconDownload />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleRequestModalContentBuyer;
