import {
  PAIconDoc,
  PAIconDropUser,
  PAIconEditlight,
  PAIconLink,
  PAIconOption,
} from "assets/images/svgs";
import Table from "layout/table/Table";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";

const DashboardTable = () => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const { transactions } = useSelector((state) => state.supplier);
  const handleDropdown = (row) => {
    if (dropdown === row.id) {
      setDropdown(null);
    } else {
      setDropdown(row.id);
    }
  };

  console.log(atBottom);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const column = [
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Company",
      selector: (row) => row?.company,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
    },
    {
      name: "Account Tier",
      selector: (row) => row?.accountTier,
    },
    {
      cell: (row) => (
        <div ref={dropdownRef}>
          <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
          {dropdown === row.id && row && (
            <div className="relative">
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && "bottom-[20px]"
                } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb-[12px]  pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconDropUser />
                  </span>
                  <p className="font-sm font-normal">View Details</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconDoc />
                  </span>
                  <p className="font-sm font-normal">Create Invoice</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconEditlight />
                  </span>
                  <p className="font-sm font-normal">Edit Tier</p>
                </div>
                <div className="border border-silver"></div>
                <div className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconLink />
                  </span>
                  <p className="font-sm font-normal text-[#DE1515]">
                    Disassociate
                  </p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  const recentTransactions = transactions?.data?.slice(0, 5);

  return (
    <>
      {!recentTransactions?.data ? (
        <>
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Recent Transactions
            </p>
          </div>
          <TableEmptyState dashboard />
        </>
      ) : (
        <Card className="rounded-[10px]">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Recent Transactions
            </p>
            <p className="text-black text-sm font-medium underline cursor-pointer">
              View All
            </p>
          </div>
          <div className="px-[24px] pb-[24px]">
            <Table
              columns={column}
              data={recentTransactions}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default DashboardTable;
