import React from "react";
import OpenMarketTable from "./Table";
import { PAIconFilter, PAIconOutlineSearch } from "assets/images/svgs";

const OpenMarketRequest = () => {
  return (
    <div>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-5">
          <select
            className="border border-gray shadow-sm rounded-[5px] p-2 text-sm focus:outline-none mr-2"
            onChange={(e) => {
              // const selectedSortBy = e.target.value;
            }}
          >
            <option value="">Sort by</option>
            <option value="date">Date</option>
            <option value="name">Name</option>
          </select>
          <button className="bg-[#07593D] p-2 text-white rounded-md">
            Go to Open Market
          </button>
        </div>
        <div className="flex md:col-span-1 justify-end">
          <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-lg px-2 lg:w-[300px] bg-white">
            <div className="items-center flex">
              <PAIconOutlineSearch />
              <input
                className="bg-transparent p-2 w-full focus:outline-none text-sm"
                type="text"
                placeholder="Search for request"
              />
            </div>
            <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
              <PAIconFilter className="bg-[#2FA06A]" />
            </div>
          </div>
          <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx-3 items-center gap-2 text-white">
            <PAIconFilter /> Filter
          </button>
        </div>
      </div>
      <OpenMarketTable />
    </div>
  );
};

export default OpenMarketRequest;
