import React from "react";

const WalletCard = ({ amount, label, info, percentage }) => {
  const totalColor =
    label === "Total Partial Payment"
      ? "text-borderColor"
      : label === "Liened Amount"
      ? "text-borderColor"
      : label === "Total Withdrawal"
      ? "text-secondaryColor"
      : label === "Total Deposit"
      ? "text-primaryColor"
      : "text-black";
  return (
    <>
      <div className="bg-white  py-[18px] px-[28px] rounded-[5.184px] shadow-md 2xl:w[300px]">
        <p className="text-[10px] font-semibold text-ebony">{label}</p>
        <h1 className={`${totalColor} text-[24px] font-semibold py-[13px]`}>
          {amount}
        </h1>
        <p className="text-[8px] text-gray800 font-semibold">
          <span className="text-ebony text-[ 7.714px] font-semibold">
            +{percentage}%{" "}
          </span>
          {info}
        </p>
      </div>
    </>
  );
};

export default WalletCard;
