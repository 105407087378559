import {
  PAIconAddRole,
  PAIconEditMain,
  PAIconSearch,
  PAIconTrashMini,
} from "assets/images/svgs";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Capsule from "./WorkflowConfig/components/Capsule/Capsule";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import toast from "react-hot-toast";
import EditAssignedProcessesModal from "./WorkflowConfig/components/EditAssignedProcessesModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllWorkflow,
  modifyWorkflowStatus,
} from "appstate/workflow/workflowSlice";
import LoadingScreen from "shared/LoadingScreen";

const WorkFlowConfig = ({ viewDetails, setViewDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteWorkflow, setDeleteWorkflow] = useState(false);
  const [editAssignedModal, setEditAssignedModal] = useState(false);

  useEffect(() => {
    dispatch(getAllWorkflow());
  }, [dispatch]);

  const { isLoading, getAllWorkflowData } = useSelector(
    (state) => state?.workflow
  );

  const handleTrigger = (id, status) => {
    dispatch(
      modifyWorkflowStatus({
        id,
        status,
      })
    ).then((data) => {
      if (data?.payload?.message === "Workflow updated successfully") {
        dispatch(getAllWorkflow());
        toast.success("Workflow updated successfully");
      }
    });
  };
  return (
    <div className="px-6 py-8 w-full flex flex-col gap-5">
      <header className=" font-bold text-[18px] mb-5">
        Workflow Configuration
      </header>

      <main className="flex w-full flex-col gap-6">
        <section className=" rounded-t-[12px] bg-[#FAFAFA] py-4 px-6">
          <div className="flex justify-between">
            <motion.button
              onClick={() => navigate("workflow-config/add-workflow")}
              whileTap={{ scale: 0.95 }}
              className="flex bg-primaryColor items-center text-sm text-white gap-2 rounded-[5px] py-2 px-4"
            >
              <PAIconAddRole />
              <span className="hidden sm:inline-block">New Workflow</span>
            </motion.button>

            <div className="flex justify-between gap-2 w-[80%] sm:w-[55%]">
              <div className="p-2.5 w-[75%] border-[0.5px] bg-white  border-[#ccc] flex gap-2 rounded-[5px] ">
                <PAIconSearch />
                <input
                  type="text"
                  className=" #7A7A7A text-xs w-full outline-none"
                  placeholder="Search Workflow"
                />
              </div>

              <button
                style={{ background: "rgba(242, 242, 242, 0.95)" }}
                className="border-[0.5px] text-sm w-fit  border-[#ccc] rounded-[5px]  py-2 px-4"
              >
                Search
              </button>
            </div>
          </div>
        </section>

        <table className="w-full overflow-auto px-5">
          <thead className="py-3 bg-[#F9F9F9] border-t border-b border-[#F9F9F9] px-5">
            <tr>
              <th className="pl-5 p-2.5 text-start  py-4 text-[#11192A] text-xs font ">
                Workflow Name
              </th>
              <th className="text-start sm:block hidden p-2.5  py-4 text-[#11192A] text-xs font ">
                Description
              </th>
              <th className="text-start p-2.5  py-4 text-[#11192A] text-xs font ">
                Updated Date
              </th>
              <th className="text-start p-2.5  py-4 text-[#11192A] text-xs font ">
                Status
              </th>
              <th className="py-4 p-2.5 text-primaryColor text-xs font-normal"></th>
            </tr>
          </thead>

          {isLoading ? (
            <LoadingScreen />
          ) : (
            <tbody className="py-5 px-5 w-full text-xs bg-white">
              {getAllWorkflowData?.data?.map((workflow) => (
                <tr
                  key={workflow?.workflowId}
                  onClick={() => {
                    navigate(`workflow-config/${workflow?.workflowId}`);
                  }}
                  className="w-full cursor-pointer border-b-[0.7px] border-[#eee] py "
                >
                  <td className="pl-5 p-2.5 wfull">{workflow?.workflowName}</td>
                  <td className="p-2.5 sm:flex hidden">
                    {workflow?.workflowDescription}
                  </td>
                  <td className="p-2.5">2023-05-23</td>
                  <td className="px3 p-2.5">
                    {workflow?.enabled ? "Enabled" : "Disabled"}
                  </td>

                  <td className="flex p-2.5 gap-3">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditAssignedModal(true);
                      }}
                      className=" cursor-pointer "
                    >
                      <PAIconEditMain />
                    </span>

                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteWorkflow(true);
                      }}
                      className=" cursor-pointer "
                    >
                      <PAIconTrashMini />
                    </span>
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      className=" cursor-pointer "
                    >
                      <Capsule
                        isToggled={workflow?.enabled}
                        onToggle={() => {
                          handleTrigger(
                            workflow?.workflowId,
                            !workflow?.enabled
                          );
                        }}
                        width="30px"
                        height="18px"
                      />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </main>

      {/* Delete Workflow Modal */}
      <AnimatePresence>
        {deleteWorkflow && (
          <ViewDocumentModal
            onAction={() => toast.success("Workflow deleted successfully")}
            onClose={() => setDeleteWorkflow(false)}
          >
            <span className=" text-[#979797] text-center">
              Are you sure you would like to{" "}
              <span className=" text-red font-[500]">delete</span> this
              workflow?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
      <EditAssignedProcessesModal
        isOpen={editAssignedModal}
        setIsOpen={setEditAssignedModal}
      />
    </div>
  );
};

export default WorkFlowConfig;
