import RoleManagerDashboardContents from 'features/roleManager/Dashbaord';

const RoleManagerDashboardPage = () => {
  return (
    <>
      <RoleManagerDashboardContents />
    </>
  );
};

export default RoleManagerDashboardPage;
