import DashboardLayout from 'layout/dashboardlayout'
import React from 'react'
import Details from './details'

const NewSingleEarlyPaymentDetails = () => {
  return (
    <DashboardLayout
      section='Invoice'
      subHeader='See all invoices'
    >
      <Details />
    </DashboardLayout>
  )
}

export default NewSingleEarlyPaymentDetails