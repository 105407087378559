import React from 'react';
import { useFormik } from 'formik';
import { fundAccountValidationSchema } from 'validation/wallet/fundAccount';
import { useSelector } from 'react-redux';

const TransferInfo = ({ isOpen, onClose, onSubmit }) => {

  const selectedAccounts = useSelector((state) => state?.wallet?.selectedAccounts);
  const formik = useFormik({
    initialValues: {
      amount: '',
      narration: '',
    },
    validationSchema: fundAccountValidationSchema,
    onSubmit: (values) => {
      onSubmit(values, selectedAccounts);
    },
  });

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white p-5 px-5 rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Transfer Funds
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        <div>
          <form className="w-full px-7" onSubmit={formik.handleSubmit}>
            <p className="mb-4 text-[#222] font-sans text-sm font-bold">
              Transfer Information
            </p>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                From - Virtual Account
              </label>
              <input
                type="number"
                value={selectedAccounts[0]?.accountNumber || ''}
                readOnly
                className="border w-full py-2 px-3 border-t-0 border-x-0 text-gray focus:border-[#222] focus:outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                To - Virtual Account
              </label>
              <input
                type="number"
                value={selectedAccounts[1]?.accountNumber || ''}
                readOnly
                className="border w-full py-2 px-3 border-t-0 border-x-0 text-gray focus:border-[#222] focus:outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                Amount
              </label>
              <input
                type="number"
                name="amount"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.amount}
                className={`border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none ${
                  formik.touched.amount && formik.errors.amount
                    ? 'border-red'
                    : ''
                }`}
              />
              {formik.touched.amount && formik.errors.amount && (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik?.errors?.amount}
                </p>
              )}
            </div>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                Narration
              </label>
              <input
                type="text"
                name="narration"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.narration}
                className={`border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none ${
                  formik.touched.narration && formik.errors.narration
                    ? 'border-red'
                    : ''
                }`}
              />
              {formik.touched.narration && formik.errors.narration && (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik?.errors?.narration}
                </p>
              )}
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-10">
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-gray300 mx-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                >
                  Cancel
                </button>
              </div>
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]">
                <button
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                  type="submit"
                >
                  Confirm
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TransferInfo;
