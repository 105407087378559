import * as Yup from 'yup';
const updateCompanyDetailsSchema = Yup.object({
  registeredAddress: Yup.string().required('This field is required'),
  companyWebsite: Yup.string()
    .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, 'Please enter a valid URL')
    .required('This field is required'),
  companyPhoneNumber: Yup.number("Cannot contain letters").required('This field is required'),
  companyEmail: Yup.string().email("invalid Email").required("Email is Required"),
});

export { updateCompanyDetailsSchema };
