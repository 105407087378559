import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLockModalOpen: false,
  offerId: "",
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openLockModal: (state) => {
      state.isLockModalOpen = true;
    },
    closeLockModal: (state) => {
      state.isLockModalOpen = false;
    },
  },
});

export const { openLockModal, closeLockModal } = modalSlice.actions;

export default modalSlice.reducer;
