import React from 'react'
import LandingpageLayout from './components/LandinpageLayout'
import Banner from './Banner'
import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'
import SectionThree from './SectionThree'
import Footer from './Footer'

const LandingPageSections = () => {
  return (
    <LandingpageLayout>
        <Banner />
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <Footer />
    </LandingpageLayout>
  )
}

export default LandingPageSections