import React from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";

const FundingOffersTable = () => {

  const fundingOffersList = [
    {
      invoiceNumber: "INV2022001",
      buyer: "John Doe",
      invoiceDueDate: "2024-01-31",
      invoiceDate: "2024-01-01",
      currency: "USD",
      invoiceAmount: "$9,000.00",
      status: "Pending request",
    },
    {
      invoiceNumber: "INV2022002",
      buyer: "Jane Smith",
      invoiceDueDate: "2024-02-04",
      invoiceDate: "2024-01-05",
      currency: "USD",
      invoiceAmount: "$4,000.00",
      status: "Updated request",
    },
    {
      invoiceNumber: "INV2022001",
      buyer: "John Doe",
      invoiceDueDate: "2024-01-31",
      invoiceDate: "2024-01-01",
      currency: "USD",
      invoiceAmount: "$9,000.00",
      status: "Approved for payment",
    },
    {
      invoiceNumber: "INV2022002",
      buyer: "Jane Smith",
      invoiceDueDate: "2024-02-04",
      invoiceDate: "2024-01-05",
      currency: "USD",
      invoiceAmount: "$4,000.00",
      status: "Rejected request",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceNumber,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyer,
      sortable: true,
    },
    {
      name: "Invoice Due Date",
      selector: (row) => row.invoiceDueDate,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Approved for payment" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Rejected request" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "Updated request" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "Pending request" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  return (
    <>
      {!fundingOffersList.length ? (
        <div className="ml-3">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">Funding Offers</p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[24px] ml-3">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <div>
              <p className="text-black text-lg font-bold">Funding Offers</p>
              <span className="text-xs font-thin">
                List of all funding offers
              </span>
            </div>
          </div>
          <div className="px-[24px] pb-[24px]">
            <Table
              columns={columns}
              data={fundingOffersList}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
    </>
  );
};

export default FundingOffersTable;
