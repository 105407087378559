import Modal from "layout/modal/Modal";
import InvoiceModal from "./InvoiceModal";

function InvoiceModalContainer({ isShown, onClose, id }) {
  return (
    <Modal isShown={isShown} hide={onClose} showClose>
      <InvoiceModal id={id} onClose={onClose} />
    </Modal>
  );
}

export default InvoiceModalContainer;
