import React, { useState } from "react";
import { PAIconArrowLeftGreen } from "assets/images/svgs";
import Headers from "features/invoice/buyerInvoice/headers";
import Table from "layout/table/Table";
import { Link, useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import DashboardLayout from "layout/dashboardlayout";
import AcceptModal from "shared/AcceptModal";
import TransferInfo from "../single/transferInfo/transferInfo";
import BulkInvoicePayment from "./bulkInvoicePayment";
import TransferInfoPin from "../single/pin/pin";

const BuyerBulkDetails = () => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isTransferInfoModalOpen, setIsTransferInfoModalOpen] = useState(false);
  const [isTransferInfoPinOpen, setIsTransferInfoPinOpen] = useState(false);
  const [isBulkInvoicePaymentOpen, setIsBulkInvoicePaymentOpen] = useState(false);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handlePayment = () => {
    setIsAcceptModalOpen(true);
  };

  const dummyPaymentExtensionData = {
    data: [
      {
        invoiceIds: "12345",
        reference: "INV-001",
        invoiceDueDate: "2024-06-15",
        newRequestedDate: "2024-06-20",
        currency: "NGN",
        invoiceAmount: 83000,
        newPaymentAmount: 22000,
        discountRate: "5%",
        buyerCompanyName: "XYZ Ventures",
        funder: "Funder",
      },
      {
        invoiceIds: "67890",
        reference: "INV-002",
        invoiceDueDate: "2024-07-01",
        newRequestedDate: "2024-07-10",
        currency: "NGN",
        invoiceAmount: 123000,
        newPaymentAmount: 33000,
        discountRate: "3%",
        buyerCompanyName: "ABC Corp",
        funder: "Funder",
      },
    ],
  };

  const invoiceData = dummyPaymentExtensionData?.data;

  const invoice = (id) => {
    let path = `/reimbursement/invoice/${id}`;
    navigate(path);
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Reference
        </span>
      ),
      selector: (row) => row.reference,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Due Date
        </span>
      ),
      selector: (row) => row.invoiceDueDate,
      sortable: true,
    },
    {
      name: "Extension Date",
      selector: (row) => row.newRequestedDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.invoiceAmount}</p>,
    },
    {
      name: "Interest Amount",
      selector: (row) => row.newPaymentAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.newPaymentAmount}</p>,
    },
    {
      name: "Interest Percent",
      selector: (row) => row.discountRate,
      sortable: true,
    },
  ];

  return (
    <DashboardLayout
      section="Reimbursement"
      subHeader="Track invoices due for reimbursement"
    >
      {isAcceptModalOpen && (
        <AcceptModal
          isOpen={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          message={
            <>
              Would you like to Initiate Disbursement for this <br />
              Bulk request?
            </>
          }
          onConfirm={() => setIsTransferInfoModalOpen(true)}
        />
      )}
       {isTransferInfoModalOpen && (
        <TransferInfo
          isOpen={isTransferInfoModalOpen}
          onClose={() => setIsTransferInfoModalOpen(false)}
          onConfirm={() => setIsBulkInvoicePaymentOpen(true)}
        />
      )}
      {isBulkInvoicePaymentOpen && (
        <BulkInvoicePayment
          isOpen={isBulkInvoicePaymentOpen}
          onClose={() => setIsBulkInvoicePaymentOpen(false)}
          onConfirm={() => setIsTransferInfoPinOpen(true)}
        />
      )}
      {isTransferInfoPinOpen && (
        <TransferInfoPin
          isOpen={isTransferInfoPinOpen}
          onClose={() => setIsTransferInfoPinOpen(false)}
        //   onConfirm={() => setIsTransferInfoPinOpen(true)}
        />
      )}
      <div>
        <header className="rounded-md w-full bg-white mb-[17px] cursor-pointer items-center">
          <Link
            onClick={goBack}
            className="flex items-center justifycenter gap-x-[3px] mb-[20px] cursor-pointer py-3 ml-2 my-auto"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>
        </header>
        <Headers />
        {/* Table */}
        {!invoiceData?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Approved Payment Extension
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[90px] rounded-t-[10px] mt-3">
              <div className="py-5">
                <div className="text-black text-md font-bold mt5">
                  XYZ Ventures -{" "}
                  <span className="text-secondaryColor">(Funder)</span>
                </div>

                <div className="text-xs font-thin flex gap-8 mt-1">
                  <span className="text-[#07A04A]">
                    Request ref no. : R343t3xy
                  </span>
                  <span className="text-[#959595]">
                    2 invoices-
                    <span className="text-[#07593D] font-bold">
                      (Bulk Extension Requests)
                    </span>
                  </span>
                </div>

                <div className="text-xs font-thin flex gap-8 mt-1 mb5">
                  <span className="text-[#959595]">
                    Total invoice amount-
                    <span className="text-[#07593D] font-bold">
                      NGN 206,000
                    </span>
                  </span>
                  <span className="text-[#959595]">
                    Total interest amount-
                    <span className="text-[#07593D] font-bold">NGN 55,000</span>
                  </span>
                </div>
              </div>
              <div className="flex items-center my-5 gap-5 mt-5">
                <button
                  className="rounded-md bg-[#07593D] text-white px-6 py-2"
                  onClick={handlePayment}
                >
                  Initiate Disbursement
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <Table
                columns={columns}
                data={invoiceData}
                onRowClicked={(row) => invoice(row.invoiceIds)}
                pointer
                tableHeader
                className="mt-5"
              />
            </div>
          </Card>
        )}
      </div>
    </DashboardLayout>
  );
};

export default BuyerBulkDetails;
