import Card from "shared/Card";

function FunderInvoiceHeader({
  activeTab,
  setActiveTab,
  searchQuery,
  setSearchQuery,
}) {
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    sessionStorage.setItem("activeTabSupplier", tabNumber);
    // console.log(sessionStorage);
  };

  return (
    <Card className="px-2 bg-white rounded-t-[5px] h-16 flex items-center justify-between border-b border-b-subtleGray">
      <div className="flex h-full items-center overflow-x-auto space-x-3">
        <div
          className={`cursor-pointer relative h-full pt-5 text-sm overflow-hidden  text-[#959595] ${
            activeTab === 1 ? " text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(1)}
        >
          <span className="px-5 text-center flex justify-center w-full">
            All
          </span>

          {activeTab === 1 && (
            <div className="absolute bottom-0 left-0 w-full  h-1  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
        <div
          className={`cursor-pointer relative h-full pt-5 text-center text-sm overflow-hidden  text-[#959595] ${
            activeTab === 2 ? "text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(2)}
        >
          <span className="px-5 text-center flex justify-center w-full ">
            Pending Invoices
          </span>
          {activeTab === 2 && (
            <div className="absolute bottom-0 left-0 w-full h-1  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
        <div
          className={`cursor-pointer relative  h-full overflow-hidden  pt-5 text-sm text-[#959595] ${
            activeTab === 3 ? "text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(3)}
        >
          <span className="px-5 flex justify-center text-center w-full">
            Direct Funding
          </span>
          {activeTab === 3 && (
            <div className="absolute bottom-0 left-0 w-full h-1  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
        <div
          className={`cursor-pointer relative   h-full pt-5 overflow-hidden  text-sm text-[#959595] ${
            activeTab === 4 ? "text-primaryColor font-bold" : "text-gray-400"
          }`}
          onClick={() => handleTabClick(4)}
        >
          <span className="px-5 text-center flex justify-center  w-full">
            New Direct Funding requests
          </span>
          {activeTab === 4 && (
            <div className="absolute bottom-0 left-0 w-full h-1  rounded bg-primaryColor transition-transform duration-300 ease-in-out"></div>
          )}
        </div>
      </div>
    </Card>
  );
}

export default FunderInvoiceHeader;
