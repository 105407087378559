import {
  PAIconAgreementBag,
  PAIconAgreementTools,
  PAIconGroupAgreement,
  PAIconIllustration,
} from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import Graph from "./Graph";
import Header from "../Header";
import { useSelector } from "react-redux";


const Overview = () => {
  const [isAnimated, setIsAnimated] = useState(false);

  const {
    toolsAndAgreementDashboardData
   } = useSelector((state) => state?.toolsAndAgreements);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setIsAnimated(true);
    }, 200);

    return () => clearTimeout(animationTimeout);
  }, []);
  return (
    <>
      {/* HEADER */}
      <Header Title="Overview" showSearchInput={false} />

      {/* SECTION */}
      <div  className={`w-full flex flex-wrap lg:flex-row px-7 mb-5 transition-transform transform ${
          isAnimated ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
        } ease-in duration-500`}>
        {/* LEFT HAND SECTION */}
        <section className="w-full  lg:w-1/2 pr-5 gap-10">
          <div className="w-full lg:flex px7 gap-4">
            {/* LEFT HAND CARD */}
            <div className="w-full lg:mb5">
              {/* CARD 1 */}
              <div className="rounded-2xl border border-transparent bg-[#2FA06A] shadow-lg p-4 text-white mb-5">
                <div className="flex items-center gap-4">
                  <PAIconAgreementBag />
                  <div className="text-base font-normal leading-normal tracking-wide lg:text-xs">
                    <p>Total Agreement</p>
                    <div className="text-white text-2xl font-bold tracking-wide">
                     {toolsAndAgreementDashboardData?.data?.totalAgreements}
                    </div>
                  </div>
                </div>
              </div>
              {/* CARD 2 */}
              <div className="rounded-2xl border border-transparent bg-[#FFBE55] shadow-lg p-4 text-white">
                <div className="flex items-center gap-4">
                  <PAIconAgreementTools />
                  <div className="text-base font-normal leading-normal tracking-wide lg:text-sm">
                    <p>Tools Avaliable</p>
                    <div className="text-2xl font-bold tracking-wide">5</div>
                  </div>
                </div>
              </div>
            </div>
            {/* RIGHT HAND CARD */}
            <div className="w-full lg:w-1/2 mt-5 xl:mt-0">
              <div className="relative h-full">
                <div className="absolute hidden lg:block top-0 left-0 w-full h-full rounded-2xl border border-transparent bg-[#07593D] shadow-lg p-4 text-white" />
                <div className="flex lg:flex-col justify-center items-left rounded-2xl border border-transparent bg-[#F08000] shadow-lg p-4 text-white h-full lg:origin-top-left lg:rotate-[-5.56deg]">
                  <div className="text-white text-2xl font-bold tracking-wide mb-5 pr-10 lg:pr-0">
                  {toolsAndAgreementDashboardData?.data?.newUpdatedAgreements}
                  </div>
                  <div className="w-[123px] text-white text-base font-normal font-['SF Pro Display'] tracking-wide">
                    New Updated Agreements
                  </div>
                  <div className="w-[11px] h-[11px] bg-white rounded-full absolute top-0 left-0 m-3" />
                  <div className="absolute top-0 right-0 mt-2 mr-2">
                    <PAIconGroupAgreement className="w-12 h-12" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* TEXT */}
          <div className="mt-10 px-7">
            <h5 className="text-black font-bold text-lg tracking-wide lg:mb-5">
              Tools that Simplify
            </h5>
            <p className="text-gray-800 font-normal text-base leading-6 tracking-wide">
              "Our digital platform offers real-time financial insights,
              simplifying payables, receivables, supplier tracking, and informed
              decision-making."
            </p>
          </div>
        </section>

        {/* RIGHT HAND SECTON */}
        <section className="w-full lg:w-1/2 px7 ">
          <div className="flex items-center justify-center">
            <PAIconIllustration />
          </div>
          <div className="mt-3">
            <div className="rounded-2xl border border-transparent bg-white shadow-lg p-4 text-black  items-center justify-center">
              <div className="text-black font-bold text-lg tracking-wide flex items-center justify-center">
                Agreement Status
              </div>
              <div className="mt-5 flex items-center justify-center">
                <Graph />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Overview;
