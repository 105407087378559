import bgImage from "assets/images/svgs/frame.png";
import React from "react";

const BackgroundCard = ({ children, className }) => {
  return (
    <div
      className={className}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      {children}
    </div>
  );
};

export default BackgroundCard;
