import React from "react";
import infoGreen from "../../../../../assets/images/svgs/infoGreen.svg";
import blockIcon from "../../../../../assets/images/svgs/blockIcon.svg";
import { useNavigate } from "react-router-dom";

const ThirdPartyModal = ({ isOpen, onClose, fromBuyer }) => {
  const navigate = useNavigate();
  if (!isOpen) {
    return null;
  }
  const handleSingle = () => {
    let path = "/payablefinancing/payablefinace/singleearlypaymentdetails/approved/directfuning_funderlist";
    navigate(path, { state: { route: "single", fromBuyer } });
  };
  return (
    <div
      //   onClick={handleCloseConfirm}
      className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute top-0 left-0"
    >
      <div>
        <div className="flex flex-col justify-between items-center bg-white rounded-[8px] p-[5%] w-[600px]">
          <img className="mt-[3%]" src={infoGreen} alt="" />
          <h3 className="font-bold text-[18px] text-[#07593D] mt-[3%]">
            Third Party Funding
          </h3>
          <p className="text-center py-5">
            Please select your prefered third party method!
          </p>
          <div className="flex items-center">
            <div
              onClick={handleSingle}
              className="bg-[#07593D] mr-[5%] text-white rounded-[8px] flex justify-between items-end w-[270px] px-[5%] py-[2%]"
            >
              <p>
                Send invoice direct to <br />{" "}
                <span className="font-bold">Funder</span>
              </p>
              <img width={20} src={blockIcon} alt="" />
            </div>

            <div className="bg-[#07593D] text-white rounded-[8px] flex justify-between items-end w-[270px] px-[5%] py-[2%]">
              <p>
                Send invoice to <br />{" "}
                <span className="font-bold">Closed Market</span>
              </p>
              <img src={blockIcon} alt="" />
            </div>
          </div>
          <div className="mt-[6%] text-right w-[100%]">
            <button
              onClick={onClose}
              className="border-[#EE0816] text-[#EE0816] border-[1px] w-[120px] rounded-[4px] h-[45px]"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdPartyModal;
