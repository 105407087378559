import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import {
  PAIconDownload,
  PAIconDropdown,
  PAIconFilter,
  PAIconNotification,
  PAIconOutlineSearch,
  PAIconVieweye,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allPaymentExtension } from "appstate/paymentExtension/paymentExtensionSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";

const ApprovedExtensions = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllText, setSelectAllText] = useState("Select All");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  // const [successMessage, setSuccessMessage] = useState("");
  // const [tableType, setTableType] = useState("funder");
  const [selectedTab, setSelectedTab] = useState("single");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { paymentExtensionData, isLoading } = useSelector(
    (state) => state?.paymentExtension
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const handleSearch = () => {
    dispatch(allPaymentExtension({ page, pageSize, search: searchQuery }));
  };

  useEffect(() => {
    dispatch(
      allPaymentExtension({
        page,
        pageSize,
        search: searchQuery,
        bulk: selectedTab === "bulk",
        status: "Approved",
      })
    );
  }, [dispatch, page, pageSize, searchQuery, selectedTab]);

  // useEffect(() => setSuccessMessage(""), []);

  const formatAmount = (amount) => {
    if (!amount || typeof amount !== "string") return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleNotificationClick = (row) => {
    setSelectedInvoice(row);
    const isSelected = selectedRows.includes(row.invoiceIds);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRows, row.invoiceIds];
    }

    setSelectedRows(newSelectedRows);
    setIsAcceptModalOpen(true);
  };

  // const handleConfirm = () => {
  //   setIsAcceptModalOpen(false);
  //   setSelectedInvoice(null);
  //   const selectedInvoiceIds = selectedRows.map((id) => id);
  //   dispatch(
  //     remainder({ type: "directfunding", body: selectedInvoiceIds[0] })
  //   ).then((response) => {
  //     if (response?.payload?.status === 200 && response?.payload?.success) {
  //       setIsSuccessModalOpen(true);
  //       setSuccessMessage(response?.payload?.message);
  //     }
  //   });
  // };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const singleDetails = (id) => {
    let path = `/payment-extension/singleapprovedextension/${id}`;
    navigate(path);
  };

  const bulkDetails = (id) => {
    let path = `/payment-extension/bulkapprovedextension/${id}`;
    navigate(path);
  };

  // const bulkDetails = (id) => {
  //   let path = `/payment-extension/approvedextension/${id}/${id}`;
  //   navigate(path);
  // };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSendReminder = () => {
    setIsAcceptModalOpen(true);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      let allIds = [];
      if (selectedTab === "single") {
        allIds = singleRequestData.map((row) => row.id);
      } else {
        allIds = bulkRequestData.map((row) => row.invoiceIds);
      }
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    // console.log(selectedRows);
  }, [selectedRows]);

  const singleRequestData =
    paymentExtensionData?.data?.filter((item) => !item.bulk)?.slice(0, 3) || [];

  const bulkRequestData =
    paymentExtensionData?.data?.filter((item) => item.bulk)?.slice(0, 3) || [];

  // const singleRequestData = [
  //   {
  //     invoiceIds: "1",
  //     requestRefNo: "REQ-2022001",
  //     invoiceNumber: "INV-2022001",
  //     buyer: "ABC Company",
  //     dueDate: "2024-01-31",
  //     extensionDate: "2024-02-10",
  //     currency: "USD",
  //     invoiceAmount: "$9,000.00",
  //     interestAmount: "$500.00",
  //     interestRate: "5%",
  //   },
  //   {
  //     invoiceIds: "2",
  //     requestRefNo: "REQ-2022002",
  //     invoiceNumber: "INV-2022002",
  //     buyer: "XYZ Inc.",
  //     dueDate: "2024-02-04",
  //     extensionDate: "2024-02-15",
  //     currency: "USD",
  //     invoiceAmount: "$12,000.00",
  //     interestAmount: "$600.00",
  //     interestRate: "5%",
  //   },
  // ];

  // const bulkRequestData = [
  //   {
  //     invoiceIds: "1",
  //     requestRefNo: "REQ-2022003",
  //     buyer: "123 Company",
  //     createdDate: "2024-01-31",
  //     numOfInvoice: "2",
  //     currency: "USD",
  //     invoiceAmount: "$2,000.00",
  //     interestAmount: "$100.00",
  //     interestRate: "5%",
  //   },
  //   {
  //     invoiceIds: "2",
  //     requestRefNo: "REQ-2022004",
  //     buyer: "456 Corp.",
  //     createdDate: "2024-01-31",
  //     numOfInvoice: "2",
  //     currency: "USD",
  //     invoiceAmount: "$5,000.00",
  //     interestAmount: "$250.00",
  //     interestRate: "5%",
  //   },
  // ];

  const singleColumns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.invoiceIds)}
        />
      ),
      width: "50px",
    },
    {
      name: "Request Ref No",
      selector: (row) => row.requestRefNo,
      sortable: true,
    },
    {
      name: "Invoice Number",
      selector: (row) => row.invoiceNumber,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyer,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Extension Date",
      selector: (row) => row.extensionDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.invoiceAmount)}</p>
      ),
    },
    {
      name: "Interest Amount",
      selector: (row) => row.interestAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.interestAmount)}</p>
      ),
    },
    {
      name: "Interest Rate",
      selector: (row) => row.interestRate,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconVieweye
              className="accent-gray300 w-4"
              onClick={() => singleDetails(row.invoiceIds[0])}
            />
          }
          {
            <PAIconNotification
              className="accent-gray300 w-4"
              onClick={() => handleNotificationClick(row)}
            />
          }
          {<PAIconDownload className="accent-gray300 w-4" />}
        </div>
      ),
    },
  ];

  const bulkColumns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.invoiceIds)}
        />
      ),
      width: "50px",
    },
    {
      name: "Request Ref No",
      selector: (row) => row.requestRefNo,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyer,
      sortable: true,
    },
    {
      name: "Created",
      selector: (row) => row.createdDate,
      sortable: true,
    },
    {
      name: "No. of invoices",
      selector: (row) => row.numOfInvoice,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.invoiceAmount)}</p>
      ),
    },
    {
      name: "Interest Amount",
      selector: (row) => row.interestAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.interestAmount)}</p>
      ),
    },
    {
      name: "Interest Rate",
      selector: (row) => row.interestRate,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconVieweye
              className="accent-gray300 w-4"
              onClick={() => bulkDetails(row.invoiceIds)}
            />
          }
          {
            <PAIconNotification
              className="accent-gray300 w-4"
              onClick={() => handleNotificationClick(row)}
            />
          }
          {<PAIconDownload className="accent-gray300 w-4" />}
        </div>
      ),
    },
  ];

  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.invoiceIds);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.invoiceIds);
    } else {
      newSelectedRows = [...selectedRows, row.invoiceIds];
    }

    setSelectedRows(newSelectedRows);
  };

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          {/* FILTER */}
          <div>
            <div
              className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
              onClick={handleDropdownToggle}
            >
              Filter{" "}
              {selectedFilters.length > 0 && (
                <span className="bg-gray rounded-lg p-1 px-2">
                  {selectedFilters.length}
                </span>
              )}{" "}
              Selected <PAIconDropdown />
            </div>
            {isDropdownOpen && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                <label className="block">
                  <input
                    type="checkbox"
                    value="date"
                    checked={selectedFilters.includes("date")}
                    onChange={handleFilterChange}
                  />
                  Date
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="name"
                    checked={selectedFilters.includes("name")}
                    onChange={handleFilterChange}
                  />
                  Name
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="currency"
                    checked={selectedFilters.includes("currency")}
                    onChange={handleFilterChange}
                  />
                  Currency
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="amount"
                    checked={selectedFilters.includes("amount")}
                    onChange={handleFilterChange}
                  />
                  Amount
                </label>
              </div>
            )}
          </div>

          {/* EXPORT */}
          <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for request"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              <PAIconFilter /> Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!singleRequestData?.length && !bulkRequestData?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                List of all approved payment extension requests you've accepted
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-black text-lg font-bold">
                  Approved extension table
                </p>
                <span className="text-xs font-thin">
                  List of all approved payment extension requests you've
                  accepted
                </span>
              </div>
              <div className="flex items-center justify-center gap-3">
                <div
                  className="border border-gray300 rounded-md p-2 text-white bg-[#2FA06A] cursor-pointer"
                  onClick={handleSelectAll}
                >
                  <p>{selectAllText}</p>
                </div>
                <button
                  className={`border border-gray300 rounded-md p-2 text-white ${
                    selectedRows.length === 0
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={selectedRows.length === 0}
                  onClick={handleSendReminder}
                >
                  <p>Send Reminder</p>
                </button>
              </div>
            </div>

            <div className="pb-[24px]">
              <div className="flex px-[24px] gap-5 my-5">
                <p
                  className={`cursor-pointer font-bold relative ${
                    selectedTab === "single"
                      ? "text-[#F08000]"
                      : "text-[#959595]"
                  }`}
                  onClick={() => setSelectedTab("single")}
                >
                  Single Request
                  {selectedTab === "single" && (
                    <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                  )}
                </p>

                <p
                  className={`cursor-pointer font-bold relative ${
                    selectedTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                  }`}
                  onClick={() => setSelectedTab("bulk")}
                >
                  Bulk Request
                  {selectedTab === "bulk" && (
                    <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                  )}
                </p>
              </div>
              {selectedTab === "bulk" ? (
                <Table
                  columns={bulkColumns}
                  data={bulkRequestData}
                  onRowClicked={(row) => bulkDetails(row.invoiceIds)}
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              ) : (
                <Table
                  columns={singleColumns}
                  data={singleRequestData}
                  onRowClicked={(row) => singleDetails(row.invoiceIds)}
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              )}
              <div className="flex justify-center gap-x-[8px] mt-[20px]">
                {(singleRequestData?.data?.length > 0 ||
                  bulkRequestData?.data?.length > 0) && (
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={paymentExtensionData?.meta?.totalElements}
                  />
                )}
              </div>
            </div>
          </Card>
        )}
      </div>
      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            {selectedRows.length > 1 ? (
              <span>to all selected due invoices?</span>
            ) : (
              <>
                to due{" "}
                <span className="text-secondaryColor font-bold">
                  invoice INV-{selectedInvoice?.invoiceNumber}
                </span>
                ?
              </>
            )}
          </>
        }
        // onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        // message={successMessage}
      />
    </>
  );
};

export default ApprovedExtensions;
