/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  PAIconVieweye,
  PAIconMDISuccess,
  PAIconIconParkSucess,
  PAICalenderPickerIcon,
  PAIconMdiReload,
} from "assets/images/svgs";
import Table from "layout/table/Table";
import SettlementReportModal from "../components/modal/SettlementReport";
import FileFormatModal from "../components/modal/FileFormatModals";
import toast from "react-hot-toast";

const SettlementMain = () => {
  const [data, setData] = useState([]);
  const [settleopen, setSettleOpen] = useState(false);
  const [formatOpen, setFormatOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const onSettleContinue = () => {
    setSettleOpen(false);
    setFormatOpen(true);
  };
  const dummyTransactions = [
    {
      id: "1",
      transactionId: "2023-05-23",
      funderFullName: "09347T-2090",
      invoiceDate: "9,220,000",
      paymentDate: "200,000",
      currency: "200,000",
      amount: "+9,000,000",
      status: "SUCCESSFUL",
    },
    {
      id: "2",
      transactionId: "2023-05-23",
      funderFullName: "09347T-2090",
      invoiceDate: "9,220,000",
      paymentDate: "103,000",
      currency: "103,000",
      amount: "+4,000,000",
      status: "SUCCESSFUL",
    },
    {
      id: "3",
      transactionId: "2023-05-23",
      funderFullName: "09347T-2090",
      invoiceDate: "9,220,000",
      paymentDate: "100,000",
      currency: "100,000",
      amount: "+2,000,000",
      status: "SUCCESSFUL",
    },
    {
      id: "4",
      transactionId: "2023-05-23",
      funderFullName: "09347T-2090",
      invoiceDate: "9,220,000",
      paymentDate: "50,000",
      currency: "50,000",
      amount: "+2,000,000",
      status: "SUCCESSFUL",
    },
  ];

  const columns = [
    {
      name: <span className="whitespace-nowrap">Date</span>,
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction ID
        </span>
      ),

      selector: (row) => row.funderFullName,
      sortable: true,
    },
    {
      name: "Transaction Amount",
      selector: (row) => row.invoiceDate,
      sortable: true,
    },
    {
      name: "Transaction Fee",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Discount Fee",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Settled Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.amount}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "FAILED" ? (
          <p className="text-red-600">{row.status}</p>
        ) : row.status === "PENDING" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      //   name: "Details",
      selector: (row) => row.view,
      cell: (row) => (
        <div className="flex">
          <PAIconVieweye height="15px" width="30px" />
          <p className="text-[#626679] cursor-pointer">View</p>
        </div>
      ),
    },
  ];

  function handleDownloadSuccessful() {
    setFormatOpen(false);
    setDownloadOpen(false);
    toast.custom(
      <>
        <div className="flex-1 items-center justify-center flex  w-full h-full">
          <div className="flex mt-[200px]  ">
            <div className="bg-primaryColor w-[147.79px]  h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
              <PAIconMDISuccess />
            </div>
            <div className="bg-white  flex-col justify-center items-center self-stretch py-[25px] h-[160px] rounded-r-[7px] shadow-lg flex px-16 ">
              <PAIconIconParkSucess />
              <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                Download Complete
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  useEffect(() => {
    setData(dummyTransactions);
  }, [dummyTransactions]); //
  return (
    <>
      <SettlementReportModal
        isOpen={settleopen}
        onClose={() => setSettleOpen(false)}
        onContinue={onSettleContinue}
      />
      <FileFormatModal
        isShown={formatOpen || downloadOpen}
        onClose={() => {
          setFormatOpen(false);
          setDownloadOpen(false);
        }}
        onContinue={handleDownloadSuccessful}
      />
      <div>
        <div class=" text-base font-medium ">Settlement Period:</div>
        <div className="flex justify-between mt-2  flex-wrap gap-4">
          <div className=" flex justify-between space-x-2">
            <div class=" h-10 pl-2 pr-3 py-2 bg-white rounded flex-col justify-center items-start gap-2.5 inline-flex">
              <div class="justify-start items-center gap-3 inline-flex">
                <div class="w-6 h-6 relative">
                  {" "}
                  <PAICalenderPickerIcon />
                </div>
                <div class="text-neutral-600 text-base font-medium ">
                  _ / _ / _{" "}
                </div>
              </div>
            </div>

            {/* second */}

            <div class=" h-10 pl-2 pr-3 py-2 bg-white rounded flex-col justify-center items-start gap-2.5 inline-flex">
              <div class="justify-start items-center gap-3 inline-flex">
                <div class="w-6 h-6 relative">
                  <PAICalenderPickerIcon />
                </div>
                <div class="text-neutral-600 text-base font-medium ">
                  _ / _ / _{" "}
                </div>
              </div>
            </div>

            {/* third */}

            <button
              class="w-[91px] h-[41px] px-6 py-3 bg-[#07593D] rounded-[5px] justify-start items-center gap-2.5 inline-flex"
              onClick={() => {}}
            >
              <div class="text-white text-sm font-medium ">Search</div>
            </button>
          </div>

          {/* stops */}

          <div className=" ">
            <button
              class="h-[39px] p-2.5 bg-amber-600 rounded justify-center items-center gap-2.5 inline-flex sm:w-full"
              on
              onClick={() => setDownloadOpen(true)}
            >
              <div class="text-white text-base font-medium ">
                Download Report
              </div>
            </button>
          </div>
        </div>
        {/* flex box section */}
        <div className=" flex flex-row justify-between w-full my-7 flex-wrap gap-3 ">
          <div class="lg:w-[24%]   md:w-1/2  w-full h-[114px] px-[29px] py-[18px] bg-white rounded shadow  flex-col justify-between items-start inline-flex">
            <div class="flex-col justify-start items-start gap-[13px] flex">
              <div class="text-slate-900 text-[10px] font-normal ">
                Upcoming Settlement
              </div>
              <div class="justify-start items-center gap-1 inline-flex">
                <div class="justify-start items-center gap-[3px] flex border-r-[2px] px-2 border-r-[#F08000]">
                  <div class="justify-start items-center flex">
                    <div class="text-fuchsia-950 text-2xl font-bold ">
                      27,920
                    </div>
                  </div>
                </div>
                <div class="justify-start items-center gap-2 flex">
                  <div class="justify-start items-center flex">
                    <div class="text-neutral-600 text-xs font-normal ">
                      0 Transactions
                    </div>
                  </div>
                </div>
                <div class="w-3 h-3 relative ">
                  <PAIconMdiReload />
                </div>
              </div>
              <div class="justify-start items-center gap-[5px] inline-flex">
                <div class="h-3 flex-col justify-between items-start inline-flex">
                  <div class="text-neutral-500 text-[8px] font-normal ">
                    As on 20 Jan 2024, 10:20AM
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* second  */}
          <div class="lg:w-[24%]   md:w-1/2  w-full h-[114px] px-7 py-[18px] bg-white rounded shadow  flex-col justify-between items-start inline-flex">
            <div class="flex-col justify-start items-start gap-[13px] flex">
              <div class="text-slate-900 text-[10px] font-normal ">
                Settlement Amount
              </div>
              <div class="justify-start items-center gap-[3px] inline-flex">
                <div class="justify-start items-center flex">
                  <div class="text-black text-2xl font-bold ">83,820.00</div>
                </div>
              </div>
              <div class="justify-start items-center gap-[5px] inline-flex">
                <div class="text-slate-900 text-[7.71px] font-semibold ">0</div>
                <div class="h-3 flex-col justify-between items-start inline-flex">
                  <div class="text-neutral-600 text-[8px] font-normal ">
                    transactions settled
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  */}

          <div class="lg:w-[24%]   md:w-1/2  w-full  h-[114px] px-7 py-[18px] bg-white rounded shadow  flex-col justify-between items-start inline-flex">
            <div class="flex-col justify-start items-start gap-[13px] flex">
              <div class="text-slate-900 text-[10px] font-normal ">
                Total Transactions
              </div>
              <div class="justify-start items-center gap-[3px] inline-flex">
                <div class="justify-start items-center flex">
                  <div class="text-black text-2xl font-bold ">213,820.00</div>
                </div>
              </div>
              <div class="justify-start items-center gap-[5px] inline-flex">
                <div class="text-slate-900 text-[7.71px] font-semibold ">0</div>
                <div class="h-3 flex-col justify-between items-start inline-flex">
                  <div class="text-neutral-600 text-[8px] font-normal ">
                    refunds
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="lg:w-[24%]   md:w-1/2  w-full h-[114px] px-7 py-[18px] bg-white rounded shadow  flex-col justify-between items-start inline-flex">
            <div class="flex-col justify-start items-start gap-[13px] flex">
              <div class="text-slate-900 text-[10px] font-normal ">
                Total Deduction
              </div>
              <div class="justify-start items-center gap-[3px] inline-flex">
                <div class="justify-start items-center flex">
                  <div class="text-black text-2xl font-bold ">0.00</div>
                </div>
              </div>
              <div class="justify-start items-center gap-[5px] inline-flex"></div>
            </div>
          </div>
        </div>

        <div className="bg-white h-auto rounded-[10px]">
          <div className="mb-[33px]    bg-[#FAFAFA] rounded-t-[10px]">
            <div className="flex items-center justify-between pt-4  ">
              <div className="flex-col pl-[20px]  py-3">
                <p className="font-bold">All Transactions</p>
                <p className="text-[13px] text-[#626679]">
                  Transactions summary
                </p>
              </div>
            </div>
          </div>

          <Table
            columns={columns}
            data={data}
            pointer
            className="mt-5 px-4"
            progressPending={false}
            onRowClicked={() => setSettleOpen(true)}
          />
        </div>
      </div>
    </>
  );
};

export default SettlementMain;
