import { useState } from 'react';
import { useFormik } from "formik";
import Button from 'shared/Button';
import { useSelector } from "react-redux";
import InputField from 'shared/InputField';
import PhoneNumber from 'shared/PhoneNumber';
import Spinner from 'shared/Spinner/Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAIconArrowLeft } from 'assets/images/svgs';
import OnboardingLayout from 'features/onboarding/category/components/OnboardingLayout';
import { companyDetailsValidationSchema } from 'validation/funder/CompanyDetailsSchema';

const CooperateFunderCompany = () => {
  const [setChecked] = useState(false)
  const { isLoading } = useSelector((state) => state?.auth);
  const location = useLocation();
  const userRole = location?.state;
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      companyName: "",
      rcNumber: "",
      companyWebsite: "",
      email: "",
      phoneNumber: "",
      registeredAddress: "",
      companyTaxId: "",
    },
    validationSchema: companyDetailsValidationSchema,
    onSubmit: (values) => {
      const body = {
        companyName: values.companyName,
        rcNumber: values.rcNumber,
        companyWebsite: values.companyWebsite,
        email: values.email,
        phoneNumber: values.phoneNumber,
        registeredAddress: values.registeredAddress,
        companyTaxId: values.companyTaxId,
        role: userRole
      };
      return body
    },
  });

  const handleChange = (e) => {
    setChecked(e.target.checked)
  };

  const goback = () => {
    navigate(-1)
  }

  return (
    <OnboardingLayout>
      <div className="px-[20px] pt-[40px] h-[100%] md:px-[60px] overflow-auto">
        <PAIconArrowLeft className='cursor-pointer' onClick={goback} />
        <div className='flex flex-col justify-center items-center mt-[10px]'>
          <div className='mb-[30px] '>
            <h1 className="font-bold text-[28px] text-primaryColor text-center">
              Welcome to PayEdge Co-Operate Funder Onboarding
            </h1>
            <p className="font-semibold text-[20px] text-textColor text-center  mt-[10px]">
              Before you get started
            </p>
            <p className="font-normal text-sm text-textColor text-center">
              Please enter your details below
            </p>
          </div>
          <form onSubmit={formik.handleSubmit} className="flex justify-center flex-col">
            <div className='mb-[30px]'>
              <InputField
                className="py-[13px] outline-none  md:w-[424px]"
                placeholder="ABC"
                label="Registered Company Name"
                id="companyName"
                name="companyName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
              />
              {formik.touched.companyName && formik.errors.companyName ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyName}
                </p>
              ) : null}
            </div>
            <div className='mb-[30px]'>
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="Enter RC Number "
                label="Registered Company Number (RC Number)"
                id="rcNumber"
                name="rcNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.rcNumber}
              />
              {formik.touched.rcNumber && formik.errors.rcNumber ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.rcNumber}
                </p>
              ) : null}
            </div>
            <div className='mb-[30px]'>
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="www.abc.com"
                label="Company Website"
                id="companyWebsite"
                name="companyWebsite"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyWebsite}
              />
              {formik.touched.companyWebsite && formik.errors.companyWebsite ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyWebsite}
                </p>
              ) : null}
            </div>
            <div className='mb-[30px]'>
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="exampleforyou@info.com"
                label="Email Address"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.email}
                </p>
              ) : null}
            </div>
            <div className="mb-[30px]">
              <PhoneNumber
                id="phoneNumber"
                name="phoneNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.phoneNumber}
                </p>
              ) : null}
            </div>
            <div className='mb-[30px]'>
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="www.abc.com"
                label="Registered Address"
                id="registeredAddress"
                name="registeredAddress"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.registeredAddress}
              />
              {formik.touched.registeredAddress && formik.errors.registeredAddress ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.registeredAddress}
                </p>
              ) : null}
            </div>
            <div className='mb-[30px]'>
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="T-098765431"
                label="Company Tax ID"
                id="companyTaxId"
                name="companyTaxId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyTaxId}
              />
              {formik.touched.companyTaxId && formik.errors.companyTaxId ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyTaxId}
                </p>
              ) : null}
            </div>
            <div className="mb-[30px]">
              <p className="font-medium text-xs text-textColor mb-[10px]">
                Please confirm if this data is correct
              </p>
              <div>

                <label
                  className="flex font-normal text-xs text-textColor relative bottom-[3px] cursor-pointer"
                  onClick={handleChange}
                >
                  <input type="checkbox" className="accent-primaryColor mr-[10px]" />
                  <p>Yes, this data is correct</p>
                </label>
              </div>
            </div>
            <Button
              type="submit"
              auth
              disabled={isLoading}
              className="mb-[30px] w-full"
            >
              {isLoading ? <Spinner /> : "Next"}
            </Button>
          </form>
        </div>

      </div>
    </OnboardingLayout>
  );
};

export default CooperateFunderCompany;
