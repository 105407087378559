import React, { useEffect, useState } from "react";
import Spinner from "shared/Spinner/Spinner";
import { PAIconPaymnet } from "assets/images/svgs";
import { useDispatch, useSelector } from "react-redux";
import { allRecentActivitiesData } from "appstate/invoice/invoiceSlice";
import Pagination from "shared/Pagination";

const AllRecentActivitiesModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { recentActivitiesData, isLoading } = useSelector(
    (state) => state?.invoice
  );

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    dispatch(allRecentActivitiesData({ page, pageSize }));
  }, [dispatch, page, pageSize]);

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full max-h-[80vh] pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Recent Activities
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        <div className="overflow-y-auto max-h-[70vh] px-5">
          {isLoading ? (
            <Spinner color />
          ) : (
            recentActivitiesData?.data?.map((invoice, index) => (
              <div key={index} className="invoice-item text-sm gap5">
                <div className="flex justify-between py-3 items-center border-b-2 border-[#ECECEC]">
                  <div className="flex items-center">
                    <div className="bg-[#376130] p-3 rounded-lg mr-2">
                      <PAIconPaymnet className="w-4 h-4" />
                    </div>
                    <div>
                      <h2>{invoice?.message}</h2>
                      <p>{formatAmount(invoice?.amount)}</p>
                    </div>
                  </div>
                  <div>
                    <p className="font-bold">Invoice | {invoice?.reference}</p>
                    <p className="text-right">View</p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      <div className="flex justify-center gap-x-[8px] mt-[20px]">
        {recentActivitiesData?.data?.length >= 10 && (
          <Pagination
            page={page}
            startPage={startPage}
            setEndPage={setEndPage}
            endPage={endPage}
            setStartPage={setStartPage}
            setPage={setPage}
            totalPages={recentActivitiesData?.meta?.totalElements}
          />
        )}
      </div>
      </div>
    </div>
  );
};

export default AllRecentActivitiesModal;
