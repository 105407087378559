import { approvalStatus } from "appstate/paymentExtension/paymentExtensionSlice";
import { PAIconAlert24 } from "assets/images/svgs";
import SuccessModal from "features/invoice/buyerInvoice/newEarlyPaymentRequests/newSingleEarlyPayment/Details/successModal";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import SuccessModal from "./successModal";

const RejectModal = ({ isOpen, onClose }) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { selectedRowId } = useSelector((state) => state?.paymentExtension);
  
  const handleConfirm = () => {
    dispatch(approvalStatus({ id: selectedRowId, status: "Rejected", comment: "" })).then(
      (response) => {
        if (response?.payload?.success) {
          setIsSuccessModalOpen(true);
        }
      }
    );
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center px-10">
        <PAIconAlert24 />
        <p className="text-[#979797] text-center font-sans text-base font-normal leading-normal tracking-wide mb-3 my-5">
          Would you like to proceed with{" "}
          <span className="text-red font-semibold">closing</span> this payment
          extension request? <br />
          Doing so will remove it from your list of outstanding payment <br />
          extension requests
        </p>
        <div className="flex justify-center mt-3">
          <button
            className="px-5 py-2 mr-5 text-[#07593D] border border-[#07593D rounded-md"
            onClick={handleConfirm}
          >
            Yes
          </button>
          <button
            className="px-5 py-2 bg-red-500 border border-red text-red rounded-md"
            onClick={onClose}
          >
            No
          </button>
        </div>
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleCloseSuccessModal}
          message="Payment extension request closed!"
        />
      </div>
    </div>
  );
};

export default RejectModal;
