import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import { useSelector } from "react-redux";
import SupplierEarlyPaymentBulkInvoice from "./bulkInvoice";

const SupplierEarlyPaymentDashboard = () => {
  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const invoicePlayer = userRole === "supplier" ? <SupplierEarlyPaymentBulkInvoice /> : <></>;
  return (
    <DashboardLayout
      section="Payable Finance Program"
      subHeader="Here is an overview of your pfp"
    >
      {invoicePlayer}
    </DashboardLayout>
  );
};

export default SupplierEarlyPaymentDashboard;
