import request from "apiInstance";
import toast from "react-hot-toast";

export const getAllApprovedInvoices = async ({
  page,
  pageSize,
  status,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/supplier/invoices?page=${page}&size=${pageSize}&status=${status}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const earlyPaymentRequestSend = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/fund-request/early-payment",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
