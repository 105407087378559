import { getAllCreatedTiers } from "appstate/api/tier";
import {
  allBuyerAssociatedSuppliers,
  buyerSupplierDisassociate,
  getAssociatedSupplierId,
  onboardingInviteSupplier,
} from "appstate/buyer/buyerSlice";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Loading from "shared/Loading";
import AccountModalContainer from "shared/accountModal/Modal";
import InviteModal from "shared/inviteModal";
import TableHeader from "shared/tableHeader/TableHeader";
import NewPagination from "shared/newpagination";
import ThreeDots from "../../../../../assets/images/svgs/option.svg";
import CardDisassociation from "../../card/card";


const dummyData = [
  {
    id: 1,
    fullName: "John Doe",
    companyName: "Doe Enterprises",
    email: "johndoe@example.com",
    phoneNumber: "1234567890",
    status: "Active",
  },
  {
    id: 2,
    fullName: "Jane Smith",
    companyName: "Smith Corp",
    email: "janesmith@example.com",
    phoneNumber: "0987654321",
    status: "Inactive",
  },
  {
    id: 3,
    fullName: "Michael Johnson",
    companyName: "Johnson & Co",
    email: "michael.j@example.com",
    phoneNumber: "1122334455",
    status: "Active",
  },
  {
    id: 4,
    fullName: "Emily Davis",
    companyName: "Davis Solutions",
    email: "emilyd@example.com",
    phoneNumber: "5566778899",
    status: "Pending",
  },
  {
    id: 5,
    fullName: "Christopher Lee",
    companyName: "Lee Technologies",
    email: "chrislee@example.com",
    phoneNumber: "2233445566",
    status: "Inactive",
  },
  {
    id: 6,
    fullName: "Patricia Wilson",
    companyName: "Wilson Holdings",
    email: "patriciaw@example.com",
    phoneNumber: "6677889900",
    status: "Active",
  },
  {
    id: 7,
    fullName: "Robert Brown",
    companyName: "Brown Ventures",
    email: "robertb@example.com",
    phoneNumber: "9988776655",
    status: "Pending",
  },
  {
    id: 8,
    fullName: "Linda Clark",
    companyName: "Clark Innovations",
    email: "lindac@example.com",
    phoneNumber: "3344556677",
    status: "Active",
  },
  {
    id: 9,
    fullName: "David Miller",
    companyName: "Miller Industries",
    email: "davidm@example.com",
    phoneNumber: "7788990011",
    status: "Inactive",
  },
  {
    id: 10,
    fullName: "Susan Rodriguez",
    companyName: "Rodriguez Enterprises",
    email: "susanr@example.com",
    phoneNumber: "4455667788",
    status: "Active",
  },
];

const BuyerSuppliers = () => {
  // const dropdownRef = useRef();
  // const [dropdown, setDropdown] = useState(false);
  const [openDisassociateModal, setOpenDisAssociateModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [companyName, setCompanyName] = useState("");
  // eslint-disable-next-line
  const [getId, setGetId] = useState(null);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  // eslint-disable-next-line
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [invite, setInvite] = useState([]);
  const [newInvite, setNewInvite] = useState("");
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [atBottom, setAtBottom] = useState(false);
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [associatedSupplierData, setAssociatedSupplierData] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(null); 
  const [searchTerm, setSearchTerm] = useState("");
  const {
    // buyerAssociatedSuppliersData,
    isLoading,
    inviteLoading,
    disAssociateLoading,
  } = useSelector((state) => state?.buyer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  

  const handleThreeDotsClick = (rowId) => {
    setDropdownVisible(dropdownVisible === rowId ? null : rowId);
  };


  useEffect(() => {
    setAssociatedSupplierData(dummyData);
  }, []);

  const fetchTiers = async () => {
    const result = await getAllCreatedTiers();
    setData(result?.data || []);
  };

  useEffect(() => {
    fetchTiers();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   const search = companyName;
  //   dispatch(allBuyerAssociatedSuppliers({ page, pageSize, search }));
  // }, [dispatch, page, pageSize, companyName]);

  // useEffect(() => {
  //   if (companyName.length <= 0) {
  //     setAssociatedSupplierData(buyerAssociatedSuppliersData?.data);
  //   }
  //   const handleClickOutside = (event) => {
  //     if (
  //       dropdown &&
  //       dropdownRef.current &&
  //       !dropdownRef.current.contains(event.target)
  //     ) {
  //       setDropdown(false);
  //     }
  //   };

  //   const handleDocumentClick = (e) => {
  //     const screenHeight = window.innerHeight;
  //     const clickY = e.clientY;
  //     const bottomThreshold = 200;
  //     if (screenHeight - clickY <= bottomThreshold) {
  //       setAtBottom(true);
  //     } else {
  //       setAtBottom(false);
  //     }
  //   };

  //   document.addEventListener("click", handleDocumentClick);
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleDocumentClick);
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  //     // eslint-disable-next-line
  // }, [dropdown]);

  const onRowClicked = (row) => {
    let path = "/buyer-suppliers/supplierdetails";
    dispatch(getAssociatedSupplierId(row?.id));
    navigate(path);
  };

  const handleSearch = () => {
    const search = companyName;
    dispatch(allBuyerAssociatedSuppliers({ page, pageSize, search })).then(
      (data) => {
        setData(data?.payload?.data);
      }
    );
  };

  const handleDisAssociate = () => {
    const supplierId = getId;
    const search = "";
    dispatch(buyerSupplierDisassociate(supplierId)).then((data) => {
      if (data?.payload?.success === true) {
        setOpenDisAssociateModal(false);
        dispatch(allBuyerAssociatedSuppliers({ page, pageSize, search }));
      }
    });
  };

  const column = [
    {
      name: "NAME",
      selector: (row) => row?.fullName,
    },
    {
      name: "COMPANY",
      selector: (row) => row?.companyName,
    },
    {
      name: "EMAIL",
      selector: (row) => row?.email,
    },
    {
      name: "VENDOR ID",
      selector: (row) => row?.phoneNumber,
    },
    {
      name: "STATUS",
      selector: (row) => row?.phoneNumber,
    },
    {
      cell: (row) => (
        <div className="relative">
        <img
          src={ThreeDots}
          alt="Options"
          onClick={() => handleThreeDotsClick(row?.id)}
          className="cursor-pointer"
        />
        {dropdownVisible === row?.id && (
          <div className="absolute right-0 z-10 mt-2">
            <CardDisassociation />
          </div>
        )}
      </div>
      ),
    },
  ];

  // const totalPages = 50;

  // let pendingRequestPath = "buyer-pending-supplier-request";
  let pendingRequestPath = "buyer-association-supplier-request";

  const handleSubmitEmails = () => {
    const body = invite;
    dispatch(onboardingInviteSupplier(body)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(true);
        setInvite([]);
      }
    });
  };

  return (
    <DashboardLayout
      section="Suppliers"
      subHeader="Manage all your associated suppliers accounts"
    >
      <AccountModalContainer
        accontModal
        isShown={openDisassociateModal}
        onClose={() => {
          setOpenDisAssociateModal(false);
        }}
        isLoading={disAssociateLoading}
        success={success}
        handleAssociate={handleDisAssociate}
        warning="Are you sure you would like to disassociate with this member?"
      />
      <InviteModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        message=" The Supplier would received a mail that your are inviting to join
          PayEdge."
        heading="Invite Supplier"
        info="You can add one or more supplier at once with ease"
        role="Supplier"
        invite={invite}
        setInvite={setInvite}
        newInvite={newInvite}
        setNewInvite={setNewInvite}
        success={success}
        setSuccess={setSuccess}
        handleAdd={handleSubmitEmails}
        isLoading={inviteLoading}
      />
      <Card className="p-[24px] rounded-[10px]">
        <TableHeader
          pendingPath={pendingRequestPath}
          setProductName={setCompanyName}
          productName={companyName}
          search={handleSearch}
          fetchTiers={fetchTiers}
          btnName="Invite Supplier"
          funder
          accounts
          buyer
          tier={false}
          data={data}
          setOpen={setOpen}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          total="All Suppliers"
          details="Access contacts details of the associated suppliers"
        />
        <Table
          columns={column}
          data={associatedSupplierData}
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
        <br />
        <div className="">
          <NewPagination />
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default BuyerSuppliers;
