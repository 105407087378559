import Modal from 'layout/modal/Modal'
import React from 'react'
import UploadModalContent from './UploadModalContent'

const UploadModal = ({isShown, onClose, name, handleRemove}) => {
  return (
   <Modal accountModal isShown={isShown} hide={onClose}>
    <UploadModalContent name={name} handleRemove={handleRemove} />
   </Modal>
  )
}

export default UploadModal