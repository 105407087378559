import {
  allPurchaseOrders,
  changePoStatus,
  filterPurchaseOrderByStatus,
  resetPurchaseOrder,
  searchPo,
} from "appstate/purchaseOrder/poSlice";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import DeleteModal from "shared/DeleteModal/Modal";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import TableHeader from "shared/tableHeader/TableHeader";
import PoModal from "./modal";

const PurchaseOrderContents = () => {
  const { isLoading, allPOs, filterPoData, updateStatusLoading } = useSelector(
    (state) => state?.purchaseOrder
  );
  const [open, setOpen] = useState(false);
  const [getId, setGetId] = useState(null);
  const [status, setStatus] = useState("");
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [filter, setFilter] = useState(false);
  const [changeStatus, setChangeStatus] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [poDatas, setPoDatas] = useState(allPOs?.data);
  const [productName, setProductName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allPurchaseOrders({ page, pageSize }));
    localStorage.removeItem("selectedSupplier");
    dispatch(resetPurchaseOrder());
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    setPoDatas(allPOs?.data);
    if (filter) {
      setPoDatas(filterPoData?.data);
    }
  }, [allPOs, filterPoData, filter, productName]);

  const handleChange = (e) => {
    if (e.target.value !== "All") {
      dispatch(filterPurchaseOrderByStatus({ status: e.target.value }));
      setStatus(e.target.value);
    }
    setFilter(true);
    if (e.target.value === "All") {
      setFilter(false);
    }
  };

  const select = (id) => {
    setGetId(id);
    setChangeStatusModal(true);
  };

  const handleChangeStatus = () => {
    const body = {
      poId: getId,
      status: changeStatus,
    };
    dispatch(changePoStatus(body)).then((data) => {
      if (data?.payload?.success) {
        setChangeStatusModal(false);
        dispatch(allPurchaseOrders(page, pageSize));
        toast.success("Purchase order status change successfully");
        if (status) {
          dispatch(filterPurchaseOrderByStatus({ status: status }));
        }
      }
    });
  };

  function onRowClicked(row) {
    setOpen(true);
    setGetId(row.id);
  }

  const handleSearch = () => {
    dispatch(searchPo({ productName })).then((data) => {
      setPoDatas([data?.payload?.data]);
    });
  };

  const totalPage = filter === false ? allPOs : filterPoData;
  const totalPages = totalPage?.meta?.totalElements;

  const PURCHASETABLECOLUMN = [
    {
      name: "PO Number",
      selector: (row) => row?.purchaseOrderReference,
    },
    {
      name: "Supplier",
      selector: (row) => row?.supplierCompanyName,
    },
    {
      name: "Created Date",
      selector: (row) => row?.createdDate,
    },
    {
      name: "Payment Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      cell: (row) =>
        row.status === "open" ? (
          <p>{row.status}</p>
        ) : row.status === "Fulfilled" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Cancelled" ? (
          <p className="text-red-600">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <Button
          neutral
          onClick={() => select(row.id)}
          className="border border-silver bg-white text-black p-[10px] text-xs whitespace-nowrap active:scale-95 hover:bg-flashWhite rounded-[5px]"
        >
          Change status
        </Button>
      ),
    },
  ];
  let path = "createpo";
  const totalPos = poDatas?.length;
  const options = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Open",
    },
    {
      id: 3,
      name: "Fulfilled",
    },
    {
      id: 4,
      name: "Cancelled",
    },
    {
      id: 5,
      name: "Closed",
    },
  ];
  const statusHandleChange = (e) => {
    const selectedStatus = e.target.value;
    setChangeStatus(selectedStatus);
  };
  return (
    <DashboardLayout section="Purchase Order">
      <PoModal isShown={open} onClose={() => setOpen(false)} id={getId} />
      <DeleteModal
        isShown={changeStatusModal}
        handleDelete={handleChangeStatus}
        isLoading={updateStatusLoading}
        handleChange={statusHandleChange}
        changeStatus={changeStatus}
        onClose={() => setChangeStatusModal(false)}
        status
        options={options}
        warning="Select status:"
      />
      <div className="bg-white p-[24px] rounded-[5px]">
        <TableHeader
          total={`${totalPos} Purchase order`}
          options={options}
          handleChange={handleChange}
          setProductName={setProductName}
          productName={productName}
          search={handleSearch}
          path={path}
        />
        <Table
          columns={PURCHASETABLECOLUMN}
          data={poDatas}
          onRowClicked={onRowClicked}
          pointer
          progressPending={isLoading}
          progressComponent={<Loading />}
          tableHeader
          className="mt-5"
        />
        <div className="flex justify-center mt-[20px]">
          {isLoading || poDatas?.length < 20 ? null : (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PurchaseOrderContents;
