import { PAIconPencilLine } from "../../../assets/images/svgs";
import ProfilePic from "./ProfilePic";
import InputField from "shared/InputField";
import { useFormik } from "formik";
import { updateProfileSchema } from "validation/settings/UpdateProfileSchema";
import { funderUpdateProfileSchema } from "validation/settings/FunderUpdateProfileSchema";
import PhoneNumber from "shared/PhoneNumber";
import { useState } from "react";
import Button from "shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { updatePlayerProfile } from "appstate/settings/settingsSlice";
import { getCurrentUser } from "appstate/auth/authSlice";
import Spinner from "shared/Spinner/Spinner";
import { toast } from "react-hot-toast";

const Account = ({ currentUser }) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.settings);

  const formik = useFormik({
    initialValues: {
      firstName: currentUser?.firstName || "",
      lastName: currentUser?.lastName || "",
      fullName: currentUser?.fullName || "",
      email: currentUser?.email || "",
      phoneNumber: currentUser?.phoneNumber || "",
    },
    validationSchema:
      currentUser?.role.toLowerCase() === "open_funder"
        ? funderUpdateProfileSchema
        : updateProfileSchema,
    onSubmit: (values) => {
      const body =
        currentUser?.role.toLowerCase() === "open_funder"
          ? {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: values.phoneNumber,
            }
          : {
              fullName: values.fullName,
              email: values.email,
              phoneNumber: values.phoneNumber,
            };
      dispatch(
        currentUser?.role.toLowerCase() === "open_funder"
          ? updatePlayerProfile({ body, role: "funder" })
          : updatePlayerProfile({ body, role: currentUser?.role.toLowerCase() })
      ).then((data) => {
        if (data?.payload?.status === 200) {
          toast.success("Profile edited successfully!");
          dispatch(getCurrentUser(data?.payload?.data));
          setIsEditing(false);
        }
      });
    },
  });

  return (
    <div className="flex flex-col py-[30px] px-[25px] space-y-5">
      <ProfilePic currentUser={currentUser} />
      <div className="bg-gallery h-[1px] pb-1 w-full"></div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col">
          <div className="mb-5">
            <p className="font-bold">Personal Details</p>
          </div>
          <div className="flex justify-between flex-wrap gap-6">
            <div className="md:w-[350px] w-full flex flex-col gap-6">
              {currentUser?.role === "OPEN_FUNDER" ? (
                <div className="flex flex-col gap-6">
                  <div className="w-full">
                    <InputField
                      disabled={!isEditing}
                      className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                      type="text"
                      placeholder="Agba"
                      label="First Name"
                      name="firstName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                        {formik.errors.firstName}
                      </p>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <InputField
                      disabled={!isEditing}
                      className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                      type="text"
                      placeholder="Dev"
                      label="Last Name"
                      name="lastName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                        {formik.errors.lastName}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="w-full">
                  <InputField
                    disabled={!isEditing}
                    className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                    type="text"
                    placeholder="Agba Dev"
                    label="Full Name"
                    name="fullName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                      {formik.errors.fullName}
                    </p>
                  ) : null}
                </div>
              )}
              <div className="w-full">
                <PhoneNumber
                  disabled={!isEditing}
                  id="phoneNumber"
                  name="phoneNumber"
                  className="!w-full h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.phoneNumber}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="w-full md:w-[350px]">
              <InputField
                disabled={!isEditing}
                className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
                placeholder="exampleforyou@info.com"
                label="Email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.email}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="py-6">
          {isEditing ? (
            <div className="flex gap-2 justify-start">
              <Button
                type="button"
                onClick={() => setIsEditing(!isEditing)}
                className="flex !text-[14px] !px-6 !py-3 items-center bg-wildSand border-none !rounded-[10px] h-[40px]"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="flex !text-[14px] !px-6 !py-3 items-center !bg-emerald border-none !rounded-[10px] h-[40px] hover:bg-green-400"
              >
                {isLoading ? <Spinner /> : "Save"}
              </Button>
            </div>
          ) : (
            <p
              onClick={() => setIsEditing(!isEditing)}
              className="cursor-pointer inline-flex items-center px-4 py-1 gap-[2px] hover:bg-blue-50 text-gray-800 text-sm font-medium rounded-full border border-[#213874] -mt-3"
            >
              <PAIconPencilLine />
              Edit
            </p>
          )}
        </div>
      </form>
    </div>
  );
};

export default Account;
