import React from "react";
import { PAIconSmallClose } from "assets/images/svgs";
import { motion } from "framer-motion";
import MultiSelectComponent from "../MultiSelectedComponent";
import { useDispatch } from "react-redux";
import {
  getAllSupplierOpenMarketByStatus,
  updateBidStatus,
} from "appstate/openMarket/openMarketSlice";
import { useLocation } from "react-router-dom";
// import toast from "react-hot-toast";

const RejectBidReasonModal = ({
  onClose,
  setRejectBidReasonModal,
  setInvoiceDeclineSuccessModalOpen,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offerId = searchParams.get("offerId");
  const handleCloseDeleteModal = () => {
    onClose();
  };

  const handleSubmit = () => {
    dispatch(updateBidStatus({ offerId, status: "Rejected" })).then((data) => {
      if (data?.payload?.success) {
        setRejectBidReasonModal(false);
        setTimeout(() => {
          setInvoiceDeclineSuccessModalOpen(true);
        }, 50);

        dispatch(getAllSupplierOpenMarketByStatus({ status: "Pending" }));
      }
    });
  };

  return (
    <motion.div>
      <motion.div className='bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50'>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className='modal-overlay'
        ></motion.div>

        <motion.div
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className=' py-9 overflow-auto bg-white rounded-[10px] w-[60%] md:w-1/2 h-[87%] flex flex-col'
        >
          <header className='flex mx-6 sticky top-0 bg-white justify-between pb-4 border-b-[3px] border-[#F6F5FA]'>
            <span className=' text-[#222] font-bold'>Reason Required</span>

            <span
              onClick={() => handleCloseDeleteModal()}
              className='flex items-center cursor-pointer gap-2 text-sm font-[500] text-[#565656]'
            >
              <PAIconSmallClose />
              Close
            </span>
          </header>

          <div className='flex flex-col pt-2'>
            <div className='text-[#959595] px-6 py-4 text-sm text border-b-[2px]  border-[#F6F5FA] w-full flex justify-center bg-white'>
              Please provide a detailed explanation for rejecting the bid. Your
              insights assist in improving our platform and understanding user
              preferences. Thank you for your valuable feedback
            </div>

            <div className='flex flex-col gap-3 py-5 px-6 text-[#959595]'>
              <span className=' text-sm text-primaryColor font-[500]'>
                Input your reply or choose from our predefined responses
              </span>

              <input
                className=' rounded-[5px] text-[#959595] border border-primaryColor outline-none w-full pt-2 pb-7 px-5'
                type='text'
                // placeholder='Note to Supplier (optional)'
              />

              <div className=' flex gap-3 flex-wrap  mt-5'>
                <MultiSelectComponent />
              </div>

              <motion.button
                whileTap={{ scale: 0.9 }}
                onClick={() => handleSubmit()}
                className=' w-full mt-5 rounded-[5px] text-white bg-primaryColor px-6 py-3'
              >
                Submit
              </motion.button>
              {/* Congratulations! request Accepted. You've successfully agreed to
              the terms. Expect further details on the transaction shortly.
              Thank you! */}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default RejectBidReasonModal;
