import {
  allPermissionAssigned,
  allRoles,
  getRoleId,
} from "appstate/roleManager/roleManagerSlice";
import {
  PAIconAddRole,
  PAIconArrowLeftMini,
  PAIconSearch,
} from "assets/images/svgs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "shared/Pagination";

function ViewRoleManager({
  toggleRoleScreenOff,
  toggleNewRoleOn,
  toggleRoleFullDetailsOn,
  setSelectedId,
  page,
  setPage,
  pageSize,
}) {
  const dispatch = useDispatch();
  const { allRolesData } = useSelector((state) => state?.roleManager);
  const [data, setData] = useState([]);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const totalPages = allRolesData?.meta?.totalElements;
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const search = "";
    dispatch(allRoles({ page, pageSize, search }));
    dispatch(allPermissionAssigned());
  }, [dispatch, page, pageSize, searchQuery]);

  useEffect(() => {
    setData(allRolesData?.data?.data);
  }, [allRolesData]);

  const handleSearch = () => {
    const search = searchQuery;
    dispatch(allRoles({ page, pageSize, search }));
  };

  return (
    <div className=" h-full w-full">
      <div className="flex flex-col gap-6 bg-white rounded-lg w-full h-full">
        <>
          <ViewRoleManagerHeader
            toggleRoleScreenOff={toggleRoleScreenOff}
            toggleNewRoleOn={toggleNewRoleOn}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearch={handleSearch}
            data={data}
          />
          <ViewRoleManagerTable
            toggleRoleFullDetailsOn={toggleRoleFullDetailsOn}
            data={data}
            setSelectedId={setSelectedId}
          />
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </>
      </div>
    </div>
  );
}

function ViewRoleManagerHeader({
  data,
  toggleRoleScreenOff,
  toggleNewRoleOn,
  searchQuery,
  setSearchQuery,
  handleSearch,
}) {
  const handleAddRole = () => {
    toggleNewRoleOn();
  };
  return (
    <div className="flex bg-[#FAFAFA] rounded-t-lg justify-between px-6 py-4">
      <div className=" flex gap-2">
        <div
          onClick={() => {
            toggleRoleScreenOff();
          }}
          className="flex gap-1 pt-2.5 pr-2 text-[15px] md:pt-0  text-[#7A7A7A] md:items-center  cursor-pointer"
        >
          <PAIconArrowLeftMini />
        </div>
        <div className="hidden md:flex items-center">
          <p className="text-sm font-[500]">
            {data?.length} <span className=" hidden lg:inline">Available</span>{" "}
            Roles
          </p>
        </div>
      </div>

      <div className=" w-full md:w-auto flex flex-col md:flex-row gap-2 ">
        <div className="flex md:justify-between gap-4">
          <div className=" w-[85%] md:w-auto flex gap-2 pl-4 pr-2 md:pr-10 rounded-[5px] justify-start items-center bg-white">
            <PAIconSearch />
            <input
              className="py-2 outline-none pr-6"
              type="text"
              name="search"
              id="search"
              placeholder="Search roles"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>

          <button
            onClick={handleSearch}
            className="hidden lg:flex justify-center items-center px-6 py-3 text-sm rounded-[5px] font-[500] border-[0.5px] border-[#CCC]"
          >
            Search
          </button>
          <button
            onClick={handleSearch}
            className="flex justify-center items-center lg:hidden  px-3 py-3 text-sm rounded-[5px] font-[500] border-[0.5px] border-[#CCC]"
          >
            <PAIconSearch />
          </button>
        </div>

        <div className=" w-full md:w-auto justify-end  flex md:justify-start gap-3">
          <button
            onClick={() => {
              handleAddRole();
            }}
            className=" flex md:hidden lg:flex justify-center items-center bg-[#07593D] rounded-[5px] gap-1 text-white px-6 py-3 text-sm font-[500] "
          >
            <PAIconAddRole />
            <span>Add New Role</span>
          </button>
          <button
            onClick={() => {
              handleAddRole();
            }}
            className="flex sm:hidden md:flex lg:hidden justify-center items-center bg-[#07593D] rounded-[5px] gap-1 text-white px-4 py-3 text-sm font-[500] "
          >
            <PAIconAddRole />
            <span className=" hidden sm:block">Add</span>
          </button>
        </div>
      </div>
    </div>
  );
}

function ViewRoleManagerTable({
  toggleRoleFullDetailsOn,
  data,
  setSelectedId,
}) {
  const columns = [
    "Role Title",
    "Reporting To",
    "Created",
    "No of  Assigned Permissions",
  ];
  const dispatch = useDispatch();
  const handleViewFullDetails = (data) => {
    setSelectedId(data?.id);
    dispatch(getRoleId(data?.id));
    toggleRoleFullDetailsOn();
  };
  const NewTableHeader = ({ column }) => {
    return (
      <tr className="w-full  bg-[#EFEFEF] font-bold py-6 border-b border-t border-[#EEE] text-[14px]">
        {column.map((header, index) => (
          <td key={index} className="w-[10%] px-[30px]  py-[17px]">
            {header}
          </td>
        ))}
      </tr>
    );
  };

  const NewTableContent = ({ data }) => {
    return (
      <tbody>
        {data?.map((data, index) => (
          <tr
            onClick={() => {
              handleViewFullDetails(data);
            }}
            key={index}
            className="w-full cursor-pointer border-b border-t border-[#EEE] text-[14px]"
          >
            <td className="px-7 whitespace-nowrap  py-5">{data?.roleName}</td>
            <td className="px-7 whitespace-nowrap   py-5">
              {/* {data.reportingTo} */}
              Admin
            </td>
            <td className="px-7 whitespace-nowrap  py-5">
              {data?.createdDate.slice(0, 11)}
            </td>
            <td className="px-7 whitespace-nowrap  py-5">
              {data?.subPermissionIds?.length}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className="px-5 bg-white pt-1.5 w-full overflow-x-auto">
      <table className="overflow-x-auto w-full border-collapse">
        <thead>
          <NewTableHeader column={columns} />
        </thead>
        <NewTableContent data={data} />
      </table>
    </div>
  );
}

export default ViewRoleManager;
