import React, { useState } from "react";
import ReconciliationMain from "../reconciliation";
import SettlementMain from "../settlement";

const SettlementDashboard = () => {
  const [activeTab, setActiveTab] = useState("reconciliation");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "settlement":
        return <SettlementMain />;
      case "reconciliation":
        return <ReconciliationMain />;
      default:
        return null;
    }
  };
  return (
    <div>
      <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-md bg-white py-3">
        <div className="gap1 flex-col items-center justify-between gap-10 p-2 px-4 md:flex md:flex-row">
          <div className="flex-col gap-10 md:flex md:flex-row">
            <div
              className={
                activeTab === "reconciliation"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("reconciliation")}
            >
              Reconciliation
            </div>
            <div
              className={
                activeTab === "settlement"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("settlement")}
            >
              Settlement
            </div>
          </div>
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default SettlementDashboard;
