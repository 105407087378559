import React from "react";

import Button from "shared/Button";
import InputField from "shared/InputField";

const StepTwo = ({
  handleNextPage,
  handlePreviousPage,
  formData,
  handleInputChange,
  errors,
  handleChange,
  searchName,
  filteredData,
  storeData,
  handleSelect,
  handleRemoveEmail,
  isDropdownOpen,
  setIsDropdownOpen,
  selectedOptions,
  handleTiersChange,
  options,
}) => {
  return (
    <div>
      <div>
        <div className="mb-[20px]">
          <InputField
            labelFontSize
            type="number"
            label="Payment Duration:"
            id="Payment Duration"
            name="paymentDuration"
            placeholder="0 days"
            className="border border-[#7A7A7A] rounded-[4px] px-[16px] py-[12px] outline-none w-[500px] height-[50px]"
            onChange={handleInputChange}
            value={formData.paymentDuration}
          />
          {errors.paymentDuration && (
            <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
              {errors.paymentDuration}
            </p>
          )}
        </div>

        <div className="flex flex-col">
          <label
            htmlFor=""
            className="font-normal text-sm text-textColor mb-[10px]"
          >
            Tier Selection
          </label>
          <div
            className={`dropdown relative ${isDropdownOpen ? "open" : ""}`}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div
              className={`selected-options border border-gray-300 ${
                isDropdownOpen ? "rounded-t-[8px]" : "rounded-[8px]"
              } p-2 cursor-pointer flex justify-between items-center`}
            >
              {selectedOptions.length === 0 ? (
                <span className="text-sm">
                  What tier qualifies for program{" "}
                </span>
              ) : selectedOptions.length === 1 ? (
                <span className="text-sm">{selectedOptions[0].label}</span>
              ) : (
                <span className="text-sm">
                  {selectedOptions.length} Tiers selected
                </span>
              )}
              <svg
                className="h-4 w-4 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isDropdownOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 15l7-7 7 7"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                )}
              </svg>
            </div>
            {errors.tiers && (
              <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                {errors.tiers}
              </p>
            )}
            <div
              className={`dropdown-menu bg-white border flex flex-wrap border-gray-800 border-t-0 w-full z-10 ${
                isDropdownOpen ? "block" : "hidden"
              } transition-all duration-500 ${
                isDropdownOpen ? "ease-in" : "ease-out"
              }`}
            >
              {options.map((option) => (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="option"
                  key={option.value}
                >
                  <label className="block cursor-pointer p-2">
                    <input
                      type="checkbox"
                      checked={option.checked}
                      onClick={(e) => e.stopPropagation()}
                      id="tiers"
                      name="tiers"
                      value={formData.tiers}
                      onChange={() => handleTiersChange(option)}
                    />
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
            <p className="text-sm mt-[10px] mb-[10px]">
              Selected Option :
              {selectedOptions.map((option) => option.label).join(", ")}
            </p>
          </div>
        </div>
        <div>
          <label
            htmlFor=""
            className="font-normal text-sm text-textColor mb-[8px]"
          >
            Sharing ratio
          </label>

          <div className="flex justify-between items-center">
            <div>
              <InputField
                labelFontSize
                label="Buyer"
                type="number"
                id="buyerShare"
                name="buyerShare"
                placeholder="buyer share"
                value={formData.buyerShare}
                onChange={handleInputChange}
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border rounded-[8px] px-[16px] py-[12px] w-[235px] outline-none"
              />
            </div>
            <p className="text-primaryColor text-[20px] relative top-3"> :</p>
            <InputField
              labelFontSize
              label="Funder"
              placeholder="100% - BS"
              disabled
              className="border bg-[#EFEFEF] rounded-[8px] px-[16px] py-[12px] w-[235px] outline-none "
            />
          </div>
          {errors.buyerShare && (
            <p className="text-red text-xs relative top-2 mb-[10px]">
              {errors.buyerShare}
            </p>
          )}
        </div>
        <p className="text-gray800 text-sm font-[300] italic mb-[20px] mt-[1.12rem]">
          <span className="text-[#F08000] text-sm font-semibold">Note</span>:
          Sharing ratio is less service charge of 25%
        </p>
        <div>
          <InputField
            labelFontSize
            type="text"
            label="Invite Funder"
            placeholder="Search associated funders"
            value={searchName}
            onChange={handleChange}
            className="border rounded-[8px] px-[16px] py-[12px] outline-none mb-[10px]"
          />
          {searchName.trim() !== "" && (
            <div className="bg-[#EFEFEF] text-sm p-[10px] rounded">
              {filteredData?.map((funder, index) => (
                <div
                  className="cursor-pointer"
                  onClick={() => handleSelect(funder)}
                  key={index}
                >
                  <p>{funder?.fullName}</p>
                </div>
              ))}
            </div>
          )}
          {storeData?.map((funder, index) => (
            <div
              key={index}
              className="flex justify-between items-center mb-[18px]"
            >
              <p className="text-sm text-textColor">{funder.fullName}</p>
              <Button
                neutral
                onClick={() => handleRemoveEmail(index)}
                className="py-[8px] px-[16px] border-[1px] border-primaryColor !text-primaryColor text-xs rounded-[5px]"
              >
                Remove
              </Button>
            </div>
          ))}
          {errors.funderIds && (
            <p className="text-red text-xs  w-[300px] md:w-[424px]">
              {errors.funderIds}
            </p>
          )}
        </div>
      </div>
      <div className="flex justify-between mt-[1.88rem]">
        <Button
          type="Button"
          neutral
          className="!text-lightGray text-base !font-normal"
          onClick={handlePreviousPage}
        >
          Previous
        </Button>
        <Button
          type="Button"
          neutral
          onClick={handleNextPage}
          //   disabled={isNextDisabled}
          className="!text-white !font-normal bg-primaryColor py-[8px] px-[24px] rounded-[5px]"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default StepTwo;
