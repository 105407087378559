import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  PutAcceptOffer,
  PutIgnoreOffer,
  getAllPfpOffer,
  viewAllInvoicesInCampaign,
  viewCampaignInvoiceById,
  viewOfferById,
} from "appstate/api/supplierEarlyPayment/supplierEarlyPayment";

const initialState = {
  isLoading: false,
  ignoreMessage: null,
  acceptMessage: null,
  offerDataById: [],
  allPfpOfferData: [],
  campaignInvoiceByIdData: [],
  allInvoicesInCampaignData: [],
};

export const ignoreOffer = createAsyncThunk("ignoreOffer", PutIgnoreOffer);

export const acceptOffer = createAsyncThunk("acceptOffer", PutAcceptOffer);

export const viewOffer = createAsyncThunk("viewOffer", viewOfferById);

export const allPfpOffer = createAsyncThunk("allPfpOffer", getAllPfpOffer);

export const campaignInvoiceById = createAsyncThunk(
  "campaignInvoiceById",
  viewCampaignInvoiceById
);

export const allInvoicesInCampaign = createAsyncThunk(
  "allInvoicesInCampaign",
  viewAllInvoicesInCampaign
);

const supplierEarlyPaymentOfferSlice = createSlice({
  name: "supplierEarlyPaymentOffer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // IGNORE OFFER
    builder.addCase(ignoreOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ignoreOffer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ignoreMessage = action.payload;
    });
    builder.addCase(ignoreOffer.rejected, (state) => {
      state.isLoading = false;
    });

    // ACCEPT OFFER
    builder.addCase(acceptOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(acceptOffer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.acceptMessage = action.payload;
    });
    builder.addCase(acceptOffer.rejected, (state) => {
      state.isLoading = false;
    });

    // VIEW OFFER BY ID
    builder.addCase(viewOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewOffer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.offerDataById = action.payload;
    });
    builder.addCase(viewOffer.rejected, (state) => {
      state.isLoading = false;
    });

    // ALL PFP OFFER
    builder.addCase(allPfpOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allPfpOffer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPfpOfferData = action.payload;
    });
    builder.addCase(allPfpOffer.rejected, (state) => {
      state.isLoading = false;
    });

    // CAMPAIGN INVOICE BY ID
    builder.addCase(campaignInvoiceById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(campaignInvoiceById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.campaignInvoiceByIdData = action.payload;
    });
    builder.addCase(campaignInvoiceById.rejected, (state) => {
      state.isLoading = false;
    });

    // ALL CAMPAIGN INVOICE
    builder.addCase(allInvoicesInCampaign.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allInvoicesInCampaign.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allInvoicesInCampaignData = action.payload;
    });
    builder.addCase(allInvoicesInCampaign.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default supplierEarlyPaymentOfferSlice.reducer;
