import Modal from 'layout/modal/Modal'
import RequestConfirmationComp from './RequestConfirmation'

const RequestConfirmationModal = ({ isShown, onClose }) => {
  return (
    <Modal isShown={isShown} hide={onClose} showClose>
      <RequestConfirmationComp onClose={onClose} />
    </Modal>
  )
}

export default RequestConfirmationModal