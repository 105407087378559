import request from "apiInstance";
import toast from "react-hot-toast";

export const getAllFunderPendingPfp = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/pending-invites?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const acceptToJoinPfp = async ({ id }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/payable-finance/accept-invite/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const pfpRequestDecline = async ({ id }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/payable-finance/reject-invite/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const pfpViewById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/view/funder?pfpID=${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllFunderPfp = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/funder/pfp-list?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const sendRequestToLeavePfp = async ({ pfpId }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/payable-finance/exit-program?pfpID=${pfpId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getAllFunderPfpInvoices = async ({ page, pageSize, search }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/funder/pfp-invoices?page=${page}&size=${pageSize}&search=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
