export const config = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

export const keycloakProviderInitConfig = {
  redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI,
  checkLoginIframe: false,
  // onLoad: 'login-required',
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  enableLoggingOut: true,
  tokenStorage: 'sessionStorage',
};


