import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import OpenMarketHeader from "./components/OpenMarketHeader";
import AllInvoices from "./components/AllInvoices";
import AcceptedInvoice from "./components/AcceptedInvoices";
// import LockedInvoice from "./components/LockedInvoices";
import InvoiceSummary from "./components/InvoiceSummary";

function SupplierOpenMarket() {
  const [acceptedQuery, setAcceptedQuery] = useState("");

  // sessionStorage.getItem("searchQuery") || ""
  const [allInvoiceQuery, setAllInvoiceQuery] = useState("");
  // sessionStorage.getItem("searchQuery") || ""

  const [requestQuery, setRequestQuery] = useState("");
  // sessionStorage.getItem("searchQuery") || ""

  const [lockedInvoiceQuery, setLockedInvoiceQuery] = useState("Wale");

  const [activeTab, setActiveTab] = useState(
    parseInt(sessionStorage.getItem("activeTabSupplier")) || 1
  );

  return (
    <div className="flex gap-4 flex-col">
      <>
        {/* <div className=" w-full flex justify-between items-center rounded-[5px] py-2.5 pl-5 pr-2 bg-white ">
          <div>Open Market</div>

         
        </div> */}
        <OpenMarketHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          searchQuery={
            activeTab === 1
              ? allInvoiceQuery
              : activeTab === 2
                ? acceptedQuery
                : activeTab === 3
                  ? lockedInvoiceQuery
                  : requestQuery
          }
          setSearchQuery={
            activeTab === 1
              ? setAllInvoiceQuery
              : activeTab === 2
                ? setAcceptedQuery
                : activeTab === 3
                  ? setLockedInvoiceQuery
                  : setRequestQuery
          }
        />

        <main className="w-full">
          <AnimatePresence mode="wait">
            {/* {activeTab === 1 && <AllInvoices key='allInvoices' />}
            {activeTab === 2 && <AcceptedInvoice key='acceptedInvoice' />}
            {activeTab === 3 && <LockedInvoice key='lockedInvoice' />}
            {activeTab === 4 && <RequestInvoice key='requestInvoice' />} */}
            {activeTab === 1 ? (
              <AllInvoices allInvoiceQuery={allInvoiceQuery} />
            ) : activeTab === 2 ? (
              <AcceptedInvoice acceptedQuery={acceptedQuery} />
            ) : (
              <InvoiceSummary requestQuery={requestQuery} />
            )}
          </AnimatePresence>
        </main>
      </>
    </div>
  );
}

export default SupplierOpenMarket;
