// PaymentExtensionCalculator.js

import React, { useState } from "react";
import RouteHeader from "../../RouteHeader";
import ToolsModal from "../ToolsModal";

const PaymentExtensionCalculator = ({ goBack }) => {
  const [invoiceAmount, setInvoiceAmount] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [newPaymentDate, setNewPaymentDate] = useState("");
  const [currentPaymentDate, setCurrentPaymentDate] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [interestPercentage, setInterestPercentage] = useState(0);
  const [interestAmount, setInterestAmount] = useState(0);
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /* CALCULATE BUTTON */
  const handleCalculate = () => {
    if (
      !invoiceAmount ||
      !timePeriod ||
      !currentPaymentDate ||
      !newPaymentDate
    ) {
      setIsModalOpen(true);
      return;
    }

    // CALCULATIONS HERE
    const calculatedInterestPercentage = 1.67;
    const calculatedInterestAmount = 11600;
    const calculatedTotalPaymentAmount = 189400;

    setInterestPercentage(calculatedInterestPercentage);
    setInterestAmount(calculatedInterestAmount);
    setTotalPaymentAmount(calculatedTotalPaymentAmount);

    setShowResults(true);
  };

  /* RESET BUTTON */
  const handleReset = () => {
    setShowResults(false);
    setInvoiceAmount("");
    setInterestAmount("");
    setCurrentPaymentDate("");
    setNewPaymentDate("");
    setTimePeriod("");
  };

  /* CLOSE MODAL BUTTON */
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='px-5'>
      <RouteHeader
        Route1='Tools'
        Heading='Payment Extension Calculators'
        handleBack={goBack}
      />
      <div className='mt-10'>
        <div>
          <h1 className='text-black font-sans text-lg font-semibold leading-normal tracking-wide'>
            Payment Extension Calculator
          </h1>
          <p className='text-gray font-sans text-base font-normal leading-normal mt-3'>
            Calculate your potential interest savings with our Payment Extension
            Calculator, tailored to your unique financial needs
          </p>
          <div className='border border-gray my-7'></div>
          <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 pr-5'>
            {/* FIRST INPUT */}
            <div className='mb-4 w-full'>
              <label
                htmlFor='totalFundedAmount'
                className='block text-sm font-semibold text-gray-600 mb-2 mt-2'
              >
                Total Funded Amount
              </label>
              <input
                type='number'
                id='invoiceAmount'
                name='setInvoiceAmount'
                value={invoiceAmount}
                onChange={(e) => setInvoiceAmount(e.target.value)}
                className='w-full p-2 border border-[#FCD596] rounded-md focus:outline-none focus:border-green-500'
                placeholder='100,000'
              />
            </div>

            {/* SECOND INPUT */}
            <div className='mb-4 w-full'>
              <label
                htmlFor='timePeriod'
                className='block text-sm font-semibold text-gray-600 mb-2 mt-2'
              >
                Time Period
              </label>
              <input
                type='number'
                id='timePeriod'
                name='timePeriod'
                value={timePeriod}
                onChange={(e) => setTimePeriod(e.target.value)}
                className='w-full p-2 border border-[#FCD596] rounded-md focus:outline-none focus:border-green-500'
                placeholder='40 days'
              />
            </div>

            {/* THIRD INPUT */}
            <div className='mb-4 w-full'>
              <label
                htmlFor='currentDate'
                className='block text-sm font-semibold text-gray-600 mb-2 mt-2'
              >
                Current Payment Date
              </label>
              <input
                type='date'
                id='currentPaymentDate'
                name='currentPaymentDate'
                value={currentPaymentDate}
                onChange={(e) => setCurrentPaymentDate(e.target.value)}
                className='w-full p-2 border border-[#FCD596] rounded-md focus:outline-none focus:border-green-500'
                // placeholder="20th, August 2004"
              />
            </div>

            {/* FOURTH INPUT */}
            <div className='mb-4 w-full'>
              <label
                htmlFor='newDate'
                className='block text-sm font-semibold text-gray-600 mb-2 mt-2'
              >
                New Payment Date
              </label>
              <input
                type='date'
                id='newPaymentDate'
                name='newPaymentDate'
                value={newPaymentDate}
                onChange={(e) => setNewPaymentDate(e.target.value)}
                className='w-full p-2 border border-[#FCD596] rounded-md focus:outline-none focus:border-green-500'
                placeholder='20th, August 2004'
              />
            </div>
          </section>

          {/* CALCULATE & RESET BUTTONS */}
          <div className='flex gap-4 mt-4'>
            <button
              onClick={handleCalculate}
              className='bg-[#FFBE55] rounded-sm p-[10px] text-white w-[182px] hover:bg-yellow-200'
            >
              Calculate
            </button>
            {showResults && (
              <button
                onClick={handleReset}
                className='border border-[#FFBE55] bg-white rounded-sm p-[10px] text-[#FFBE55] w-[182px] hover:bg-yellow-200'
              >
                Reset
              </button>
            )}
          </div>

          {/* RESULTS */}
          {showResults && (
            <div className='flex mt-10 w-full justify-between text-black font-sans text-base font-medium'>
              <div className='mr-4'>
                <p>Interest Percentage: {interestPercentage}%</p>
              </div>
              <div className='mr-4'>
                <span>
                  Interest Amount:
                  <span className='text-[#07593D]'>NG {interestAmount}</span>
                </span>
              </div>
              <div>
                Total Payment Amount:
                <span className='text-[#07593D]'>NG {totalPaymentAmount}</span>
              </div>
            </div>
          )}

          {/* MODAL */}
          {isModalOpen && (
            <ToolsModal
              message='Input fields are empty. Please fill in the required fields.'
              onClose={closeModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentExtensionCalculator;
