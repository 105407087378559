// import Playcard from "./components/Playcard";

import {
  PAIconArrowBlue,
  PAIconArrowIndigo,
  PAIconArrowLightGreen,
  PAIconArrowLightYellow,
  PAIconArrowPink,
  PAIconArrowPurple,
  PAIconArrowReddish,
  PAIconArrowSplendidGreen,
  PAIconArrowYellow,
  PAIconCardArrow,
  PAIconWalletBlue,
  PAIconWalletEcclipse,
  PAIconWalletIndigo,
  PAIconWalletLightGreen,
  PAIconWalletLightYellow,
  PAIconWalletPink,
  PAIconWalletPurple,
  PAIconWalletReddish,
  PAIconWalletSplendidGreen,
  PAIconWalletYellow,
} from "assets/images/svgs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EllipsisText from "utilities/helper/Ellipsis";

function BuyerRulesandConfig() {
  const { allRulesData } = useSelector((state) => state?.rulesAndConfig);

  const totalPages = allRulesData?.meta?.totalElements;
  return (
    <div className='hfull w-full pt-4 px-4 pb-4'>
      <div className='flex w-fit mxauto hfull flex-wrap gap-8'>
        {/* Card 1 */}
        <Link
          transition={{
            ease: "linear",
            duration: 2,
            x: { duration: 1 },
          }}
          to='EARLY_PAYMENT'
          className='w-full md:w-[283px] cursor-pointer shadow-md rounded-2xl h-[265px] transform transition duration-500 hover:scale-[1.04]'
        >
          <section className='h-[75%] px-5 py-5 rounded-t-2xl bg-speckGreen'>
            {/* <img src='' alt='' /> */}
            <span className='flex h-[33%] items-center justify-end'>
              <PAIconWalletEcclipse />
            </span>

            <div className='flex justify-between h-[67%]'>
              <div className=' h-full flex flex-col gap-1.5 w-full'>
                <h1 className='text-white text-2xl font-bold'>Early Payment</h1>
                <span className='text-white text-sm '>
                  Manage rules related to early payment discounts for invoices
                </span>
              </div>
              <span className='flex  items-end'>
                <PAIconCardArrow />
              </span>
            </div>
          </section>
          <section className='h-[25%] px-5 rounded-b-2xl flex items-center shadow-lg '>
            <div className='flex flex-col gap-1'>
              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>
                  Total rules configured:
                </span>
                <span className='text-speckGreen'>{totalPages}</span>
              </div>

              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>Last Updated:</span>
                <span className='text-speckGreen'>November 20, 2023</span>
              </div>
            </div>
          </section>
        </Link>

        {/* Card 2 */}
        <Link
          to='PAYMENT_EXTENSION'
          className='w-full md:w-[283px] cursor-pointer shadow-md rounded-2xl h-[265px] transform transition duration-500 hover:scale-[1.04]'
        >
          <section className='h-[75%] px-5 py-5 rounded-t-2xl bg-gray700 '>
            {/* <img src='' alt='' /> */}
            <span className='flex h-[33%] items-center justify-end'>
              <PAIconWalletYellow />
            </span>

            <div className='flex justify-between h-[67%]'>
              <div className=' h-full flex flex-col gap-1.5 w-full'>
                <h1 className='text-secondaryColor text-2xl font-bold'>
                  Payment Extension
                </h1>
                <span className='text-greyishBlack text-sm hidden md:inline-block'>
                  <EllipsisText
                    text='Configure rules for extending payment terms for specific
                    scenarios'
                    maxLength={60}
                  />
                </span>

                <span className='md:hidden text-greyishBlack text-sm '>
                  Configure rules for extending payment terms for specific
                  scenarios
                </span>
              </div>
              <span className='flex  items-end'>
                <PAIconArrowYellow className='text-secondaryColor' />
              </span>
            </div>
          </section>
          <section className='h-[25%] px-5 rounded-b-2xl flex items-center shadow-lg '>
            <div className='flex flex-col gap-1'>
              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>
                  Total rules configured:
                </span>
                <span className='text-secondaryColor'>5</span>
              </div>

              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>Last Updated:</span>
                <span className='text-secondaryColor'>November 20, 2023</span>
              </div>
            </div>
          </section>
        </Link>

        {/* Card 3 */}
        <Link
          to='DISBURSEMENT'
          className='w-full md:w-[283px] cursor-pointer shadow-md rounded-2xl h-[265px] transform transition duration-500 hover:scale-[1.04]'
        >
          <section className='h-[75%] px-5 py-5 rounded-t-2xl bg-gray700'>
            {/* <img src='' alt='' /> */}
            <span className='flex h-[33%] items-center justify-end'>
              <PAIconWalletBlue />
            </span>

            <div className='flex justify-between h-[67%]'>
              <div className=' h-full flex flex-col gap-1.5 w-full'>
                <h1 className='text-brightBlue text-2xl font-bold'>
                  Disbursement
                </h1>
                <span className='text-greyishBlack text-sm '>
                  <EllipsisText
                    text='Optimize financial flows with efficient disbursement collection rules'
                    maxLength={70}
                  />
                </span>
              </div>
              <span className='flex  items-end'>
                <PAIconArrowBlue />
              </span>
            </div>
          </section>
          <section className='h-[25%] px-5 rounded-b-2xl flex items-center shadow-lg '>
            <div className='flex flex-col gap-1'>
              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>
                  Total rules configured:
                </span>
                <span className='text-brightBlue'>5</span>
              </div>

              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>Last Updated:</span>
                <span className='text-brightBlue'>November 20, 2023</span>
              </div>
            </div>
          </section>
        </Link>

        {/* Card 4 */}
        <Link
          to='ASSOCIATION'
          className='w-full md:w-[283px] cursor-pointer shadow-md rounded-2xl h-[265px] transform transition duration-500 hover:scale-[1.04]'
        >
          <section className='h-[75%] px-5 py-5 rounded-t-2xl bg-gray700'>
            {/* <img src='' alt='' /> */}
            <span className='flex h-[33%] items-center justify-end'>
              <PAIconWalletPink />
            </span>

            <div className='flex justify-between h-[67%]'>
              <div className=' h-full flex flex-col gap-1.5 w-full'>
                <h1 className='text-brigthPink text-2xl font-bold'>
                  Association
                </h1>
                <span className='text-greyishBlack text-sm '>
                  <EllipsisText
                    text='Optimize financial flows with efficient disbursement collection rules'
                    maxLength={70}
                  />
                </span>
              </div>
              <span className='flex  items-end'>
                <PAIconArrowPink />
              </span>
            </div>
          </section>
          <section className='h-[25%] px-5 rounded-b-2xl flex items-center shadow-lg '>
            <div className='flex flex-col gap-1'>
              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>
                  Total rules configured:
                </span>
                <span className='text-brigthPink'>5</span>
              </div>

              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>Last Updated:</span>
                <span className='text-brigthPink'>November 20, 2023</span>
              </div>
            </div>
          </section>
        </Link>

        {/* Card 5 */}
        <Link
          to='RELATIONSHIP_MANAGEMENT'
          className='w-full md:w-[283px] cursor-pointer shadow-md rounded-2xl h-[265px] transform transition duration-500 hover:scale-[1.04]'
        >
          <section className='h-[75%] px-5 py-5 rounded-t-2xl bg-gray700'>
            {/* <img src='' alt='' /> */}
            <span className='flex h-[33%] items-center justify-end'>
              <PAIconWalletReddish />
            </span>

            <div className='flex justify-between h-[67%]'>
              <div className=' h-full flex flex-col gap-1.5 w-full'>
                <h1 className='text-reddish text-2xl font-bold'>
                  Relationship Management
                </h1>
                <span className='text-greyishBlack text-sm hidden md:inline-block'>
                  <EllipsisText
                    text='Configure rules for extending payment terms for specific
                    scenarios'
                    maxLength={60}
                  />
                </span>

                <span className='md:hidden text-greyishBlack text-sm '>
                  Configure rules for extending payment terms for specific
                  scenarios
                </span>
              </div>
              <span className='flex  items-end'>
                <PAIconArrowReddish />
              </span>
            </div>
          </section>
          <section className='h-[25%] px-5 rounded-b-2xl flex items-center shadow-lg '>
            <div className='flex flex-col gap-1'>
              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>
                  Total rules configured:
                </span>
                <span className='text-reddish'>5</span>
              </div>

              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>Last Updated:</span>
                <span className='text-reddish'>November 20, 2023</span>
              </div>
            </div>
          </section>
        </Link>

        {/* Card 6 */}
        <Link
          to='OPEN_MARKET'
          className='w-full md:w-[283px] cursor-pointer shadow-md rounded-2xl h-[265px] transform transition duration-500 hover:scale-[1.04]'
        >
          <section className='h-[75%] px-5 py-5 rounded-t-2xl bg-gray700'>
            {/* <img src='' alt='' /> */}
            <span className='flex h-[33%] items-center justify-end'>
              <PAIconWalletIndigo />
            </span>

            <div className='flex justify-between h-[67%]'>
              <div className=' h-full flex flex-col gap-1.5 w-full'>
                <h1 className='text-indigoish text-2xl font-bold'>
                  Open Market
                </h1>
                <span className='text-greyishBlack text-sm '>
                  <EllipsisText
                    text='Unrestricted marketplace for buying and selling financial instruments.'
                    maxLength={70}
                  />
                </span>
              </div>
              <span className='flex  items-end'>
                <PAIconArrowIndigo />
              </span>
            </div>
          </section>
          <section className='h-[25%] px-5 rounded-b-2xl flex items-center shadow-lg '>
            <div className='flex flex-col gap-1'>
              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>
                  Total rules configured:
                </span>
                <span className='text-indigoish'>5</span>
              </div>

              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>Last Updated:</span>
                <span className='text-indigoish'>November 20, 2023</span>
              </div>
            </div>
          </section>
        </Link>

        {/* Card 7 */}
        <Link
          to='NOTIFICATION'
          className='w-full md:w-[283px] cursor-pointer shadow-md rounded-2xl h-[265px] transform transition duration-500 hover:scale-[1.04]'
        >
          <section className='h-[75%] px-5 py-5 rounded-t-2xl bg-gray700'>
            {/* <img src='' alt='' /> */}
            <span className='flex h-[33%] items-center justify-end'>
              <PAIconWalletPurple />
            </span>

            <div className='flex justify-between h-[67%]'>
              <div className=' h-full flex flex-col gap-1.5 w-full'>
                <h1 className='text-sparklyPurple text-2xl font-bold'>
                  Notification
                </h1>
                <span className='text-greyishBlack text-sm '>
                  <EllipsisText
                    text='Unrestricted marketplace for buying and selling financial instruments.'
                    maxLength={70}
                  />
                </span>
              </div>
              <span className='flex  items-end'>
                <PAIconArrowPurple />
              </span>
            </div>
          </section>
          <section className='h-[25%] px-5 rounded-b-2xl flex items-center shadow-lg '>
            <div className='flex flex-col gap-1'>
              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>
                  Total rules configured:
                </span>
                <span className='text-sparklyPurple'>5</span>
              </div>

              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>Last Updated:</span>
                <span className='text-sparklyPurple'>November 20, 2023</span>
              </div>
            </div>
          </section>
        </Link>

        {/* Card 8 */}
        <Link
          to='INVOICE_APPROVAL'
          className='w-full md:w-[283px] cursor-pointer shadow-md rounded-2xl h-[265px] transform transition duration-500 hover:scale-[1.04]'
        >
          <section className='h-[75%] px-5 py-5 rounded-t-2xl bg-gray700'>
            {/* <img src='' alt='' /> */}
            <span className='flex h-[33%] items-center justify-end'>
              <PAIconWalletLightYellow />
            </span>

            <div className='flex justify-between h-[67%]'>
              <div className=' h-full flex flex-col gap-1.5 w-full'>
                <h1 className='text-lightYellow text-2xl font-bold'>
                  Invoicing Approval
                </h1>
                <span className='text-greyishBlack text-sm '>
                  <EllipsisText
                    text='Unrestricted marketplace for buying and selling financial instruments.'
                    maxLength={70}
                  />
                </span>
              </div>
              <span className='flex  items-end'>
                <PAIconArrowLightYellow />
              </span>
            </div>
          </section>
          <section className='h-[25%] px-5 rounded-b-2xl flex items-center shadow-lg '>
            <div className='flex flex-col gap-1'>
              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>
                  Total rules configured:
                </span>
                <span className='text-lightYellow'>5</span>
              </div>

              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>Last Updated:</span>
                <span className='text-lightYellow'>November 20, 2023</span>
              </div>
            </div>
          </section>
        </Link>

        {/* Card 9 */}
        <Link
          to='PAYABLE_FINANCING'
          className='w-full md:w-[283px] cursor-pointer shadow-md rounded-2xl h-[265px] transform transition duration-500 hover:scale-[1.04]'
        >
          <section className='h-[75%] px-5 py-5 rounded-t-2xl bg-gray700'>
            {/* <img src='' alt='' /> */}
            <span className='flex h-[33%] items-center justify-end'>
              <PAIconWalletLightGreen />
            </span>

            <div className='flex justify-between h-[67%]'>
              <div className=' h-full flex flex-col gap-1.5 w-full'>
                <h1 className='text-lightishGreen text-2xl font-bold'>
                  Payable Financing
                </h1>
                <span className='text-greyishBlack text-sm '>
                  <EllipsisText
                    text='Unrestricted marketplace for buying and selling financial instruments.'
                    maxLength={70}
                  />
                </span>
              </div>
              <span className='flex  items-end'>
                <PAIconArrowLightGreen />
              </span>
            </div>
          </section>
          <section className='h-[25%] px-5 rounded-b-2xl flex items-center shadow-lg '>
            <div className='flex flex-col gap-1'>
              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>
                  Total rules configured:
                </span>
                <span className='text-lightishGreen'>5</span>
              </div>

              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>Last Updated:</span>
                <span className='text-lightishGreen'>November 20, 2023</span>
              </div>
            </div>
          </section>
        </Link>

        {/* Card 10 */}
        <Link
          to='WORKFLOW'
          className='w-full md:w-[283px] cursor-pointer shadow-md rounded-2xl h-[265px] transform transition duration-500 hover:scale-[1.04]'
        >
          <section className='h-[75%] px-5 py-5 rounded-t-2xl bg-gray700'>
            {/* <img src='' alt='' /> */}
            <span className='flex h-[33%] items-center justify-end'>
              <PAIconWalletSplendidGreen />
            </span>

            <div className='flex justify-between h-[67%]'>
              <div className=' h-full flex flex-col gap-1.5 w-full'>
                <h1 className=' text-primaryColor text-2xl font-bold'>
                  Workflow
                </h1>
                <span className='text-greyishBlack text-sm '>
                  <EllipsisText
                    text='Unrestricted marketplace for buying and selling financial instruments.'
                    maxLength={70}
                  />
                </span>
              </div>
              <span className='flex  items-end'>
                <PAIconArrowSplendidGreen />
              </span>
            </div>
          </section>
          <section className='h-[25%] px-5 rounded-b-2xl flex items-center shadow-lg '>
            <div className='flex flex-col gap-1'>
              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>
                  Total rules configured:
                </span>
                <span className='text-primaryColor'>5</span>
              </div>

              <div className='flex text-xs gap-3'>
                <span className=' text-dustyGray '>Last Updated:</span>
                <span className='text-primaryColor'>November 20, 2023</span>
              </div>
            </div>
          </section>
        </Link>

        <div className=' w-full pt-5 h-10'></div>
      </div>
    </div>
  );
}

export default BuyerRulesandConfig;
