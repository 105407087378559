import React, { useState } from "react";
import TransferInfoPinModal from "./TransferInfoPinModal";
const TransferInfoModal = ({ isOpen, onClose, onConfirm }) => {
  const [isTransferInfoPinOpen, setIsTransferInfoPinOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedRecipient, setSelectedRecipient] = useState("");

  const handleNextClick = (e) => {
    e.preventDefault();
    onConfirm(selectedAccount, selectedRecipient);
    setIsTransferInfoPinOpen(true);
    onClose();
  };

  if (!isOpen && !isTransferInfoPinOpen) return null;

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
          <div className="bg-white p-5 px-5 rounded-md max-w-xs md:max-w-lg w-full pb-5">
            <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
              <p className=" text-[#7A7A7A] font-sans text-base p-3 px-5 rounded-sm font-thin italic">
                Transfer Funds
              </p>
              <button
                onClick={onClose}
                className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
              >
                &times; <span className="text-sm mx-2 mt-1">Close</span>
              </button>
            </div>

            <div>
              <form className="w-full px-7">
                <p className="mb-4 text-[#222] font-sans text-sm font-bold">
                  Transfer Information
                </p>
                <div className="mb-4">
                  <label className="text-[#222] font-sans text-sm font-normal mb-2">
                    Select Account
                  </label>
                  <select
                    value={selectedAccount}
                    onChange={(e) => setSelectedAccount(e.target.value)}
                    className="border w-full py-2 px-3 border-t-0 border-x-0 text-[#222] focus:border-[#222] focus:outline-none"
                  >
                    <option value="">Select an account</option>
                    <option value="account1">Account 1</option>
                    <option value="account2">Account 2</option>
                    <option value="account3">Account 3</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="text-[#222] font-sans text-sm font-normal mb-2">
                    Recipient Account
                  </label>
                  <select
                    value={selectedRecipient}
                    onChange={(e) => setSelectedRecipient(e.target.value)}
                    className="border w-full py-2 px-3 border-t-0 border-x-0 text-[#222] focus:border-[#222] focus:outline-none"
                  >
                    <option value="">Select a recipient account</option>
                    <option value="recipient1">Recipient Account 1</option>
                    <option value="recipient2">Recipient Account 2</option>
                    <option value="recipient3">Recipient Account 3</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="text-[#222] font-sans text-sm font-normal mb-2">
                    Amount
                  </label>
                  <input
                    type="number"
                    name="amount"
                    className="border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="text-[#222] font-sans text-sm font-normal mb-2">
                    Narration
                  </label>
                  <input
                    type="text"
                    name="narration"
                    className="border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
                  />
                </div>

                {/* Buttons */}
                <div className="flex justify-end mt-10">
                  <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex  mx-2">
                    <button
                      type="button"
                      onClick={onClose}
                      className="text-center text-[#7A7A7A] text-base font-normal  tracking-wide"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#EDEDED]">
                    <button
                      className="text-center text-[#7A7A7A] text-base font-normal  tracking-wide"
                      onClick={handleNextClick}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <TransferInfoPinModal
        isOpen={isTransferInfoPinOpen}
        onClose={() => setIsTransferInfoPinOpen(false)}
      />
    </>
  );
};

export default TransferInfoModal;
