import { PAIconFluentSend, PAIconInventoryArrowLeft } from "assets/images/svgs";
import {
  PAIconTrashRed,
  PAIconSettingsProfile,
} from "../../../assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";

//import ProfilePicSuport from "./ProfilePicSupport";
import InputField from "shared/InputField";
import Button from "shared/Button";
import toast from "react-hot-toast";

const UserCommunication = () => {
  const navigate = useNavigate();
  // const { id } = useParams();

  const handleNavigate = () => {
    navigate("/support");
  };

  return (
    <DashboardLayout section="Support" subHeader="Having problems - help">
      <div
        onClick={handleNavigate}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
      >
        <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
      </div>
      <div className="bg-white rounded-t-xl rounded-[5px]">
        {/* <div
          onClick={handleNavigate}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div> */}

        <div className="flex flex-col w-full rounded-t-lg bg-[#FAFAFA] gap-4 justify-between">
          <div className="flex flex-row gap-x-5 ml-10 mr-10 mt-5">
            <div className="flex gap-x-1 pb-4">
              <h1 className="font-bold">RE: Invoice Payment incomplete</h1>
              <div className="inline-flex items-center justify-center px-3 bg-[#eaeaea] rounded-[5px]">
                <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',_Helvetica] font-normal text-[color:var(--black)] text-[12px] tracking-[0] leading-[normal] whitespace-nowrap">
                  Payment
                </div>
              </div>
            </div>
            <div className="flex ml-auto justify-between font-medium sm:font-sm">
              <span>
                <select
                  className="w-full rounded-[15px] text-white  cursor-pointer  outline-none px-1 py-1 bg-[#F08000] sm:px-3"
                  name="status"
                  id=""
                >
                  <option disabled value="#">
                    Open
                  </option>
                  <option className="cursor-pointer" value="Open">
                    Open
                  </option>
                  <option className="cursor-pointer" value="2">
                    2
                  </option>
                  <option className="cursor-pointer" value="3">
                    3
                  </option>
                </select>
              </span>
              <div className="px-1 pt-1">
                <PAIconTrashRed />
              </div>
            </div>
          </div>

          <div className="flex gap-x-3 pb-4 ml-10">
            <span>Ticket #1001</span>
            <span className="test-sm">
              <b className="text-[#F08000]">*</b> Open
            </span>
            <div className="inline-flex items-center justify-center gap-[10px] px-[8px] py-[4px] relative bg-[#eaeaea] rounded-[2px]">
              <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',_Helvetica] font-normal text-[color:var(--black)] text-[12px] tracking-[0] leading-[normal] whitespace-nowrap">
                2023-05-03
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex gap-x-5 pb-4">
          <h1 className="font-bold">RE: Invoice Payment incomplete</h1>
          <div className="inline-flex items-center justify-center gap-[10px] px-[8px] py-[4px] relative bg-[#eaeaea] rounded-[2px]">
            <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',_Helvetica] font-normal text-[color:var(--black)] text-[12px] tracking-[0] leading-[normal] whitespace-nowrap">
              issue #1001
            </div>
          </div>
        </div> */}

        {/* <hr className="block w-[100%] my-3 border-t-2 bg-[#ccc] border-silver" /> */}

        <div className="flex flex-row items-end gap-0 mt-7">
          <span className="relative inline-block">
            <PAIconSettingsProfile className="inline-block h-8 rounded-full ring-2 ring-white dark:ring-gray-800" />
          </span>
          <span
            className="justify-start bg-[#FAFAFA] text-[#7A7A7A]
              w-auto h-auto rounded-lg border border-[#F0F0F0] p-2 sm:p-2 md:p-2 lg:p-3 xl:p-3"
          >
            <div class="">
              <p class="text-left text-sm  font-inter font-normal leading-tight px-7 py-5 sm:px-1 sm:py-2">
                Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?
              </p>
            </div>
          </span>
        </div>

        <div className="flex flex-row items-center w-full h-auto top-20 gap-3ß text-[#C1C1C1] ml-10">
          <span className="">
            <p class="font-inter text-base font-normal leading-[19.36px] text-left">
              Tailor Gimms
            </p>
          </span>
          <div className="justify-start w-auto h-auto sm:p-2 md:p-2 lg:p-3 xl:p-3">
            <p class="text-left text-sm  font-inter font-normal leading-tight">
              * Today 11:30AM
            </p>
          </div>
        </div>

        <div className="flex flex-row items-end gap-0 mt-7">
          <span
            className="justify-end 
              w-auto h-auto rounded-lg border border-[#F0F0F0] pl-2 sm:p-2 md:p-2 lg:p-3 xl:p-3"
          >
            <div class="pl-[40%]">
              <p class=" bg-[#DEF8EC] text-[#7A7A7A] text-left text-sm  font-inter font-normal leading-tight px-7 py-5 sm:px-1 sm:py-2">
                Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?Hello! How can I help you
                today?Hello! How can I help you today?
              </p>
            </div>
          </span>
          <span className="relative inline-block">
            <PAIconSettingsProfile className="inline-block h-8 rounded-full ring-2 ring-white dark:ring-gray-800" />
          </span>
        </div>

        {/* <ProfilePicSuport className="" />

        <div className="pt-4">
          <div
            id=""
            className="w-full h-80 pt-4 px-4 py-2 text-gray-700 rounded-lg focus:outline-none focus:border-black resize-none"
            rows="12"
          ></div>
        </div> */}

        <div className="flex flex-col w-full gap-x-2 pt-4">
          <div className="w-[100%] justify-center place-items-center items-center">
            <InputField
              placeholder="Reply"
              className=" rounded-[5px] border-[0.5px] !border-silver px-2 outline-none"
            />
          </div>
          <div className="w-[100%] justify-end place-items-end gap-1 text-[#7A7A7A]">
            <div className="flex justify-end place-items-end mr-2">
              <label
                htmlFor="fileInput"
                className="justify-end place-items-end rounded-[5px] flex gap-1 text-black border border-[#F0F0F0] cursor-pointer  outline-none px-5 py-3"
              >
                <input
                  type="file"
                  name=""
                  id="fileInput"
                  style={{ display: "none" }}
                />
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 8.50098V6.50098C8 4.29184 9.79086 2.50098 12 2.50098C14.2091 2.50098 16 4.29184 16 6.50098V18.501C16 20.7101 14.2091 22.501 12 22.501C9.79086 22.501 8 20.7101 8 18.501V14.001C8 12.6203 9.11929 11.501 10.5 11.501C11.8807 11.501 13 12.6203 13 14.001V16.501"
                    stroke="#141B34"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </label>
            </div>
          </div>
          <div className="w-[100%] justify-end place-items-end gap-1 mr-2">
            <div className="flex justify-end place-items-end mr-2">
              <Button
                neutral
                onClick={() => toast.success("Complaint sent successfully")}
                className="flex w-[100px] h-[40px] hover:!bg-[#317a62] border-[1px] border-[#317a62] rounded-[5px] place-items-end justify-center items-center bg-DeepSeaGreen -gap-x-6"
              >
                <PAIconFluentSend />
                <p className="text-sm pl-1 font-normal text-white">Send</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default UserCommunication;
