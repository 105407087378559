import React, { useEffect, useState } from "react";
import {
  PAIconDownload,
  PAIconDropdown,
  PAIconFilter,
  PAIconNotification,
  PAIconOutlineSearch,
  PAIconVieweye,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import { allDirectFunding } from "appstate/invoice/invoiceSlice";
import { remainder } from "appstate/collections/collectionSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";

const DirectFunding = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // const [disabled, setDisabled] = useState(true);
  const [selectAllText, setSelectAllText] = useState("Select All");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [tableType, setTableType] = useState("buyer");

  const navigate = useNavigate();

  const { directFundingData, isLoading } = useSelector(
    (state) => state?.invoice
  );

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  const handleSearch = () => {
    dispatch(allDirectFunding({ search: searchQuery }));
  };

  useEffect(() => {
    dispatch(
      allDirectFunding({
        page,
        pageSize,
        bulk: "",
        status: "APPROVED",
        search: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  const formatAmount = (amount) => {
    if (!amount || typeof amount !== "string") return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleNotificationClick = (row) => {
    setSelectedInvoice(row);
    const isSelected = selectedRows.includes(row.invoiceIds);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRows, row.invoiceIds];
    }

    setSelectedRows(newSelectedRows);
    setIsAcceptModalOpen(true);
  };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setSelectedInvoice(null);
    const selectedInvoiceIds = selectedRows.map((id) => id);
    dispatch(
      remainder({ type: "directfunding", body: selectedInvoiceIds[0] })
    ).then((response) => {
      if (response?.payload?.status === 200 && response?.payload?.success) {
        setIsSuccessModalOpen(true);
        setSuccessMessage(response?.payload?.message);
      }
    });
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const directFundingDetails = (id) => {
    // console.log(id);
    let path = `directfunding/${id}`;
    navigate(path);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters?.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  // FILTER
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // SEND REMAINDER BUTTON
  const handleSendReminder = () => {
    setIsAcceptModalOpen(true);
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const selectedIndex = selectedRows.indexOf(row.invoiceIds);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, row.invoiceIds];
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== row.invoiceIds);
    }

    setSelectedRows(newSelectedRows);
    // setDisabled(newSelectedRows.length === 0);
  };

  // SELECT ALL
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      let allIds = [];
      if (tableType === "buyer") {
        allIds = directFundingData?.data?.map((row) => row.invoiceIds);
      } else {
        allIds = supplierInvoices.map((row) => row.invoiceIds);
      }
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
  };

  const supplierInvoices = [
    {
      invoiceIds: "1",
      invoiceNumber: "INV-2022001",
      buyerCompanyName: "ABC Company",
      dueDate: "2024-01-31",
      currency: "USD",
      totalAmount: "$9,000.00",
      status: "PARTIAL PAYMENT",
    },
    {
      invoiceIds: "2",
      invoiceNumber: "INV-2022002",
      buyerCompanyName: "XYZ Inc.",
      dueDate: "2024-02-04",
      currency: "USD",
      totalAmount: "$9,000.00",
      status: "PARTIAL PAYMENT",
    },
    {
      invoiceIds: "3",
      invoiceNumber: "INV-2022003",
      buyerCompanyName: "123 Company",
      dueDate: "2024-01-31",
      currency: "USD",
      totalAmount: "$2,000.00",
      status: "PARTIAL PAYMENT",
    },
  ];

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.invoiceIds)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => {
        const invoiceNumber = row.invoiceNumber;
        return invoiceNumber && !isNaN(invoiceNumber)
          ? `INV-${invoiceNumber}`
          : "INV-0";
      },
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyerCompanyName,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.invoiceDueDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.invoiceAmount)}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: () => <p className="text-yellow-400">PENDING REIMBURSEMENT</p>,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconVieweye
              className="accent-gray300 w-4"
              onClick={() => directFundingDetails(row.invoice[0]?.id)}
            />
          }
          {
            <PAIconNotification
              className="accent-gray300 w-4"
              onClick={() => handleNotificationClick(row)}
            />
          }
          {<PAIconDownload className="accent-gray300 w-4" />}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          {/* FILTER */}
          <div>
            <div
              className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
              onClick={handleDropdownToggle}
            >
              Filter{" "}
              {selectedFilters.length > 0 && (
                <span className="bg-gray rounded-lg p-1 px-2">
                  {selectedFilters.length}
                </span>
              )}{" "}
              Selected <PAIconDropdown />
            </div>
            {isDropdownOpen && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                <label className="block">
                  <input
                    type="checkbox"
                    value="date"
                    checked={selectedFilters.includes("date")}
                    onChange={handleFilterChange}
                  />
                  Date
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="name"
                    checked={selectedFilters.includes("name")}
                    onChange={handleFilterChange}
                  />
                  Name
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="currency"
                    checked={selectedFilters.includes("currency")}
                    onChange={handleFilterChange}
                  />
                  Currency
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="amount"
                    checked={selectedFilters.includes("amount")}
                    onChange={handleFilterChange}
                  />
                  Amount
                </label>
              </div>
            )}
          </div>

          {/* EXPORT */}
          <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for request"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              <PAIconFilter /> Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!directFundingData?.data?.length && !supplierInvoices?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Due Invoices from Direct Funding
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-black text-lg font-bold">
                  Due Invoices from Direct Funding
                </p>
                <span className="text-xs font-thin">
                  List of all due invoices you have funded in direct funding
                </span>
              </div>
              <div className="flex items-center justify-center gap-3">
                <div
                  className="border border-gray300 rounded-md p-2 text-white bg-[#2FA06A] cursor-pointer"
                  onClick={handleSelectAll}
                  // checked={selectAll}
                >
                  <p>{selectAllText}</p>
                </div>
                <button
                  className={`border border-gray300 rounded-md p-2 text-white ${
                    selectedRows.length === 0
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={selectedRows.length === 0}
                  onClick={handleSendReminder}
                >
                  <p>Send Reminder</p>
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <div className="flex px-[24px] gap-5 my-5">
                <div className="flex justify-center items-center px[24px] gap-5 my2">
                  <p className="text-sm font-normal">Due Invoices from</p>
                  <select
                    className="border border-gray300 rounded-md p-2 text-gray800 cursor-pointer"
                    onChange={(e) => setTableType(e.target.value)}
                  >
                    <option value="buyer">Buyer</option>
                    <option value="supplier">Supplier</option>
                  </select>
                </div>
              </div>
              {tableType === "buyer" ? (
                <Table
                  columns={columns}
                  data={directFundingData?.data}
                  onRowClicked={(row) =>
                    directFundingDetails(row.invoice[0]?.id)
                  }
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              ) : (
                <Table
                  columns={columns}
                  data={supplierInvoices}
                  onRowClicked={(row) => directFundingDetails(row.id)}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              )}
              <div className="flex justify-center gap-x-[8px] mt-[20px]">
                {directFundingData?.data?.length > 1 && (
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={directFundingData?.meta?.totalElements}
                  />
                )}
              </div>
            </div>
          </Card>
        )}
      </div>
      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            {selectedRows.length > 1 ? (
              <span>to all selected due invoices?</span>
            ) : (
              <>
                to due{" "}
                <span className="text-secondaryColor font-bold">
                  {/* invoice INV-{selectedInvoiceId} */}
                  invoice {selectedInvoice?.invoiceReference}
                </span>
                ?
              </>
            )}
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default DirectFunding;
