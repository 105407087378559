import React from 'react';
import Button from 'shared/Button';
import Spinner from 'shared/Spinner/Spinner';
import {
  ButtonContainer,
  DeleteWrapper,
  SelectContainer,
  WarningText,
  WarningWrapper,
} from './styles';

function Delete({
  onClose,
  handleDelete,
  warning,
  isLoading,
  status,
  handleChange,
  changeStatus,
}) {
  const deleteUser = () => {
    handleDelete();
  };
  return (
    <DeleteWrapper>
      <WarningWrapper>
        <WarningText>{warning}</WarningText>
        {status && (
          <SelectContainer>
            <select
              label="Status"
              id="status"
              onChange={handleChange}
              value={changeStatus}
              className="border !border-gray600 rounded-[5px] w-[500px] h-[40px] px-[16px] outline-none"
            >
              <option value="Open">Open</option>
              <option value="Fulfilled">Fulfilled</option>
              <option value="cancel">Cancelled</option>
              <option value="Closed">Closed</option>
            </select>
          </SelectContainer>
        )}
      </WarningWrapper>
      <ButtonContainer>
        <Button
          neutral
          type="button"
          onClick={onClose}
          className="bg-white !text-primaryColor !font-bold !w-[91px]"
          auth
          invert
        >
          Cancel
        </Button>
        <Button
          neutral
          submitBtn
          type="submit"
          onClick={deleteUser}
          className={`bg-red !font-bold ${status ? 'submit-btn' : 'save-btn'}`}
        >
          {isLoading ? <Spinner /> : `${status ? 'Submit' : 'Delete'}`}
        </Button>
      </ButtonContainer>
    </DeleteWrapper>
  );
}

export default Delete;
