import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const Graph = () => {
  const {
    statusOverviewData
   } = useSelector((state) => state?.toolsAndAgreements);

   const approvedAgreements = statusOverviewData?.data?.approvedAgreements
   const pendingAgreements = statusOverviewData?.data?.pendingAgreements
   const rejectedAgreements = statusOverviewData?.data?.rejectedAgreements

  const [chartData] = useState({
    series: [approvedAgreements, pendingAgreements, rejectedAgreements],
    options: {
      chart: {
        width: 250,
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
                fontWeight: "bold",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: true,
            },
          },
        },
      ],
      legend: {
        show: true,
        position: "bottom",
      },
      colors: ["#FFDCA3", "#2FA06A", "#07593D"],
      labels: ["Approved", "Rejected", "Pending"],
    },
  });

  return (
    <div className="mr-4">
      <div className="chart-wrap">
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="donut"
            width={250}
          />
        </div>
      </div>
    </div>
  );
};

export default Graph;
