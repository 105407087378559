import {
  PAIconArrowLeftGreen,
  PAIconCocacola,
  PAIconSmallBrewIcon,
} from "assets/images/svgs";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import BidAcceptModal from "../../modals/BidAcceptModal";
import BidSelectAccountModal from "../../modals/BidSelectAccountModal";

import BidProceedModal from "../../modals/BidProceedModal";
import BidPinModal from "../../modals/BidPinModal";
import BidInvoiceLockedModal from "../../modals/BidInvoiceLockedModal";
import BidLoaderModal from "../../modals/BidLoaderModla";
import { useDispatch } from "react-redux";
import { setClosedMarketActiveTab } from "appstate/funder/funderSlice";
import { AnimatePresence } from "framer-motion";
import DashboardLayout from "layout/dashboardlayout";

const BulkClosedMarketDetails = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [bidSelectAccountModal, setBidSelectAccountModal] = useState(false);
  const [ongoingAcceptUpdateBidModal, setOngoingAcceptUpdateBidModal] =
    useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [bidProceed, setBidProceed] = useState(false);
  const [bidPin, setBidPin] = useState(false);
  const [bidLoaderModal, setBidLoaderModal] = useState(false);
  const goBack = () => {
    navigate(-1);
  };
  const [discountRate, setDiscountRate] = useState("");
  const handleSetDiscountRate = (e) => {
    setDiscountRate(e.target.value);
  };

  const handleAccept = () => {
    setOngoingAcceptUpdateBidModal(false);
    setTimeout(() => {
      setBidSelectAccountModal(true);
    }, 300);
  };

  const handleProceed = () => {
    setBidProceed(false);

    setTimeout(() => {
      setBidPin(true);
    }, 300);
  };
  const handleInvoiceModalClose = () => {
    setIsInvoiceModalOpen(false);
    goBack();
  };
  const handleOnselectModal = () => {
    setBidSelectAccountModal(false);

    setTimeout(() => {
      setBidProceed(true);
    }, 300);
  };
  const handlePinModal = () => {
    setBidPin(false);
    setBidLoaderModal(true);
  };

  const handleTabClick = (tabNumber) => {
    setIsInvoiceModalOpen(false);
    goBack();
    dispatch(setClosedMarketActiveTab(tabNumber));
    sessionStorage.setItem("activeTab", tabNumber);
  };

  const dummyData = [
    {
      index: 1,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      invoiceAmount: "9,000,000",
    },
    {
      index: 2,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      invoiceAmount: "9,000,000",
    },
    {
      index: 3,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      invoiceAmount: "9,000,000",
    },
    {
      index: 4,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      invoiceAmount: "9,000,000",
    },
    {
      index: 5,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      invoiceAmount: "9,000,000",
    },
    {
      index: 6,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      invoiceAmount: "9,000,000",
    },
  ];

  return (
    <DashboardLayout
      section="Closed Market"
      subHeader="View invoices uploaded to the closed market by your associated buyers"
    >
      <>
        <AnimatePresence>
          <BidInvoiceLockedModal
            isOpen={isInvoiceModalOpen}
            onClose={handleInvoiceModalClose}
            toOngoingTab={() => handleTabClick(3)}
          />
        </AnimatePresence>
        {/* <AnimatePresence> */}
        <>
          {bidLoaderModal && (
            <BidLoaderModal
              isOpen={bidLoaderModal}
              onClose={() => setBidLoaderModal(false)}
              onAction={() => {
                setBidLoaderModal(false);
                setTimeout(() => {
                  setIsInvoiceModalOpen(true);
                }, 300);
              }}
              statusText={"Fund Invoice"}
            />
          )}{" "}
        </>

        <AnimatePresence>
          {" "}
          <BidPinModal
            isOpen={bidPin}
            onClose={() => setBidPin(false)}
            handleNext={handlePinModal}
          />
        </AnimatePresence>
        <AnimatePresence>
          {" "}
          <BidProceedModal
            isOpen={bidProceed}
            refNum={" 7364738921"}
            onClose={() => setBidProceed(false)}
            handleYes={handleProceed}
          />
        </AnimatePresence>
        <AnimatePresence>
          {" "}
          <BidAcceptModal
            isOpen={ongoingAcceptUpdateBidModal}
            onClose={() => setOngoingAcceptUpdateBidModal(false)}
            refNum={"R3456X89Y"}
            handleYes={handleAccept}
          />
        </AnimatePresence>
        <AnimatePresence>
          {" "}
          <BidSelectAccountModal
            isOpen={bidSelectAccountModal}
            onClose={() => setBidSelectAccountModal(false)}
            handleProceed={handleOnselectModal}
          />
        </AnimatePresence>
        <Link
          onClick={goBack}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>

        <div className="grid lg:grid-cols-[60%,40%] gap-5">
          <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] py-5">
            <div className="px-6 ">
              <div className="flex flex-col gap-1">
                <div class=" justify-start items-center inline-flex">
                  <div class="text-[#222222] text-lg font-semibold">
                    Reference No: R3456X89Y
                  </div>
                </div>
                <div className=" flex justify-between items-center flex-row">
                  <h1 className="text-[18px] font-bold">Invoice | INV-2002</h1>
                  <div>
                    <div class="text-center text-[#f08000] text-xs font-semibold ">
                      Approved
                    </div>
                  </div>
                </div>

                <main className=" w-full   ">
                  <div className="flex flex-row w-full justify-between">
                    <div className="flex flex-col gap-1">
                      <div className="flex gap-x-[50px]">
                        <span className=" text-xs text-[#222222] font-semibold">
                          Created Date:
                        </span>
                        <span className="text-[#979797]  text-xs">
                          23-Dec-2024
                        </span>
                      </div>
                      <div className="flex gap-x-[50px]">
                        <span className=" text-xs text-[#222222] font-semibold ">
                          Payment Due Date:
                        </span>
                        <span className="text-[#979797]  text-xs">
                          23-Dec-2024
                        </span>
                      </div>

                      <div className="flex gap-x-[50px]">
                        <span className=" text-xs text-[#222222] font-semibold">
                          Early Payment Date:
                        </span>
                        <span className="text-[#979797]  text-xs">
                          23-Dec-2024
                        </span>
                      </div>
                    </div>
                    <div className="mt-1">
                      <div class="text-right text-[#222222] text-xl font-bold ">
                        N23,000,000
                      </div>
                      <div class="text-[#979797] text-xs font-normal text-right">
                        Total request amount
                      </div>
                    </div>
                    {/* seconded here */}
                  </div>

                  <div></div>
                </main>
              </div>

              <div className="flex flex-col gap-4 ">
                <header className="border-b-[5px] border-b-[#F6F5FA] mt-6 ">
                  <h1 className="text-[18px] font-bold">
                    Supplier/buyer details
                  </h1>
                </header>
                <div className="flex  justify-between mb-5">
                  <div className="flex gap-2">
                    <PAIconCocacola />
                    <div className="flex flex-col">
                      <span class="text-black text-xs font-semibold ">
                        Coca Cola
                      </span>
                      <span className="text-[#979797]  text-xs">Supplier</span>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <PAIconSmallBrewIcon />
                    <div className="flex flex-col">
                      <span class="text-black text-xs font-semibold ">
                        ABC Limited
                      </span>
                      <span className="text-[#979797]  text-xs">Buyer</span>
                    </div>
                  </div>
                </div>

                {/* next level */}

                <div className="flex flex-col gap-4 mt-4  w-full">
                  <div className=" w-full overflow-x-auto">
                    <table className=" w-full overflow-x-auto">
                      <thead className="bg-[#F9F9FA] w-full border-b-[1px] border-b-[#eeeeee] border-t-[1px] border-t-[#eeeeee]  ">
                        <tr className=" bg-unusualGray p-2.5  ">
                          <td className="text-[#11192A] p-2.5 text-xs font-bold">
                            Invoice Number
                          </td>
                          <td className=" p-2.5 text-xs font-bold">Due Date</td>

                          <td className=" p-2.5 text-xs font-bold">
                            Invoice Amount{" "}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {dummyData.map((item, index) => (
                          // <tbody key={index}>
                          <tr
                            className=" px-1.5  py-16 my-12  border-b-[1px] border-b-[#eeeeee] h-[55px] cursor-pointer w-full "
                            onClick={() =>
                              navigate(
                                `/payablefinancing-closedmarket/funderclosedmarketoverviewdetails/${item.index}`
                              )
                            }
                          >
                            <td className=" text-xs text-[#11192A] p-2.5">
                              {item.ivnNuM}
                            </td>

                            <td className=" text-xs text-[#11192A] p-2.5">
                              {item.dueDate}
                            </td>

                            <td className=" text-xs text-[#11192A] p-2.5">
                              {item.invoiceAmount}
                            </td>
                          </tr>
                          // </tbody>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-[10px] gap-8 ">
            <div className=" py-8 px-6">
              <div class="text-black text-xl font-semibold ">Bid Invoice</div>
              <div class="text-[#222222] text-sm font-semibold mt-8 ">
                Please input your bid discount rate. The buyer's benchmark rate
                is 5.6%. Your initial bid can match or be lower than this rate,
                but it must not exceed the benchmark specified in the request.
              </div>
              {/* search section */}
              <div>
                <input
                  type="text"
                  placeholder="Enter discount rate"
                  value={discountRate}
                  onChange={handleSetDiscountRate}
                  className="px-3 w-full flex items-center text-black focus:outline-none h-[47px]  rounded-[5px] border border-[#07593d] mt-8  text-base font-semibold "
                />
              </div>

              <button
                class="h-[47px] px-2.5 py-[15px] bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex mt-8 w-full cursor-pointer"
                onClick={() => setOngoingAcceptUpdateBidModal(true)}
              >
                <div class="text-white text-base font-semibold ">Bid Now</div>
              </button>
            </div>
          </div>
        </div>
      </>
    </DashboardLayout>
  );
};

export default BulkClosedMarketDetails;
