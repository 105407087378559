import { motion } from "framer-motion";
import { PAIconDownload, PAIconArrowLeftGray } from "assets/images/svgs";

import { useNavigate } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import DashboardLayout from "layout/dashboardlayout";

function BulkDetailPage() {
  const navigate = useNavigate();

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <>
      <DashboardLayout
        section="Closed Market"
        subHeader="View invoices uploaded to the closed market by your associated buyers"
      >
        <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
          <main className="flex flex-col  pb-8 gap-8 w-full ">
            <div
              className="flex cursor-pointer w-fit gap-1 items-center"
              onClick={handleGoBack}
            >
              <PAIconArrowLeftGray />
              <span className=" text-[#7A7A7A] text-sm">Go back</span>
            </div>
            <motion.section
              initial={{
                x: 20,
              }}
              animate={{
                x: 0,
              }}
              exit={{
                x: -20,
              }}
              transition={{
                delay: 0.6,
              }}
              className="flex flex-col gap-8 px-6 pb-24 w-full bg-white "
            >
              <header className="w-full py-5 border-b border-[#B1A6B2]  ">
                <div className="flex cursor-pointer pt-4 px-6 w-fit gap-1 items-center">
                  <span className="font-[500] text-black text-sm pb-1.5 border-b  ">
                    Invoices
                  </span>
                  {/* <div className="absolute bottom-0 left-0 w-full  h-0.5  rounded bg-black transition-transform duration-300 ease-in-out"></div> */}
                </div>
              </header>

              <div className="   ">
                <div className="flex flex-row justify-between w-full ">
                  <div class="text-[#222222] text-lg font-semibold ">
                    Reference No: R3456X89Y
                  </div>
                  <button class=" px-6 py-2.5 bg-[#07593d] rounded justify-center items-center  ">
                    <div class="text-white text-sm font-semibold ">Pay now</div>
                  </button>{" "}
                </div>
                {/* <div>
                <div class="text-right text-[#222222] text-lg font-bold ">
                  N23,000,000
                </div>
                <div class="text-right text-[#979797] text-xs font-normal ">
                  Total request amount
                </div>
              </div> */}
                <div className=" flex flex-row justify-between">
                  {/* first */}
                  <div>
                    {/* <header className=" m-0  p-0 "> */}
                    <div className=" text-[18px] font-bold">
                      Invoice | INV-2002
                    </div>
                    {/* </header> */}

                    <main className=" w-full flex justify-between">
                      <div className="flex flex-col gap-3  w-full">
                        <div className="flex justify-between">
                          <span>Reference No:</span>
                        </div>

                        <div className="flex flex-col gap-1 w-full ">
                          <div className="flex gap-x-[50px]">
                            {/* <div class="text-[#222222] text-xs font-semibold font-['SF Pro Display']">
                        Created Date:{" "}
                      </div> */}
                            <span className=" text-xs  text-[#222222] font-semibold ">
                              Created Date:
                            </span>

                            <span className="text-[#979797]  text-xs ">
                              23-Dec-2024
                            </span>
                          </div>
                          <div className="flex gap-x-[50px]">
                            <span className=" text-xs text-[#222222] font-semibold ">
                              Payment Due Date:
                            </span>
                            <span className="text-[#979797]  text-xs">
                              23-Dec-2024
                            </span>
                          </div>

                          <div className="flex gap-x-[50px]">
                            <span className=" text-xs  text-[#222222] font-semibold ">
                              Early Payment Date:{" "}
                            </span>
                            <span className="text-[#979797]  text-xs">
                              23-Dec-2024
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col justify-between"></div>
                    </main>
                  </div>

                  {/* second */}
                  <div className="">
                    <div class="text-right text-[#222222] text-lg font-bold ">
                      N23,000,000
                    </div>
                    <div class="text-right text-[#979797] text-xs font-normal ">
                      Total request amount
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className=" font-bold text-sm italic">Payment</span>
                  <span className="text-[#979797]  text-xs">60 Days</span>
                </div>
                <div className="flex flex-col">
                  <span className=" font-bold text-sm italic">Currency</span>
                  <span className="text-[#979797]  text-xs">NGN</span>
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <header className="border-b-[5px] border-b-[#F6F5FA] ">
                  <h1 className="text-[18px] font-bold">
                    Supplier/buyer details
                  </h1>
                </header>
                <div className="flex  justify-between">
                  <div class="w-[147px] h-[39px] flex-col justify-start items-start gap-1 inline-flex">
                    <div class="w-[129px] h-5 text-neutral-800 text-sm font-extrabold italic">
                      Supplier
                    </div>
                    <div class="w-[147px] h-[15px] text-neutral-400 text-xs font-normal ">
                      ABC Cooperation
                    </div>
                  </div>

                  <div class="w-[147px] h-[39px] flex-col justify-start items-start gap-1 inline-flex">
                    <div class="w-[129px] h-5 text-neutral-800 text-sm font-extrabold italic ">
                      Buyer
                    </div>
                    <div class="w-[147px] h-[15px] text-neutral-400 text-xs font-normal ">
                      Big Buyer Coop
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-3">
                <header className="border-b-[5px] border-b-[#F6F5FA] ">
                  <h1 className="text-sm font-bold">Line Items</h1>
                </header>

                <div className=" w-full overflow-x-auto">
                  <table className=" w-full overflow-x-auto">
                    <thead className="bg-subtleGray w-">
                      <tr className=" bg-unusualGray p-2.5 ">
                        <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td>
                        <td className=" p-2.5 text-xs font-bold">PO Number</td>
                        <td className=" p-2.5 text-xs font-bold">
                          Product/Service Number
                        </td>
                        <td className=" p-2.5 text-xs font-bold">
                          Product/Service Description
                        </td>
                        <td className=" p-2.5 text-xs font-bold">Quantity</td>
                        <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                        <td className=" p-2.5 text-xs font-bold">
                          Line Item Extended Amount
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192a] p-2.5">
                          INV-2002
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">
                          PO-2002
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">
                          ITM3201
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">
                          Dangote Cement
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">20</td>
                        <td className=" whitespace-nowrap text-xs text-[#11192a] p-2.5">
                          $30
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">$600</td>
                      </tr>

                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192a] p-2.5">
                          INV-2002
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">
                          PO-2002
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">
                          ITM3201
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">
                          Dangote Cement
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">20</td>
                        <td className=" whitespace-nowrap text-xs text-[#11192a] p-2.5">
                          $30
                        </td>
                        <td className=" text-xs text-[#11192a] p-2.5">$600</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                  <h1 className="text-sm font-bold">Attachments</h1>
                </header>

                <div className="w-full overflow-x-auto">
                  <table className="w-full overflow-x-auto">
                    <thead className="  bg-subtleGray w-">
                      <tr className="px-4  bg-unusualGray p-2.5 ">
                        <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                          Document Name
                        </td>
                        <td className=" px-4 py-2.5 text-xs font-bold">
                          File Type
                        </td>
                        <td className=" px-4 py-2.5 text-xs font-bold">
                          Upload Date / Time
                        </td>
                        <td className=" px-4 py-2.5 text-xs font-bold">
                          Action
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                          DOC-29384.pdf
                        </td>
                        <td className=" text-xs text-[#11192a] px-4 py-2.5">
                          PDF
                        </td>
                        <td className=" text-xs text-[#11192a] px-4 py-2.5">
                          2021-09-14 13:25
                        </td>
                        <td className="cursor-pointer text-xs text-[#11192a] px-4 py-2.5">
                          <PAIconDownload />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </motion.section>
          </main>
        </TransitionScale>
      </DashboardLayout>
    </>
  );
}

export default BulkDetailPage;
