import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const Graph = () => {

  const { buyerOverviewData } = useSelector(
    (state) => state?.paymentExtension
  );

  const approved = buyerOverviewData?.totalApprovedRequest || 0;
  const pending = buyerOverviewData?.totalPendingRequest || 0;
  const rejected = buyerOverviewData?.totalRejectedRequest || 0;

  const [chartData] = useState({
    series: [approved, pending, rejected],
    options: {
      chart: {
        width: 250,
        type: "donut",
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
                fontWeight: "bold",
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: true,
            },
          },
        },
      ],
      legend: {
        show: true,
        position: "bottom",
      },
      colors: ["#07593D", "#F08000", "#FE7B7B"],
      labels: ["Approved", "Pending", "Rejected"],
    },
  });

  return (
    <div className="mr4">
      <div className="chart-wrap">
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="donut"
            width={250}
          />
        </div>
      </div>
    </div>
  );
};

export default Graph;
