import { PAIconLogo, PAIconReceiptRefined } from "assets/images/svgs";
import React, { useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import { motion } from "framer-motion";

// Define the props
const TransactionReceipt = ({
  logoWidth = "30%",
  logoHeight = "10%",
  paymentAmount,
  currency,
  senderDetails,
  beneficiaryDetails,
  transactionType,
  transactionReference,
  description,
  paymentDate,
  onClose
}) => {
  const modalContentRef = useRef(null);
  const [showDownload, setShowDownload] = useState(true);

  const handleDownload = () => {
    setShowDownload(false);
    const element = modalContentRef.current;

    // Get the dimensions of the modal content
    const width = element.offsetWidth;
    const height = element.offsetHeight + 20;

    // Set the options for the PDF
    const options = {
      filename: "receipt.pdf",
      jsPDF: { unit: "px", format: [width, height], orientation: "portrait" },
      image: { type: "jpeg", quality: 1 },
      output: "dataurlnewwindow",
      html2canvas: { scale: 3 },
    };

    // Generate the PDF
    html2pdf().set(options).from(element).save();

    setShowDownload(true);

    setTimeout(() => {
      onClose();
    }, 3000);
  };

  return (
    <div className="fixed h-full top-0 left-0 w-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.2 } }}
        exit={{ opacity: 0, transition: { delay: 0.15 } }}
        ref={modalContentRef}
        className="bg-white p-7 rounded-md max-w-xl flex justify-center flex-col w-full"
      >
        <div className="flex justify-between items-center pb2">
          <PAIconLogo width={logoWidth} height={logoHeight} />
          <div className="flex gap-2">
            {showDownload && (
              <button
                onClick={() => {
                  setShowDownload(false);
                  setTimeout(() => handleDownload(), 0.001);
                }}
                className="bg-[#07593D] flex items-center justify-center p-2 text-white rounded-md text-sm px-5"
              >
                Download
              </button>
            )}
            <button
              onClick={() => onClose()}
              className="border border-[#07593D] rounded p-1 px-6"
            >
              Close
            </button>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <PAIconReceiptRefined className="mb-[-30px] w-72" />
          <div className="text-black font-bold text-xl mb-0 border-b border-[#00929F] w-full flex items-center justify-center">
            Payment receipt
          </div>
        </div>

        <div className="text-[#7A7A7A] text-center flex items-center justify-between text-xs font-normal mt-5">
          <div className="flex flex-col gap-1 text-left">
            <p className="text-[#222] text-sm font-bold italic">Payment Amount</p>
            <p className="text-[#07593D] font-bold">{paymentAmount} - Success</p>
          </div>
          <div className="flex flex-col gap-1 text-left">
            <p className="text-[#222] text-sm font-bold italic">Currency</p>
            <p className="text-[#7A7A7A] text-xs font-semibold flex justify-end">{currency}</p>
          </div>
        </div>

        <div className="text-[#7A7A7A] text-center flex gap-1 justify-between text-xs font-normal mt-5">
          <div className="flex flex-col gap-1 text-left">
            <p className="text-black text-sm font-bold italic">Sender details</p>
            <p className="text-[#7A7A7A] font-semibold">{senderDetails.name}</p>
            <p className="text-[#7A7A7A] font-semibold">{senderDetails.bank} | {senderDetails.accountNumber}</p>
          </div>
          <div className="flex flex-col items-end gap-1">
            <p className="text-[#222] text-sm font-bold">Beneficiary details</p>
            <p className="text-[#7A7A7A] font-semibold flex">{beneficiaryDetails.name}</p>
            <p className="text-[#7A7A7A] font-semibold flex justify-end">{beneficiaryDetails.bank} | {beneficiaryDetails.accountNumber}</p>
          </div>
        </div>

        <div className="text-[#7A7A7A] text-center flex items-center justify-between text-xs font-normal mt-5">
          <div className="flex flex-col gap-1 text-left">
            <p className="text-black text-sm font-bold italic">Transaction Type</p>
            <p className="text-[#7A7A7A] font-semibold flex">{transactionType}</p>
          </div>
          <div className="flex flex-col gap-1 text-left">
            <p className="text-[#222] font-bold italic text-right">Transaction Reference</p>
            <p className="text-[#7A7A7A] font-semibold text-right">{transactionReference}</p>
          </div>
        </div>

        <div className="text-[#7A7A7A] text-center flex items-center justify-between text-xs font-normal mb-5 mt-5">
          <div className="flex flex-col gap-1 text-left">
            <p className="text-[#222] text-sm font-bold italic">Description</p>
            <p className="text-[#7A7A7A] font-semibold flex">{description}</p>
          </div>
          <div className="flex flex-col gap-1 text-left">
            <p className="text-[#222] font-bold italic text-right">Payment Date</p>
            <p className="text-[#7A7A7A] font-semibold text-right">{paymentDate}</p>
          </div>
        </div>

        {/* FOOTER */}
        <div className="flex flex-col text-xs items-center justify-center w-full h-auto mt-10">
          <p className="text-[#7A7A7A]">Support</p>
          <p className="text-[#F08000]">customerservice@payedge.com</p>
        </div>
      </motion.div>
    </div>
  );
};

export default TransactionReceipt;

