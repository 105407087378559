import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Details from "./detail";
const OpenMarketDirectFundingDetai = () => {
  return (
    <DashboardLayout
      section="Reimbursement"
      subHeader="Track invoices due for Reimbursement"
    >
      <Details />
    </DashboardLayout>
  );
};

export default OpenMarketDirectFundingDetai;
