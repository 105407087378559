import request from 'apiInstance';
import toast from 'react-hot-toast';

export const getAllTransactions = async () => {
  try {
    const response = await request({
      method: 'get',
      url: '/api/v1/transactions',
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const transactionDetails = async ({ transactionId }) => {
  try {
    const response = await request({
      method: 'get',
      url: `/api/v1/transactions/${transactionId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const createVirtualAccount = async (body) => {
  try {
    const response = await request({
      method: 'post',
      url: '/api/v1/account/create-virtual-account',
      data: body, 
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const fetchAllVirtualAccounts = async () => {
  try {
    const response = await request({
      method: 'get',
      url: '/api/v1/account/virtual-accounts',
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const getAccountOverview = async () => {
  try {
    const response = await request({
      method: 'get',
      url: '/api/v1/account/account-overview',
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const virtualAccountDetails = async ({ accountId }) => {
  try {
    const response = await request({
      method: 'get',
      url: `/api/v1/account/virtual-account/${accountId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const virtualAccountEdit = async ({ accountId, body }) => {
  try {
    const response = await request({
      method: 'put',
      url: `/api/v1/account/virtual-account/${accountId}`,
      data: body
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const virtualAccountDelete = async ({ accountId }) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/account/virtual-account/${accountId}?deleted=true`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const createExternalAccount = async (body) => {
  try {
    const response = await request({
      method: 'post',
      url: '/api/v1/account/external-account',
      data: body, 
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const fetchAllExternalAccounts = async () => {
  try {
    const response = await request({
      method: 'get',
      url: '/api/v1/account/external-accounts',
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const externalAccountDelete = async ({ accountId }) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/account/external-account/${accountId}?deleted=true`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const externalAccountDetails = async ({ accountId }) => {
  try {
    const response = await request({
      method: 'get',
      url: `/api/v1/account/external-account/${accountId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const externalAccountEdit = async ({ accountId, body }) => {
  try {
    const response = await request({
      method: 'put',
      url: `/api/v1/account/external-account/${accountId}`,
      data: body
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const createVirtualCard = async (body) => {
  try {
    const response = await request({
      method: 'post',
      url: '/api/v1/account/virtual-card',
      data: body, 
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const fetchAllVirtualCards = async () => {
  try {
    const response = await request({
      method: 'get',
      url: '/api/v1/account/virtual-cards',
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const virtualCardDelete = async ({ cardId }) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/account/virtual-card/${cardId}?deleted=true`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const virtualCardDetails = async ({ cardId }) => {
  try {
    const response = await request({
      method: 'get',
      url: `/api/v1/account/virtual-card/${cardId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const virtualCardEdit = async ({ cardId, body }) => {
  try {
    const response = await request({
      method: 'put',
      url: `/api/v1/account/virtual-card/${cardId}`,
      data: body
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};

export const createFundTransfer = async (body) => {
  try {
    const response = await request({
      method: 'post',
      url: '/api/v1/account/fund-transfer',
      data: body, 
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + '!');
  }
};