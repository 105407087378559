import Card from 'shared/Card'
import { useState } from 'react'
import AllInvoiceHeader from './Header'
import DashboardLayout from 'layout/dashboardlayout'
import ImportModal from '../InvoiceModal/ImportModal';

const AllInvoiceContents = () => {
  const [open, setOpen] = useState(false)

  return (
    <DashboardLayout section="Invoice" subHeader="Here is an overview of your invoices">
      <ImportModal isShown={open} onClose={() => setOpen(false)} />
      <Card className="rounded-[10px]">
        <div className='bg-[#FAFAFA] rounded-[10px]'>
          <AllInvoiceHeader setOpen={setOpen} />
        </div>
      </Card>
    </DashboardLayout>
  )
}

export default AllInvoiceContents