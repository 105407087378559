import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/styles/index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'appstate/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { config, keycloakProviderInitConfig } from 'features/keycloak/config';
import SplashScreen from 'shared/SplashScreen';

const keycloak = new Keycloak(config);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={keycloakProviderInitConfig}
    LoadingComponent={<SplashScreen />} 
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
