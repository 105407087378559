import React from "react";
import DebitCardNew from "./new_debitcard";
import { useSelector } from "react-redux";

const FunderCard = () => {
  const { funderDashboardData } = useSelector((state) => state.funder);
  const { allVirtualCardsData } = useSelector((state) => state?.wallet);

  const formatCardNumber = (cardNumber) => {
    if (!cardNumber) {
      return "";
    }

    const sanitizedCardNumber = cardNumber.replace(/\D/g, "");
    const maskedCardNumber = sanitizedCardNumber.replace(
      /(\d{4})(\d{4})(\d{4})(\d{4})/,
      (_, firstPart, middlePart1, middlePart2, lastPart) => {
        const maskedMiddlePart = `${middlePart1.replace(
          /\d/g,
          "*"
        )} ${middlePart2.replace(/\d/g, "*")}`;
        return `${firstPart} ${maskedMiddlePart} ${lastPart}`;
      }
    );

    return maskedCardNumber.trim();
  };

  const formatExpiryDate = (expiryDate) => {
    if (!expiryDate) {
      return "";
    }

    const options = { month: "2-digit", year: "2-digit" };
    return new Date(expiryDate).toLocaleDateString(undefined, options);
  };

  const getFirstCardData = () => {
    const cardData = Array.isArray(allVirtualCardsData?.data)
      ? allVirtualCardsData.data.map((card) => ({
          id: card?.id,
          cardName: card?.cardName,
          cardNumber: formatCardNumber(card?.cardNumber),
          cardCurrency: card?.cardCurrency,
          paymentMethod: card?.paymentMethod,
          balance: card?.balance,
          expiryDate: formatExpiryDate(card?.expiryDate),
        }))
      : [];
    return cardData?.length > 0 ? cardData[0] : null;
  };

  const firstCardData = getFirstCardData();
  return (
    <div className='w-full rounded-lg bg-white bg-opacity-30 shadow-sm mb-5 md:mt-0 mt-3 lg:mt-0 md:mx-2 p-4 flex'>
      {/* Section (60%) */}
      <div className='w-full flex flex-wrap'>
        <section className='w-full lg:w-3/5 lg:pr-4'>
          <div className='w-full lg:w3/5 pr4 mb-4 md:mb-0'>
            <div>
              <DebitCardNew cardData={firstCardData} />
            </div>
          </div>
          <div className='rounded-b-lg border-t-2 border-solid border-[#0EBB68] bg-white shadow-md mt-3 p-3 transition duration-300 ease-in-out transform hover:scale-105'>
            <div className='flex flex-col items-center'>
              <div className='text-black text-2xl font-bold self-start'>
                {funderDashboardData?.data?.totalFundingAmount}
              </div>
              <div className='text-[#565656]   text-[13px] self-end'>
                Total amount in Funding
              </div>
            </div>
          </div>
        </section>

        {/* Section (40%) */}
        <section className='w-full lg:w-2/5'>
          {/* Container for Cards */}
          <div className='flex flex-col'>
            {/* card 1 */}
            <div className='w-full lg:w2/5 mt-5 lg:mt-0'>
              <div className='rounded-l-0 rounded-r-lg border-l-2 border-solid border-[#07593D] bg-white p-3 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer'>
                <div className='flex items-center'>
                  <div className='flex-1 text-[#07593D] font-inter font-bold text-4xl md:text-5xl'>
                    {funderDashboardData?.data?.directFundingRequests}
                  </div>
                  <div className='flex-1 text-[#565656]    text-[13px]'>
                    Direct funding request
                  </div>
                </div>
                <div>
                  <img src='/colaspe-arrow.png' alt='#' className='ml-auto' />
                </div>
              </div>
            </div>

            {/* card 2 */}
            <div className='w-full lg:w2/5 mt-5 lg:mt0'>
              <div className='rounded-l-0 rounded-r-lg border-l-2 border-solid border-[#F08000] bg-white p-3 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer'>
                <div className='flex items-center'>
                  <div className='flex-1 text-[#F08000] font-inter font-bold md:text-5xl text-4xl'>
                    {funderDashboardData?.data?.overduePayments}
                  </div>
                  <div className='flex-1 text-[#565656]    text-[13px]'>
                    Overdue payment
                  </div>
                </div>
                <div>
                  <img src='/colaspe-arrow.png' alt='#' className='ml-auto' />
                </div>
              </div>
            </div>
            <div className='flex flex-wrap justify-between'>
              <div className='w-[45%] md:w1/2 lg:w1/3 xl:w1/4 rounded-b-lg border-t-2 border-solid border-[#F08000] bg-white overflow-hidden shadow-md mt-7 p-2 flex flex-col transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer'>
                <div className='flex items-end'>
                  <img
                    src='/colaspe-up-arrow.png'
                    alt='#'
                    className='ml-auto'
                  />
                </div>

                <div className='text-black font-inter text-xl font-bold flex flex-col items-center'>
                  {funderDashboardData?.data?.payables}
                </div>
                <div className='text-[#565656]   text-[13px] text-center'>
                  Payables
                </div>
              </div>
              <div className='w-[45%] md:w1/2 lg:w1/3 xl:w1/4 rounded-b-lg border-t-2 border-solid border-[#0EBB68] bg-white overflow-hidden shadow-md mt-7 p-2 flex flex-col transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer'>
                <div className='flex items-end'>
                  <img
                    src='/colaspe-up-arrow.png'
                    alt='#'
                    className='ml-auto'
                  />
                </div>
                <div className='text-black font-inter text-xl font-bold flex flex-col items-center'>
                  {funderDashboardData?.data?.receivables}
                </div>
                <div className='text-[#565656]   text-[13px] text-center'>
                  Receivables
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FunderCard;
