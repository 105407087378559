import Modal from "layout/modal/Modal";
import React from "react";
import LockModalContent from "./LockModalContent";

const LockModal = ({ isShown, onClose, handleLock }) => {
  return (
    <Modal isShown={isShown} hide={onClose}>
      <LockModalContent hide={onClose} handleLock={handleLock} />
    </Modal>
  );
};

export default LockModal;
