import SingleBulkOption from "./directFunding/option";
import DirectFundingHeader from "./headers/DirectFundingHeader";

function DirectFunding() {
  return (
    <div>
      <>
        <DirectFundingHeader />
        <SingleBulkOption />
      </>
    </div>
  );
}

export default DirectFunding;
