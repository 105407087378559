import { FONTSIZES, FONTWEIGHTS } from "constants/font-spec";
import styled from "styled-components";
export const SidebarContainer = styled.div.attrs({
  className:
    "bg-primaryColor flex flex-col justify-between text-white flex flex-col scrollbar-hide justify-center",
})`
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 24px;
  padding-bottom: 20px;
`;

export const SideNavLinkText = styled.p`
  line-height: 17px;
  font-family: "Inter", sans-serif;
  margin-right: 40px;
  font-size: ${FONTSIZES.base};
  font-weight: ${FONTWEIGHTS.normal};
`;

export const SidebarLinkWrapper = styled.div`
  margin-top: 28px;
  & > :first-child {
    margin-bottom: 5px;
  }

  .nav-link {
    white-space: nowrap;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 20.35px;
    padding: 10px 30.583px 10px 25px;
    margin: 5px 0 5px 0;
    @media only screen and (min-width: 768px) {
      padding: 10px 30.583px 10px 30.25px;
    }
  }
`;
