import React, { useState, useEffect } from "react";

const MultiSelectComponent = ({ setSelectedReason }) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setOptions([
      { id: 1, label: "Price misalignment" },
      { id: 2, label: "Funding constraints" },
      { id: 3, label: "Preferred terms not met" },
      { id: 4, label: "Seeking better offers" },
      { id: 5, label: "Invoice already funded" },
    ]);
  }, []); // Empty dependency array ensures this effect runs only once, on component mount

  useEffect(() => {
    setSelectedReason(selectedOptions);
  }, [selectedOptions, setSelectedReason]);

  const toggleOption = (option) => {
    setSelectedOptions((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((item) => item !== option);
      } else {
        return [...prevSelected, option];
      }
    });
  };

  return (
    <div className="space-y-2 text-sm">
      <div className="flex flex-wrap">
        {options.map((option) => (
          <div
            key={option.id}
            className={`border rounded-md p-2 mr-2 mb-2 cursor-pointer ${
              selectedOptions.includes(option)
                ? "bg-[#CDECDB] text-primaryColor"
                : "bg-gray-200"
            }`}
            onClick={() => toggleOption(option)}
          >
            {option.label}
          </div>
        ))}
      </div>

      <div className="flex text-sm">
        <span>Complaints:</span>

        {/* <div className="flex flex-wrap">
          {selectedOptions.map((option) => (
            <span
              key={option.id}
              className="border border-[#F08000] text-primaryColor rounded-md mb-3  px-2 py-2 ml-2"
            >
              {option.label}
              <button
                className="ml-1"
                onClick={() =>
                  setSelectedOptions((prevSelected) =>
                    prevSelected.filter((item) => item !== option)
                  )
                }
              >
                &#10006;
              </button>
            </span>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default MultiSelectComponent;
