import React from 'react';
import { useSelector } from 'react-redux';

const PurchaseOrderModal = ({ id }) => {
  const { allPOs } = useSelector((state) => state?.purchaseOrder);
  const poData = allPOs?.data?.find((cur) => cur?.id === id);
  return (
    <div>
      <div className="max-w-[40rem] px-4 pb-4 md:px-[32px] md:pb-[32px] mx-auto">
        {/* <!-- Grid --> */}
        <div className="mb-4 flex justify-between items-center">
          <div>
            <h2 className="text-lg font-bold text-gray-800 ">
              {`Purchase Order | ${poData?.purchaseOrderReference}`}
            </h2>
          </div>
        </div>

        {/* <!-- Grid --> */}
        <div className="grid md:grid-cols-2 gap-3">
          <div>
            <div className="grid space-y-3">
              <dl className="grid sm:flex gap-x-3 text-xs">
                <dt className="min-w-[150px] max-w-[200px] text-gray-500 font-bold text-xs">
                  Created Date:
                </dt>
                <dd className="text-gray-800 text-xs">{poData?.createdDate}</dd>
              </dl>

              <dl className="grid sm:flex gap-x-3 text-sm">
                <dt className="min-w-[150px] max-w-[200px] text-gray-500 font-bold text-xs">
                  Payment Due Date:
                </dt>
                <dd className="text-gray-800 text-xs">{poData?.dueDate}</dd>
              </dl>
            </div>
          </div>
          {/* <!-- Col --> */}
        </div>

        <div className="grid grid-cols-2 items-center pt-5 space-x-40">
          <div className="flex items-center">
            <div className="flex bg-[#c4c4c4] p-4 px-6 py-5 rounded-lg m-3 cursor-pointer -ml-0"></div>
            <div>
              <h2 className="font-semibold text-sm">
                {poData?.buyerCompanyName}
              </h2>
              <h3 className="text-[#626679] text-xs">Company</h3>
            </div>
          </div>
          <div className="flex">
            <button
              className={`${
                poData?.status === 'Cancelled'
                  ? 'bg-red-300 text-red-500'
                  : 'bg-greenIce text-greenHaze'
              } font-bold py-2 px-8 rounded-md text-xs`}
            >
              {poData?.status}
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 pt-5 space-x-40">
          <div className="md:w-[150px]">
            <h2 className="font-semibold text-sm mb-1">From:</h2>
            <h3 className="text-[#626679] text-xs">
              <p className="mb-[5px]">{poData?.buyerCompanyAddress}</p>
              <p className="mb-[5px]">{poData?.buyerCompanyEmail}</p>
              <p>{poData?.buyerCompanyPhoneNumber}</p>
            </h3>
          </div>

          <div className="md:w-[150px]">
            <h2 className="font-semibold text-sm mb-1">Bill to:</h2>
            <h3 className="text-[#626679] text-xs">
              <p className="mb-[5px]">{poData?.supplierCompanyAddress}</p>
              <p className="mb-[5px]">{poData?.supplierCompanyEmail}</p>
              <p>
                {poData?.supplierCompanyPhoneNumber === null
                  ? ''
                  : `${poData?.supplierCompanyPhoneNumber}`}
              </p>
            </h3>
          </div>
        </div>

        <div className="h-[5px] w-full bg-[#F6F5FA] mt-[38px]"></div>

        <h1 className="mb-[20px] mt-[38px] font-bold text-md">Descriptions</h1>

        {/* <!-- Table --> */}
        <div className="rounded-lg space-y-4">
          <div className="hidden sm:grid sm:grid-cols-5 bg-[#efefef] h-full gap-2 py-2 px-3 ">
            <div className="sm:col-span-2 text-xs font-medium text-black uppercase">
              Item
            </div>
            <div className="text-left text-xs font-medium text-black uppercase">
              Quantity
            </div>
          </div>
          <div className="hidden sm:block"></div>
          {poData?.items?.map((item, idx) => (
            <div key={idx} className="grid grid-cols-3 sm:grid-cols-5 gap-2">
              <div className="col-span-full sm:col-span-2">
                <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase bg-black">
                  Item
                </h5>
                <p className="font-medium text-gray-800 text-xs">
                  {item?.productName}
                </p>
              </div>
              <div>
                <h5 className="sm:hidden text-xs font-medium text-gray-500 uppercase">
                  Quantity
                </h5>
                <p className="text-gray-800 text-xs">{item?.quantity}</p>
              </div>
            </div>
          ))}
          <div className="sm:hidden border-b border-gray-200 dark:border-gray-700"></div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrderModal;
