import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllDisputeTickets,
  initiateDisputeResolution,
} from "appstate/api/help-and-support";

const initialState = {
  allDisputeTicketsData: [],
};

export const disputeResolution = createAsyncThunk(
  "helpandsupport/disputeResolution",
  initiateDisputeResolution
);

export const allDisputeTickets = createAsyncThunk(
  "helpandsupport/allDisputeTickets",
  getAllDisputeTickets
);

export const helpAndSupportSlice = createSlice({
  name: "helpAndSupport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(disputeResolution.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(disputeResolution.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(disputeResolution.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(allDisputeTickets.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(allDisputeTickets.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allDisputeTicketsData = action.payload;
    });

    builder.addCase(allDisputeTickets.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default helpAndSupportSlice.reducer;
