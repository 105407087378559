import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import PendingBidRequestCard from "./cards/PendingBidRequestCard";
import { useDispatch } from "react-redux";
import {
  // cancelBid,
  getInvoiceBidByStatus,
} from "appstate/openMarket/openMarketSlice";
// import LoadingScreen from "shared/LoadingScreen";
// import EmptyPage from "features/roleManager/Dashbaord/components/EmptyPage";

function OngoingBidRequest({ pendingQuery }) {
  const dispatch = useDispatch();
  // const [offerId, setOfferId] = useState("");

  useEffect(() => {
    dispatch(
      getInvoiceBidByStatus({ page: 0, pageSize: 10, status: "Pending" })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getInvoiceBidByStatus({
        page: 0,
        pageSize: 10,
        status: "Pending",
        searchQuery: pendingQuery,
      })
    );
  }, [dispatch, pendingQuery]);

  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  // const { isLoading, getInvoiceBidByStatusData } = useSelector(
  //   (state) => state?.openMarket
  // );

  // Handle Cancel
  const handleCancel = () => {
    // dispatch(cancelBid({ offerId })).then((data) => {
    //   if (data?.payload?.success) {
    //     setCancelModalOpen(false);
    //     toast.success("Invoice Cancelled Successfully");
    //     dispatch(
    //       getInvoiceBidByStatus({ page: 0, pageSize: 10, status: "Pending" })
    //     );
    //   }
    // });
    toast.success("Invoice Cancelled Successfully");
  };

  return (
    <TransitionOpacity className=" min-h-[75vh] h-full  bg-unusualGray rounded-[5px] p-6">
      <div className="w-full mb-4 flex items-center gap-2.5">
        <span className="py-2.5">Filter Bids:</span>

        <select className=" text-sm font-medium border border-[#959595] rounded-[5px] px-5 py-2.5 outline-none cursor-pointer">
          <option value="" key="">
            All Ongoing Bids
          </option>
          <option value="" key="">
            Bid Active Bids
          </option>
          <option value="" key="">
            Posted Bids
          </option>
        </select>
      </div>
      <div className="flex flex-col gap-4 ">
        <PendingBidRequestCard
          cancelInvoice={setCancelModalOpen}
          // setOfferId={setOfferId || "N/A"}
          // bidOfferId={getInvoiceBidByStatusData[0]?.offerId || "N/A"}
          // key={getInvoiceBidByStatusData[0]?.invoiceId || "N/A"}
          // cancelInvoice={setCancelModalOpen || "N/A"}
          // id={getInvoiceBidByStatusData[0]?.invoiceId || "N/A"}
          // invoiceNumber={
          //   getInvoiceBidByStatusData[0]?.invoiceReference || "N/A"
          // }
          // buyerCompanyName={
          //   getInvoiceBidByStatusData[0]?.buyerCompanyName || "N/A"
          // }
          // supplierCompanyName={
          //   getInvoiceBidByStatusData[0]?.supplierCompanyName || "N/A"
          // }
          // description={getInvoiceBidByStatusData[0]?.description || "N/A"}
          // invoiceAmount={getInvoiceBidByStatusData[0]?.invoiceAmount || "N/A"}
          // lastModifiedDate={
          //   getInvoiceBidByStatusData[0]?.lastModifiedDate || "N/A"
          // }
          // dueDate={getInvoiceBidByStatusData[0]?.dueDate || "N/A"}
          // numberOfBids={getInvoiceBidByStatusData[0]?.numberOfBids || "N/A"}
        />
      </div>
      {/* Cancel Modal */}
      <AnimatePresence>
        {cancelModalOpen && (
          <ViewDocumentModal
            onAction={handleCancel}
            onClose={() => setCancelModalOpen(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Cancel
              </span>{" "}
              this invoice
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </TransitionOpacity>
  );
}

export default OngoingBidRequest;
