import React, { useState, useRef, useEffect } from "react";
import { PAIconCancel, PAAddFileImg } from "assets/images/svgs";

const GeneralDocumentModal = ({
  onCloseModal,
  setSelectedFileName,
  selectedFileName,
  // onCloseUpload,
  onAction,
}) => {
  const [progress, setProgress] = useState(0);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);

  const handleTextClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setSelectedFileName(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
      setProgress(0); // Reset progress to 0 when a new file is selected
      simulateUploadProgress(); // Start simulating upload progress
    }
  };

  const simulateUploadProgress = () => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 10;
      });
    }, 100); // Increase progress every 100 milliseconds
  };

  const handleUpload = () => {
    // onCloseUpload();
    onAction();
  };

  const handleCloseModal = () => {
    onCloseModal();
  };

  useEffect(() => {
    return () => {
      // Clean up the URL object to avoid memory leaks
      if (previewUrl) URL.revokeObjectURL(previewUrl);
    };
  }, [previewUrl]);

  return (
    <div className="">
      <div>
        <div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
          <div className="modal-overlay"></div>
          <div className="modal-container px-5 z-[9999] max-h-[600px] overflow-auto mt-14 ml-16 bg-white w-1/2 p-4 rounded-lg shadow-lg">
            <div className="modal-header mx-4 mb-10 flex justify-between items-center">
              <h2 className="text-l font-semibold">Upload Document</h2>
              <PAIconCancel
                className="cursor-pointer"
                onClick={handleCloseModal}
              />
            </div>
            <div
              className={`bg-[#F8FAFB] rounded-md mb-10 modalbody border-dashed border-2 border-blue-200 ${selectedFileName ? "p-8" : "p-24"} text-center flex flex-col items-center justify-center`}
            >
              {!selectedFileName && <PAAddFileImg />}
              {selectedFileName ? (
                <div>
                  <p className="mt-4">
                    Uploaded file: <strong>{selectedFileName?.name}</strong>
                  </p>
                  {previewUrl && (
                    <div className="mt-4">
                      {selectedFileName.type.includes("image") ? (
                        <img
                          src={previewUrl}
                          alt="Preview"
                          className="max-w-full h-auto"
                        />
                      ) : selectedFileName.type.includes("pdf") ? (
                        <embed
                          src={previewUrl}
                          type="application/pdf"
                          className="max-w-full h-auto"
                          width="100%"
                          height="400px"
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <p>
                    Drag file here or click to upload or{" "}
                    <span
                      onClick={handleTextClick}
                      className="text-blue-500 cursor-pointer"
                    >
                      choose a file
                    </span>
                  </p>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    accept="image/*,application/pdf"
                  />
                </div>
              )}
            </div>

            {selectedFileName && (
              <div className="w-full mt-4">
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-secondaryColor bg-blue-200">
                        {progress.toFixed(0)}%
                      </span>
                    </div>
                  </div>
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                    <div
                      style={{ width: `${progress}%` }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-secondaryColor"
                    ></div>
                  </div>
                </div>
              </div>
            )}

            <div className="modal-footer text-right border-t border-slate-400 pt-6 pb-4">
              <button
                onClick={handleCloseModal}
                className="btn bg-[#CCCCCC] text-sm text-black mr-4 rounded-md px-4 py-2"
              >
                Discard
              </button>
              <button
                onClick={handleUpload}
                disabled={selectedFileName === null}
                className={`btn text-sm text-white rounded-md px-6 py-2 bg-primaryColor`}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralDocumentModal;
