import { AnimatePresence, motion } from "framer-motion";
import { PAIconArrowLeftGreen, PAIconSabModel } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import toast from "react-hot-toast";
import OfferDetailsModal from "./modal/OfferDetailsModal";
import InvoiceLockSuccessModal from "./modal/InvoiceLockSuccessModal";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelBid,
  getInvoiceById,
  lockInvoice,
  placeInvoiceBid,
  updateInvoice,
  getInvoiceBidByStatus,
} from "appstate/openMarket/openMarketSlice";
import { toggleCancelButtonOff } from "./buttonSlice";
import InvoiceDetails from "features/openMarket/common/components/InvoiceDetails";
// import ViewMoreBidders from "features/openMarket/supplier/components/ViewMoreBidders";
import CardLoading from "./cards/CardLoading";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import { timeAgo } from "utilities/helper/timeAgo";

function ViewInvoiceDetails() {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offerId = searchParams.get("offerId");

  const dispatch = useDispatch();

  // console.log(invoiceByIdData);

  // console.log();

  // const invoiceByIdData = allOpenMarketInvoicesData?.data?.find(
  //   (data) => data?.invoiceId === id
  // );

  const navigate = useNavigate();
  const buttonClicked = useSelector(
    (state) => state?.invoiceButton?.buttonClicked
  );

  useEffect(() => {
    dispatch(getInvoiceById({ id }));
  }, [dispatch, id, offerId]);

  const { invoiceByIdData, getAllBidsData, isLoading } = useSelector(
    (state) => state?.openMarket
  );

  const [bidAmount, setBidAmount] = useState("");
  const [discountRate, setDiscountRate] = useState("");
  const [noteToSupplier, setNoteToSupplier] = useState("");

  const [bidAmountOffer, setBidAmountOffer] = useState("");
  const [discountRateOffer, setDiscountRateOffer] = useState("");
  const [noteToSupplierOffer, setNoteToSupplierOffer] = useState("");

  const [bidAmountNoRecourse, setBidAmountNoRecourse] = useState("");
  const [discountRateNoRecourse, setDiscountRateNoRecourse] = useState("");
  const [noteToSupplierNoRecourse, setNoteToSupplierNoRecourse] = useState("");

  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const isDisabled =
    bidAmount === "" || discountRate === "" || noteToSupplier === "";

  const isDisabledNoRecourse =
    bidAmountNoRecourse === "" ||
    discountRateNoRecourse === "" ||
    noteToSupplierNoRecourse === "";

  const lockDisabled = isDisabled || isDisabledNoRecourse;

  // const lockDisabled = isDisabled || isDisabledNoRecourse;

  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [lockModalOpen, setLockModalOpen] = useState(false);
  const [offerDetailModalOpen, setOfferDetailModalOpen] = useState(false);
  const [invoiceLockModalOpen, setInvoiceLockModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  // Handle Cancel
  const handleCancel = () => {
    dispatch(cancelBid({ offerId })).then((data) => {
      if (data?.payload?.success) {
        setCancelModalOpen(false);
        dispatch(toggleCancelButtonOff());
        toast.success("Invoice Cancelled Successfully");
        dispatch(
          getInvoiceBidByStatus({ page: 0, pageSize: 10, status: "Pending" })
        );
      }
    });
  };

  function handleGoBack() {
    dispatch(toggleCancelButtonOff());
    navigate(-1); // Navigate back one step
  }

  // Handle Bid
  const handleBid = () => {
    const body = { amount: bidAmount, discountRate, note: noteToSupplier };
    dispatch(placeInvoiceBid({ id, body })).then((data) => {
      if (data?.payload?.success) {
        toast.success("Your bid has been sent succesfully");
        setBidAmount("");
        setDiscountRate("");
        setNoteToSupplier("");
      }
    });
  };

  // Handle Submit

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (bidAmount && discountRate !== "") {
    //   setBidModalOpen(true);
    // } else {
    //   toast.error("Fill appropraite fields and try again!");
    // }
  };

  // Handle Lock
  const handleLock = () => {
    dispatch(lockInvoice({ id })).then((res) => {
      if (res?.payload?.success) {
        setInvoiceLockModalOpen(true);
        toast.success("Invoice Locked Successfully");
        setBidAmountOffer("");
        setDiscountRateOffer("");
        setNoteToSupplierOffer("");
        setOfferDetailModalOpen(false);
      }
    });
    setLockModalOpen(false);
    // setOfferDetailModalOpen(true);
  };

  // Handle Offer Detail
  const handleOfferDetail = () => {
    dispatch(lockInvoice({ id })).then((res) => {
      if (res?.payload?.success) {
        setInvoiceLockModalOpen(true);
        toast.success("Invoice Locked Successfully");
        setBidAmountOffer("");
        setDiscountRateOffer("");
        setNoteToSupplierOffer("");
        setOfferDetailModalOpen(false);
      }
    });
  };

  // Handle Update Detail
  const handleUpdate = () => {
    const body = { amount: bidAmount, discountRate, note: noteToSupplier };
    dispatch(updateInvoice({ id: offerId, body })).then((res) => {
      if (res?.payload?.success) {
        toast.success("Your bid has been updated succesfully");
        setBidAmount("");
        setDiscountRate("");
        setNoteToSupplier("");
      }
    });
  };

  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <header onClick={handleGoBack} className="w-full">
          <div className="flex cursor-pointer w-fit gap-1 items-center">
            <PAIconArrowLeftGreen />
            <span className=" text-primaryColor text-sm">Go back</span>
          </div>
        </header>

        <main className="flex flex-col semi-lg:flex-row pb-8 gap-8 w-full ">
          <InvoiceDetails
            invoiceByIdData={invoiceByIdData}
            setLockModalOpen={setLockModalOpen}
          />

          <motion.section
            initial={{
              y: -20,
            }}
            animate={{
              y: 0,
            }}
            exit={{
              y: 20,
            }}
            transition={{
              delay: 0.8,
            }}
            className="flex flex-col gap-3  pt-10 pb-24 w-full bg-white rounded-[10px]"
          >
            {/* Section */}
            <div className="px-6 flex flex-col gap-3 ">
              <header className=" w-full flex justify-between">
                <h1 className=" text-[18px] font-bold">Bid Invoice</h1>
                {buttonClicked && (
                  <motion.button
                    onClick={(event) => {
                      setCancelModalOpen(true);
                    }}
                    whileTap={{ scale: 0.9 }}
                    className="flex border text-sm justify-center gap-2 text-white bg-[#F08000] rounded-[5px] items-center px-5 py-2.5 "
                  >
                    <span className="hidden sm:inline">Cancel Bid</span>{" "}
                    <span className=" flex sm:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    </span>
                  </motion.button>
                )}
              </header>

              <main className="w-full pb-5 flex flex-col">
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col gap-3.5 "
                >
                  <h2 className=" font-[500]">Bid on invoice</h2>

                  <span className="text-sm text-[#959595]">
                    Please input your bid amounts and discount rates for both
                    recourse and without recourse options. The supplier will
                    subsequently make a selection from the provided options.
                  </span>
                  <div
                    style={{
                      borderBottom: "1px solid rgba(168, 168, 168, 0.53)",
                    }}
                    className="flex flex-col  pb-6"
                  >
                    <div
                      style={{
                        borderRadius: "5px",
                        border: "1px solid rgba(222, 222, 222, 0.30)",
                        background: "#FFF",
                        boxShadow: "4px 4px 8px 1px rgba(243, 219, 191, 0.26)",
                      }}
                      className="flex lg:flex-col gap-3 w-full"
                    >
                      <div className="flex w-full">
                        <button
                          style={{
                            boxShadow:
                              "0px 1px 1.9px 0px rgba(233, 230, 230, 0.38)",
                          }}
                          className={`w-1/2 whitespace-nowrap px-5 flex justify-center text-sm py-2.5 text-left ${
                            activeTab === "tab1"
                              ? " text-primaryColor border-b   border-secondaryColor font-bold"
                              : ""
                          }`}
                          onClick={() => handleTabClick("tab1")}
                        >
                          With Recourse
                        </button>
                        <button
                          style={{
                            boxShadow:
                              "0px 1px 1.9px 0px rgba(233, 230, 230, 0.38)",
                          }}
                          className={`w-1/2 whitespace-nowrap flex justify-center text-sm px-5 py-2.5 text-left ${
                            activeTab === "tab2"
                              ? " text-primaryColor border-b   border-secondaryColor  font-bold"
                              : ""
                          }`}
                          onClick={() => handleTabClick("tab2")}
                        >
                          Without Recourse
                        </button>
                      </div>
                    </div>
                    <div className="w-full mt-4">
                      {activeTab === "tab1" && (
                        <div className="flex w-full text-sm flex-col gap-4">
                          <div className=" flex  justify-between">
                            <input
                              className=" w-[48%] rounded-[5px] border border-primaryColor outline-none  py-3 px-5"
                              type="number"
                              placeholder="Enter Bid Amount"
                              value={bidAmount}
                              onChange={(e) => {
                                if (e.target.value < 0) {
                                  setBidAmount(0);
                                } else {
                                  setBidAmount(e.target.value);
                                }
                              }}
                            />
                            <input
                              className="w-[48%] rounded-[5px] border border-primaryColor outline-none py-3 px-5"
                              type="number"
                              placeholder="Discount Rate %"
                              value={discountRate}
                              onChange={(e) => {
                                if (e.target.value < 0) {
                                  setDiscountRate(0);
                                } else {
                                  setDiscountRate(e.target.value);
                                }
                              }}
                            />
                          </div>

                          <input
                            className=" rounded-[5px] text-[#959595] border border-primaryColor outline-none w-full pt-2 pb-10 px-5"
                            type="text"
                            placeholder="Note to Supplier (optional)"
                            value={noteToSupplier}
                            onChange={(e) => setNoteToSupplier(e.target.value)}
                          />

                          <footer className=" w-full flex justify-between">
                            <div className=" text-[#959595] w-[78%]">
                              With recourse, the supplier must reimburse the
                              funded invoice amount.{" "}
                              <span className="text-[11px] text-primaryColor">
                                (Learn more)
                              </span>
                            </div>

                            <motion.button
                              whileTap={{ scale: 0.9 }}
                              disabled={isDisabled}
                              className={`text-white ${
                                isDisabled
                                  ? "bg-[#DFE3E8]"
                                  : "bg-secondaryColor"
                              }  px-5 py-2.5 rounded-[5px] font-medium`}
                            >
                              Apply
                            </motion.button>
                          </footer>
                        </div>
                      )}
                      {activeTab === "tab2" && (
                        <div className="flex text-sm flex-col gap-4">
                          <div className=" flex  justify-between">
                            <input
                              className=" w-[48%] rounded-[5px] border border-primaryColor outline-none  py-3 px-5"
                              type="number"
                              placeholder="Enter Bid Amount"
                              value={bidAmountNoRecourse}
                              onChange={(e) => {
                                if (e.target.value < 0) {
                                  setBidAmountNoRecourse(0);
                                } else {
                                  setBidAmountNoRecourse(e.target.value);
                                }
                              }}
                            />
                            <input
                              className="w-[48%] rounded-[5px] border border-primaryColor outline-none py-3 px-5"
                              type="number"
                              placeholder="Discount Rate %"
                              value={discountRateNoRecourse}
                              onChange={(e) => {
                                if (e.target.value < 0) {
                                  setDiscountRateNoRecourse(0);
                                } else {
                                  setDiscountRateNoRecourse(e.target.value);
                                }
                              }}
                            />
                          </div>

                          <input
                            className=" rounded-[5px] text-[#959595] border border-primaryColor outline-none w-full pt-2 pb-10 px-5"
                            type="text"
                            placeholder="Note to Supplier (optional)"
                            value={noteToSupplierNoRecourse}
                            onChange={(e) =>
                              setNoteToSupplierNoRecourse(e.target.value)
                            }
                          />

                          <footer className=" w-full flex justify-between">
                            <div className=" text-[#959595] w-[78%]">
                              With recourse, the supplier must reimburse the
                              funded invoice amount.{" "}
                              <span className="text-[11px] text-primaryColor">
                                (Learn more)
                              </span>
                            </div>

                            <motion.button
                              whileTap={{ scale: 0.9 }}
                              disabled={isDisabledNoRecourse}
                              className={`text-white ${
                                isDisabledNoRecourse
                                  ? "bg-[#DFE3E8]"
                                  : "bg-secondaryColor"
                              }  px-5 py-2.5 rounded-[5px] font-medium`}
                            >
                              Apply
                            </motion.button>
                          </footer>
                        </div>
                      )}
                    </div>
                  </div>

                  <footer className="flex flex-col gap-4 ">
                    <div className="flex flex-col gap-2">
                      <span className=" text-primaryColor font-bold">
                        Summary
                      </span>

                      <div className="flex justify-between text-sm text-[#000]">
                        <span>
                          With Recourse:
                          <span className="red text-white">out</span>{" "}
                        </span>
                        <span className="text-[#959595]">
                          Bid amount<span className=" text-red">*</span> -{" "}
                          <span className="text-[#000] font-medium">
                            {bidAmount}
                          </span>
                        </span>

                        <span className="text-[#959595]">
                          Discount Rate<span className=" text-red">*</span> -{" "}
                          <span className="text-[#000] font-medium">
                            {discountRate}
                          </span>
                        </span>
                      </div>

                      <div className="flex justify-between text-sm text-[#000]">
                        <span>Without Recourse: </span>
                        <span className="text-[#959595]">
                          Bid amount<span className=" text-red">*</span> -{" "}
                          <span className="text-[#000] font-medium">
                            {bidAmountNoRecourse}
                          </span>
                        </span>

                        <span className="text-[#959595]">
                          Discount Rate<span className=" text-red">*</span> -{" "}
                          <span className="text-[#000] font-medium">
                            {discountRateNoRecourse}
                          </span>
                        </span>
                      </div>
                    </div>
                    <motion.button
                      disabled={lockDisabled}
                      whileTap={{ scale: 0.9 }}
                      type="submit"
                      className={`${
                        lockDisabled ? "bg-[#DFE3E8]" : "bg-primaryColor"
                      } w-full rounded-[5px] text-white mt-3  px-6 py-3`}
                    >
                      {buttonClicked ? "Update Bid" : "Bid Now"}
                    </motion.button>
                  </footer>

                  {/* <motion.button
                    whileTap={{ scale: 0.9 }}
                    type="submit"
                    className=" w-full rounded-[5px] text-white bg-primaryColor px-6 py-3"
                  ></motion.button> */}
                </form>
              </main>
            </div>

            {/* Section */}
            {buttonClicked && (
              <div className="flex flex-col gap-2.5 pt-4 pb-1 px-4">
                <div className=" w-fit p-1.5 font-semibold ">
                  Your previous Bid
                </div>

                <div className="flex flex-col gap-2.5 p-2.5">
                  {/* A card */}
                  {isLoading ? (
                    <ul>
                      <CardLoading />
                      <CardLoading />
                    </ul>
                  ) : (
                    <TransitionOpacity>
                      <li
                        key={getAllBidsData?.data?.bidResponses[0]?.offerId}
                        className="flex cursor-pointer justify-between items-center text-xs py-1.5 mb-2"
                      >
                        <span className="text-[#F08000]">
                          {getAllBidsData?.data?.bidResponses[0]?.currency ||
                            "₦"}
                          {getAllBidsData?.data?.bidResponses[0]?.bidAmount}
                        </span>

                        <span>
                          {timeAgo(
                            getAllBidsData?.data?.bidResponses[0]
                              ?.lastModifiedDate
                          )}
                        </span>
                      </li>
                      <li
                        key={getAllBidsData?.data?.bidResponses[1]?.offerId}
                        className="flex cursor-pointer justify-between items-center text-xs py-1.5 mb-2"
                      >
                        <span className="text-[#F08000]">
                          {getAllBidsData?.data?.bidResponses[1]?.currency ||
                            "₦"}
                          {getAllBidsData?.data?.bidResponses[1]?.bidAmount}
                        </span>

                        <span>
                          {timeAgo(
                            getAllBidsData?.data?.bidResponses[1]
                              ?.lastModifiedDate
                          )}
                        </span>
                      </li>
                      <li
                        key={getAllBidsData?.data?.bidResponses[2]?.offerId}
                        className="flex cursor-pointer justify-between items-center text-xs py-1.5 mb-2"
                      >
                        <span className="text-[#F08000]">
                          {getAllBidsData?.data?.bidResponses[2]?.currency ||
                            "₦"}
                          {getAllBidsData?.data?.bidResponses[2]?.bidAmount}
                        </span>

                        <span>
                          {timeAgo(
                            getAllBidsData?.data?.bidResponses[2]
                              ?.lastModifiedDate
                          )}
                        </span>
                      </li>
                    </TransitionOpacity>
                  )}
                </div>
              </div>
            )}

            {/* Section */}
            <div className="flex flex-col gap-2.5 p-6">
              <div className=" w-fit p-2.5 font-semibold ">Top Bidders</div>

              <div className="flex flex-col gap-2.5 p-2.5">
                {/* A card */}
                {isLoading ? (
                  <ul>
                    <CardLoading />
                    <CardLoading />
                    <CardLoading />
                  </ul>
                ) : (
                  <TransitionOpacity>
                    <li
                      key={getAllBidsData?.data?.bidResponses[0]?.offerId}
                      className="flex cursor-pointer justify-between shadow-sm  items-center text-xs py-1.5 mb-2"
                    >
                      <span className="flex items-center gap-2">
                        <PAIconSabModel />
                        <p>
                          {getAllBidsData?.data?.bidResponses[0]
                            ?.buyerCompanyName || "Dangote"}
                        </p>
                      </span>

                      <span>
                        {getAllBidsData?.data?.bidResponses[0]?.currency || "₦"}
                        {getAllBidsData?.data?.bidResponses[0]?.invoiceAmount ||
                          "5,000,000"}
                      </span>

                      <span>
                        {getAllBidsData?.data?.bidResponses[0]?.discountRate ||
                          "1.7"}
                        %
                      </span>
                      <span>
                        {timeAgo(
                          getAllBidsData?.data?.bidResponses[0]
                            ?.lastModifiedDate
                        ) || "5 months ago"}
                      </span>
                    </li>
                    <li
                      key={getAllBidsData?.data?.bidResponses[1]?.offerId}
                      className="flex cursor-pointer justify-between shadow-sm  items-center text-xs py-1.5 mb-2"
                    >
                      <span className="flex items-center gap-2">
                        <PAIconSabModel />
                        <p>
                          {getAllBidsData?.data?.bidResponses[1]
                            ?.buyerCompanyName || "Stanbic"}
                        </p>
                      </span>

                      <span>
                        {getAllBidsData?.data?.bidResponses[1]?.currency || "₦"}
                        {getAllBidsData?.data?.bidResponses[1]?.invoiceAmount ||
                          "5,000,000"}
                      </span>

                      <span>
                        {getAllBidsData?.data?.bidResponses[1]?.discountRate ||
                          "1.7"}
                        %
                      </span>
                      <span>
                        {timeAgo(
                          getAllBidsData?.data?.bidResponses[1]
                            ?.lastModifiedDate
                        ) || "5 months ago"}
                      </span>
                    </li>
                    <li
                      key={getAllBidsData?.data?.bidResponses[2]?.offerId}
                      className="flex cursor-pointer justify-between shadow-sm  items-center text-xs py-1.5 mb-2"
                    >
                      <span className="flex items-center gap-2">
                        <PAIconSabModel />
                        <p>
                          {getAllBidsData?.data?.bidResponses[2]
                            ?.buyerCompanyName || "Coschar"}
                        </p>
                      </span>

                      <span>
                        {getAllBidsData?.data?.bidResponses[2]?.currency || "₦"}
                        {getAllBidsData?.data?.bidResponses[2]?.invoiceAmount ||
                          "5,000,000"}
                      </span>

                      <span>
                        {getAllBidsData?.data?.bidResponses[2]?.discountRate ||
                          "1.7"}
                        %
                      </span>
                      <span>
                        {timeAgo(
                          getAllBidsData?.data?.bidResponses[2]
                            ?.lastModifiedDate
                        ) || "5 months ago"}
                      </span>
                    </li>
                  </TransitionOpacity>
                )}
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>
      {/* Bid Modal */}
      <AnimatePresence>
        {bidModalOpen && (
          <ViewDocumentModal
            onAction={buttonClicked ? handleUpdate : handleBid}
            onClose={() => setBidModalOpen(false)}
          >
            <span className=" text-center">
              Would you like to proceed with{" "}
              {buttonClicked ? "updating you bid on" : "bidding on"} this
              invoice
              <br />
              <span className="font-bold text-center text-primaryColor">
                {invoiceByIdData?.invoiceReference}
              </span>
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
      {/* Lock Modal */}
      <AnimatePresence>
        {lockModalOpen && (
          <ViewDocumentModal
            onAction={handleLock}
            onClose={() => setLockModalOpen(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Lock
              </span>{" "}
              this invoice
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
      {/* Offer Detail Modal */}
      <AnimatePresence>
        {offerDetailModalOpen && (
          <OfferDetailsModal
            onAction={handleOfferDetail}
            onClose={() => {
              setBidAmountOffer("");
              setDiscountRateOffer("");
              setNoteToSupplierOffer("");
              setOfferDetailModalOpen(false);
            }}
            bidAmount={bidAmountOffer}
            setBidAmount={setBidAmountOffer}
            discountRate={discountRateOffer}
            setDiscountRate={setDiscountRateOffer}
            noteToSupplier={noteToSupplierOffer}
            setNoteToSupplier={setNoteToSupplierOffer}
          />
        )}
      </AnimatePresence>

      {/* Invoice Lock Success Modal */}
      <AnimatePresence>
        {invoiceLockModalOpen && (
          <InvoiceLockSuccessModal
            onClose={() => setInvoiceLockModalOpen(false)}
          />
        )}
      </AnimatePresence>

      {/* Cancel Modal */}
      <AnimatePresence>
        {cancelModalOpen && (
          <ViewDocumentModal
            onAction={handleCancel}
            onClose={() => setCancelModalOpen(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Cancel
              </span>{" "}
              this invoice
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
}

export default ViewInvoiceDetails;
