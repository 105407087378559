import React from "react";
import DashboardLayout from "layout/dashboardlayout";
import Details from "./Detail";

const ReconciliationDetail = () => {
  return (
    <DashboardLayout
      section="Settlement  & Reconciliation"
      subHeader="Here is an overview of your outstanding transactions"
    >
      <Details />
    </DashboardLayout>
  );
};

export default ReconciliationDetail;
