import { PAIconCancel } from "assets/images/svgs";
import React from "react";

import Modal from "layout/modal/Modal";
import AddModalComponent from "./modalcomponent/AddModalComponent";
import { PAIconSearchForFunder } from "assets/images/svgs";

function AddFundModal({ isShown, onClose, handleProceed }) {
  const selectedable = [
    { id: "1", title: "Nestlé Alimentana SA" },
    { id: "2", title: "Dangote" },
    { id: "3", title: "Coca-Cola Company" },
    { id: "4", title: "Piggyvest PLC." },
    { id: "5", title: "Shell" },
    { id: "6", title: "Shell" },
    { id: "7", title: "Shell" },
    { id: "8", title: "Shell" },
    { id: "9", title: "Shell" },
  ];

  return (
    <>
      <Modal
        isShown={isShown}
        hide={onClose}
        accountModal
        className={"mx-auto"}
      >
        <div className="  h-full  rounded-md">
          <div className="sticky top-0 bg-[#FFFFFF] pt-[38px] px-[37px] pb-2">
            {" "}
            <header className="flex justify-between border-b-4 border-whisper200 pb-[16px] ">
              <p class="text-neutral-800 text-base font-bold font-['SF Pro Display']">
                Add funders to your closed market
              </p>
              <div
                onClick={onClose}
                className="flex justify-between items-center gap-x-[10px] cursor-pointer"
              >
                <PAIconCancel className="w-[8px]" />
                <p className="text-sm font-medium text-gray800">Close</p>
              </div>
            </header>
            <div class="w-full h-[59px] py-6  border-stone-300 justify-start items-center gap-6 inline-flex border-b-[1.5px]  ">
              <div>
                <PAIconSearchForFunder />
              </div>

              <div class="justify-start items-center w-full flex">
                <input
                  className=" w-full focus:outline-none  text-neutral-600 text-xl font-normal "
                  type="text"
                  placeholder="Search for funder"
                />
              </div>
            </div>{" "}
          </div>

          <div className="px-[37px]">
            <div class="w-full h-[59px] py-6 border-b-[1.5px] border-stone-300 justify-start items-center gap-6 inline-flex mt-1">
              <div>
                <input
                  type="checkbox"
                  style={{ height: 20, width: 20 }}
                  class="checked:bg-blue-500 cursor-pointer"
                />
              </div>

              <div class="justify-start items-center gap-3 flex">
                <div class="flex-col justify-start items-start gap-[7px] inline-flex">
                  <div class="text-zinc-900 text-sm font-normal ">
                    Select All
                  </div>
                </div>
              </div>
            </div>{" "}
            {selectedable.map((item) => {
              return <AddModalComponent companyName={item.title} />;
            })}
          </div>

          <div
            className="  px-[37px] sticky bottom-0 pb-[38px] pt-4 w-full bg-[#FFFFFF] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]"
            onClick={handleProceed}
          >
            <button class="w-full h-[57px] px-2.5 py-5 bg-[#07593D] rounded-[5px] justify-center items-center gap-2.5 inline-flex cursor-pointer">
              <div class="text-white text-sm font-normal ">
                Proceed to Import Invoices
              </div>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddFundModal;
