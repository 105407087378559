import { allDisbursedInvoice, getAssociatedFunderId } from "appstate/paymentExtension/paymentExtensionSlice";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import Heading from "shared/heading/Heading";

const SingleRequestInvoiceList = () => {
  const { disbursedInvoiceData, isLoading } = useSelector(
    (state) => state?.paymentExtension
  );
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allDisbursedInvoice({ page, pageSize }));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    setData(disbursedInvoiceData?.data);
  }, [disbursedInvoiceData]);

  const navigate = useNavigate();

  const onRowClicked = (row) => {
    dispatch(getAssociatedFunderId(row?.funderId));
    let path = "/payment-extension/singleExtensioninvoicelist/invoicedetails";
    navigate(path);
  };

  const totalPages = disbursedInvoiceData?.meta?.totalElements;

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoice?.invoiceNumber,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyerCompanyName,
      sortable: true,
    },
    {
      name: "Invoice Due Date",
      selector: (row) => row.invoice.dueDate,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoice.createdDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.invoice.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoice.total,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Open" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Rejected request" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "Updated request" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "Pending request" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : row.status === "PAID" ? (
          <p className="text-success">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  return (
    <DashboardLayout>
      <Card className="rounded-lg">
        <div>
          <Heading
            heading={`${data?.length} total Invoices`}
            info="Available for extension"
          />
          <div className="rounded-b-lg px-[24px] pb-[24px]">
            <Table
              columns={columns}
              data={data}
              onRowClicked={onRowClicked}
              pointer
              progressPending={isLoading}
              progressComponent={<Loading />}
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {!isLoading && totalPages > 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              )}
            </div>
          </div>
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default SingleRequestInvoiceList;
