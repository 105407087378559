// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  /* Add any other styles you need for the container */
}
`, "",{"version":3,"sources":["webpack://./src/utilities/helper/styles/elipsis.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,oDAAoD;AACtD","sourcesContent":[".ellipsis-text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  /* Add any other styles you need for the container */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
