import DashboardLayout from "layout/dashboardlayout";
import React, { useState } from "react";
import "../styles/role-manager.css";
import RoleManagerTable from "./components/tables/RoleManagerTable";
import AddUserHeader from "./components/AddUserHeader";
import EmptyPage from "./components/EmptyPage";
import { data } from "./data";
import { AnimatePresence, motion } from "framer-motion";
import ViewMemberModal from "./components/modals/ViewMemberModal";
import AddNewUserModal from "./components/modals/AddNewUserModal";
import ViewDocumentModal from "features/documents/Components/DeleteDocumentModal";
import ViewRoleManager from "features/roleManager/Dashbaord/components/ViewRoleManager";
import ViewRoleFullDetails from "./components/ViewRoleFullDetails";
import EditRoleModal from "./components/modals/edithrole/EditRoleModal";
import AddNewRoleModal from "./components/modals/AddNewRoleModal";
import { useSelector } from "react-redux";

// View Full Message Modal

const RoleManagerDashboard = () => {
  const [selectedId, setSelectedId] = useState("");
  const [toggleViewModal, setToggleViewModal] = useState(false);
  const [toggleRoleScreen, setToggleRoleScreen] = useState(false);
  const [toggleNewRole, setToggleNewRole] = useState(false);
  const [toggleRoleFullDetails, setToggleRoleFullDetails] = useState(false);
  const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
  const [toggleAddUserModal, setToggleAddUserModal] = useState(false);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const [toggleEditRolePermissionModal, setToggleEditRolePermissionModal] =
    useState(false);
  useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [umRole, setUmRole] = useState(null);
  const [editActive, setEditActive] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
  };

  function toggleViewModalOff() {
    setToggleViewModal(false);
  }
  function toggleRoleScreenOn() {
    setToggleRoleScreen(true);
  }
  function toggleNewRoleOn() {
    setToggleNewRole(true);
  }
  function toggleNewRoleOff() {
    setToggleNewRole(false);
  }

  function toggleEditRolePermissionModalOff() {
    setToggleEditRolePermissionModal(false);
  }
  function toggleEditRolePermissionModalOn() {
    setToggleEditRolePermissionModal(true);
  }
  function toggleRoleScreenOff() {
    setToggleRoleScreen(false);
  }

  function toggleRoleFullDetailsOn() {
    setToggleRoleFullDetails(true);
  }
  function toggleRoleFullDetailsOff() {
    setToggleRoleFullDetails(false);
  }

  function toggleAddUserModalOff() {
    setToggleAddUserModal(false);
  }
  function toggleAddUserModalOn() {
    setToggleAddUserModal(true);
  }

  function toggleDeleteModalOff() {
    setToggleDeleteModal(false);
  }
  function toggleDeleteModalOn() {
    setToggleDeleteModal(true);
  }

  const UserRoleManager = () => {
    const columns = ["Permissions", "Member", "Manager", "Admin"];
    const { allRolesData } = useSelector((state) => state.roleManager);
    const { allPermissionsData } = useSelector((state) => state.roleManager);
    const data = allPermissionsData?.data?.data;
    const NewTableHeader = () => {
      return (
        <tr className='w-full font-[500] py-6 border-b border-[#DADADA] text-[14px]'>
          <td className=' px-6 py-2.5 text-[#565656]'>Permissions</td>
          {allRolesData?.data?.data?.map((role, index) => (
            <td key={index} className='w-[10%] py-2.5'>
              <span className='flex justify-center px-4'>{role.roleName}</span>
            </td>
          ))}
        </tr>
      );
    };

    const NewTableSubHeader = ({ title, perId }) => {
      return (
        <tr className='w-full font-[500] bg-[#F9F9F9]  border-t border-b border-[#dadada]  text-[14px]'>
          <td className='px-6 font-bold py-2.5 whitespace-nowrap'>{title}</td>
          {allRolesData?.data?.data.map((item, index) => (
            <td key={index} className='w-[10%] py-2.5'>
              <span className='flex justify-center px-4'>
                <input type='checkbox' value={perId} />
              </span>
            </td>
          ))}
        </tr>
      );
    };

    const NewTableContent = ({ title, id }) => {
      return (
        <tr className='w-full font-[500] border-t border-b border-[#dadada]  text-[14px]'>
          <td className='px-6 py-2.5 whitespace-nowrap'>{title}</td>
          {allRolesData?.data?.data.map((item, index) => (
            <td key={index} className='w-[10%] py-2.5'>
              <span className='flex justify-center px-4'>
                <input type='checkbox' value={id} />
              </span>
            </td>
          ))}
        </tr>
      );
    };
    return (
      <div className='pb-3 pt-1.5 w-full overflow-x-auto'>
        <table className='overflow-x-auto w-full border-collapse'>
          <tbody className=' py-10'>
            <NewTableHeader column={columns} />
            {data.map((permissionData) => (
              <React.Fragment key={permissionData.permissionId}>
                <NewTableSubHeader
                  key={permissionData.permissionId}
                  title={permissionData?.permissionName}
                  perId={permissionData.permissionId}
                />
                {permissionData.subPermissions &&
                  permissionData.subPermissions.map((sub) => (
                    <NewTableContent
                      key={sub.id}
                      title={sub.subPermissionName}
                      checkboxName='createNewInv'
                      id={sub.id}
                    />
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const RoleManagerTab = () => {
    return (
      <div>
        <div className=' flex justify-between w-full rounded-lg '>
          <motion.button
            whileTap={{ scale: 0.99 }}
            onClick={() => {
              setUmRole(false);
              changeTab(0);
            }}
            className={`flex justify-center items-center gap-1 rounded-tl-lg py-5 w-1/2 ${
              activeTab === 0
                ? "active-tab-role tab-button-role"
                : "inactive-tab-role tab-button-role"
            }`}
          >
            <span className='flex items-center justify-center h-full'>
              All User
            </span>
            <span
              style={{ background: "rgba(136, 136, 136, 0.2)" }}
              className='rounded-[50%] text-[#07593d] flex justify-center items-center h-[20px]  w-[20px] text-[10px] font-[500]'
            >
              {data?.length}
            </span>
          </motion.button>

          <motion.button
            whileTap={{ scale: 0.99 }}
            onClick={() => {
              setUmRole(true);
              changeTab(1);
            }}
            className={`flex rounded-tr-lg justify-center py-5 w-1/2 ${
              activeTab === 1
                ? "active-tab-role tab-button-role"
                : "inactive-tab-role tab-button-role"
            }`}
          >
            <span>User role manager</span>
          </motion.button>
        </div>

        <div className='tab-content-wrapper-role mb-6'>
          {activeTab === 0 ? (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className='tab-content rounded-b-lg py-2 px-6 bg-white    active w-full'
              >
                {data?.length === 0 || !data ? (
                  <EmptyPage />
                ) : (
                  <RoleManagerTable
                    data={data}
                    toggleViewModal={toggleViewModal}
                    toggleDeleteModalOn={toggleDeleteModalOn}
                    setToggleViewModal={setToggleViewModal}
                    selectedCard={selectedCard}
                    setSelectedCard={setSelectedCard}
                    setEditActive={setEditActive}
                    pageSize={pageSize}
                    page={page}
                    setPage={setPage}
                  />
                )}
              </motion.div>
            </AnimatePresence>
          ) : (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
              className='tab-content h-full  rounded-b-lg bg-white active w-full'
            >
              {data?.length === 0 || !data === 0 ? (
                <EmptyPage />
              ) : (
                <UserRoleManager />
              )}
            </motion.div>
          )}
        </div>
      </div>
    );
  };
  return (
    <DashboardLayout
      section='Role Manager'
      subHeader='Manage all your users and roles'
    >
      {/* Modal Popup */}
      <AnimatePresence>
        {toggleViewModal && (
          <ViewMemberModal
            editActive={editActive}
            setEditActive={setEditActive}
            toggleViewModalOff={toggleViewModalOff}
          />
        )}
        {toggleAddUserModal && (
          <AddNewUserModal
            setAddUserClick
            toggleUserModalOff={toggleAddUserModalOff}
            pageSize={pageSize}
            page={page}
          />
        )}
        {toggleNewRole && (
          <AddNewRoleModal
            page={page}
            pageSize={pageSize}
            toggleNewRoleOff={toggleNewRoleOff}
          />
        )}
        {toggleDeleteModal && (
          <ViewDocumentModal
            text='Are you sure you would like to remove this member from the platform?'
            onCloseDeleteModal={toggleDeleteModalOff}
          />
        )}

        {toggleEditRolePermissionModal && (
          <EditRoleModal closeModal={toggleEditRolePermissionModalOff} />
        )}
      </AnimatePresence>

      {toggleRoleScreen ? (
        <div className=' h-full'>
          {!toggleRoleFullDetails ? (
            <ViewRoleManager
              toggleNewRoleOn={toggleNewRoleOn}
              toggleRoleScreenOff={toggleRoleScreenOff}
              toggleRoleFullDetailsOn={toggleRoleFullDetailsOn}
              toggleRoleFullDetailsOff={toggleRoleFullDetailsOff}
              setSelectedId={setSelectedId}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
            />
          ) : (
            <ViewRoleFullDetails
              toggleRoleFullDetailsOff={toggleRoleFullDetailsOff}
              toggleEditPermissionOn={toggleEditRolePermissionModalOn}
              selectedId={selectedId}
            />
          )}
        </div>
      ) : (
        <div className='flex flex-col gap-6 h-full w-full'>
          <>
            <AddUserHeader
              toggleNewRoleOn={toggleNewRoleOn}
              umRole={umRole}
              toggleUserModalOn={toggleAddUserModalOn}
              toggleRoleScreenOn={toggleRoleScreenOn}
            />
            <RoleManagerTab />
          </>
        </div>
      )}
    </DashboardLayout>
  );
};

export default RoleManagerDashboard;
