function MarketCards({ amount = '0', title, color = '#07593D' }) {
  return (
    <div
      className={` cursor-pointer mb-2 flex flex-col justify-center items-center text-white px-3 w-full md:w-[145px] h-[142px] bg-[${color}] rounded-md transition duration-500 transform hover:scale-105 ease-in-out`}
    >
      <span className='block font-semibold text-4xl md:text-[2rem] lg:text-6xl'>
        {amount}
      </span>
      <span className='block whitespace-nowrap'>{title}</span>
      <span className='w-full flex px-[5%] justify-end '>
        <img src='arrow-lead.svg' alt='' />
      </span>
    </div>
  );
}

export default MarketCards;
