/* eslint-disable react-hooks/exhaustive-deps */
import {
  PAIconDoc,
  PAIconDropUser,
  PAIconEditlight,
  PAIconLink,
  PAIconOption,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import TableHeader from "shared/tableHeader/TableHeader";
import { useLocation } from "react-router-dom";

const Funders = () => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [atBottom, setAtBottom] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const dummyData = [
    {
      id: 1,
      fullName: "John Doe",
      companyName: "Company A",
      email: "john@example.com",
      phoneNumber: "123-456-7890",
      discountRate: "3%",
    },
    {
      id: 2,
      fullName: "Jane Smith",
      companyName: "Company B",
      email: "jane@example.com",
      phoneNumber: "234-567-8901",
      discountRate: "5%",
    },
    {
      id: 3,
      fullName: "Jim Brown",
      companyName: "Company C",
      email: "jim@example.com",
      phoneNumber: "345-678-9012",
      discountRate: "9%",
    },
  ];

  useEffect(() => {
    setData(dummyData);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(null);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const location = useLocation();

  const onRowClicked = (row) => {
    let bulkPath =
      "/invoice/fundingSection/directfundingbulkrequest/directfundingbulklist";
    let path = "/invoice/fundingSection/directsinglerequestinvoicelist";
    // console.log(row?.id);
    if (location?.state?.route === "single") {
      navigate(path);
    } else if (location?.state?.route === "bulk") {
      navigate(bulkPath);
    }
  };

  const totalPages = Math.ceil(dummyData.length / pageSize);

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      name: "Discount Rate",
      selector: (row) => row?.discountRate,
    },
    {
      cell: (row) => (
        <div>
          <PAIconOption title="Options" onClick={() => setDropdown(row.id)} />
          {dropdown === row.id && row && (
            <div className="relative" ref={dropdownRef}>
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && "bottom-[20px]"
                } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb-[12px]  pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconDropUser />
                  </span>
                  <p className="font-sm font-normal">View Details</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconDoc />
                  </span>
                  <p className="font-sm font-normal">Create Invoice</p>
                </div>
                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconEditlight />
                  </span>
                  <p className="font-sm font-normal">Edit Tier</p>
                </div>
                <div className="border border-silver"></div>
                <div className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconLink />
                  </span>
                  <p className="font-sm font-normal text-[#DE1515]">
                    Disassociate
                  </p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  const handleSearch = () => {
    const search = companyName.toLowerCase();
    const filteredData = dummyData.filter(
      (item) =>
        item.companyName.toLowerCase().includes(search) ||
        item.fullName.toLowerCase().includes(search) ||
        item.email.toLowerCase().includes(search)
    );
    setData(filteredData);
  };

  return (
    <DashboardLayout section="Funders">
      <Card className="p-[24px] rounded-[10px]">
        <TableHeader
          setProductName={setCompanyName}
          productName={companyName}
          search={handleSearch}
          data={data}
          total="All Funders"
          details="Access contacts details of the associated funders"
        />
        <Table
          columns={columns}
          data={data}
          onRowClicked={onRowClicked}
          progressPending={false}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {totalPages > 1 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default Funders;
