import React, { useState } from "react";
import RouteHeader from "../../RouteHeader";
import {
  PAIconContract,
  PAIconMore,
  PAIconStep1,
  PAIconStep2,
  PAIconStep3,
  PAIconStep4,
  PAIconStep5,
} from "assets/images/svgs";
import Modal from './Modal';

const TemplateDetails = ({goBack}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);


  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  

  const sections = [
    { label: "Designed", content: "October 16, 2023" },
    { label: "Template Size", content: "A4 (21 х 24,7 cm)" },
    { label: "Modified", content: "October 20, 2023" },
    { label: "Available Formats", content: "PDF, TXT, ODT, EPUB" },
  ];

  const steps = [
    {
      icon: <PAIconStep1 />,
      step: "Step 1",
      text: "Tap the more icon ",
    },
    {
      icon: <PAIconStep2 />,
      step: "Step 2",
      text: "Click on download and select the file format of your choice",
    },
    {
      icon: <PAIconStep3 />,
      step: "Step 3",
      text: "Edit file to fit your desired contract",
    },
    {
      icon: <PAIconStep4 />,
      step: "Step 4",
      text: "Upload your contract to your lists of agreements",
    },
    {
      icon: <PAIconStep5 />,
      step: "Step 5",
      text: "You can also preview contract template before you choose to download",
    },
  ];

  return (
    <div className="px3 mb-5">
      <RouteHeader
        Route1="Contract Template"
        Heading="Invoicing Financial Agreement"
        handleBack={goBack}
      />
      <div className="mt-5">
        <div className="grid grid-row lg:grid-cols-2 items-start gap-7">
          {/* TEMPLATE DETAILS */}
          <div className="">
            <div className="p-5 border border-gray rounded-md">
              <div className="rounded-md flex items-center justify-between">
                <div className="text-black font-sans font-bold text-xl">
                  Template Details
                </div>
                <PAIconStep1 />
              </div>
              <div className="p5">
                {sections.map((section, index) => (
                  <div
                    key={index}
                    className="mt-3 border border-[#FFBE55] rounded-md flex items-center justify-between"
                  >
                    <div className="rounded-l-md p-2 border-t border-b border-l border-r border-yellow-200 bg-yellow-200 w-1/2 text-custom-black font-sans text-xs font-medium">
                      {section.label}
                    </div>
                    <div className="bg-white w-1/2 items-center flex justify-center text-black font-sans text-xs font-medium">
                      {section.content}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* CARD */}
          <div className="max-w-xl mb-4 rounded-md shadow-sm cursor-pointer relative">
            <div className="bg-[#FFF8F3] p-4 flex items-center justify-center rounded-md">
              <img
                src="/A4.png"
                alt="CardImage"
                className="max-h-16 overflow-hidden"
              />
            </div>
            <div className="bg-white p-4 flex justify-between items-center mb-[-10px] mt-[-20px]">
              <div className="flex items-center">
                <PAIconContract className="w6 h6 mr-2" />
                <span className="font-semibold">
                  Invoicing Financial Agreement
                </span>
              </div>
              <div className="flex items-center justify-end cursor-pointer">
                <PAIconMore onClick={toggleDropdown} />
              </div>
              {isDropdownOpen && (
                <div className="absolute top-full right-5 mt-[-40px] bg-white rounded-md shadow-md">
                  {/* Dropdown Content */}
                  <ul className="p-3">
                    <li className="py-2 px-4 hover:shadow-sm cursor-pointer text-black font-inter text-base font-normal">
                      Preview
                    </li>
                    <li className="py-2 px-4 hover:shadow-sm cursor-pointer">
                      Print
                    </li>
                    <li className="py-2 px-4 hover:shadow-sm cursor-pointer" onClick={toggleModal}>
                      Download
                    </li>
                    <li className="py-2 px-4 hover:shadow-sm cursor-pointer">
                      Share
                    </li>
                    <li className="py-2 px-4 hover:shadow-sm cursor-pointer">
                      Export Data
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <p className="text-[#959595] font-sans text-xs font-normal px-4 mb-5">
              Last Updated: 2 days ago
            </p>
          </div>
        </div>

        {/* STEPS */}
        <div className="mt-10">
          <h5 className="text-black font-sans font-bold text-xl">
            How to use this template
          </h5>
          {/* Map over the steps array and render each step */}
          {steps.map((step, index) => (
            <div key={index} className="flex items-center mt-3">
              {step.icon}
              <div className="ml-5">
                <p className="text-green-900 font-sans text-sm font-normal">
                  {step.step}
                </p>
                <p className="text-black font-sans text-base font-semibold fontnormal">
                  {step.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </div>
  );
};

export default TemplateDetails;
