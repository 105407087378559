import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import HeaderTabs from "shared/HeaderTabs";
import Dashboard from "./dashboard";
import { setActiveTab } from "appstate/collections/collectionSlice";
import DirectFunding from "./supplier/directFunding";

const BuyerReimbursementTab = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("overview"));
  }, [dispatch]);

  return (
    <HeaderTabs
      tabs={[
        { key: "overview", label: "Funder", content: <Dashboard /> },
        { key: "supplier", label: "Supplier", content: <DirectFunding /> },
      ]}
      hasPaddingY={true}
    />
  );
};

export default BuyerReimbursementTab;
