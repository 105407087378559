import React, { useState } from 'react';
import VirtualSelectedAccount from './VirtualSelectedAccount';

const VirtualAccountSelection = ({ handleCloseVirtualAccount }) => {
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [showSelectedAccountModal, setShowSelectedAccountModal] = useState(false);
    const [showAccountSelectionModal, setShowAccountSelectionModal] = useState(true);

    const handleAccountClick = (id) => {
        setSelectedAccount(id === selectedAccount ? null : id);
    };

    const handleNextClick = () => {
        if (selectedAccount) {
            setShowAccountSelectionModal(false);
            setShowSelectedAccountModal(true);
        }
    };

    const handleCloseSelectedAccountModal = () => {
        setShowSelectedAccountModal(false);
        setShowAccountSelectionModal(true);
    };

    const handleCloseModal = () => {
        handleCloseVirtualAccount();
        setShowSelectedAccountModal(false);
        setShowAccountSelectionModal(true);
    };

    const dummyAccounts = [
        { id: 1, accountName: "Praise Akunbundu", accountNumber: "727772772729", bank: "payedge MFB", accountType: "Virtual Account" },
        { id: 2, accountName: "Worship Akunbundu", accountNumber: "727772000729", bank: "payedge MFB", accountType: "Virtual Account" },
        { id: 3, accountName: "Prayer Akunbundu", accountNumber: "727772111729", bank: "payedge MFB", accountType: "Virtual Account" },
    ];

    if (showSelectedAccountModal) {
        return (
            <VirtualSelectedAccount 
                selectedAccount={dummyAccounts.find(account => account.id === selectedAccount)} 
                onClose={handleCloseModal} 
                handleChangeAccount={handleCloseSelectedAccountModal}
                showAccountSelectionModal={showAccountSelectionModal}
            />
        );
    }

    return (
        <div className="w-[858px] fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
            <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
                <div className='flex justify-between mb-4'>
                    <h2 className="text-xl font-semibold">Virtual Accounts</h2>
                    <button className="text-red-500">Close</button>
                </div>
                <div className="mb-4">
                    <h2 className="text-gray-600">Please select a virtual account you want to use to fund the early payment request</h2>
                </div>
                <div className="space-y-4">
                    {dummyAccounts.map(account => (
                        <div key={account.id} className="flex items-center">
                            <input
                                type="radio"
                                checked={selectedAccount === account.id}
                                onChange={() => handleAccountClick(account.id)}
                                className="mr-4 appearance-none h-5 w-5 border border-gray-300 rounded-full checked:border-orange-500 checked:bg-white checked:ring-2 checked:ring-orange-500 checked:ring-offset-1"
                            />
                            <div
                                onClick={() => handleAccountClick(account.id)}
                                className={`flex-1 p-4 border rounded-lg cursor-pointer ${selectedAccount === account.id ? 'border-green-500 bg-green-50' : 'border-gray-300 bg-white'}`}
                            >
                                <h3 className={`font-medium ${selectedAccount === account.id ? 'text-[#076344]' : 'text-black'}`}>
                                    {account.accountName}
                                </h3>
                                <p className={selectedAccount === account.id ? 'text-[#076344]' : 'text-black'}>
                                    {account.accountNumber}
                                </p>
                                <div className="flex justify-between">
                                    <span className={selectedAccount === account.id ? 'text-[#0a8555]' : 'text-black'}>
                                        {account.bank}
                                    </span>
                                    <span className={selectedAccount === account.id ? 'text-[#0a8555]' : 'text-black'}>
                                        {account.accountType}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <br />
                <div className='flex justify-between'>
                    <span style={{ fontSize: "14px", lineHeight: "16px", fontWeight: "500" }}>
                        The selected receiving account cannot be changed for this invoice. Please choose carefully.
                    </span>
                    <button 
                        className="py-2 px-8" 
                        style={{ backgroundColor: "#F08000", color: "white" }}
                        onClick={handleNextClick}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VirtualAccountSelection;
