import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  changeStatus,
  createPurchaseOrder,
  filterPoByStatus,
  getAllPurchaseOrders,
  searchPurchaseOrder,
  searchSupplierInventories,
  viewSupplierInventories,
} from 'appstate/api/purchaseOrder';

const initialState = {
  isLoading: false,
  products: [],
  selectedProducts: null,
  allPOs: [],
  filterPoData: [],
  updateStatusLoading: false
};

export const viewSupplierProducts = createAsyncThunk(
  'viewSupplierProducts',
  viewSupplierInventories
);

export const createPO = createAsyncThunk('createPO', createPurchaseOrder);

export const allPurchaseOrders = createAsyncThunk(
  'allPurchaseOrders',
  getAllPurchaseOrders
);

export const searchSupplierProducts = createAsyncThunk(
  'searchSupplierProducts',
  searchSupplierInventories
);

export const searchPo = createAsyncThunk('searchPo', searchPurchaseOrder);

export const filterPurchaseOrderByStatus = createAsyncThunk(
  'filterPurchaseOrderByStatus',
  filterPoByStatus
);

export const changePoStatus = createAsyncThunk('changePoStatus', changeStatus);

export const poSlice = createSlice({
  name: 'purchaseOrder',
  initialState,
  reducers: {
    resetPurchaseOrder: (state) => {
      state.selectedProducts = null;
    },
    selectedProductData: (state, action) => {
      state.selectedProducts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(viewSupplierProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(viewSupplierProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    });
    builder.addCase(viewSupplierProducts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createPO.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createPO.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createPO.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allPurchaseOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allPurchaseOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPOs = action.payload;
    });
    builder.addCase(allPurchaseOrders.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(searchSupplierProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchSupplierProducts.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(searchSupplierProducts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(searchPo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchPo.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(searchPo.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(filterPurchaseOrderByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(filterPurchaseOrderByStatus.fulfilled, (state, action) => {
      state.filterPoData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(filterPurchaseOrderByStatus.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(changePoStatus.pending, (state) => {
      state.updateStatusLoading = true;
      state.isLoading = false
    });
    builder.addCase(changePoStatus.fulfilled, (state) => {
      state.updateStatusLoading = false;
    });
    builder.addCase(changePoStatus.rejected, (state) => {
      state.updateStatusLoading = false;
    });
  },
});
export const { resetPurchaseOrder, selectedProductData, searchedPos } =
  poSlice.actions;
export default poSlice.reducer;
