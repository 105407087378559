import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import infoGreen from "../../../../../assets/images/svgs/infoGreen.svg";
import blockIcon from "../../../../../assets/images/svgs/blockIcon.svg";
import TransferFundsModal from "features/disbursement/common/modal/TransferFundsModal";
import EnterPin from "features/disbursement/common/modal/Pin";

const PaymentMethodModal = ({ handleClose, handleProceed }) => {
  const [transferModal, setTransferModal] = useState(false);
  const [pinModal, setPinModal] = useState(false);

  const handleTransfer = () => {
    setTransferModal(true);
  };

  return (
    <div className="bg-[rgba(0,0,0,0.2)] w-[100vw] h-[100vh] flex justify-center items-center absolute ml-[-10%] mt-[-110px]">
      <div>
        <div className="flex flex-col justify-between items-center bg-white rounded-[8px] p-[5%] w-[600px]">
          <img className="mt-[3%]" src={infoGreen} alt="" />
          <p className="text-center py-6">Please select payment method!</p>
          <div className="flex items-center">
            <div
              onClick={handleTransfer}
              className="bg-[#07593D] mr-[5%] text-white rounded-[8px] flex justify-between items-end w-[270px] px-[5%] py-[2%]"
            >
              <p>
                Make Payment via <br />{" "}
                <span className="font-bold">Wallet</span>
              </p>
              <img width={20} src={blockIcon} alt="" />
            </div>
            <AnimatePresence>
              {transferModal && (
                <TransferFundsModal
                  onAction={() => {
                    setTransferModal(false);
                    setPinModal(true);
                  }}
                  onClose={() => setTransferModal(false)}
                />
              )}
            </AnimatePresence>
            <AnimatePresence>
              <EnterPin isOpen={pinModal} onClose={() => setPinModal(false)} />
            </AnimatePresence>
            <div
              onClick={handleProceed}
              className="bg-[#07593D] text-white rounded-[8px] flex justify-between items-end w-[270px] px-[5%] py-[2%]"
            >
              <p>
                Make Payment via <br />{" "}
                <span className="font-bold">Third Party Funding</span>
              </p>
              <img src={blockIcon} alt="" />
            </div>
          </div>
          <div className="mt-[6%] text-right w-[100%]">
            <button
              onClick={handleClose}
              className="border-[#EE0816] text-[#EE0816] border-[1px] w-[120px] rounded-[4px] h-[45px]"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodModal;
