import React, { useEffect, useState } from "react";
import {
  PAIconCardPayment,
  PAIconDelete3,
  PAIconMastercardWhite,
  PAIconPayAssystLight,
  PAIconViewDay,
} from "assets/images/svgs";
import DeleteCardModal from "./deleteCard/deleteCard";
import DeleteCardSuccessModal from "./deleteCard/successDelete";
// import EditCardModal from "./editCard";
import CardDetails from "./editCard/accountCardDetails";
import CongratsModal from "features/wallet/linkExternalAccount/congrats/congratsModal";
import { useDispatch, useSelector } from "react-redux";
import { allVirtualCards, getVirtualCardId } from "appstate/wallet/walletSlice";
import ViewCardInfo from "./viewDetails";

const Cards = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  // const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [accountDetails, setCardDetails] = useState(false);
  const [congrats, setCongrats] = useState(false);
  const [cardIdToDelete, setCardIdToDelete] = useState(null);
  // const [cardIdToEdit, setCardIdToEdit] = useState(null);
  // const [editedCardData, setEditedCardData] = useState(null);
  const [viewCardInfo, setViewCardInfo] = useState(null);
  const [cardIdToView, setCardIdToView] = useState(null);

  const { allVirtualCardsData } = useSelector((state) => state?.wallet);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allVirtualCards());
  }, [dispatch]);

  // Function to format card number with spaces after every 4 digits
  const formatCardNumber = (cardNumber) => {
    if (!cardNumber) {
      return "";
    }

    const formattedCardNumber = cardNumber?.replace(/(\d{4})/g, "$1 ");
    return formattedCardNumber.trim();
  };

  const cardData = Array.isArray(allVirtualCardsData?.data)
    ? allVirtualCardsData?.data?.map((card) => ({
        id: card?.id,
        cardName: card?.cardName,
        cardNumber: formatCardNumber(card?.cardNumber),
        cardCurrency: card?.cardCurrency,
        paymentMethod: card?.paymentMethod,
        balance: card?.balance,
        expiryDate: card?.expiryDate,
      }))
    : [];

  const [dropdownState, setDropdownState] = useState(
    Array(cardData?.length).fill(false)
  );

  const openDeleteModal = (cardId) => {
    setCardIdToDelete(cardId);
    setDeleteModalOpen(true);
  };

  const openViewCardDetails = (cardId) => {
    setCardIdToView(cardId);
    dispatch(getVirtualCardId(cardId));
    setViewCardInfo(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    // setEditModalOpen(false);
    setCardDetails(false);
    setCongrats(false);
    setViewCardInfo(false);
  };

  const handleDeleteConfirm = () => {
    closeDeleteModal();
    setSuccessModalOpen(true);
  };

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  // const handleEditModalSubmit = (editedData) => {
  //   closeDeleteModal();
  //   setEditedCardData(editedData);
  //   setCardDetails(true);
  // };

  const handleCardDetailsSubmit = () => {
    closeDeleteModal();
    setCongrats(true);
  };

  // const openEditModal = (cardId) => {
  //   setCardIdToEdit(cardId);
  //   setEditModalOpen(true);
  //   dispatch(getVirtualCardId(cardId));
  // };

  const handleDropdownToggle = (cardId) => {
    const newDropdownState = [...dropdownState];
    newDropdownState[cardId] = !newDropdownState[cardId];
    setDropdownState(newDropdownState);
  };

  return (
    <div className='md:flex md:gap-5 flex-wrap'>
      {cardData?.map((card) => (
        <div
          key={card?.id}
          className='relative mt-3 text-white text-xs md:w-[calc(25%-10px)] md:mb-0 mb-3'
        >
          <div
            className='bg-[#07593D] p-3 rounded-t-lg cursor-pointer'
            onClick={() => handleDropdownToggle(card?.id)}
          >
            <div className='flex items-center justify-between'>
              <div className='text-white text-xs flex items-center'>
                <PAIconPayAssystLight className='mr-2' />
              </div>
              <div
                className='cursor-pointer accent-white text-white'
                onClick={() => handleDropdownToggle(card?.id)}
              >
                <PAIconCardPayment className='color-white text-white' />
              </div>
              {dropdownState[card?.id] && (
                <div className='absolute top-0 right-0 mt-8 bg-white text-black p-2 rounded'>
                  {/* <div
                    className="flex items-center gap-1"
                    onClick={() => {
                      handleDropdownToggle(card.id);
                      openEditModal();
                    }}
                  >
                    <PAIconManageSearch /> <p>Edit Card Details</p>
                  </div> */}
                  <div
                    className='mb-2 flex items-center gap-1'
                    onClick={() => {
                      handleDropdownToggle(card?.id);
                      openViewCardDetails(card?.id);
                    }}
                  >
                    <PAIconViewDay /> <p>View Card Details</p>
                  </div>
                  <div
                    className='flex items-center gap-1'
                    onClick={() => {
                      handleDropdownToggle(card?.id);
                      openDeleteModal(card?.id);
                    }}
                  >
                    <PAIconDelete3 />
                    Delete Card
                  </div>
                </div>
              )}
            </div>
            <div className='flex items-center justify-between my-3'>
              <div className='text-white text-xs mt-2 font-semibold'>
                {card?.cardName}
              </div>
              <p className='mt-2 italic'>{card?.cardNumber}</p>
            </div>
          </div>
          <div className='bg-amber-600 p-3 rounded-b-lg'>
            <div className='flex items-center justify-between'>
              <div className='text-white text-xl font-medium'>
                <p>{card?.balance}</p>
                <p className='text-white text-[8.83px] font-normal'>
                  Hide balance
                </p>
              </div>
              <div className='text-white text-xl font-medium'>
                <div className='flex justify-end'>
                  <PAIconMastercardWhite />
                </div>
                <p className='text-white text-[8.83px] font-normal'>
                  {card?.paymentMethod}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
      <DeleteCardModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDeleteConfirm}
        cardId={cardIdToDelete}
      />
      <DeleteCardSuccessModal
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
      />
      {/* <EditCardModal
        isOpen={isEditModalOpen}
        onClose={closeDeleteModal}
        onSubmit={handleEditModalSubmit}
        cardId={cardIdToEdit}
      /> */}
      <CardDetails
        isOpen={accountDetails}
        onClose={closeDeleteModal}
        onSubmit={handleCardDetailsSubmit}
        // editedData={editedCardData}
      />
      <CongratsModal
        isOpen={congrats}
        onClose={closeDeleteModal}
        message='Your Card has been updated'
      />
      <ViewCardInfo
        isOpen={viewCardInfo}
        onClose={closeDeleteModal}
        cardId={cardIdToView}
      />
    </div>
  );
};

export default Cards;
