
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "routes";
import SplashScreen from "shared/SplashScreen";


function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);


  return (
    <Router>
      {loading ? <SplashScreen /> : <Routing />}
      <Toaster
        position="top-center"
        containerClassName="text-sm mt-16"
        reverseOrder={false}
      />
    </Router>
  );
}

export default App;
