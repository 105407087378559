import { PAIconPayassyst1 } from "assets/images/svgs";
import React from "react";

const SaveAs = () => {
  return (
    <div className="h-[80vh] flex items-center justify-center mt-5">
      <div className="bg-white shadow-md px-10 py-5 text-center md:w-1/2">
        <div className="mb10 flex items-center justify-center">
          <PAIconPayassyst1 />
        </div>
        <div className="mt-9 text-left text-black font-inter font-semibold text-lg">
          <p>Save Email Template</p>
        </div>
        <div className="border border-gray mt-2 mb-5"></div>
        <div className="text-black font-inter text-sm font-normal leading-normal tracking-wide text-left mb2">
          <p>
            Please name and save your template; access all saved templates in
            the "Saved" section for your convenience.
          </p>
        </div>
        <div class="my-7">
          <input
            type="text"
            id="text"
            name="text"
            class="mt-1 p-2 block w-full rounded-md border border-green-500 focus:ring focus:ring-indigo-200 focus:border-green-500"
            placeholder="Name of template"
          />
        </div>
        <button className="flex items-start justify-start mb-20 rounded-md bg-[#07593D] p-3 text-white text-center font-inter text-sm font-normal leading-normal tracking-wide">
          Save Template
        </button>
      </div>
    </div>
  );
};

export default SaveAs;
