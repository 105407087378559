import { ruleById } from "appstate/rulesandconfig/rulesandconfigSlice";
import {
  PAIconCheckMarkGreen,
  PAIconCheckmarkYellow,
  PAIconEditLight,
  PAIconSmallClose,
  PAIconTestTubeGreen,
  PAIconTrashMini,
} from "assets/images/svgs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "shared/Loading";
import { motion } from "framer-motion";
// import toast from "react-hot-toast";
import RightSideModal from "shared/RightSideModal";
import { useParams } from "react-router-dom";
import { formatCapitaliseString } from "utilities/helper/formatCapitaliseString";

function ViewModal({ closeModal, setDeleteModalOpen, onEdit, id }) {
  const { ruleType } = useParams();
  const dispatch = useDispatch();
  const { modalLoading, ruleByIdData } = useSelector(
    (state) => state?.rulesAndConfig
  );

  const data = ruleByIdData?.data;

  useEffect(() => {
    dispatch(ruleById({ id, ruleType }));
  }, [dispatch, id, ruleType]);

  return (
    <>
      <RightSideModal closeModal={closeModal}>
        {modalLoading ? (
          <div className='w-full h-screen py-10 flex justify-center items-center'>
            <Loading />
          </div>
        ) : (
          <div className='flex flex-col gap-3 py-4 px-10'>
            <header className='flex pb-6 border-b-[3.25px] border-[#F6F5FA]   items-center justify-between'>
              <span className='font-bold text-xl'>
                Details:{" "}
                <span className='font-normal text-primaryColor text-xl'>
                  {data?.name}
                </span>
              </span>

              <span
                onClick={closeModal}
                className=' cursor-pointer flex gap-2 items-center'
              >
                <PAIconSmallClose />

                <span>Close</span>
              </span>
            </header>
            <main className=' py-3 flex flex-col gap-8'>
              <div className='flex gap-4 justify-end'>
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  className='flex text-sm gap-2 justify-center items-center rounded-[5px] py-2.5 px-5 border text-primaryColor border-primaryColor'
                >
                  <PAIconTestTubeGreen />
                  <span>Test Rule</span>
                </motion.button>
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  onClick={() => {
                    setTimeout(() => {
                      closeModal();
                      onEdit(data);
                    }, 400);
                  }}
                  className='flex text-sm justify-center items-center gap-2 rounded-[5px] py-2.5 px-5 border text-[#F08000] border-[#F08000]'
                >
                  <PAIconEditLight />
                  <span> Edit Rule</span>
                </motion.button>
                <motion.button
                  whileTap={{ scale: 0.9 }}
                  onClick={() => {
                    closeModal();
                    setDeleteModalOpen(true);
                  }}
                  className='flex text-sm gap-2 justify-center items-center rounded-[5px] py-2.5 px-5 border text-[#DE1515] border-[#DE1515]'
                >
                  <PAIconTrashMini />
                  <span>Remove Rule</span>
                </motion.button>
              </div>

              <section className='flex flex-col border-b border-b-[#BBBBBB8F] gap-7 pb-5'>
                <div className=' flex justify-between'>
                  <span>Rule Name</span>
                  <span className='text-[#959595]'>{data?.name}</span>
                </div>

                <div className=' flex justify-between'>
                  <span>Status</span>
                  <span
                    className={`${
                      data?.status === "Activated"
                        ? "text-primaryColor"
                        : "text-[#DE1515]"
                    }`}
                  >
                    {data?.status === "Activated" ? "Activated" : "Deactivated"}
                  </span>
                </div>

                <div className=' flex justify-between'>
                  <span>Priority</span>
                  <span className='text-[#959595]'>
                    Priority {data?.priority}
                  </span>
                </div>

                <div className=' flex justify-between'>
                  <span>Last Modified</span>
                  <span className='text-[#959595]'>
                    {data?.lastModifiedDate}
                  </span>
                </div>
              </section>

              <section className='flex flex-col border-b border-b-[#BBBBBB8F] gap-7 pb-7'>
                <div>
                  <span className='text-[20px] font-bold'>Conditions</span>
                </div>

                {data?.conditions.map((condition) => (
                  <div key={condition?.type} className='flex gap-6'>
                    <PAIconCheckMarkGreen />
                    <span className='text-[#959595]'>
                      If {formatCapitaliseString(condition?.type)} is{" "}
                      {formatCapitaliseString(condition?.operator)}{" "}
                      {condition?.value}
                    </span>
                  </div>
                ))}
              </section>

              <section className='flex flex-col border-b border-b-[#BBBBBB8F] gap-7 pb-7'>
                <div>
                  <span className='text-[20px] font-bold'>Actions</span>
                </div>

                {data?.actions.map((action, index) => (
                  <div key={action?.type} className='flex gap-6'>
                    <PAIconCheckmarkYellow />
                    <span className='text-[#959595]'>
                      {formatCapitaliseString(action?.type)} is {action?.value}
                    </span>
                  </div>
                ))}
              </section>

              <section
                style={{
                  borderRadius: "10px",
                  background: "rgba(29, 224, 130, 0.1)",
                }}
                className='flex px-5 py-5  flex-col gap-7 pb-4'
              >
                <div>
                  <span className='text-[20px] font-bold'>
                    Additional Settings
                  </span>
                </div>

                <div className=' flex justify-between'>
                  <span>Discount Expiry Date</span>
                  <span className='text-[#959595]'>{data?.lastModified}</span>
                </div>

                <div className=' flex justify-between'>
                  <span>Payment Method</span>
                  <span className='text-[#959595]'>Electronic Transfer</span>
                </div>
              </section>
            </main>
          </div>
        )}
      </RightSideModal>
    </>
  );
}

export default ViewModal;
