import React from "react";
import Loading from "./Loading";

const LoadingScreen = () => {
  return (
    <div className='h-full w-full absolute top-0 left-0 bg-loadingBg z-9999'>
      <div className='flex flex-col justify-center items-center h-full w-full'>
        <Loading />
      </div>
    </div>
  );
};

export default LoadingScreen;
