import React from "react";

const Guildlines = () => {
  const guidelines = [
    {
      id: 1,
      text: "The financing cost for the extended period will be recalculated based on the revised repayment schedule"
    },
    {
      id: 2,
      text: "Failure to adhere to the revised repayment schedule may result in additional fees and could impact the supplier's eligibility for future financing"
    },
    {
      id: 3,
      text: "All communications regarding the payment extension, including requests for modifications, should be submitted in writing to our support team"
    },
    {
      id: 4,
      text: "Non-compliance with the agreed-upon terms may result in legal action to recover outstanding amounts"
    },
    {
      id: 5,
      text: "Any request for modification to the extension terms must be submitted in at least 10 days prior to the next scheduled payment."
    }
  ];

  return (
    <div className="my2 rounded">
      <div className="bg-alabasterHeader shadow p-3 rounded">
        <h1 className="text-black font-bold text-lg">
          Payment Extension Guidelines
        </h1>
        <span className="text-[#5F4D61] text-sm font-normal">
          Guidelines for Requesting and Implementing Payment Extensions
          Effectively
        </span>
      </div>
      {guidelines.map((guideline) => (
        <div
          key={guideline.id}
          className="fle itemsstart px-5 gap-3 mt-3 border-b border-gray300 flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 14 14"
            fill="none"
            className="flex-shrink-0"
          >
            <circle cx="7" cy="7" r="7" fill="#F08000" />
          </svg>
          <p className="text-[#11192A] text-sm font-normal flex-grow">
            {guideline.text}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Guildlines;

