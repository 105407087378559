import { motion } from "framer-motion";
import {
  PAIconCocacola,
  PAIconInvShop,
  PAIconLockYellow,
  PAIconModelOne,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";

function OpenMarketTabCard({
  lockInvoice,
  id,
  setId,
  invoiceNumber = "N/A",
  buyerCompanyName = "N/A",
  supplierCompanyName = "N/A",
  description = "N/A",
  invoiceAmount = "N/A",
  lastModifiedDate = "N/A",
  dueDate = "N/A",
  numberOfBids = "0",
}) {
  const navigate = useNavigate();

  return (
    <motion.div
      onClick={() => navigate(id)}
      whileTap={{ scale: 0.97 }}
      style={{ boxShadow: "0px 4px 12px 5px rgba(0, 0, 0, 0.04)" }}
      className="bg-white cursor-pointer flex flex-col gap-7 px-6 py-[25px] rounded-[10px]"
    >
      <div className="flex justify-between">
        <div className="flex gap-9">
          <div className="flex gap-6 items-center">
            <PAIconInvShop />
            <div className="flex flex-col gap-0.5">
              <span className="text-sm font-[500]">{invoiceNumber}</span>
              <span className=" text-[10px] text-[#959595]">
                Invoice number
              </span>
            </div>
          </div>

          <div className="hidden sm:flex items-center gap-16">
            <div className="flex items-center gap-2 ">
              <PAIconModelOne />
              <div className="flex flex-col gap-0.5">
                <span className="text-sm font-[500]">{buyerCompanyName}</span>
                <span className=" text-[10px] text-[#959595]">Buyer</span>
              </div>
            </div>

            <div className="flex items-center gap-2 ">
              <PAIconCocacola />
              <div className="flex flex-col gap-0.5">
                <span className="text-sm font-[500]">
                  {supplierCompanyName}
                </span>
                <span className=" text-[10px] text-[#959595]">Supplier</span>
              </div>
            </div>

            <div className="flex items-center gap-2 ">
              <div className="flex flex-col gap-0.5">
                <span className="text-sm text-secondaryColor font-[500]">
                  Bid Active
                </span>
                <span className=" text-[10px] text-[#959595]">
                  Status of invoice
                </span>
              </div>
            </div>
          </div>
        </div>

        <motion.button
          onClick={(event) => {
            event.stopPropagation();
            setId(id);
            lockInvoice(true);
          }}
          whileTap={{ scale: 0.9 }}
          className="flex border border-primaryColor text-sm justify-center gap-2 bg-white text-primaryColor rounded-[5px] items-center px-2.5 py-[5px] "
        >
          <span className="sm:hidden md:inline">Bid</span> <PAIconLockYellow />
          <span className=" pl-2 border-l border-primaryColor">
            00h:56m:23s
          </span>
        </motion.button>
      </div>

      <div className="flex justify-between">
        <div className="text-sm flex gap-3">
          <span className=" text-black">Lock Rate:</span>
          <span className=" text-[#959595]">{"1.2%" || description}</span>
        </div>

        <div className="text-sm flex gap-3">
          <span className=" text-black">Request Amount:</span>
          <span className=" text-[#959595]">${invoiceAmount}</span>
        </div>

        <div className="text-sm hidden semi-lg:flex gap-3">
          <span className=" text-black">Request Date:</span>
          <span className=" text-[#959595]">{lastModifiedDate}</span>
        </div>

        <div className="text-sm hidden semi-lg:flex gap-3">
          <span className=" text-black">Payment Date:</span>
          <span className=" text-[#959595]">{dueDate}</span>
        </div>
        {/* 
        <div className="text-sm hidden semi-lg:flex gap-3">
          <span className=" text-black">No. of bids on Invoice </span>
          <span className=" text-[#959595]">{numberOfBids}</span>
        </div> */}
      </div>
    </motion.div>
  );
}

export default OpenMarketTabCard;
