import React from "react";
import { PAIDangerAlert } from "assets/images/svgs";
import Button from "shared/Button";
import Spinner from "shared/Spinner/Spinner";

const DisputeCheckModal = ({
  isOpen,
  onClose,
  message,
  onConfirm,
  loading,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
        <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center px-6 min-w-[395px]">
          <div className="flex justify-end items-end  mb-4 w-full">
            <button
              onClick={onClose}
              className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
            >
              &times; <span className="text-sm mx-2 mt-1">Close</span>
            </button>
          </div>
          <PAIDangerAlert />
          {message && (
            <p className="text-[#979797] text-center  text-base font-normal leading-normal tracking-wide mb-3 my-5">
              {message}
            </p>
          )}
          <div className=" justify-center mt-3  gap-6">
            <Button
              neutral
              disabled={loading}
              className="flex justify-center items-center w-[100px] h-[41px] px-6 py-3  !text-white bg-primaryColor border !border-[#07593D] rounded-md text-sm font-medium"
              onClick={onConfirm}
            >
              {loading ? <Spinner /> : "Dispute"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisputeCheckModal;
