import Modal from "layout/modal/Modal";
import React from "react";
import SelectBuyerForBulkRequest from "./BulkRequestContents";

const BulkRequestSelectBuyerModal = ({ hide, isShown }) => {
  return (
    <Modal accountModal isShown={isShown} hide={hide}>
      <SelectBuyerForBulkRequest hide={hide} />
    </Modal>
  );
};

export default BulkRequestSelectBuyerModal;
