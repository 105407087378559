import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";

const PayableFinanceInvoices = () => {
  const navigate = useNavigate();
  const onRowClicked = () => {
    let path = "payableinvoicedetails";
    navigate(path);
  };
  const columns = [
    {
      name: "Invoice Reference",
      selector: (row) => row.invoiceReference,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Supplier",
      selector: (row) => row.supplier,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Invoiced Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "10rem",
    },
  ];

  const data = [
    {
      id: 1,
      invoiceReference: "INV-2090",
      supplier: "Dynamic Discounting",
      invoiceDate: "11-02-2023",
      dueDate: "11-12-2023",
      currency: "NGN",
      invoiceAmount: "9,000,000",
      status: "Paid",
    },
    {
      id: 2,
      invoiceReference: "INV-2090",
      supplier: "Dynamic Discounting",
      invoiceDate: "11-02-2023",
      dueDate: "11-12-2023",
      currency: "NGN",
      invoiceAmount: "9,000,000",
      status: "Paid",
    },
    {
      id: 3,
      invoiceReference: "INV-2090",
      supplier: "Dynamic Discounting",
      invoiceDate: "11-02-2023",
      dueDate: "11-12-2023",
      currency: "NGN",
      invoiceAmount: "9,000,000",
      status: "Paid",
    },
    {
      id: 4,
      invoiceReference: "INV-2090",
      supplier: "Dynamic Discounting",
      invoiceDate: "11-02-2023",
      dueDate: "11-12-2023",
      currency: "NGN",
      invoiceAmount: "9,000,000",
      status: "Paid",
    },

    {
      id: 5,
      invoiceReference: "INV-2090",
      supplier: "Dynamic Discounting",
      invoiceDate: "11-02-2023",
      dueDate: "11-12-2023",
      currency: "NGN",
      invoiceAmount: "9,000,000",
      status: "Paid",
    },
  ];
  const path = "/invoice/invoice";
  return (
    <DashboardLayout>
      <Card className="p-[24px] rounded-[10px]">
        <div>
          <TableHeader
            details="18 Invoices on Payable Flex PFP"
            btnName="Import Invoices"
            payableFinance
            path={path}
          />
        </div>
        <Table
          columns={columns}
          data={data}
          onRowClicked={onRowClicked}
          //   progressPending={isLoading}
          //   progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
      </Card>
    </DashboardLayout>
  );
};

export default PayableFinanceInvoices;
