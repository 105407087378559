import React from "react";
import { PAIconFilter, PAIconOutlineSearch } from "assets/images/svgs";

const SearchInput = () => {
  return (
    <div>
      <div className='flex items-center justify-between gap-5'>
        <div className='border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white'>
          <div className='items-center flex bg-white'>
            <PAIconOutlineSearch />
            <input
              className='bg-transparent p-2 w-full focus:outline-none text-sm bg-white'
              type='text'
              placeholder='Search for request'
            />
          </div>
          <div className='lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer'>
            <PAIconFilter className='bg-[#2FA06A]' />
          </div>
        </div>
        <button className='hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white'>
          <PAIconFilter /> Filter
        </button>
      </div>
    </div>
  );
};

export default SearchInput;
