import React, { useState } from "react";
import RouteHeader from "../../RouteHeader";
import { PAIconIosArrow, PAIconTemplateEmail } from "assets/images/svgs";
import TemplateDetails from "./TemplateDetails";

const EmailTemplate = ({goBack}) => {
  const [selectedCard, setSelectedCard] = useState(null);
  function handleGoBack(){
    setSelectedCard(null)
  }
  const cardItems = [
    {
      id: 1,
      header: "Request Association",
      title: "You have 4 customized templates",
      date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000), 
    },
    {
      id: 2,
      header: "Invitation Request",
      title: "Customize your email ",
      date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
    },
    {
      id: 3,
      header: "Agreement Notification",
      title: "Customize your email",
      date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000), 
    },
  ];

  const handleCardClick = (id) => {
    setSelectedCard(id);
  };

  // header of the template details
  const header = (
    <div className="flex grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap4 items-center justify-between">
      <RouteHeader handleBack={handleGoBack} Route1="Tools" icon={<PAIconIosArrow className="inline-block ml-2 mr-2" />} Route2="Email Templates" Heading={selectedCard ? cardItems.find(card => card.id === selectedCard)?.header : null} />
    </div>
  );

  const content = selectedCard ? (
    <div className="p4">
      {/* Render content for the selected card */}
      <TemplateDetails card={selectedCard} />
    </div>
  ) : (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 lg:grid-cols4 xl:grid-cols4 gap-4 mt-3">
      {/* Render Card component for each item */}
      {cardItems.map((item) => (
        <div
          key={item.id}
          className="w-full cursor-pointer"
          onClick={() => handleCardClick(item.id)}
        >
          <Card item={item} />
        </div>
      ))}
    </div>
  );

  return (
      <div className="px-5">
      {selectedCard ? header : (
        <div className="flex grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap4 items-center justify-between">
          <RouteHeader handleBack={goBack} Route1="Tools" Heading="Email Templates" />
        </div>
      )}
      {content}
    </div>
  );
};

const Card = ({ item }) => (
  <div className="flex flex-col max-wxl mb-4 rounded-md shadow-sm cursor-pointer mt-10">
    <div className="bg-[#07593D] py-10 flex items-center justify-center rounded-md">
      <h1 className="text-white font-sans font-semibold text-xl hfull">{item.header}</h1>
    </div>
    <div className="bg-white p-4 flex justify-between items-center mb-[-10px] mt-[-20px]">
      <div className="flex items-center">
        <PAIconTemplateEmail className="w6 h6 mr-2" />
        <span className="text-black font-sans font-bold text-xs">{item.title}</span>
      </div>
    </div>
    <p className="text-[#959595] font-sans text-xs font-normal px-4 mb-5 pb10">
      Last Updated: {item.date.toDateString()}
    </p>
  </div>
);


export default EmailTemplate;
