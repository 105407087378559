import React from "react";

import Headers from "./headers";
import SingleBulkOption from "./option";

import DashboardLayout from "layout/dashboardlayout";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PAIconArrowLeftGreen } from "assets/images/svgs";

const ReinbursementDirectFundInvoice = () => {
  const location = useLocation();
  const route = location.state;
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <DashboardLayout
        section={"Reimbursement"}
        subHeader={"Track invoices due for reimbursement"}
      >
        <Link
          onClick={goBack}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full bg-white py-4 rounded-md px-4"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>
        <Headers />
        <SingleBulkOption route={route} />
      </DashboardLayout>
    </>
  );
};

export default ReinbursementDirectFundInvoice;
