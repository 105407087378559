import Details from "features/relationshipManagement/supplier/buyer/Details";
import Associate from "features/relationshipManagement/supplier/buyer/associate";
import Createproduct from "features/inventory/Product/Createproduct";
import Product from "features/inventory/Product/Product";
import AllInvoiceContents from "features/invoice/AllInvoiceDashboard";
import NewInvoiceDashboardContents from "features/invoice/NewInvoiceDashboard";
import SingleInvoiceContents from "features/invoice/SingleInvoice";
import AddAccount from "features/onboarding/buyer/AddAccount";
import BuyerCompanyDetails from "features/onboarding/buyer/BuyerCompanyDetails";
import PersonalDetails from "features/onboarding/buyer/PersonalDetails";
import Terms from "features/onboarding/buyer/Terms";
import BuyerInviteSupplier from "features/onboarding/buyer/buyerOnboardSupplier/BuyerInviteSupplier";
import BuyerOnboardSupplierAddAccount from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierAddAccount";
import BuyerOnboardSupplierCompanyDetails from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierCompanyDetails";
import BuyerOnboardSupplierGetStarted from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierGetStarted";
import BuyerOnboardSupplierSigIn from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierSignIn";
import BuyerOnboardSupplierSignUp from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierSignUp";
import BuyerOnboardSupplierTerms from "features/onboarding/buyer/buyerOnboardSupplier/BuyerOnboardSupplierTerms";
import NextSteps from "features/onboarding/buyer/buyerOnboardSupplier/NextSteps";
import CooperateFunderCompany from "features/onboarding/funder/co-operate-funder/CooperateFunderCompany";
import CooperateFunderGetstarted from "features/onboarding/funder/co-operate-funder/CooperateFunderGetstarted";
import CooperateFunderPassword from "features/onboarding/funder/co-operate-funder/CooperateFunderPassword";
import CooperateFunderTerms from "features/onboarding/funder/co-operate-funder/CooperateFunderTerms";
import AddBankAll from "features/onboarding/supplier/Addbank";
import Companydetails from "features/onboarding/supplier/Companydetails";
import GetStartedAll from "features/onboarding/supplier/Getstarted";
import SelectBuyer from "features/onboarding/supplier/SelectBuyer";
import SupplierSignup from "features/onboarding/supplier/SupplierSignup";
import TermsAndCondition from "features/onboarding/supplier/TermsAndCondition";
import CreatePO from "features/purchaseOrder/createPurchaseOrder/CreatePO";
import SelectProducts from "features/purchaseOrder/createPurchaseOrder/SelectProducts";
import CreateComplaint from "features/support/components/CreateComplaint";
import UserCommunication from "features/support/components/UserCommunication";
// import TransactionDashboard from "features/transactions/transactionDashboard";
import SupplierBuyersPage from "pages/relationshipmanagement/supplier/buyers";
import CategoryPage from "pages/CategoryPage";
import Dashboard from "pages/Dashboard";
import Inventory from "pages/Inventory";
import InvoiceDashboard from "pages/Invoice";
import LandingPage from "pages/LandingPage";
import PurchaseOrder from "pages/PurchaseOrder";
import SettingsPage from "pages/SettingsPage";
import SupportPage from "pages/SupportPage";
import TransactionPage from "pages/TransactionPage";
import { Outlet } from "react-router-dom";
import ResendVerification from "shared/ResendVerification";
import RoleManagerAccountsPage from "pages/RoleManagerAccountsPage";
import RoleManagerDashboardPage from "pages/RoleManagerDashboard";
import RoleManagerDocument from "pages/RoleManagerDocument";
import FunderDetails from "features/relationshipManagement/supplier/funder/Details";
import AssociateFunders from "features/relationshipManagement/supplier/funder/associate";
import BuyerDetails from "features/relationshipManagement/funder/buyer/Details";
import FunderAssociateBuyers from "features/relationshipManagement/funder/buyer/associate";
import FunderBuyerPendingRequest from "features/relationshipManagement/funder/buyer/pendingRequest";
import SupplierDetails from "features/relationshipManagement/funder/supplier/Details";
import FunderAssociateSuppliers from "features/relationshipManagement/funder/supplier/associate";
import FunderSupplierPendingRequest from "features/relationshipManagement/funder/supplier/pendingRequest";
import BuyerSupplierDetails from "features/relationshipManagement/buyer/supplier/Details";

import BuyerSuppliersPendingRequest from "features/relationshipManagement/buyer/supplier/pendingRequest";
import BuyerFunderDetails from "features/relationshipManagement/buyer/funder/Details";
import BuyerAssociateFunders from "features/relationshipManagement/buyer/funder/associate";
import SupplierFundersPage from "pages/relationshipmanagement/supplier/funders";
import FunderBuyersPage from "pages/relationshipmanagement/funder/buyer";
import FunderSuppliersPage from "pages/relationshipmanagement/funder/supplier";
import BuyerSuppliersPage from "pages/relationshipmanagement/buyer/supplier";
import BuyerFunderPage from "pages/relationshipmanagement/buyer/funder";
import NotificationPage from "pages/NotificationPage";
import FunderSignup from "features/onboarding/funder/funder/FunderSignup";
import FunderTerms from "features/onboarding/funder/funder/FunderTerms";
import FunderGetStarted from "features/onboarding/funder/funder/FunderGetStarted";
import FunderCompany from "features/onboarding/funder/funder/FunderCompany";
import ToolsAgreement from "features/ToolsAgreement/ToolsAgreement";
import RulesConfig from "features/RulesConfig/RulesConfig";
import AccountManagementPage from "features/wallet/accountManagement";
import RuleConfigScreen from "features/RulesConfig/common";
import MemberSignup from "features/onboarding/Member/MemberSignup";
import MemberTermsAndCondition from "features/onboarding/Member/MemberTerms";
import MemberGetstarted from "features/onboarding/Member/MemberGetStarted";
import FundingSectionPage from "features/invoice/fundingSection";
import PayableFinancingDashboard from "pages/PayableFinancingDashboard";
import PayableFinanceDetails from "features/payableFinancing/PayableFinanceDetails";
import PayableFinanceInvoices from "features/payableFinancing/PayableFinanceInvoices";
import PayableInvoiceDetails from "features/payableFinancing/invoicedetails";

import Pfpdetails from "features/funderpayablefinance/Pfpdetails";
import FunderPfpInvoiceDetails from "features/funderpayablefinance/FunderPfpInvoiceDetails";
import PfpPendingRequest from "features/funderpayablefinance/pendingrequest";
import OpenMarket from "features/openMarket";
import ViewInvoiceDetails from "features/openMarket/funder/components/ViewInvoiceDetails";
import AcceptedInvoiceDetails from "features/openMarket/supplier/components/AcceptedInvoiceDetails";
import LockedInvoiceDetails from "features/openMarket/supplier/components/LockedInvoiceDetails";
import RequestedInvoiceDetails from "features/openMarket/supplier/components/RequestedInvoiceDetails";
import SingleRequestInvoiceList from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest";
import InvoiceDetails from "features/invoice/fundingSection/earlyPaymentRequest/singlerequest/InvoiceDetails";
import SelectBulkTable from "features/invoice/fundingSection/earlyPaymentRequest/bulkrequest";
import DirectFundingSingleRequestInvoiceList from "features/invoice/fundingSection/directFundingRequest/singlerequest";
import DirectFundingInvoiceDetails from "features/invoice/fundingSection/directFundingRequest/singlerequest/InvoiceDetails";
import DirectFundingSelectBulkTable from "features/invoice/fundingSection/directFundingRequest/bulkrequest";
import DirectFundingInvoiceList from "features/invoice/fundingSection/directFundingRequest/bulkrequest/DirectFundingInvoiceList";
import SingleEarlyPaymentDetails from "features/invoice/buyerInvoice/earlyPayment/singleEarlyPayment/Details";
import BulkEarlyPaymentDetails from "features/invoice/buyerInvoice/earlyPayment/bulkEarlyPayment/Details";
import NewSingleEarlyPaymentDetails from "features/invoice/buyerInvoice/newEarlyPaymentRequests/newSingleEarlyPayment/Details";
import NewBulkEarlyPaymentDetails from "features/invoice/buyerInvoice/newEarlyPaymentRequests/newBulkEarlyPayment/Details";
import BulkEarlyPaymentDetailsInvoice from "features/invoice/buyerInvoice/newEarlyPaymentRequests/newBulkEarlyPayment/Details/invoiceIndex";
import TransactionHistoryDetails from "features/collections/supplier/transactionHistory/Details";
import ReceivablesPreview from "features/collections/supplier/recievables/Details";
import EarlyPaymentPreview from "features/collections/supplier/earlyPayment/Details";
import DirectFundingPreview from "features/collections/supplier/directFunding/Details";
import OpenMarketPreview from "features/collections/supplier/openMarket/Details";
import CollectionDashboard from "features/collections";
import PayableFinancePreview from "features/collections/funder/payableFinanceProgram/Details";
import Funders from "features/invoice/fundingSection/directFundingRequest/Funders";
import AcceptedInvoiceFunderDetails from "features/openMarket/funder/components/AcceptedInvoiceFunderDetails";

import ImportInvoice from "features/invoice/fundingSection/importInvoice";
import CreatePfp from "features/payableFinancing/createpfp";
import ViewDetails from "features/settings/components/WorkflowConfig/ViewDetails";
import AddWorkflow from "features/settings/components/WorkflowConfig/AddWorkflow";
import PaymentExtensionDashboard from "features/paymentExtension";
import ApprovedExtensionPreview from "features/paymentExtension/funder/approvedExtensions/Details";
import BulkApprovedExtensionPreview from "features/paymentExtension/funder/approvedExtensions/Details/bulkpreview";
import BulkInvoice from "features/paymentExtension/funder/approvedExtensions/Details/bulkinvoice";
import SingleNewExtensionRequestDetails from "features/paymentExtension/funder/newExtensionRequests/single.js/Details";
import BulkNewExtensionRequestDetails from "features/paymentExtension/funder/newExtensionRequests/bulk/Details";
import InvoiceBulk from "features/paymentExtension/funder/newExtensionRequests/bulk/Details/invoice";
import SingleUpdated from "features/paymentExtension/buyer/single/updated";
import SingleRejected from "features/paymentExtension/buyer/single/rejected";
import SinglePending from "features/paymentExtension/buyer/single/pending";
import SingleApproved from "features/paymentExtension/buyer/single/approved";
import SingleExtensionInvoiceList from "features/paymentExtension/buyer/single/Modal/Table";
import SingleInvoiceDetails from "features/paymentExtension/buyer/single/InvoiceDetails";
import BulkRejected from "features/paymentExtension/buyer/bulk/rejected/Details";
import BulkPaymentExtensionPreview from "features/paymentExtension/buyer/bulk/invoice/invoiceIndex";
import BulkPending from "features/paymentExtension/buyer/bulk/pending/Details";
import PfpTableDetails from "features/funderpayablefinance/PfpTableDetails";
import ViewFundInvoice from "features/funderpayablefinance/components/ViewFundInvoice";
import BulkApproved from "features/paymentExtension/buyer/bulk/approved/Details";
import BulkExtensionInvoiceList from "features/paymentExtension/buyer/bulk/createBulkRequest/Table";
import SingleDirectFundingDetails from "features/invoice/funder/components/pages/directFunding/directFunding/singleDirectFunding/Details";
import BulkDirectFundingPaymentDetails from "features/invoice/funder/components/pages/directFunding/directFunding/bulkDirectFunding/Details";
import BulkDirectFundingInvoiceDetails from "features/invoice/funder/components/pages/directFunding/directFunding/bulkDirectFunding/Details/BulkDirectFundingInvoiceDetails";
import NewSingleDirectFundingDetails from "features/invoice/funder/components/pages/newdirectfunding/directFundingNew/singleDirectFunding/Details";
import NewBulkDirectFundingPaymentDetails from "features/invoice/funder/components/pages/newdirectfunding/directFundingNew/bulkDirectFunding/Details";
import NewBulkDirectFundingInvoiceDetails from "features/invoice/funder/components/pages/newdirectfunding/directFundingNew/bulkDirectFunding/Details/BulkDirectFundingInvoiceDetails";
import UploadInvoice from "features/invoice/fundingSection/invoiceupload";
import ValidationMaping from "features/invoice/fundingSection/invoiceupload/ValidationMaping";
import InvoiceTable from "features/invoice/fundingSection/invoiceupload/InvoiceTable";
import Invoice from "features/paymentExtension/buyer/bulk/createBulkRequest/bulkInvoice";
import SupplierEarlyPaymentDashboard from "features/supplierEarlyPayment/Dashboard";
import SupplierEarlypaymentDetails from "features/supplierEarlyPayment/details";
import SupplierEarlyPaymentBulkInvoice from "features/supplierEarlyPayment/bulkInvoice";

import ClosedMarketDashBoard from "pages/ClosedMarket";
import MyInvoicesDetails from "features/closedmarket/components/carddetails/MyInvoicesDetails";
import RequestDetails from "features/closedmarket/components/carddetails/RequestsDetails";
// import BuyerTabs from "features/BuyerEarlyPayment/Tabs";
import FunderList from "features/BuyerEarlyPayment/EarlyPayment/FunderList";
import FunderInvoiceDetails from "features/BuyerEarlyPayment/EarlyPayment/singleEarlyPayment/Details/FunderInvoiceDetails";

import TopContestedInvoiceDetail from "features/closedmarket/components/carddetails/TopContestedInvoiceDetail";

import DirectEarlyPaymentRequestsDetailContainer from "features/closedmarket/closedMarketInvoice/DirectEarlyPaymentRequestsDetailContainer";
import DirectEarlyPaymentRequests from "features/closedmarket/closedMarketInvoice/DirectEarlyPaymentRequests";

import ReinbursementDirectFundInvoice from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/DirectFundingInvoice";
import SingleDirectFundingDetai from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/Single/Detail";
import DirectFundingPageDetail from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFunding/detail";
import BulkDirectFundingPageDetails from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/bulk/Details";
import ReimbursementDashboard from "features/Reimbursement/reimbursementDashboard";
import ReimbursementDirectFunding from "features/Reimbursement/buyerReimbursement/directFunding";
import BuyerSingleDetails from "features/Reimbursement/buyerReimbursement/directFunding/single/details";
import BuyerBulkDetails from "features/Reimbursement/buyerReimbursement/directFunding/bulk/details";
import BuyerBulkInvoice from "features/Reimbursement/buyerReimbursement/directFunding/bulk/invoice";
import ReimbursementBuyerEaryPayment from "features/Reimbursement/buyerReimbursement/earlyPayment";
import ReimbursementBuyerOpenMarket from "features/Reimbursement/buyerReimbursement/openMarket";
import ReimbursementBuyerClosedMarket from "features/Reimbursement/buyerReimbursement/closedMarket";

import DisbursementInvoice from "features/disbursement/common/DisbursementInvoice";
import OpenMarketSelectableDetail from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/openmarket/OpenMarketSelectableDetail";
import OpenMarketDirectFundingDetai from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/openmarket/details";
import BulkDirectFundingInnerPageDetails from "features/payments/reinbursement/reinbursementDashBoard/supplier/directFundingInvoice/bulk/Details/InnerDetail";
import DisbursementDashboard from "features/disbursement";

import DisbursementDirectFundingDetails from "features/disbursement/buyer/directFunding/Details";
import DisbursementBulkDirectFundingPageDetails from "features/disbursement/buyer/directFunding/bulk";
import DisbursementBulkDirectFundingInnerPageDetails from "features/disbursement/buyer/directFunding/bulk/InnerDetail";

// import SettlementBoard from "pages/Settlement";
import SettlementDashboardContents from "features/settlement";
import ViewReconciliationHistory from "features/settlement/buyer/reconciliation/viewHistory/ViewReconciliationHistory";
import ReconciliationDetail from "features/settlement/buyer/reconciliation/Detail";
import AllPayableFinance from "features/payableFinancing";
import FundsAndPortfolioDashboard from "features/fundsPortfolio/dashboard";
import ActiveInvoiceDetails from "features/openMarket/supplier/components/ActiveInvoiceDetails";
import KYC from "features/onboarding/supplier/KYC";
import PortfolioInvoiceDetails from "features/fundsPortfolio/dashboard/invoice";
import FundedInvoice from "features/fundsPortfolio/dashboard/fundedInvoice";
import ConnectedPlayers from "features/fundsPortfolio/connectedPlayers";
import PortfolioManagement from "features/fundsPortfolio/portfolioManagement";
import DisbursementTransactionHistory from "features/disbursement/buyer/transactionHistory";
import FundingAgreement from "features/onboarding/supplier/FundingAgreement";
import CheckLoginComplete from "./CheckLoginComplete";

import BuyerOpenMarketDashBoard from "features/factoring/openMarket";
import FacOpenMarketAllInvoiceDetail from "features/factoring/openMarket/all/details";
import FacOpenMarketFundedDetail from "features/factoring/openMarket/funded/details";
import FacOpenMarketPendingDetail from "features/factoring/openMarket/pendingfunding/details/FacOpenMarketPendingDetail";
import FacDirectFundingPendingDetail from "features/factoring/directFunding/pendingfunding/details/FacOpenMarketPendingDetail";
import BuyerDirectFundingDashBoard from "features/factoring/directFunding";
import VerifyEmail from "shared/VerifyEmail/VerifyEmail";
import ResendVerificationLink from "shared/VerifyEmail/ResendVerification";
import SupplierEarlyPaymentAcceptedInvoice from "features/supplierEarlyPayment/invoices/invoice";
import SupplierEarlyPaymentRejectedInvoice from "features/supplierEarlyPayment/invoices/rejectedInvoice";
import SupplierEarlyPaymentPendingInvoice from "features/supplierEarlyPayment/invoices/pendingInvoice";
import AllSupplierInvoices from "features/invoice/InvoiceDashboard/Tables/AllSupplierInvoices";
import BuyerInvoiceDetails from "features/invoice/buyerInvoice/invoiceDetails";
import TopContestPage from "features/openMarket/funder/components/TopContestPage";
import FunderPayableFinance from "features/funderpayablefinance";
import FunderClosedMarketDashBoard from "features/payableFinaceForFunder/CloseMarket";
import FunderClosedMarketOverViewDetails from "features/payableFinaceForFunder/CloseMarket/overview/details";
import FunderClosedMarketClosedMarketDetails from "features/payableFinaceForFunder/CloseMarket/closedMarket/details";
import FunderClosedMarketOnGoingBidDetails from "features/payableFinaceForFunder/CloseMarket/ongoingBid/details";
import BukOverViewDetails from "features/payableFinaceForFunder/CloseMarket/overview/details/BulkOverviewDetail";
import BulkClosedMarketDetails from "features/payableFinaceForFunder/CloseMarket/closedMarket/details/BulkClosedMarketDetails";
import OngoingBidBulkDetails from "features/payableFinaceForFunder/CloseMarket/ongoingBid/details/OngoingBidBulkDetails";
import BulkDetailPage from "features/payableFinaceForFunder/CloseMarket/overview/details/BulkDetailPage";
import WalletDashboard from "features/wallet(new)";
import Tier from "features/relationshipManagement/buyer/supplier/tier/tier";
import AssociationRequest from "features/relationshipManagement/buyer/supplier/associationRequest/associationRequest";
import AssociationRequestFunder from "features/relationshipManagement/buyer/funder/associationRequest/associationReequest";

export const privateRoutes = [
  {
    path: "/dashboard",
    element: (
      <CheckLoginComplete>
        <Dashboard />
      </CheckLoginComplete>
    ),
  },
  {
    path: "/notifications",
    element: <NotificationPage />,
  },
  {
    path: "/alltiers",
    element: <Tier />,
  },
  {
    path: "purchase-order/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <PurchaseOrder />,
      },
      {
        path: "createpo",
        element: <CreatePO />,
      },
      {
        path: "createpo/selectproducts",
        element: <SelectProducts />,
      },
    ],
  },
  {
    path: "accounts/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <SupplierBuyersPage />,
      },
      {
        path: "accountdetails",
        element: <Details />,
      },
      {
        path: "associate",
        element: <Associate />,
      },
    ],
  },
  {
    path: "supplier-funders/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <SupplierFundersPage />,
      },
      {
        path: "funderdetails",
        element: <FunderDetails />,
      },
      {
        path: "associate-funders",
        element: <AssociateFunders />,
      },
    ],
  },
  {
    path: "funder-buyers/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FunderBuyersPage />,
      },
      {
        path: "buyerdetails",
        element: <BuyerDetails />,
      },
      {
        path: "funder-associate-buyer",
        element: <FunderAssociateBuyers />,
      },
      {
        path: "funder-pending-buyer-request",
        element: <FunderBuyerPendingRequest />,
      },
    ],
  },
  {
    path: "funder-suppliers/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FunderSuppliersPage />,
      },
      {
        path: "supplierdetails",
        element: <SupplierDetails />,
      },
      {
        path: "funder-associate-supplier",
        element: <FunderAssociateSuppliers />,
      },
      {
        path: "funder-pending-supplier-request",
        element: <FunderSupplierPendingRequest />,
      },
    ],
  },
  {
    path: "buyer-suppliers/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <BuyerSuppliersPage />,
      },
      {
        path: "supplierdetails",
        element: <BuyerSupplierDetails />,
      },

      {
        path: "buyer-pending-supplier-request",
        element: <BuyerSuppliersPendingRequest />,
      },

      {
        path: "buyer-association-supplier-request",
        element: <AssociationRequest />,
      },
    ],
  },
  {
    path: "buyer-funders/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <BuyerFunderPage />,
      },
      {
        path: "funderdetails",
        element: <BuyerFunderDetails />,
      },
      {
        path: "buyer-associate-funders",
        element: <BuyerAssociateFunders />,
      },
      {
        path: "buyer-association-funder-request",
        element: <AssociationRequestFunder />
      }
    ],
  },
  {
    path: "invoice/*",
    element: <Outlet />,
    children: [
      {
        path: "invoice",
        element: <InvoiceDashboard />,
      },
      {
        path: "all-invoices",
        element: <AllInvoiceContents />,
      },
      {
        path: "all-invoice",
        element: <AllSupplierInvoices />,
      },
      {
        path: "buyer-invoice-details/:id",
        element: <BuyerInvoiceDetails />,
      },
      {
        path: "supplier-invoice-details/:id",
        element: <BuyerInvoiceDetails />,
      },
      {
        path: "new-invoice",
        element: <NewInvoiceDashboardContents />,
      },
      {
        path: "edit-invoice/:invoiceId",
        element: <NewInvoiceDashboardContents />,
      },
      {
        path: ":id",
        element: <SingleInvoiceContents />,
      },
      {
        path: "invoice/fundingSection",
        element: <FundingSectionPage />,
      },
      {
        path: "fundingSection/singlerequestinvoicelist",
        element: <SingleRequestInvoiceList />,
      },
      {
        path: "fundingSection/singlerequestinvoicelist/invoicedetails",
        element: <InvoiceDetails />,
      },
      {
        path: "fundingSection/bulkrequestinvoicelist",
        element: <SelectBulkTable />,
      },
      {
        path: "fundingSection/directsinglerequestFundersLists",
        element: <Funders />,
      },
      {
        path: "fundingSection/directsinglerequestinvoicelist",
        element: <DirectFundingSingleRequestInvoiceList />,
      },
      {
        path: "fundingSection/directsinglerequestinvoicelist/directinvoicedetails",
        element: <DirectFundingInvoiceDetails />,
      },
      {
        path: "fundingSection/directbulkinvoicelist",
        element: <DirectFundingInvoiceList />,
      },
      {
        path: "fundingSection/directfundingbulkrequest/directfundingbulklist",
        element: <DirectFundingSelectBulkTable />,
      },
      {
        path: "invoice/singleearlypaymentdetails/approved",
        element: <SingleEarlyPaymentDetails />,
      },
      {
        path: "invoice/bulkearlypaymentdetails/approved",
        element: <BulkEarlyPaymentDetails />,
      },
      {
        path: "invoice/singleearlypaymentdetails/new",
        element: <NewSingleEarlyPaymentDetails />,
      },
      {
        path: "invoice/bulkearlypaymentdetails/new",
        element: <NewBulkEarlyPaymentDetails />,
      },
      {
        path: "invoice/bulkearlypaymentdetails/new/invoice",
        element: <BulkEarlyPaymentDetailsInvoice />,
      },
      {
        path: "/invoice/singledirectfunding/approved",
        element: <SingleDirectFundingDetails />,
      },
      {
        path: "/invoice/bulkdirectfundingypaymentdetails/approved",
        element: <BulkDirectFundingPaymentDetails />,
      },
      {
        path: "/invoice/bulkdirectfundingypaymeninvoicetdetails/approved",
        element: <BulkDirectFundingInvoiceDetails />,
      },
      {
        path: "/invoice/newsingledirectfunding/approved",
        element: <NewSingleDirectFundingDetails />,
      },
      {
        path: "/invoice/newbulkdirectfundingypaymentdetails/approved",
        element: <NewBulkDirectFundingPaymentDetails />,
      },
      {
        path: "/invoice/newbulkdirectfundingypaymeninvoicetdetails/approved",
        element: <NewBulkDirectFundingInvoiceDetails />,
      },
      {
        path: "/invoice/fundingSection/invoiceupload",
        element: <UploadInvoice />,
      },
      {
        path: "/invoice/fundingSection/invoiceupload/validation",
        element: <ValidationMaping />,
      },
      {
        path: "/invoice/fundingSection/invoiceupload/validation/invoice-table",
        element: <InvoiceTable />,
      },
      {
        path: "invoice/fundingSection/invoice-import",
        element: <ImportInvoice />,
      },
    ],
  },

  //Buyer Payable Finance Program
  {
    path: "payablefinancing/*",
    element: <Outlet />,
    children: [
      {
        path: "/overview",
        element: <PayableFinancingDashboard />,
        // element: <BuyerTabs />,
      },
      {
        path: "payablefinace/singleearlypaymentdetails/approved",
        element: <SingleEarlyPaymentDetails />,
      },
      {
        path: "payablefinanceProgram",
        element: <AllPayableFinance />,
      },
      {
        path: "payablefinance",
        element: <AllPayableFinance />,
      },
      {
        path: "singleearlypaymentdetails/approved",
        element: <SingleEarlyPaymentDetails />,
      },
      {
        path: "bulkearlypaymentdetails/approved",
        element: <BulkEarlyPaymentDetails />,
      },
      {
        path: "singleearlypaymentdetails/new",
        element: <NewSingleEarlyPaymentDetails />,
      },
      {
        path: "bulkearlypaymentdetails/new",
        element: <NewBulkEarlyPaymentDetails />,
      },
      {
        path: "bulkearlypaymentdetails/new/invoice",
        element: <BulkEarlyPaymentDetailsInvoice />,
      },
      {
        path: "payablefinanceProgram/payablefinancedetails",
        element: <PayableFinanceDetails />,
      },

      {
        path: "payablefinancedetail/createpayablefinance",
        element: <CreatePfp />,
      },
      {
        path: "payablefinancedetails/payablefinanceinvoice",
        element: <PayableFinanceInvoices />,
      },
      {
        path: "payablefinancedetails/payablefinanceinvoice/payableinvoicedetails",
        element: <PayableInvoiceDetails />,
      },
      {
        path: "payablefinace/singleearlypaymentdetails/approved/directfuning_funderlist",
        element: <FunderList />,
      },
      {
        path: "payablefinace/fundingSection/directsinglerequestinvoicelist/directinvoicedetails",
        element: <FunderInvoiceDetails />,
      },
    ],
  },

  // started here

  {
    path: "factoring/*",
    element: <Outlet />,
    children: [
      {
        path: "/openmarket",
        element: <BuyerOpenMarketDashBoard />,
      },
      { path: "/directfunding", element: <BuyerDirectFundingDashBoard /> },
      {
        path: "/facopenmarketallinvoicedetail/:id",
        element: <FacOpenMarketAllInvoiceDetail />,
      },
      // FacDirectFundingPendingDetail
      {
        path: "/facopenmarketpendingdetail/:id",
        element: <FacOpenMarketPendingDetail />,
      },

      {
        path: "/facdirectfundingpendingdetail/:id",
        element: <FacDirectFundingPendingDetail />,
      },

      {
        path: "/facopenmarketfundeddetail/:id",
        element: <FacOpenMarketFundedDetail />,
      },
    ],
  },

  //mine ends here

  {
    path: "settlement/*",
    element: <Outlet />,
    children: [
      { path: "/", element: <SettlementDashboardContents /> },
      {
        path: "/detail/viewreconciliation",
        element: <ViewReconciliationHistory />,
      },
      {
        path: "/detail/reconciliationdetail/:id",
        element: <ReconciliationDetail />,
      },
    ],
  },
  {
    path: "closedmarket/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <ClosedMarketDashBoard />,
      },

      {
        path: "myinvoicesdetails/:id",
        element: <MyInvoicesDetails />,
      },
      {
        path: "requestsdetails/:id",
        element: <RequestDetails />,
      },
      {
        path: "topContestedInvoices/",
        element: <TopContestedInvoiceDetail />,
      },

      {
        path: "directEarlyPaymentRequests/",
        element: <DirectEarlyPaymentRequests />,
      },
      {
        path: "directEarlyPaymentRequestsDetail/",
        element: <DirectEarlyPaymentRequestsDetailContainer />,
      },
    ],
  },

  {
    path: "reinbursement/*",
    element: <Outlet />,
    children: [
      { path: "/", element: <ReimbursementDashboard /> },
      {
        path: "/reinbursementdirectfundinvoice",
        element: <ReinbursementDirectFundInvoice />,
      },
      {
        path: "/singledirectfundingdetail",
        element: <SingleDirectFundingDetai />,
      },
      {
        path: "/detail/directfundingpageDetail",
        element: <DirectFundingPageDetail />,
      },
      {
        path: "/detail/bulkdirectfundingpagedetails/:id",
        element: <BulkDirectFundingPageDetails />,
      },
      {
        path: "/detail/bulkdirectfundinginnerpagedetails/:id",
        element: <BulkDirectFundingInnerPageDetails />,
      },
      {
        path: "/detail/openmarketselectabledetail",
        element: <OpenMarketSelectableDetail />,
      },
      {
        path: "/detail/openmarketdirectfundingdetai/:id",
        element: <OpenMarketDirectFundingDetai />,
      },
    ],
  },

  //Funder Payable Finace Program

  //Funder Payable Finace Program
  {
    path: "payablefinancing-campaign*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FunderPayableFinance />,
      },
      {
        path: "funderpayablefinancedetails",
        element: <Pfpdetails />,
      },
      {
        path: "funderpayablefinanceviewdetails",
        element: <PfpTableDetails />,
      },
      {
        path: "funderpayablefinanceviewdetails/:id",
        element: <ViewFundInvoice />,
      },
      {
        path: "funderpayablefinancedetails/funderpayablefinanceinvoice",
        element: <FunderPfpInvoiceDetails />,
      },
      {
        path: "pfppendingrequest",
        element: <PfpPendingRequest />,
      },
    ],
  },
  //afunder payablefinance for closedmarket

  //payablefinancing-closedmarket/funderclosedmarketoverviewdetails/

  {
    path: "payablefinancing-closedmarket/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FunderClosedMarketDashBoard />,
      },
      {
        path: "funderclosedmarketoverviewdetails/:id",
        element: <FunderClosedMarketOverViewDetails />,
      },
      {
        path: "funderclosemarketdetails/:id",
        element: <FunderClosedMarketClosedMarketDetails />,
      },
      {
        path: "funderclosemarketbulkdetails/:id",
        element: <BulkClosedMarketDetails />,
      },
      {
        path: "funderclosedmarketongoingbiddetails/:id",
        element: <FunderClosedMarketOnGoingBidDetails />,
      },
      {
        path: "funderclosedmarketongoingbidbulkdetails/:id",
        element: <OngoingBidBulkDetails />,
      },
      {
        path: "funderclosedmarketbulkoverviewdetails/:id",
        element: <BukOverViewDetails />,
      },
      {
        path: "funderclosedmarketbuldetailspage/:id",
        element: <BulkDetailPage />,
      },
    ],
  },
  {
    path: "inventory/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <Inventory />,
      },
      {
        path: "createproduct",
        element: <Createproduct />,
      },
      {
        path: "product/:id",
        element: <Product />,
      },
    ],
  },
  {
    path: "wallet/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <WalletDashboard />,
      },
      {
        path: "transactions",
        element: <TransactionPage />,
      },
      {
        path: "accountManagement",
        element: <AccountManagementPage />,
      },
    ],
  },

  //DISBURSEMENT ROUTES
  {
    path: "disbursement/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <DisbursementDashboard />,
      },
      {
        path: "transactionhistory/disbursementtransactionhistory",
        element: <DisbursementTransactionHistory />,
      },

      {
        path: "invoice/:id",
        element: <DisbursementInvoice />,
      },
      {
        path: "detail/disbursementdirectfundingdetails/:id",
        element: <DisbursementDirectFundingDetails />,
      },

      {
        path: "detail/disbursementbulkdirectfundingpagedetails/:id",
        element: <DisbursementBulkDirectFundingPageDetails />,
      },
      {
        path: "detail/disbursementbulkdirectfundinginnerpagedetails/:id",
        element: <DisbursementBulkDirectFundingInnerPageDetails />,
      },
    ],
  },

  {
    path: "collections/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <CollectionDashboard />,
      },
      {
        path: "transactionhistory/:id",
        element: <TransactionHistoryDetails />,
      },
      {
        path: "recievables/:id",
        element: <ReceivablesPreview />,
      },
      {
        path: "earlypayment/:id",
        element: <EarlyPaymentPreview />,
      },
      {
        path: "directfunding/:id",
        element: <DirectFundingPreview />,
      },
      {
        path: "open-market/:id",
        element: <OpenMarketPreview />,
      },
      {
        path: "payablefinanceprogram/:id",
        element: <PayableFinancePreview />,
      },
    ],
  },

  {
    path: "funds&portfolio/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <FundsAndPortfolioDashboard />,
      },
      {
        path: "/portfolioinvoice/:id",
        element: <PortfolioInvoiceDetails />,
      },
      {
        path: "/fundedinvoice",
        element: <FundedInvoice />,
      },
      {
        path: "/connectedplayers",
        element: <ConnectedPlayers />,
      },
      {
        path: "/portfoliomanagement",
        element: <PortfolioManagement />,
      },
    ],
  },

  {
    path: "payment-extension/*",
    element: <PaymentExtensionDashboard />,
    children: [
      {
        path: "/",
        element: <PaymentExtensionDashboard />,
      },
      {
        path: "singleapprovedextension/:id",
        element: <ApprovedExtensionPreview />,
      },
      {
        path: "bulkapprovedextension/:id",
        element: <BulkApprovedExtensionPreview />,
      },
      {
        path: "bulkapprovedextension/:id/:id",
        element: <BulkInvoice />,
      },
      {
        path: "singlenewextensiondetails/:id",
        element: <SingleNewExtensionRequestDetails />,
      },
      {
        path: "bulknewextensiondetails/:id",
        element: <BulkNewExtensionRequestDetails />,
      },
      {
        path: "bulknewextensiondetailspreview/:id/",
        element: <InvoiceBulk />,
      },
      {
        path: "single/updated/:id/",
        element: <SingleUpdated />,
      },
      {
        path: "single/rejected/:id/",
        element: <SingleRejected />,
      },
      {
        path: "single/pending/:id/",
        element: <SinglePending />,
      },
      {
        path: "single/approved/:id/",
        element: <SingleApproved />,
      },
      {
        path: "singleextensioninvoicelist",
        element: <SingleExtensionInvoiceList />,
      },
      {
        path: "singleextensioninvoicelist/invoicedetails",
        element: <SingleInvoiceDetails />,
      },
      {
        path: "bulk/rejected/:id/",
        element: <BulkRejected />,
      },
      {
        path: "bulk/invoice/:id",
        element: <BulkPaymentExtensionPreview />,
      },
      {
        path: "bulk/invoice/",
        element: <Invoice />,
      },
      {
        path: "bulk/pending/:id/",
        element: <BulkPending />,
      },
      {
        path: "bulk/approved/:id/",
        element: <BulkApproved />,
      },
      {
        path: "bulkextensioninvoicelist",
        element: <BulkExtensionInvoiceList />,
      },
    ],
  },

  {
    path: "supplier-earlypayment/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <SupplierEarlyPaymentDashboard />,
      },
      {
        path: "details/:offerId",
        element: <SupplierEarlypaymentDetails />,
      },
      // {
      //   path: "invoice/:offerId/:invoiceId",
      //   element: <SupplierEarlyPaymentAcceptedInvoice />,
      // },
      {
        path: "invoice/pending/:id",
        element: <SupplierEarlyPaymentPendingInvoice />,
      },
      {
        path: "invoice/accepted/:id",
        element: <SupplierEarlyPaymentAcceptedInvoice />,
      },
      {
        path: "invoice/rejected/:id",
        element: <SupplierEarlyPaymentRejectedInvoice />,
      },
      {
        path: "bulk-invoice/:offerId",
        element: <SupplierEarlyPaymentBulkInvoice />,
      },
    ],
  },
  {
    path: "reimbursement/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <ReimbursementDashboard />,
      },
      {
        path: "/directfunding",
        element: <ReimbursementDirectFunding />,
      },
      {
        path: "/singlereimbursement/:id",
        element: <BuyerSingleDetails />,
      },
      {
        path: "/bulkreimbursement/:id",
        element: <BuyerBulkDetails />,
      },
      {
        path: "/invoice/:id",
        element: <BuyerBulkInvoice />,
      },
      {
        path: "/earlypayment",
        element: <ReimbursementBuyerEaryPayment />,
      },
      {
        path: "/openmarket",
        element: <ReimbursementBuyerOpenMarket />,
      },
      {
        path: "/closedmarkegggt",
        element: <ReimbursementBuyerClosedMarket />,
      },

      { path: "/", element: <ReimbursementDashboard /> },
      {
        path: "/reinbursementdirectfundinvoice",
        element: <ReinbursementDirectFundInvoice />,
      },
      {
        path: "/singledirectfundingdetail",
        element: <SingleDirectFundingDetai />,
      },
      {
        path: "/detail/directfundingpageDetail",
        element: <DirectFundingPageDetail />,
      },
      {
        path: "/detail/bulkdirectfundingpagedetails/:id",
        element: <BulkDirectFundingPageDetails />,
      },
      {
        path: "/detail/bulkdirectfundinginnerpagedetails/:id",
        element: <BulkDirectFundingInnerPageDetails />,
      },
      {
        path: "/detail/openmarketselectabledetail",
        element: <OpenMarketSelectableDetail />,
      },
      {
        path: "/detail/openmarketdirectfundingdetai/:id",
        element: <OpenMarketDirectFundingDetai />,
      },
    ],
  },
  {
    path: "payment-extension/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <PaymentExtensionDashboard />,
      },
      {
        path: "/singleapprovedextension/:id",
        element: <ApprovedExtensionPreview />,
      },
      {
        path: "/bulkapprovedextension/:id",
        element: <BulkApprovedExtensionPreview />,
      },
      {
        path: "/bulkapprovedextension/:id/:id",
        element: <BulkInvoice />,
      },
      {
        path: "/singlenewextensiondetails/:id",
        element: <SingleNewExtensionRequestDetails />,
      },
      {
        path: "/bulknewextensiondetails/:id",
        element: <BulkNewExtensionRequestDetails />,
      },
      {
        path: "/bulknewextensiondetailspreview/:id/",
        element: <InvoiceBulk />,
      },
      {
        path: "/single/updated/:id/",
        element: <SingleUpdated />,
      },
      {
        path: "/single/rejected/:id/",
        element: <SingleRejected />,
      },
      {
        path: "/single/pending/:id/",
        element: <SinglePending />,
      },
      {
        path: "/single/approved/:id/",
        element: <SingleApproved />,
      },
      {
        path: "/singleextensioninvoicelist",
        element: <SingleExtensionInvoiceList />,
      },
      {
        path: "/singleextensioninvoicelist/invoicedetails",
        element: <SingleInvoiceDetails />,
      },
      {
        path: "/bulk/rejected/:id/",
        element: <BulkRejected />,
      },
      {
        path: "/bulk/invoice/:id",
        element: <BulkPaymentExtensionPreview />,
      },
      {
        path: "/bulk/invoice/",
        element: <Invoice />,
      },

      {
        path: "/bulk/pending/:id/",
        element: <BulkPending />,
      },

      {
        path: "/bulk/approved/:id/",
        element: <BulkApproved />,
      },
      {
        path: "/bulkextensioninvoicelist",
        element: <BulkExtensionInvoiceList />,
      },
    ],
  },

  {
    path: "support/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <SupportPage />,
      },

      {
        path: "createcomplaint",
        element: <CreateComplaint />,
      },

      {
        path: "usercommunication/:id",
        element: <UserCommunication />,
      },
    ],
  },
  {
    path: "/settings",
    element: <SettingsPage />,
  },

  {
    path: "/settings/tools&agreement/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <ToolsAgreement />,
      },
    ],
  },

  {
    path: "/settings/workflow-config/*",
    element: <Outlet />,
    children: [
      {
        path: "/:id",
        element: <ViewDetails />,
      },
      {
        path: "/add-workflow",
        element: <AddWorkflow />,
      },
    ],
  },

  // RULES & CONFIGURATION
  {
    path: "/settings/rules&config/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <RulesConfig />,
      },
      {
        path: "/:ruleType",
        element: <RuleConfigScreen />,
      },
    ],
  },

  // Open Market
  {
    path: "/open-market/*",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <OpenMarket />,
      },
      {
        path: "/:id",
        element: <ViewInvoiceDetails />,
      },
      {
        path: "activeInvoice/:id",
        element: <ActiveInvoiceDetails />,
      },

      {
        path: "acceptedInvoice/:id",
        element: <AcceptedInvoiceDetails />,
      },
      {
        path: "lockedInvoice/:id",
        element: <LockedInvoiceDetails />,
      },
      {
        path: "requestedInvoice/:id",
        element: <RequestedInvoiceDetails />,
      },
      {
        path: "accepted-bid/:id",
        element: <AcceptedInvoiceFunderDetails nofund />,
      },
      {
        path: "top-ten-invoices",
        element: <TopContestPage />,
      },
    ],
  },

  //role manager
  {
    path: "/role-manager",
    element: <RoleManagerDashboardPage />,
  },

  {
    path: "/account-management",
    element: <RoleManagerAccountsPage />,
  },
  {
    path: "/documents",
    element: <RoleManagerDocument />,
  },

  //onboarding
  {
    path: "buyer/*",
    element: <Outlet />,
    children: [
      {
        path: "company-details",
        element: <BuyerCompanyDetails />,
      },
      {
        path: "terms",
        element: <Terms />,
      },

      {
        path: "add-account",
        element: <AddAccount />,
      },
    ],
  },
  {
    path: "buyer-onboard-supplier/*",
    element: <Outlet />,
    children: [
      {
        path: "buyer-invite-supplier",
        element: <BuyerInviteSupplier />,
      },
      {
        path: "next-steps",
        element: <NextSteps />,
      },
      {
        path: "create-account",
        element: <BuyerOnboardSupplierSignUp />,
      },
      {
        path: "sign-in",
        element: <BuyerOnboardSupplierSigIn />,
      },
      {
        path: "add-account",
        element: <BuyerOnboardSupplierAddAccount />,
      },

      {
        path: "get-started",
        element: <BuyerOnboardSupplierGetStarted />,
      },
      {
        path: "company-details",
        element: <BuyerOnboardSupplierCompanyDetails />,
      },

      {
        path: "terms",
        element: <BuyerOnboardSupplierTerms />,
      },
    ],
  },
  {
    path: "supplier/*",
    element: <Outlet />,
    children: [
      {
        path: "select-buyer",
        element: <SelectBuyer />,
      },
      {
        path: "company-details",
        element: <Companydetails />,
      },
      {
        path: "terms",
        element: <TermsAndCondition />,
      },
    ],
  },

  {
    path: "funder/*",
    element: <Outlet />,
    children: [
      {
        path: "funder-company-details",
        element: <FunderCompany />,
      },
      {
        path: "terms",
        element: <FunderTerms />,
      },
      {
        path: "get-started",
        element: <FunderGetStarted />,
      },
    ],
  },

  // Dynamic Onboarding routes

  {
    path: "onboarding/:player/*",
    element: <Outlet />,
    children: [
      {
        path: "kyc",
        element: <KYC />,
      },
      {
        path: "sign-funding-agreement",
        element: <FundingAgreement />,
      },
      {
        path: "get-started",
        element: <GetStartedAll />,
      },
      {
        path: "banking-details",
        element: <AddBankAll />,
      },
    ],
  },
];

export const publicRoutes = [
  {
    path: "/",
    element: <LandingPage />,
  },

  {
    path: "/verify-email",
    element: <VerifyEmail />,
  },
  {
    path: "/resend-verification-link",
    element: <ResendVerificationLink />,
  },

  {
    path: "/resend-verification",
    element: <ResendVerification />,
  },
  {
    path: "/category",
    element: <CategoryPage />,
  },
  {
    path: "/personal-details",
    element: <PersonalDetails />,
  },
  {
    path: "/supplier-create-account",
    element: <SupplierSignup />,
  },
  {
    path: "funder/Cooperate-Funder/*",
    element: <Outlet />,
    children: [
      {
        path: "company-details",
        element: <CooperateFunderCompany />,
      },
      {
        path: "password",
        element: <CooperateFunderPassword />,
      },
      {
        path: "get-started",
        element: <CooperateFunderGetstarted />,
      },
      {
        path: "terms",
        element: <CooperateFunderTerms />,
      },
    ],
  },
  {
    path: "/funder-create-account",
    element: <FunderSignup />,
  },
  {
    path: "member/*",
    element: <Outlet />,
    children: [
      {
        path: "membersignup",
        element: <MemberSignup />,
      },
      {
        path: "memberTerms",
        element: <MemberTermsAndCondition />,
      },
      {
        path: "membergetstarted",
        element: <MemberGetstarted />,
      },
    ],
  },
];
