import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepOne from "./StepOne";
import { useDispatch } from "react-redux";
import { allBuyerAssociatedFunders } from "appstate/buyer/buyerSlice";
import Preview from "./Preview";

const CreatePfp = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [dynamic, setDynamic] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [errors, setErrors] = useState({});
  const initialOptions = [
    { label: " Tier 1", value: "TIER_1", checked: false },
    { label: " Tier 2", value: "TIER_2", checked: false },
    { label: " Tier 3", value: "TIER_3", checked: false },
    { label: " Tier 4", value: "TIER_4", checked: false },
    { label: " Tier 5", value: "TIER_5", checked: false },
    { label: " Tier 6", value: "TIER_6", checked: false },
    { label: " Tier 7", value: "TIER_7", checked: false },
    { label: " Tier 8", value: "TIER_8", checked: false },
    { label: " Tier 9", value: "TIER_9", checked: false },
    { label: " Tier 10", value: "TIER_10", checked: false },
  ];
  const [options, setOptions] = useState(initialOptions);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const selectedOptions = options.filter((option) => option.checked);
  const [userPfpId, setuserPfpId] = useState("");
  const [formData, setFormData] = useState({
    programName: "",
    startDate: "",
    endDate: "",
    tiers: "",
    financingOptions: "",
    discount: "",
    minAmount: "",
    maxAmount: "",
    buyerShare: "",
    funderIds: "",
    paymentDuration: "",
  });
  const dispatch = useDispatch();

  const handleDynamic = (e) => {
    const isChecked = e.target.checked;
    setDynamic(isChecked);
    if (isChecked) {
      setFormData((prev) => ({
        ...prev,
        financingOptions: "DYNAMIC_DISCOUNTING",
      }));
      // Disable reverse checkbox when dynamic is checked
      setReverse(false); // Uncheck reverse if dynamic is checked
    } else {
      setFormData((prev) => ({
        ...prev,
        financingOptions: "",
      }));
    }
  };

  const handleReverse = (e) => {
    const isChecked = e.target.checked;
    setReverse(isChecked);
    if (isChecked) {
      setFormData((prev) => ({
        ...prev,
        financingOptions: "REVERSE_FACTORING",
      }));
      // Disable dynamic checkbox when reverse is checked
      setDynamic(false); // Uncheck dynamic if reverse is checked
    } else {
      setFormData((prev) => ({
        ...prev,
        financingOptions: "",
      }));
    }
  };

  const page = 0;

  const pageSize = 10;

  const handleSearch = (searchQuery) => {
    dispatch(
      allBuyerAssociatedFunders({ page, pageSize, search: searchQuery })
    ).then((response) => {
      setFilteredData(response?.payload?.data);
    });
  };

  const handleChange = (event) => {
    const searchQuery = event.target.value;
    setSearchName(searchQuery);
    handleSearch(searchQuery);
  };

  const handleSelect = (funder) => {
    setFormData((prevData) => ({
      ...prevData,
      funderIds: [...prevData.funderIds, funder.userId],
    }));
    setStoreData([funder, ...storeData]);
    setFilteredData([]);
    setSearchName("");
  };

  useEffect(() => {
    if (formData.funderIds.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        funderIds: "Please select at least one funder",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        funderIds: "",
      }));
    }
  }, [formData]);

  const handleRemoveEmail = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      funderIds: prevData.financingOptions.filter((_, i) => i !== index),
    }));
    const updatedFunder = storeData.filter((_, i) => i !== index);
    setStoreData(updatedFunder);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors({
      ...errors,
      [name]: "",
    });

    if (name === "startDate") {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const selectedDate = new Date(value);
      selectedDate.setHours(0, 0, 0, 0);

      if (selectedDate < today) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please select a date on or after today.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }

    if (name === "endDate") {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(value);

      if (endDate < startDate) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "End date cannot be before the start date",
        }));
      }
    }

    if (name === "minAmount" || name === "maxAmount") {
      const minAmount =
        name === "minAmount"
          ? parseFloat(value)
          : parseFloat(formData.minAmount);
      const maxAmount =
        name === "maxAmount"
          ? parseFloat(value)
          : parseFloat(formData.maxAmount);

      if (!isNaN(minAmount) && !isNaN(maxAmount) && minAmount >= maxAmount) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Amount can not be below minimum",
        }));
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim() === "") {
      const formattedName = name
        .split(/(?=[A-Z])/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      setErrors({
        ...errors,
        [name]: `${formattedName} is required`,
      });
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.programName) {
      newErrors.programName = "Programe is required";
      isValid = false;
    }

    if (!formData.startDate) {
      newErrors.startDate = "Start Date is required";
      isValid = false;
    }

    if (!formData.endDate) {
      newErrors.endDate = "End Date is required";
      isValid = false;
    }

    if (formData.tiers.length === 0) {
      newErrors.tiers = "Tiers is required";
      isValid = false;
    }

    if (formData.financingOptions.length === 0) {
      newErrors.financingOptions = "Financing options is required";
      isValid = false;
    }

    if (!formData.discount) {
      newErrors.discount = "Discount is required";
      isValid = false;
    }

    if (!formData.minAmount) {
      newErrors.minAmount = "Minimum amount is required";
      isValid = false;
    }

    if (!formData.maxAmount) {
      newErrors.maxAmount = "Maximum amount is required";
      isValid = false;
    }

    if (!formData.paymentDuration) {
      newErrors.paymentDuration = "paymentDuration is required";
      isValid = false;
    }

    if (!dynamic && !formData.buyerShare) {
      newErrors.buyerShare = "Buyer share is required";
      isValid = false;
    }

    if (!dynamic && formData.funderIds.length === 0) {
      newErrors.funderIds = "Please select funder";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleTiersChange = (option) => {
    const updatedOptions = options.map((opt) =>
      opt.value === option.value ? { ...opt, checked: !opt.checked } : opt
    );
    setOptions(updatedOptions);

    const selectedTiers = updatedOptions
      .filter((opt) => opt.checked)
      .map((opt) => opt.value);

    setFormData((prevData) => ({
      ...prevData,
      tiers: selectedTiers,
    }));

    if (selectedTiers.length === 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tiers: "Tiers is required",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tiers: "",
      }));
    }
  };

  const isNextDisabled =
    !formData.programName ||
    !formData.startDate ||
    !formData.endDate ||
    !formData.tiers ||
    !formData.financingOptions ||
    !formData.discount ||
    !formData.minAmount ||
    !formData.maxAmount ||
    new Date(formData.endDate) < new Date(formData.startDate) ||
    new Date(formData.startDate).setHours(0, 0, 0, 0) <
      new Date().setHours(0, 0, 0, 0);

  const handleNextPage = (e) => {
    setCurrentPage(currentPage + 1);
    // if (validateForm()) {
    // } else {
    //   toast.error("Fill all required fields");
    // }
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <DashboardLayout>
      <div className="bg-white rounded-[10px] pb-[2.75rem]">
        <div className="bg-alabasterHeader px-[1.5rem] py-[1rem] rounded-t-[10px] mb-[2.75rem]">
          <p className="text-[#A6A6A6] text-[14px]">
            <span className="text-primaryColor text-[16px] font-semibold mr-[5px]">
              Step {currentPage}
            </span>{" "}
            of Step 3
          </p>
        </div>
        {currentPage === 1 || currentPage === 2 ? (
          <h1 className="text-black text-[1rem] font-semibold mb-[1.88rem] text-center">
            Create New Campaign
          </h1>
        ) : null}

        <div className="flex justify-center">
          {currentPage === 1 && (
            <StepOne
              formData={formData}
              handleInputChange={handleInputChange}
              errors={errors}
              dynamic={dynamic}
              reverse={reverse}
              handleReverse={handleReverse}
              handleDynamic={handleDynamic}
              isNextDisabled={isNextDisabled}
              handleNextPage={handleNextPage}
              validateForm={validateForm}
              setuserPfpId={setuserPfpId}
            />
          )}
          {currentPage === 2 && (
            <StepTwo
              formData={formData}
              errors={errors}
              searchName={searchName}
              handleChange={handleChange}
              dynamic={dynamic}
              handleInputChange={handleInputChange}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              filteredData={filteredData}
              storeData={storeData}
              handleSelect={handleSelect}
              handleRemoveEmail={handleRemoveEmail}
              isDropdownOpen={isDropdownOpen}
              selectedOptions={selectedOptions}
              setIsDropdownOpen={setIsDropdownOpen}
              handleTiersChange={handleTiersChange}
              options={options}
              userPfpId={userPfpId}
              financingOptions={formData.financingOptions}
            />
          )}
        </div>
        {currentPage === 3 && (
          <StepThree
            //   formData={formData}
            //   setFormData={setFormData}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            //   handleFormSubmit={handleFormSubmit}
          />
        )}
        {currentPage === 4 && (
          <Preview
            //   formData={formData}
            //   setFormData={setFormData}
            handlePreviousPage={handlePreviousPage}
            //   handleFormSubmit={handleFormSubmit}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default CreatePfp;
