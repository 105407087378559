import "../styles/switch.css";
function Switch({ isToggled, onToggle, width = "49px", height = "28px" }) {
  return (
    <label style={{ height, width }} className='switch'>
      <input
        className=''
        type='checkbox'
        checked={isToggled}
        onChange={onToggle}
      />
      <span className='slider'></span>
    </label>
  );
}

export default Switch;
