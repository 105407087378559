import React, { useEffect, useState } from "react";
import {
  PAIconFilter,
  PAIconOutlineSearch,
  PAIconDropdown,
} from "assets/images/svgs";
import { useDispatch } from "react-redux";
import { allDirectFunding } from "appstate/invoice/invoiceSlice";
import Table from "layout/table/Table";
import Loading from "shared/Loading";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";

const TransactionHistory = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dispatch = useDispatch();

  const [searchQuery] = useState("");

  const pageSize = 10;

  useEffect(() => {
    dispatch(
      allDirectFunding({
        pageSize,
        bulk: "",
        status: "APPROVED",
        search: searchQuery,
      })
    );
  }, [dispatch, pageSize, searchQuery]);

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const dumData = [
    {
      idx: "1",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Paid",
    },
    {
      idx: "4",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Paid",
    },
    {
      idx: "5",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Paid",
    },
    {
      idx: "2",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Paid",
    },
    {
      idx: "3",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Paid",
    },
    {
      idx: "6",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Paid",
    },
    {
      idx: "7",
      companyName: "XYZ Ventures",
      player: "Supplier",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Paid",
    },
    {
      idx: "8",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Paid",
    },
    {
      idx: "9",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Paid",
    },

    {
      idx: "10",
      companyName: "Dangote Ltd",
      player: "Funder",
      invoiceDate: "2023-05-23",
      invoiceReference: "INV-2090",
      currency: "NGN",
      amount: 9000000,
      status: "Paid",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Beneficiary
        </span>
      ),
      selector: (row) => row.companyName,
      sortable: true,
      cell: (row) => (
        <div className="">
          <p>{row.companyName}</p>
          <span
            className={`${
              row.player === "Supplier" ? "text-[#2EA923]" : "text-[#F08000]"
            }`}
          >
            {`(${row.player})`}
          </span>
        </div>
      ),
    },
    {
      name: "Payment Date",
      selector: (row) => row.invoiceDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <p className="text-success">{formatAmount(row.amount)}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Paid" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PARTIALLY PAID" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div className="border border-gray300 rounded text-[#959595] p-2">
          View Details
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        {" "}
        <div className="my-2 grid md:grid-cols-2 items-center">
          <div className="gap-3 flex relative">
            <div>
              <div
                className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
                onClick={handleDropdownToggle}
              >
                Filter{" "}
                {selectedFilters.length > 0 && (
                  <span className="bg-gray rounded-lg p-1 px-2">
                    {selectedFilters.length}
                  </span>
                )}{" "}
                Selected <PAIconDropdown />
              </div>
              {isDropdownOpen && (
                <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                  <label className="block">
                    <input
                      type="checkbox"
                      value="date"
                      checked={selectedFilters.includes("date")}
                      onChange={handleFilterChange}
                    />
                    Date
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="name"
                      checked={selectedFilters.includes("name")}
                      onChange={handleFilterChange}
                    />
                    Name
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="currency"
                      checked={selectedFilters.includes("currency")}
                      onChange={handleFilterChange}
                    />
                    Currency
                  </label>
                  <label className="block">
                    <input
                      type="checkbox"
                      value="amount"
                      checked={selectedFilters.includes("amount")}
                      onChange={handleFilterChange}
                    />
                    Amount
                  </label>
                </div>
              )}
            </div>
            <div>
              <select
                className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
                onChange={(e) => {}}
              >
                <option value="">Sort by</option>
                <option value="date">Date</option>
                <option value="name">Name</option>
              </select>
            </div>
            <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 ">
              Export
            </button>
          </div>
          <div className="flex md:col-span-1 justify-end">
            {/* <SearchInput /> */}
            {/* starts from here */}
            <div>
              <div className="flex items-center justify-between gap-5">
                <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                  <div className="items-center flex bg-white">
                    <PAIconOutlineSearch />
                    <input
                      className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                      type="text"
                      placeholder="Search for due invoices awaiting collection"
                    />
                  </div>
                  <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div>
                </div>
                <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                  <PAIconFilter /> Filter
                </button>
              </div>
            </div>

            {/* ends here */}
          </div>
        </div>
        <>
          {!dumData.length ? (
            <div className="ml3">
              <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
                <p className="text-black text-sm font-medium">
                  Overview of CashInflow Records
                </p>
              </div>
              <TableEmptyState dashboard />
            </div>
          ) : (
            <Card className="rounded-[10px] mt-[24px] ">
              <div className="bg-alabasterHeader rounded-t-[10px] min-h-20 px-[24px]   ">
                <div className=" border-b-[0.8px] border-zinc-400 py-4 h-full ">
                  <div className="flex  items-center    ">
                    <div>
                      <p className="text-black text-lg font-semibold">
                        Recent disbursement history
                      </p>
                      <span className="text-xs font-thin text-[#959595]">
                        Here is a recent list of your receivables from Payment
                        disbursement
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* top ends here */}
              <div className="px-[24px] pb[24px]">
                <Table
                  columns={columns}
                  data={dumData}
                  // onRowClicked={(row) => transactionDetails(row.idx)}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              </div>
            </Card>
          )}
        </>
      </div>
    </>
  );
};

export default TransactionHistory;
