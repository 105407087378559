import DashboardLayout from "layout/dashboardlayout";
import React from "react";

import Details from "./details";

const DisbursementDirectFundingDetails = () => {
  return (
    <DashboardLayout
      section="Disbursement"
      subHeader="Track invoices due for payment disbursement"
    >
      <Details />
    </DashboardLayout>
  );
};

export default DisbursementDirectFundingDetails;
