import * as Yup from "yup";

export const addBuyerBankValidationSchema = Yup.object({
  bankName: Yup.string().required("This field is required"),
  bankCountry: Yup.string().required("This field is required"),
  currency: Yup.string().required("This field is required"),
  accountNumber: Yup.string()
    .matches(/^\d+$/, "Account number can only contain digits")
    .length(10, "Account number must be exactly 10 digits")
    .required("This field is required"),
  accountHolder: Yup.string().required("This field is required"),
});
