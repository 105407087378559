import request from "apiInstance";
import toast from "react-hot-toast";

export const requestDirectFunding = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/fund-request/direct-funding`,
      data: body
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};