import { addCompanyDetails } from "appstate/auth/authSlice";
import OnboardingLayout from "features/onboarding/category/components/OnboardingLayout";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import InputField from "shared/InputField";
// import MultiSelectDropdown from "shared/MultiSelectDropdown";  PAIconOnboardArrowLeftIcon
import MultiSelectDropdown from "shared/BuyerMultiSelectDropdown";
import Spinner from "shared/Spinner/Spinner";
import { industries } from "utilities/Industries";
import { buyerCompanyDetailsValidationSchema } from "validation/buyer/BuyerCompanyDetails";
import Contact from "../components/Contact";
import { PAIconOnboardArrowLeftIcon } from "assets/images/svgs";

const BuyerCompanydetails = () => {
  const [checked, setChecked] = useState(false);
  const { isLoading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedCountryCode, setSelectedCountryCode] = useState("+234");

  const formik = useFormik({
    initialValues: {
      companyName: "",
      rcNumber: "",
      companyWebsite: "",
      companyPhoneNumber: "",
      registeredAddress: "",
      companyTaxId: "",
      companyEmail: "",
      industry: [],
    },
    validationSchema: buyerCompanyDetailsValidationSchema,
    onSubmit: (values) => {
      const phoneNumberWithCountryCode = `${selectedCountryCode}${values.companyPhoneNumber}`;
      const body = {
        companyName: values.companyName,
        rcNumber: values.rcNumber,
        companyWebsite: values.companyWebsite,
        companyPhoneNumber: phoneNumberWithCountryCode,
        industryType: values.industry,
        registeredAddress: values.registeredAddress,
        companyTaxId: values.companyTaxId,
        companyEmail: values.companyEmail,
      };

      dispatch(addCompanyDetails({ body })).then((data) => {
        if (data?.payload?.status === 200) {
          let path = "/buyer/terms";

          navigate(path);
        }
      });
    },
  });
  const handlePhoneCountryChange = (selectedCountryCode) => {
    setSelectedCountryCode(selectedCountryCode); // Update selected country code in state
  };

  useEffect(() => {
    if (
      formik.values.companyEmail &&
      formik.values.companyName &&
      formik.values.companyPhoneNumber &&
      formik.values.companyTaxId &&
      formik.values.companyWebsite &&
      formik.values.industry.length > 0 &&
      formik.values.rcNumber &&
      formik.values.registeredAddress
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [
    formik.values.companyEmail,
    formik.values.companyName,
    formik.values.companyPhoneNumber,
    formik.values.companyTaxId,
    formik.values.companyWebsite,
    formik.values.industry,
    formik.values.rcNumber,
    formik.values.registeredAddress,
  ]);

  return (
    <OnboardingLayout>
      <div className=" px-[18px] lg:px-[42px]  mt-6">
        <PAIconOnboardArrowLeftIcon
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>{" "}
      <div className="pt-[60px] flex items-center flex-col h-[100%] overflow-auto ">
        <div className="mb-[50px] mt-[30px] flex justify-center items-center gap-x-[20px]">
          <div className="relative bottom-[15px]">
            <div className="w-[100px] h-[2px]   bg-[#F08000]  rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[28px] md:bottom-[30px]">
            <p className="font-normal text-xs text-textColor mb-[10px] md:text-sm">
              Company Details
            </p>

            <div className="w-[100px] h-[2px] bg-[#F08000] rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[15px]">
            <div className="w-[100px] h-[2px]   bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[15px]">
            <div className="w-[100px] h-[2px] bg-[#D9D9D9]  rounded-[100px] md:w-[220px]"></div>
          </div>
        </div>
        {/* <h1 className="font-semibold text-[24px] text-[#222222] text-center mb-[30px]">
          Please enter and confirm your company details
        </h1>

        <p className=" text-[#7A7A7A] mb-[30px]">
          Enter your details company details here.
        </p> */}
        <div className="mb-[30px] ">
          {/* <div class=" text-[#222222] text-2xl   font-medium">
            Please check and confirm your contact details
          </div> */}
          <div class=" text-[#222222] text-2xl    font-medium">
            Please enter and confirm your company details
          </div>
          <div class="text-[#7a7a7a] text-sm  mb-[30px] text-center mt-8  font-medium">
            Enter your company details here.
          </div>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center flex-col"
        >
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px] "
              placeholder="Coca Cola"
              label="Company Name"
              name="companyName"
              id="companyName"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.companyName}
            />
            {formik.touched.companyName && formik.errors.companyName ? (
              <p className=" text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.companyName}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="Enter RC Number "
              label="Company Number (RC Number)"
              name="rcNumber"
              id="rcNumber"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.rcNumber}
            />
            {formik.touched.rcNumber && formik.errors.rcNumber ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.rcNumber}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="www.abc.com"
              label="Company Website"
              name="companyWebsite"
              id="companyWebsite"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.companyWebsite}
            />
            {formik.touched.companyWebsite && formik.errors.companyWebsite ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.companyWebsite}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px]"
              placeholder=""
              label="Registered Address"
              type="text"
              name="registeredAddress"
              id="registeredAddress"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.registeredAddress}
            />
            {formik.touched.registeredAddress &&
            formik.errors.registeredAddress ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.registeredAddress}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <Contact
              name="companyPhoneNumber"
              id="companyPhoneNumber"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.companyPhoneNumber}
              title={"Contact Number"}
              onCountryCodeChange={handlePhoneCountryChange}
            />

            {formik.touched.companyPhoneNumber &&
            formik.errors.companyPhoneNumber ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.companyPhoneNumber}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <MultiSelectDropdown
              options={industries}
              selectedOptions={formik.values.industry}
              setSelectedOptions={(selected) =>
                formik.setFieldValue("industry", selected)
              }
            />
            {formik.touched.industry && formik.errors.industry ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.industry}
              </p>
            ) : null}
          </div>
          <div className=" mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="T-098765431"
              label="Company Tax ID"
              name="companyTaxId"
              id="companyTaxId"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.companyTaxId}
            />
            {formik.touched.companyTaxId && formik.errors.companyTaxId ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.companyTaxId}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px]"
              placeholder="exampleforyou@info.com"
              label=" Company Email Address"
              id="companyEmail"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyEmail}
            />
            {formik.touched.companyEmail && formik.errors.companyEmail ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.companyEmail}
              </p>
            ) : null}
          </div>

          <button
            disabled={isLoading ? isLoading : !checked}
            auth
            className={`my-[30px]   md:w-[424px]  rounded-[5px] text-sm font-medium h-[45px] w-full text-[#222222] border-none  ${
              !checked
                ? "bg-[#d7d7d7] text-[#7a7a7a] "
                : "  bg-primaryColor text-white"
            }`}
            type="submit"
          >
            {isLoading ? <Spinner /> : "Continue"}
          </button>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default BuyerCompanydetails;
