import { allTransactions } from "appstate/wallet/walletSlice";
import { PAIconVieweye } from "assets/images/svgs";
import Table from "layout/table/Table";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";

const DirectFundTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allTransactions());
  }, [dispatch]);

  const dummyData = [
    {
      bidAmount: 3,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Paid",
      invoiceDate: "2023-05-23",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "09347T-2090",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-05-31",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "NGN",
    },

    {
      bidAmount: 4,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Part Payment",
      invoiceDate: "2023-05-23",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "09347T-2090",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-05-31",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "NGN",
    },

    {
      bidAmount: 5,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Paid",
      invoiceDate: "2023-05-23",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "09347T-2090",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-05-31",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "NGN",
    },

    {
      bidAmount: 6,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Paid",
      invoiceDate: "2023-05-23",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "09347T-2090",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-05-31",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "NGN",
    },

    {
      bidAmount: 7,
      amount: "+9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Part Payment",
      invoiceDate: "2023-05-23",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "09347T-2090",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-05-31",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "NGN",
    },

    {
      bidAmount: 8,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Part Payment",
      invoiceDate: "2023-05-23",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "09347T-2090",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-05-31",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "NGN",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction ID
        </span>
      ),
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Funder
        </span>
      ),
      selector: (row) => row.funder,
      sortable: true,
    },

    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },

    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.amount.startsWith("+", 0) ? (
          <p className="text-success">{row.amount}</p>
        ) : row.amount.startsWith("-", 0) ? (
          <p className="text-red">{row.amount}</p>
        ) : (
          <p>{row.amount}</p>
        ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Paid" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Part Payment" ? (
          <p className="text-[#07593D]">{row.status}</p>
        ) : row.status === "PENDING" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      name: "",
      selector: (row) => row.view,
      cell: (row) => (
        <div className="flex">
          <PAIconVieweye height="15px" width="30px" />
          <p className="text-[#626679] cursor-pointer">View</p>
        </div>
      ),
    },
  ];

  return (
    <>
      <Card className="rounded-[10px] mt-[24px]">
        <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
          <div>
            <p className="text-black text-sm font-medium">All Transactions</p>
            <p className="text-[10px]">Transactions Summary</p>
          </div>
          <p
            onClick={() =>
              navigate("/reimbursement/detail/directfundingpageDetail")
            }
            className="text-black text-sm font-medium underline cursor-pointer"
          >
            View All
          </p>
        </div>
        <div className="px-[24px] pb-[24px]">
          <Table
            columns={columns}
            data={dummyData}
            pointer
            tableHeader
            className="mt-5"
          />
        </div>
      </Card>
    </>
  );
};

export default DirectFundTable;
