import Modal from 'layout/modal/Modal'
import ImportModalComp from './ImportModal'

const ImportModal = ({ isShown, onClose }) => {
  return (
    <Modal isShown={isShown} hide={onClose} contrast header="Import Modal" showClose>
      <ImportModalComp onClose={onClose} />
    </Modal>
  )
}

export default ImportModal