import request from "apiInstance";
import toast from "react-hot-toast";

export const allFunderNotifications = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/notification/funder/view-all?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const notificationById = async ({ notificationId }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/notification/funder/view?id=${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
