import { PAIconCancel } from 'assets/images/svgs';
import React from 'react';
import { useSelector } from 'react-redux';

function BuyerDetails({ onClose, id }) {
  const { allBuyersData } = useSelector((state) => state?.auth);
  const buyer = allBuyersData?.data?.find((cur) => cur?.userId === id);
  return (
    <div className="px-[27px] py-[38px]">
      <header className="flex justify-between border-b-2 border-whisper200 pb-[16px]">
        <p>Request to associate</p>
        <div
          onClick={onClose}
          className="flex justify-between items-center gap-x-[10px] cursor-pointer"
        >
          <PAIconCancel className="w-[8px]" />
          <p className="text-sm font-medium text-gray800">Close</p>
        </div>
      </header>
      <h2 className="text-sm font-semibold text-black mt-[32px] mb-[24px]">
        Personal Information
      </h2>
      <div>
        <p className="text-sm font-normal text-lightGray mb-[8px]">Name</p>
        <p className="text-sm font-normal text-textColor mb-[16px]">
          {buyer?.fullName ? buyer?.fullName : 'N/A'}
        </p>
        <p className="text-sm font-normal text-lightGray mb-[8px]">Email</p>
        <p className="text-sm font-normal text-textColor mb-[16px]">
          {buyer?.email ? buyer?.email : 'N/A'}
        </p>
        <p className="text-sm font-normal text-lightGray mb-[8px]">
          Contact number
        </p>
        <p className="text-sm font-normal text-textColor">
          {buyer?.phoneNumber ? buyer?.phoneNumber : 'N/A'}
        </p>
      </div>
      <h2 className="text-sm font-semibold text-black mt-[32px] mb-[24px]">
        Company Information
      </h2>
      <div>
        <p className="text-sm font-normal text-lightGray mb-[8px]">
          Company Name
        </p>
        <p className="text-sm font-normal text-textColor mb-[16px]">
          {buyer?.companyName ? buyer?.companyName : 'N/A'}
        </p>
        <p className="text-sm font-normal text-lightGray mb-[8px]">
          Company Address
        </p>
        <p className="text-sm font-normal text-textColor mb-[16px]">
          {buyer?.registeredAddress ? buyer?.registeredAddress : 'N/A'}
        </p>
        <p className="text-sm font-normal text-lightGray mb-[8px]">
          Company Website
        </p>
        <p className="text-sm font-normal text-textColor mb-[16px]">
          {buyer?.companyWebsite ? buyer?.companyWebsite : 'N/A'}
        </p>
      </div>
    </div>
  );
}

export default BuyerDetails;
