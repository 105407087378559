import React, { useEffect, useState } from "react";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconInfoGreen,
  PAIconInvoiceIcon,
  PAIconNotification,
  PAIconSearchGreen,
  PAIconVieweye,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import * as XLSX from "xlsx";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";

import { useDispatch, useSelector } from "react-redux";
import { allDirectFunding } from "appstate/invoice/invoiceSlice";

import Pagination from "shared/Pagination";
import Loading from "shared/Loading";
import DateRangePickerModal from "shared/datePicker/DateRangePicker";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import ViewFundInvoiceModal from "features/funderpayablefinance/components/modals/ViewFundInvoiceModal";
import TransferFundsModal from "features/disbursement/common/modal/TransferFundsModal";
import EnterPin from "features/disbursement/common/modal/Pin";

const DirectFunding = () => {
  const navigate = useNavigate();
  const { invoiceByIdData } = useSelector((state) => state?.openMarket);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedTab, setSelectedTab] = useState("single");

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [acceptPaymentModal, setAcceptPaymentModal] = useState(false);
  // const [disabled, setDisabled] = useState(true);
  const [selectAllText, setSelectAllText] = useState("Select All");
  const [pinModal, setPinModal] = useState(false);

  const [selectDownloadType, setSelectDownloadType] = useState(false);
  const [makePaymentModal, setMakePaymentModal] = useState(false);

  const { isLoading } = useSelector((state) => state?.invoice);

  const directFundingData = {
    data: [
      {
        id: 1,
        refNo: "R3456X89Y",
        invoiceNumber: "2090",
        buyerCompanyName: "Access Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invoiceAmount: 9000000,
        discountAmount: 950000,
        discountRate: "1.5%",
        fundableAmount: 8150000,
      },

      {
        id: 2,
        refNo: "R3456Y89Z",
        invoiceNumber: "2080",
        buyerCompanyName: "Providus Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invoiceAmount: 9000000,
        discountAmount: 90000,
        discountRate: "1.5%",
        fundableAmount: 1350000,
      },

      {
        id: 3,
        refNo: "R3456X89Y",
        invoiceNumber: "2090",
        buyerCompanyName: "Access Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invoiceAmount: 9000000,
        discountAmount: 950000,
        discountRate: "1.5%",
        fundableAmount: 8150000,
      },

      {
        id: 4,
        refNo: "R3456X89Y",
        invoiceNumber: "2090",
        buyerCompanyName: "Access Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invoiceAmount: 9000000,
        discountAmount: 950000,
        discountRate: "1.5%",
        fundableAmount: 8150000,
      },
    ],
  };

  // const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const applyFilter = (range) => {
    setSelectedRange(range);
    closeModal();
  };

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  useEffect(() => {
    dispatch(
      allDirectFunding({
        page,
        pageSize,
        bulk: "",
        status: "APPROVED",
        search: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    sessionStorage.setItem("searchQueryFunder", value);
  };

  const handleFilterButtonClick = () => {};

  // SEND REMAINDER BUTTON
  const handleSendReminder = () => {
    setMakePaymentModal(true);
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.id);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.id);
    } else {
      newSelectedRows = [...selectedRows, row.id];
    }

    setSelectedRows(newSelectedRows);
  };

  // SELECT ALL
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      const allIds = directFundingData?.data?.map((row) => row.id);
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
    // setDisabled(selectAll);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      directFundingData.data.map((row) => ({
        "Program Ref No": row?.refNo,
        "Invoice Number": `INV-${row?.invoiceNumber}`,
        Buyer: row?.buyerCompanyName,
        "Due Date": row?.dueDate,
        "Payment Date": row?.paymentDate,
        Currency: row?.currency,
        "Invoice Amount": formatAmount(row?.invoiceAmount),
        "Discount Amount": formatAmount(row?.discountAmount),
        "Discount Rate": formatAmount(row?.discountRate),
        "Fundable Amount": formatAmount(row?.fundableAmount),
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Direct Funding");
    XLSX.writeFile(wb, "DirectFundingData.xlsx");
  };

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row?.id)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />{" "}
          {/* Program */}
          Ref. No.
        </span>
      ),
      selector: (row) => row?.refNo,
      sortable: true,
      width: "7rem",
    },

    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Inv No
        </span>
      ),
      selector: (row) => {
        const invoiceNumber = row.invoiceNumber;
        return invoiceNumber && !isNaN(invoiceNumber)
          ? `INV-${invoiceNumber}`
          : "INV-0";
      },
      sortable: true,
      // width: "9rem",
    },
    {
      name: "Company",
      selector: (row) => row?.buyerCompanyName,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
      sortable: true,
    },
    {
      name: "Pay Date",
      selector: (row) => row?.paymentDate,
      sortable: true,
      // width: "9rem",
    },

    {
      name: "Invoice Amount",
      selector: (row) => row?.invoiceAmount,
      sortable: true,
      cell: (row) => (
        <p className=" text-[#2EA923]">{formatAmount(row?.invoiceAmount)}</p>
      ),
      width: "9rem",
    },
    {
      name: "Dis. Amount",
      selector: (row) => row?.discountAmount,
      sortable: true,
      cell: (row) => (
        <p className=" text-secondaryColor">
          {formatAmount(row?.discountAmount)}
        </p>
      ),
      // width: "9rem",
    },

    {
      name: "Dis. Rate",
      selector: (row) => row?.discountRate,
      sortable: true,
      cell: (row) => (
        <p className=" text-[#2EA923]">{`${row?.discountRate}`}</p>
      ),
      // width: "9rem",
    },
    {
      name: "Fundable Amount",
      selector: (row) => row?.fundableAmount,
      sortable: true,
      cell: (row) => (
        <p className=" text-[#2EA923]">{formatAmount(row?.fundableAmount)}</p>
      ),
      width: "9rem",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconVieweye
              className="accent-gray300 w-4"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`invoice/${row?.id}`);
              }}
            />
          }
          {
            <PAIconNotification
              className="accent-gray300 w-4"
              // onClick={() => handleNotificationClick(row)}
            />
          }
          {<PAIconDownload className="accent-gray300 w-4" />}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="mb-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div className="flex bg-white items-center rounded shadow-md">
            <button
              className="px-5  text-xs py-4 bg-white rounded"
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              Filters{" "}
              {selectedRange.startDate
                ? selectedRange.startDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : "Not selected"}{" "}
              -{" "}
              {selectedRange.endDate
                ? selectedRange.endDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : "Not selected"}
            </button>
          </div>
          <DateRangePickerModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onApply={applyFilter}
          />

          <button
            onClick={() => {
              setSelectDownloadType(true);
            }}
            className="py-4 text-xs px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5"
          >
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        <div className="hidden md:flex  items-center gap-2">
          <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
            <PAIconSearchGreen />
            <input
              type="text"
              placeholder="Search for invoices on the open market"
              value={searchQuery}
              onChange={handleSearchInputChange}
              className="p-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
            />
          </div>

          <motion.button
            whileTap={{ scale: 0.9 }}
            onClick={handleFilterButtonClick}
            className="ml-2 flex gap-2 items-center justify-center bg-[#2FA06A] text-white text-sm  px-6 py-4 rounded-md duration-[200ms] ease-in  hover:bg-primaryColor focus:outline-none"
          >
            <PAIconFilter />
            Filter
          </motion.button>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!directFundingData?.data?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Due Invoices from Direct Funding
              </p>
            </div>

            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] min-h-[80vh] mt-[15px]">
            <div className="flex justify-between items-center p-[20px] bg-alabasterHeader rounded-t-[10px]">
              <div className="flex gap-6 items-center">
                <div>
                  <p className="text-black text-lg font-bold">Direct Funding</p>
                  <span className="text-xs font-thin">
                    List of all invoices you have accepted
                  </span>
                </div>

                <div>
                  <select className="text-xs border border-[#DEDEDEC7] outline-none rounded-[5px] px-5 py-3 cursor-pointer">
                    <option value="" key="">
                      Supplier
                    </option>
                    <option value="" key="">
                      Buyer
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex items-center justify-center gap-3">
                <div
                  className=" text-xs rounded-md py-2.5 px-4 text-white bg-[#2EA923] cursor-pointer"
                  onClick={handleSelectAll}
                  // checked={selectAll}
                >
                  <p>{selectAllText}</p>
                </div>
                <button
                  style={{ boxShadow: "2px 3px 10px 0px rgba(0, 0, 0, 0.10)" }}
                  className={`text-xs  rounded-md py-2.5 px-4 text-white ${
                    selectedRows.length === 0
                      ? "cursor-not-allowed bg-[#C2C0C0]"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={selectedRows.length === 0}
                  onClick={handleSendReminder}
                >
                  <p>Make Payment</p>
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <div className="pb-[24px]">
                <div className="flex px-[24px] gap-5 my-5">
                  <p
                    className={`cursor-pointer text-sm font-bold relative ${
                      selectedTab === "single"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => setSelectedTab("single")}
                  >
                    Single Request
                    {selectedTab === "single" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>

                  <p
                    className={`cursor-pointer text-sm font-bold relative ${
                      selectedTab === "bulk"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => setSelectedTab("bulk")}
                  >
                    Bulk Request
                    {selectedTab === "bulk" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>
                </div>
                {selectedTab === "bulk" ? (
                  <Table
                    columns={columns}
                    data={directFundingData?.dazzta}
                    onRowClicked={(row) => {
                      // bulkDetails(row.invoiceIds, row?.id);
                    }}
                    progressPending={isLoading}
                    progressComponent={<Loading />}
                    pointer
                    tableHeader
                    className="mt-5"
                  />
                ) : (
                  <Table
                    columns={columns}
                    data={directFundingData?.data}
                    onRowClicked={(row) => navigate(`invoice/${row?.id}`)}
                    progressPending={isLoading}
                    progressComponent={<Loading />}
                    pointer
                    tableHeader
                    className="mt-5"
                  />
                )}
                <div className="flex justify-center gap-x-[8px] mt-[20px]">
                  {directFundingData?.data?.length > 0 ||
                    (directFundingData?.data?.length > 0 && (
                      <Pagination
                        page={page}
                        startPage={startPage}
                        setEndPage={setEndPage}
                        endPage={endPage}
                        setStartPage={setStartPage}
                        setPage={setPage}
                        totalPages={directFundingData?.length}
                      />
                    ))}
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>

      {/* Accept Modal */}
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Would you like to proceed with sending a{" "}
              <span className="font-bold text-center text-primaryColor">
                Pay
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {makePaymentModal && (
        <ViewFundInvoiceModal>
          <div className="flex lg:min-w-[520px] lg:max-w-[550px] overflowscroll max-h-[87vh]  flex-col gap-4">
            <header className="flex sticky top-0 bg-white py-3  items-center justify-between">
              <span className=" text-sm">Invoice Disbursement</span>

              <motion.button
                onClick={() => {
                  setMakePaymentModal(false);
                }}
                whileTap={{ scale: 0.9 }}
                className="text-xs border border-primaryColor py-2 px-8  rounded-[5px] text-primaryColor"
              >
                Close
              </motion.button>
            </header>

            <main className="flex flex-col  gap-6">
              <section className="flex gap-28 justify-between">
                <div className="flex flex-col gap-2">
                  <span className="text-[#222] text-[20px] font-bold">
                    Wale Sach Corp.
                  </span>

                  <div className=" text-xs">
                    <span className=" text-[#7A7A7A]">78 Invoices</span> Invoice
                    No : | Buyer Info{" "}
                    <span className=" text-[#7A7A7A]">Walediwura</span>
                  </div>
                </div>

                <div className="flex items-center">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setAcceptPaymentModal(true)}
                    className="text-xs bg-primaryColor py-2 px-8  rounded-[5px] text-white"
                  >
                    Make Payment
                  </motion.button>
                </div>
              </section>

              <section className="flex gap-28 justify-between">
                <div className="flex flex-col">
                  <div>
                    <span>Payment Amount</span>
                  </div>
                  <span className="text-primaryColor text-[20px] font-bold">
                    NGN 1,890,400
                  </span>
                </div>

                <div className="flex flex-col gap-4 items-end">
                  <span className=" text-xs">
                    Total Invoice Amount &nbsp;
                    <span className="text-primaryColor border-b pb-0.5 border-b-[#AFAFAF]">
                      N2,345,000
                    </span>
                  </span>

                  <span className="border-b pb-0.5 text-xs  border-b-[#AFAFAF] w-fit">
                    Discount: 1.67%
                  </span>

                  <span className="text-[10px] font-medium flex items-center gap-1 text-[#F08000]">
                    <PAIconInfoGreen />
                    Tap to view more
                  </span>

                  <span>
                    <motion.button
                      whileTap={{ scale: 0.9 }}
                      className="py-2 px-8 bg-[#F08000] text-xs text-white  rounded-[5px]"
                    >
                      Submit new amount
                    </motion.button>
                  </span>
                </div>
              </section>

              <section className=" flex flex-col ">
                <header className=" pb-3 font-bold border-b-[5px] mb-4 border-[#F6F5FA] text-[18px]">
                  Buyer Bulk Invoice details
                </header>

                <main className="flex pb-4 justify-between">
                  <div className="flex flex-col">
                    <span className="text-sm font-medium">Supplier</span>

                    <span className="text-xs text-[#7A7A7A]">
                      ABC Cooperation
                    </span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-sm font-medium">Buyer</span>

                    <span className="text-xs text-[#7A7A7A]">
                      Big Buyer Coop
                    </span>
                  </div>
                </main>

                <div>
                  <header className=" pb-3 font-bold border-b-[5px] mb-4 border-[#F6F5FA] text-sm">
                    Line Items
                  </header>

                  <div className=" w-full overflow-x-auto">
                    {invoiceByIdData ? (
                      <table className=" w-full overflow-x-auto">
                        <thead className="bg-subtleGray w-">
                          <tr className=" bg-unusualGray p-2.5 ">
                            <td className="text-[#11192A] p-2.5 text-xs font-bold">
                              Item Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              PO Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Product/Service Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Product/Service Description
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Quantity
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Unit Price
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Line Item Extended Amount
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {invoiceByIdData?.data?.items?.map((item, index) => (
                            <tr key={index} className=" py-[3px] px-1.5">
                              <td className=" text-xs text-[#11192a] p-2.5">
                                INV-{invoiceByIdData?.data?.invoiceNumber}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                PO-2002
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.itemName}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.description}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.quantity}
                              </td>
                              <td className=" whitespace-nowrap text-xs text-[#11192a] p-2.5">
                                {invoiceByIdData?.data?.currency === "NGN"
                                  ? "₦"
                                  : "$"}{" "}
                                {item?.unitPrice}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {invoiceByIdData?.data?.currency === "NGN"
                                  ? "₦"
                                  : "$"}{" "}
                                {item?.amount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="flex flex-col gap-3 animate-pulse justify-center items-center w-full py-6 px-4 text-sm font-bold">
                        <PAIconInvoiceIcon />
                        No invoice list available
                      </div>
                    )}
                  </div>

                  <div className="flex py-5 flex-col gap-4">
                    <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                      <h1 className="text-sm font-bold">Attachments</h1>
                    </header>

                    <div className="w-full overflow-x-auto">
                      <table className="w-full overflow-x-auto">
                        <thead className="  bg-subtleGray w-">
                          <tr className="px-4  bg-unusualGray p-2.5 ">
                            <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                              Document Name
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              File Type
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              Upload Date / Time
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              Action
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          <tr className=" py-[3px] px-1.5">
                            <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                              DOC-29384.pdf
                            </td>
                            <td className=" text-xs text-[#11192a] px-4 py-2.5">
                              PDF
                            </td>
                            <td className=" text-xs text-[#11192a] px-4 py-2.5">
                              2021-09-14 13:25
                            </td>
                            <td className="cursor-pointer text-xs text-[#11192a] px-4 py-2.5">
                              <PAIconDownload />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </ViewFundInvoiceModal>
      )}

      <AnimatePresence>
        {acceptPaymentModal && (
          <ViewDocumentModal
            onAction={() => {
              setAcceptPaymentModal(false);
              setMakePaymentModal(false);
              setTransferModal(true);
            }}
            onClose={() => setAcceptPaymentModal(false)}
          >
            <span className=" text-center">
              Are you sure you would like to make payment for this invoice?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {transferModal && (
          <TransferFundsModal
            onAction={() => {
              setTransferModal(false);
              setPinModal(true);
            }}
            onClose={() => setTransferModal(false)}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        <EnterPin isOpen={pinModal} onClose={() => setPinModal(false)} />
      </AnimatePresence>

      {/* Export Invoice List */}
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Export
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </>
  );
};

export default DirectFunding;
