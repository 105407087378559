import React from "react";
import DashboardLayout from "layout/dashboardlayout";
import Detail from "./Detail";

const DirectFundingPageDetail = () => {
  return (
    <>
      <DashboardLayout
        section="Reimbursement"
        subHeader="Track invoices due for Reimbursement"
      >
        <Detail />
      </DashboardLayout>
    </>
  );
};

export default DirectFundingPageDetail;
