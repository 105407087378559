import { PAIconVirtualDebitCard } from "assets/images/svgs";
import ArrowIcon from "features/ToolsAgreement/components/Tools/ArrowIcon";
import React, { useState } from "react";
import RequestVirtualCardOption from "../modal";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import CongratsModal from "features/wallet/linkExternalAccount/congrats/congratsModal";

const RequestVirtualCard = () => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isVirtualCardOptionOpen, setVirtualCardOptionOpen] = useState(false);
  const [isStepOne, setStepOne] = useState(false);
  const [isStepTwo, setStepTwo] = useState(false);
  const [currency, setCurrency] = useState("NGN");
  const [congratsModal, setCongratsModal]  = useState(false);

  const handleVirtualCardOption = (account) => {
    setSelectedAccount(account);
    setVirtualCardOptionOpen(!isVirtualCardOptionOpen);
  };

  const handleClose = () => {
    setVirtualCardOptionOpen(false);
    setStepOne(false);
    setStepTwo(false);
    setCongratsModal(false);
  };

  const handleVirtualCardOptionSubmit = () => {
    setVirtualCardOptionOpen(false);
    setStepOne(true);
  };

  const handleStepOneSubmit = () => {
    setStepOne(false);
    setStepTwo(true);
  };

  const handleStepTwoSubmit = () => {
    setStepTwo(false);
    setCongratsModal(true);
  }

  return (
    <>
      <div
        className="bg-[#07593D] p-5 rounded cursor-pointer"
        onClick={handleVirtualCardOption}
      >
        <div className="flex flex-col items-center justify-center">
          <PAIconVirtualDebitCard />
          <p className="text-white mt-5">Request for a virtual debit card</p>
        </div>
        <div className="flex items-center justify-end text-white mb[-20px]">
          <ArrowIcon />
        </div>
      </div>
      <RequestVirtualCardOption
        isOpen={isVirtualCardOptionOpen}
        onClose={() => handleClose()}
        onSubmit={handleVirtualCardOptionSubmit}
        onSelectAccount={handleVirtualCardOption}
      />
      <StepOne
        isOpen={isStepOne}
        account={selectedAccount}
        onClose={() => handleClose()}
        onSubmit={(currency) => {
          setCurrency(currency);
          handleStepOneSubmit();
        }}
      />
      <StepTwo
        isOpen={isStepTwo}
        onClose={() => handleClose()}
        account={selectedAccount}
        currency={currency}
        onSubmit={handleStepTwoSubmit}
      />
       <CongratsModal
        isOpen={congratsModal}
        onClose={() => handleClose(false)}
        message="Your Virtual Debit Card has been created"
      />
    </>
  );
};

export default RequestVirtualCard;
