import { createNewTier, editTier } from "appstate/tier/tierSlice";
import { PAIconCancel } from "assets/images/svgs";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import InputField from "shared/InputField";
import Spinner from "shared/Spinner/Spinner";
import TierConfirmModal from "./TierConfirmationModal";
import ImageOne from "../../assets/images/svgs/no.svg";
import { createTierValidationSchema } from "validation/tier/createTierValidation";
import toast from "react-hot-toast";

const TierModalContents = ({
  onClose,
  fetchTiers,
  edit,
  tierId,
  isDetails,
}) => {
  const dispatch = useDispatch();
  const [newCriteria, setNewCriteria] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { tierDetails, isLoading } = useSelector((state) => state?.tier);

  const formik = useFormik({
    initialValues: {
      tierName: "",
      description: "",
      tierCriteria: [],
    },
    validationSchema: createTierValidationSchema,
    onSubmit: (values) => {
      setShowConfirmation(true);
    },
  });

  const handleInputChange = (e) => {
    setNewCriteria(e.target.value);
  };

  const handleAddCriteria = () => {
    if (newCriteria.trim() !== "") {
      formik.setFieldValue("tierCriteria", [
        ...formik.values.tierCriteria,
        newCriteria,
      ]);
      setNewCriteria("");
    }
  };

  const handleRemoveCriteria = (index) => {
    const updatedCriteria = formik.values.tierCriteria.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("tierCriteria", updatedCriteria);
  };

  const handleYesClick = () => {
    const body = {
      tierName: formik.values.tierName,
      tierDescription: formik.values.description,
      criteria: formik.values.tierCriteria,
    };
    if (edit) {
      dispatch(editTier({ tierId, body })).then((data) => {
        if (data?.payload?.status) {
          toast.success("Tier updated successfully");
          onClose();
          fetchTiers();
        }
      });
    } else {
      dispatch(createNewTier(body)).then((data) => {
        if (data?.payload?.status) {
          toast.success("Tier created successfully");
          onClose();
          fetchTiers();
        }
      });
    }

    setShowConfirmation(false);
    setShowSuccessModal(true);
  };

  useEffect(() => {
    if (tierDetails && isDetails) {
      formik.setValues({
        tierName: tierDetails?.data?.tierName || "",
        description: tierDetails?.data?.tierDescription || "",
        tierCriteria: tierDetails?.data?.tierCriteria || [],
      });
    }
     // eslint-disable-next-line
  }, [tierDetails]);

  return (
    <>
      {showSuccessModal && <img src={ImageOne} alt="" />}
      {!showConfirmation && !showSuccessModal && (
        <div className="px-[32px] py-[28px]">
          <header className="flex justify-between border-b-[1px] boder-[#f6f5fa] pb-[10px] mb-[32px]">
            <h1 className="text-textColor text-base italic font-extrabold">
              {isDetails ? "About Tier" : edit ? "Edit Tier" : "Tier Creation"}
            </h1>
            <div onClick={onClose} className="flex items-center cursor-pointer">
              <PAIconCancel className="w-[10px]" />
              <p className="text-sm font-medium ml-[3px]">Close</p>
            </div>
          </header>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-[16px]">
              <InputField
                labelFontSize
                label="Tier Name"
                type="text"
                id="tierName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tierName}
                placeholder="Enter name of tier"
                className="border  rounded-[8px] px-[16px] py-[12px]  outline-none"
              />
              {formik.touched.tierName && formik.errors.tierName ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.tierName}
                </p>
              ) : null}
            </div>

            <div className="mb-[16px]">
              <InputField
                labelFontSize
                label="Tier Description"
                placeholder="Enter description of tier"
                id="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                multiple
                value={formik.values.description}
                className="border  rounded-[8px] px-[16px] py-[12px]  outline-none"
              />
              {formik.touched.description && formik.errors.description ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.description}
                </p>
              ) : null}
            </div>

            <div>
              <InputField
                labelFontSize
                label="Tier Criteria"
                id="criteria"
                name="criteria"
                value={newCriteria}
                onChange={handleInputChange}
                placeholder="Enter tier criteria"
                className="border  rounded-[8px] px-[16px] py-[12px]  outline-none mb-[16px] "
              />
              {formik.touched.tierCriteria && formik.errors.tierCriteria ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.tierCriteria}
                </p>
              ) : null}
               {!isDetails && <div className="flex justify-end">
                <Button
                  onClick={handleAddCriteria}
                  type="button"
                  neutral
                  className="flex items-center border-2 border-[#F08000] rounded-lg px-3 py-2"
                >
                  {/* <img src={PAIconAdd} alt="" /> */}
                 <p className="text-textColor text-sm ml-[3px]">
                    + Add new criteria
                  </p>
                </Button>
              </div>}
              {formik.values.tierCriteria.length > 0 && (
                <p className="text-base font-semibold text-primaryColor mb-[30px]">
                  {`Criteria (${formik.values.tierCriteria.length})`}
                </p>
              )}
              {formik.values.tierCriteria.map((criterion, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center mb-[18px]"
                >
                  <p className="text-sm text-textColor">{criterion}</p>
                  <Button
                    neutral
                    onClick={() => handleRemoveCriteria(index)}
                    className="py-[8px] px-[16px] border-[1px] border-primaryColor !text-primaryColor text-xs rounded-[5px]"
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div>
            {!isDetails &&  <div className="flex justify-end gap-x-[10px] mt-[32px]">
              <Button
                type="button"
                neutral
                onClick={onClose}
                className="flex items-center rounded-[2px] border border-silver bg-[#f8f8f8] py-[8px] px-[16px]"
              >
                <p className="text-black font-medium text-sm ml-[3px]">
                  Cancel
                </p>
              </Button>

              {edit ? (
                <Button
                  type="submit"
                  neutral
                  disabled={!formik.isValid || !formik.dirty}
                  className="flex w-[73px] h-[40px] text-white font-medium text-sm ml-[3px] items-center rounded-[2px] border border-silver bg-primaryColor py-[8px] px-[16px]"
                >
                  {isLoading ? <Spinner /> : "Save"}
                </Button>
              ) : (
                <Button
                  type="submit"
                  neutral
                  disabled={!formik.isValid || !formik.dirty}
                  className="flex w-[73px] h-[40px] text-white font-medium text-sm ml-[3px] items-center rounded-[2px] border border-silver bg-primaryColor py-[8px] px-[16px]"
                >
                  {isLoading ? <Spinner /> : "Create"}
                </Button>
              )}
            </div>}
          </form>
        </div>
      )}
      {showConfirmation && (
        <TierConfirmModal
          onYes={handleYesClick}
          onNo={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default TierModalContents;
