import { useFormik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import Spinner from "shared/Spinner/Spinner";
import { virtualAccountValidationSchema } from "validation/wallet/virtualAccount";
import { useDispatch } from "react-redux";
import { setVirtualAccountFormData } from "appstate/wallet/walletSlice";

const CreateVirtualAccountModal = ({ isOpen, onClose, onPinOpen }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state?.wallet);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      bank: "",
      currency: "",
    },
    validationSchema: virtualAccountValidationSchema,

    onSubmit: (values, { setSubmitting }) => {
      //   console.log("Values being sent to Redux:", values);
      const action = setVirtualAccountFormData(values);
      //   console.log("Action being dispatched:", action);
      dispatch(action);
      setSubmitting(false);
      onPinOpen();
    },
  });

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
        <div className="bg-white p5 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5">
          <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
            <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
              Create Virtual Account
            </p>
            <button
              onClick={onClose}
              className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
            >
              &times; <span className="text-sm mx-2 mt-1">Close</span>
            </button>
          </div>

          {/* FORM */}
          <div>
            <form className="w-full px-7" onSubmit={formik.handleSubmit}>
              <p className="text-[#7A7A7A] font-sans text-sm font-normal mb-6">
                Step 1
              </p>
              <p className="mb-4 text-[#222] font-sans text-sm font-bold">
                Account Information
              </p>
              <div className="mb-4">
                <label className="text-[#222] font-sans text-sm font-normal mb-2">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.firstName}
                  </p>
                ) : null}
              </div>
              <div className="mb-4">
                <label className="text-[#222] text-sm font-normal mb-2">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.lastName}
                  </p>
                ) : null}
              </div>

              <div className="mb-4">
                <label className="text-[#222] text-sm font-normal mb-2">
                  Bank
                </label>
                <input
                  type="text"
                  id="bank"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bank}
                  className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
                />
                {formik.touched.bankName && formik.errors.bank ? (
                  <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.bank}
                  </p>
                ) : null}
              </div>

              <div className="mb-4">
                <label className="text-[#222] text-sm font-normal mb-2">
                  Currency
                </label>
                <select
                  id="currency"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currency}
                  className="border text-sm w-full py-2 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
                >
                  <option value="">Select Currency</option>
                  <option value="NGN">NGN</option>
                  <option value="USD">USD</option>
                </select>
                {formik.touched.currency && formik.errors.currency ? (
                  <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                    {formik.errors.currency}
                  </p>
                ) : null}
              </div>

              {/* Buttons */}
              <div className="flex justify-end mt-10">
                <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex mx-2">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-center text-gray text-base font-normal tracking-wide"
                  >
                    Cancel
                  </button>
                </div>
                <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]">
                  <button
                    className="text-center w-full text-white text-base font-normal tracking-wide"
                    disabled={isLoading ? isLoading : null}
                    type="submit"
                  >
                    {isLoading ? <Spinner /> : "Next"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateVirtualAccountModal;
