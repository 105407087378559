/* eslint-disable react-hooks/exhaustive-deps */
import { addBuyerBankDetails } from "appstate/auth/authSlice";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import Select from "shared/Select";
import Spinner from "shared/Spinner/Spinner";
import { countries } from "utilities/Countries";
import { currencies } from "utilities/Currencies";
import { addBuyerBankValidationSchema } from "validation/Addbank/AddBuyerBankSchema";
import OnboardingLayout from "../category/components/OnboardingLayout";
import { getBuyerById } from "appstate/api/auth/onboarding";

const AddAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, userDetails } = useSelector((state) => state.auth);

  const banks = [
    { id: "1", name: "Zenith Bank" },
    { id: "2", name: "GTBank" },
    { id: "3", name: "First Bank of Nigeria" },
    { id: "4", name: "Access Bank" },
    { id: "5", name: "United Bank for Africa" },
    { id: "6", name: "Fidelity Bank" },
    { id: "7", name: "Ecobank Nigeria" },
  ];

  // console.log("userDetails ", userDetails)

  const formik = useFormik({
    initialValues: {
      bankName: "",
      bankCountry: "",
      currency: "",
      accountNumber: "",
      accountHolder: "",
      bvn: "",
    },
    validationSchema: addBuyerBankValidationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      const body = {
        bankName: values.bankName,
        bankCountry: values.bankCountry,
        currency: values.currency,
        accountNumber: values.accountNumber,
        accountHolder: values.accountHolder,
        bvn: values.bvn,
      };

      try {
        const response = await dispatch(addBuyerBankDetails({ body }));
        if (response?.payload?.status === 200) {
          toast.success("Bank details successfully updated");
          navigate(-1);
        } else {
          toast.error("Failed to update bank details");
        }
      } catch (error) {
        console.error("Error updating bank details:", error);
        toast.error("An error occurred while updating bank details");
      }
    },
  });

  useEffect(() => {
    const fetchBuyerData = async () => {
      try {
        const buyerData = await getBuyerById({
          buyerId: userDetails?.data?.userId,
        });
        if (buyerData) {
          formik.setValues({
            bankName: buyerData?.data?.bankName || "",
            countryOfBank: buyerData?.data?.bankCountry || "",
            currency: buyerData?.data?.currency || "",
            accountHolder: buyerData?.data?.accountHolder || "",
            accountNumber: buyerData?.data?.accountNumber || "",
            bvn: buyerData?.data?.bvn,
          });
          // if(buyerData?.data?.bankName){
          //   setEditMode(true);
          // }
        }
      } catch (error) {
        toast.error(error?.response?.data?.message + "!");
      }
    };
    if (userDetails?.data?.userId) {
      fetchBuyerData();
    }
  }, [userDetails?.data?.userId]);

  useEffect(() => {
    const fetchAccountHolder = async (accountNumber) => {
      // Mock function to fetch account holder name
      const accountName = "Adewale Favour Adediwura"; // Replace with actual API call when backend provides an API
      formik.setFieldValue("accountHolder", accountName);
    };

    if (formik.values.accountNumber.length === 10) {
      fetchAccountHolder(formik.values.accountNumber);
    } else {
      formik.setFieldValue("accountHolder", "");
    }
  }, [formik.values.accountNumber]);

  const handleClick = () => {
    let path = -1;
    navigate(path);
  };

  return (
    <OnboardingLayout cancel={handleClick}>
      <div className="pt-[100px] grid place-content-center h-[100%] overflow-auto md:pt-0 lg:pt-[150px] xl:pt-0">
        <h1 className="font-semibold text-[28px] text-primaryColor text-center mb-[50px]">
          Add a bank account
        </h1>
        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center flex-col"
        >
          <div className="mb-[30px]">
            <Select
              label="Country of bank"
              datas={countries}
              id="bankCountry"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bankCountry}
            />
            {formik.touched.bankCountry && formik.errors.bankCountry ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.bankCountry}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <Select
              label="Bank Name"
              datas={banks}
              id="bankName"
              value={formik.values.bankName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.currency && formik.errors.currency ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.currency}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <Select
              label="Currency"
              datas={currencies}
              id="currency"
              value={formik.values.currency}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.currency && formik.errors.currency ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.currency}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px]  py-[13px] outline-none md:w-[424px]"
              placeholder=""
              label="Account Number"
              id="accountNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.accountNumber}
            />
            {formik.touched.accountNumber && formik.errors.accountNumber ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.accountNumber}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px]  py-[13px] outline-none  md:w-[424px]"
              placeholder="Account Name"
              disabled
              label="Account Name"
              id="accountHolder"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.accountHolder}
            />
            {formik.touched.accountHolder && formik.errors.accountHolder ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.accountHolder}
              </p>
            ) : null}
          </div>

          <Button
            disabled={isLoading || !formik.isValid}
            auth
            className="mb-[30px] w-full md:w-[424px]"
            type="submit"
          >
            {isLoading ? <Spinner /> : "Verify Account"}
          </Button>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default AddAccount;
