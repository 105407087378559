import { allPurchaseOrders, createPO } from "appstate/purchaseOrder/poSlice";
import Image from "features/inventory/components/Image";
import { useFormik } from "formik";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import LoadingScreen from "shared/LoadingScreen";
import { PurchaseOrderValidationSchema } from "validation/Po/index";

const CreatePO = () => {
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const { currentUserSuppliers } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const supplierData = currentUserSuppliers?.data?.find(
    (curr) => curr.userId === selectedSupplier
  );

  const { isLoading, selectedProducts } = useSelector(
    (state) => state?.purchaseOrder
  );

  const handleSelect = (e) => {
    localStorage.setItem("selectedSupplier", e.target.value);
    setSelectedSupplier(e.target.value);
  };

  useEffect(() => {
    const storedValue = localStorage.getItem("selectedSupplier");
    if (storedValue) {
      setSelectedSupplier(storedValue);
    }
  }, []);

  const CREATEPOTABLECOLUMN = [
    {
      name: "Image",
      selector: (row) => row?.imageUrl,
      cell: (row) => (
        <Image
          className="w-[51px] h-[34px] object-contain"
          src={row?.imageUrl[0]}
        />
      ),
      width: "5rem",
    },
    {
      name: "Product Description",
      selector: (row) => row?.productDescription,
    },
    {
      name: "Product Name",
      selector: (row) => row?.productName,
    },
    {
      name: "Qty",
      selector: (row) => row?.quantity,
    },
  ];

  function handleNavigate(e) {
    e.preventDefault();
    let path = "/purchase-order/createpo/selectproducts";
    let name = supplierData?.companyName;
    let supplierId = supplierData?.userId;
    navigate(path, { state: { name, supplierId } });
  }

  const initialValues = {
    selectedDate: "",
  };

  function addPurchaseOrder(values) {
    const products = selectedProducts.map((product) => ({
      productId: product.id,
      productName: product.productName,
      productDescription: product.productDescription,
      quantity: product.quantity,
    }));
    const body = {
      dueDate: values.selectedDate,
      items: products,
    };
    dispatch(createPO({ body: body, supplierId: selectedSupplier })).then(
      (data) => {
        if (data?.payload?.success) {
          toast.success("Purchase order created successfully");
          dispatch(allPurchaseOrders());
          let path = -1;
          navigate(path);
        }
      }
    );
  }

  const formik = useFormik({
    initialValues,
    validationSchema: PurchaseOrderValidationSchema,
    onSubmit: addPurchaseOrder,
  });

  const discard = (e) => {
    e.preventDefault();
    let path = -1;
    navigate(path);
  };

  return (
    <DashboardLayout>
      {isLoading && <LoadingScreen />}
      <div className="bg-white rounded p-[10px] md:p-[20px]">
        <form onSubmit={formik.handleSubmit}>
          <div className="md:flex w-full justify-between flex-wrap">
            <div className="flex flex-col">
              <label className="text-xs" htmlFor="">
                To
              </label>
              <select
                className="border border-silver bg-white h-[40px] outline-none rounded my-[10px] md:w-[250px] lg:w-[400px]"
                name=""
                id=""
                value={selectedSupplier}
                onChange={handleSelect}
              >
                <option disabled value="">
                  Select supplier
                </option>
                {currentUserSuppliers?.data?.map((supplier) => (
                  <option key={supplier?.userId} value={supplier?.userId}>
                    {supplier.fullName}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <InputField
                label="Payment Date"
                disabled={selectedProducts === null}
                type="date"
                id="selectedDate"
                name="selectedDate"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.selectedDate}
                className="border !border-silver rounded px-[5px] md:w-[250px] lg:w-[400px]"
              />
              {formik.touched.selectedDate && formik.errors.selectedDate ? (
                <p className="text-red-500 text-xs mt-[8px]">
                  {formik.errors.selectedDate}
                </p>
              ) : null}
            </div>
          </div>
          <Table
            columns={CREATEPOTABLECOLUMN}
            data={selectedProducts}
            selectableRows
            tableHeader
            className="mt-5"
          />
          <Button
            neutral
            disabled={!selectedSupplier}
            onClick={(e) => handleNavigate(e)}
            className="border border-silver mt-[32px] rounded-[5px] bg-brightGray text-sm font-medium text-black w-[140px] h-[41px]"
          >
            Add Products
          </Button>
          <div className="mt-[48px] flex justify-end gap-x-[24px]">
            <Button
              onClick={(e) => discard(e)}
              neutral
              className="border border-silver rounded-[5px] bg-brightGray text-sm font-medium text-black w-[100px] h-[41px]"
            >
              Discard
            </Button>
            <Button
              type="submit"
              neutral
              className="border border-silver rounded-[5px] bg-primaryColor text-white text-sm font-medium  w-[100px] h-[41px]"
            >
              Create
            </Button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default CreatePO;
