import request from "apiInstance";
import toast from "react-hot-toast";

export const createPfp = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/payable-finance/create",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updatePfpDetails = async ({ id, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/payable-finance/buyer/import-invoices?pfpID=${id}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBuyerPfp = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/buyer/view-all?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveExitRequest = async ({ id }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/payable-finance/exit-program/accept?notificationID=${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const declineExitRequest = async ({ id, note }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/payable-finance/exit-program/reject?notificationID=${id}&note=${note}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const invoiceImport = async ({ id, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/payable-finance/buyer/import-invoices?pfpID=${id}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const viewBuyerPfpById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/view/buyer-pfp?pfpID=${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBuyerPfpInvoices = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/payable-finance/buyer/pfp-invoices?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
