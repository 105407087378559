import React, { useState, useEffect } from "react";

import { motion } from "framer-motion";

const CheckMark = ({ onAction }) => {
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    // Simulate loading completion after 3.5 seconds
    const timer = setTimeout(() => {
      setIsComplete(true);
    }, 3500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (isComplete) {
      const actionTimer = setTimeout(() => {
        onAction();
      }, 700);

      return () => {
        clearTimeout(actionTimer);
      };
    }
  }, [isComplete, onAction]);

  return (
    <>
      <div className="flex-col gap-4 w-full flex items-center justify-center">
        <div
          className={`w-12 h-12 border-4 border-secondaryColor text-md flex items-center justify-center rounded-full ${
            isComplete
              ? "bg-secondaryColor"
              : "animate-spin border-gray-300 border-t-white"
          }`}
        >
          {isComplete && (
            <svg
              className="w-7 h-7 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </div>
      </div>

      {isComplete && (
        <motion.div
          initial={{ y: 10 }}
          animate={{ y: 0, transition: { duration: 0.2 } }}
          exit={{ y: -10, transition: { delay: 0.1 } }}
          className=" font-semibold text-[#5F4D61] text-center text-sm"
        >
          Account Verified
        </motion.div>
      )}
    </>
  );
};

export default CheckMark;
