import Support from "features/support";

const SupportPage = () => {
  return (
    <>
      <Support />
    </>
  );
};

export default SupportPage;
