import React, { useState } from "react";

import OngoingBidCard from "./card/OngoingBidCard";
import OngoingBidCancelSuccessOnBoardModal from "../modals/OngoingBidCancelSucessOnBoardModal";
import OngoingCancelOnBoardModal from "../modals/OngoingCancelOnBoardModal";

import Pagination from "shared/Pagination";

const OngoingBid = ({ closedMarketQuery }) => {
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);

  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
  };
  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    setOngoingBidCancelledSuccessModal(true);
  };
  const data = [
    {
      id: "1",
      invoiceNumber: "R3456X89Y",
      buyerName: "ABC Limited",
      invoiceType: "Single Invoice",

      lockRate: "Widget Delivery",
      statusOfInvoice: "Bid Active",
      requestAmount: "N7,940,000",
      dueDate: "2023-12-01",
      supplierName: "Coca Cola",
      earlyPaymentDate: "2023-11-01",
    },
    {
      id: "2",
      invoiceNumber: "R3456X89Y",
      buyerName: "Coca Cola",
      invoiceType: "Bulk Invoice",

      lockRate: "Widget Delivery",
      statusOfInvoice: "Bid Active",
      requestAmount: "N7,940,000",
      dueDate: "2023-12-01",
      supplierName: "Defi",
      earlyPaymentDate: "2023-11-01",
    },
    {
      id: "3",
      invoiceNumber: "R3456X89Y",
      buyerName: "Nestle Plc.",
      invoiceType: "Single Invoice",

      lockRate: "Widget Delivery",
      statusOfInvoice: "Posted",
      requestAmount: "N7,940,000",
      dueDate: "2023-12-01",
      supplierName: "Coca Cola",
      earlyPaymentDate: "2023-11-01",
    },
    {
      id: "4",
      invoiceNumber: "R3456X89Y",
      buyerName: "Coca Cola",
      invoiceType: "Single Invoice",

      lockRate: "Widget Delivery",
      statusOfInvoice: "Bid Active",
      requestAmount: "N7,940,000",
      dueDate: "2023-12-01",
      supplierName: "Nestle Plc.",
      earlyPaymentDate: "2023-11-01",
    },
    {
      id: "5",
      invoiceNumber: "R3456X89Y",
      buyerName: "Coca cola",
      invoiceType: "Single Invoice",

      lockRate: "Widget Delivery",
      statusOfInvoice: "Bid Active",
      requestAmount: "N7,940,000",
      dueDate: "2023-12-01",
      supplierName: "Breweries",
      earlyPaymentDate: "2023-11-01",
    },
  ];
  const totalPages = data.length;

  return (
    <>
      <OngoingCancelOnBoardModal
        isOpen={ongoingCancelBidModal}
        onClose={() => setOngoingCancelBidModal(false)}
        refNum={"R3456X89Y"}
        handleYes={handleOnCancelBidTrue}
      />
      <OngoingBidCancelSuccessOnBoardModal
        isOpen={ongoingBidCancelledSuccessModal}
        onClose={handleOnCancelSuccessClose}
        message={"Your bid has been cancelled"}
      />
      <div>
        <div className="flex flex-col  rounded-[10px] bg-unusualGray  px-6 mt-2">
          <header className="pb-4  flex flex-row gap-1  py-8 ">
            <div className="flex flex-row gap-2">
              <div class="text-black text-sm font-normal ">Filter bids:</div>

              <div>
                <select
                  className=" shadow-sm  text-sm focus:outline-none cursor-pointer h-[37px] px-5 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex md:min-w-[12.5rem]"
                  onChange={(e) => {}}
                >
                  <option value="">All Ongoing Bids</option>
                  <option value="date">Bids I'm currently leading</option>
                  <option value="name">Bids I'm not currently leading</option>
                </select>
              </div>

              {/*  */}
            </div>
            <div></div>
          </header>

          <div className="w-full  overflow-x-auto  ">
            <div className="flex flex-col gap-2.5 ">
              {data.map((bids, idx) => (
                <OngoingBidCard
                  key={idx}
                  id={bids.id}
                  invoiceNumber={bids.invoiceNumber}
                  buyerName={bids.buyerName}
                  invoiceType={bids.invoiceType}
                  lockRate={bids.lockRate}
                  statusOfInvoice={bids.statusOfInvoice}
                  requestAmount={bids.requestAmount}
                  dueDate={bids?.dueDate}
                  earlyPaymentDate={bids.earlyPaymentDate}
                  supplierName={bids.supplierName}
                  onCancel={() => setOngoingCancelBidModal(true)}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {totalPages > 2 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OngoingBid;
