import { PAIconArrowLeftGreen } from "assets/images/svgs";
import Headers from "features/invoice/buyerInvoice/headers";
import Table from "layout/table/Table";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import AcceptModal from "../../newSingleEarlyPayment/Details/acceptModal";
import RejectModal from "../../newSingleEarlyPayment/Details/rejectModal";

const Details = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const navigate = useNavigate()

  const handleAccept = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRejectModalOpen(false);
  };

  const handleReject = () => {
    setRejectModalOpen(true);
  };

  const invoice = () => {
    let path = "invoice";
    navigate(path);
  };

  const NewBulkEarlyPaymentList = [
    {
      id: "1",
      invoiceNumber: "INV2022001",
      buyerCompanyName: "ABC Company",
      supplier: "John Doe",
      invoiceDueDate: "2024-01-31",
      invoiceDate: "2024-01-01",
      newRequestedDate: "2024-01-15",
      currency: "USD",
      invoiceAmount: "$9,000.00",
      status: "PENDING",
      tier: "Tier 1",
      discountRate: "5%",
      reference: "E-PR-20220222-OTRRDHY12I",
      discountAmount: "$450.00",
    },
    {
      id: "2",
      invoiceNumber: "INV2022002",
      buyerCompanyName: "XYZ Inc.",
      supplier: "Jane Smith",
      invoiceDueDate: "2024-02-04",
      invoiceDate: "2024-01-05",
      newRequestedDate: "2024-01-20",
      currency: "USD",
      invoiceAmount: "$4,000.00",
      status: "Updated request",
      tier: "Tier 2",
      discountRate: "3%",
      reference: "E-PR-20220221-3INUPDJXL1",
      discountAmount: "$120.00",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref.No.
        </span>
      ),
      selector: (row) => row.reference,
      sortable: true,
      width: "140px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row.supplierCompanyName,
      sortable: true,
      width: "100px",
    },
    {
      name: "Request Date",
      selector: (row) => row.newRequestedDate,
      sortable: true,
      width: "120px",
    },
    {
      name: "No. of invoices",
      selector: (row) => row.numberOfInvoices,
      sortable: true,
      width: "110px",
    },
    {
      name: "Tier",
      selector: (row) => row.tier,
      sortable: true,
      width: "70px",
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.invoiceAmount}</p>,
      width: "130px",
    },
    {
      name: "Discount Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-yellow-400">{row.discountAmount}</p>,
      width: "120px",
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
      sortable: true,
      cell: (row) => <p className="text-success">{row.discountRate}</p>,
      width: "150px",
    },
  ];

  return (
    <div>
      <header className="rounded-md w-full bg-white mb-[17px] cursor-pointer items-center">
        <Link
          to="/invoice/invoice"
          className="flex items-center justifycenter gap-x-[3px] mb-[20px] cursor-pointer py-3 ml-2 my-auto"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>
      </header>
      <Headers />
      <div className="my-3">
        <div className="p-3 px-4 border border-[#F08000] border-x-[1px] border-t-[0.2px] border-b-0 bg-white rounded-md">
          <h2 className="text-[#F08000] font-bold my-2">
            Reason for Supplier’s Early Payment Request.
          </h2>
          <p className="text-sm text-[#959595] mb-2">
            A common reason for a buyer to request a payment extension could be
            unforeseen financial challenges or cash flow constraints. Delays in
            receiving funds, unexpected expenses, or fluctuations in business
            income might necessitate additional time for the buyer to fulfill
            the payment obligation.
          </p>
        </div>
      </div>
      {/* Table */}
      {!NewBulkEarlyPaymentList.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Approved Early Payment Request
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[90px] rounded-t-[10px] mt-3">
            <div className="py-5">
              <div className="text-black text-md font-bold mt5">
                XYZ Ventures -{" "}
                <span className="text-secondaryColor">(Supplier)</span>
              </div>

              <div className="text-xs font-thin flex gap-8 mt-1">
                <span className="text-[#07A04A]">
                  Request ref no. : R343t3xy
                </span>
                <span className="text-gray300">
                  2 invoices-
                  <span className="text-[#07593D] font-bold">
                    (Approved Bulk Requests)
                  </span>
                </span>
              </div>

              <div className="text-xs font-thin flex gap-8 mt-1 mb5">
                <span className="text-gray300">
                  Total invoice amount-
                  <span className="text-[#07593D] font-bold">NGN 83,000</span>
                </span>
                <span className="text-gray300">
                  Total request amount-
                  <span className="text-[#07593D] font-bold">NGN 22,000</span>
                </span>
              </div>
            </div>
            <div className="flex items-center my-5 gap-5 mt-5">
              <button
                className="rounded-md bg-[#07593D] text-white px-12 py-2"
                onClick={handleAccept}
              >
                Accept
              </button>
              <button
                className="rounded-md bg-white border border-[#F08000] text-[#F08000] px-12 py-2"
                onClick={handleReject}
              >
                Reject
              </button>
            </div>
          </div>
          <div className="pb-[24px]">
            <Table
              columns={columns}
              data={NewBulkEarlyPaymentList}
              onRowClicked={(row) => invoice(row.id)}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
          <AcceptModal isOpen={isModalOpen} onClose={handleCloseModal} />
          <RejectModal isOpen={isRejectModalOpen} onClose={handleCloseModal} />
        </Card>
      )}
    </div>
  );
};

export default Details;
