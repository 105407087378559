import {
  PAIconArrowLeftGreen,
  PAIconBasilOutline,
  PAIconCalendar,
  PAIconOutlineFunds,
  PAIconSolarWalletOutline,
  PAIconLockOutline,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import PortfolioMangementTable from "./table";

const PortfolioManagement = () => {
  const [showMonthListTotalInvoices, setShowMonthListTotalInvoices] =
    useState(false);
  const [showMonthListInvestmentAmount, setShowMonthListInvestmentAmount] =
    useState(false);
  const [selectedTotalInvoicesMonth, setSelectedTotalInvoicesMonth] = useState("select a month");
  const [selectedListInvestmentAmountMonth, setSelectedListInvestmentAmountMonth] = useState("select a month");


  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleMonthClickTotalInvoices = (month) => {
    setSelectedTotalInvoicesMonth(month);
    setShowMonthListTotalInvoices(false);
  };

  const handleMonthClickInvestmentAmount = (month) => {
    setSelectedListInvestmentAmountMonth(month);
    setShowMonthListInvestmentAmount(false);
  };

  return (
    <DashboardLayout
      section="Portfolio Dashboard"
      subHeader="Here is an overview of your invoices"
    >
      <header className="rounded-md w-full bg-white mb-4 cursor-pointer flex items-center px-4">
        <Link
          to="/funds&portfolio"
          className="flex items-center gap-1 py-3 ml-2"
        >
          <PAIconArrowLeftGreen />
          <span className="text-primaryColor">Go Back</span>
        </Link>
      </header>

      <div className="rounded-lg bg-white shadow-md">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 py-3 px-5 gap-3">
          {/* TOTAL INVOICES */}
          <div className="my-2 rounded-md bg-[rgba(172,131,84,0.20)] p-3">
            <div className="flex justify-between items-center">
              <PAIconOutlineFunds />
              <span className="ml-2 cursor-pointer">
                <PAIconBasilOutline />
              </span>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex-1 p-2">
                <h5 className="text-[12px] font-medium py-1">
                  Total Funded Invoices
                </h5>
                <h5 className="text-[40px] font-bold leading-normal">
                  235 <span className="text-[10px]">invoices</span>
                </h5>
              </div>

              <div className="flex-1 bg-[#AC8354] rounded-md text-white p-3 ml-2 relative">
                <h5 className="text-[10px] font-bold">Funded Invoices</h5>
                <div className="flex justify-between items-center">
                  <p className="text-[10px] underline">{selectedTotalInvoicesMonth}</p>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      setShowMonthListTotalInvoices(!showMonthListTotalInvoices)
                    }
                  >
                    <PAIconCalendar />
                  </div>
                </div>
                <h5 className="text-[40px] font-bold leading-normal">
                  97 <span className="text-[10px]">invoices</span>
                </h5>
                {showMonthListTotalInvoices && (
                  <div className="absolute top-full left-0 mt-2 bg-[#AC8354] text-white rounded shadow-lg z-10 grid grid-cols-2 gap-2 p4">
                    {months.map((month) => (
                      <div
                        key={month}
                        className="px-4 py-2 hover:bg-stone-300 hover:text-[#AC8354] shadow-md rounded cursor-pointer transition-colors duration-200"
                        onClick={() => handleMonthClickTotalInvoices(month)}
                      >
                        {month}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* INVESTMENT AMOUNT */}
          <div className="my-2 rounded-md bg-[#4B4EAC33] p-3">
            <div className="flex justify-between items-center">
              <PAIconSolarWalletOutline />
              <span className="ml-2 cursor-pointer">
                <PAIconBasilOutline />
              </span>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex-1 p-2">
                <h5 className="text-[12px] font-medium py-1">
                  Total Investment Amount
                </h5>
                <h5 className="text-[26.928px] font-bold leading-normal">
                  2,345,800
                </h5>
              </div>

              <div className="flex-1 bg-[#4B4EAC] rounded-md text-white p-3 ml-2 relative">
                <h5 className="text-[10px] font-bold">Funded Invoices</h5>
                <div className="flex justify-between items-center">
                  <p className="text-[10px] underline">{selectedListInvestmentAmountMonth}</p>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      setShowMonthListInvestmentAmount(
                        !showMonthListInvestmentAmount
                      )
                    }
                  >
                    <PAIconCalendar />
                  </div>
                </div>
                <h5 className="text-[23.113px] my-3 font-bold leading-normal">
                  451,000
                </h5>
                {showMonthListInvestmentAmount && (
                  <div className="absolute top-full left-0 mt-2 bg-[#4B4EAC]  text-white rounded shadow-lg z-10 grid grid-cols-2 gap-2 p4">
                    {months.map((month) => (
                      <div
                        key={month}
                        className="px-4 py-2 hover:bg-stone-300 hover:text-[#4B4EAC] shadow-md rounded cursor-pointer transition-colors duration-200"
                        onClick={() => handleMonthClickInvestmentAmount(month)}
                      >
                        {month}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* TRANSACTIONS */}
          <div className="my-2 rounded-md bg-[#1CAE5F33] p-3">
            <div className="flex justify-between items-center">
              <PAIconLockOutline />
              <span className="ml-2 cursor-pointer">
                <PAIconBasilOutline />
              </span>
            </div>
            <h5 className="text-sm py-2">Transactions</h5>
            <h5 className="text-[40px] font-bold leading-normal">
              147 <span className="text-[10px]">Payment processes</span>
            </h5>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <PortfolioMangementTable />
      </div>
    </DashboardLayout>
  );
};

export default PortfolioManagement;
