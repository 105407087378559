import request from "apiInstance";
import toast from "react-hot-toast";

export const getAllearlyPayments = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/buyer/invoices?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllNewEarlyPaymentRequest = async ({
  page,
  pageSize,
  bulk,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/early-payments?page=${page}&size=${pageSize}&bulk=${bulk}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const earlyPaymentApproveOrDecline = async ({ id, comment, status }) => {
  try {
    const response = await request({
      method: "put",
      url: `/fund-request/approve-early-payment/${id}?comment=${comment}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
