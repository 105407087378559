// VirtualCard.js
import React from "react";
import {  PAIconPayassyst2 } from "assets/images/svgs";

const VirtualCard = ({ name, date, cardNumber, cvv }) => {
  return (
    <div className="w-[312px] h-[179px] px-[17px] py-4 rounded-[10px] bg-[#07593D] ">
      <div className="flex justify-between items-center">
        <section className="flex flex-col items-start justify-between gap-10 ">
          <PAIconPayassyst2 />
          <div className="text-white text-sm font-extrabold">{cardNumber}</div>
          <div className="text-white text-sm font-extrabold mt4">{name}</div>
        </section>
        <section className="flex flex-col items-center justify-between gap-10 ">
          <div className="text-white text-xs font-extrabold">{date}</div>
          <div className="text-white text-sm font-extrabold mt4">{cvv}</div>
          {/* <PAIconMasterCardLogo /> */}
        </section>
      </div>
    </div>
  );
};

export default VirtualCard;
