import { AnimatePresence } from "framer-motion";
import { useState } from "react";

import OverView from "./overview";
import FunderClosedMarketHeader from "./FunderClosedMarketHeader";
import OngoingBid from "./ongoingBid";
import WonBid from "./wonBid";
import ClosedMarket from "./closedMarket";

import { useSelector } from "react-redux";

function DashboardContents() {
  const [acceptedQuery, setAcceptedQuery] = useState("");
  const [overviewQuery, setOverviewQuery] = useState("");
  const [openMarketQuery, setOpenMarketQuery] = useState("");
  const [pendingQuery, setPendingQuery] = useState("");

  const { closeMarketactiveTab } = useSelector((state) => state?.funder);

  return (
    <div className="flex flex-col gap-8">
      <>
        <FunderClosedMarketHeader
          closeMarketactiveTab={closeMarketactiveTab}
          searchQuery={
            closeMarketactiveTab === 1
              ? overviewQuery
              : closeMarketactiveTab === 2
              ? openMarketQuery
              : closeMarketactiveTab === 3
              ? pendingQuery
              : acceptedQuery
          }
          setSearchQuery={
            closeMarketactiveTab === 1
              ? setOverviewQuery
              : closeMarketactiveTab === 2
              ? setOpenMarketQuery
              : closeMarketactiveTab === 3
              ? setPendingQuery
              : setAcceptedQuery
          }
        />

        <main className="w-full">
          <AnimatePresence mode="wait">
            {closeMarketactiveTab === 1 ? (
              <OverView />
            ) : closeMarketactiveTab === 2 ? (
              <ClosedMarket closedMarketQuery={openMarketQuery} />
            ) : closeMarketactiveTab === 3 ? (
              // <PendingBidRequest pendingQuery={pendingQuery} />
              <OngoingBid ongoingBidQuery={pendingQuery} />
            ) : closeMarketactiveTab === 4 ? (
              <WonBid WonBidQuery={acceptedQuery} />
            ) : (
              <></>
            )}
          </AnimatePresence>
        </main>
      </>
    </div>
  );
}

export default DashboardContents;
