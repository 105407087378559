import BuyerTabs from "features/BuyerEarlyPayment/Tabs";
// import AllPayableFinance from "features/payableFinancing";
import DashboardLayout from "layout/dashboardlayout";
import React from "react";

const PayableFinancingDashboard = () => {
  return (
    <DashboardLayout>
      {/* <AllPayableFinance /> */}
      <BuyerTabs />
    </DashboardLayout>
  );
};

export default PayableFinancingDashboard;
