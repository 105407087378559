import Table from "layout/table/Table";
import React from "react";
import Button from "shared/Button";

function Preview({ handlePreviousPage }) {
  const column = [
    {
      name: "Invoice Reference",
      selector: (row) => row?.invoiceReference,
    },
    {
      name: "Supplier",
      selector: (row) => row?.supplier,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
    },
    {
      name: "Invoiced Amount",
      selector: (row) => row?.invoiceAmount,
    },
    {
      name: "Discounted Amt",
      selector: (row) => row?.discountedAmount,
    },
    {
      name: "Payable Amt",
      selector: (row) => row?.payableAmount,
    },
  ];
  const data = [
    {
      invoiceReference: "INV-2090",
      supplier: "NNPC",
      currency: "NGN",
      dueDate: "2023-05-31",
      paymentDate: "2023-01-20",
      invoiceAmount: "9,000,000",
      discountedAmount: "180,000",
      payableAmount: "8,820,000",
    },
    {
      invoiceReference: "INV-2090",
      supplier: "NNPC",
      currency: "NGN",
      dueDate: "2023-05-31",
      paymentDate: "2023-01-20",
      invoiceAmount: "9,000,000",
      discountedAmount: "180,000",
      payableAmount: "8,820,000",
    },
    {
      invoiceReference: "INV-2090",
      supplier: "NNPC",
      currency: "NGN",
      dueDate: "2023-05-31",
      paymentDate: "2023-01-20",
      invoiceAmount: "9,000,000",
      discountedAmount: "180,000",
      payableAmount: "8,820,000",
    },
    {
      invoiceReference: "INV-2090",
      supplier: "NNPC",
      currency: "NGN",
      dueDate: "2023-05-31",
      paymentDate: "2023-01-20",
      invoiceAmount: "9,000,000",
      discountedAmount: "180,000",
      payableAmount: "8,820,000",
    },
    {
      invoiceReference: "INV-2090",
      supplier: "NNPC",
      currency: "NGN",
      dueDate: "2023-05-31",
      paymentDate: "2023-01-20",
      invoiceAmount: "9,000,000",
      discountedAmount: "180,000",
      payableAmount: "8,820,000",
    },
  ];
  return (
    <div className="mx-[3.5rem]">
      <div className="flex gap-x-[20rem]">
        <div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Name of Program</p>
            <p className="text-textColor text-sm">Payable Flex</p>
          </div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Financing Option</p>
            <p className="text-textColor text-sm">Reverse Factoring</p>
          </div>
          <div>
            <p className="text-lightGray text-sm">Duration </p>
            <p className="text-textColor text-sm">
              <span className="text-[#BFBFBF] font-bold">From:</span> 11-02-2024{" "}
              <span className="text-[#BFBFBF] font-bold ml-[5px]">To:</span>{" "}
              11-02-2024
            </p>
          </div>
        </div>

        <div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Amount Range</p>
            <p className="text-textColor text-sm">Min: NGN 100,100.00</p>
            <p className="text-textColor text-sm">Max: NGN 100,100.00</p>
          </div>
          <div className="mb-[16px]">
            <p className="text-lightGray text-sm">Discount Rate</p>
            <p className="text-textColor text-sm">2%</p>
          </div>
          <div>
            <p className="text-lightGray text-sm">Payment Period</p>
            <p className="text-textColor text-sm">40 Days</p>
          </div>
        </div>
      </div>
      <div className="flex gap-x-[5px] items-center mt-[32px]">
        <p className="text-textColor text-sm mt-[8px]">
          Select invoices for the campaign
        </p>
        <div className="bg-[#C9D9D3] py-[4px] px-[6px] rounded-[100%] text-textColor text-xs flex justify-center items-center relative top-[5px]">
          20
        </div>
      </div>
      <div className="border border-brightGray rounded-[0.5rem] px-[1.69rem] pb-[1.69rem] mt-[8px]">
        <Table
          columns={column}
          data={data}
          pointer
          tableHeader
          className="mt-5"
        />
      </div>
      <div className="flex gap-x-[2.5rem] mt-[1.75rem]">
        <div className="flex gap-x-[5px] items-center">
          <p className="text-lightGray text-[0.875rem]">
            Total Discounted Amount:
          </p>
          <h1 className="text-primaryColor text-[1.125rem] font-bold">
            NGN 1,080,000
          </h1>
        </div>
        <div className="flex gap-x-[5px] items-center">
          <p className="text-lightGray text-[0.875rem]">
            Total Payable Amount:
          </p>
          <h1 className="text-primaryColor text-[1.125rem] font-bold">
            NGN 52,920,000
          </h1>
        </div>
      </div>
      <div className="flex justify-between mt-[1.88rem]">
        <Button
          neutral
          className=" py-[0.75rem] px-[1.5rem] !text-lightGray !text-[0.875rem] bg-alabasterHeader rounded-[0.25rem]"
          onClick={handlePreviousPage}
        >
          Previous
        </Button>

        <Button
          neutral
          className=" py-[0.75rem] px-[1.5rem] !text-white !text-[0.875rem] bg-primaryColor rounded-[0.25rem]"
          //   onClick={handlePreviousPage}
        >
          Create
        </Button>
      </div>
    </div>
  );
}

export default Preview;
