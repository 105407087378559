import request from "apiInstance";
import toast from "react-hot-toast";

export const funderTransactions = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/funders/transactions?page&size&status",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const funderOpenMarket = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/funders/market?page&size&filter",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllFunders = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const searchFunder = async ({ companyName }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/funders/search?fullName=${companyName}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};