import React, { useEffect } from "react";
import RecentPaymentTable from "./Table";
import { supplierOverview } from "appstate/collections/collectionSlice";
import { useDispatch, useSelector } from "react-redux";

const Overview = () => {
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const { supplierOverviewData } = useSelector((state) => state?.collection);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(supplierOverview());
  }, [dispatch]);

  const sections = [
    {
      title: "Open Market",
      titleColor: "#07593D",
      borderColor: "#07593D",
      selectColor: "#07593D",
      data: [
        {
          label: "Total Due Invoices",
          value: supplierOverviewData?.openMarketDueInvoices || 0,
        },
        {
          label: "Total Due Amount",
          value:
            formatAmount(supplierOverviewData?.openMarketDueAmount) || "0.00",
        },
      ],
    },
    {
      title: "Direct Funding",
      titleColor: "#F08000",
      borderColor: "#F08000",
      selectColor: "#F08000",
      data: [
        {
          label: "Total Due Invoices",
          value: supplierOverviewData?.directFundingDueInvoices || 0,
        },
        {
          label: "Total Due Amount",
          value:
            formatAmount(supplierOverviewData?.directFundingDueAmount) ||
            "0.00",
        },
      ],
    },
    {
      title: "Early Payment Requests",
      titleColor: "#F08000",
      borderColor: "#F08000",
      selectColor: "#F08000",
      data: [
        {
          label: "Total Due Invoices",
          value: supplierOverviewData?.earlyPaymentDueInvoices || 0,
        },
        {
          label: "Total Due Amount",
          value:
            formatAmount(supplierOverviewData?.earlyPaymentDueAmount) || "0.00",
        },
      ],
    },
    {
      title: "Early Payment Offers",
      titleColor: "#07593D",
      borderColor: "#07593D",
      selectColor: "#07593D",
      data: [
        {
          label: "Total Due Invoices",
          value: "10" /*supplierOverviewData?.receivablesDueInvoices || 0 */,
        },
        {
          label: "Total Due Amount",
          value: "50,000,000",
          /*formatAmount(supplierOverviewData?.receivablesDueAmount) || "0.00" */
        },
      ],
    },
  ];

  return (
    <div className="w-full ">
      <div className="w-full rounded-md mt-2 bg-white shadow-lg">
        <header className="mt-3 border-b-2 border-[#AEAEAE] flex-shrink-0 bg-white px-7 py-3 rounded-t-md">
          <h2 className="text-[#3E3E3E] font-bold text-[16px]">
            Summary of pending invoices for collection from diverse channels.
          </h2>
        </header>
        <div className="bg-[#EBEBEB]">
          <div className="px-7 py-4 grid md:grid-cols-[35%,64%] gap-5">
            {/* First Grid Section */}
            <section>
              <div className="grid lg:grid-cols-2 gap-2">
                <div className="flex flex-col items-center justify-center mb-5 rounded-bl-lg rounded-br-lg rounded-tr-lg text-white shadow-sm gap3">
                  {/* Top Section: Due Invoices */}
                  <div className="p-2 bg-[#07593D] flex flex-col items-center justify-center rounded-t-lg w-full">
                    <h2 className="text-md font-semibold">
                      {formatAmount(supplierOverviewData?.totalDueInvoices) ||
                        "0.00"}
                    </h2>
                    <h2 className="font-normal text-md">Due Invoices</h2>
                  </div>

                  {/* Bottom Section: Total Due Amount */}
                  <div className="p-2 bg-secondaryColor flex flex-col items-center justify-center rounded-b-lg w-full">
                    <h2 className="font-bold md:text-md lg:text-md flex items-center">
                      <span>N </span>
                      {formatAmount(supplierOverviewData?.totalDueAmount) ||
                        "0.00"}
                    </h2>
                    <h2 className="text-xs font-normal">Total Due Amount</h2>
                  </div>
                </div>

                <div className="p-3 bg-[#2FA06A] flex flex-col mb-5 rounded-lg text-white shadow-sm gap-1">
                  <h2 className="font-bold text-xs md:text-base flex items-center justify-center">
                    <span>N </span>
                    {formatAmount(supplierOverviewData?.totalAmountCollected) ||
                      "0.00"}
                  </h2>
                  <h2 className="text-xs font-normal my-2">
                    Total Amount Collected
                  </h2>
                  <div className="flex justify-end items-center">
                    <select className=" border border-white bg-white text-[#07593D] rounded cursor-pointer w-11">
                      <option value="all">All</option>
                      <option value="1week">1 week</option>
                      <option value="1month">1 month</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="halfyear">Half a year</option>
                      <option value="1year">1 year</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Recievables */}
              <div className="bg-[#07593D] rounded-b-md border-t-2 border-[#2FA06A] px-[20px] py-2">
                <div className="flex item-center justify-between gap10">
                  <p className={`text-white font-bold text-base`}>
                    Recievables
                  </p>
                  <select
                    className={`border border-[#07593D] bg-white text-[#07593D] rounded px2 cursor-pointer w-11`}
                  >
                    <option value="all">All</option>
                    <option value="1week">1 week</option>
                    <option value="1month">1 month</option>
                    <option value="quarterly">Quarterly</option>
                    <option value="halfyear">Half a year</option>
                    <option value="1year">1 year</option>
                  </select>
                </div>
                <div className="flex justify-between gap28 mt-4 text-white">
                  <div className="flex-row items-center justify-end">
                    <h2 className="flex items-center justify-center font-bold text-lg">
                      {supplierOverviewData?.receivablesDueInvoices || 0}
                    </h2>
                    <h2 className="flex items-center justify-center font-normal text-xs mt-3">
                      Due Invoice
                    </h2>
                  </div>
                  <div className="flex-row items-center justify-end">
                    <h2 className="flex items-center justify-center font-bold text-lg">
                      {formatAmount(
                        supplierOverviewData?.receivablesDueAmount
                      ) || "0.00"}
                    </h2>
                    <h2 className="flex items-center justify-center font-normal text-xs mt-3">
                      Total Amount
                    </h2>
                  </div>
                </div>
              </div>
            </section>

            <section className="grid lg:grid-cols-2 gap-5">
              {sections.map((section, index) => (
                <div
                  key={index}
                  className={`fle justifybetween px-[20px] py-2 itemscenter bg-white rounded-b-md border-t-2`}
                  style={{ borderColor: section.borderColor }}
                >
                  <div className="flex item-center justify-between gap10">
                    <p
                      className={`text-[${section.titleColor}] font-bold text-base`}
                    >
                      {section.title}
                    </p>
                    <select
                      className={`border border-[${section.selectColor}] bg-[${section.selectColor}] text-white rounded px2 cursor-pointer w-11`}
                    >
                      <option value="all">All</option>
                      <option value="1week">1 week</option>
                      <option value="1month">1 month</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="halfyear">Half a year</option>
                      <option value="1year">1 year</option>
                    </select>
                  </div>
                  <div className="flex justify-between gap28 mt-4">
                    {section.data.map((item, idx) => (
                      <div
                        key={idx}
                        className="flex-row items-center justify-end"
                      >
                        <h2
                          className={`text-[${
                            item.label === "Total Due Invoices"
                              ? "#07593D"
                              : "#F08000"
                          }] flex items-center justify-center font-bold text-xl`}
                        >
                          {item.value}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-xs mt-3">
                          {item.label}
                        </h2>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </section>
          </div>
        </div>
      </div>
      <div>
        <RecentPaymentTable />
      </div>
    </div>
  );
};

export default Overview;
