// buttonSlice.js

import { createSlice } from "@reduxjs/toolkit";

export const buttonSlice = createSlice({
  name: "button",
  initialState: {
    buttonClicked: false,
  },
  reducers: {
    toggleCancelButtonOn: (state) => {
      state.buttonClicked = true;
    },
    toggleCancelButtonOff: (state) => {
      state.buttonClicked = false;
    },
  },
});

export const { toggleCancelButtonOn, toggleCancelButtonOff } =
  buttonSlice.actions;

export default buttonSlice.reducer;
