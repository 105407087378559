import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import AccountModalContainer from "shared/accountModal/Modal";
import { getInvoiceForApprove } from "appstate/invoice/invoiceSlice";
import { allEarlyPayments } from "appstate/buyer/buyerSlice";
import { Link, useNavigate } from "react-router-dom";
import {
  PAIconArrowLeftGreen,
  PAIconMDISuccess,
  PAIconIconParkSucess,
} from "assets/images/svgs";

import toast from "react-hot-toast";
import DashboardLayout from "layout/dashboardlayout";

import ImportInvoicesModal from "../components/carddetails/importInvoicesModal";
import AddFundModal from "../components/carddetails/AddFundModal";

function DirectEarlyPaymentRequests() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [rowActive, setRowActive] = useState(false);
  const [rowTotal, setRowTotal] = useState([]);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [addFundModal, setaddFundModal] = useState(false);

  const pageSize = 10;
  const { allEarlyPAymentsData, isLoading } = useSelector(
    (state) => state?.buyer
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allEarlyPayments({ page, pageSize }));
  }, [dispatch, page, pageSize]);
  useEffect(() => {}, [allEarlyPAymentsData]);

  const dumdata = [
    {
      id: 0,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 1,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 2,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 3,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 4,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 5,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 6,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 7,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 8,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 9,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 10,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 11,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 12,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 13,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 14,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
    {
      id: 15,
      invoiceRef: "INV-2090",
      supplier: "Agba Dev",
      invoiceDate: "2023-05-23",
      paymentDate: "2023-05-31",
      currency: "NGN",
      amount: "+9,000,000",
      status: "Approved",
      view: "view",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Reference
        </span>
      ),
      selector: (row) => row.invoiceRef,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row.supplier,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      cell: (row) => <p className="text-success">{row.amount}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => <p className="text-success">{row.status}</p>,
    },
    {
      name: "",
      selector: (row) => row.view,
      cell: (row) => <p className="text-yellow-400">{row.view}</p>,
    },
  ];

  const totalPages = dumdata.length;

  const handleCloseModal = () => {
    setRejectModalOpen(false);
  };

  const handleImport = (e) => {
    setRejectModalOpen(true);
    e.preventDefault();
  };
  const handleImportInvoice = () => {
    setRejectModalOpen(false);
    setaddFundModal(true);
  };

  function handleProceedToImport() {
    setaddFundModal(false);
    toast.custom(
      <div className="flex mt-[200px]">
        <div className="bg-primaryColor w-[147.791px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
          <PAIconMDISuccess />
        </div>
        <div className="bg-white flex flex-col justify-center items-center w-[394.538px] h-[160px] rounded-r-[7px] shadow-lg">
          <PAIconIconParkSucess />
          <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
            Import successful
          </p>
        </div>
      </div>
    );
  }
  const handleRowSelect = (row) => {
    const checkedRows = row.selectedRows.map((curr) => curr.id);
    if (checkedRows) {
      setRowActive(true);
    }

    setRowTotal(checkedRows);

    dispatch(getInvoiceForApprove(checkedRows.toString()));
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const singleDetail = () => {
    navigate("/closedmarket/directEarlyPaymentRequestsDetail");
  };
  useEffect(() => {
    if (rowTotal.length > 0) {
      setRowActive(true);
    } else {
      setRowActive(false);
    }
  }, [rowTotal, rowTotal.length]);

  return (
    <>
      <DashboardLayout
        section={"Closed Market"}
        subHeader={"Manage your invoices on the closed market"}
      >
        <Link
          onClick={goBack}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full bg-white px-3 py-5 rounded-[8px]"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>
        <ImportInvoicesModal
          isOpen={isRejectModalOpen}
          onClose={handleCloseModal}
          handleImportInvoice={handleImportInvoice}
        />
        <AddFundModal
          isShown={addFundModal}
          onClose={() => setaddFundModal(false)}
          handleProceed={handleProceedToImport}
        />

        <AccountModalContainer
          accontModal
          isShown={open}
          onClose={() => {
            setOpen(false);
            setSuccess(false);
          }}
          success={success}
          warning="Are you sure you would like to approve this/these invoice upload?"
        />
        <Card className="rounded-lg">
          <Card className="  rounded-tl-[10px]  rounded-tr-[10px] p-[0.5px] ">
            <div className=" px-[24px]  rounded-tl-[10px]  rounded-tr-[10px]  ">
              <div className="my-2 grid md:grid-cols-2 items-center">
                <div className=" flex flex-col relative">
                  <div className="  flex ">
                    <div className="text-zinc-900 text-sm font-bold ">
                      Direct Early Payment Requests
                    </div>
                  </div>

                  <div class="text-zinc-600 text-[10px] font-normal font-['SF Pro Display']">
                    All available invoices from direct early payment requests
                  </div>
                </div>

                <div className="flex md:col-span-1 justify-end">
                  <button
                    class={` h-[37px] px-[15px] py-2.5 ${
                      rowActive ? "bg-[#07593D]" : "bg-[#D5D8DB]"
                    } rounded-[5px] justify-start items-center gap-3.5 inline-flex`}
                    disabled={!rowActive}
                    onClick={handleImport}
                  >
                    <div class="text-white text-sm font-normal font-['SF Pro Display']">
                      {rowActive
                        ? "Import Selected Invoices"
                        : "Upload Selected Inoives"}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </Card>
          <div className="rounded-b-lg px-[24px] pb-[24px]">
            <Table
              columns={columns}
              data={dumdata}
              pointer
              progressPending={isLoading}
              selectableRows
              handleRowSelect={handleRowSelect}
              progressComponent={<Loading />}
              tableHeader
              className="mt-5"
              onRowClicked={(row) => singleDetail(row.id)}
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {!isLoading && totalPages > 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              )}
            </div>
          </div>
        </Card>
      </DashboardLayout>
    </>
  );
}

export default DirectEarlyPaymentRequests;
