import { PAIconArrowRight, PAIconCardSend } from "assets/images/svgs";
import React from "react";
import { useSelector } from "react-redux";

const FundingOverview = () => {
  const { supplierDashboardData } = useSelector((state) => state.supplier);
  return (
    <div>
      <div className="bg-primaryColor rounded-r-[10px] py-[34px] mt-[30px] lg:mt-0 2xl:w-[400px]">
        <div className="flex justify-center mb-[10px]">
          <PAIconCardSend />
        </div>
        <p className="text-center text-sm lg:text-xs text-alto font-medium">
          Initiate Early Payment Request
        </p>
      </div>
      <div className="bg-white rounded-r-[10px] px-[30px] border-l-[2px] lg:px-[15px] xl:px-[30px] border-primaryColor my-[10px] xl:my-[17px]">
        <div className="flex items-center justify-center gap-x-[12px]">
          <h1 className="text-primaryColor text-[51.841px] xl:text-[51.841px] lg:text-4xl mt-[34px] lg:mt-[24px] xl:mt-[34px] font-bold">
            {supplierDashboardData?.data?.approvedFundingRequests}
          </h1>
          <p className="text-sm text-gray800 font-medium mt-[34px] lg:mt-[24px] xl:mt-[34px] lg:text-xs xl:text-sm">
            Approved Funding Requests
          </p>
        </div>
        <div className="pb-[15.12px] xl:mt-[15px] flex justify-end">
          <PAIconArrowRight className="!stroke-primaryColor" />
        </div>
      </div>
      <div className="bg-white rounded-r-[10px] px-[30px] border-l-[2px] lg:px-[10px] xl:px-[30px] border-primaryColor">
        <div className="flex items-center justify-center gap-x-[12px]">
          <h1 className="text-primaryColor text-[51.841px] lg:text-4xl xl:text-[51.841px] mt-[34px] lg:mt-[24px] xl:mt-[34px] font-bold">
            {supplierDashboardData?.data?.pendingFundingRequests}
          </h1>
          <p className="text-sm text-gray800 font-medium mt-[34px] lg:mt-[24px] xl:mt-[34px] lg:text-xs xl:text-sm">
            Pending Funding Requests
          </p>
        </div>
        <div className="pb-[15.12px] xl:mt-[15px] flex justify-end">
          <PAIconArrowRight className="!stroke-primaryColor" />
        </div>
      </div>
    </div>
  );
};

export default FundingOverview;
