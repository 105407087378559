import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import Button from "shared/Button";
import Card from "shared/Card";
import InputField from "shared/InputField";
import ConfirmModal from "../singlerequest/Modal/ConfirmModal";
import toast from "react-hot-toast";
import { PAIconConfirm, PAIconMDISuccess } from "assets/images/svgs";
import Modal from "layout/modal/Modal";
import {
  allApprovedInvoices,
  sendEarlyPaymentRequest,
} from "appstate/supplier/supplierSlice";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "shared/Pagination";
import { useNavigate } from "react-router-dom";

const SelectBulkTable = () => {
  const [storeRow, setStoreRow] = useState([]);
  const [open, setOpen] = useState(false);
  const [showCalcRequest, setShowCalcRequest] = useState(false);
  const [newPaymentDate, setNewPaymentDate] = useState("");
  const [description, setDescription] = useState("");
  const [getIds, setGetIds] = useState([]);
  const [storeAmount, setStoreAmount] = useState(null);
  const navigate = useNavigate();
  const handleRowSelect = (row) => {
    const checkedRows = row.selectedRows.map((cur) => cur);
    setStoreRow(checkedRows, ...storeRow);
    const getSelectedRowId = row.selectedRows.map((cur) => cur.id);
    setGetIds(getSelectedRowId, ...getIds);
    
    let totalAmount = 0;
    for (let i = 0; i < checkedRows.length; i++) {
      if (checkedRows[i].approvedByBuyer === true) {
        totalAmount += checkedRows[i].total;
      }
    }
    setStoreAmount(totalAmount);
  };

  const { approvedInvoicesData, isLoading, earlyPaymentLoading } = useSelector(
    (state) => state?.supplier
  );
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const status = "Approved"

  const dummyData = [
    {
      id: 1,
      invoiceReference: "INV-0001",
      buyerCompanyName: "ABC Corp",
      dueDate: "2024-12-01",
      createdDate: "2024-11-01",
      currency: "USD",
      total: 1500.00,
      status: "Approved",
    },
    {
      id: 2,
      invoiceReference: "INV-0002",
      buyerCompanyName: "XYZ Ltd",
      dueDate: "2024-12-05",
      createdDate: "2024-11-05",
      currency: "USD",
      total: 2500.00,
      status: "Pending request",
    },
    {
      id: 3,
      invoiceReference: "INV-0003",
      buyerCompanyName: "Big Buyer Corp",
      dueDate: "2024-12-10",
      createdDate: "2024-11-10",
      currency: "EUR",
      total: 3500.00,
      status: "Open",
    },
  ];

  useEffect(() => {
    dispatch(allApprovedInvoices({ page, pageSize, status }));
  }, [dispatch, page, pageSize]);

  // const rowSelectCritera = (row) => row.id;

  // useEffect(() => {
  //   setData(approvedInvoicesData?.data);
  // }, [approvedInvoicesData]);
  useEffect(() => {
    setData(dummyData);
    // eslint-disable-next-line
  }, []);
  const handleCalcRequest = () => {
    setShowCalcRequest(true);
  };

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyerCompanyName,
      sortable: true,
    },
    {
      name: "Invoice Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.createdDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.total,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Open" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Rejected request" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "Updated request" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "Pending request" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  function handleSendRequest() {
    const body = {
      invoiceIds: getIds,
      discountRate: 1.67,
      description: description,
      newPaymentDate: newPaymentDate,
      requestedAmount: parseFloat(storeAmount),
    };
    dispatch(sendEarlyPaymentRequest(body)).then((data) => {
      if (data?.payload?.success) {
        setOpen(false);
        toast.custom(
          <div className="flex mt-[200px]">
            <div className="bg-primaryColor w-[147.791px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
              <PAIconMDISuccess />
            </div>
            <div className="bg-white flex flex-col justify-center items-center w-[394.538px] h-[160px] rounded-r-[7px] shadow-lg">
              <PAIconConfirm />
              <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                Early payment request successful
              </p>
            </div>
          </div>
        );
        setOpen(false);
      }
    });
  }

  const totalPages = approvedInvoicesData?.meta?.totalElements;

  function handleOpenModal() {
    if (!description || !newPaymentDate) {
      toast.error("Description or new payment date are required.");
      return;
    }
    setOpen(true);
  }

  const onRowClicked = (row) => {
    // dispatch(getApprovedInvoiceId(row?.id));
    let path =
      "/invoice/fundingSection/singlerequestinvoicelist/invoicedetails";
    navigate(path);
  };

  return (
    <DashboardLayout>
      <Modal accountModal isShown={open} hide={() => setOpen(false)}>
        <ConfirmModal
          hide={() => setOpen(false)}
          handleAssociate={handleSendRequest}
          isLoading={earlyPaymentLoading}
          warning="Would you like to proceed with sending the early payment request?"
        />
      </Modal>
      <div className={`${showCalcRequest && "grid grid-cols-2 gap-x-[12px]"}`}>
        <Card className="px-[24px] py-[16px] rounded-[10px]">
          <div className="flex justify-between">
            <div>
              <h1 className="text-black text-sm font-medium">
                78 Approved Invoices
              </h1>
              <p className="text-xs text-[#5F4D61]">Available for payment</p>
              {!showCalcRequest && (
                <div>
                  <p className="text-textColor text-xs mb-[5px] mt-[10px]">
                    Select Early Payment Request Date
                  </p>
                  <InputField
                    type="date"
                    className="w-[369px] h-[39px] border border-[#AFAFAF] rounded-[5px] px-[10px]"
                  />
                </div>
              )}
            </div>
            <div className="flex gap-x-[14px]">
              <Button
                neutral
                // onClick={handleRowSelect}
                className="bg-[#2EA923] shadow-lg text-xs font-medium text-white w-[87px] h-[34px] rounded-[5px]"
              >
                Select All
              </Button>
              {!showCalcRequest && (
                <Button
                  neutral
                  disabled={storeRow?.length < 1}
                  onClick={handleCalcRequest}
                  className="bg-secondaryColor text-xs text-white font-medium w-[159px] h-[34px] rounded-[5px] shadow-lg"
                >
                  Request Early Payment
                </Button>
              )}
            </div>
          </div>
          <div className="mt-[45.5px]">
            <Table
              columns={columns}
              data={data}
              selectableRows
              onRowClicked={onRowClicked}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {!isLoading && totalPages > 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              )}
            </div>
          </div>
        </Card>
        {showCalcRequest && (
          <Card className="px-[24px] py-[16px] rounded-[10px]">
            <div className="flex justify-between">
              <div className="flex-1">
                <div className="flex items-center gap-x-[23px]">
                  <h1 className="text-textColor text-[20px] font-bold">
                    Invoice | INV-2002
                  </h1>
                  <p className="text-textColor text-sm">
                    Reference No: 123484BH
                  </p>
                </div>
                <div>
                  <span className="text-textColor text-xs mr-[10px]">
                    Created Date:{" "}
                  </span>
                  <span className="text-lightGray text-xs border-r pr-[10px]">
                    23 Dec, 2024
                  </span>
                  <span className="text-textColor text-xs ml-[10px]">
                    Payment Due Date:{" "}
                  </span>
                  <span className="text-lightGray text-xs ml-[10px]">
                    23-Dec-2024
                  </span>
                </div>
              </div>
              <Button
                neutral
                type="Button"
                onClick={handleOpenModal}
                className="border flex justify-center items-center border-[#AFAFAF] rounded-[5px] text-xs text-[#616161] w-[135px]  h-[30px]"
              >
                Send Request
              </Button>
            </div>
            <div className="mt-[25px]">
              <p className="text-textColor font-extrabold text-base italic">
                Invoice Amount
              </p>
              <h1 className="text-primaryColor text-2xl font-extrabold">
                NGN {storeAmount}
              </h1>
              <h1 className="text-textColor text-sm italic font-extrabold mt-[19px]">
                Early Payment Request
              </h1>
            </div>
            <div>
              <p className="text-textColor text-xs mb-[5px] mt-[24px]">
                New Payment Date:
              </p>
              <InputField
                type="date"
                value={newPaymentDate}
                onChange={(e) => setNewPaymentDate(e.target.value)}
                className="w-full h-[39px] border border-[#AFAFAF] rounded-[5px] px-[10px]"
              />
            </div>
            <div className="flex flex-col mt-[24px]">
              <label className="text-textColor mb-[10px] text-xs" htmlFor="">
                Description
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="border outline-none border-[#AFAFAF] w-full rounded-[5px] h-[67px] "
              ></textarea>
            </div>
            <h1 className="text-textColor text-lg font-bold border-b-[5px] border-[#F6F5FA] pb-[12px] mt-[23px]">
              Buyer Bulk Invoice details
            </h1>
            <div className="flex justify-between mt-[29px]">
              <div>
                <p className="text-textColor text-sm italic font-extrabold">
                  Supplier
                </p>
                <p className="text-lightGray text-xs">ABC Cooperation</p>
              </div>
              <div>
                <p className="text-textColor text-sm italic font-extrabold">
                  Buyer
                </p>
                <p className="text-lightGray text-xs">Big Buyer Coop</p>
              </div>
            </div>
            <div className="mt-[24px]">
              <Table
                columns={columns}
                data={storeRow}
                handleRowSelect={handleRowSelect}
                // selectableRowSelected={rowSelectCritera}
                pointer
                tableHeader
                className="mt-5"
              />
            </div>
          </Card>
        )}
      </div>
    </DashboardLayout>
  );
};

export default SelectBulkTable;
