import React, { useEffect, useState } from "react";
import Header from "../Header";
import { PAIconCustomerHeadset } from "assets/images/svgs";
// import { ChevronDownIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

const DropdownItem = ({ title, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="pb-5 cursor-pointer">
      <div
        className={`flex items-center justify-between font-bold`}
        onClick={toggleDropdown}
      >
        {title}
        <span>
          {/* <ChevronDownIcon
            className={`h-5 w-5 ml-2 ${isOpen ? "transform rotate-180" : ""}`}
          /> */}
        </span>
      </div>
      {isOpen && (
        <div className="rounded-xl mt-2 p-3 text-black bg-[#fff1da] leading-7">
          {answer}
        </div>
      )}
    </div>
  );
};

const FAQs = () => {
  const navigate = useNavigate();
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setIsAnimated(true);
    }, 200);

    return () => clearTimeout(animationTimeout);
  }, []);

  const handleClick = () => {
    navigate("/support");
  };
  return (
    <div
      className={`transition-transform transform ${
        isAnimated ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
      } ease-in duration-500`}
    >
      <Header Title="FAQs" showSearchInput={false} />
      <div className="w-full">
        <div className="bg-[#FFBE55] px-7 p-7">
          <p className="text-white">
            Everything you need to know about PayEdge and how it works. Can’t
            find an answer?
          </p>
          <button
            className="bg-white p-2 mt-3 flex items-center justify-center text-center gap-2 px-4 border border-transparent rounded-md shadow-md"
            onClick={handleClick}
          >
            <PAIconCustomerHeadset />
            Chat with our team
          </button>
        </div>
        <div className="flex items-center justify-center mt-16">
          <div className="w-[70%] space-y-5">
            <DropdownItem
              title="How secure is your supply chain finance software's data privacy?"
              answer="We prioritize data security and comply with industry standards to protect sensitive financial data. Our software utilizes advanced encryption and authentication mechanisms to ensure the confidentiality and integrity of your data."
            />
            <DropdownItem
              title="Does your software integrate with existing ERP and accounting systems?"
              answer="Yes, our supply chain finance software is designed to seamlessly integrate with a variety of ERP (Enterprise Resource Planning) and accounting systems. This enables smooth data flow and ensures that your financial information remains up-to-date across platforms."
            />
            <DropdownItem
              title="What kind of support and training do you provide for users of your software?"
              answer="We offer comprehensive support and training programs for users of our supply chain finance software. Our dedicated support team is available to assist you with any inquiries, and we provide training sessions to ensure that your team can fully leverage the capabilities of our software."
            />
            <DropdownItem
              title="Are there customizable features for our specific business needs?"
              answer="Absolutely! Our supply chain finance software is designed with flexibility in mind. We understand that every business is unique, and we offer customizable features to tailor the software to meet your specific business needs. This ensures that you get the most value out of our solution."
            />
            <DropdownItem
              title="How can our business get started with your supply chain finance software?"
              answer="Getting started with our supply chain finance software is easy. Simply reach out to our sales team, and we'll guide you through the onboarding process. We provide step-by-step assistance to help you set up the software, integrate it with your systems, and train your team for a smooth transition."
            />
            <DropdownItem
              title="What is supply chain finance, and how can your software help our business?"
              answer="Supply chain finance is a financial strategy that optimizes the working capital of businesses involved in a supply chain. Our software facilitates this process by providing advanced tools for managing invoices, optimizing cash flow, and enhancing collaboration between suppliers and buyers. With our software, you can streamline your supply chain financial operations for increased efficiency and profitability."
            />
            <DropdownItem
              title="Does your software integrate with existing ERP and accounting systems?"
              answer="Yes, our supply chain finance software is designed to seamlessly integrate with a variety of ERP (Enterprise Resource Planning) and accounting systems. This enables smooth data flow and ensures that your financial information remains up-to-date across platforms."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
