import { useState } from "react";
import { useFormik } from "formik";
import Button from "shared/Button";
import { useDispatch, useSelector } from "react-redux";
import InputField from "shared/InputField";
import Spinner from "shared/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { funderCompany, getFunderId } from "appstate/auth/authSlice";
import OnboardingLayout from "features/onboarding/category/components/OnboardingLayout";
import { companyDetailsValidationSchema } from "validation/funder/CompanyDetailsSchema";
import Contact from "features/onboarding/components/Contact";
import MultiSelectDropdown from "features/onboarding/components/MultiSelectDropdown";
import { industries } from "features/onboarding/constants/industries";

const FunderCompany = () => {
  const [selectedCountryCode, setSelectedCountryCode] = useState("+234");
  const [checked, setChecked] = useState(false);
  const { isLoading } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      companyName: "",
      rcNumber: "",
      companyWebsite: "",
      email: "",

      phoneNumber: "",
      registeredAddress: "",
      companyTaxId: "",
      industry: [],
    },
    validationSchema: companyDetailsValidationSchema(selectedCountryCode),

    onSubmit: (values) => {
      const phoneNumberWithCountryCode = `${selectedCountryCode}${values.phoneNumber}`;
      const body = {
        companyName: values.companyName,
        rcNumber: values.rcNumber,
        companyWebsite: values.companyWebsite,
        companyPhoneNumber: phoneNumberWithCountryCode,
        registeredAddress: values.registeredAddress,
        companyTaxId: values.companyTaxId,
        industryType: values.industry,
        companyEmail: values.email,
      };

      if (checked) {
        dispatch(funderCompany(body)).then((data) => {
          const funderId = data?.payload?.data?.userId;
          dispatch(getFunderId(funderId));
          if (data?.payload?.status === 200) {
            let path = "/funder/terms";
            navigate(path);
          }
        });
      }
    },
  });

  const handlePhoneCountryChange = (selectedCountryCode) => {
    setSelectedCountryCode(selectedCountryCode); // Update selected country code in state
  };

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <OnboardingLayout nohelp>
      <div className="px-[20px] pt-[40px] h-[100%] md:px-[60px] overflow-auto">
        <div className="mb-[50px] mt-[30px] flex justify-center items-center gap-x-[20px] lg:gap-x-[50px]">
          <div className="relative bottom-[13px] md:bottom-[15px]">
            <p className="font-normal text-[10px] text-textColor mb-[10px] md:text-sm">
              Company Details
            </p>
            <div className="w-[100px] h-[2px] bg-primaryColor rounded-[100px] md:w-[220px]"></div>
          </div>
          <div>
            <div className="w-[100px] h-[2px] bg-[#D9D9D9]  rounded-[100px] md:w-[220px]"></div>
          </div>
          <div>
            <div className="w-[100px] h-[2px]   bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center mt-[10px]">
          <div className="mb-[30px] ">
            <div class=" text-[#2d0432] text-2xl   font-medium  ">
              Please enter and confirm your company details
            </div>
            {/* <p className=" text-[#7A7A7A] mb-[30px] text-center">
              Enter your details company details here.
            </p> */}
            <div class="text-[#7a7a7a] text-sm  mb-[30px] text-center mt-8  font-medium">
              Enter your company details here.
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="flex justify-center flex-col"
          >
            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none  md:w-[424px] "
                placeholder="Coca Cola"
                label="Company Name"
                name="companyName"
                id="companyName"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.companyName}
              />
              {formik.touched.companyName && formik.errors.companyName ? (
                <p className=" text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyName}
                </p>
              ) : null}
            </div>
            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="Enter RC Number "
                label="Company Number (RC Number)"
                name="rcNumber"
                id="rcNumber"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.rcNumber}
              />
              {formik.touched.rcNumber && formik.errors.rcNumber ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.rcNumber}
                </p>
              ) : null}
            </div>
            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="www.abc.com"
                label="Company Website"
                name="companyWebsite"
                id="companyWebsite"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.companyWebsite}
              />
              {formik.touched.companyWebsite && formik.errors.companyWebsite ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyWebsite}
                </p>
              ) : null}
            </div>

            <div className="mb-[30px]">
              <Contact
                name="phoneNumber"
                id="phoneNumber"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                title={"Contact Number"}
                onCountryCodeChange={handlePhoneCountryChange}
              />

              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.phoneNumber}
                </p>
              ) : null}
            </div>

            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="098765431"
                label="Company Tax ID"
                id="companyTaxId"
                name="companyTaxId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyTaxId}
              />
              {formik.touched.companyTaxId && formik.errors.companyTaxId ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.companyTaxId}
                </p>
              ) : null}
            </div>

            <div className="mb-[30px]">
              <MultiSelectDropdown
                options={industries}
                selectedOptions={formik.values.industry}
                setSelectedOptions={(selected) =>
                  formik.setFieldValue("industry", selected)
                }
              />
              {formik.touched.industry && formik.errors.industry ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.industry}
                </p>
              ) : null}
            </div>

            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="Company Address"
                label="Company Address"
                id="registeredAddress"
                name="registeredAddress"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.registeredAddress}
              />
              {formik.touched.registeredAddress &&
              formik.errors.registeredAddress ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.registeredAddress}
                </p>
              ) : null}
            </div>

            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="exampleforyou@info.com"
                label="Email Address"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.email}
                </p>
              ) : null}
            </div>

            <div className="mb-[30px]">
              <p className="font-medium text-xs text-textColor mb-[10px]">
                Please confirm if this data is correct
              </p>
              <div>
                <label
                  className="flex font-normal text-xs text-textColor relative bottom-[3px] cursor-pointer"
                  onClick={handleChange}
                >
                  <input
                    type="checkbox"
                    className="accent-primaryColor mr-[10px]"
                  />
                  <p>Yes, this data is correct</p>
                </label>
              </div>
            </div>
            <Button
              type="submit"
              auth
              disabled={isLoading ? isLoading : !checked}
              className="mb-[30px] w-full"
            >
              {isLoading ? <Spinner /> : "Next"}
            </Button>
          </form>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default FunderCompany;
