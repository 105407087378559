import { useFormik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import Spinner from "shared/Spinner/Spinner";
import { virtualAccountValidationSchema } from "validation/wallet/virtualAccount";

const VirtualAccountModal = ({ isOpen, onClose, handleSubmit }) => {
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [bvn, setBVN] = useState("");
  // const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state?.wallet);

  // const formik = useFormik({
  //   initialValues: {
  //     firstName: "",
  //     lastName: "",
  //   },
  //   validationSchema: virtualAccountValidationSchema,
  //   onSubmit: (values) => {
  //     const body = {
  //       firstName: values.firstName,
  //       lastName: values.lastName,
  //     };
  //     dispatch(postVirtualAccount(body)).then((data) => {
  //       if (data?.meta?.requestStatus === "fulfilled") {
  //         handleSubmit(values.firstName, values.lastName);
  //         dispatch(allVirtualAccounts());
  //       }
  //     });
  //   },
  // });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema: virtualAccountValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values.firstName, values.lastName);
      // dispatch(allVirtualAccounts());
    },
  });

  if (!isOpen) return null;

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   onSubmit(firstName, lastName, bvn);
  // };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white p5 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Create Virtual Account
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        {/* FORM */}
        <div>
          <form className="w-full px-7" onSubmit={formik.handleSubmit}>
            <p className="text-[#7A7A7A] font-sans text-sm font-normal mb-6">
              Step 1
            </p>
            <p className="mb-4 text-[#222] font-sans text-sm font-bold">
              Account Information
            </p>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                className="border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.firstName}
                </p>
              ) : null}
            </div>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                className="border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.lastName}
                </p>
              ) : null}
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-10">
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-gray300 mx-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                >
                  Cancel
                </button>
              </div>
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]">
                <button
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                  type="submit"
                  disabled={isLoading ? isLoading : null}
                >
                  {isLoading ? <Spinner /> : "Confirm"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VirtualAccountModal;
