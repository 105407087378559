export const currencies = [
  {
    id: 1,
    country: "Algeria",
    title: "Algerian Dinar (DZD)",
    currency: "DZD-Dinar ",
  },
  {
    id: 2,
    country: "Egypt",
    title: "Egyptian Pound (EGP)",
    currency: "EGP-Pound",
  },
  {
    id: 3,
    country: "Kenya",
    title: "Kenyan Shilling (KES)",
    currency: "KES-Shilling",
  },
  {
    id: 4,
    country: "Morocco",
    title: "Moroccan Dirham (MAD)",
    currency: "MAD-Dirham",
  },
  {
    id: 5,
    country: "Nigeria",
    title: "Nigerian Naira (NGN)",
    currency: "NGN- Naira",
  },
  {
    id: 6,
    country: "South Africa",
    title: "South African Rand (ZAR)",
    currency: "ZAR-Rand",
  },
  {
    id: 7,
    country: "Tanzania",
    title: "Tanzanian Shilling (TZS)",
    currency: "TZS-Shilling ",
  },
  {
    id: 8,
    country: "Uganda",
    title: "Ugandan Shilling (UGX)",
    currency: "UGX-Shilling",
  },
  {
    id: 9,
    country: "Ethiopia",
    title: "Ethiopian Birr (ETB)",
    currency: "ETB-Birr ",
  },
  {
    id: 10,
    country: "Ghana",
    title: "Ghanaian Cedi (GHS)",
    currency: "GHS-Cedi ",
  },
];
