import Modal from "layout/modal/Modal";
import PurchaseOrderModal from "./PurchaseOrderModal";

function PoModal({ isShown, onClose, id }) {
  return (
    <Modal isShown={isShown} hide={onClose} showClose>
      <PurchaseOrderModal id={id} onClose={onClose} />
    </Modal>
  );
}

export default PoModal;
