import React, { useState } from "react";
import Header from "../Header";
import ReusableCard from "./Cards";
import {
  PAIconContractTemplate,
  PAIconDiscountCalculator,
  PAIconEmailTemplate,
  PAIconPaymentCalculator,
  PAIconROICalculator,
} from "assets/images/svgs";
import DiscountCalculator from "./Calculators/DiscountCalculator";
import ROICalculator from "./Calculators/ROICalculator";
import PaymentExtensionCalculator from "./Calculators/PaymentExtensionCalculator";
import ContractTemplate from "./ContractTemplate/ContractTemplate";
import EmailTemplate from "./EmailTemplate/EmailTemplate";

const Tools = ({renderTools}) => {
  const [selectedCard, setSelectedCard] = useState(null);
  function handleBack(){
    setSelectedCard(null)
  }
  
  const cardItems = [
    {
      icon: <PAIconDiscountCalculator />,
      title: "Discount Calculator",
      description: "Discover Your Discount: Early Payment Calculator",
      content: <DiscountCalculator goBack={handleBack} />,
    },
    {
      icon: <PAIconPaymentCalculator />,
      title: "Payment Extension Calculator",
      description: "Calculate Extensions and Interest Rates",
      content: <PaymentExtensionCalculator goBack={handleBack} />,
    },
    {
      icon: <PAIconROICalculator />,
      title: "ROI Calculator",
      description: "Discover Investment Returns",
      content: <ROICalculator goBack={handleBack} />,
    },
    {
      icon: <PAIconEmailTemplate />,
      title: "Email Template",
      description: "Effortless Email Design",
      content: <EmailTemplate goBack={handleBack} />,
    },
    {
      icon: <PAIconContractTemplate />,
      title: "Contract Template",
      description: "Effortless Contract Design",
      content: <ContractTemplate goBack={handleBack} />,
    },
  ];

  const handleCardClick = (index) => {
    setSelectedCard(cardItems[index]);
  };

  const content = selectedCard ? (
    <div className="p-4">
      {/* <h2 className="text-2xl font-bold mb-4">{selectedCard.title}</h2> */}
     {renderTools ? <EmailTemplate goBack={handleBack} /> : <>{selectedCard.content}</> } 
    </div>
  ) : (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 px-7">
      {cardItems.map((item, index) => (
        <div
          key={index}
          className="w-full cursor-pointer"
          onClick={() => handleCardClick(index)}
        >
          <ReusableCard
            icon={item.icon}
            title={item.title}
            description={item.description}
          />
        </div>
      ))}
    </div>
  );

  const header = !selectedCard && (
    <Header Title="Tools" showSearchInput={false} />
  );

  return (
    <>
      {header}
      {content}
    </>
  );
};

export default Tools;
