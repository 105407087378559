import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Invoice from "./invoice";

const BulkPaymentExtensionPreview = () => {
  return (
    <DashboardLayout
      section="Payment Extension"
      subHeader="Send and track payment extension processes"
    >
      <Invoice />
    </DashboardLayout>
  );
};

export default BulkPaymentExtensionPreview;
