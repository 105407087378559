import React, { useEffect, useState } from "react";
import {
  PAIconBasilOutlineWhite,
  PAIconDelete3,
  PAIconManageSearch,
  PAIconPaymnet,
  PAIconViewDay,
} from "assets/images/svgs";
import DeleteAccountModal from "./deleteAccount/deleteAccount";
import DeleteSuccessModal from "./deleteAccount/successDelete";
import EditAccountModal from "./editAccount";
import AccountDetails from "./editAccount/accountCardDetails";
import CongratsModal from "features/wallet/linkExternalAccount/congrats/congratsModal";
import { useDispatch, useSelector } from "react-redux";
import {
  allVirtualAccounts,
  getVirtualAccountId,
} from "appstate/wallet/walletSlice";
import VIewAccountModal from "./viewAccount";

const Account = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [accountDetails, setAccountDetails] = useState(false);
  const [congrats, setCongrats] = useState(false);
  const [accountIdToEdit, setAccountIdToEdit] = useState(null);
  const [editedAccountData, setEditedAccountData] = useState(null);
  const [accountIdToDelete, setAccountIdToDelete] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(null);

  const { allVirtualAccountsData } = useSelector((state) => state?.wallet);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allVirtualAccounts());
  }, [dispatch]);

  // Check if allVirtualAccountsData is defined before mapping
  const accountData = Array.isArray(allVirtualAccountsData?.data)
    ? allVirtualAccountsData?.data?.map((account) => ({
        id: account?.id,
        name: account?.accountName,
        accountNumber: account?.accountNumber,
        bankName: account?.provider || "N/A",
        accountType: "Virtual Account",
        balance: account?.balance,
      }))
    : [];

  const [dropdownState, setDropdownState] = useState(
    Array(accountData?.length).fill(false)
  );

  const handleDropdownToggle = (accountId) => {
    const newDropdownState = [...dropdownState];
    newDropdownState[accountId] = !newDropdownState[accountId];
    setDropdownState(newDropdownState);

    if (!newDropdownState[accountId]) {
      openEditModal(accountId);
    }
  };

  const openDeleteModal = (accountId) => {
    setAccountIdToDelete(accountId); 
    setDeleteModalOpen(true);
  };

  const openEditModal = (accountId) => {
    setAccountIdToEdit(accountId);
    setEditModalOpen(true);
    dispatch(getVirtualAccountId(accountId));
  };

  const openViewModal = (accountId) => {
    setViewModalOpen(true);
    dispatch(getVirtualAccountId(accountId));
  }

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setEditModalOpen(false);
    setAccountDetails(false);
    setCongrats(false);
    setViewModalOpen(false);
  };

  const handleDeleteConfirm = () => {
    closeDeleteModal();
    setSuccessModalOpen(true);
  };

  const handleEditModalSubmit = (editedData) => {
    closeDeleteModal();
    setEditedAccountData(editedData);
    setAccountDetails(true);
  };

  const handleAccountDetailsSubmit = () => {
    closeDeleteModal();
    setCongrats(true);
  };

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const editClick = () => {
    setAccountDetails(false);
    setEditModalOpen(true)
  }

  return (
    <>
      <div className="md:flex md:gap-5 flex-wrap">
        {accountData?.map((account) => (
          <div
            key={account?.id}
            className="relative bg-[#07593D] p-3 mt-3 cursor-pointer text-white text-xs md:w-[calc(25%-10px)] md:mb-0 mb-3 rounded"
          >
            <div className="flex items-center justify-between">
              <div className="text-white text-xs flex items-center">
                <PAIconPaymnet className="mr-2" />
                <p>{account?.name}</p>
              </div>
              <div
                className="cursor-pointer accent-white text-white"
                onClick={() => handleDropdownToggle(account?.id)}
              >
                <PAIconBasilOutlineWhite className="color-white text-white" />
              </div>
              {dropdownState[account?.id] && (
                <div className="absolute top-0 right-0 mt-8 bg-white text-black p-2 rounded">
                  <div
                    className="flex items-center gap-1"
                    onClick={() => {
                      handleDropdownToggle(account?.id);
                      openEditModal(account?.id);
                    }}
                  >
                    <PAIconManageSearch /> <p>Edit Account Details</p>
                  </div>
                  <div
                    className="my-2 flex items-center gap-1"
                    onClick={() => {
                      handleDropdownToggle(account?.id);
                      openViewModal(account?.id);
                    }}
                  >
                    <PAIconViewDay /> <p>View Account Details</p>
                  </div>
                  <div
                    className="flex items-center gap-1"
                    onClick={() => {
                      handleDropdownToggle(account?.id);
                      openDeleteModal(account?.id);
                    }}
                  >
                    <PAIconDelete3 />
                    Delete Account
                  </div>
                </div>
              )}
            </div>
            <div className="flex justify-between my-3">
              <div className="text-white text-xl font-medium">
                ₦{account?.balance}
              </div>
              <p className="mt-2 italic">{account?.accountNumber}</p>
            </div>
            <div className="flex items-center justify-between mt-2 text-[10px]">
              <p>{account?.bankName}</p>
              <p>{account?.accountType}</p>
            </div>
          </div>
        ))}
        {/* <Cards /> */}
        <DeleteAccountModal
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          onConfirm={handleDeleteConfirm}
          accountId={accountIdToDelete}
        />
        <DeleteSuccessModal
          isOpen={isSuccessModalOpen}
          onClose={closeSuccessModal}
        />
        <VIewAccountModal
          isOpen={viewModalOpen}
          onClose={closeDeleteModal}
        />
        <EditAccountModal
          isOpen={isEditModalOpen}
          onClose={closeDeleteModal}
          onSubmit={handleEditModalSubmit}
          accountId={accountIdToEdit}
        />
        <AccountDetails
          isOpen={accountDetails}
          onClose={closeDeleteModal}
          onSubmit={handleAccountDetailsSubmit}
          editedData={editedAccountData}
          editClick={editClick}
        />
        <CongratsModal
          isOpen={congrats}
          onClose={closeDeleteModal}
          message="Your Virtual Account has been updated"
        />
      </div>
    </>
  );
};

export default Account;
