import { PAIconArrowLeftGreen, PAIconDownload } from "assets/images/svgs";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";

const InvoiceDetails = ({ data, showSendReminderButton = true }) => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const successStatuses = ["CREATED", "APPROVED", "PAID", "FULLY PAID"];
  const rejectedStatuses = ["PARTIALLY PAID", "REJECTED"];
  const awaitingStatuses = ["PENDING", "AWAITING APPROVAL", "AWAITING PAYMENT"];

  const getStatusColorClass = (status) => {
    if (successStatuses.includes(status)) return "text-success";
    if (rejectedStatuses.includes(status)) return "text-[#ee0816]"; 
    if (awaitingStatuses.includes(status)) return "text-[#facc15]"; 
    return "text-[#000]"; 
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleRemainderClick = () => {
    setIsAcceptModalOpen(true);
  };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setIsSuccessModalOpen(true);
    setSuccessMessage("Payment reminders sent successfully!");
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const {
    invoiceNumber,
    status,
    referenceNumber,
    createdDate,
    dueDate,
    payment,
    currency,
    supplier,
    buyer,
    lineItems,
    attachments,
  } = data;

  return (
    <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px]">
      <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
        <div>
          <Link
            onClick={goBack}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>
        </div>
        <div className="flex justify-between items-center">
          <h1 className="text-black font-medium underline">Invoices</h1>
          {showSendReminderButton && (
            // <div
            //   onClick={handleRemainderClick}
            //   className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
            // >
            <div
              onClick={handleRemainderClick}
              className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
            >
              Send Reminder
            </div>
            // </div>
          )}
        </div>
      </header>

      <div className="px-6">
        <div className="flex flex-col gap-3">
          <header className="flex justify-between items-center">
            <h1 className="text-[18px] font-bold">Invoice | {invoiceNumber}</h1>
            {/* <button className="rounded-md bg-gray300 py-1.5 px-6">
              <p className="text-black text-center text-xs font-normal">Edit</p>
            </button> */}
            <div className={`rounded-md py-1.5 bg-flashWhite px-6 ${getStatusColorClass(status)}`}>
            <p className="text-center text-xs font-bold">
                {status || "NOT PAID"}
              </p>
            </div>
          </header>

          <main className="w-full flex justify-between">
            <div className="flex flex-col gap-3 w-1/2">
              <div className="flex gap-x-[5px]">
                <span>Reference No:</span>
                <span>{referenceNumber}</span>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex gap-x-[50px]">
                  <span className="text-xs w-[97px]">Created Date:</span>
                  <span className="text-[#979797] text-xs">{createdDate}</span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className="text-xs">Payment Due Date:</span>
                  <span className="text-[#979797] text-xs">{dueDate}</span>
                </div>
              </div>
              {/* <div className="flex justify-between my-5">
                <div className="flex flex-col">
                  <span className="font-bold text-sm">Payment</span>
                  <span className="text-[#979797] text-xs">{payment}</span>
                </div>
                <div className="flex flex-col">
                  <span className="font-bold text-sm">Currency</span>
                  <span className="text-[#979797] text-xs">{currency}</span>
                </div>
              </div> */}
            </div>
            <div className="flex flex-col justify-center items-center">
              {/* <div className="rounded-md bg-[#CDECDB] py-1.5 px-6">
                <p className="text-[#07A04A] text-center text-xs font-normal">
                  Not Paid
                </p>
              </div> */}
            </div>
          </main>
          <div className="flex justify-between my-5">
            <div className="flex flex-col">
              <span className="font-bold text-sm">Payment</span>
              <span className="text-[#979797] text-xs">{payment}</span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-sm">Currency</span>
              <span className="text-[#979797] text-xs">{currency}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
            <h1 className="text-[18px] font-bold">Supplier/buyer details</h1>
          </header>
          <div className="flex  justify-between mb-5">
            <div className="flex gap-2">
              {/* <PAIconCocacola /> */}
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Supplier</span>
                <span className="text-[#979797]  text-xs">{supplier}</span>
              </div>
            </div>
            <div className="flex gap-2">
              {/* <PAIconModelOne /> */}
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Buyer</span>
                <span className="text-[#979797]  text-xs">{buyer}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Line Items Table */}
        <div className="flex flex-col gap-4">
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
            <h1 className="text-[18px] font-bold">Line Items</h1>
          </header>
          <div className="w-full overflow-x-auto">
            <table className="w-full overflow-x-auto">
              <thead className="bg-subtleGray w-">
                <tr className="bg-unusualGray p-2.5 ">
                  <td className="text-[#11192A] p-2.5 text-xs font-bold">
                    Item Number
                  </td>
                  <td className="p-2.5 text-xs font-bold">PO Number</td>
                  <td className="p-2.5 text-xs font-bold">
                    Product/Service Number
                  </td>
                  <td className="p-2.5 text-xs font-bold">
                    Product/Service Description
                  </td>
                  <td className="p-2.5 text-xs font-bold">Quantity</td>
                  <td className="p-2.5 text-xs font-bold">Unit Price</td>
                  <td className="p-2.5 text-xs font-bold">
                    Line Item Extended Amount
                  </td>
                </tr>
              </thead>
              <tbody>
                {lineItems.map((item, index) => (
                  <tr key={index} className="py-[3px] px-1.5">
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.itemNumber}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.poNumber}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.productNumber}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.productDescription}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.quantity}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.unitPrice}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.extendedAmount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Attachments Table */}
        <div className="flex flex-col gap-4">
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
            <h1 className="text-sm font-bold">Attachments</h1>
          </header>
          <div className="w-full overflow-x-auto">
            <table className="w-full overflow-x-auto">
              <thead className="bg-subtleGray w-">
                <tr className="px-4 bg-unusualGray p-2.5 ">
                  <td className="px-4 py-2.5 text-xs font-bold">
                    Document Name
                  </td>
                  <td className="px-4 py-2.5 text-xs font-bold">File Type</td>
                  <td className="px-4 py-2.5 text-xs font-bold">
                    Upload Date / Time
                  </td>
                  <td className="px-4 py-2.5 text-xs font-bold">Action</td>
                </tr>
              </thead>
              <tbody>
                {attachments.map((attachment, index) => (
                  <tr key={index} className="py-[3px] px-1.5">
                    <td className="text-xs text-[#0076EC] px-4 py-2.5">
                      {attachment.name}
                    </td>
                    <td className="px-4 py-2.5 text-xs">
                      {attachment.fileType}
                    </td>
                    <td className="px-4 py-2.5 text-xs">
                      {attachment.uploadDateTime}
                    </td>
                    <td className="cursor-pointer text-xs px-4 py-2.5">
                      <a
                        href={attachment.downloadUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <PAIconDownload />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            to due{" "}
            <span className="text-secondaryColor font-bold">
              invoice {invoiceNumber}
            </span>
            ?
          </>
        }
        onConfirm={handleConfirm}
      />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </div>
  );
};

export default InvoiceDetails;
