import { PAIconDropdown, PAIconQuillSend } from "assets/images/svgs";
import React, { useState } from "react";

const ModalGeneralComment = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState("general");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const commentsArray = [
    {
      img: "/dp.png",
      name: "Alice Smith",
      comment: "Hi there! I've reviewed the agreement, and it looks good to me. Just a quick question: Is there a specific deadline for us to submit the final report? Thanks!",
      date: "October 28, 2023",
      time: "3:30 AM",
    },
    {
      img: "/dp1.png",
      name: "David Doe",
      comment: "Hi Alice! Thanks for checking. Yes, there's a deadline mentioned in section 3. It's 60 days from the effective date. Does that work for you.",
      date: "October 28, 2023",
      time: "3:30 AM",
    },
    {
        img: "/dp.png",
        name: "Alice Smith",
        comment: "Thanks, David! That works for us. Just one more thing - in section 5.2, it mentions indemnification. Could you clarify what types of claims fall under that?",
        date: "October 28, 2023",
        time: "3:30 AM",
      },
      {
        img: "/dp1.png",
        name: "David Doe",
        comment: "Of course, Alice. Indemnification covers any third-party claims related to the project that result from our actions or negligence. We'll share more details in a separate indemnification clause.",
        date: "October 28, 2023",
        time: "3:30 AM",
      },
  ];

  if (!isOpen) return null;
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg">
      <div className="bg-white py-2 px5 rounded-md max-w-xs md:max-w-lg w-full">
        <div className="flex justify-between items-center mb-4 px-4">
          <div className="flex space-x-4">
            <button
              className={`${
                activeTab === "general"
                  ? "text-[#07593D] border-b border-[#07593D]"
                  : "text-black"
              } py-2 rounded-t-md`}
              onClick={() => handleTabClick("general")}
            >
              General Comment
            </button>
            <button
              className={`${
                activeTab === "latestUpdate"
                ? "text-[#07593D] border-b border-[#07593D]"
                : "text-black"
              } py-2 rounded-t-md flex justify-between items-center`}
              onClick={() => handleTabClick("latestUpdate")}
            >
              Latest Update{" "}
              <span>
                <PAIconDropdown />
              </span>
            </button>
          </div>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        <div className="w-full border border-gray py-1 bg-gray300 text-[#07593D] mt-[-15px] mb-5 px-4">
            All comment for latest update
        </div>

        {/* COMMENTS */}
        <div className="w-full max-h-[250px] overflow-y-auto">
        {commentsArray.map((comment, index) => (
          <div key={index} className="flex items-start mb-4 w-full px-10">
            <img src={comment.img} alt={comment.name} className="w-10 h-10 rounded-full mr-3" />
            <div>
              <div className="flex items-center justify-between w-full">
                <p className="text-black text-sm font-bold font-['SF Pro Display'] mr-2">{comment.name}</p>
                <p className="text-sky-400 text-xs font-normal font-['SF Pro Display']">
                  {comment.date} at {comment.time}
                </p>
              </div>
              <p className="text-neutral-800 text-sm font-normal font-['SF Pro Display']">{comment.comment}</p>
            </div>
          </div>
        ))}
        </div>
        {/* SEND BUTTON */}
        <div className="relative w-full mt-2 px-4">
          <input
            type="text"
            id="fileUrl"
            placeholder="Post a comment"
            className="w-full border border-[#CCC] hover:border-blue-300 outline-none rounded-md p-2"
          />
          <button className="absolute top-1/2 right-2 transform -translate-y-1/2 p-2.5">
            <PAIconQuillSend />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalGeneralComment;
