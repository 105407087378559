import React from "react";
import DashboardLayout from "layout/dashboardlayout";
import Details from "./details";

const FacOpenMarketFundedDetail = () => {
  return (
    <div>
      <DashboardLayout
        section="Open market"
        subHeader="Monitor your invoices in the open market"
      >
        <Details />
      </DashboardLayout>
    </div>
  );
};

export default FacOpenMarketFundedDetail;
