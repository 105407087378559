import { buyerViewPfpById } from "appstate/buyer/buyerSlice";
import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import Card from "shared/Card";

function PayableFinanceDetails() {
  const dispatch = useDispatch();
  const { pfpId, viewPfpByIdData } = useSelector((state) => state?.buyer);
  useEffect(() => {
    dispatch(buyerViewPfpById({ id: pfpId }));
  }, [dispatch, pfpId]);
  // console.log(viewPfpByIdData);
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Tier",
      selector: (row) => row.tier,
      sortable: true,
      width: "15rem",
    },
  ];

  const data = [
    {
      id: 1,
      name: "Eleanor Pena",
      email: "jamesnicolas@gmail.com",
      tier: " Tier 1",
    },
    {
      id: 2,
      name: "Eleanor Pena",
      email: "jamesnicolas@gmail.com",
      tier: " Tier 1",
    },
    {
      id: 3,
      name: "Eleanor Pena",
      email: "jamesnicolas@gmail.com",
      tier: " Tier 1",
    },
    {
      id: 4,
      name: "Eleanor Pena",
      email: "jamesnicolas@gmail.com",
      tier: " Tier 1",
    },

    {
      id: 5,
      name: "Eleanor Pena",
      email: "jamesnicolas@gmail.com",
      tier: " Tier 1",
    },
  ];
  const navigate = useNavigate();
  function handleGoback() {
    let path = -1;
    navigate(path);
  }
  function handleRedirect() {
    let path = "payablefinanceinvoice";
    navigate(path);
  }
  return (
    <DashboardLayout>
      <div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div
            onClick={handleGoback}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
          <div className="flex gap-x-[395px] flex-wrap md:gap-x-[200px] lg:gap-x-[280px] xl:gap-x-[395px]">
            <div>
              <p className="text-black text-sm font-medium">Details</p>
              <p className="text-textColor text-xs font-normal">
                Basic information about the Payable finance program
              </p>
            </div>
            <div className="flex gap-x-[10px]">
              <Button
                neutral
                onClick={handleRedirect}
                className="py-[12px] px-[24px] rounded-[5px] border-[1px] border-silver !text-gray800 text-sm"
              >
                View Invoices
              </Button>

              <Button
                neutral
                className="py-[12px] px-[24px] bg-primaryColor rounded-[5px] border-[1px] border-silver !text-white text-sm"
              >
                New PFP
              </Button>
            </div>
          </div>
        </header>
        <Card className="px-[20px] md:px-[40px] pb-[40px] rounded-b-[10px] 2xl:pl-[100px]">
          <div>
            <div className="md:flex md:justify-between  pt-[24px] 2xl:justify-start 2xl:gap-x-[370px] ">
              <div>
                <h2 className="text-lightGray text-sm">Name of Program</h2>

                <p className="mt-[8px] text-sm font-normal text-textColor">
                  {viewPfpByIdData?.data?.programName}
                </p>
                <p className="mt-[8px] text-lightGray text-sm font-normal">
                  Financing Option
                </p>
                <p className="text-sm font-normal text-textColor">
                  {viewPfpByIdData?.data?.financingOptions}
                </p>
                <div className="mt-[16px]">
                  <p className="text-sm font-normal text-lightGray">
                    Start Date
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {viewPfpByIdData?.data?.startDate}
                  </p>
                </div>
                <div className="mt-[16px]">
                  <p className="text-sm font-normal text-lightGray">End Date</p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {viewPfpByIdData?.data?.endDate}
                  </p>
                </div>
              </div>

              <div className="mt-[24px] md:mt-0">
                <div>
                  <p className="text-sm font-normal text-lightGray">
                    Amount Range
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    Min: NGN {viewPfpByIdData?.data?.minimumAmount}
                  </p>
                </div>
                <div className="mt-[8px]">
                  <p className="mt-[8px] text-textColor text-sm font-normal w-[150px] md:w-full">
                    Max: NGN {viewPfpByIdData?.data?.maximumAmount}
                  </p>
                </div>
                <div className="mt-[8px]">
                  <p className="text-sm font-normal text-lightGray">
                    Discount Rate
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    {viewPfpByIdData?.data?.discountRate}%
                  </p>
                </div>
                <div>
                  <p className="text-sm font-normal text-lightGray">
                    Sharing Ratio
                  </p>
                  <p className="mt-[8px] text-textColor text-sm font-normal">
                    Buyer: {viewPfpByIdData?.data?.buyerPercentage} %
                  </p>
                </div>
                {/* <div className="mt-[8px]">
                  <p className="mt-[8px] text-textColor text-sm font-normal w-[150px] md:w-full">
                    Funder: 30%
                  </p>
                </div> */}
              </div>
            </div>
            <div className="mt-[48px]">
              <h1 className="text-sm font-semibold md:justify-start text-textColor flex items-center">
                Funders on the PFP
                <span className="w-[25px] h-[25px] flex justify-center items-center bg-conch ml-[10px] rounded-[50%]">
                  {viewPfpByIdData?.data?.invitedFunderIds?.length}
                </span>
              </h1>
              <div className="mt-[24px] pr-[30px]">
                <Table
                  columns={columns}
                  data={data}
                  // onRowClicked={onRowClicked}
                  //   progressPending={isLoading}
                  //   progressComponent={<Loading />}
                  pointer
                  tableHeader
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </DashboardLayout>
  );
}

export default PayableFinanceDetails;
