import {
  getAllSupplierAssociatedFunder,
  getAssociatedFunderId,
  supplierFunderDisassociate,
  supplierOnboardingInviteToFunder,
} from "appstate/supplier/supplierSlice";
import {
  PAIconCancel,
  PAIconDropUser,
  PAIconEditlight,
  PAIconLink,
  PAIconOption,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import AccountModalContainer from "shared/accountModal/Modal";
import InviteModal from "shared/inviteModal";
import TableHeader from "shared/tableHeader/TableHeader";

const Funders = () => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [open, setOpen] = useState(false);
  const [invite, setInvite] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [success, setSuccess] = useState(false);
  const [getId, setGetId] = useState(null);
  const [openDisassociateModal, setOpenDisAssociateModal] = useState(false);
  const [newInvite, setNewInvite] = useState("");
  const [atBottom, setAtBottom] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const {
    supplierAssociatedFundersData,
    isLoading,
    disAssociateLoading,
    inviteLoading,
  } = useSelector((state) => state?.supplier);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const handleDropdown = (row) => {
    if (dropdown === row.id) {
      setDropdown(null);
    } else {
      setDropdown(row.id);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const search = "";
    dispatch(getAllSupplierAssociatedFunder({ page, pageSize, search }));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    if (companyName.length <= 0) {
      setData(supplierAssociatedFundersData?.data);
    }
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown, supplierAssociatedFundersData, companyName]);

  const onRowClicked = (row) => {
    let path = "/supplier-funders/funderdetails";
    dispatch(getAssociatedFunderId(row?.id));
    navigate(path);
  };

  const handleOpenModal = (row) => {
    setGetId(row?.userId);
    setOpenDisAssociateModal(true);
  };

  const handleDisAssociate = () => {
    const funderId = getId;
    const search = "";

    dispatch(supplierFunderDisassociate(funderId)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(false);
        setOpenDisAssociateModal(false);
        dispatch(getAllSupplierAssociatedFunder({ page, pageSize, search }));
      }
    });
  };

  const totalPages = supplierAssociatedFundersData?.meta?.totalElements;
  const column = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      name: "Account Tier",
      selector: (row) => row?.tierName,
    },
    {
      cell: (row) => (
        <div>
          {dropdown ? (
            <PAIconCancel
              title="Options"
              onClick={() => handleDropdown(row)}
              className="w-[10px]"
            />
          ) : (
            // <></>
            // <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
            <PAIconOption title="Options" onClick={() => handleDropdown(row)} />
          )}
          {dropdown === row.id && row && (
            <div className="relative" ref={dropdownRef}>
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && ""
                } absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div className="flex items-center gap-x-[10px] mb-[12px]  pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconDropUser />
                  </span>
                  <p className="font-sm font-normal">View Details</p>
                </div>

                <div className="flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]">
                  <span>
                    <PAIconEditlight />
                  </span>
                  <p className="font-sm font-normal">Change Tier</p>
                </div>
                <div className="border border-silver"></div>
                <div
                  onClick={() => handleOpenModal(row)}
                  className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                >
                  <span>
                    <PAIconLink />
                  </span>
                  <p className="font-sm font-normal text-[#DE1515]">
                    Disassociate
                  </p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
      width: "5rem",
    },
  ];

  let path = "associate-funders";

  const handleSearch = () => {
    const search = companyName;
    dispatch(getAllSupplierAssociatedFunder({ page, pageSize, search })).then(
      (data) => {
        setData(data?.payload?.data);
      }
    );
  };

  const handleSubmitEmails = () => {
    const body = invite;
    dispatch(supplierOnboardingInviteToFunder(body)).then((data) => {
      if (data?.payload?.success === true) {
        setSuccess(true);
        setInvite([]);
      }
    });
  };

  return (
    <DashboardLayout section="Funders">
      <AccountModalContainer
        accontModal
        isShown={openDisassociateModal}
        onClose={() => {
          setOpenDisAssociateModal(false);
        }}
        isLoading={disAssociateLoading}
        success={success}
        handleAssociate={handleDisAssociate}
        warning="Are you sure you would like to disassociate with this member?"
      />
      <InviteModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        message=" The Funder would received a mail that your are inviting to join
          PayEdge."
        heading="Invite Funder"
        info="You can add one or more funders at once with ease"
        role="Funder"
        invite={invite}
        setInvite={setInvite}
        newInvite={newInvite}
        setNewInvite={setNewInvite}
        success={success}
        setSuccess={setSuccess}
        isLoading={inviteLoading}
        handleAdd={handleSubmitEmails}
      />
      <Card className="p-[24px] rounded-[10px]">
        <TableHeader
          path={path}
          setProductName={setCompanyName}
          productName={companyName}
          search={handleSearch}
          associateBtn
          inviteBtn
          accounts
          data={data}
          setOpen={setOpen}
          total="All Funders"
          details="Access contacts details of the associated funders"
        />
        <Table
          columns={column}
          data={data}
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
          dropdown={dropdown}
        />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default Funders;
