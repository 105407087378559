import React from "react";
import ClosedMarketCard from "./cards/ClosedMarketCard";
import { useState } from "react";
import Pagination from "shared/Pagination";
import BidInvoiceModal from "../modals/BidInvoiceModal";
import BidAcceptModal from "../modals/BidAcceptModal";
import BidSelectAccountModal from "../modals/BidSelectAccountModal";

import BidProceedModal from "../modals/BidProceedModal";
import BidPinModal from "../modals/BidPinModal";
import BidInvoiceLockedModal from "../modals/BidInvoiceLockedModal";
import BidLoaderModal from "../modals/BidLoaderModla";
import { setClosedMarketActiveTab } from "appstate/funder/funderSlice";
import { useDispatch } from "react-redux";
import TransitionOpacity from "shared/animation/TransitionOpacity";
import { AnimatePresence } from "framer-motion";

const ClosedMarket = ({ closedMarketQuery }) => {
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [onBid, setOnbid] = useState(false);

  const dispatch = useDispatch();
  const data = [
    {
      id: "1",
      invoiceNumber: "R3456X89Y",
      buyerName: "ABC Limited",
      invoiceType: "Single request",

      lockRate: "2.7%",
      statusOfInvoice: "Bid Active",
      requestAmount: "N7,940,000",
      dueDate: "2023-12-01",
      supplierName: "Coca Cola",
      earlyPaymentDate: "2023-11-01",
    },
    {
      id: "2",
      invoiceNumber: "R3456X89Y",
      buyerName: "Coca Cola",
      invoiceType: "Bulk request",

      lockRate: "3.6%",
      statusOfInvoice: "Bid Active",
      requestAmount: "N7,940,000",
      dueDate: "2023-12-01",
      supplierName: "Defi",
      earlyPaymentDate: "2023-11-01",
    },
    {
      id: "3",
      invoiceNumber: "R3456X89Y",
      buyerName: "Nestle Plc.",
      invoiceType: "Single request",

      lockRate: "2.7%",
      statusOfInvoice: "Posted",
      requestAmount: "N7,940,000",
      dueDate: "2023-12-01",
      supplierName: "Coca Cola",
      earlyPaymentDate: "2023-11-01",
    },
    {
      id: "4",
      invoiceNumber: "R3456X89Y",
      buyerName: "Coca Cola",
      invoiceType: "Single request",

      lockRate: "2.1%",
      statusOfInvoice: "Bid Active",
      requestAmount: "N7,940,000",
      dueDate: "2023-12-01",
      supplierName: "Nestle Plc.",
      earlyPaymentDate: "2023-11-01",
    },
    {
      id: "5",
      invoiceNumber: "R3456X89Y",
      buyerName: "Coca cola",
      invoiceType: "Single request",

      lockRate: "2.1%",
      statusOfInvoice: "Bid Active",
      requestAmount: "N7,940,000",
      dueDate: "2023-12-01",
      supplierName: "Breweries",
      earlyPaymentDate: "2023-11-01",
    },
  ];

  const [bidSelectAccountModal, setBidSelectAccountModal] = useState(false);
  const [ongoingAcceptUpdateBidModal, setOngoingAcceptUpdateBidModal] =
    useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [bidProceed, setBidProceed] = useState(false);
  const [bidPin, setBidPin] = useState(false);
  const [bidLoaderModal, setBidLoaderModal] = useState(false);

  const [discountRate, setDiscountRate] = useState("");
  const handleSetDiscountRate = (e) => {
    setDiscountRate(e.target.value);
  };

  const handleAccept = () => {
    setOngoingAcceptUpdateBidModal(false);
    setTimeout(() => {
      setBidSelectAccountModal(true);
    }, 300);
  };

  const handleProceed = () => {
    setBidProceed(false);

    setTimeout(() => {
      setBidPin(true);
    }, 300);
  };
  const handleInvoiceModalClose = () => {
    setIsInvoiceModalOpen(false);
  };
  const handleOnselectModal = () => {
    setBidSelectAccountModal(false);

    setTimeout(() => {
      setBidProceed(true);
    }, 300);
  };
  const handlePinModal = () => {
    setBidPin(false);
    setBidLoaderModal(true);
  };

  const handleContinueBidding = () => {
    setOnbid(false);
    setTimeout(() => {
      setOngoingAcceptUpdateBidModal(true);
    }, 300);
  };
  const handleStartBid = () => {
    setTimeout(() => {
      setOnbid(true);
    }, 300);
  };

  const handleTabClick = (tabNumber) => {
    setIsInvoiceModalOpen(false);
    dispatch(setClosedMarketActiveTab(tabNumber));
    sessionStorage.setItem("activeTab", tabNumber);
  };

  const totalPages = data.length;

  return (
    <>
      <TransitionOpacity>
        <AnimatePresence>
          <BidInvoiceModal
            isOpen={onBid}
            handleYes={handleContinueBidding}
            onClose={() => setOnbid(false)}
            handleSetDiscountRate={handleSetDiscountRate}
            discountRate={discountRate}
          />
        </AnimatePresence>
        <AnimatePresence>
          <BidInvoiceLockedModal
            isOpen={isInvoiceModalOpen}
            onClose={handleInvoiceModalClose}
            toOngoingTab={() => handleTabClick(3)}
          />
        </AnimatePresence>
        {/* <AnimatePresence> */}
        <>
          {bidLoaderModal && (
            <BidLoaderModal
              isOpen={bidLoaderModal}
              onClose={() => setBidLoaderModal(false)}
              onAction={() => {
                setBidLoaderModal(false);
                setTimeout(() => {
                  setIsInvoiceModalOpen(true);
                }, 300);
              }}
              statusText={"Verifying account"}
            />
          )}{" "}
        </>

        <AnimatePresence>
          {" "}
          <BidPinModal
            isOpen={bidPin}
            onClose={() => setBidPin(false)}
            handleNext={handlePinModal}
          />
        </AnimatePresence>
        <AnimatePresence>
          {" "}
          <BidProceedModal
            isOpen={bidProceed}
            refNum={" 7364738921"}
            onClose={() => setBidProceed(false)}
            handleYes={handleProceed}
          />
        </AnimatePresence>
        <AnimatePresence>
          {" "}
          <BidAcceptModal
            isOpen={ongoingAcceptUpdateBidModal}
            onClose={() => setOngoingAcceptUpdateBidModal(false)}
            refNum={"R3456X89Y"}
            handleYes={handleAccept}
          />
        </AnimatePresence>
        <AnimatePresence>
          {" "}
          <BidSelectAccountModal
            isOpen={bidSelectAccountModal}
            onClose={() => setBidSelectAccountModal(false)}
            handleProceed={handleOnselectModal}
          />
        </AnimatePresence>
        <div>
          <div className="flex flex-col  rounded-[10px] bg-unusualGray  px-6 mt-2">
            <header className="pb-4  flex flex-row gap-1  py-8 ">
              <div className="flex flex-row gap-2">
                <div class="text-black text-sm font-normal ">Filter bids:</div>

                <div>
                  <select
                    className=" shadow-sm  text-sm focus:outline-none cursor-pointer h-[37px] px-5 py-2.5 rounded-[5px] border border-[#949494] justify-start items-end gap-2 inline-flex md:min-w-[12.5rem]"
                    onChange={(e) => {}}
                  >
                    <option value="">All Closed Market Bids</option>
                    <option value="date">Active Bids</option>
                    <option value="name">Posted Bids</option>
                  </select>
                </div>

                {/*  */}
              </div>
              <div></div>
            </header>

            <div className="w-full  overflow-x-auto  ">
              <div className="flex flex-col gap-2.5 ">
                {data.map((bids, idx) => (
                  <ClosedMarketCard
                    key={idx}
                    id={bids.id}
                    invoiceNumber={bids.invoiceNumber}
                    buyerName={bids.buyerName}
                    invoiceType={bids.invoiceType}
                    lockRate={bids.lockRate}
                    statusOfInvoice={bids.statusOfInvoice}
                    requestAmount={bids.requestAmount}
                    dueDate={bids?.dueDate}
                    earlyPaymentDate={bids.earlyPaymentDate}
                    supplierName={bids.supplierName}
                    onBid={handleStartBid}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {totalPages > 2 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </div>
      </TransitionOpacity>
    </>
  );
};

export default ClosedMarket;
