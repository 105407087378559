import * as Yup from "yup";

export const createTierValidationSchema = Yup.object({
  tierName: Yup.string()
    .required("Tier name is required"),
  
  description: Yup.string()
    .required("Description is required"),

  tierCriteria: Yup.array()
    .required("Criteria is required"),
});
