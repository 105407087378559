import React, { useEffect } from "react";
import RecentPaymentTable from "./Table";
import { funderOverview } from "appstate/collections/collectionSlice";
import { useDispatch, useSelector } from "react-redux";

const Overview = () => {
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const { funderOverviewData } = useSelector((state) => state?.collection);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(funderOverview());
  }, [dispatch]);

  const sections = [
    {
      title: "Open Market",
      titleColor: "#07593D",
      borderColor: "#07593D",
      selectColor: "#07593D",
      data: [
        {
          label: "Total Due Invoices",
          value: funderOverviewData?.openMarketDueInvoices || 0,
        },
        {
          label: "Total Due Amount",
          value:
            formatAmount(funderOverviewData?.openMarketDueAmount) || "0.00",
        },
      ],
    },
    {
      title: "Direct Funding",
      titleColor: "#F08000",
      borderColor: "#F08000",
      selectColor: "#F08000",
      data: [
        {
          label: "Total Due Invoices",
          value: funderOverviewData?.directFundingDueInvoices || 0,
        },
        {
          label: "Total Due Amount",
          value:
            formatAmount(funderOverviewData?.directFundingDueAmount) || "0.00",
        },
      ],
    },
    {
      title: "Closed Market",
      titleColor: "#F08000",
      borderColor: "#F08000",
      selectColor: "#F08000",
      data: [
        {
          label: "Total Due Invoices",
          value: funderOverviewData?.closedMarketDueInvoices || 0,
        },
        {
          label: "Total Due Amount",
          value:
            formatAmount(funderOverviewData?.closedMarketDueAmount) || "0.00",
        },
      ],
    },
    {
      title: "Early Payment Program",
      titleColor: "#07593D",
      borderColor: "#07593D",
      selectColor: "#07593D",
      data: [
        {
          label: "Total Due Invoices",
          value: funderOverviewData?.earlyPaymentDueInvoices || 0,
        },
        {
          label: "Total Due Amount",
          value:
            formatAmount(funderOverviewData?.earlyPaymentDueAmount) || "0.00",
        },
      ],
    },
  ];

  return (
    <div className="w-full ">
      <div className="w-full rounded-md mt-2 bg-white shadow-lg">
        <header className="mt-3 border-b-2 border-[#AEAEAE] flex-shrink-0 bg-white px-7 py-3 rounded-t-md">
          <h2 className="text-[#3E3E3E] font-bold text-[16px]">
            Summary of pending invoices for collection from diverse channels.
          </h2>
        </header>
        <div className="bg-[#EBEBEB]">
          <div className="px-7 py-4 grid md:grid-cols-[35%,64%] gap-5">
            {/* First Grid Section */}
            <section>
              <div className="grid grid-cols-[50%,50%] gap-5 mb-5">
                <div className="bg-[#07593D] py-8 flex-row items-center justify-center text-white rounded-md mb5">
                  <p className="flex items-center justify-center font-bold text-4xl">
                    {funderOverviewData?.totalDueInvoices || 0}
                  </p>
                  <p className="flex items-center justify-center font-normal text-[16px]">
                    Due Invoices
                  </p>
                </div>

                <div className="py-8 bg-secondaryColor flex-row items-center justify-center mb5 rounded-md text-white shadowsm gap3 mr-5">
                  <p className="flex items-center justify-center font-bold text-lg">
                    <span>N </span>
                    {formatAmount(funderOverviewData?.totalDueAmount) || "0.00"}
                  </p>
                  <p className="flex items-center justify-center font-normal mt-3 text-sm">
                    Total Due Amount
                  </p>
                </div>
              </div>

              <div className="p-5 bg-[#07593D] flex flex-col mt5 rounded-bl-lg rounded-br-lg border border-t-[#2fa066] border-t-[2.5px] text-white shadow-sm gap-1">
                <div className="flex justify-end items-center">
                  <select className=" border border-white bg-white text-[#07593D] rounded cursor-pointer w-11">
                    <option value="all">All</option>
                    <option value="1week">1 week</option>
                    <option value="1month">1 month</option>
                    <option value="quarterly">Quarterly</option>
                    <option value="halfyear">Half a year</option>
                    <option value="1year">1 year</option>
                  </select>
                </div>
                <h2 className="font-bold text-xs md:text-base flex mt-1">
                  <span>N </span>
                  {formatAmount(funderOverviewData?.totalAmountCollected) ||
                    "0.00"}
                </h2>
                <h2 className="text-xs font-normal mt-2">
                  Total Amount Collected
                </h2>
              </div>
            </section>

            <section className="grid lg:grid-cols-2 gap-5">
              {sections.map((section, index) => (
                <div
                  key={index}
                  className={`fle justifybetween px-[20px] py-2 itemscenter bg-white rounded-b-md border-t-2`}
                  style={{ borderColor: section.borderColor }}
                >
                  <div className="flex item-center justify-between gap10">
                    <p
                      className={`text-[${section.titleColor}] font-bold text-base`}
                    >
                      {section.title}
                    </p>
                    <select
                      className={`border border-[${section.selectColor}] bg-[${section.selectColor}] text-white rounded px2 cursor-pointer w-11`}
                    >
                      <option value="all">All</option>
                      <option value="1week">1 week</option>
                      <option value="1month">1 month</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="halfyear">Half a year</option>
                      <option value="1year">1 year</option>
                    </select>
                  </div>
                  <div className="flex justify-between gap28 mt-4">
                    {section.data.map((item, idx) => (
                      <div
                        key={idx}
                        className="flex-row items-center justify-end"
                      >
                        <h2
                          className={`text-[${
                            item.label === "Total Due Invoices"
                              ? "#07593D"
                              : "#F08000"
                          }] flex items-center justify-center font-bold text-xl`}
                        >
                          {item.value}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-xs mt-3">
                          {item.label}
                        </h2>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </section>
          </div>
        </div>
      </div>
      <div>
        <RecentPaymentTable />
      </div>
    </div>
  );
};

export default Overview;
