import { sendAsscociateRequestToFunder } from "appstate/supplier/supplierSlice";
import { PAIconUseradd } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import AccountModalContainer from "shared/accountModal/Modal";
import Heading from "../components/Heading";
import BuyerDetailsModal from "../modal";
import { allFunders, funderSearch } from "appstate/funder/funderSlice";
import toast from "react-hot-toast";

function AssociateFunders() {
  const [open, setOpen] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const { allFundersData, isLoading } = useSelector((state) => state?.funder);
  const { associateLoading } = useSelector((state) => state?.supplier);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  //Getting the super id
  const { supplierId } = useSelector((state) => state?.auth);

  const [getId, setGetId] = useState(null);
  useEffect(() => {
    dispatch(allFunders({ page, pageSize }));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    if (companyName.length <= 0) {
      setData(allFundersData?.data);
    }
  }, [allFundersData, companyName]);

  const handleOpenModal = (row) => {
    setGetId(row?.userId);
    setOpen(true);
  };

  const handleAssociate = () => {
    const funderId = getId;

    dispatch(sendAsscociateRequestToFunder(funderId)).then((data) => {
      if (data?.payload?.success === true) {
        toast.success(data?.payload?.message);
        setSuccess(true);
      }
    });
  };

  const onRowClicked = (row) => {
    setOpenDetailsModal(true);
    setGetId(row?.userId);
  };

  const handleSearch = () => {
    dispatch(funderSearch({ companyName })).then((data) => {
      setData(data?.payload?.data);
    });
  };

  /*

  cell: (row) => {
        return (
          <div>
            <Button
              onClick={() => handleOpenModal(row)}
              neutral
              disabled={row?.supplierIds?.includes(supplierId)}
              className="flex w-[157px] h-[44px] border-[1px] border-gray rounded-[5px] justify-center items-center 
               bg-primaryColor"
            >
              {row?.supplierIds?.includes(supplierId) ? (
                <p className="text-sm font-normal text-white ml-[5px]">
                  Aprroved
                </p>
              ) : (
                <>
                  <PAIconUseradd />
                  <p className="text-sm font-normal text-white ml-[5px]">
                    Associate
                  </p>
                </>
              )}
            </Button>
          </div>
        );
      },

  */

  const column = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      width: "15rem",
    },
    {
      name: "Phone",
      selector: (row) => row?.phoneNumber,
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div className=" flex flex-row">
            {/* <Button
              onClick={() => handleOpenModal(row)}
              neutral
              className="flex w-[157px] h-[44px] border-[1px] border-gray rounded-[5px] justify-center items-center 
               bg-primaryColor"
            >
              <PAIconUseradd />
              <p className="text-sm font-normal text-white ml-[5px]">
                Associate
              </p>
            </Button> */}

            <Button
              onClick={() => handleOpenModal(row)}
              neutral
              disabled={row?.supplierIds?.includes(supplierId)}
              className="flex w-[157px] h-[44px] border-[1px] border-gray rounded-[5px] justify-center items-center 
               bg-primaryColor"
            >
              {row?.supplierIds?.includes(supplierId) ? (
                <p className="text-sm font-normal text-white ml-[5px]">
                  Aprroved
                </p>
              ) : (
                <>
                  <PAIconUseradd />
                  <p className="text-sm font-normal text-white ml-[5px]">
                    Associate
                  </p>
                </>
              )}
            </Button>
          </div>
        );
      },
    },
  ];
  const totalPages = allFundersData?.meta?.totalElements;
  return (
    <DashboardLayout section="Accounts">
      <BuyerDetailsModal
        isShown={openDetailsModal}
        onClose={() => setOpenDetailsModal(false)}
        getId={getId}
      />
      <AccountModalContainer
        accontModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        isLoading={associateLoading}
        success={success}
        handleAssociate={handleAssociate}
        warning=" Are you sure you want to associate with this funder."
        info=" The funder will receive an email indicating that you are requesting
        to associate."
      />
      <Card className="rounded-lg">
        <Heading
          setCompanyName={setCompanyName}
          search={handleSearch}
          companyName={companyName}
        />
        <div className="rounded-b-lg px-[24px] pb-[24px]">
          <Table
            columns={column}
            data={data}
            onRowClicked={onRowClicked}
            pointer
            progressPending={isLoading}
            progressComponent={<Loading />}
            tableHeader
            className="mt-5"
          />
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading && totalPages > 10 && (
              <Pagination
                page={page}
                startPage={startPage}
                setEndPage={setEndPage}
                endPage={endPage}
                setStartPage={setStartPage}
                setPage={setPage}
                totalPages={totalPages}
              />
            )}
          </div>
        </div>
      </Card>
    </DashboardLayout>
  );
}

export default AssociateFunders;
