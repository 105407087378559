import request from "apiInstance";
import toast from "react-hot-toast";

export const initiateDisputeResolution = async ({ body, player }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/ticket/${player}/create`,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllDisputeTickets = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/ticket/views-ticket`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
