import { PAIconArrowLeftGray } from "assets/images/svgs";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import DashboardLayout from "layout/dashboardlayout";

const BukOverViewDetails = () => {
  const dummyData = [
    {
      index: 1,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      requestDate: "2023-05-31",
      currency: "NGN",
      invoiceAmount: "9,000,000",
      discountAmount: "234,846",
      discountRate: "2.3%",
    },
    {
      index: 2,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      requestDate: "2023-05-31",
      currency: "NGN",
      invoiceAmount: "9,000,000",
      discountAmount: "234,846",
      discountRate: "2.3%",
    },
    {
      index: 3,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      requestDate: "2023-05-31",
      currency: "NGN",
      invoiceAmount: "9,000,000",
      discountAmount: "234,846",
      discountRate: "2.3%",
    },
    {
      index: 4,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      requestDate: "2023-05-31",
      currency: "NGN",
      invoiceAmount: "9,000,000",
      discountAmount: "234,846",
      discountRate: "2.3%",
    },
    {
      index: 5,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      requestDate: "2023-05-31",
      currency: "NGN",
      invoiceAmount: "9,000,000",
      discountAmount: "234,846",
      discountRate: "2.3%",
    },
  ];

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <DashboardLayout
        section="Closed Market"
        subHeader="View invoices uploaded to the closed market by your associated buyers"
      >
        <div>
          <Link
            className="flex cursor-pointer w-fit gap-1 items-center  mb-6"
            onClick={goBack}
          >
            <PAIconArrowLeftGray />
            <span className=" text-[#7A7A7A] text-sm">Go back</span>
          </Link>
        </div>
        <div className="flex flex-col gap-8  pb-24  bg-white rounded-[10px] ">
          <div className="">
            <div className="flex flex-col gap-3 py-5 bg-[#fafafa]  px-6  rounded-[10px]">
              <header className="flex justify-between items-center">
                <div class="text-[#222222] text-lg font-semibold ">
                  Reference No: R3456X89Y
                </div>

                <button class="h-[41px] px-6 py-3 bg-[#07593d] rounded justify-center items-center gap-2.5 inline-flex">
                  <div class="text-white text-sm font-semibold ">
                    Disburse Now
                  </div>
                </button>
              </header>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div class="text-[#222222] text-base font-semibold">
                    Invoice | INV-2002
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      {/* <div class="text-[#222222] text-xs font-semibold font-['SF Pro Display']">
                        Created Date:{" "}
                      </div> */}
                      <span className=" text-xs  text-[#222222] font-semibold ">
                        Created Date:
                      </span>

                      <span className="text-[#979797]  text-xs ">
                        23-Dec-2024
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs text-[#222222] font-semibold ">
                        Payment Due Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        23-Dec-2024
                      </span>
                    </div>

                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs  text-[#222222] font-semibold ">
                        Early Payment Date:{" "}
                      </span>
                      <span className="text-[#979797]  text-xs">
                        23-Dec-2024
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-between">
                  <div class="h-[67px] flex-col justify-start items-start gap-4 inline-flex">
                    <div class="flex-col justify-start items-end gap-2.5 flex">
                      <div class="justify-start items-start gap-8 inline-flex">
                        <div>
                          <span className="text-[#1d0220] text-base font-bold ">
                            XYZ Ventures -{" "}
                          </span>
                          <span className="text-[#f08000] text-sm font-normal ">
                            (Supplier)
                          </span>
                        </div>
                        <div>
                          <span className="text-[#1d0220] text-base font-bold ">
                            ABC Plc. -{" "}
                          </span>
                          <span className="text-[#f08000] text-sm font-normal ">
                            (Buyer)
                          </span>
                        </div>
                      </div>
                      <div class="h-3.5 justify-center items-end gap-2.5 inline-flex">
                        <div>
                          <span className="text-[#949494] text-sm font-normal ">
                            Total request amounts -{" "}
                          </span>
                          <span className="text-[#222222] text-sm font-normal ">
                            NGN
                          </span>
                          <span className="text-[#949494] text-sm font-normal ">
                            {" "}
                          </span>
                          <span className="text-[#222222] text-sm font-normal ">
                            83,373,000
                          </span>
                        </div>
                      </div>
                      <div class="h-3.5 justify-center items-end gap-2.5 inline-flex">
                        <div>
                          <span className="text-[#949494] text-sm font-normal ">
                            Number of invoices -{" "}
                          </span>
                          <span className="text-[#222222] text-sm font-normal ">
                            5
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>

            <div className="flex flex-col gap-4  mt-8 px-6">
              <div className=" w-full overflow-x-auto">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-[#F9F9FA] w-full border-b-[1px] border-b-[#eeeeee] border-t-[1px] border-t-[#eeeeee] ">
                    <tr className=" bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Invoice Number
                      </td>
                      <td className=" p-2.5 text-xs font-bold">Due Date</td>
                      <td className=" p-2.5 text-xs font-bold">Request date</td>
                      <td className=" p-2.5 text-xs font-bold">Currency</td>
                      <td className=" p-2.5 text-xs font-bold">
                        Invoice Amount{" "}
                      </td>
                      <td className=" p-2.5 text-xs font-bold">
                        Discount Amount
                      </td>
                      <td className=" p-2.5 text-xs font-bold">
                        Discount Rate
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {/*  {
      index: 4,
      ivnNuM: "INV-2090",
      dueDate: "2023-05-23",
      requestDate: "2023-05-31",
      currency: "NGN",
      invoiceAmount: "9,000,000",
      discountAmount: "234,846",
      discountRate: "2.3%",
    }, */}
                    {dummyData.map((item, index) => (
                      // <tbody key={index}>
                      <tr
                        className=" px-1.5  py-16 my-12  border-b-[1px] border-b-[#eeeeee] h-[55px] cursor-pointer"
                        onClick={() =>
                          navigate(
                            `/payablefinancing-closedmarket/funderclosedmarketbuldetailspage/${item.index}`
                          )
                        }
                      >
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.ivnNuM}
                        </td>

                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.dueDate}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.requestDate}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.currency}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.invoiceAmount}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.discountAmount}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.discountAmount}
                        </td>
                      </tr>
                      // </tbody>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default BukOverViewDetails;
