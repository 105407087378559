import { PAIconCancelMini, PAIconWallet } from "assets/images/svgs";
import { motion } from "framer-motion";
function SelectAccountModal({ onClose, onAction }) {
  const handleSubmit = () => {
    onAction();
  };

  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>

        <motion.form
          onSubmit={handleSubmit}
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="bg-white overflow-auto rounded-[10px] min-w-[40%] max-w-[60%] lg:max-w-[47%]  max-h-[80%] lg:max-h-[70%] min-h-[50%] pb-10 flex flex-col gap-1"
        >
          <header className=" px-6  items-center flex bg-[#FAFAFA] py-5  justify-between">
            <span className=" font-bold">Selected account</span>

            <span
              onClick={() => onClose()}
              className="flex cursor-pointer items-center gap-2"
            >
              <PAIconCancelMini /> Close
            </span>
          </header>

          <section className="text-[#5F4D61] text-center px-6 mt-4 mb-4">
            Select which account you want to use for the bid
          </section>

          <section className="px-6 overflow-auto flex flex-col gap-3">
            <header className="text-[#5F4D61]  font-semibold pb-3 italic">
              Accounts
            </header>
            <main className="grid gap-x-5 overflow-auto grid-cols-1 sm:grid-cols-2">
              <motion.div
                whileTap={{ scale: 0.96 }}
                onClick={() => handleSubmit()}
                className="bg-primaryColor mb-6 cursor-pointer flex flex-col gap-1.5 text-white w-full sm:w-fit p-3 rounded-[10px]"
              >
                <div className="flex items-center gap-1.5">
                  <PAIconWallet />
                  <span className=" text-sm">Adewale Favour Adediwura</span>
                </div>

                <div className=" italic font-semibold">7364738921</div>

                <div className="flex justify-between items-center">
                  <span>PayEdge MFB</span>
                  <span className=" text-[10px]">Virtual Account</span>
                </div>
              </motion.div>

              <motion.div
                onClick={() => handleSubmit()}
                whileTap={{ scale: 0.96 }}
                className="bg-primaryColor mb-6 cursor-pointer flex flex-col gap-1.5 text-white w-full sm:w-fit p-3 rounded-[10px]"
              >
                <div className="flex items-center gap-1.5">
                  <PAIconWallet />
                  <span className=" text-sm">Adewale Favour Adediwura</span>
                </div>

                <div className=" italic font-semibold">7364738921</div>

                <div className="flex justify-between items-center">
                  <span>PayEdge MFB</span>
                  <span className=" text-[10px]">Virtual Account</span>
                </div>
              </motion.div>

              <motion.div
                onClick={() => handleSubmit()}
                whileTap={{ scale: 0.96 }}
                className="bg-primaryColor mb-6 cursor-pointer flex flex-col gap-1.5 text-white w-full sm:w-fit p-3 rounded-[10px]"
              >
                <div className="flex items-center gap-1.5">
                  <PAIconWallet />
                  <span className=" text-sm">Adewale Favour Adediwura</span>
                </div>

                <div className=" italic font-semibold">7364738921</div>

                <div className="flex justify-between items-center">
                  <span>PayEdge MFB</span>
                  <span className=" text-[10px]">Virtual Account</span>
                </div>
              </motion.div>
            </main>
          </section>
        </motion.form>
      </motion.div>
    </motion.div>
  );
}

export default SelectAccountModal;
