import RecentPaymentTable from "./Table";

const Overview = () => {
  const sections = [
    {
      title: "Direct Funding",
      titleColor: "#2FA06A",
      borderColor: "#07593D",
      selectColor: "#07593D",
      data: [
        {
          label: "Due Invoices",
          value: 0,
        },
        {
          label: "Due Amount",
          value: "0.00",
        },
      ],
    },
    {
      title: "Open Market",
      titleColor: "#2FA06A",
      borderColor: "#07593D",
      selectColor: "#07593D",
      data: [
        {
          label: "Due Invoices",
          value: 0,
        },
        {
          label: "Due Amount",
          value: "0.00",
        },
      ],
    },
    {
      title: "Early Payment Program",
      titleColor: "#F08000",
      borderColor: "#F08000",
      selectColor: "#F08000",
      data: [
        {
          label: "Due Invoices",
          value: 0,
        },
        {
          label: "Due Amount",
          value: "0.00",
        },
      ],
    },

    {
      title: "Closed Market",
      titleColor: "#F08000",
      borderColor: "#F08000",
      selectColor: "#F08000",
      data: [
        {
          label: "Due Invoices",
          value: 0,
        },
        {
          label: "Due Amount",
          value: "0.00",
        },
      ],
    },
  ];

  return (
    <div className="w-full">
      <div className="w-full rounded-b-lg  shadow-lg">
        <header className="mt-3 border-b-2 border-[#AEAEAE] flex-shrink-0 bg-white px-7 py-4 rounded-t-md">
          <h2 className="text-[#3E3E3E] font-bold text-[16px]">
            Summary of pending invoices for collection from diverse channels.
          </h2>
        </header>
        <div className="bg-[#EBEBEB] pb-5 rounded-b-lg mb-4">
          <div className="px-7 py-4 grid xl:grid-cols-[35%,64%] gap-5">
            {/* First Grid Section */}
            <section className="flex flex-col justify-between">
              <div className=" bg-primaryColor p-10 flex-row items-center justify-center text-white rounded-md">
                <p className="flex items-center justify-center font-bold text-4xl">
                  135{/* {funderOverviewData?.totalDueInvoices || 0} */}
                </p>
                <p className="flex items-center justify-center font-normal text-[16px]">
                  Total Due Invoices
                </p>
              </div>

              <div className="flex justify-between ">
                <div className="p-5 bg-[#3A455B] flex flex-col items-center justify-center mt-5 rounded-b-[10px] rounded-tr-[10px] text-white shadow-sm gap-3 w-[48%] ">
                  <span className="font-bold md:text-base xl:text-[20px]">
                    <span>N </span> 1,677,360
                    {/* {formatAmount(funderOverviewData?.totalDueAmount) || "0.00"} */}{" "}
                  </span>
                  <span className=" text-[13px] font-normal">
                    Total Due Amount
                  </span>
                </div>

                {/*  */}

                <div className="py-[42px] px-5 bg-secondaryColor flex flex-col items-center justify-center mt-5 rounded-bl-[10px] rounded-t-[10px] relative text-white shadow-sm gap-3 w-[48%] ">
                  <div className="flex absolute bottom-[76%] text-xs left-[65%] justify-end items-center">
                    <select className=" border border-white bg-white outline-none text-[#07593D] rounded cursor-pointer">
                      <option value="all">All</option>
                      <option value="1week">Jan</option>
                      <option value="1month">Feb</option>
                      <option value="quarterly">Mar</option>
                      <option value="halfyear">Apr</option>
                      <option value="1year">May</option>
                    </select>
                  </div>
                  <span className="font-bold md:text-base xl:text-[20px]">
                    <span>N </span> 1,677,360
                    {/* {formatAmount(funderOverviewData?.totalDueAmount) || "0.00"} */}{" "}
                  </span>
                  <span className=" text-[13px] whitespace-nowrap font-normal">
                    Total Amount Disbursed
                  </span>
                </div>
              </div>
            </section>

            <section className="grid lg:grid-cols-2 gap-5">
              {sections.map((section, index) => (
                <div
                  key={index}
                  className={`fle justifybetween p-6 itemscenter bg-white rounded-b-md border-t-2`}
                  style={{ borderColor: section.borderColor }}
                >
                  <div className="flex item-center justify-between gap10">
                    <p
                      className={`text-[${section.titleColor}] font-bold text-base`}
                    >
                      {section.title}
                    </p>
                    <select
                      className={`border border-[${section.selectColor}] bg-[${section.selectColor}] text-xs outline-none text-white rounded px2 cursor-pointer`}
                    >
                      <option value="all">All</option>
                      <option value="1week">1 week</option>
                      <option value="1month">1 month</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="halfyear">Half a year</option>
                      <option value="1year">1 year</option>
                    </select>
                  </div>
                  <div className="flex justify-between gap28 mt-4">
                    {section.data.map((item, idx) => (
                      <div
                        key={idx}
                        className="flex-row items-center justify-end"
                      >
                        <h2
                          className={`text-[${
                            item.label === "Due Invoices"
                              ? "#07593D"
                              : "#F08000"
                          }] flex items-center justify-center font-bold text-2xl`}
                        >
                          {item.value}
                        </h2>
                        <h2 className="flex items-center justify-center font-normal text-[#565656] text-xs mt-3">
                          {item.label}
                        </h2>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </section>
          </div>
        </div>
      </div>
      <div>
        <RecentPaymentTable />
      </div>
    </div>
  );
};

export default Overview;
