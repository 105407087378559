import { PAIconArrowRight, PAIconCheck } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import OnboardingLayout from "../category/components/OnboardingLayout";
const MemberGetstarted = () => {
  const navigate = useNavigate();
  const handleSkip = () => {
    navigate("/dashboard");
  };
  const handleAddBankDetails = () => {
    let path = "/member/banking-details";
    navigate(path);
  };
  const icon = <PAIconCheck />;
  const getStatedDatas = [
    {
      id: 1,
      title: "Setup your receiving bank account",
      info: "Add a bank account so that you can receive payments from your buyers.",
      settings: "Go to bank settings",
      icon: icon,
      click: () => {
        handleAddBankDetails();
      },
    },
    {
      id: 2,
      title: "Review and accept funding agreement ",
      info: "Please review the funding agreement",
      settings: "Review funding agreement",
      icon: icon,
    },
    {
      id: 3,
      title: "Complete KYC",
      info: "Upload the required information for verification ",
      settings: "Upload documents",
      icon: icon,
    },
  ];
  return (
    <OnboardingLayout>
      <div className="pt-[60px] px-[20px] h-[100%] overflow-auto pb-[30px] md:flex flex-col items-center md:px-0">
        <div className="mb-[50px] mt-[30px] flex justify-center items-center gap-x-[20px] lg:gap-x-[50px]">
          <div>
            <div className="w-[100px] h-[2px] bg-primaryColor rounded-[100px] md:w-[220px]"></div>
          </div>
          <div>
            <div className="w-[100px] h-[2px]  bg-primaryColor rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[13px] md:bottom-[15px]">
            <p className="font-normal text-xs text-textColor mb-[10px] md:text-sm">
              Get started
            </p>
            <div className="w-[100px] h-[2px] bg-primaryColor rounded-[100px] md:w-[220px]"></div>
          </div>
        </div>
        <h1 className="font-semibold text-2xl text-center text-primaryColor mb-[30px]">
          Let’s get started
        </h1>
        <div className=" pb-[20px] rounded-[5px] border border-gray500 md:w-[722px]">
          <div className="h-[47px] flex items-center pl-[30px] bg-gray500">
            <p className="font-semibold text-sm text-primaryColor">
              Complete Setup
            </p>
          </div>
          <div className="h-[47px]  flex items-center pl-[30px] border-b border-gray500">
            <p className="font-semibold text-sm text-textColor">
              Before you start using Supplier Pay, please complete the following
              steps.
            </p>
          </div>
          <div className="px-[30px]">
            {getStatedDatas.map((data) => (
              <div
                key={data.id}
                className={`pt-[30px] md:flex justify-between pb-[20px] ${
                  data.id !== 4 && "border-b"
                } border-[#86888B]`}
              >
                <div className="flex">
                  <span className="mr-[20px]">{data.icon}</span>
                  <div>
                    <p className="font-semibold text-xs mb-[10px]">
                      {data.title}
                    </p>
                    <p className="font-normal text-xs md:w-[310px]">
                      {data.info}
                    </p>
                  </div>
                </div>
                <div
                  onClick={data.click}
                  className="w-[179px] h-[31px] bg-[#F0EFF1] rounded-[5px] flex items-center cursor-pointer mt-[20px]"
                >
                  <p className="font-medium text-[10px] w-[160px] pl-[5px]">
                    {data.settings}
                  </p>
                  <PAIconArrowRight />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="underline text-primaryColor font-medium text-xs flex
          gap-x-[6.33px] items-center mt-[33px] hover:cursor-pointer"
          onClick={handleSkip}
        >
          Continue to Dashboard
          <PAIconArrowRight className="w-[16px]" />
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default MemberGetstarted;
