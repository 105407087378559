import * as Yup from 'yup';

export const externalAccountValidationSchema = Yup.object({
  currency: Yup.string().required('This field is required'),
  // accountName: "Yup.string().required('This field is required')",
  accountHolderName: "",
  bankName: Yup.string().required('This field is required').matches(/^[a-zA-Z]+$/, 'Must only contain alphabetical characters'),
  accountNumber: Yup.number().required('This field is required').test('len', 'Must be exactly 10 digits', val => val && val.toString().length === 10),
  bvn: Yup.number().required('This field is required').test('len', 'Must be exactly 11 numbers', val => val && val.toString().length === 11),
});
