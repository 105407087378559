import RoleManagerAccounts from "features/roleManager/Accounts";

const RoleManagerAccountsPage = () => {
  return (
    <>
      <RoleManagerAccounts />
    </>
  )
}

export default RoleManagerAccountsPage;