import { PAIconMIDelete, PAIconTablerEdit } from "assets/images/svgs";
import React, { useState } from "react";

const Edit = () => {
  const [associateText, setAssociateText] = useState("Associate with supplier");
  const [username, setUsername] = useState("Collins Eze");
  const [requestMessage, setRequestMessage] = useState(
    "Jenny Wilson of Dangote has requested to associate with you. Click the “Accept Request” button if you would like to associate and the “Decline Request” button if you would not like to associate with them."
  );

  const [iconSrc, setIconSrc] = useState("/PayAssyst-01.svg");
  const [imageSrc, setImageSrc] = useState("/happy_announcement.png");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setIconSrc(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleImageDeleteClick = () => {
    setImageSrc("/happy_announcement.png");
  };

  return (
    <div className="h-[80vh] flex items-center justify-center mt-5">
      <div className="bg-white shadow-md px-10 pb-5 text-center md:w-1/2">
        <div className="mb-5 flex items-center justify-center">
          <img
            src={iconSrc}
            alt="Icon"
            className="border border-dashed py1 border-[#367CFF] w24 h16"
          />
          <label htmlFor="fileInput" className="cursor-pointer">
            <PAIconTablerEdit className="w-3 mb-3" />
            <PAIconMIDelete className="w-3" onClick={() => setIconSrc("/PayAssyst-01.svg")} />
          </label>
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            className="hidden"
            onChange={handleFileChange}
          />
        </div>
        <div
          className="p-3 bg-[#07593D] text-white font-rocknroll-one text-base font-normal"
          contentEditable={true}
          onBlur={(e) => setAssociateText(e.target.textContent.trim())}
        >
          <p className="border border-dashed border-[#367CFF]">
            {associateText}
          </p>
        </div>
        <div className="flex items-center justify-center mt-7">
          <img
            src={imageSrc}
            alt="/"
            className="border border-dashed py-2 border-[#367CFF]"
          />
          <label htmlFor="imageFileInput" className="cursor-pointer">
            <PAIconTablerEdit className="w-3 mb-3" />
            <PAIconMIDelete className="w-3" onClick={handleImageDeleteClick} />
          </label>
          <input
            type="file"
            id="imageFileInput"
            accept="image/*"
            className="hidden"
            onChange={(e) => setImageSrc(URL.createObjectURL(e.target.files[0]))}
          />
        </div>
        <div className="mb-2">
          <p
            className="text-black font-sans font-extrabold text-xs my-5 text-left border border-dashed py-2 border-[#367CFF] w-1/2"
            contentEditable={true}
            onBlur={(e) => setUsername(e.target.textContent.trim())}
          >
            {username}
          </p>
          <p
            className="text-[#565656] font-sans italic font-extrabold text-xs text-left border border-dashed py-2 border-[#367CFF]"
            contentEditable={true}
            onBlur={(e) => setRequestMessage(e.target.textContent.trim())}
          >
            {requestMessage}
          </p>
        </div>
        <div className="pb-5 ">
          <button className="px-4 py-2 rounded-lg text-white font-custom text-xs font-medium bg-[#F08000] hover:opacity-90 transition">
            Accept Request
          </button>
          <button className="px-4 py-2 text-red-500 font-sans font-medium text-xs rounded-lg  border border-[#E64D4D] hover:opacity-90 transition ml-5">
            Decline Request
          </button>
        </div>
        <p className="text-black font-custom text-xs font-normal">
          The PayEdge Team
        </p>
      </div>
    </div>
  );
};

export default Edit;
