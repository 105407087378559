import * as Yup from "yup";
// Custom validation method for companyNumber
const numberLength = (countryCode, min, max) => {
  return Yup.string()
    .matches(/^(\+)?\d+$/, "Mobile number should contain digits only.")
    .test("len", function (val) {
      const digitLength = val ? val.replace(/^\+/, "").length : 0;
      if (countryCode === "+234" && digitLength !== 10) {
        return this.createError({
          message: "Please enter a valid Nigerian mobile number.",
        });
      }
      if (digitLength < min || digitLength > max) {
        return this.createError({
          message: `Please enter a valid mobile number between ${min} and ${max} digits.`,
        });
      }
      return true;
    });
};

// Custom validation method for rcNumber
const validateRCNumber = (rcNumber) => {
  const rcNumberPattern = /^[A-Z0-9-]{7,12}$/; // Adjust length as needed for specific countries
  return rcNumberPattern.test(rcNumber);
};

// Custom validation method for companyTaxId
const validateTIN = (tin) => {
  const tinPattern = /^[0-9]{10,12}$/; // Adjust length based on specific countries

  if (!tinPattern.test(tin)) {
    return false;
  }

  return true;
};

export const companyDetailsValidationSchema = (countryCode) =>
  Yup.object({
    companyName: Yup.string().required("This field is required"),
    rcNumber: Yup.string()
      .required("This field is required")
      .test("valid-rcNumber", "Invalid RC number format", validateRCNumber),
    companyWebsite: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Please enter a valid URL"
      )
      .required("This field is required"),
    email: Yup.string().email("invalid Email").required("Email is Required"),
    phoneNumber: numberLength(countryCode, 10, 15).required(
      "Mobile number is required."
    ),
    registeredAddress: Yup.string().required("This field is required"),
    companyTaxId: Yup.string()
      .required("This field is required")
      .test("valid-tin", "Invalid TIN format", validateTIN),
  });
