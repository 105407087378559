import React, { useState } from "react";
import { PAIconThickArrowDown } from "assets/images/svgs";

const MultiSelectDropdown = ({
  options,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option.toUpperCase()]);
    }
  };

  return (
    <div className="relative inline-block w-[300px] md:w-[424px]">
      <label
        htmlFor=""
        className="font-normal text-xs text-textColor relative bottom-[10px]"
      >
        Industry
      </label>
      <div
        className="flex items-center appearance-none w-[300px] text-xs md:w-[424px] min-h-10 py-1 pr-8 leading-tight text-gray-500 bg-white border-b border-gray-600 outline-none focus:outline-none cursor-pointer"
        onClick={toggleDropdown}
      >
        {selectedOptions.length === 0 ? (
          <span className="text-gray-500">Select options</span>
        ) : (
          <div className="flex text-xs flex-wrap gap-2">
            {selectedOptions.map((option) => (
              <span
                key={option}
                className="bg-blue-500 text-xs text-white px-2 py-1 rounded-lg"
              >
                {option}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="absolute inset-y-0 right-0 flex items-center px-2 top-[30px] pointer-events-none">
        {/* <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
          <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
        </svg> */}
        <PAIconThickArrowDown />
      </div>
      {isDropdownOpen && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg text-sm mt-1 max-h-60 overflow-auto">
          {options.map((option) => (
            <li
              key={option}
              className={`p-2 cursor-pointer hover:bg-blue-100 ${
                selectedOptions.includes(option) ? "bg-blue-200" : ""
              }`}
              onClick={() => handleSelectOption(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
