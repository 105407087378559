import DashboardLayout from "layout/dashboardlayout";
import TopContestedInvoices from "./TopContestedInvoices";

const TopContestedInvoiceDetail = () => {
  return (
    <>
      <DashboardLayout
        section={"Closed Market"}
        subHeader={"Manage your invoices on the closed market"}
      >
        <TopContestedInvoices />
      </DashboardLayout>
    </>
  );
};

export default TopContestedInvoiceDetail;
