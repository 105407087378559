import React from 'react';

const Bar = ({ cardBar }) => {
  return (
    <div
      className={
        cardBar
          ? 'w-[170px] h-[10px] bg-secondaryColor md:w-[297px]'
          : 'w-[170px] h-[10px] bg-secondaryColor'
      }
    ></div>
  );
};

export default Bar;
