import TransitionOpacity from "shared/animation/TransitionOpacity";
import AllInvoicesCard from "./cards/AllInvoicesCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllSupplierOpenMarket } from "appstate/openMarket/openMarketSlice";
import LoadingScreen from "shared/LoadingScreen";
import EmptyPage from "features/roleManager/Dashbaord/components/EmptyPage";
import { PAIconSearchGreen } from "assets/images/svgs";
import { motion } from "framer-motion";

function AllInvoices({ allInvoiceQuery }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSupplierOpenMarket());
  }, [dispatch]);

  const { isLoading, getAllSupplierOpenMarketData } = useSelector(
    (state) => state?.openMarket
  );

  useEffect(() => {
    dispatch(
      getAllSupplierOpenMarket({
        search: allInvoiceQuery,
      })
    );
  }, [dispatch, allInvoiceQuery]);

  // console.log(getAllSupplierOpenMarketData);

  return (
    <TransitionOpacity className="flex flex-col gap-5 min-h-[75vh] rounded-b-[10px] bg-unusualGray  pb-6">
      <div className="flex bg-white py-5 px-6 justify-between items-center w-full">
        <div className="flex items-center gap-2.5">
          <span className="py-2.5 text-sm">Filter Request Status: </span>

          <select className="text-sm font-medium border border-[#959595] rounded-[5px] px-5 py-2.5 outline-none cursor-pointer">
            <option value="" key="">
              All Requests
            </option>
            <option value="" key="">
              Posted Requests
            </option>
            <option value="" key="">
              Bid Active Requests
            </option>
          </select>
        </div>
        <div className="flex w-[35%]  items-center gap-3">
          <div className="flex w-full items-center gap-4">
            <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
              <PAIconSearchGreen />
              <input
                type="text"
                placeholder="Search for invoices on the open market"
                // valdivue={searchQuery}
                // onChange={handleSearchInputChange}
                className="px-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
              />
            </div>
          </div>

          <motion.button
            whileTap={{ scale: 0.94 }}
            className="py-2.5 text-white text-sm px-6 rounded-[5px] bg-[#2FA06A]"
          >
            Search
          </motion.button>
        </div>
      </div>
      <div className="w-full px-6 font-medium text-sm text-end text-primaryColor justify-end items-center">
        Days:Hours:Mins:Secs
      </div>
      {isLoading ? (
        <LoadingScreen />
      ) : getAllSupplierOpenMarketData?.data?.length > 0 ? (
        <div className="flex px-6 flex-col gap-4 ">
          {/* {getAllSupplierOpenMarketData?.data?.map((invoice) => (
            <AllInvoicesCard
              key={invoice?.invoiceId}
              id={invoice?.invoiceId}
              invoiceNumber={invoice?.invoiceReference}
              buyerCompanyName={invoice?.buyerCompanyName}
              invoiceAmount={invoice?.invoiceAmount}
              invoiceDueDate={invoice?.invoiceDueDate}
              status={invoice?.status}
            />
          ))} */}

          <AllInvoicesCard
            key={getAllSupplierOpenMarketData?.data?.[0]?.invoiceId}
            id={getAllSupplierOpenMarketData?.data?.[0]?.invoiceId}
            invoiceNumber={
              getAllSupplierOpenMarketData?.data?.[0]?.invoiceReference
            }
            buyerCompanyName={
              getAllSupplierOpenMarketData?.data?.[0]?.buyerCompanyName
            }
            invoiceAmount={
              getAllSupplierOpenMarketData?.data?.[0]?.invoiceAmount
            }
            invoiceDueDate={
              getAllSupplierOpenMarketData?.data?.[0]?.invoiceDueDate
            }
            status={getAllSupplierOpenMarketData?.data?.[0]?.status}
          />

          <AllInvoicesCard
            key={getAllSupplierOpenMarketData?.data?.[1]?.invoiceId}
            id={getAllSupplierOpenMarketData?.data?.[1]?.invoiceId}
            invoiceNumber={
              getAllSupplierOpenMarketData?.data?.[1]?.invoiceReference
            }
            buyerCompanyName={
              getAllSupplierOpenMarketData?.data?.[1]?.buyerCompanyName
            }
            invoiceAmount={
              getAllSupplierOpenMarketData?.data?.[1]?.invoiceAmount
            }
            invoiceDueDate={
              getAllSupplierOpenMarketData?.data?.[1]?.invoiceDueDate
            }
            status={getAllSupplierOpenMarketData?.data?.[1]?.status}
          />
        </div>
      ) : (
        <EmptyPage />
      )}
    </TransitionOpacity>
  );
}

export default AllInvoices;
