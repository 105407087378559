import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import { useSelector } from "react-redux";
import FunderCollectionDashboard from "./funder/collectionDashboard";
import SupplierCollectionDashboard from "./supplier/collectionDashboard";

const CollectionDashboard = () => {

  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const invoicePlayer =
    userRole === "funder" ? (
      <FunderCollectionDashboard />
    ) : userRole === "supplier" ? (
      <SupplierCollectionDashboard />
    ) : (
      <></>
    );
  return (
    <DashboardLayout
      section="Collections"
      subHeader="Track invoices due for payment collection"
    >
      {invoicePlayer}
    </DashboardLayout>
  );
};

export default CollectionDashboard;
