import React from "react";
import {
  PAIconSmallCoke,
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconSmallShell,
  PAIconSmallTrust,
} from "assets/images/svgs";

const AddModalComponent = ({ onBoxChange, boxChecked, companyName }) => {
  return (
    <div class="w-full h-[59px] py-6 border-b-[1.5px] border-stone-300 justify-start items-center gap-6 inline-flex">
      <div>
        <input
          type="checkbox"
          onChange={onBoxChange}
          checked={boxChecked}
          style={{ height: 20, width: 20 }}
          color="#F08000"
          class="checked:bg-blue-500 bg-black cursor-pointer"
        />
      </div>

      <div class="justify-start items-center gap-3 flex">
        {companyName === "Nestlé Alimentana SA" ? (
          <PAIconSmallNestle style={{ height: 40, width: 40 }} />
        ) : companyName === "Coca-Cola Company" ? (
          <PAIconSmallCoke style={{ height: 40, width: 40 }} />
        ) : companyName === "Dangote" ? (
          <PAIconSmallDangote style={{ height: 40, width: 40 }} />
        ) : companyName === "Piggyvest PLC." ? (
          <PAIconSmallTrust style={{ height: 40, width: 40 }} />
        ) : (
          <PAIconSmallShell style={{ height: 40, width: 40 }} />
        )}
        <div class="flex-col justify-start items-start gap-[7px] inline-flex">
          <div class="text-zinc-900 text-sm font-normal ">{companyName}</div>
        </div>
      </div>
    </div>
  );
};

export default AddModalComponent;
