import { motion } from "framer-motion";
export default function ViewFundInvoiceModal({ children }) {
  return (
    <motion.div className="overflow-auto">
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>
        <motion.div
          onClick={(e) => e.stopPropagation()}
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-container overflow-auto bg-white  rounded-[10px] shadow-lg z-50"
        >
          <div className="px-6 py-[30px]">{children}</div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
