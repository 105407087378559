import request from "apiInstance";
import toast from "react-hot-toast";

export const getSupplierOverview = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/collections/supplier/overview",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getFunderOverview = async () => {
    try {
      const response = await request({
        method: "get",
        url: "/collections/funder/overview",
      });
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message + "!");
    }
  };

// export const getAllRecievables = async ({ pageSize, page }) => {
//   try {
//     const response = await request({
//       method: "get",
//       url: `/collections/receivables?${pageSize}&${page}`,
//     });
//     return response?.data;
//   } catch (error) {
//     toast.error(error?.response?.data?.message + "!");
//   }
// };
export const getAllRecievables = async ({ pageSize, page, search }) => {
    try {
      const response = await request({
        method: "get",
        url: `/collections/receivables?size=${pageSize}&page=${page}&search=${search || ""}`,
      });
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message + "!");
    }
  };
  

export const getAllTransactions = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/collections/transactions?${page}&${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const sendRemainder = async ({type, body}) => {
    try {
      const response = await request({
        method: 'post',
        url: `/collections/send-reminder?type=${type}`,
        data: body, 
      });
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message + '!');
    }
  };
