// formDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const earlyPaymentFormSlice = createSlice({
  name: "earlyPaymentFormData",
  initialState: {
    name: "",
    conditions: [],
    actions: [],
  },
  reducers: {
    updateField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    clearFormData: (state) => {
      // Reset form data to initial state
      return {
        name: "",
        conditions: [],
        actions: [],
      };
    },
  },
});

export const { updateField, clearFormData } = earlyPaymentFormSlice.actions;
export const selectFormData = (state) => state.earlyPaymentFormData;
export default earlyPaymentFormSlice.reducer;
