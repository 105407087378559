import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteNotification } from "appstate/api/notification";

const initialState = {
  isLoading: false,
};

export const notificationDelete = createAsyncThunk(
  "notificationDelete",
  deleteNotification
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(notificationDelete.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(notificationDelete.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(notificationDelete.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default notificationSlice.reducer;
