const RadioCard = ({
  title,
  description = `Our competitive offer signifies our dedication to fostering a fruitful
          collaboration. We look forward to your consideration. Thank you.`,
  isSelected,
  onSelect,
}) => {
  return (
    <label
      className={`flex flex-col gap-2 border  rounded-[10px] p-4 cursor-pointer transition duration-300 ${
        isSelected
          ? "border-primaryColor border-[2px] bg-opacity-5 bg-green-300"
          : "border-gray-300 hover:border-gray-500"
      }`}
    >
      <div className=" flex justify-between">
        <span
          className={`${
            isSelected ? "text-primaryColor" : "text-secondaryColor"
          } font-bold`}
        >
          {title}
        </span>
        <input
          type="radio"
          // className="hidden"
          checked={isSelected}
          onChange={onSelect}
        />
      </div>

      <div className="flex justify-between">
        <div className="py-2.5 flex flex-col gap-2">
          <div className="text-[10px] text-[#959595]">Discount rate</div>
          <div className="text-xs">1.5%</div>
        </div>

        <div className="py-2.5 flex flex-col gap-2">
          <div className="text-[10px] text-[#959595]">Discount Amount</div>
          <div className="text-xs">N234,560</div>
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="text-[10px] text-[#959595]">Note from Funder</div>
        <div className="text-xs">{description}</div>
      </div>
    </label>
  );
};

export default RadioCard;
