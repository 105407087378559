import React, { useState, useEffect } from "react";
import { PAIconCancel, PAIconInfo } from "assets/images/svgs";
import Loading from "shared/Loading";

const BulkInvoicePayment = ({ isOpen, onClose, onConfirm }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      const dummyData = [
        {
          funderId: 1,
          buyerCompanyName: "Company A",
          invoiceReference: "INV-001",
          invoiceDate: "2024-05-24",
          dueDate: "2024-05-24",
          invoiceAmount: 1000.0,
        },
        {
          funderId: 2,
          buyerCompanyName: "Company B",
          invoiceReference: "INV-002",
          invoiceDate: "2024-05-25",
          dueDate: "2024-05-24",
          invoiceAmount: 1500.0,
        },
      ];
      setData(dummyData);
      setIsLoading(false);
    }, 1000);
  }, []);

  const handlePayment = () => {
    // setIsAcceptModalOpen(true);
    onConfirm();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
        <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center max-w-[600px] w-[500px]">
          {/* Header */}
          <header className="flex justify-between border-b border-[#F6F5FA] w-full">
            <h1 className="text-textColor text-base">Invoice Disbursement</h1>
            <div
              onClick={onClose}
              className="flex items-center gap-x-[5px] cursor-pointer"
            >
              <PAIconCancel className="w-[8px]" />
              <p className="text-gray-800 text-sm font-medium">Close</p>
            </div>
          </header>

          {/* Sub Header */}
          <div className="flex justify-between items-center w-full mt-3">
            <div className="text-textColor text-base font-extrabold">
              Bulk Payment Request <br />
              <span className="text-sm text-[#7A7A7A]">
                78 invoices | Agba dev
              </span>
            </div>
            <div
              className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px] w-32 text-center"
              onClick={handlePayment}
            >
              Make Payment
            </div>
          </div>

          {/* Payment Amount */}
          <div className="flex justify-between items-start w-full mt-3">
            <div>
              <h1 className="text-textColor text-base">
                Payment Amount
              </h1>
              <h1 className="text-[#07593D] font-medium text-xl">
                NGN 1,890,400
              </h1>
            </div>
            <div className="flex flex-col items-end">
              <div>
                <p className="text-textColor text-xs">
                  Total Invoice Amount:{" "}
                  <span className="text-primaryColor text-xs font-extrabold border-b border-[#AFAFAF] pb-[2px]">
                    1000.00
                  </span>
                </p>
              </div>
              <div className="flex flex-col items-end">
                <p className="text-textColor text-xs font-medium border-b border-[#AFAFAF] w-[84px] mt-2">
                  Discount: <span className="text-[#17191C] text-xs">0.05</span>
                </p>
                <div className="flex items-center mt-[2px]">
                  <PAIconInfo />
                  <p className="text-[#F08000] text-[10px] font-medium">
                    Tap to view more
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Supplier/Buyer */}
          <div className="w-full mt-3">
            <header className="border-b-[5px] border-[#F6F5FA] pb-2">
              <h1 className="text-[18px] font-bold">
                Buyer Bulk Invoice details
              </h1>
            </header>
            <div className="flex  justify-between my-3">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="font-bold text-sm">Supplier</span>
                  <span className="text-[#979797] text-xs">
                    ABC Cooperation
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="font-bold text-sm">Buyer</span>
                  <span className="text-[#979797] text-xs">
                    buyer name here
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="flex flex-col items-center w-full">
            <div className="w-full">
              {isLoading ? (
                <div className="flex items-center justify-center w-full h-[200px]">
                  <Loading />
                </div>
              ) : (
                <div className="w-full overflow-x-auto">
                  <table className="w-full overflow-x-auto">
                    <thead className="bg-subtleGray">
                      <tr className="bg-unusualGray p2.5">
                        <td className="text-[#11192A] p2.5 text-xs font-bold">
                          Invoice Reference
                        </td>
                        <td className="p2.5 text-xs font-bold">Buyer</td>
                        <td className="p2.5 text-xs font-bold">Invoice Date</td>
                        <td className="p2.5 text-xs font-bold">Due Date</td>
                        <td className="p2.5 text-xs font-bold">
                          Invoice amount
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index} className="py-[3px] px1.5">
                          <td className="text-xs text-[#11192A] py-2.5">
                            {item.invoiceReference}
                          </td>
                          <td className="text-xs text-[#11192A] py-2.5">
                            {item.buyerCompanyName}
                          </td>
                          <td className="text-xs text-[#11192A] py-2.5">
                            {item.invoiceDate}
                          </td>
                          <td className="text-xs text-[#11192A] py-2.5">
                            {item?.dueDate}
                          </td>
                          <td className="text-xs text-[#11192A] py-2.5">
                            {item?.invoiceAmount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkInvoicePayment;
