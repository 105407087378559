import React, { useEffect } from "react";
import FunderOpenmarket from "./components/funder_openmarket";
import DashboardLayout from "layout/dashboardlayout";
import FunderInvoice from "./components/funder_invoice";
import FunderCard from "./components/funder_card";
import FunderTable from "./components/funder_table";
import OverviewHeader from "shared/overviewHeader";

const FunderDashboard = () => {
  useEffect(() => {
    // Clean up sessionStorage if necessary
    sessionStorage.removeItem("activeTab");
    sessionStorage.removeItem("searchQuery");
    sessionStorage.removeItem("activeTabSupplier");
    sessionStorage.removeItem("searchQuerySupplier");
  }, []);
  return (
    <DashboardLayout subHeader='Here is what is happening in your finance'>
      <OverviewHeader />
      <div className='w-full flex flex-wrap flex-col-reverse md:flex-row'>
        {/* INVOICE */}
        <section className='w-full md:w-1/2'>
          <FunderOpenmarket />

          <FunderInvoice />
        </section>

        {/* SECOND SECTION */}
        <section className='w-full md:w-1/2'>
          <div className='flex-1 items-center p4 pt-5 md:pb-6'>
            <div className='flex md:flex-row md:items-center items-start md:justify-end justify-between lg:mb-4 mb4 md:mb0 md:ml-4'>
              <button className='rounded-md border border-green-800 flex items-center lg:p-2 p-2 gap-2 text-green-800 mr-5 transition duration-300 ease-in-out transform hover:scale-105'>
                <img src='/green-acct.png' alt='#' />
                <span className='hidden md:inline'>Manage Suppliers</span>
                <span className='md:hidden text-sm'>Suppliers</span>
              </button>
              <button className='rounded-md bg-green-800 flex items-center lg:p-2 p-2 gap-2 text-white transition duration-300 ease-in-out transform hover:scale-105'>
                <img src='/white-acct.svg' alt='#' />
                <span className='hidden md:inline'>Manage Buyers</span>
                <span className='md:hidden text-sm'>Buyers</span>
              </button>
            </div>
          </div>

          <FunderCard />
          <FunderTable />
        </section>
      </div>
    </DashboardLayout>
  );
};

export default FunderDashboard;
