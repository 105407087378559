import {
  PAIconCocacola,
  PAIconDownload,
  PAIconModelOne,
} from "assets/images/svgs";

import { motion } from "framer-motion";

function InvoiceDetails({ setLockModalOpen, invoiceByIdData }) {
  return (
    <motion.section
      initial={{
        x: 20,
      }}
      animate={{
        x: 0,
      }}
      exit={{
        x: -20,
      }}
      transition={{
        delay: 0.6,
      }}
      className="flex flex-col gap8 px-6 pt-10 pb-24 w-full semi-lg:w-[55%] bg-white rounded-[10px]"
    >
      <div className="mb-2 flex flex-col gap-3 ">
        <header>
          <h1 className=" text-[18px] font-bold">
            Reference No | {invoiceByIdData?.data?.invoiceNumber || "R3456X89Y"}
          </h1>
        </header>

        <main className=" w-full flex justify-between">
          <div className="flex flex-col gap-3 w-1/2">
            <div className="flex justify-between">
              <span className=" font-medium">Invoice | INV-2002</span>
              {/* <span>{invoiceByIdData?.data?.invoiceReference}</span> */}
            </div>

            <div className="flex flex-col gap-1">
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">Created Date:</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.createdDate.slice(0, 10) ||
                    "23-Dec-2024"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className=" font-semibold text-xs">
                  Payment Due Date:
                </span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.dueDate.slice(0, 10) || "23-Dec-2024"}
                </span>
              </div>

              <div className="flex justify-between">
                <span className=" font-semibold text-xs">
                  Early Payment Date
                </span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.dueDate.slice(0, 10) || "23-Dec-2024"}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-between">
            <span className="flex justify-end text-xs text-[#F08000]">
              {invoiceByIdData?.data?.status || "Approved"}
            </span>
          </div>
        </main>
      </div>

      <div className="">
        <div className="flex flex-col items-end ">
          <span className=" font-bold text-lg">N23,000,000</span>
          <span className="text-[#979797]  text-xs">Request Amount</span>
        </div>
      </div>

      <div className="flex mb-8 justify-between">
        <div className="flex flex-col">
          <span className=" font-bold text-sm">Payment</span>
          <span className="text-[#979797]  text-xs">60 Days</span>
        </div>
        <div className="flex flex-col">
          <span className=" font-bold text-sm">Currency</span>
          <span className="text-[#979797]  text-xs">
            {invoiceByIdData?.data?.currency}
          </span>
        </div>
      </div>

      <div className="flex mb-8 flex-col gap-4">
        <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
          <h1 className="text-[18px] font-bold">Supplier/buyer details</h1>
        </header>
        <div className="flex  justify-between">
          <div className="flex gap-2">
            <PAIconCocacola />
            <div className="flex flex-col">
              <span className=" font-bold text-sm">
                {invoiceByIdData?.data?.supplierCompanyName}
              </span>
              <span className="text-[#979797]  text-xs">Supplier</span>
            </div>
          </div>

          <div className="flex gap-2">
            <PAIconModelOne />
            <div className="flex flex-col">
              <span className=" font-bold text-sm">
                {invoiceByIdData?.data?.buyerCompanyName}
              </span>
              <span className="text-[#979797]  text-xs">Buyer</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex mb-8 flex-col gap-4">
        <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
          <h1 className="text-sm font-bold">Line Items</h1>
        </header>

        <div className=" w-full overflow-x-auto">
          <table className=" w-full overflow-x-auto">
            <thead className="bg-subtleGray w-">
              <tr className=" bg-unusualGray p-2.5 ">
                <td className="text-[#11192A] p-2.5 text-xs font-bold">
                  Item Number
                </td>
                <td className=" p-2.5 text-xs font-bold">PO Number</td>
                <td className=" p-2.5 text-xs font-bold">
                  Product/Service Number
                </td>
                <td className=" p-2.5 text-xs font-bold">
                  Product/Service Description
                </td>
                <td className=" p-2.5 text-xs font-bold">Quantity</td>
                <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                <td className=" p-2.5 text-xs font-bold">
                  Line Item Extended Amount
                </td>
              </tr>
            </thead>

            <tbody>
              {invoiceByIdData?.data?.items?.map((item, index) => (
                <tr key={index} className=" py-[3px] px-1.5">
                  <td className=" text-xs text-[#11192a] p-2.5">
                    INV-{invoiceByIdData?.data?.invoiceNumber}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">PO-2002</td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.itemName}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.description}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">
                    {item?.quantity}
                  </td>
                  <td className=" whitespace-nowrap text-xs text-[#11192a] p-2.5">
                    {invoiceByIdData?.data?.currency === "NGN" ? "₦" : "$"}{" "}
                    {item?.unitPrice}
                  </td>
                  <td className=" text-xs text-[#11192a] p-2.5">$600</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex mb-8 flex-col gap-4">
        <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
          <h1 className="text-sm font-bold">Attachments</h1>
        </header>

        <div className="w-full overflow-x-auto">
          <table className="w-full overflow-x-auto">
            <thead className="  bg-subtleGray w-">
              <tr className="px-4  bg-unusualGray p-2.5 ">
                <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                  Document Name
                </td>
                <td className=" px-4 py-2.5 text-xs font-bold">File Type</td>
                <td className=" px-4 py-2.5 text-xs font-bold">
                  Upload Date / Time
                </td>
                <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
              </tr>
            </thead>

            <tbody>
              <tr className=" py-[3px] px-1.5">
                <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                  DOC-29384.pdf
                </td>
                <td className=" text-xs text-[#11192a] px-4 py-2.5">PDF</td>
                <td className=" text-xs text-[#11192a] px-4 py-2.5">
                  2021-09-14 13:25
                </td>
                <td className="cursor-pointer text-xs text-[#11192a] px-4 py-2.5">
                  <PAIconDownload />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </motion.section>
  );
}

export default InvoiceDetails;
