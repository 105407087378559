export const SUPPLIERINVOICETABLECOLUMN = [
  {
    name: 'Invoice Reference',
    selector: row => row.invoiceReference
  },
  {
    name: 'Buyer',
    selector: row => row.buyer?.fullName
  },
  {
    name: 'Start Date',
    selector: row => row.createdDate,
    cell: row => <p>{row.createdDate.split(" ")[0]}</p>
  },
  {
    name: 'Due Date',
    selector: row => row.dueDate
  },
  {
    name: 'Currency',
    selector: row => row.currency
  },
  {
    name: 'Invoice Amount',
    selector: row => row.total,
    cell: row => <p>N{row.total}</p>
  },
  {
    name: 'Status',
    selector: row => row.status,
    cell: row => row.status === 'Paid' ? <p style={{ color: 'green' }}>{row.status}</p> : row.status === 'Cancelled' ? <p style={{ color: 'red' }}>{row.status}</p> : row.status === 'Expired' ? <p style={{ color: 'red' }}>{row.status}</p> : row.status === 'Approved' ? <p style={{ color: 'green' }}>{row.status}</p> : <p>{row.status}</p>
  },
]

export const BUYERINVOICETABLECOLUMN = [
  {
    name: 'Invoice Reference',
    selector: row => row.invoiceReference
  },
  {
    name: 'Supplier',
    selector: row => row.supplier?.fullName
  },
  {
    name: 'Start Date',
    selector: row => row.createdDate,
    cell: row => <p>{row.createdDate.split(" ")[0]}</p>
  },
  {
    name: 'Due Date',
    selector: row => row.dueDate
  },
  {
    name: 'Currency',
    selector: row => row.currency
  },
  {
    name: 'Invoice Amount',
    selector: row => row.total,
    cell: row => <p>N{row.total}</p>
  },
  {
    name: 'Status',
    selector: row => row.status,
    cell: row => row.status === 'Paid' ? <p style={{ color: 'green' }}>{row.status}</p> : row.status === 'Cancelled' ? <p style={{ color: 'red' }}>{row.status}</p> : row.status === 'Expired' ? <p style={{ color: 'red' }}>{row.status}</p> : row.status === 'Approved' ? <p style={{ color: 'green' }}>{row.status}</p> : <p>{row.status}</p>
  },
]

export const NEWINVOICETABLECOLUMN = [
  {
    name: 'Item',
    selector: row => row.item,
    cell: row => (
      <input type="text" className="w-full h-full focus:outline-none" />
    ),
    width: "8rem"
  },
  {
    name: 'Description',
    selector: row => row.description,
    cell: row => (
      <input type="text" className="w-full h-full focus:outline-none" />
    ),
  },
  {
    name: 'Qty',
    selector: row => row.qty,
    cell: row => (
      <input type="number" className="w-full h-full focus:outline-none" />
    ),
    width: "4rem"
  },
  {
    name: 'Unit Price',
    selector: row => row.unitPrice,
    cell: row => (
      <input type="text" className="w-full h-full focus:outline-none" />
    ),
  },
  {
    name: 'Tax Rate',
    selector: row => row.taxRate,
    cell: row => (
      <input type="text" className="w-full h-full focus:outline-none" />
    ),
    width: "6rem"
  },
  {
    name: 'Amount',
    selector: row => row.amount,
    cell: row => (
      <input type="text" className="w-full h-full focus:outline-none" />
    )
  },
]

export const addNewLine = (array) => {
  return [...array, {
    id: array.length + 1,
    item: '',
    description: '',
    qty: '',
    unitPrice: "",
    taxRate: "",
    amount: "",
  }]
}
export const NEWINVOICETABLEDATA = [
  {
    id: 1,
    item: '',
    description: '',
    qty: '',
    unitPrice: "",
    taxRate: "",
    amount: "",
  },
  {
    id: 2,
    item: '',
    description: '',
    qty: '',
    unitPrice: "",
    taxRate: "",
    amount: "",
  },
  {
    id: 3,
    item: '',
    description: '',
    qty: '',
    unitPrice: "",
    taxRate: "",
    amount: "",
  },
  {
    id: 4,
    item: '',
    description: '',
    qty: '',
    unitPrice: "",
    taxRate: "",
    amount: "",
  },
  {
    id: 5,
    item: '',
    description: '',
    qty: '',
    unitPrice: "",
    taxRate: "",
    amount: "",
  },
]

export const INVOICETABLEDATA = [
  {
    id: "64ad7c0b0bd21f3a40d6b4f0",
    invoiceReference: 'INV-2090',
    buyer: 'Agba Dev',
    invoiceDate: "2023-05-25",
    dueDate: "2023-06-02",
    currency: "EUR",
    invoiceAmount: "2,000,000",
    status: "Paid",
  },
  {
    id: "64ad7c3e0bd21f3a40d6b4f1",
    invoiceReference: 'INV-2091',
    buyer: 'Praiz Benzy',
    invoiceDate: "2023-06-04",
    dueDate: "2023-06-07",
    currency: "GBP",
    invoiceAmount: "11,000,000",
    status: "Cancelled",
  },
  {
    id: 3,
    invoiceReference: 'INV-2092',
    buyer: 'Taiwo Bytes',
    invoiceDate: "2023-02-23",
    dueDate: "2023-04-02",
    currency: "USD",
    invoiceAmount: "8,000,000",
    status: "Awaiting Approval",
  },
  {
    id: 4,
    invoiceReference: 'INV-2093',
    buyer: 'Michael Chief',
    invoiceDate: "2023-05-31",
    dueDate: "2023-06-02",
    currency: "NGN",
    invoiceAmount: "13,000,000",
    status: "Awaiting Payment",
  },
  {
    id: 5,
    invoiceReference: 'INV-2094',
    buyer: 'Agba Dev',
    invoiceDate: "2023-05-25",
    dueDate: "2023-06-02",
    currency: "USD",
    invoiceAmount: "9,000,000",
    status: "Cancelled",
  },
  {
    id: 6,
    invoiceReference: 'INV-2095',
    buyer: 'Agba Dev',
    invoiceDate: "2023-05-25",
    dueDate: "2023-06-02",
    currency: "NGN",
    invoiceAmount: "9,000,000",
    status: "Awaiting Approvals",
  },
  {
    id: 7,
    invoiceReference: 'INV-2090',
    buyer: 'Agba Dev',
    invoiceDate: "2023-05-25",
    dueDate: "2023-06-02",
    currency: "EUR",
    invoiceAmount: "2,000,000",
    status: "Paid",
  },
  {
    id: 8,
    invoiceReference: 'INV-2091',
    buyer: 'Praiz Benzy',
    invoiceDate: "2023-06-04",
    dueDate: "2023-06-07",
    currency: "GBP",
    invoiceAmount: "11,000,000",
    status: "Cancelled",
  },
  {
    id: 9,
    invoiceReference: 'INV-2092',
    buyer: 'Taiwo Bytes',
    invoiceDate: "2023-02-23",
    dueDate: "2023-04-02",
    currency: "USD",
    invoiceAmount: "8,000,000",
    status: "Awaiting Approvals",
  },
  {
    id: 10,
    invoiceReference: 'INV-2093',
    buyer: 'Michael Chief',
    invoiceDate: "2023-05-31",
    dueDate: "2023-06-02",
    currency: "NGN",
    invoiceAmount: "13,000,000",
    status: "Awaiting Payment",
  },
  {
    id: 11,
    invoiceReference: 'INV-2094',
    buyer: 'Agba Dev',
    invoiceDate: "2023-05-25",
    dueDate: "2023-06-02",
    currency: "USD",
    invoiceAmount: "9,000,000",
    status: "Cancelled",
  },
  {
    id: 12,
    invoiceReference: 'INV-2095',
    buyer: 'Agba Dev',
    invoiceDate: "2023-05-25",
    dueDate: "2023-06-02",
    currency: "NGN",
    invoiceAmount: "9,000,000",
    status: "Awaiting Approvals",
  },
  {
    id: 13,
    invoiceReference: 'INV-2095',
    buyer: 'Agba Dev',
    invoiceDate: "2023-05-25",
    dueDate: "2023-06-02",
    currency: "NGN",
    invoiceAmount: "9,000,000",
    status: "Expired",
  },
  {
    id: 14,
    invoiceReference: 'INV-2090',
    buyer: 'Agba Dev',
    invoiceDate: "2023-05-25",
    dueDate: "2023-06-02",
    currency: "EUR",
    invoiceAmount: "2,000,000",
    status: "Open",
  },
  {
    id: 15,
    invoiceReference: 'INV-2091',
    buyer: 'Praiz Benzy',
    invoiceDate: "2023-06-04",
    dueDate: "2023-06-07",
    currency: "GBP",
    invoiceAmount: "11,000,000",
    status: "Expired",
  },
  {
    id: 16,
    invoiceReference: 'INV-2092',
    buyer: 'Taiwo Bytes',
    invoiceDate: "2023-02-23",
    dueDate: "2023-04-02",
    currency: "USD",
    invoiceAmount: "8,000,000",
    status: "Approved",
  },
  {
    id: 17,
    invoiceReference: 'INV-2093',
    buyer: 'Michael Chief',
    invoiceDate: "2023-05-31",
    dueDate: "2023-06-02",
    currency: "NGN",
    invoiceAmount: "13,000,000",
    status: "Open",
  },
  {
    id: 18,
    invoiceReference: 'INV-2094',
    buyer: 'Agba Dev',
    invoiceDate: "2023-05-25",
    dueDate: "2023-06-02",
    currency: "USD",
    invoiceAmount: "9,000,000",
    status: "Approved",
  },
  {
    id: 19,
    invoiceReference: 'INV-2095',
    buyer: 'Agba Dev',
    invoiceDate: "2023-05-25",
    dueDate: "2023-06-02",
    currency: "NGN",
    invoiceAmount: "9,000,000",
    status: "Approved",
  },
]

export const INVOICETABCOLUMN = [
  {
    name: 'Item',
    selector: row => row.itemName,
    cell: row => <p>{row.itemName}</p>
  },
  {
    name: 'Description',
    selector: row => row.description,
    cell: row => <p>{row.description}</p>
  },
  {
    name: 'Quantity',
    selector: row => row.quantity,
    cell: row => <p>{row.quantity}</p>
  },
  {
    name: 'Unit Price',
    selector: row => row.unitPrice,
    cell: row => <p>{row.unitPrice}</p>
  },
  {
    name: 'Tax Rate',
    selector: row => row.taxRate,
    cell: row => <p>{row.taxRate}</p>
  },
  {
    name: 'Amount',
    selector: row => row.amount,
    cell: row => <p>{row.amount}</p>
  },
]
export const INVOICETABDATA = [
  {
    id: 1,
    itemNo: 'INV-2002',
    poNumber: 'PO-2002',
    productService: 'ITM3201',
    productDescription: 'Dangote Cement',
    quantity: '20',
    unitPrice: "$30",
    lineItem: "$600",
  },
  {
    id: 2,
    itemNo: 'INV-2002',
    poNumber: 'PO-2002',
    productService: 'ITM3201',
    productDescription: 'Dangote Cement',
    quantity: '20',
    unitPrice: "$30",
    lineItem: "$600",
  }
]

export const INVOICEATTACHMENTDATA = [
  {
    id: 1,
    documentName: 'DOC-29385.pdf',
    fileType: 'PDF',
    uploadDateTime: '2021-09-14 13:25',
  }
]

export const PURCHASETABDESCCOLUMN = [
  {
    name: 'Item',
    selector: row => row.item,
  },
  {
    name: 'Quantity',
    selector: row => row.quantity
  },
  {
    name: 'Rate',
    selector: row => row.rate
  },
  {
    name: 'Amount',
    selector: row => row.quantity * row.rate
  },
]
export const PURCHASETABDES = [
  {
    id: 1,
    item: 'AET watch',
    quantity: 2,
    rate: 144,
    // amount: 10
  },
  {
    id: 2,
    item: 'AET iPhone',
    quantity: 2,
    rate: 552,
    // amount: 10
  },
]