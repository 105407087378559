import React from "react";
import infoGreen from "../../../../assets/images/svgs/infoGreen.svg";
import blockIcon from "../../../../assets/images/svgs/blockIcon.svg";

const FundingOptionsModal = ({
  isOpen,
  onClose,
  confirm,
  thirdParty,
  setFromBuyer,
}) => {
  if (!isOpen) {
    return null;
  }

  const handleThirdParty = () => {
    setFromBuyer(true);
    thirdParty();
  };

  return (
    <div
      //   onClick={handleCloseConfirm}
      className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0"
    >
      <div>
        <div className="flex flex-col justify-between items-center bg-white rounded-[8px] p-[5%] w-[600px]">
          <img className="mt-[3%]" src={infoGreen} alt="" />
          <p className="text-center py-6">Please select payment method!</p>
          <div className="flex items-center">
            <div
              onClick={confirm}
              className="bg-[#07593D] mr-[5%] text-white rounded-[8px] flex justify-between items-end w-[270px] px-[5%] py-[2%]"
            >
              <p>
                Make Payment via <br />{" "}
                <span className="font-bold">Wallet</span>
              </p>
              <img width={20} src={blockIcon} alt="" />
            </div>

            <div
              onClick={handleThirdParty}
              className="bg-[#07593D] text-white rounded-[8px] flex justify-between items-end w-[270px] px-[5%] py-[2%]"
            >
              <p>
                Make Payment via <br />{" "}
                <span className="font-bold">Third Party Funding</span>
              </p>
              <img src={blockIcon} alt="" />
            </div>
          </div>
          <div className="mt-[6%] text-right w-[100%]">
            <button
              onClick={onClose}
              className="border-[#EE0816] text-[#EE0816] border-[1px] w-[120px] rounded-[4px] h-[45px]"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundingOptionsModal;
