import Settings from "features/settings";

const SettingsPage = () => {
  return (
    <>
      <Settings />
    </>
  );
};

export default SettingsPage;
