import { COLORS } from 'constants/colors';
import { FONTSIZES, FONTWEIGHTS } from 'constants/font-spec';
import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #07593D;
  background-position: bottom left, bottom right;
  background-repeat: no-repeat;
`;
export const ResendLinkWrapper = styled.div`
  background-color: white;
  .resend-verification {
    padding: 24px 40px 40px;
    &__heading {
      font-style: normal;
      font-weight: ${FONTWEIGHTS.bold};
      font-size: ${FONTSIZES.xlarge};
      line-height: 23px;
      color: ${COLORS.deepPurple};
    }
    &__image-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 64px;
      &--image {
        width: 103px;
      }
    }

    &__info {
      font-style: normal;
      font-weight: ${FONTWEIGHTS.normal};
      font-size: ${FONTSIZES.lg};
      line-height: 21px;
      color: ${COLORS['grey-500']};
      padding-top: 56px;
      width: 360px;
    }

    &__email {
      font-style: normal;
      font-weight: ${FONTWEIGHTS.bold};
      font-size: ${FONTSIZES.lg};
      line-height: 21px;
      color: ${COLORS.deepPurple};
      padding-top: 16px;
    }

    &__link {
      font-style: normal;
      font-weight: ${FONTWEIGHTS.bold};
      font-size: ${FONTSIZES.lg};
      line-height: 21px;
      color: ${COLORS['blue-200']};
      padding-top: 56px;
      text-align: center;
      cursor: pointer;
    }
  }
`;
