import React from "react";
import OverviewGraph from "../components/OverviewGraph";
import line from "assets/images/svgs/repFrameLine.svg";
import { useSelector } from "react-redux";
import { PAIconFluentBoxGreen } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";

const Overview = () => {
  const { dashboardData } = useSelector((state) => state?.invoice);
  const navigate = useNavigate();
  const handleFundingSection = () => {
    let path = "/invoice/invoice/fundingSection";
    navigate(path);
  };
  const handleOpenMarket = () => {
    let path = "/open-market/";
    navigate(path);
  };
  function handleNavigate() {
    let path = "/invoice/new-invoice";
    navigate(path);
  }
  return (
    <div className="flex gap-5 justify-between">
      <div className="bg-[#FAFAFA] p-7 rounded-xl w-[70%]">
        <div className="flex justify-between mb-[18px]">
          <h2 className="text-base font-medium">Overview</h2>
          <Button
            neutral
            onClick={handleNavigate}
            className="rounded-[5px] border border-silver py-[12px] px-[24px] !text-black text-sm font-normal"
          >
            Create Invoice
          </Button>
        </div>

        <div className="flex items-center">
          <OverviewGraph />
          <div className="w-full">
            <div className="bg-white p-4 xl:p-6 flex gap-x-[20px] xl:justify-between">
              <h1 className="text-2xl xl:text-4xl font-bold text-primaryColor whitespace-nowrap">
                {dashboardData?.totalReceivables}
              </h1>
              <div className="text-sm text-left 2xl:relative right-8">
                <span className="block">Total</span>
                <span className="block">Recievables</span>
              </div>
            </div>

            <div className="bg-white p-4 xl:p-6 flex gap-x-[20px] mt-4 xl:justify-between">
              <h1 className="text-2xl xl:text-4xl font-bold text-secondaryColor whitespace-nowrap">
                {dashboardData?.totalEarlyPaymentReceived}
              </h1>
              <div className="text-sm text-left 2xl:relative right-8">
                <span className="block whitespace-nowrap">Early Payment</span>
                <span className="block">Recieved</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#FAFAFA] p-3 rounded-xl basis-1/3">
        <div className="bg-[#07593D] p-4 rounded-md">
          {/* <img src={addPlus} alt="Add Icon"></img>
          <div className="text-3xl text-white text-left mt-1">
            <span className="block">Request Early</span>
            <span className="block">Payment</span>
          </div>
          <img className="float-right -mt-4" src={arrow} alt="Add Icon"></img> */}
          <div
            className="bg-white p-3 mt-3 text-[#07593D] font-semibold flex items-center justify-between cursor-pointer"
            onClick={handleFundingSection}
            // onClick={() => {
            //   handleFundingSection();
            // }}
          >
            <p className="textwhite text-xs">Funding Section</p>
            <br />
            <div className="mt-2">
              <PAIconFluentBoxGreen />
            </div>
          </div>

          <div
            className="bg-white p-3 mt-3 text-[#07593D] font-semibold flex justify-between cursor-pointer"
            onClick={handleOpenMarket}
          >
            <p className="text-xs">
              Open Market <br />
              <span>Section</span>
            </p>
            <br />
            <div className="mt-2">
              <PAIconFluentBoxGreen />
            </div>
          </div>
        </div>
        <div className="flex mt-2 items-center">
          <img className="h-40" src={line} alt="frame"></img>
          <div className="ml-5">
            <div className="mb-5">
              <p>Total Available Invoice</p>
              <h1 className="text-xl font-bold text-[#86888B]">
                {dashboardData?.invoiceCount}
              </h1>
            </div>
            <div>
              <p>Early Payment Requested</p>
              <h1 className="text-xl font-bold text-[#86888B]">
                {dashboardData?.earlyPaymentRequestCount}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
