import { PAIconSupplier, PAIconUser } from "assets/images/svgs";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import OnboardingLayout from "./components/OnboardingLayout";
import RadioButton from "./components/RadioButton";
import { useKeycloak } from "@react-keycloak/web";


const Category = () => {
  const [select, setSelect] = useState("");
  const [active, setActive] = useState(null);
  const [role, setRole] = useState("");
  const { keycloak } = useKeycloak();


  // #F08000
  const onboardingOptions = [
    {
      id: 1,
      icon: <PAIconSupplier />,
      name: "Supplier",
      route: "/supplier-create-account",
    },
    {
      id: 3,
      icon: <PAIconUser />,
      name: "Buyer",
      route: "/personal-details",
    },
    {
      id: 4,
      icon: <PAIconUser />,
      name: "Funder",
      route: "/funder-create-account",
    },
  ];

  const navigate = useNavigate();

  const handleSubmit = () => {
    let path = select;
    navigate(path, { state: role });
  };

  const GoToLogin = () => {
    keycloak.login();
  }

  return (
    <OnboardingLayout nohelp>
      <div className="w-full pt-[100px] flex flex-col items-center px-[20px] md:mt-0 md:px-0 lg:pb-[60px]">
        <h1 className="font-semibold text-[28px] text-primaryColor text-center">
          Welcome to PayEdge
        </h1>
        <p className="font-semibold italic text-xl text-[#222] mt-[12px] text-center">
          Before you get started
        </p>
        <p className="font-medium text-sm text-[#7A7A7A] mt-[10px] text-center mb-[35px]">
          Please choose your category
        </p>
        {onboardingOptions.map((option, idx) => (
          <RadioButton
            key={idx + 1}
            setSelect={setSelect}
            setRole={setRole}
            route={option.route}
            select={select}
            icon={option.icon}
            name={option.name}
            idx={idx}
            active={active}
            onClick={() => setActive(idx)}
          />
        ))}
        <Button
          onClick={(e) => handleSubmit(e)}
          className="mt-[30px] w-full md:w-[424px] mb-[20px]"
          auth
          disabled={!select}
        >
          Create Account
        </Button>
        <div>
          <span style={{fontWeight: 400, fontSize: 16}}>Have an existing account? <span onClick={GoToLogin} style={{color: "#F08000", cursor: "pointer", fontSize: 16}}>Sign in</span></span>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default Category;
