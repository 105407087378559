import {
  allVirtualAccounts,
  allVirtualCards,
} from "appstate/wallet/walletSlice";
import { PAIconCardOutline, PAIconPaymnet } from "assets/images/svgs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const RequestVirtualCardOption = ({
  isOpen,
  onClose,
  onSubmit,
  onSelectAccount,
}) => {
  const { allVirtualAccountsData, allVirtualCardsData } = useSelector(
    (state) => state?.wallet
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allVirtualAccounts());
    dispatch(allVirtualCards());
  }, [dispatch]);

  const formatCardNumber = (cardNumber) => {
    if (!cardNumber) {
      return "";
    }

    const formattedCardNumber = cardNumber?.replace(/(\d{4})/g, "$1 ");
    return formattedCardNumber.trim();
  };

  const accountData = Array.isArray(allVirtualAccountsData?.data)
    ? allVirtualAccountsData?.data?.map((account) => ({
        id: account?.id,
        name: account?.accountName,
        accountNumber: account?.accountNumber,
        bankName: account?.provider || "N/A",
        accountType: "Virtual Account",
        bvn: account?.bvn,
      }))
    : [];

  const cardData = Array.isArray(allVirtualCardsData?.data)
    ? allVirtualCardsData?.data?.map((card) => ({
        id: card?.id,
        cardName: card?.cardName,
        cardNumber: formatCardNumber(card?.cardNumber),
        cardCurrency: card?.cardCurrency,
        paymentMethod: card?.paymentMethod,
        balance: card?.balance,
        expiryDate: card?.expiryDate,
      }))
    : [];

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg max-h-md w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Request virtual card
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        {/* ACCOUNTS */}
        <div>
          <div className="w-full px-7" onSubmit={handleSubmit}>
            <p className="text-zinc-600 text-sm font-extrabold flex justify-center">
              Select which account or card to transfer from
            </p>
            <h1 className="text-zinc-600 text-base font-extrabold mt-5">
              Accounts
            </h1>

            {/* CARDS */}
            <div className="md:flex md:gap-5 flex-wrap max-h-48 overflow-scroll">
              {accountData?.map((account, index) => (
                <div
                  key={index}
                  className="bg-[#07593D] p-3 mt-3 cursor-pointer text-white text-xs md:w-[calc(50%-10px)] md:mb-0 mb-3 rounded"
                  onClick={() => {
                    onSelectAccount(account);
                    handleSubmit();
                  }}
                >
                  <div className="text-white text-xs flex items-center">
                    <PAIconPaymnet className="mr-2" /> <p>{account?.name}</p>
                  </div>
                  <p className="mt-2">{account?.accountNumber}</p>
                  <div className="flex items-center justify-between mt-2 text-[10px]">
                    <p>{account?.bankName}</p>
                    <p>{account?.accountType}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-10 mb-3">
              <button className="bg-[#F08000] flex items-center rounded p-2 text-xs text-white gap-5">
                <PAIconCardOutline className="mr-2 w-4" />
                <p>Add new account</p>
              </button>
            </div>

            {/* CARDS */}
            <h1 className="text-zinc-600 text-base font-extrabold mt-5">
              Cards
            </h1>
            <div className="overflow-scroll max-h-32">
              {cardData.map((card, index) => (
                <div
                  key={index}
                  className="bg-[#07593D] p-2 text-white dlex items-center justify-between w[80%] flex itemscenter rounded text-xs my-3 mr-3"
                >
                  <p className="flex items-center">
                    {" "}
                    <PAIconCardOutline className="mr-2 w-4" /> {card?.cardName}
                  </p>{" "}
                  - <p>{card?.cardNumber}</p> - <p>{card?.balance}</p>
                </div>
              ))}
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-10 mb-3">
              <button className="bg-[#F08000] flex items-center rounded p-2 text-xs text-white">
                <PAIconCardOutline className="mr-2 w-4" />
                <p>Add new card</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestVirtualCardOption;
