import { assignPermission } from "appstate/roleManager/roleManagerSlice";
import { PAIconCancelRound } from "assets/images/svgs";
import { motion } from "framer-motion";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

function EditRoleModal({ closeModal }) {
  const [checkBoxItems, setCheckBoxItem] = useState({});
  const [storeData, setStoreData] = useState([]);
  const { allPermissionsData } = useSelector((state) => state?.roleManager);
  const { roleId } = useSelector((state) => state?.roleManager);
  const dispatch = useDispatch();

  const getCheckedItems = () => {
    const checkedParentIds = Object.keys(checkBoxItems).filter(
      (key) =>
        checkBoxItems[key] &&
        !allPermissionsData?.data?.data.find(
          (item) =>
            item.subPermissions &&
            item.subPermissions.some((subItem) => subItem.id === key)
        )
    );

    const childrenIds = Object.keys(checkBoxItems).filter(
      (key) =>
        checkBoxItems[key] &&
        !checkedParentIds.includes(key) &&
        allPermissionsData?.data?.data.some((item) =>
          item.subPermissions
            ? item.subPermissions.some((subItem) => subItem.id === key)
            : false
        )
    );

    return {
      parentId: checkedParentIds.length > 0 ? checkedParentIds[0] : null,
      childrenIds,
    };
  };

  const handleChildChange = (event, parentId, childId) => {
    const isChecked = event.target.checked;

    const existingObjectIndex = storeData.findIndex(
      (item) => item.permissionId === parentId
    );

    if (isChecked) {
      if (existingObjectIndex === -1) {
        const object = {
          roleId: roleId,
          permissionId: parentId,
          subPermissionId: [childId],
        };
        setStoreData((prev) => [...prev, object]);
      } else {
        setStoreData((prev) =>
          prev.map((item, index) =>
            index === existingObjectIndex
              ? {
                  ...item,
                  subPermissionId: [...item.subPermissionId, childId],
                }
              : item
          )
        );
      }
    } else {
      if (existingObjectIndex !== -1) {
        setStoreData((prev) =>
          prev.map((item, index) =>
            index === existingObjectIndex
              ? {
                  ...item,
                  subPermissionId: item.subPermissionId.filter(
                    (id) => id !== childId
                  ),
                }
              : item
          )
        );
        const updatedParent = storeData[existingObjectIndex];
        if (
          updatedParent.subPermissionId.length === 1 &&
          updatedParent.subPermissionId[0] === childId
        ) {
          setStoreData((prev) =>
            prev.filter((item) => item.permissionId !== parentId)
          );
        }
      }
    }

    setCheckBoxItem((prevCheckBoxItems) => {
      const updatedCheckBoxItems = {
        ...prevCheckBoxItems,
        [childId]: isChecked,
      };

      const parent = storeData.find((item) => item.permissionId === parentId);

      if (parent && parent.subPermissionId.length === 1 && !isChecked) {
        updatedCheckBoxItems[parentId] = false;
      } else {
        updatedCheckBoxItems[parentId] =
          storeData
            .filter((item) => item.permissionId === parentId)
            .flatMap((item) => item.subPermissionId)
            .some((id) => updatedCheckBoxItems[id] === true) || isChecked;
      }

      return updatedCheckBoxItems;
    });
  };

  const ChildBox = ({ child, parentId, childId, value }) => {
    return (
      <>
        <label className="flex gap-2 px-2 py-1.5 cursor-pointer">
          <input
            type="checkbox"
            checked={checkBoxItems[childId]}
            onChange={(event) => handleChildChange(event, parentId, childId)}
            value={value}
          />
          <p className="text-sm text-black">
            {child.subPermissionName || child.permissionName}
          </p>
        </label>
        {child.subPermissions &&
          child.subPermissions.map((subItem) => (
            <ChildBox
              key={subItem.id}
              child={subItem}
              parentId={childId}
              childId={subItem.id}
              value={null}
            />
          ))}
      </>
    );
  };

  const handleClick = (event, parentId) => {
    const isChecked = event.target.checked;
    const updatedItems = { ...checkBoxItems, [parentId]: isChecked };

    const parentItem = allPermissionsData?.data?.data.find(
      (item) => item.permissionId === parentId
    );

    if (parentItem.subPermissions) {
      parentItem.subPermissions.forEach((child) => {
        updatedItems[child.id] = isChecked;
      });
    }

    // eslint-disable-next-line no-unused-vars
    const newCheckedItems = getCheckedItems();

    if (isChecked) {
      const existingObjectIndex = storeData.findIndex(
        (item) => item.permissionId === parentId
      );

      const object = {
        roleId: roleId,
        permissionId: parentId,
        subPermissionId: parentItem.subPermissions.map(
          (subPermission) => subPermission.id
        ),
      };
      if (existingObjectIndex === -1) {
        setStoreData((prev) => [...prev, object]);
      } else {
        setStoreData((prev) =>
          prev.map((item) =>
            item.permissionId === parentId ? { ...item, ...object } : item
          )
        );
      }
    } else {
      setStoreData((prev) =>
        prev.filter((item) => item.permissionId !== parentId)
      );
    }
    setCheckBoxItem(updatedItems);
    // If the child array is empty, uncheck the parent
    // if (parentItem.subPermissions.length === 0) {
    //   setCheckBoxItem((prevCheckBoxItems) => ({
    //     ...prevCheckBoxItems,
    //     [parentId]: false,
    //   }));
    // }
  };

  function handleSave() {
    const body = { assignPermissionPayload: storeData };
    dispatch(assignPermission(body)).then((data) => {
      if (data?.payload?.success) {
        toast.success("Permission assigned successfully");
        closeModal();
      }
    });
  }
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          delay: 0.3,
        },
      }}
      onClick={() => closeModal()}
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[9999] flex justify-end items-center"
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        initial={{
          x: 20,
          opacity: 0,
          transition: {
            duration: 0.3,
          },
        }}
        animate={{
          x: 0,
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          x: 20,
          opacity: 0,
          transition: {
            duration: 0.3,
          },
        }}
        className={`bg-white w-full md:w-[65%] lg:w-[45%] h-full overflow-auto  z-[9999]`}
      >
        <motion.div>
          <motion.div
            exit={{
              x: 20,
              opacity: 0,
              transition: {
                duration: 0.3,
              },
            }}
            className="z-[9999]   h-full flex flex-col justify-start w-full gap-8 pt-[3%] pb-[6%] px-[4%] "
          >
            <div className=" flex flex-col gap-6">
              <header className="w-full sticky bg-white top-0 z-[999999] flex py-[32px] justify-between">
                <span className="flex items-center font-bold ">
                  Edit Permissions
                </span>
                <span
                  onClick={() => closeModal()}
                  className="cursor-pointer gap-2 flex items-center justify-center "
                >
                  <PAIconCancelRound />
                </span>
              </header>

              <main className="h-full overflow-auto">
                <div className="w-full h-4/5 rounded overflow-auto ">
                  <div className="overflow-auto flex flex-col gap-8">
                    <div className=" w-full ">
                      <p className=" text-[#7A7A7A] text-xs">
                        Assign a permission to a role by selecting the
                        permission from the “All Permission” section and it
                        would be moved to the “Assigned Permission”
                      </p>
                    </div>

                    <div className=" w-full flex justify-between ">
                      <div className="flex w-[48%]   flex-col gap-3">
                        <span>
                          <h1 className=" text-sm font-bold text-[#565656]">
                            All Permissions
                          </h1>
                        </span>

                        <section className="flex py-7 px-3 rounded-sm bg-[#F9F9F9] flex-col gap-3">
                          <>
                            {allPermissionsData?.data?.data.map((item) => (
                              <div key={item.permissionId}>
                                <label className="flex border-t border-b border-[#DADADA] gap-2 px-2 py-2 cursor-pointer">
                                  <input
                                    type="checkbox"
                                    checked={checkBoxItems[item.permissionId]}
                                    onChange={(e) =>
                                      handleClick(e, item.permissionId)
                                    }
                                    value={null}
                                  />
                                  <p className="text-sm font-bold text-black">
                                    {item.permissionName}
                                  </p>
                                </label>

                                <div>
                                  {item.subPermissions &&
                                    item.subPermissions.map((subItem) => (
                                      <ChildBox
                                        key={subItem.id}
                                        child={subItem}
                                        parentId={item.permissionId}
                                        childId={subItem.id}
                                      />
                                    ))}
                                </div>
                              </div>
                            ))}
                          </>
                        </section>
                      </div>

                      <div className="flex w-[48%]  flex-col gap-3">
                        <span>
                          <h1 className=" text-sm font-bold text-[#565656]">
                            Assigned Permissions
                          </h1>
                        </span>
                        {/* <section className="flex rounded-sm  px-3  py-7 bg-[#F9F9F9] flex-col gap-3">
                          <>
                            <PermissionSubCategory
                              permissionIds={permissionIds}
                              setPermissionIds={setPermissionIds}
                              subPermissionId={subPermissionIds}
                              setSubPermissionIds={setSubPermissionIds}
                              title="Invoice Management"
                            />
                          </>
                        </section> */}
                      </div>
                    </div>
                  </div>
                </div>
              </main>

              <footer className=" text-white flex justify-end ">
                <button
                  onClick={() => handleSave()}
                  className=" px-4 py-2 bg-[#07593D] rounded-[5px] border-none cursor-pointer"
                >
                  Save
                </button>
              </footer>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
export default EditRoleModal;
