import { createNewSupplier } from "appstate/auth/authSlice";
import { PAIconEye, PAIconVieweye } from "assets/images/svgs";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import PhoneNumber from "shared/PhoneNumber";
import Spinner from "shared/Spinner/Spinner";
import { supplierValidationSchema } from "validation/supplier/SuppliersignupSchema";
import PasswordLength from "../../../shared/PasswordLength";
import OnboardingLayout from "../category/components/OnboardingLayout";

const MemberSignup = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading } = useSelector((state) => state?.auth);
  const { saveInviteData } = useSelector((state) => state?.roleManager);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const calculateStrength = (value) => {
    let strength = 0;
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*()])(?=.*[A-Z]).{6,}$/;

    if (/\d/.test(value)) {
      strength++;
    }
    if (/[!@#$%^&*]/.test(value)) {
      strength++;
    }
    if (/[A-Z]/.test(value)) {
      strength++;
    }
    if (value.length >= 6) {
      strength++;
    }

    if (passwordRegex.test(value)) {
      strength++;
    }

    return strength;
  };

  const memberId = saveInviteData?.data?.id

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: saveInviteData?.data?.email,
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: supplierValidationSchema,
    onSubmit: (values) => {
      const body = {
        fullName: values.fullName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        password: values.password,
        role: "USERS",
      };
      dispatch(createNewSupplier(body, memberId)).then((data) => {
        if (data?.payload?.success) {
          let path = "/member/memberTerms";
          navigate(path);
        }
      });
    },
  });

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <OnboardingLayout>
      <div className="pt-[100px] grid place-content-center h-[100%] overflow-auto md:pt-0 lg:pt-[100px]">
        <h1 className="font-semibold text-[28px] text-primaryColor text-center">
          Welcome to PayEdge
        </h1>
        <p className="font-semibold text-[20px] text-textColor mt-[35px] text-center">
          Before you get started
        </p>
        <p className="font-normal text-small text-textColor mt-[10px] text-center mb-[35px]">
          Please enter your new password below
        </p>
        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center flex-col"
        >
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px]"
              placeholder="Full Name"
              label="Full Name"
              id="fullName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullName}
            />
            {formik.touched.fullName && formik.errors.fullName ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.fullName}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <PhoneNumber
              name="phoneNumber"
              id="phoneNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.phoneNumber}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="exampleforyou@info.com"
              label="Email Address"
              disabled
              id="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.email}
              </p>
            ) : null}
          </div>
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none  md:w-[424px]"
              name="password"
              type={showPassword ? "text" : "password"}
              label="Password"
              id="password"
              placeholder="........"
              onChange={formik.handleChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              value={formik.values.password}
              autoComplete="new-password"
            />
            {showPassword ? (
              <PAIconVieweye
                onClick={toggleShowPassword}
                className="float-right mr-[6px] mt-[-30px] relative z-2"
              />
            ) : (
              <PAIconEye
                onClick={toggleShowPassword}
                className="float-right mr-[6px] mt-[-30px] relative z-2"
              />
            )}

            {formik.touched.password && formik.errors.password ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.password}
              </p>
            ) : null}

            <div className="flex justify-between mt-[10px]">
              <PasswordLength
                className={`strength-bar ${isFocused ? "block" : "hidden"} ${
                  calculateStrength(formik.values.password) === 1
                    ? "bg-red"
                    : calculateStrength(formik.values.password) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : "bg-red-500"
                }`}
              />
              <PasswordLength
                className={`strength-bar ${isFocused ? "block" : "hidden"} ${
                  calculateStrength(formik.values.password) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar ${isFocused ? "block" : "hidden"} ${
                  calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar ${isFocused ? "block" : "hidden"} ${
                  calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
            </div>
          </div>
          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder="........"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.confirmPassword}
              </p>
            ) : null}
          </div>
          <Button
            auth
            type="submit"
            disabled={isLoading}
            className="mb-[30px] w-full md:w-[424px]"
          >
            {isLoading ? <Spinner /> : "Create Account"}
          </Button>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default MemberSignup;
