import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bankDetailsUploaded: false,
  kycDocumentUploaded: false,
  agreementDocumentAccepted: false,
};

const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    setBankDetailsUploaded: (state, action) => {
      state.bankDetailsUploaded = action.payload;
    },
    setKycDocumentUploaded: (state, action) => {
      state.kycDocumentUploaded = action.payload;
    },
    setAgreementDocumentAccepted: (state, action) => {
      state.agreementDocumentAccepted = action.payload;
    },
  },
});

export const { setBankDetailsUploaded, setKycDocumentUploaded, setAgreementDocumentAccepted } = verificationSlice.actions;
export default verificationSlice.reducer;
