import React, { useEffect, useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";

import { useDispatch, useSelector } from "react-redux";
import { allTransactions } from "appstate/collections/collectionSlice";
import Pagination from "shared/Pagination";
import * as XLSX from "xlsx";
import Loading from "shared/Loading";
import {
  PAIconDownload,
  PAIconFilter,
  PAIconInvoiceIcon,
  PAIconSearchGreen,
} from "assets/images/svgs";
import { AnimatePresence, motion } from "framer-motion";
import DateRangePickerModal from "shared/datePicker/DateRangePicker";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state?.collection);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectDownloadType, setSelectDownloadType] = useState(false);
  const [selectedRange, setSelectedRange] = useState({
    startDate: null,
    endDate: null,
  });
  const pageSize = 10;

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    sessionStorage.setItem("searchQueryFunder", value);
  };

  const closeModal = () => setIsModalOpen(false);

  const applyFilter = (range) => {
    setSelectedRange(range);
    closeModal();
  };

  const handleFilterButtonClick = () => {};

  const transactionData = {
    data: [
      {
        companyName: "Alat Bank",
        paymentDate: "12-03-2023",
        invoiceReference: "XDGSYYEYIOS",
        currency: "NGN",
        amount: "500,000",
        status: "Paid",
      },
      {
        companyName: "Alat Bank",
        paymentDate: "12-03-2023",
        invoiceReference: "XDGSYYEYIOS",
        currency: "NGN",
        amount: "500,000",
        status: "Paid",
      },
      {
        companyName: "Alat Bank",
        paymentDate: "12-03-2023",
        invoiceReference: "XDGSYYEYIOS",
        currency: "NGN",
        amount: "500,000",
        status: "Paid",
      },
      {
        companyName: "Alat Bank",
        paymentDate: "12-03-2023",
        invoiceReference: "XDGSYYEYIOS",
        currency: "NGN",
        amount: "500,000",
        status: "Paid",
      },
      {
        companyName: "Alat Bank",
        paymentDate: "12-03-2023",
        invoiceReference: "XDGSYYEYIOS",
        currency: "NGN",
        amount: "500,000",
        status: "Paid",
      },
    ],
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      transactionData.data.map((row) => ({
        "Program Ref No": row?.refNo,
        "Invoice Number": `INV-${row?.invoiceNumber}`,
        Buyer: row?.buyerCompanyName,
        "Due Date": row?.dueDate,
        "Payment Date": row?.paymentDate,
        Currency: row?.currency,
        "Invoice Amount": formatAmount(row?.invoiceAmount),
        "Discount Amount": formatAmount(row?.discountAmount),
        "Discount Rate": formatAmount(row?.discountRate),
        "Fundable Amount": formatAmount(row?.fundableAmount),
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Direct Funding");
    XLSX.writeFile(wb, "DirectFundingData.xlsx");
  };

  useEffect(() => {
    dispatch(allTransactions({ page, pageSize }));
  }, [dispatch, page, pageSize]);

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Beneficiary
        </span>
      ),
      selector: (row) => row.companyName,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.paymentDate?.slice(0, 10),
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) => <p>{formatAmount(row.amount)}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Paid" ? (
          <p className=" text-success">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PARTIALLY PAID" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      cell: (row) => (
        <div
          className="border border-gray300 rounded text-[#959595] p-2"
          //   onClick={() => transactionDetails(row.invoiceId)}
        >
          View Details
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="mb-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div className="flex bg-white items-center rounded shadow-md">
            <button
              className="px-5  text-xs py-4 bg-white rounded"
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              Filters{" "}
              {selectedRange.startDate
                ? selectedRange.startDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : "Not selected"}{" "}
              -{" "}
              {selectedRange.endDate
                ? selectedRange.endDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : "Not selected"}
            </button>
          </div>
          <DateRangePickerModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onApply={applyFilter}
          />

          <button
            onClick={() => {
              setSelectDownloadType(true);
            }}
            className="py-4 text-xs px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5"
          >
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        <div className="hidden md:flex  items-center gap-2">
          <div className="flex gap-2.5 w-full bg-white border-[0.5px] border-[#ccc] p-2.5 items-center rounded-[5px]">
            <PAIconSearchGreen />
            <input
              type="text"
              placeholder="Search for invoices on the open market"
              value={searchQuery}
              onChange={handleSearchInputChange}
              className="p-2 w-full text-xs flex items-center text-black placeholder:text-black focus:outline-none"
            />
          </div>

          <motion.button
            whileTap={{ scale: 0.9 }}
            onClick={handleFilterButtonClick}
            className="ml-2 flex gap-2 items-center justify-center bg-[#2FA06A] text-white text-sm  px-6 py-4 rounded-md duration-[200ms] ease-in  hover:bg-primaryColor focus:outline-none"
          >
            <PAIconFilter />
            Filter
          </motion.button>
        </div>
      </div>

      {!transactionData?.data?.length ? (
        <div className="ml3">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Recent disbursement history
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] px-[24px] mt-[24px]">
          <div
            style={{ borderBottom: "1px solid rgba(174, 174, 174, 0.46)" }}
            className="flex justify-between items-center  pt-6 pb-4 rounded-t-[10px]"
          >
            <div className="flex flex-col">
              <p className="text-black font-semibold">
                Recent disbursement history
              </p>
              <span className="text-xs text-[#959595] font-thin">
                Here is a recent list of your receivables from Payment
                disbursement
              </span>
            </div>
          </div>
          <div className="pb-[24px]">
            <Table
              columns={columns}
              data={transactionData?.data}
              // onRowClicked={(row) => transactionDetails(row.invoiceId)}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {transactionData?.data?.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={transactionData?.meta?.totalElements}
                />
              )}
            </div>
          </div>
        </Card>
      )}

      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Export
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </>
  );
};

export default TransactionHistory;
