import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "shared/InputField";
import styled from "styled-components";
import TableHeaderSelect from "../../../shared/tableHeader/TableHeaderSelect";
import Button from "shared/Button";
import TierModal from "shared/tierModal";

const TableHeaderFunder = ({
  handleChange,
  search,
  productName,
  setProductName,
  total,
  path,
  buyer,
  title,
  options,
  details,
  showBackBtn,
  data,
}) => {
  const [openTierModal, setOpenTierModal] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(path, { state: "earlyPayments" });
  };

  return (
    <>
      <TierModal
        isShown={openTierModal}
        onClose={() => setOpenTierModal(false)}
      />
      <div className="mb-[33px]">
        {showBackBtn && (
          <div
            onClick={handleNavigate}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconInventoryArrowLeft />
            <p className="mt-[2px] text-sm font-normal text-lightGray">
              Go Back
            </p>
          </div>
        )}
        <div className="md:flex justify-between flex-wrap items-center border-b-2 border-silver pb-[12px] lg:flex-nowrap">
          <div>
            <p className="mb-[10px] text-black text-sm font-medium md:mb-0">
              {total}
            </p>
            <p className="mb-[10px] text-xs font-normal text-textColor md:mb-0">
              {details}
            </p>
          </div>
          {buyer && (
            <p className="text-sm font-medium mb-[10px] md:mb-0">{title}</p>
          )}
        </div>
        <div className="flex flex-col gap-y-[10px] md:gap-x-[10px] md:flex-row md:flex-wrap pt-[12px]">
          <InputField
            placeholder="Search"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            className="rounded-[5px] border-[0.5px] !border-silver px-2 outline-none md:w-[350px]"
          />
          <SelectContainer>
            <TableHeaderSelect options={options} onChange={handleChange} />
          </SelectContainer>
          <Button
            neutral
            onClick={search}
            disabled={data?.length < 1}
            className="w-[96px] h-[41px] bg-headerbtnBg flex justify-center items-center border-[0.5px] border-gray rounded-[5px] text-sm font-normal !text-black"
          >
            Search
          </Button>
        </div>
      </div>
    </>
  );
};

export default TableHeaderFunder;

const SelectContainer = styled.div`
  min-width: 180px;
  @media screen and (max-width: 470px) {
    min-width: auto;
  }
`;
