// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-flow {
  position: relative;
  display: inline-block;
  /* width: 49px;
  height: 28px; */
}

.switch-flow input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-flow {
  position: absolute;
  cursor: pointer;
  border-radius: 34px;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: gray;
  transition: 0.4s;
}

.slider-flow:before {
  position: absolute;
  border-radius: 50%;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider-flow {
  background-color: #4caf50;
}

input:checked + .slider-flow:before {
  transform: translate(12px);
}
`, "",{"version":3,"sources":["webpack://./src/features/settings/components/WorkflowConfig/components/Capsule/capsule.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB;iBACe;AACjB;;AAEA;EACE,UAAU;EACV,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;;EAEnB,MAAM;EACN,OAAO;EACP,SAAS;EACT,QAAQ;;EAER,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".switch-flow {\n  position: relative;\n  display: inline-block;\n  /* width: 49px;\n  height: 28px; */\n}\n\n.switch-flow input {\n  opacity: 0;\n  width: 0;\n  height: 0;\n}\n\n.slider-flow {\n  position: absolute;\n  cursor: pointer;\n  border-radius: 34px;\n\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n\n  background-color: gray;\n  transition: 0.4s;\n}\n\n.slider-flow:before {\n  position: absolute;\n  border-radius: 50%;\n  content: \"\";\n  height: 10px;\n  width: 10px;\n  left: 4px;\n  bottom: 4px;\n  background-color: white;\n  transition: 0.4s;\n}\n\ninput:checked + .slider-flow {\n  background-color: #4caf50;\n}\n\ninput:checked + .slider-flow:before {\n  transform: translate(12px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
