import React, { useState } from "react";
import { PAIThickArrowDropDown } from "assets/images/svgs";

const TransferInfoModal = ({
  isOpen,
  onClose,
  onConfirm,
  textareaValue,
  onChange,
  disputeTypeData,

  labelTitleText,
  bodyText,
  placeholderText,
}) => {
  const [isTransferInfoPinOpen, setIsTransferInfoPinOpen] = useState(false);

  const [toggle, setToggle] = useState(false);
  const [disputeType, setDisputeType] = useState("Select dispute type");

  const handleNextClick = (e) => {
    e.preventDefault();
    onConfirm();
    setIsTransferInfoPinOpen(true);
    onClose();
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const handleSelect = (item) => {
    setDisputeType(item);
    setToggle(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const isDisabled =
    textareaValue === "" ||
    disputeType === "Select dispute type" ||
    textareaValue === " ";
  if (!isOpen && !isTransferInfoPinOpen) return null;

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
          <div className="bg-white pb-5 rounded-md  md:max-w-lg w-full   max-w-[40rem]   md:pb-[32px] mx-auto lg:min-w-[600px] lg:min-h-[512px]">
            <div className="flex justify-between items-center mb-4 bg-[#FAFAFA] w-full py-4 rounded-t-md px-5">
              <div class="text-[#7A7A7A] text-sm font-thin ">
                Raise a dispute
              </div>
              <button
                onClick={onClose}
                className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
              >
                &times; <span className="text-sm mx-2 mt-1">Close</span>
              </button>
            </div>
            {/* horizontal padding added here */}
            <div className=" px-8">
              <div>
                <form
                  className="w-full px-7 justify-center items-center  mt-16"
                  onSubmit={handleSubmit}
                >
                  <div className="mb-4">
                    <label className="text-neutral-800 text-sm font-medium   mb-2">
                      {labelTitleText}
                    </label>
                    {/* check this section */}
                    <div className=" text-[#222]  mt-5">
                      <div className="border-[0.7px] w-full py-2  border-t-0 border-x-0 text-[#222] focus:border-[#222] focus:outline-none flex flex-row  justify-between">
                        <div>
                          <div
                            class={` text-xs font-normal  tracking-wide ${
                              disputeType === "Select dispute type"
                                ? "text-[#7A7A7A]"
                                : "text-[#565656]"
                            }`}
                          >
                            {disputeType}
                          </div>
                        </div>
                        <div onClick={handleToggle}>
                          <PAIThickArrowDropDown />
                        </div>
                      </div>
                      <div
                        class={`${
                          toggle === false
                            ? "hidden"
                            : "w-full h-[151px] px-4 py-3.5 bg-white rounded-[5px] shadow border border-stone-300 flex-col justify-start items-start gap-3 inline-flex"
                        }`}
                      >
                        {disputeTypeData.map((item) => (
                          <div
                            class="text-[#565656] text-xs font-normal  tracking-wide"
                            onClick={() => handleSelect(item.type)}
                          >
                            {item.type}
                          </div>
                        ))}
                      </div>
                    </div>{" "}
                  </div>

                  <div className="mb-4">
                    <label className="text-neutral-800 text-sm font-medium font-sans  mb-2 mt-3">
                      {bodyText}
                    </label>

                    <textarea
                      type="text"
                      name="narration"
                      className="border-[0.7px] w-full py-2  border-t-0 border-x-0 border-[#222222] focus:outline-none h-[100px] mt-2 text-[#7A7A7A] text-sm font-normal"
                      multiple
                      cols="40"
                      rows="5"
                      placeholder={placeholderText}
                      value={textareaValue}
                      onChange={onChange}
                    ></textarea>
                  </div>

                  {/* Buttons */}
                  <div className="flex justify-end mt-10">
                    <div className="w-[105px] h-[35px] px-6 py-2 rounded-[5px] justify-center items-center gap-2.5 inline-flex  mx-2">
                      <button
                        type="button"
                        onClick={onClose}
                        className="text-center text-[#7A7A7A] text-base font-normal  tracking-wide"
                      >
                        Cancel
                      </button>
                    </div>
                    <button
                      className={`w-[105px] h-[35px] px-6 py-2 rounded-[5px] justify-center items-center gap-2.5 inline-flex  ${
                        isDisabled
                          ? "bg-[#BFCBC7]  text-white"
                          : "bg-primaryColor text-white"
                      } `}
                      disabled={isDisabled}
                    >
                      <div
                        className="text-center   text-base font-normal  tracking-wide"
                        onClick={handleNextClick}
                      >
                        Submit
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TransferInfoModal;
