import React, { useEffect, useState } from "react";
import SingleEarlyPayment from "../singleDirectFunding/Table";
import BulkEarlyPayment from "../bulkDirectFunding/Table";
import { useDispatch, useSelector } from "react-redux";
import { allNewDirectFunding } from "appstate/funder/funderSlice";
import AcceptModal from "shared/AcceptModal";
import toast from "react-hot-toast";
import { PAIconHappy } from "assets/images/svgs";

const SingleBulkOption = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [reject, setReject] = useState(false);
  const [activeTab, setActiveTab] = useState("single");
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const dispatch = useDispatch();
  const { newDirectFundingData, isLoading } = useSelector(
    (state) => state?.funder
  );

  const [data, setData] = useState([]);

  const search = "";

  useEffect(() => {
    dispatch(
      allNewDirectFunding({
        page,
        pageSize,
        bulk: activeTab === "single" ? false : true,
        search,
        status: "APPROVED",
      })
    );
  }, [page, pageSize, dispatch, activeTab]);

  useEffect(() => {
    setData(newDirectFundingData?.data);
  }, [newDirectFundingData]);

  const totalPages = newDirectFundingData?.meta?.totalElements;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setReject(false);
  };

  const handleCheckboxChange = (row) => {
    const selectedIndex = selectedRows.indexOf(row.id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, row.id];
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== row.id);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allIds = data?.map((row) => row.id);
      setSelectedRows(allIds);
    }
    setSelectAll(!selectAll);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "single":
        return (
          <SingleEarlyPayment
            handleSelectAll={handleSelectAll}
            handleCheckboxChange={handleCheckboxChange}
            SingleDirectFundingPaymentList={data}
            selectedRows={selectedRows}
            setPage={setPage}
            startPage={startPage}
            setStartPage={setStartPage}
            endPage={endPage}
            setEndPage={setEndPage}
            totalPages={totalPages}
            page={page}
            isLoading={isLoading}
          />
        );
      case "bulk":
        return (
          <BulkEarlyPayment
            handleSelectAll={handleSelectAll}
            handleCheckboxChange={handleCheckboxChange}
            BulkEarlyPaymentList={data}
            selectedRows={selectedRows}
            setPage={setPage}
            startPage={startPage}
            setStartPage={setStartPage}
            endPage={endPage}
            setEndPage={setEndPage}
            totalPages={totalPages}
            page={page}
            isLoading={isLoading}
          />
        );
      default:
        return null;
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    toast.custom(
      <div className="bg-white shadow-lg w-[529px] p-[27px] rounded-[10px] mt-[50px]">
        <header>
          <h1 className="text-textColor text-base font-bold">
            Direct Funding request accepted
          </h1>
        </header>
        <div className="flex justify-center my-[24px]">
          <PAIconHappy />
        </div>
        <p className="text-textColor text-base ">
          Congratulations! The Direct Funding request has been approved. Your
          understanding and cooperation are greatly appreciated. Thank you.
        </p>
      </div>
    );
  };

  return (
    <div className="mt-3 gap-2">
      <AcceptModal
        message={
          reject
            ? "Would you like to proceed with Rejecting this Direct Funding request?"
            : "Would you like to proceed with Approving this Direct Funding request?"
        }
        isOpen={open}
        onClose={handleCloseModal}
        onConfirm={handleConfirm}
      />
      <div className="bg-white rounded-xl mt-5">
        <div className="flex justify-between itemscenter p-6 bg-[#FAFAFA] h[70px] rounded-t-[10px] mt-3">
          <div className="py5">
            <p className="text-black text-lg font-bold">
              Approved Direct Funding Requests
            </p>
            <span className="text-sm font-thin text-[#5F4D61]">
              List of all approved Direct Funding requests you've accepted
            </span>
          </div>

          <div className=" w-fit flex flex-col gap-2 extra-md:inline-block extra-md:space-x-3">
            <button className="bg-[#2EA923] text-sm py-2  px-[16px] rounded-[5px] text-white">
              Select all
            </button>
            <button
              onClick={() => setOpen(true)}
              disabled={selectedRows.length < 1}
              className={`disabled text-sm py-[8px] px-[16px] rounded-[5px] text-white ${
                selectedRows?.length < 1 ? "bg-gray" : "bg-secondaryColor"
              }`}
            >
              Initiate Disbursement
            </button>
          </div>
        </div>
        <div className="mt-3 px-[15px] text-sm bgwhite">
          <div className="flex gap-3">
            <p
              className={`cursor-pointer font-bold relative ${
                activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
              }`}
              onClick={() => handleTabClick("single")}
            >
              Single requests
              {activeTab === "single" && (
                <span
                  className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                ></span>
              )}
            </p>

            <p
              className={`cursor-pointer font-bold relative ${
                activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
              }`}
              onClick={() => handleTabClick("bulk")}
            >
              Bulk requests
              {activeTab === "bulk" && (
                <span
                  className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                ></span>
              )}
            </p>
          </div>

          {/* Render the tab content */}
          <div className="mt-3">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default SingleBulkOption;
