import { PAIconPaymnet } from "assets/images/svgs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AllRecentActivitiesModal from "./AllRecentActivitiesModal";

const RecentTables = () => {
  const { dashboardData } = useSelector((state) => state?.invoice);
  const [isViewAllRecentActivitiesOpen, setIsViewAllRecentActivitiesOpen] = useState(false)

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const hasRecentActivities = dashboardData?.recentActivities?.data?.length > 0;

  return (
    <div className="bg-white p-[24px] mt-5 rounded-xl w-[40%] basis2/5 ml-8">
      <div className="flex justify-between mb-2 pb-4 border-b-2 border-[#ECECEC]">
        <p>Recent Activities</p>
        {hasRecentActivities && <p className="cursor-pointer" onClick={() => setIsViewAllRecentActivitiesOpen(true)}>View all</p>}
      </div>
      {hasRecentActivities ? (
        dashboardData?.recentActivities?.data?.map((invoice, index) => (
          <div key={index} className="invoice-item text-sm gap5">
            <div className="flex justify-between py-3 items-center border-b-2 border-[#ECECEC]">
              <div className="flex items-center">
                <div className="bg-[#376130] p-3 rounded-lg mr-2">
                  <PAIconPaymnet className="w-4 h-4" />
                </div>
                <div>
                  <h2>{invoice?.message}</h2>
                  <p>{formatAmount(invoice?.amount)}</p>
                </div>
              </div>
              <div>
                <p className="font-bold">Invoice | {invoice?.reference}</p>
                <p className="text-right">View</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center py-5">
          <p>There are no records to display</p>
        </div>
      )}
      {isViewAllRecentActivitiesOpen && (
        <AllRecentActivitiesModal
          onClose={() => setIsViewAllRecentActivitiesOpen(false)}
        />
      )}
    </div>
  );
};

export default RecentTables;
