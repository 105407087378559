import DashboardLayout from 'layout/dashboardlayout'
import React from 'react'
import Details from './details'

const BulkApproved = () => {
  return (
    <DashboardLayout
    section="Payment Extension"
    subHeader="Send and track payment extension processes"
    >
      <Details />
    </DashboardLayout>
  )
}

export default BulkApproved