import { enrollUser2FA } from "appstate/settings/settingsSlice";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Button from "shared/Button";
import InputField from "shared/InputField";
import Spinner from "shared/Spinner/Spinner";

const ConfirmEnrollContent = ({ onClose, next }) => {
  const { qrImageUri, qrSecret, isLoading } = useSelector(
    (state) => state.settings
  );
  const dispatch = useDispatch();

  return (
    <div className="px-12 pb-7">
      <div>
        <div className="space-y-2">
          <p className="font-bold text-[24px]">
            Please confirm enrollment for two-factor authentication (2FA)
          </p>
          <p className="text-sm text-gray-500">
            Authenticator apps and browser extensions like 1Password, Authy,
            Microsoft Authenticator, etc. generate one-time passwords that are
            used as a second factor to verify your identity when prompted during
            sign-in.
          </p>
        </div>
        <div className="py-5 space-y-1">
          <p className="font-bold">Scan the QR code</p>
          <p className="text-sm text-gray-500">
            Use an authenticator app or browser extension to scan
          </p>
        </div>
        <div className="pb-3 w-[150px] h-[150px]">
          <img
            className="w-full h-full object-cover"
            src={qrImageUri ? qrImageUri : ""}
            alt="2FA-QRcode"
          />
        </div>
        <p className="text-sm text-gray-500">
          Unable to scan? You can use the setup key to manually configure your
          authenticator app.
        </p>
      </div>
      <div className="py-4 flex flex-col gap-4">
        <div className="flex w-full flex-col">
          <InputField
            disabled
            label="Verify the code from the app"
            id="verify-code"
            name="verify-code"
            placeholder="********"
            className="h-[45px] !border-none bg-wildSand rounded-md p-[13px] outline-none"
            value={qrSecret ? qrSecret : ""}
          />
        </div>
      </div>
      <div className="flex justify-end gap-3">
        <Button
          type="button"
          onClick={onClose}
          className="flex !text-[14px] w-[120px] !px-6 !py-3 justify-center items-center bg-wildSand border-none !rounded-[10px] h-[40px]"
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={() => {
            dispatch(enrollUser2FA()).then((data) => {
              if (data?.payload?.status === 200) {
                toast.success("2FA enrolled successfully!");
                next();
              }
            });
          }}
          className="flex !text-[14px] w-[120px] !px-6 !py-3 justify-center  items-center !bg-emerald border-none !rounded-[10px] h-[40px] hover:bg-green-400"
        >
          {isLoading ? <Spinner /> : "Confirm"}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmEnrollContent;
