import {
  PAIconArrowLeftGreen,
  PAIconCocacola,
  PAIconDownload,
  // PAIconMDISuccess,
  PAIconModelOne,
} from "assets/images/svgs";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AcceptModal from "./acceptModal";
import RejectModal from "./rejectModal";
import { useSelector } from "react-redux";
// import { approveOrDeclineEarlyPayment } from "appstate/buyer/buyerSlice";
// import toast from "react-hot-toast";
import FundingOptionsModal from "features/invoice/buyerInvoice/Modals/FundingOptionsModal";
import TransferFundsSetupModal from "features/invoice/buyerInvoice/Modals/TransferFundsSetupModal";
import SelectAccountModal from "features/invoice/buyerInvoice/Modals/SelectAccountModal";
import EnterPin from "features/invoice/buyerInvoice/Modals/EnterPin";
import approved from "../../../../../../assets/images/svgs/approved.svg";
import rejected from "../../../../../../assets/images/svgs/rejected.svg";
import RejectReason from "features/invoice/buyerInvoice/Modals/RejectReason";
import RejectionSuccess from "features/invoice/buyerInvoice/Modals/RejectionSuccess";
import ThirdPartyModal from "features/BuyerEarlyPayment/EarlyPayment/singleEarlyPayment/Modals/ThirdPartyModal";
// import { useDispatch } from "react-redux";
// import { approveOrDeclineEarlyPayment } from "appstate/buyer/buyerSlice";
// import toast from "react-hot-toast";

const Details = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [isSelectAccountModalOpen, setSelectAccountModalOpen] = useState(false);
  const [isenterPinModal, setIsEnterPinModal] = useState(false);
  const [paymentViaWallet, setPaymentViaWallet] = useState(false);
  const [isRequestRejected, setIsRequestRejected] = useState(false);
  const [isRejectReasonModal, setIsRejectReasonModal] = useState(false);
  const [isRejectSuccessModal, setIsRejectSuccessModal] = useState(false);
  const [isThirdPartyModal, setIsThirdPartyModal] = useState(false);
  const [isFundingModalOpen, setIsFundingModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [fromBuyer, setFromBuyer] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [rejectedReason, setRejectedReason] = useState([]);

  // const dispatch = useDispatch();

  const { getInvoiceId, allNewEarlyPaymentRequestData, isLoading } =
    useSelector((state) => state?.buyer);

  const invoiceData = allNewEarlyPaymentRequestData?.data?.find(
    (cur) => cur?.id === getInvoiceId
  );

  // console.log(invoiceData);
  // console.log(getInvoiceId);

  const handleAccept = () => {
    setIsModalOpen(true);
  };

  const handleAcceptRequest = () => {
    setIsModalOpen(false);
    setIsFundingModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRejectModalOpen(false);
  };

  const handleReject = () => {
    setRejectModalOpen(true);
  };

  //funding Request modal control
  const handleFundingRequest = () => {
    setIsFundingModalOpen(false);
    setIsTransferModalOpen(true);
  };

  const handleFundingRequestClose = () => {
    setIsFundingModalOpen(false);
  };

  //Transfer modal control

  const handleTransferRequest = () => {
    setSelectAccountModalOpen(true);
    setIsTransferModalOpen(false);
  };

  const handleTransferModalClose = () => {
    setIsTransferModalOpen(false);
  };

  // Select account controls
  const handleSelectAccountClose = () => {
    setSelectAccountModalOpen(false);
  };

  const handleSelectAccountProceed = () => {
    setSelectAccountModalOpen(false);
    setIsTransferModalOpen(true);
  };

  //Enter pin controls
  const handleEnterPin = () => {
    setIsEnterPinModal(true);
    setIsTransferModalOpen(false);
  };

  const handleEnterPinModalClose = () => {
    setIsEnterPinModal(false);
  };

  //Reject modal control
  const handleRejectRequest = () => {
    setRejectModalOpen(false);
    setIsRejectReasonModal(true);
  };
  const handleRejectReasonClose = () => {
    setIsRejectReasonModal(false);
  };

  //Success modal control
  const handleSuccessModal = () => {
    setIsRejectReasonModal(false);
    setIsRejectSuccessModal(true);
  };

  const handleSuccessModalClose = () => {
    setIsRejectSuccessModal(false);
  };

  //third party modal
  const handleThirdPartyModal = () => {
    setIsThirdPartyModal(true);
    setIsFundingModalOpen(false);
  };
  const handleThirdPartyModalClose = () => {
    setIsThirdPartyModal(false);
  };

  // const handleAcceptRequest = () => {
  //   dispatch(
  //     approveOrDeclineEarlyPayment({
  //       id: getInvoiceId,
  //       comment: "",
  //       status: "APPROVED",
  //     })
  //   ).then((data) => {
  //     if (data?.payload?.success) {
  //       toast.custom(
  //         <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
  //           <div className="flex">
  //             <div className="bg-[#07593D] p-10 shadow-md rounded-s text-center text-white">
  //               <PAIconMDISuccess />
  //             </div>
  //             <div className="bg-white py-10 px-3 flex flex-col items-center justify-center shadow-md text-center rounded-e">
  //               <p className="text-[#959595] text-center font-sans text-xs font-normal leading-normal tracking-[0.321px] mb-3 mt-3">
  //                 The Early payment request has been approved!
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     }
  //   });
  // };

  return (
    <>
      <Link
        to="/invoice/invoice"
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </Link>

      <div className="grid lg:grid-cols-[60%,40%] gap-5">
        {/* 60% */}
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <h1 className="text-[18px] font-bold">
                  Invoice Details | INV-{invoiceData?.invoiceNumber}
                </h1>
                {paymentViaWallet === true ? (
                  <button className="w-[135px] h-[45px] rounded-[5px] bg-[#07593D] text-[white]">
                    Disburse Now
                  </button>
                ) : null}
              </div>

              <main className=" w-full flex justify-between">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex gap-x-[5px]">
                    <span>Reference No:</span>
                    <span>{invoiceData?.invoiceReference}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Created Date:</span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.createdDate}
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs">Payment Due Date:</span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceData?.dueDate}
                      </span>
                    </div>
                  </div>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className="font-bold text-sm">Payment</span>
                <span className="text-[#979797]  text-xs">60 Days</span>
              </div>
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Currency</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceData?.currency}
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-[18px] font-bold">
                  Supplier/buyer details
                </h1>
              </header>
              <div className="flex  justify-between mb-5">
                <div className="flex gap-2">
                  <PAIconCocacola />
                  <div className="flex flex-col">
                    <span className=" font-bold text-sm">Supplier</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.supplierCompanyName}
                    </span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <PAIconModelOne />
                  <div className="flex flex-col">
                    <span className=" font-bold text-sm">Buyer</span>
                    <span className="text-[#979797]  text-xs">
                      {" "}
                      {invoiceData?.buyerCompanyName}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Line Items</h1>
              </header>
              <div className=" w-full overflow-x-auto">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-subtleGray w-">
                    <tr className=" bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Tax rate
                      </td>
                      <td className=" p-2.5 text-xs font-bold">Item name</td>
                      <td className=" p-2.5 text-xs font-bold">
                        Product/Service Description
                      </td>
                      <td className=" p-2.5 text-xs font-bold">Quantity</td>
                      <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                      <td className=" p-2.5 text-xs font-bold">
                        Line Item Extended Amount
                      </td>
                    </tr>
                  </thead>
                  {invoiceData?.items?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxRate}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.quantity}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.unitPrice}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.amount}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Attachments</h1>
              </header>
              <div className="w-full overflow-x-auto">
                <table className="w-full overflow-x-auto">
                  <thead className="  bg-subtleGray w-">
                    <tr className="px-4  bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                        Document Name
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">
                        File Type
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">
                        Upload Date / Time
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className=" py-[3px] px-1.5">
                      <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                        DOC-29384.pdf
                      </td>
                      <td className=" text-xs text-[#11192A] px-4 py-2.5">
                        PDF
                      </td>
                      <td className=" text-xs text-[#11192A] px-4 py-2.5">
                        2021-09-14 13:25
                      </td>
                      <td className="cursor-pointer text-xs text-[#11192A] px-4 py-2.5">
                        <PAIconDownload />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 px-6 pt-7 pb-24">
          <div>
            <h2 className="font-semibold text-lg leading-[30px] mb-4">
              Early Payment Details
            </h2>

            <div className="flex justify-between my-5">
              <div className="flex gap-2">
                <PAIconModelOne />
                <div className="flex flex-col">
                  <span className="text-sm">Supplier</span>
                  <span className="text-[#979797]  text-xs">
                    {" "}
                    {invoiceData?.supplierCompanyName}
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">1.6%</span>
                <span className="text-[#979797]  text-xs">Discount rate</span>
              </div>
            </div>

            <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
              <div className="flex gap-2">
                <div className="flex flex-col">
                  <span className="text-sm">N{invoiceData?.total}</span>
                  <span className="text-[#979797]  text-xs">
                    Discount Amount
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-sm">23-Dec-2024(90days)</span>
                <span className="text-[#979797]  text-xs">
                  Early Payment Request Date
                </span>
              </div>
            </div>

            <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
              <h2 className="font-medium text-base my-3">
                Note from Supplier:
              </h2>
              <p className="text-[#959595] text-sm">
                Seeking Early payment due to unexpected challenges. Facing
                temporary financial constraints and need a bit more time to
                ensure a smooth transaction. Appreciate your understanding.
                Thank you. Best regards, ABC Limited
              </p>
            </div>

            {paymentViaWallet === true || isRequestRejected === true ? null : (
              <div className="pb-3">
                <h2 className="font-medium text-base leading-[30px] my-2">
                  Your decision:
                </h2>
                <p className="text-[#959595] text-sm">
                  Early Payment Request: Please review and take action. Accept
                  if approved, or Reject if not suitable. Thank you
                </p>
                <div className="flex gap-5 mt-5">
                  <button
                    className="rounded-md bg-[#07593D] text-white px-12 py-2"
                    onClick={handleAccept}
                  >
                    Accept
                  </button>
                  <button
                    className="rounded-md bg-white border border-[#F08000] text-[#F08000] px-12 py-2"
                    onClick={handleReject}
                  >
                    Reject
                  </button>
                </div>
              </div>
            )}

            {isRequestRejected === true ? (
              <div className=" pt-[3%]">
                <div className="border-b-[3px] border-[#EFEFEF] mb-[4%] pb-[3%]">
                  <h2 className="text-[16px] font-normal">
                    You rejected this invoice based on
                    {rejectedReason?.map((option) => (
                      <span
                        key={option.id}
                        className="rounded-md font-semibold ml-2"
                      >
                        {option.label}
                      </span>
                    ))}
                    <span className="ml-[3px]">reasons</span>
                  </h2>
                </div>

                <div className="flex items-center justify-center mt-[3%]">
                  <img src={rejected} alt="Rejected" />
                </div>
              </div>
            ) : null}

            {paymentViaWallet === true ? (
              <div className=" pt-[3%]">
                <div className="border-b-[3px] border-[#EFEFEF] mb-[4%] pb-[3%]">
                  <h2 className="text-[16px] font-semibold">Funding Option</h2>
                  <p>This invoice will be funded with this virtual account:</p>
                  <p>{selectedAccount.accountName}</p>
                  <p>{selectedAccount.accountNumber}</p>
                  <p>{selectedAccount.bankName}</p>
                </div>

                <div className="flex items-center justify-center mt-[3%]">
                  <img src={approved} alt="Approved" />
                </div>
              </div>
            ) : null}

            <AcceptModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              isLoading={isLoading}
              confirm={handleAcceptRequest}
            />
            <FundingOptionsModal
              isOpen={isFundingModalOpen}
              onClose={handleFundingRequestClose}
              confirm={handleFundingRequest}
              thirdParty={handleThirdPartyModal}
              setFromBuyer={setFromBuyer}
            />
            <TransferFundsSetupModal
              isOpen={isTransferModalOpen}
              onClose={handleTransferModalClose}
              confirm={handleEnterPin}
              proceed={handleTransferRequest}
              selectedAccount={selectedAccount}
            />
            <SelectAccountModal
              isOpen={isSelectAccountModalOpen}
              onClose={handleSelectAccountClose}
              setSelectedAccount={setSelectedAccount}
              confirm={handleSelectAccountProceed}
            />
            <EnterPin
              isOpen={isenterPinModal}
              onClose={handleEnterPinModalClose}
              confirm={handleEnterPinModalClose}
              setPaymentViaWallet={setPaymentViaWallet}
            />
            <RejectModal
              isOpen={isRejectModalOpen}
              onClose={handleCloseModal}
              confirm={handleRejectRequest}
            />
            <RejectReason
              isOpen={isRejectReasonModal}
              onClose={handleRejectReasonClose}
              confirm={handleSuccessModal}
              setIsRequestRejected={setIsRequestRejected}
              setRejectedReason={setRejectedReason}
            />
            <RejectionSuccess
              isOpen={isRejectSuccessModal}
              onClose={handleSuccessModalClose}
            />
            <ThirdPartyModal
              isOpen={isThirdPartyModal}
              onClose={handleThirdPartyModalClose}
              fromBuyer={fromBuyer}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
