import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import OnboardingLayout from "features/onboarding/category/components/OnboardingLayout";
import { useState } from "react";

const BuyerOnboardSupplierTerms = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = (e) => {
    e.preventDefault();
    if (checked) {
      let path = "/buyer-onboard-supplier/get-started";
      navigate(path);
    }
  };
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  return (
    <OnboardingLayout>
      <div className="px-[20px] h-[100%] mb-[30px]  pt-[60px] overflow-auto lg:px-[50px]">
        <div className="mb-[50px] mt-[30px] flex justify-center items-center gap-x-[20px]">
          <div>
            <div className="w-[100px] h-[2px] bg-primaryColor rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[13px] md:bottom-[15px]">
            <p className="font-normal text-[10px] text-textColor mb-[10px] md:text-sm">
              Terms & Conditions
            </p>
            <div className="w-[100px] h-[2px] bg-primaryColor rounded-[100px] md:w-[220px]"></div>
          </div>
          <div>
            <div className="w-[100px] h-[2px]   bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
          </div>
        </div>
        <h1 className="font-semibold text-[28px] text-primaryColor text-center">
          Review and agree
        </h1>
        <p className="font-medium text-base text-textColor mt-[35px] text-center">
          Please review and agree to the terms of agreement to register to the
          PayEdge Program
        </p>
        <div className="md:flex justify-center">
          <div className="h-[100%] md:w-[722px]">
            <div className="border h-[100%] rounded-[5px] border-gray500 mt-[29px] pb-[50px]">
              <div className="h-[47px] flex items-center pl-[30px] bg-gray500">
                <p className="font-semibold text-small text-primaryColor">
                PayEdge Terms and Conditions
                </p>
              </div>
              <div className=" px-[15px] h-[100%] py-[20px] overflow-y-scroll md:px-[30px] lg:px-[50px] lg:py-[30px]">
                <p className="font-semibold text-xs text-[#424242] leading-5 md:leading-6">
                  Terms and Conditions ("Terms") Our Terms and Conditions were
                  last updated on [DATE]. Please read these terms and conditions
                  carefully before using Our Service. Interpretation and
                  Definitions Interpretation The words of which the initial
                  letter is capitalized have meanings defined under the
                  following conditions. The following definitions shall have the
                  same meaning regardless of whether they appear in singular or
                  in plural. Definitions For the purposes of these Terms and
                  Conditions
                </p>
                <ul className="list-disc font-semibold text-xs text-[#424242] leading-5 md:leading-6">
                  <li>
                    “Account” means a unique account created for You to access
                    our Service or parts of our Service.
                  </li>
                  <li>
                    “Company” (referred to as either "the Company", "We", "Us"
                    or "Our" in this Agreement) refers to [COMPANY_INFORMATION].
                  </li>
                  <li>“Country” refers to [COMPANY_COUNTRY].</li>
                  <li>
                    “Content” refers to content such as text, images, or other
                    information that can be posted, uploaded, linked to or
                    otherwise made available by You, regardless of the form of
                    that content.
                  </li>
                  <li>
                    “Device” means any device that can access the Service such
                    as a computer, a cellphone or a digital tablet.
                  </li>
                  <li>
                    Feedback” means feedback, innovations or suggestions sent by
                    You regarding the attributes, performance or features of our
                    Service.
                  </li>
                  <li>
                    “Products” refer to the products or items offered for sale
                    on the Service.
                  </li>
                  <li>
                    “Orders” mean a request by You to purchase Products from Us.
                  </li>
                  <li>
                    “Promotions” refer to contests, sweepstakes or other
                    promotions offered through the Service.
                  </li>
                  <li>“Service” refers to the Website.</li>
                  <li>
                    “Terms and Conditions” (also referred as "Terms") mean these
                    Terms and Conditions that form the entire agreement between
                    You and the Company regarding the use of the Service. This
                    Terms and Conditions Agreement was generated by TermsFeed
                    Terms and Conditions Generator.
                  </li>
                  <li>
                    “Third-party Social Media Service” means any services or
                    content (including data, information, products or services)
                    provided by a third-party that may be displayed, included or
                    made available by the Service.
                  </li>
                  <li>
                    “Website” refers to [WEBSITE_NAME], accessible from
                    [WEBSITE_URL]
                  </li>
                  <li>
                    “You” means the individual accessing or using the Service,
                    or the company, or other legal entity on behalf of which
                    such individual is accessing or using the Service, as
                    applicable.
                  </li>
                </ul>
                <p className="font-semibold text-xs text-[#424242] leading-5 md:leading-6">
                  Acknowledgment These are the Terms and Conditions governing
                  the use of this Service and the agreement that operates
                  between You and the Company. These Terms and Conditions set
                  out the rights and obligations of all users regarding the use
                  of the Service. Your access to and use of the Service is
                  conditioned on Your acceptance of and compliance with these
                  Terms and Conditions. These Terms and Conditions apply to all
                  visitors, users and others who access or use the Service. By
                  accessing or using the Service You agree to be bound by these
                  Terms and Conditions. If You disagree with any part of these
                  Terms and Conditions then You may not access the Service. You
                  represent that you are over the age of 18. The Company does
                  not permit those under 18 to use the Service. Your access to
                  and use of the Service is also conditioned on Your acceptance
                  of and compliance with the Privacy Policy of the Company. Our
                  Privacy Policy describes Our policies and procedures on the
                  collection, use and disclosure of Your personal information
                  when You use the Application or the Website and tells You
                  about Your privacy rights and how the law protects You. Please
                  read Our Privacy Policy carefully before using Our Service.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[30px] md:flex flex-col items-center">
          <div>
            <label
              onClick={handleChange}
              className="font-medium text-sm text-textColor flex justify-center cursor-pointer"
            >
              <input
                type="checkbox"
                className="accent-primaryColor mr-[10px]"
              />
              <p className="text-xs text-textColor text-normal">
                I agree to the PayEdge Terms and Conditions
              </p>
            </label>

            <Button
              onClick={handleNavigate}
              auth
              className="my-[30px]  w-full md:w-[424px]"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default BuyerOnboardSupplierTerms;
