import {
  allVirtualAccounts,
  // eachVirtualAccount,
  editVirtualAccount,
} from "appstate/wallet/walletSlice";
import React from "react";
// import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "shared/Spinner/Spinner";

const AccountDetails = ({ isOpen, onClose, onSubmit, editedData, editClick }) => {

  const dispatch = useDispatch();

  // const isLoading = useSelector((state) => state.wallet.isLoading);
  const {isLoading, virtualAccountId} = useSelector(state => state?.wallet)

  if (!isOpen) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const [firstName, ...lastNameArray] = editedData?.accountName.split(" ");
    const lastName = lastNameArray.join(" ");

    const body = {
      firstName: firstName,
      lastName: lastName,
      bvn: editedData?.bvn,
    };

    dispatch(editVirtualAccount({ accountId: virtualAccountId, body })).then(data => {
      if (data?.meta?.requestStatus === "fulfilled") {
        dispatch(allVirtualAccounts())
        onSubmit();
      }
    })
  };

  const handleEditClick = () => {
    editClick()
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Edit Virtual Account
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        {/* FORM */}
        <div>
          <div className="w-full px-7" onSubmit={handleSubmit}>
            <p className="my-7 text-[#222] font-sans text-sm font-bold">
              Account Information
            </p>
            <div className="mb-10">
              <div className="text-neutral-800 text-[13px] font-semibold">
                Name:{" "}
                <span className="font-normal">
                  {editedData?.accountName || "N/A"}
                </span>
              </div>
              <div className="text-neutral-800 text-[13px] font-semibold my-5">
                Virtual Account Number :{" "}
                <span className="font-normal">
                  {editedData?.accountNumber || "N/A"}
                </span>
              </div>
              <div className="text-neutral-800 text-[13px] font-semibold">
                BVN Number :{" "}
                <span className="font-normal">{editedData?.bvn || "N/A"}</span>
              </div>
            </div>
            <div className="text-amber-600 text-xs font-bold mb-5 flex justify-end cursor-pointer" onClick={handleEditClick}>
              Edit Account Info
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-10">
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-gray300 mx-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                >
                  Cancel
                </button>
              </div>
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]">
                <button
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isLoading ? isLoading : null}
                >
                  {isLoading ? <Spinner /> : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
