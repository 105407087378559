import React from 'react'
import Headers from '../headers'
import EarlyPaymentTable from './Table'

const EarlyPaymentRequest = () => {
  return (
    <div>
      <Headers />
      <EarlyPaymentTable />
    </div>
  )
}

export default EarlyPaymentRequest