import React from "react";

const CongratsModal = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-10 rounded-md max-w-xl w-full">
        <div className="flex flex-col items-center justify-center">
          <img
            src="/congratulations.jpg"
            className="mb-6 w-32"
            alt="Congratulations"
          />
          <div className="text-center text-[#07593D] font-'SF Pro Display' text-2xl italic font-extrabold leading-normal tracking-wide">
            Congratulations
          </div>
          <div className="text-[#7A7A7A] text-center text-xs font-normal leading-normal tracking-wide mt-5">
            {message}
          </div>
          <button
            onClick={onClose}
            className="text-center text-white text-base font-normal font-'SF Pro Display' tracking-wide px-4 py-2 rounded-md bg-[#07593D] mt-14"
          >
            Back to dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default CongratsModal;
