import React, { useState } from "react";
import WalletCard from "features/transactions/components/WalletCard";
import NewDebitCard from "./NewDebitCard";
import TransactionTable from "../transactions/TransactionTable";
import { useSelector } from "react-redux";
import LinkExternalModal from "./LinkExternalAccount";
import CreateVirtualAccountModal from "./CreateVirtualAccount";
import PinModal from "shared/PinModal";
import CongratsModal from "features/wallet/linkExternalAccount/congrats/congratsModal";

const WalletOverview = () => {
  const [isLinkExternalAccontModalOpen, setIsLinkExternalAccontModalOpen] =
    useState(false);

  const [isCreateVirtualAccountModalOpen, setIsCreateVirtualAccontModalOpen] =
    useState(false);
  const [showPinModal, setShowPinModal] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);

  const { userId } = useSelector((state) => state?.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  const cardContents = [
    {
      label: "Available Balance",
      amount: "₦40,000",
      percentage: "10",
      info: "Increase since January",
    },
    {
      label:
        userRole === "buyer" || userRole === "funder"
          ? "Liened Amount"
          : "Total Partial Payment",
      amount:
        userRole === "buyer" || userRole === "funder"
          ? "₦100,000"
          : "₦3,000,000",
      percentage: "40",
      info: "Increase since January",
    },
    {
      label: "Total Withdrawal",
      amount: "₦3,220,900",
      percentage: "25",
      info: "Increase since January",
    },
    {
      label: "Total Deposit",
      amount: "₦760,100",
      percentage: "30",
      info: "Increase since January",
    },
  ];

  return (
    <>
      <div className="flex justify-between gap-5">
        <div>
          <div className="flex flex-row gap-2 mb-3">
            <div
              className="rounded-md text-sm border border-gray300 bg-white px-5 py-2 cursor-pointer"
              onClick={() => {
                setIsLinkExternalAccontModalOpen(
                  !isLinkExternalAccontModalOpen
                );
              }}
            >
              Link External Account
            </div>
            <div
              className="rounded-md text-sm border border-gray300 bg-white px-5 py-2 cursor-pointer"
              // onClick={toggleCreateVirtualAccount}
              onClick={() => {
                setIsCreateVirtualAccontModalOpen(
                  !isCreateVirtualAccountModalOpen
                );
              }}
            >
              Create Virtual Account
            </div>
            <div
              className={`rounded-md text-sm border border-gray300 bg-white px-5 py-2 cursor-pointer`}
              // onClick={handleAccountManagement}
            >
              Account Management
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row gap-2 mb-3 justify-end">
            <div
              className={`rounded-md text-sm text-white border border-gray300 bg-[#07593D] px-5 py-2 cursor-pointer`}
              // onClick={toggleFund}
            >
              Fund Account
            </div>
            <div
              className={`rounded-md text-sm text-white border border-gray300 bg-[#07593D] px-5 py-2 cursor-pointer`}
              // onClick={toggleWithdraw}
            >
              Withdraw
            </div>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-[60%_40%] gap-3">
        {" "}
        <div className="bg-alabasterHeader py-[10px] px-2 rounded-md">
          <div className=" text-[#064731] mb-2 px-2">
            <p className="text-sm font-bold">My Account</p>
            <p className="text-xs font-normal">Account Summary</p>
          </div>
          <div className="flex flex-wrap justify-between mx-auto w-full">
            {cardContents.map((content, idx) => (
              <div
                key={idx}
                className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-6 px-2"
              >
                <WalletCard
                  label={content?.label}
                  amount={content?.amount}
                  percentage={content?.percentage}
                  info={content?.info}
                />
              </div>
            ))}
          </div>
        </div>
        {/* <DebitCard cardData={firstCardData} /> */}
        {/* <NewDebitCard /> */}{" "}
        <div className="hidden md:block">
          <NewDebitCard />
        </div>
      </div>

      {/* <OverviewTable /> */}
      <TransactionTable />

      {/* Conditionally render the modal */}
      {isLinkExternalAccontModalOpen && (
        <LinkExternalModal
          onClose={() => {
            setIsLinkExternalAccontModalOpen(false);
          }}
        />
      )}

      {isCreateVirtualAccountModalOpen && (
        <CreateVirtualAccountModal
          isOpen={isCreateVirtualAccountModalOpen}
          onClose={() => {
            setIsCreateVirtualAccontModalOpen(false);
          }}
          onPinOpen={() => {
            setShowPinModal(true);
            setIsCreateVirtualAccontModalOpen(false);
          }}
        />
      )}

      {showPinModal && (
        <PinModal
          isOpen={showPinModal}
          onClose={() => {
            setShowPinModal(false);
          }}
          onSubmit={(pin) => {
            setShowPinModal(false);
            setShowCongratsModal(true);
          }}
          headerText="Create Virtual Account"
          instructionText="Please enter your PIN to approve the creation of this virtual account."
        />
      )}

      {showCongratsModal && (
        <CongratsModal
          isOpen={showCongratsModal}
          onClose={() => {
            setShowCongratsModal(false);
          }}
          message={`Your Virtual Account has been created`}
        />
      )}
    </>
  );
};

export default WalletOverview;
