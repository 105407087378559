import React, { useState } from "react";
import RouteHeader from "../../../RouteHeader";
import {
  PAIconAlert24,
  PAIconContract,
  PAIconDropdown,
  PAIconIosArrow,
  PAIconMDIClock,
  PAIconMDIClock1,
  PAIconMore,
  PAIconReject,
  PAIconSign,
  PAIconStep1,
} from "assets/images/svgs";
import PermissionModal from "../../PermissionModal";
import DownloadModal from "../../DownloadModal";
import DeleteModal from "../../DeleteModal";
import SignModal from "../Modal/SignModal";
import RejectModal from "../Modal/RejectModal";
import UpdateHistory from "./UpdateHistory";
import NewAgreement from "../../NewAgreement/NewAgreement";
import LatestUpdate from "./LatestUpdate/LatestUpdate";
import GeneralComment from "./GeneralComment/GeneralComment";
import LatestUpdateModal from "./LatestUpdate/ModalLatestUpdate";
import ModalGeneralComment from "./GeneralComment/ModalGeneralComment";
import { useDispatch, useSelector } from "react-redux";
import { updateAgreement } from "appstate/toolsAndAgreement/toolsAndAgreementSlice";
import toast from "react-hot-toast";

const AgreementDetails = ({ agreement, goBack }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isPermissionModalOpen, setPermissionModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSignModalOpen, setSignModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [showUpdateHistory, setShowUpdateHistory] = useState(false);
  const [showNewAgreement, setShowNewAgreement] = useState(false);
  const [isLatestUpdateDropdownOpen, setLatestUpdateDropdownOpen] =
    useState(false);
  const [isGeneralCommentModalOpen, setGeneralCommentModalOpen] =
    useState(false);
  const [isLatestUpdateModalOpen, setLatestUpdateModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("general");
  const dispatch = useDispatch();

  const { allAgreementData, agreementId } = useSelector(
    (state) => state?.toolsAndAgreements
  );

  const details = allAgreementData?.data?.data?.find(
    (curr) => curr?.id === agreementId
  );

  const userId = details?.initiatorUserId;

  const handleSign = () => {
    const status = true;
    dispatch(updateAgreement({ agreementId, status })).then((data) => {
      if (data?.payload?.success === true) {
        toast.success("Agreement Signed");
        setSignModalOpen(true);
      }
    });
  };

  const handleReject = () => {
    const status = false;
    const agreementId = userId;
    dispatch(updateAgreement({ agreementId, status })).then((data) => {
      if (data?.payload?.success === true) {
        toast.success("Agreement Declined");
        setRejectModalOpen(true);
      }
    });
  };

  const toggleGeneralCommentModal = () => {
    setGeneralCommentModalOpen(!isGeneralCommentModalOpen);
  };

  const toggleLatestUpdateModal = () => {
    setLatestUpdateModalOpen(!isLatestUpdateModalOpen);
  };

  const toggleLatestUpdateDropdown = () => {
    setLatestUpdateDropdownOpen(!isLatestUpdateDropdownOpen);
  };

  const handleViewAll = () => {
    setShowUpdateHistory(true);
  };

  const handleBackFromUpdateHistory = () => {
    setShowUpdateHistory(false);
  };

  function handleBack() {
    setShowNewAgreement(false);
    setShowUpdateHistory(false);
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const togglePermissionModal = () => {
    setPermissionModalOpen(!isPermissionModalOpen);
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!isDeleteModalOpen);
  };

  // const toggleSignModal = () => {
  //   setSignModalOpen(!isSignModalOpen);
  // };

  // const toggleRejectModal = () => {
  //   setRejectModalOpen(!isRejectModalOpen);
  // };

  const handleUpdateButtonClick = () => {
    setShowNewAgreement(true);
  };

  const agreementUpdates = [
    {
      name: "latest update",
      date: "October 16, 2023",
      time: "7:15PM",
      comments: 12,
    },
    { name: "update1", date: "October 16, 2023", time: "4:56PM", comments: 5 },
    { name: "update2", date: "October 16, 2023", time: "5:30PM", comments: 8 },
    { name: "update3", date: "October 16, 2023", time: "6:45AM", comments: 3 },
    { name: "update4", date: "October 16, 2023", time: "8:45AM", comments: 22 },
  ];

  const agreementData = [
    {
      label: "Agreement Name",
      value: details?.name,
    },
    {
      label: "Agreement Initiator",
      value: details?.initiatorName,
    },
    {
      label: "Agreement Recipient",
      value: details?.recipientName,
    },
    {
      label: "Number of Parties Involved",
      value: 2,
    },
    {
      label: "Initiator Sign Status",
      value: details?.initiatorSignatureStatus ? "Signed" : "Not Signed",
    },
    {
      label: "Recipient Sign Status",
      value: details?.recipientSignatureStatus ? "Signed" : "Not Signed",
    },
    {
      label: "Number of Updates",
      value: details?.updateCount,
    },
    {
      label: "Date of Institution",
      value: details?.createdDate
        ? new Date(details.createdDate).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })
        : null,
    },
    {
      label: "Date of Finalization",
      value: details?.finalizedDate
        ? new Date(details.createdDate).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })
        : null,
    },
    {
      label: "Description",
      value: details?.description,
    },
  ];

  return (
    <div>
      {agreement && (
        <>
          <div className="px-7 mb-10">
            {showUpdateHistory ? (
              <RouteHeader
                Route1="Agreements"
                Heading="Update history"
                handleBack={handleBackFromUpdateHistory}
                icon={<PAIconIosArrow className="inline-block ml-2 mr-2" />}
                Route2={details?.name}
              />
            ) : (
              <>
                {showNewAgreement ? null : (
                  <div className="flex items-center justify-between">
                    <RouteHeader
                      Route1="Agreements"
                      Heading={details?.name}
                      handleBack={goBack}
                    />
                    <button
                      onClick={handleUpdateButtonClick}
                      className="bg-blue-400 rounded-md text-white p-1 px-4 mt-3 hover:bg-blue-300"
                    >
                      Update
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      {showUpdateHistory ? (
        <UpdateHistory
          agreementUpdates={agreementUpdates}
          goBack={handleBackFromUpdateHistory}
        />
      ) : showNewAgreement ? (
        <NewAgreement goBack={handleBack} />
      ) : (
        <>
          <div className="px-7 mb-10">
            <div className="flex items-center justify-between">
              <RouteHeader
                Route1="Agreements"
                Heading={details?.name}
                handleBack={goBack}
              />
              <button
                onClick={handleUpdateButtonClick}
                className="bg-blue-400 rounded-md text-white p-1 px-4 mt-3 hover:bg-blue-300"
              >
                Update
              </button>
            </div>
          </div>
          <div className="px-7 lg:grid grid-cols-2 mt-5 gap-5">
            {/* SECTION 1 */}
            <section className="w-full lg:w[50%] pr5">
              <h1 className="text-black font-sans font-semibold text-lg leading-normal">
                Latest update
              </h1>
              {/* CARD */}
              <div className="max-w-xl my-4 rounded-md shadow-sm cursor-pointer relative">
                <div className="bg-[#FFF8F3] p-4 flex items-center justify-center rounded-md">
                  <img
                    src="/A4.png"
                    alt="CardImage"
                    className="max-h-16 overflow-hidden"
                  />
                </div>
                <div className="bg-white p-4 flex justify-between items-center mb-[-10px] mt-[-20px]">
                  <div className="flex items-center">
                    <PAIconContract className="w6 h6 mr-2" />
                    <span className="font-semibold">{details?.name}</span>
                  </div>
                  <div className="flex items-center justify-end cursor-pointer">
                    <PAIconMore onClick={toggleDropdown} />
                  </div>
                  {isDropdownOpen && (
                    <div className="absolute top-full right-5 mt-[-40px] bg-white rounded-md shadow-md">
                      {/* Dropdown Content */}
                      <ul className="p-1">
                        <li className="py-1 px-4 hover:shadow-sm cursor-pointer text-black font-inter text-sm font-normal">
                          Preview
                        </li>
                        <li className="py-1 px-4 hover:shadow-sm text-sm cursor-pointer">
                          Print
                        </li>
                        <li
                          className="py-1 px-4 hover:shadow-sm text-sm cursor-pointer"
                          onClick={toggleModal}
                        >
                          Download
                        </li>
                        <li className="py-1 px-4 hover:shadow-sm text-sm cursor-pointer">
                          Share
                        </li>
                        <li className="py-1 px-4 hover:shadow-sm text-sm cursor-pointer">
                          Export Data
                        </li>
                        <li
                          className="py-1 px-4 hover:shadow-sm text-sm cursor-pointer"
                          onClick={togglePermissionModal}
                        >
                          Permission settings
                        </li>
                        <li
                          className="py-1 px-4 hover:shadow-sm text-sm cursor-pointer text-[#DE1515]"
                          onClick={toggleDeleteModal}
                        >
                          Delete agreement
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <p className="text-[#959595] font-sans text-xs font-normal px-4 mb-5 mt-[-15px] pb-5">
                  Last Updated:{" "}
                  {details?.lastModifiedDate
                    ? new Date(details.lastModifiedDate).toLocaleDateString(
                        "en-US",
                        {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                        }
                      )
                    : null}
                </p>
              </div>
              {/* BUTTONS */}
              <div>
                <button
                  className="bg-[#F08000] text-white p-2 px-4 rounded-md mr-5 hover:bg-[#f08000ed]"
                  onClick={handleSign}
                >
                  <span className="flex justify-center items-center gap-2">
                    Click to sign <PAIconSign />
                  </span>
                </button>
                <button
                  className="bg-[#CD3A1A] text-white p-2 px-4 rounded-md hover:bg-[#cd3b1aeb]"
                  onClick={handleReject}
                >
                  <span className="flex justify-center items-center gap-2">
                    Reject <PAIconReject />
                  </span>
                </button>
              </div>
              <div className="flex items-center justifycenter mt-2 gap-2">
                <PAIconAlert24 className="w-6" />
                <p className="text-orange-500 text-xs font-bold flex">
                  By clicking the “Click to sign” button, you're approving and
                  confirming your consent to the above agreement
                </p>
              </div>
              {/* AGREEMENT UPDATE HISTORY */}
              <div className="mb-10 max-h-[270px] shadowsm overflow-y-auto">
                <h1 className="text-black font-sans font-semibold text-lg leading-normal mt-5">
                  Agreement update history
                </h1>
                {agreementUpdates.map((update, index) => (
                  <div key={index} className="mt-3">
                    <div className="flex justify-between items-center">
                      {index === 0 ? <PAIconMDIClock /> : <PAIconMDIClock1 />}
                      <div className="w-[165px]">
                        <p className="font-semibold">{update.name}</p>
                      </div>
                      <p className="text-gray600 font-sans text-base font-normal leading-normal mr-2">
                        {update.date} - {update.time}
                      </p>
                    </div>
                    <p className="text-gray600 font-sans text-sm font-normal leading-normal">
                      {update.comments} comment
                      {update.comments !== 1 ? "s" : ""} in {update.name}
                    </p>
                  </div>
                ))}
              </div>
              <div className="mt-8 flex justify-end items-end">
                <button
                  className="rounded-md border border-solid border-orange-500 px-3 py-1"
                  onClick={handleViewAll}
                >
                  View all
                </button>
              </div>
            </section>

            {/* SECTION 2 */}
            <section className="w-full lg:w[50%]">
              {/* AGREEMENT DETAILS */}
              <div className="mt-10 lg:mt-0 rounded-md max-h-[350px] shadow-sm overflow-y-auto">
                <div className="mt-5 px-2">
                  <div className="flex items-center justify-between">
                    <h1 className="text-black font-sans font-semibold text-lg leading-normal">
                      Agreement Details
                    </h1>
                    <div>
                      {" "}
                      <PAIconStep1 />
                    </div>
                  </div>
                  <div className="mt-4">
                    {agreementData.map((item, index) => (
                      <div
                        key={index}
                        className="mb-4 flex justify-between px-5"
                      >
                        <div>
                          <p className="text-[#222] font-sans font-medium text-sm">
                            {item.label}
                          </p>
                        </div>
                        <div className="flex-1 ml-4 max-h-[50px] overflow-yauto">
                          <p className="text-[#979797] font-sans font-medium text-sm flex justify-end">
                            {item.value}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* TABS & COMMENTS */}
              <div className="mt-10">
                <div className="flex space-x-4">
                  <button
                    className={`${
                      activeTab === "general"
                        ? "bg-gray700 text-emerald border border-gray300"
                        : "border border-gray300 text-gray-700"
                    } px-4 py-2 rounded-t-md`}
                    onClick={() => handleTabClick("general")}
                  >
                    General Comment
                  </button>
                  {/* <button
                  className={`${
                    activeTab === "latestUpdate"
                      ? "bg-gray700 text-emerald border border-gray300"
                      : "border border-gray300 text-gray-700"
                  } px-4 py-2 rounded-t-md flex justify-between items-center`}
                  onClick={() => handleTabClick("latestUpdate")}
                >
                  Latest Update <span><PAIconDropdown /></span>
                </button> */}
                  <div className="relative">
                    <button
                      className={`${
                        activeTab === "latestUpdate"
                          ? "bg-gray700 text-emerald border border-gray300"
                          : "border border-gray300 text-gray-700"
                      } px-4 py-2 rounded-t-md flex justify-between items-center`}
                      onClick={() => {
                        handleTabClick("latestUpdate");
                        toggleLatestUpdateDropdown();
                      }}
                    >
                      Latest Update
                      <span>
                        <PAIconDropdown />
                      </span>
                    </button>
                    {isLatestUpdateDropdownOpen && (
                      <div className="absolute top-full right-0 mt-[-10px] bg-white rounded-md shadow-md">
                        {/* Dropdown Content */}
                        <ul className="p-1">
                          {agreementUpdates.map((update, index) => (
                            <li
                              key={index}
                              className="py-1 px-4 hover:shadow-sm cursor-pointer text-black font-inter text-sm font-normal"
                            >
                              {update.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* COMMENT SECTION */}
              {activeTab === "general" ? <GeneralComment /> : <LatestUpdate />}

              {/* BUTTONS */}
              <div className="my-5 flex gap-5 w-full">
                <button className="bg-[#07593D] hover:bg-[#07592b] w-[40%] text-white  text-base font-normal font-['SF Pro Display'] rounded-md p-2 px4">
                  {activeTab === "general"
                    ? "Post a comment"
                    : "Post an update"}
                </button>
                <button
                  className="border border-[#07593D] hover:border-emerald text-[#07593D] w-[60%] rounded-md"
                  onClick={
                    activeTab === "general"
                      ? toggleGeneralCommentModal
                      : toggleLatestUpdateModal
                  }
                >
                  {activeTab === "general"
                    ? "All general comment"
                    : "All latest update comments"}
                </button>
              </div>
            </section>
          </div>
        </>
      )}
      {/* MODALS */}
      <DownloadModal isOpen={isModalOpen} onClose={toggleModal} />
      <PermissionModal
        isOpen={isPermissionModalOpen}
        onClose={togglePermissionModal}
      />
      <DeleteModal isOpen={isDeleteModalOpen} onClose={toggleDeleteModal} />
      <SignModal
        isOpen={isSignModalOpen}
        onClose={() => setSignModalOpen(false)}
      />
      <RejectModal
        isOpen={isRejectModalOpen}
        onClose={() => setRejectModalOpen(false)}
      />
      <ModalGeneralComment
        isOpen={isGeneralCommentModalOpen}
        onClose={toggleGeneralCommentModal}
      />
      <LatestUpdateModal
        isOpen={isLatestUpdateModalOpen}
        onClose={toggleLatestUpdateModal}
      />
    </div>
  );
};

export default AgreementDetails;
