import Button from "shared/Button";
import { useFormik } from "formik";
import React, { useState } from "react";
import InputField from "shared/InputField";
import Spinner from "shared/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import PasswordLength from "shared/PasswordLength";
import { useDispatch, useSelector } from "react-redux";
import { createNewFunder } from "appstate/auth/authSlice";
import { PAIconArrowLeft, PAIconEye, PAIconVieweye } from "assets/images/svgs";
import OnboardingLayout from "features/onboarding/category/components/OnboardingLayout";
import { FunderSignUpValidationSchema } from "validation/funder/FunderSignupSchema";
import PhoneNumber from "shared/PhoneNumber";
import MailsentConfirmationModal from "features/onboarding/MailsentConfirmationModal/MailsentConfirmationModal";

const FunderSignup = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { isLoading } = useSelector((state) => state?.auth);
  const [checked, setChecked] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+234");
  const navigate = useNavigate();

  const calculateStrength = (value) => {
    let strength = 0;
    // Check if the password contains at least one number
    if (/\d/.test(value)) {
      strength++;
    }
    // Check if the password contains at least one special character
    if (/[!@#$%^&*]/.test(value)) {
      strength++;
    }
    // Check if the password contains at least one uppercase letter
    if (/[A-Z]/.test(value)) {
      strength++;
    }
    // Check if the password length is between 6 and 8 characters
    if (value.length >= 6) {
      strength++;
    }
    return strength;
  };

  const goback = () => {
    navigate(-1);
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: FunderSignUpValidationSchema(selectedCountryCode),
    onSubmit: (values) => {
      const phoneNumberWithCountryCode = `${selectedCountryCode}${values.phoneNumber}`;
      const body = {
        fullName: values.fullName,
        email: values.email,
        phoneNumber: phoneNumberWithCountryCode,
        password: values.password,
        role: "FUNDER",
      };

      dispatch(createNewFunder(body)).then((data) => {
        // const email = data?.payload?.data?.email;
        if (data?.payload?.status === 200) {
          setShowModal(true);
          // let path = "/resend-verification";
          // navigate(path, { state: email });
        } else {
          return;
        }
      });
    },
  });

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
  };

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  const handlePhoneCountryChange = (selectedCountryCode) => {
    setSelectedCountryCode(selectedCountryCode); // Update selected country code in state
  };

  return (
    <OnboardingLayout nohelp>
      <div className="pt-[40px] px-[20px] h-[100%] overflow-auto md:px-[60px]">
        <PAIconArrowLeft
          className="cursor-pointer mb-[10px]"
          onClick={goback}
        />

{showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
      )}
      <div className="mt-[30px] flex justify-center items-center">
        <div>
          <p className="font-normal text-xs text-textColor mb-[10px] md:text-sm">
            Personal Details
          </p>
          <div className="w-[100px] h-[2px] bg-[#F08000] rounded-[100px] md:w-[220px]"></div>
        </div>
        <div className="ml-[15px] mt-[28px]">
          <div className="w-[100px] h-[2px] bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
        </div>
        <div className="ml-[15px] mt-[28px]">
          <div className="w-[100px] h-[2px] bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
        </div>
        <div className="ml-[15px] mt-[28px]">
          <div className="w-[100px] h-[2px] bg-[#D9D9D9] rounded-[100px] md:w-[220px]"></div>
        </div>
      </div>

        <div className="flex flex-col items-center">
          <div className="mb-[30px] flex flex-col justify-center items-center">
             <h1 className="font-semibold text-[28px] text-black text-center mb-[10px]">
          Welcome to Payedge
        </h1>
        <p className="font-medium text-[18px] text-semibold text-textColor text-center">
           Create an account
        </p>
        <p className="font-light text-[15px] text-semibold text-[#7A7A7A] text-center  mb-[20px]">
          Please enter your personal details
        </p>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="flex justify-center flex-col"
          >
            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="John"
                label="Full Name"
                id="fullName"
                name="fullName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fullName}
              />
              {formik.touched.fullName && formik.errors.fullName ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.fullName}
                </p>
              ) : null}
            </div>
            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="exampleforyou@info.com"
                label="Email Address"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.email}
                </p>
              ) : null}
            </div>
            <div className="mb-[30px]">
              <PhoneNumber
                id="phoneNumber"
                name="phoneNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                onCountryCodeChange={handlePhoneCountryChange}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.phoneNumber}
                </p>
              ) : null}
            </div>
            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none  md:w-[424px] placeholder:text-[40px]"
                placeholder="........"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                onChange={formik.handleChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                value={formik.values.password}
                autoComplete="new-password"
              />
              {showPassword ? (
                <PAIconVieweye
                  onClick={toggleShowPassword}
                  className="float-right mr-[6px] mt-[-30px] relative z-2 hover:cursor-pointer"
                />
              ) : (
                <PAIconEye
                  onClick={toggleShowPassword}
                  className="float-right mr-[6px] mt-[-30px] relative z-2 hover:cursor-pointer"
                />
              )}
         <div className="flex justify-between mt-[10px]">
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.password) === 1
                    ? "bg-red"
                    : calculateStrength(formik.values.password) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : "bg-red-500"
                }`}
              />
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.password) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar ${
                  calculateStrength(formik.values.password) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar  ${
                  calculateStrength(formik.values.password) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
            </div>
              {formik.touched.password && formik.errors.password ? (
                <p className="text-red-500 text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.password}
                </p>
              ) : null}
            </div>
            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none  md:w-[424px] placeholder:text-[40px]"
                placeholder="........"
                type={showConfirmedPassword ? "text" : "password"}
                label="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              {showConfirmedPassword ? (
                <PAIconVieweye
                  onClick={toggleShowConfirmPassword}
                  className="float-right mr-[6px] mt-[-30px] relative z-2 hover:cursor-pointer"
                />
              ) : (
                <PAIconEye
                  onClick={toggleShowConfirmPassword}
                  className="float-right mr-[6px] mt-[-30px] relative z-2 hover:cursor-pointer"
                />
              )}
               <div className="flex justify-between mt-[10px]">
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.confirmPassword) === 1
                    ? "bg-red"
                    : calculateStrength(formik.values.confirmPassword) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : "bg-red-500"
                }`}
              />
              <PasswordLength
                className={`strength-bar } ${
                  calculateStrength(formik.values.confirmPassword) === 2
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar ${
                  calculateStrength(formik.values.confirmPassword) === 3
                    ? "bg-yellow-500"
                    : calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
              <PasswordLength
                className={`strength-bar  ${
                  calculateStrength(formik.values.confirmPassword) >= 4
                    ? "bg-green-500"
                    : ""
                }`}
              />
            </div>
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.confirmPassword}
                </p>
              ) : null}
            </div>

            <div className="mb-[24.5px]">
              <span className="text-xs font-medium text-[#222]">
                Please confirm if this data is correct
              </span>

              <label className="flex cursor-pointer" onClick={handleChange}>
                <input
                  type="checkbox"
                  className="accent-primaryColor mr-[10px]"
                />
                <p className="text-xs font-normal text-textColor">
                  Yes, this data is correct
                </p>
              </label>
            </div>

            <Button
              type="submit"
              auth
              disabled={isLoading ? isLoading : !checked}
              className="mb-[30px] w-full md:w-[424px]"
            >
              {isLoading ? <Spinner /> : "Next"}
            </Button>
          </form>
        </div>
      </div>
      {showModal && <MailsentConfirmationModal email={formik.values.email} />}
    </OnboardingLayout>
  );
};

export default FunderSignup;
