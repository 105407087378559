import React, { useState } from "react";
import { PAIconFilter, PAIconOutlineSearch } from "assets/images/svgs";

const SearchInput = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    onSearch(searchTerm);
  };
  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <div className="flex">
      <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-lg flex items-center justify-between px-2 lg:w-[300px]">
        <div className="items-center flex">
          <PAIconOutlineSearch />
          <input
            className="bg-transparent p-2 wfull focus:outline-none text-sm"
            type="text"
            placeholder="Search for articles"
            value={searchTerm}
            onChange={handleChange}
          />
        </div>
        <div
          className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer"
          onClick={handleSearch}
        >
          <PAIconFilter className="bg-[#2FA06A]" />
        </div>
      </div>
      <button
        className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 px-6 mx-3  items-center gap-2 text-white"
        onClick={handleSearch}
      >
        <PAIconFilter /> Filter
      </button>
    </div>
  );
};

export default SearchInput;
