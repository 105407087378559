import { allDirectFunding } from "appstate/invoice/invoiceSlice";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import TableEmptyState from "shared/TableEmptyState";

const DirectFundingComponent = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const { directFundingData, isLoading } = useSelector(
    (state) => state?.invoice
  );

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  useEffect(() => {
    dispatch(
      allDirectFunding({
        page,
        pageSize,
        bulk: false,
      })
    );
  }, [dispatch, page, pageSize]);

  const column = [
    {
      cell: (row) => (
        <input
          type="checkbox"
          className="cursor-pointer"
          checked={selectedRows.includes(row.id)}
          onChange={() => toggleRow(row.id)}
        />
      ),
      width: "1px",
    },
    {
      name: "Invoice Reference",
      selector: (row) => row.reference,
      // width: "100px",
    },
    {
      name: "Funder",
      selector: (row) => row.funderCompanyName,
    },
    {
      name: "Invoice Date",
      selector: (row) => {
        const invoiceDate = new Date(row.invoiceDate);
        return invoiceDate.toISOString().split("T")[0];
      },
      // width: "100px"
    },
    {
      name: "New Requested Date",
      selector: (row) => row.newRequestedDate,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.invoiceAmount}</p>,
    },
  ];

  const toggleRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  return (
    <>
      {directFundingData?.data?.length === 0 ? (
        <>
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Recent Request
            </p>
          </div>
          <TableEmptyState dashboard />
        </>
      ) : (
        <Card className="rounded-[10px]">
          <div className="pb-[24px]">
            <Table
              columns={column}
              data={directFundingData?.data}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {directFundingData?.data?.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={directFundingData?.meta?.totalElements}
                />
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default DirectFundingComponent;
