import React from "react";
import Table from "layout/table/Table";
import Loading from "shared/Loading";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const InvoiceDashboardTable = () => {
  const { dashboardData } = useSelector((state) => state?.invoice);
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const navigate = useNavigate();

  const handleView = () => {
    // navigate to the invoice details page
    // navigate("/invoice/invoice/all-invoices")
    let path ="/invoice/all-invoice";
    navigate(path);
  }
  const PURCHASETABLECOLUMN = [
    {
      name: "Invoice Reference",
      selector: (row) => row?.invoiceRef,
    },
    {
      name: "Buyer",
      selector: (row) => row?.buyer,
    },
    {
      name: "Invoice Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Invoice Amount",
      selector: (row) => formatAmount(row?.invoicedAmount),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        const successStatuses = ["CREATED", "APPROVED", "PAID", "FULLY PAID"];
        const rejectedStatuses = ["PARTIALLY PAID", "REJECTED"];
        const awaitingStatuses = ["SAVED", "AWAITING APPROVAL", "AWAITING PAYMENT"];
        return successStatuses.includes(row.status) ? (
          <p className="text-success">{row.status}</p>
        ) : rejectedStatuses.includes(row.status) ? (
          <p className="text-red">{row.status}</p>
        ) :awaitingStatuses.includes(row.status) ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        );
      },
    }    
  ];
  return (
    <div className="bg-white p-[24px] mt-5 rounded-xl w-[60%]">
      <div className="flex justify-between">
        <p>Invoice List</p>
        <p className="cursor-pointer" onClick={handleView}>View all</p>
      </div>
      <Table
        columns={PURCHASETABLECOLUMN}
        data={dashboardData?.invoices?.data}
        // pointer
        progressComponent={<Loading />}
        tableHeader
        className="mt-5"
      />
    </div>
  );
};

export default InvoiceDashboardTable;
