import { useKeycloak } from "@react-keycloak/web";
import {
  buyerDataById,
  funderDataById,
  getAllBuyers,
  getAllBuyersAssociatedWithSupplier,
  getAllSuppliersAssociatedWithBuyer,
  // getFunderBykeycloakId,
  // getPlayerBykeycloakId,
  getSupplierId,
  logOut,
  supplierDataById,
} from "appstate/auth/authSlice";
import {
  getAllPlayerInvoices,
  invoiceDashboardData,
} from "appstate/invoice/invoiceSlice";
// import { generateQRcode } from "appstate/settings/settingsSlice";
import {
  getAllSupplierTransactions,
  getSupplierDashboard,
} from "appstate/supplier/supplierSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import TwoFactorModal from "./modal";
import {
  allExternalAccounts,
  allTransactions,
} from "appstate/wallet/walletSlice";
import SupplierDashboard from "./supplierDashboard";
import FunderDashboard from "./funder";
import BuyerDashboard from "./buyerdashboard";
import {
  allBuyerAssociatedSuppliers,
  getBuyerPendingAssociateRequest,
  getBuyersDashboard,
} from "appstate/buyer/buyerSlice";
import {
  funderDashboardStats,
  getFunderTransactions,
} from "appstate/funder/funderSlice";
import {
  toolsAndAgreementOverview,
  toolsAndAgreementStatusOverview,
} from "appstate/toolsAndAgreement/toolsAndAgreementSlice";
import { allRules } from "appstate/rulesandconfig/rulesandconfigSlice";
import { getNotificationSetting } from "appstate/settings/settingsSlice";
import {
  allMembers,
  allPermissions,
  allRoles,
  allSubPermissions,
} from "appstate/roleManager/roleManagerSlice";
import { allTiers } from "appstate/tier/tierSlice";
import { getAllWorkflow } from "appstate/workflow/workflowSlice";
// import { allOpenMarketInvoices } from "appstate/openMarket/openMarketSlice";

const DashboardContents = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const { currentUser, userId, hasCompleted2FA, userDetails } = useSelector(
    (state) => state.auth
  );

  // const { totalItemsByRuleType } = useSelector(
  //   (state) => state?.rulesAndConfig
  // );

  // // console.log(totalItemsByRuleType);

  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();

  // useEffect(() => {
  //   // Clean up sessionStorage if necessary
  //   return () => {
  //     sessionStorage.removeItem("activeTab");
  //     sessionStorage.removeItem("searchQuery");
  //     sessionStorage.removeItem("activeTabSupplier");
  //     sessionStorage.removeItem("searchQuerySupplier");
  //   };
  // }, []);

  useEffect(() => {
    // ? dispatch(
    //     getFunderBykeycloakId({
    //       userId: userId?.sub,
    //       variant: userRole?.split("_")[0],
    //     })
    //   ).then(() => {
    //     if (currentUser?.enabled2FA && !hasCompleted2FA) {
    //       dispatch(generateQRcode()).then(() => {
    //         setOpenModal(true);
    //       });
    //     }
    //   })
    // : dispatch(
    //     getPlayerBykeycloakId({ userId: userId.sub, type: userRole })
    //   ).then(() => {
    //     if (currentUser?.enabled2FA && !hasCompleted2FA) {
    //       dispatch(generateQRcode()).then(() => {
    //         setOpenModal(true);
    //       });
    //     }
    //   });
    if (userRole === "supplier") {
      dispatch(getSupplierId(keycloak?.tokenParsed?.sub));
      dispatch(
        getAllBuyersAssociatedWithSupplier({ playerId: currentUser?.id })
      );
      dispatch(getAllPlayerInvoices({ playerType: userRole }));
      dispatch(supplierDataById({ supplierId: keycloak?.tokenParsed?.sub }));
      dispatch(getSupplierDashboard());
      dispatch(getAllSupplierTransactions());
      dispatch(invoiceDashboardData());
    } else if (userRole === "buyer") {
      dispatch(
        getAllSuppliersAssociatedWithBuyer({ playerId: currentUser?.id })
      );
      dispatch(buyerDataById({ buyerId: keycloak?.tokenParsed?.sub }));
      dispatch(getBuyersDashboard());
      dispatch(getBuyerPendingAssociateRequest());
      dispatch(allBuyerAssociatedSuppliers());
    }
    if (userRole === "funder") {
      dispatch(funderDataById({ funderId: keycloak?.tokenParsed?.sub }));
      dispatch(funderDashboardStats());
      dispatch(getFunderTransactions());
      // dispatch(getFunderOpenMarket());
    }
    dispatch(getAllBuyers());
    dispatch(allTransactions());
    dispatch(toolsAndAgreementOverview());
    dispatch(toolsAndAgreementStatusOverview());
    dispatch(allExternalAccounts());
    dispatch(getNotificationSetting());
    dispatch(allRules({ page: 0, pageSize: 10, ruleType: "EARLY_PAYMENT" }));
    dispatch(allRules({ page: 0, pageSize: 10, ruleType: "ASSOCIATION" }));
    // dispatch(allOpenMarketInvoices({ page: 0, pageSize: 10 }));
    dispatch(getAllWorkflow());
    dispatch(allSubPermissions());
    dispatch(allPermissions());
    dispatch(allTiers());
    dispatch(allRoles({ page: 0, pageSize: 20 }));
    dispatch(allMembers({ page: 0, pageSize: 20 }));
  }, [
    dispatch,
    userId?.realm_access?.roles,
    userRole,
    userId?.sub,
    currentUser?.role,
    currentUser?.id,
    currentUser?.enabled2FA,
    hasCompleted2FA,
    keycloak,
  ]);

  const dashboard =
    userRole === "supplier" ? (
      <SupplierDashboard />
    ) : userRole === "funder" ? (
      <FunderDashboard />
    ) : userRole === "buyer" ? (
      <BuyerDashboard />
    ) : (
      <Navigate to="/role-manager" replace />
    );

  return (
    <>
      <TwoFactorModal
        isShown={openModal}
        onClose={() => {
          keycloak.logout().then(() => {
            dispatch(logOut());
            setOpenModal(false);
          });
        }}
        setOpenModal={setOpenModal}
        enrolled={currentUser?.enrolled2FA}
      />
      {userDetails?.data?.companyDetailsCompleted && dashboard}
    </>
  );
};
export default DashboardContents;
