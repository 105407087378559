import React from 'react';
import './styles/elipsis.css';

function EllipsisText({ text, maxLength }) {
  const ellipsisText =
    text?.length > maxLength ? `${text?.substring(0, maxLength)}...` : text;

  return <div className='ellipsis-text'>{ellipsisText}</div>;
}

export default EllipsisText;
