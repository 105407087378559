import { PAIconArrowLeftGreen } from "assets/images/svgs";
import Headers from "features/invoice/buyerInvoice/headers";
import Table from "layout/table/Table";
import React from "react";
import { Link } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";

const Details = () => {
  const BulkEarlyPaymentList = [
    {
      id: "1",
      invoiceNumber: "INV2022001",
      buyerCompanyName: "ABC Company",
      supplier: "John Doe",
      invoiceDueDate: "2024-01-31",
      invoiceDate: "2024-01-01",
      newRequestedDate: "2024-01-15",
      currency: "USD",
      invoiceAmount: "$9,000.00",
      status: "PENDING",
      tier: "Tier 1",
      discountRate: "5%",
      reference: "E-PR-20220222-OTRRDHY12I",
      discountAmount: "$450.00",
    },
    {
      id: "2",
      invoiceNumber: "INV2022002",
      buyerCompanyName: "XYZ Inc.",
      supplier: "Jane Smith",
      invoiceDueDate: "2024-02-04",
      invoiceDate: "2024-01-05",
      newRequestedDate: "2024-01-20",
      currency: "USD",
      invoiceAmount: "$4,000.00",
      status: "Updated request",
      tier: "Tier 2",
      discountRate: "3%",
      reference: "E-PR-20220221-3INUPDJXL1",
      discountAmount: "$120.00",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref.No.
        </span>
      ),
      selector: (row) => row.reference,
      sortable: true,
      width: "140px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row.supplierCompanyName,
      sortable: true,
      width: "100px",
    },
    {
      name: "Request Date",
      selector: (row) => row.newRequestedDate,
      sortable: true,
      width: "120px",
    },
    {
      name: "No. of invoices",
      selector: (row) => row.numberOfInvoices,
      sortable: true,
      width: "110px",
    },
    {
      name: "Tier",
      selector: (row) => row.tier,
      sortable: true,
      width: "70px",
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.invoiceAmount}</p>,
      width: "130px",
    },
    {
      name: "Discount Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-yellow-400">{row.discountAmount}</p>,
      width: "120px",
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
      sortable: true,
      cell: (row) => <p className="text-success">{row.discountRate}</p>,
      width: "150px",
    },
  ];

  return (
    <div>
      <header className="rounded-md w-full bg-white mb-[17px] cursor-pointer items-center">
        <Link
          to="/invoice/invoice"
          className="flex items-center justifycenter gap-x-[3px] mb-[20px] cursor-pointer py-3 ml-2 my-auto"
        >
          <PAIconArrowLeftGreen />
          <span className="flex items-center text-primaryColor">Go Back</span>
        </Link>
      </header>
      <Headers />

      {/* Table */}
      {!BulkEarlyPaymentList.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Approved Early Payment Request
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[90px] rounded-t-[10px] mt-3">
            <div className="py-5">
              <div className="text-black text-md font-bold mt5">
                XYZ Ventures -{" "}
                <span className="text-secondaryColor">(Supplier)</span>
              </div>

              <div className="text-xs font-thin flex gap-8 mt-1">
                <span className="text-[#07A04A]">
                  Request ref no. : R343t3xy
                </span>
                <span className="text-gray300">
                  2 invoices-
                  <span className="text-[#07593D] font-bold">
                    (Approved Bulk Requests)
                  </span>
                </span>
              </div>

              <div className="text-xs font-thin flex gap-8 mt-1 mb5">
                <span className="text-gray300">
                  Total invoice amount-
                  <span className="text-[#07593D] font-bold">NGN 83,000</span>
                </span>
                <span className="text-gray300">
                  Total request amount-
                  <span className="text-[#07593D] font-bold">NGN 22,000</span>
                </span>
              </div>
            </div>
            <div className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]">
              Initiate Disbursement
            </div>
          </div>
          <div className="pb-[24px]">
            <Table
              columns={columns}
              data={BulkEarlyPaymentList}
              pointer
              tableHeader
              className="mt-5"
            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default Details;
