import {
  PAIconAgreeFolder,
  PAIconAgreeReject,
  PAIconAgreeWarning,
  PAIconArrowLeft,
  PAIconSignPen,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Heading from "../category/components/Heading";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import { useState } from "react";
import AgreementSignedModal from "../common/modals/AgreementSignedModal";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updateAgreementDocumentAccepted } from "./supplierVerifcationSlice/verificationActions";
import AgreementRejectedModal from "../common/modals/AgreementRejectedModal";
import { useRef } from "react";
import html2pdf from "html2pdf.js";
function FundingAgreement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signAgreementModal, setSignAgreementModal] = useState(false);
  const [rejectAgreementModal, setRejectAgreementModal] = useState(false);
  const [rejectedAgreement, setRejectedAgreement] = useState(false);

  // On Accept Agreement
  const [agreementAccepted, setAgreementAccepted] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  const handleAcceptAgreement = () => {
    setSignAgreementModal(false);
    setTimeout(() => {
      setAgreementAccepted(true);
    }, 600);
    toast.success("Agreement Signed Successfully");
  };

  const handleCloseAcceptAgreement = () => {
    dispatch(updateAgreementDocumentAccepted(true));
    setAgreementAccepted(false);
    navigate(-1);
  };
  const handleCancelContinue = () => {
    setRejectedAgreement(false);
    navigate(-1);
  };

  // handle download

  const modalContentRef = useRef(null);

  const handleDownload = () => {
    const element = modalContentRef.current;

    // Get the dimensions of the modal content
    const width = element.offsetWidth;
    const height = element.offsetHeight + 20;

    // Set the options for the PDF
    const options = {
      filename: "receipt.pdf",
      jsPDF: { unit: "px", format: [width, height], orientation: "portrait" },
      image: { type: "jpeg", quality: 1 },
      output: "dataurlnewwindow",
      html2canvas: { scale: 3 },
    };

    // Generate the PDF
    html2pdf().set(options).from(element).save();
    console.log("===HELLO=--");
  };

  return (
    <>
      <Heading nohelp />
      <div className="flex flex-col gap-6 items-center w-full pb-20 pt-4 px-4 md:px-[3%] relative">
        <span
          onClick={() => handleBack()}
          className="left-[3%] cursor-pointer absolute"
          style={{ top: "1rem" }}
        >
          <PAIconArrowLeft />
        </span>
        <header className="flex flex-col items-center w-full pt-6">
          <div>
            <h1 className="font-medium text-2xl">Review Funding Agreement</h1>
          </div>
          <div className="w-full">
            {/* <span className="text-xs font-semibold">Page 1 of 1</span> */}
            <div class="text-black text-xs font-medium">Page 1 of 1</div>
          </div>
        </header>

        <main className="rounded-[10px] bg-[#F6F6F680] h-full w-full">
          <div
            className="flex flex-col lg:flex-row justify-between h-full w-full"
            ref={modalContentRef}
          >
            <section className="w-fit lg:w-1/2 m-10 p-[30px] overflow-y-auto border-[1.39px] border-[#B4B4B433] rounded-[2px] bg-white max-h-[600px] scrollbar-hide pb-5">
              <header className="flex w-full mb-2.5 justify-center font-bold">
                FUNDING AGREEMENT
              </header>

              <main className="flex flex-col gap-3">
                <section className="flex flex-col gap-[3px]">
                  <div className="border-b-[1.5px] text-xs font-bold text-[13.62px]">
                    CAREER OBJECTIVE
                  </div>
                  <div className=" text-justify font-semibold  text-black text-[11.62px]  capitalize tracking-wide">
                    Feugiat lectus arcu, sapien tempor laoreet viverra in. Ac
                    eget pellentesque dignissim donec elit. Sed arcu sagittis eu
                    nulla molestie. Lacinia vulputate mauris sed eget turpis
                    netus nisi. Auctor lobortis in lacus vulputate a. Augue
                    egestas sodales urna, sed mattis. A rhoncus rhoncus congue
                    sit. Volutpat rutrum.
                  </div>
                </section>

                <section className="flex flex-col gap-[10px]">
                  <div className="border-b-[1.5px] text-xs font-bold text-[13.62px]">
                    EDUCATION AND AWARDS
                  </div>
                  <div className=" flex justify-between w-full">
                    {" "}
                    <div className="text-xs flex flex-col text-justify font-bold text-[13.62px]  leading-5">
                      <span>
                        BA Hons English, Worcester College, University of Oxford
                      </span>
                      <div>
                        <ul>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            First year exam results: 2.1 (65% average)
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="text-right text-black  font-medium  lowercase text-[11.62px]">
                      2018-2021
                    </div>
                  </div>

                  <div className=" flex justify-between w-full">
                    <div className="text-xs flex flex-col text-justify font-bold  leading-5 text-[13.62px]">
                      <span>St John’s School, Milton Keynes</span>
                      <div className="text-[11.62px] text-justify font-semibold">
                        A levels: French A*, English A*, Chemistry A, Geography
                        A
                      </div>
                      <div className="text-[11.62px] text-justify font-semibold">
                        GCSEs: % A*, 3 A, 1 B including A* in English and Maths
                      </div>
                      <div>
                        <ul>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Awarded School Prize for A-Level results and Smith
                            Prize for ‘Contribution to School Life’
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="text-right text-black  font-medium  lowercase text-[11.62px]">
                      2018-2021
                    </div>
                  </div>

                  <div className=" flex justify-between w-full leading-5 text-[13.62px]">
                    <div className="text-xs flex flex-col text-justify font-bold leading-5 text-[13.62px]">
                      <span>
                        BA Hons English, Worcester College, University of Oxford
                      </span>
                      <div>
                        <ul>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            First year exam results: 2.1 (65% average)
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="text-right text-black text-[11.62px] font-medium  lowercase">
                      2018-2021
                    </div>
                  </div>
                </section>

                <section className="flex flex-col gap-2.5">
                  <div className="text-xs font-bold border-b-[1.5px] text-[13.62px]">
                    RELEVANT EXPERIENCE
                  </div>
                  <div className=" flex justify-between w-full leading-5 ">
                    <div className="text-xs flex flex-col text-justify font-bold leading-5 text-[13.62px]">
                      <span>
                        Worcester College Wind Ensemble, Marketing Officer
                      </span>
                      <div>
                        <ul>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Responsible for promoting termly college concerts
                            using social media and printed material
                          </li>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Organised online events during lockdown period
                          </li>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Manage ticket sales, successfully increasing
                            audience numbers by 30% in the first term
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="text-right text-black text-[11.62px] font-medium  lowercase">
                      2020-Ongoing
                    </div>
                  </div>
                  <div className=" flex justify-between w-full leading-5">
                    <div className="text-xs flex flex-col text-justify font-bold leading-5 text-[13.62px]">
                      <span>
                        WWOOF, Organic Farm in Haute Vienne, France, Volunteer
                        (summer)
                      </span>
                      <div>
                        <ul>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Independently planned and organised two months work
                            in France
                          </li>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Improved to near-fluent French, while learning
                            organic food production chain processes
                          </li>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Developed good working relationships with 22
                            colleagues from 12 countries
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="text-right text-black text-[11.62px] font-medium  lowercase">
                      2016-2018
                    </div>
                  </div>
                  <div className=" flex justify-between w-full leading-5">
                    <div className="text-xs flex flex-col text-justify font-bold leading-5 text-[13.62px]">
                      <span>
                        Student Consultancy, Team Leader, Oxford University
                      </span>
                      <div>
                        <ul>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Led a team of five students to develop marketing
                            strategy for local start-up
                          </li>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Designed and executed paper and phone surveys,
                            engaging 250 participants
                          </li>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Presented reccommendations to client: all
                            implemented within 3 months and delivering 20%
                            increase in sales within 6 months.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="text-right text-black text-[11.62px] font-medium  lowercase">
                      2014-2016
                    </div>
                  </div>{" "}
                  <div className=" flex justify-between w-full leading-5">
                    <div className="text-xs flex flex-col text-justify font-bold leading-5 text-[13.62px]">
                      <span>
                        Red Lion Hotel, Newport Pagnell, Waitress (Part-Time
                        Summer Work)
                      </span>
                      <div>
                        <ul>
                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Provided professional, courteous dinner service in
                            busy gastro-pub with 80 covers
                          </li>

                          <li className="ml-4 list-disc font-semibold text-[11.62px]">
                            Maintained positivity and good working relationships
                            in high-pressure service environment
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="text-right text-black text-[11.62px] font-medium  lowercase">
                      2010-2012
                    </div>
                  </div>
                  <div className=" flex justify-between w-full leading-5">
                    <div className="text-xs flex flex-col text-justify font-bold leading-5 text-[13.62px]">
                      <span>
                        EGM Analysis, Market Research Assistant (1-month
                        internship)
                      </span>
                      <div>
                        <ul>
                          <li className="ml-4 list-disc font-semibold text-[11.62px] ">
                            Responsible for producing eight data tables
                            summarising previous field activities using SPSS
                          </li>
                          <li className="ml-4 list-disc font-semibold text-[11.62px] ">
                            Presented daily key data summary to four senior
                            managers, with a colleague
                          </li>
                          <li className="ml-4 list-disc font-semibold text-[11.62px] ">
                            Developed knowledge and interest in marketing and
                            consumer goods
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="text-right text-black text-[11.62px]  font-medium  lowercase">
                      2012-2014
                    </div>
                  </div>
                  {/* STARTS */}
                  <div class="flex-col justify-start items-start gap-[2.36px] flex mt-3">
                    {/* <div class="w-[528.52px] text-black text-xs font-bold  uppercase"></div> */}
                    <div className="text-xs font-bold  text-[13.62px] uppercase w-full border-b-[1.5px]">
                      additional skills & interests
                    </div>

                    <div class="h-[79.44px] flex-col justify-start items-start gap-[2.36px] flex">
                      <div>
                        <span className="text-black text-[12.62px] font-bold ">
                          Languages:{" "}
                        </span>
                        <span className="text-black text-[11.62px] font-medium ">
                          Bilingual Punjabi and English; French – near-fluent;
                          German – conversational{" "}
                        </span>
                      </div>
                      <div>
                        <span className="text-black text-[12.62px] font-bold ">
                          IT:{" "}
                        </span>
                        <span className="text-black text-[11.62px] font-medium f">
                          Advanced proficiency in Excel, WordPress, InDesign
                        </span>
                      </div>
                      <div>
                        <span className="text-black text-[12.62px] font-bold ">
                          Music:{" "}
                        </span>
                        <span className="text-black text-[11.62px] font-medium">
                          Lead clarinettist in Milton Keynes Youth Band; member
                          of National Youth Orchestra
                        </span>
                      </div>
                      <div>
                        <span className="text-black text-[12.62px] font-bold ">
                          Sport:{" "}
                        </span>
                        <span className="text-black text-[11.62px] font-medium ">
                          College football team, training twice a week
                        </span>
                      </div>
                      <div>
                        <span className="text-black text-[12.62px] font-bold ">
                          Travel:{" "}
                        </span>
                        <span className="text-black text-[11.62px] font-medium ">
                          Extensive travel throughout Europe, including
                          organising work placement in France
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </section>

            <section className="min-h-[570px] rounded-r-[10px] px-10 py-8 bg-custom-orange w-full lg:w-[40%]">
              <div className="py-6 px-5 border-[0.5px] border-[#CFCFCF] rounded-[10px] h-full bg-white">
                <header className="flex px-5 items-center justify-between">
                  <span className="font-semibold">Agreement Details</span>
                  <PAIconAgreeFolder onClick={handleDownload} />
                </header>

                <main className="px-5">
                  <div className="flex justify-between text-sm font-medium py-2.5 mt-2">
                    <span>Agreement name</span>
                    <span className="text-[#979797]"> Funding agreement</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Agreement Initiator</span>
                    <span className="text-[#979797]"> Payedge</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Agreement Recipient</span>
                    <span className="text-[#979797]">Dangote groups</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>No. of parties involved</span>
                    <span className="text-[#979797]">2</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Initiator sign status</span>
                    <span className="text-[#979797]">Signed</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Recipient sign status</span>
                    <span className="text-[#979797]">Not signed</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>No. of updates</span>
                    <span className="text-[#979797]">1</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Date of Initiation</span>
                    <span className="text-[#979797]">July 12, 2024</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Date of Finalization</span>
                    <span className="text-[#979797]">-</span>
                  </div>

                  <div className="flex justify-between text-sm font-medium py-2.5">
                    <span>Description</span>
                  </div>

                  <div className="py-2.5 text-[#979797] text-sm font-medium">
                    The funding agreement outlines the detailed terms and
                    conditions under which you will receive financial support.
                    It ensures both parties clearly understand their obligations
                    and expectations, providing a framework for the funding
                    process.
                  </div>
                </main>
              </div>
            </section>
          </div>

          <footer className="w-full flex flex-col md:flex-row justify-between px-10 py-5 shadow-sm rounded-b-[10px] bg-white">
            <section className="flex gap-3 items-center">
              <span>
                <PAIconAgreeWarning />
              </span>

              <div className="text-sm font-semi">
                By clicking the{" "}
                <span className="text-secondaryColor font-extrabold">
                  “Click to sign”
                </span>{" "}
                button, you're approving <br /> and confirming your consent to
                the above agreement
              </div>
            </section>
            <section className="flex gap-4 text-sm">
              <motion.button
                whileTap={{ scale: 0.95 }}
                onClick={() => setSignAgreementModal(true)}
                className="font-medium flex gap-2.5 justify-center items-center py-2.5 px-5 text-white rounded-[5px] bg-secondaryColor"
              >
                Click to sign <PAIconSignPen />
              </motion.button>

              <motion.button
                className="flex gap-2.5 items-center justify-center rounded-[5px] border text-[#CD3A1A] border-[#CD3A1A] py-2.5 px-5"
                whileTap={{ scale: 0.95 }}
                onClick={() => setRejectAgreementModal(true)}
              >
                Reject
                <PAIconAgreeReject />
              </motion.button>
            </section>
          </footer>
        </main>

        <AnimatePresence>
          {signAgreementModal && (
            <ViewDocumentModal
              onAction={() => {
                handleAcceptAgreement();
              }}
              onClose={() => setSignAgreementModal(false)}
            >
              <span className=" text-center">
                Are you sure you want to{" "}
                <span className="text-primaryColor">Sign</span> this Agreement?
              </span>
            </ViewDocumentModal>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {rejectedAgreement && (
            <AgreementRejectedModal
              onClose={() => setRejectedAgreement(false)}
              onContinue={handleCancelContinue}
            ></AgreementRejectedModal>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {rejectAgreementModal && (
            <ViewDocumentModal
              onAction={() => {
                setRejectAgreementModal(false);
                setRejectedAgreement(true);
                // handleUploadCacDocuments();
              }}
              onClose={() => {
                setRejectAgreementModal(false);
              }}
            >
              <span className=" text-center">
                Are you sure you want to{" "}
                <span className="text-[#BB0C0C]">Reject</span> this Agreement?
              </span>
            </ViewDocumentModal>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {agreementAccepted && (
            <AgreementSignedModal
              onClose={() => {
                handleCloseAcceptAgreement();
              }}
            />
          )}
        </AnimatePresence>
      </div>
    </>
  );
}

export default FundingAgreement;
