import * as Yup from "yup";
const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z]).{6,}$/;
const corporatePasswordValidationSchema = Yup.object({
  value: Yup.string()
    .min(6, "Password must be at least minimum of 6characters")
    .matches(
      passwordRegex,
      "Password must contain at least one number, one special character, one uppercase letter, and be at least 6 characters long."
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("value"), null], "Passwords must match")
    .required("Passwords do not match"),
});

export { corporatePasswordValidationSchema, passwordRegex };
