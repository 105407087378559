import {
  PAIconEmailIcon,
  PAIconInventoryArrowLeft,
  PAIconInvoiceIcon,
  PAIconSmallClose,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Card from "shared/Card";
import { AnimatePresence, motion } from "framer-motion";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import MailSentModal from "./components/modals/MailSentModal";

const Pfpdetails = () => {
  const [leavePFPModal, setLeavePFPModal] = useState(false);
  const [mailSentModal, setMailSentModal] = useState(false);
  const { allFunderPfpData, pfpId } = useSelector((state) => state?.funder);

  const details = allFunderPfpData?.data?.find((data) => data?.pfpId === pfpId);

  // console.log(details);
  const navigate = useNavigate();
  function handleGoback() {
    let path = -1;
    navigate(path);
  }

  // const leavePfp = () => {};

  let path = "/payablefinance/funderpayablefinanceviewdetails";

  return (
    <DashboardLayout>
      <div className="min-h-full">
        <div
          onClick={handleGoback}
          className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
        >
          <PAIconInventoryArrowLeft />
          <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
        </div>
        <header className="bg-alabasterHeader px-6 py-4 rounded-t-[10px]">
          <div className="flex justify-between">
            <div className="flex flex-col  justify-center">
              <p className="text-black text-sm font-medium">Details</p>
              <p className="text-textColor text-xs font-normal">
                Basic information about the Payable finance program
              </p>
            </div>
            <motion.button
              onClick={() => setLeavePFPModal(true)}
              whileTap={{ scale: 0.9 }}
              className="py-[12px] px-[24px] bg-[#A92B23] rounded-[5px] border-[1px] border-silver !text-white text-sm"
            >
              Leave
            </motion.button>
          </div>
        </header>
        <Card className=" min-h-[80%] md:px-[40px] pb-[40px] rounded-b-[10px] ">
          <div className="">
            <div className="md:flex md:justify-between  pt-[24px] 2xl:justify-start 2xl:gap-x-[370px] ">
              {/* Here */}
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <p className="text-sm font-normal text-lightGray">
                    Name of Program
                  </p>
                  <p className="text-textColor text-sm font-normal">
                    {details?.programName}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  {" "}
                  <p className="text-sm font-normal text-lightGray">
                    Discount Rate
                  </p>
                  <p className=" text-textColor text-sm font-normal">
                    {details?.discountRate || "2%"}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-sm font-normal text-lightGray">Duration</p>

                  <div className="flex flex-row">
                    <span className="text-[#BFBFBF] mr-1 font-bold text-sm">
                      From:
                    </span>
                    <span className="text-textColor text-sm font-normal">
                      {details?.pfpEndDate || "12-03-24"}
                    </span>

                    <span className="text-[#BFBFBF] mr-1 font-bold ml-2 text-sm">
                      To:
                    </span>
                    <span className="text-textColor text-sm font-normal">
                      {details?.pfpEndDate || "12-03-24"}
                    </span>
                  </div>

                  {/* <div className="flex items-center">
                    <div className="flex items-center">
                      <span className="text-[#BFBFBF] text-sm">From:</span>
                      <span className="mt-[8px] text-textColor text-sm font-normal">
                        {details?.pfpEndDate}
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <p className="text-sm text-[#7A7A7A]">Terms of Agreement</p>
                  <p className="cursor-pointer underline text-lightGray text-sm font-normal">
                    View agreement
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <p className="text-sm text-[#7A7A7A]">Payment Period</p>
                  <p className="cursor-pointer text-lightGray text-sm font-normal">
                    40 days
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <span className="text-sm text-[#7A7A7A]">
                    Number of Funders in Program
                  </span>
                  <p className="cursor-pointer text-sm font-normal">2</p>
                </div>
              </div>
            </div>
          </div>

          {/* Here */}
          <div className="mt-[48px] flex flex-col gap-6">
            <div className=" w-full flex justify-between">
              <span className="text-sm gap-2.5 font-semibold md:justify-start text-textColor flex items-center">
                Number of Invoices
                <span className="w-[25px] h-[25px] flex justify-center items-center bg-conch  rounded-[50%]">
                  2
                </span>
              </span>

              <Link
                to={path}
                className=" text-sm underline text-[#222] cursor-pointer"
              >
                View all
              </Link>
            </div>

            <main className="flex flex-wrap gap-6">
              <motion.div
                whileTap={{ scale: 0.9 }}
                className="flex cursor-pointer mb-2 flex-col gap-4"
              >
                <PAIconInvoiceIcon />

                <span className=" text-xs text-[#222]">#INV-2012</span>
              </motion.div>
              <motion.div
                whileTap={{ scale: 0.9 }}
                className="flex cursor-pointer mb-2 flex-col gap-4"
              >
                <PAIconInvoiceIcon />

                <span className=" text-xs text-[#222]">#INV-2012</span>
              </motion.div>
              <motion.div
                whileTap={{ scale: 0.9 }}
                className="flex cursor-pointer mb-2 flex-col gap-4"
              >
                <PAIconInvoiceIcon />

                <span className=" text-xs text-[#222]">#INV-2012</span>
              </motion.div>
            </main>
          </div>
        </Card>
      </div>
      <AnimatePresence>
        {leavePFPModal && (
          <ViewDocumentModal
            onAction={() => {
              setLeavePFPModal(false);
              setTimeout(() => {
                setMailSentModal(true);
              }, 1200);
            }}
            onClose={() => setLeavePFPModal(false)}
            proceed="Send Request"
            reject="Cancel"
          >
            <div className=" text-center">
              <div>Are you sure you would like to leave this Payable</div>
              <div> Financing Program?</div>
            </div>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {mailSentModal && (
          <MailSentModal>
            <div className="flex flex-col gap-8">
              <header className="flex items-center justify-between">
                <span>Email sent</span>

                <span
                  onClick={() => setMailSentModal(false)}
                  className="flex gap-1 cursor-pointer items-center"
                >
                  <PAIconSmallClose />
                  Close
                </span>
              </header>

              <main className="flex flex-col gap-2.5">
                <span className=" text-center flex justify-center">
                  <PAIconEmailIcon />
                </span>
                <span className="text-[#959595] text-center">
                  An email has been sent. Request pending buyer's approval
                </span>
              </main>
            </div>
          </MailSentModal>
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
};

export default Pfpdetails;
