import React, { useState } from "react";

import {
  // PAIconArrowDown,
  // PAIconDownload,
  PAIconDropdown,
} from "assets/images/svgs";
import SearchInput from "./SearchInput";

const DirectFundingHeader = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className='my-2 grid md:grid-cols-2 items-center'>
      <div className='gap-3 flex relative'>
        <div>
          <div
            className='flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer'
            onClick={handleDropdownToggle}
          >
            Filter{" "}
            {selectedFilters.length > 0 && (
              <span className='bg-gray m-1 rounded-[50%]  px-2'>
                {selectedFilters.length}
              </span>
            )}{" "}
            Selected <PAIconDropdown />
          </div>
          {isDropdownOpen && (
            <div className='absolute text-sm top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white'>
              <label className='flex items-center gap-[2px]'>
                <input
                  type='checkbox'
                  value='date'
                  checked={selectedFilters.includes("date")}
                  onChange={handleFilterChange}
                />
                Date
              </label>
              <label className='flex gap-[2px]'>
                <input
                  type='checkbox'
                  value='name'
                  checked={selectedFilters.includes("name")}
                  onChange={handleFilterChange}
                />
                Name
              </label>
              <label className='flex gap-[2px]'>
                <input
                  type='checkbox'
                  value='currency'
                  checked={selectedFilters.includes("currency")}
                  onChange={handleFilterChange}
                />
                Currency
              </label>
              <label className='flex gap-[2px]'>
                <input
                  type='checkbox'
                  value='amount'
                  checked={selectedFilters.includes("amount")}
                  onChange={handleFilterChange}
                />
                Amount
              </label>
            </div>
          )}
        </div>

        <select
          className='border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer'
          onChange={(e) => {
            // const selectedSortBy = e.target.value;
          }}
        >
          <option value=''>Sort by</option>
          <option value='date'>Date</option>
          <option value='name'>Name</option>
        </select>

        <button className='flex items-center gap-2 py-2 px-4 rounded-[5px] bg-white text-sm border-[#DEDEDE] shadow-sm mr-2 '>
          Export
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='17'
            viewBox='0 0 16 17'
            fill='none'
          >
            <path
              d='M8.00016 10.4987V3.83203M8.00016 10.4987C7.53336 10.4987 6.66118 9.16916 6.3335 8.83203M8.00016 10.4987C8.46696 10.4987 9.33916 9.16916 9.66683 8.83203'
              stroke='black'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M3.3335 13.168H12.6669'
              stroke='black'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
      </div>
      <div className=' extra-md:flex hidden md:col-span-1 justify-end'>
        <SearchInput />
      </div>
    </div>
  );
};

export default DirectFundingHeader;
