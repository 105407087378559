import DashboardLayout from "layout/dashboardlayout";
import React from "react";
// import Overview from "./Overview";
import WalletOverview from "features/wallet(new)/overview/Overview";

const WalletDashboard = () => {
  return (
    <DashboardLayout
      section="Wallet"
      subHeader="Here is an overview of your payments and payment processes"
    >
      {/* <Overview /> */}
      <WalletOverview />
    </DashboardLayout>
  );
};

export default WalletDashboard;
