import React, { useState } from "react";
import Overview from "../overview/Overview";
import EarlyPaymentRequest from "../earlyPaymentRequest";
import DirectFundingRequest from "../directFundingRequest";
import OpenMarketRequest from "../openMarketRequest";
import FundingOffers from "../fundingOffers";
import EarlyPaymentOption from "../earlyPaymentRequest/Option";
import DirectFundingOption from "../directFundingRequest/Option";
import BulkRequestSelectBuyerModal from "../earlyPaymentRequest/bulkrequest/Modal";
import { useNavigate } from "react-router-dom";
import { PAIconAdd } from "assets/images/svgs";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [showEarlyPaymentModal, setShowEarlyPaymentModal] = useState(false);
  const [showDirectFundingModal, setShowDirectFundingModal] = useState(false);
  const [open, setOpen] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "overview":
        return <Overview />;
      case "earlyPayment":
        return <EarlyPaymentRequest />;
      case "directFunding":
        return <DirectFundingRequest />;
      case "openMarket":
        return <OpenMarketRequest />;
      case "fundingOffers":
        return <FundingOffers />;
      default:
        return null;
    }
  };

  const navigate = useNavigate();

  function handleNavigate() {
    let path = "/invoice/invoice/fundingSection/invoice-import";
    navigate(path);
  }

  const renderCreateNewRequestButton = () => {
    if (activeTab === "earlyPayment" || activeTab === "directFunding") {
      return (
        <div className="fle items-center text-black font-medium text-base">
          <button
            className="text-white p-2 px-4 text-base rounded-md border border-[#CCC] bg-[#FFBE55]"
            onClick={() => {
              if (activeTab === "earlyPayment") {
                setShowEarlyPaymentModal(true);
              } else {
                setShowDirectFundingModal(true);
              }
            }}
          >
            Create new request
          </button>
          <BulkRequestSelectBuyerModal
            isShown={open}
            hide={() => setOpen(false)}
          />
          <EarlyPaymentOption
            setOpen={setOpen}
            isOpen={showEarlyPaymentModal}
            onClose={() => setShowEarlyPaymentModal(false)}
          />
          <DirectFundingOption
            isOpen={showDirectFundingModal}
            onClose={() => setShowDirectFundingModal(false)}
          />
        </div>
      );
    }
    if (activeTab === "openMarket") {
      return (
        <div className="fle items-center text-black font-medium text-base">
          <button
            onClick={handleNavigate}
            className="text-white p-2 px-4 text-base flex items-center gap-x-[5px] rounded-md border border-[#CCC] bg-[#FFBE55]"
          >
            <PAIconAdd className="fill-white w-[16px]" />
            <p>Import Invoice</p>
          </button>
        </div>
      );
    }
    return <div className="p-2 invisible">Hidden Button</div>;
  };

  return (
    <div>
      <div className="rounded-md w-full bg-white mt5 mx6 mb-[17px] cursor-pointer">
        <div className="md:flex md:flex-row flex-col gap1 p-2 px-7 justify-between gap-10 items-center">
          <div className="md:flex md:flex-row flex-col gap-10">
            <div
              className={
                activeTab === "overview"
                  ? "text-[#07593D] md:border-b-4 rounded-l-sm rounded-r-sm font-bold border-[#07593D] md:mb-[-18px]"
                  : "text-gray500 font-normal text-base"
              }
              onClick={() => handleTabClick("overview")}
            >
              Overview
            </div>
            <div
              className={
                activeTab === "earlyPayment"
                  ? "text-[#07593D] md:border-b-4 rounded-l-sm rounded-r-sm font-bold border-[#07593D] md:mb-[-18px]"
                  : "text-gray500 font-normal text-base my-2 md:my-0"
              }
              onClick={() => handleTabClick("earlyPayment")}
            >
              Early Payment Request
            </div>
            <div
              className={
                activeTab === "directFunding"
                  ? "text-[#07593D] md:border-b-4 rounded-l-sm rounded-r-sm font-bold border-[#07593D] md:mb-[-18px]"
                  : "text-gray500 font-normal text-base"
              }
              onClick={() => handleTabClick("directFunding")}
            >
              Direct funding request
            </div>
            <div
              className={
                activeTab === "openMarket"
                  ? "text-[#07593D] md:border-b-4 rounded-l-sm rounded-r-sm font-bold border-[#07593D] md:mb-[-18px]"
                  : "text-gray500 font-normal text-base my-2 md:my-0"
              }
              onClick={() => handleTabClick("openMarket")}
            >
              Open market request
            </div>
            <div
              className={
                activeTab === "fundingOffers"
                  ? "text-[#07593D] md:border-b-4 rounded-l-sm rounded-r-sm font-bold border-[#07593D] md:mb-[-18px]"
                  : "text-gray500 font-normal text-base"
              }
              onClick={() => handleTabClick("fundingOffers")}
            >
              Funding offers
            </div>
          </div>
          {renderCreateNewRequestButton()}
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default Tabs;
