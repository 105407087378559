import { AnimatePresence, motion } from "framer-motion";
import {
  PAIconArrowLeftGreen,
  PAIconCocacola,
  PAIconDownload,
  PAIconInfoGreen,
  PAIconInvoiceIcon,
  PAIconModelOne,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate, useParams } from "react-router-dom";
import TransitionScale from "shared/animation/TransitionScale";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceById } from "appstate/openMarket/openMarketSlice";
import ViewFundInvoiceModal from "features/funderpayablefinance/components/modals/ViewFundInvoiceModal";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";

import EnterPin from "./modal/Pin";
import TransferFundsModal from "./modal/TransferFundsModal";

function DisbursementInvoice() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleGoBack() {
    navigate(-1); // Navigate back one step
  }

  useEffect(() => {
    dispatch(getInvoiceById({ id }));
  }, [dispatch, id]);
  const [acceptPaymentModal, setAcceptPaymentModal] = useState(false);
  const { invoiceByIdData } = useSelector((state) => state?.openMarket);
  const [makePaymentModal, setMakePaymentModal] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [pinModal, setPinModal] = useState(false);
  // console.log(invoiceByIdData);
  return (
    <DashboardLayout subHeader="Here is what is happening in your finance">
      <TransitionScale className="flex  flex-col gap-6 bg-[#EEE] h-full">
        <main className="flex flex-col  pb-8 gap-8 w-full ">
          <motion.section
            initial={{
              x: 20,
            }}
            animate={{
              x: 0,
            }}
            exit={{
              x: -20,
            }}
            transition={{
              delay: 0.6,
            }}
            className="flex flex-col gap-8 px-6 pb-24 w-full bg-white rounded-[10px]"
          >
            <header
              onClick={handleGoBack}
              className="w-full py-5 border-b border-[#B1A6B2] "
            >
              <div className="flex cursor-pointer pt-4 w-fit gap-1 items-center">
                <PAIconArrowLeftGreen />
                <span className=" text-primaryColor text-sm">Go back</span>
              </div>

              <div className="flex">
                <div className="flex cursor-pointer pt-4 px-6 w-fit gap-1 items-center">
                  <span className="font-[500] text-black text-sm pb-1.5 border-b  ">
                    Invoices
                  </span>
                  <div className="absolute bottom-0 left-0 w-full  h-0.5  rounded bg-black transition-transform duration-300 ease-in-out"></div>
                </div>

                <div className="flex cursor-pointer pt-4 px-6 w-fit gap-1 items-center">
                  <span className="font-[500] pb-1.5 text-black text-sm ">
                    Matching
                  </span>
                </div>
              </div>
            </header>

            <div className=" flex flex-col gap-6 ">
              <header className="flex justify-between">
                <h1 className=" text-[18px] font-bold">
                  Invoice | INV-{invoiceByIdData?.data?.invoiceNumber || "000"}
                </h1>

                <motion.button
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setMakePaymentModal(true)}
                  className="rounded-[5px] text-xs text-white bg-primaryColor py-2 px-8"
                >
                  Initiate Disbursement
                </motion.button>
              </header>

              <main className=" w-full rounded-b-[10px] flex flex-col gap-4 md:flex-row justify-between">
                <div className="flex flex-col gap-3 w-full md:w-1/4">
                  <div className="flex  gap-4 justifybetween">
                    <span>Reference No:</span>
                    <span>
                      {invoiceByIdData?.data?.invoiceReference || "XXXXXXX"}
                    </span>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div className="flex justify-between">
                      <span className=" text-xs">Created Date:</span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceByIdData?.data?.createdDate?.slice(0, 10) ||
                          "23 - Dec - 2024"}
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <span className=" text-xs">Payment Due Date:</span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceByIdData?.data?.dueDate || "23 - Dec - 2024"}
                      </span>
                    </div>

                    <div className="flex justify-between">
                      <span className=" text-xs">Request Date:</span>
                      <span className="text-[#979797]  text-xs">
                        {invoiceByIdData?.data?.dueDate || "23 - Dec - 2024"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-2.5">
                  <div className="flex items-end gap-1 flex-col">
                    <span> New Invoice Amount</span>
                    <span className=" font-medium  text-xl text-primaryColor">
                      NGN 355,600
                    </span>
                  </div>

                  <div className=" text-secondaryColor flex flex-col items-end gap-[5px]">
                    <span className=" font-thin  line-through text-sm">
                      NGN 350,000
                    </span>
                    <span className=" text-xs">+1.67%</span>
                  </div>
                </div>
              </main>
            </div>

            <div className="flex justify-between">
              <div className="flex gap-1 flex-col">
                <span className=" font-bold text-sm">Payment</span>
                <span className="text-[#979797]  text-xs">60 Days</span>
              </div>
              <div className="flex flex-col gap-1">
                <span className=" font-thin text-[#222] text-sm">Currency</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceByIdData?.data?.currency || "NGN"}
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-[18px] font-bold">
                  Supplier/buyer details
                </h1>
              </header>
              <div className="flex  justify-between">
                <div className="flex gap-2">
                  <PAIconCocacola />
                  <div className="flex flex-col">
                    <span className=" font-bold text-sm">
                      {invoiceByIdData?.data?.supplierCompanyName || "Dangote"}
                    </span>
                    <span className="text-[#979797]  text-sm">Supplier</span>
                  </div>
                </div>

                <div className="flex gap-2">
                  <PAIconModelOne />
                  <div className="flex flex-col">
                    <span className=" font-bold text-sm">
                      {invoiceByIdData?.data?.buyerCompanyName || "Unilever"}
                    </span>
                    <span className="text-[#979797]  text-sm">Buyer</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Line Items</h1>
              </header>

              <div className=" w-full overflow-x-auto">
                {invoiceByIdData ? (
                  <table className=" w-full overflow-x-auto">
                    <thead className="bg-subtleGray w-">
                      <tr className=" bg-unusualGray p-2.5 ">
                        <td className="text-[#11192A] p-2.5 text-xs font-bold">
                          Item Number
                        </td>
                        <td className=" p-2.5 text-xs font-bold">PO Number</td>
                        <td className=" p-2.5 text-xs font-bold">
                          Product/Service Number
                        </td>
                        <td className=" p-2.5 text-xs font-bold">
                          Product/Service Description
                        </td>
                        <td className=" p-2.5 text-xs font-bold">Quantity</td>
                        <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                        <td className=" p-2.5 text-xs font-bold">
                          Line Item Extended Amount
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {invoiceByIdData?.data?.items?.map((item, index) => (
                        <tr key={index} className=" py-[3px] px-1.5">
                          <td className=" text-xs text-[#11192a] p-2.5">
                            INV-{invoiceByIdData?.data?.invoiceNumber}
                          </td>
                          <td className=" text-xs text-[#11192a] p-2.5">
                            PO-2002
                          </td>
                          <td className=" text-xs text-[#11192a] p-2.5">
                            {item?.itemName}
                          </td>
                          <td className=" text-xs text-[#11192a] p-2.5">
                            {item?.description}
                          </td>
                          <td className=" text-xs text-[#11192a] p-2.5">
                            {item?.quantity}
                          </td>
                          <td className=" whitespace-nowrap text-xs text-[#11192a] p-2.5">
                            {invoiceByIdData?.data?.currency === "NGN"
                              ? "₦"
                              : "$"}{" "}
                            {item?.unitPrice}
                          </td>
                          <td className=" text-xs text-[#11192a] p-2.5">
                            {invoiceByIdData?.data?.currency === "NGN"
                              ? "₦"
                              : "$"}{" "}
                            {item?.amount}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="flex flex-col gap-3 animate-pulse justify-center items-center w-full py-6 px-4 text-sm font-bold">
                    <PAIconInvoiceIcon />
                    No invoice list available
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Attachments</h1>
              </header>

              <div className="w-full overflow-x-auto">
                <table className="w-full overflow-x-auto">
                  <thead className="  bg-subtleGray w-">
                    <tr className="px-4  bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                        Document Name
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">
                        File Type
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">
                        Upload Date / Time
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr className=" py-[3px] px-1.5">
                      <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                        DOC-29384.pdf
                      </td>
                      <td className=" text-xs text-[#11192a] px-4 py-2.5">
                        PDF
                      </td>
                      <td className=" text-xs text-[#11192a] px-4 py-2.5">
                        2021-09-14 13:25
                      </td>
                      <td className="cursor-pointer text-xs text-[#11192a] px-4 py-2.5">
                        <PAIconDownload />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </motion.section>
        </main>
      </TransitionScale>

      {makePaymentModal && (
        <ViewFundInvoiceModal>
          <div className="flex lg:min-w-[520px] lg:max-w-[550px] overflowscroll max-h-[87vh]  flex-col gap-4">
            <header className="flex sticky top-0 bg-white py-3  items-center justify-between">
              <span className=" text-sm">Invoice Disbursement</span>

              <motion.button
                onClick={() => {
                  setMakePaymentModal(false);
                }}
                whileTap={{ scale: 0.9 }}
                className="text-xs border border-primaryColor py-2 px-8  rounded-[5px] text-primaryColor"
              >
                Close
              </motion.button>
            </header>

            <main className="flex flex-col  gap-6">
              <section className="flex gap-28 justify-between">
                <div className="flex flex-col gap-2">
                  <span className="text-[#222] text-[20px] font-bold">
                    Wale Sach Corp.
                  </span>

                  <div className=" text-xs">
                    <span className=" text-[#7A7A7A]">78 Invoices</span> Invoice
                    No : | Buyer Info{" "}
                    <span className=" text-[#7A7A7A]">Walediwura</span>
                  </div>
                </div>

                <div className="flex items-center">
                  <motion.button
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setAcceptPaymentModal(true)}
                    className="text-xs bg-primaryColor py-2 px-8  rounded-[5px] text-white"
                  >
                    Make Payment
                  </motion.button>
                </div>
              </section>

              <section className="flex gap-28 justify-between">
                <div className="flex flex-col">
                  <div>
                    <span>Payment Amount</span>
                  </div>
                  <span className="text-primaryColor text-[20px] font-bold">
                    NGN 1,890,400
                  </span>
                </div>

                <div className="flex flex-col gap-4 items-end">
                  <span className=" text-xs">
                    Total Invoice Amount &nbsp;
                    <span className="text-primaryColor border-b pb-0.5 border-b-[#AFAFAF]">
                      N2,345,000
                    </span>
                  </span>

                  <span className="border-b pb-0.5 text-xs  border-b-[#AFAFAF] w-fit">
                    Discount: 1.67%
                  </span>

                  <span className="text-[10px] font-medium flex items-center gap-1 text-[#F08000]">
                    <PAIconInfoGreen />
                    Tap to view more
                  </span>

                  <span>
                    <motion.button
                      whileTap={{ scale: 0.9 }}
                      className="py-2 px-8 bg-[#F08000] text-xs text-white  rounded-[5px]"
                    >
                      Submit new amount
                    </motion.button>
                  </span>
                </div>
              </section>

              <section className=" flex flex-col ">
                <header className=" pb-3 font-bold border-b-[5px] mb-4 border-[#F6F5FA] text-[18px]">
                  Buyer Bulk Invoice details
                </header>

                <main className="flex pb-4 justify-between">
                  <div className="flex flex-col">
                    <span className="text-sm font-medium">Supplier</span>

                    <span className="text-xs text-[#7A7A7A]">
                      ABC Cooperation
                    </span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-sm font-medium">Buyer</span>

                    <span className="text-xs text-[#7A7A7A]">
                      Big Buyer Coop
                    </span>
                  </div>
                </main>

                <div>
                  <header className=" pb-3 font-bold border-b-[5px] mb-4 border-[#F6F5FA] text-sm">
                    Line Items
                  </header>

                  <div className=" w-full overflow-x-auto">
                    {invoiceByIdData ? (
                      <table className=" w-full overflow-x-auto">
                        <thead className="bg-subtleGray w-">
                          <tr className=" bg-unusualGray p-2.5 ">
                            <td className="text-[#11192A] p-2.5 text-xs font-bold">
                              Item Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              PO Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Product/Service Number
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Product/Service Description
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Quantity
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Unit Price
                            </td>
                            <td className=" p-2.5 text-xs font-bold">
                              Line Item Extended Amount
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {invoiceByIdData?.data?.items?.map((item, index) => (
                            <tr key={index} className=" py-[3px] px-1.5">
                              <td className=" text-xs text-[#11192a] p-2.5">
                                INV-{invoiceByIdData?.data?.invoiceNumber}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                PO-2002
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.itemName}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.description}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {item?.quantity}
                              </td>
                              <td className=" whitespace-nowrap text-xs text-[#11192a] p-2.5">
                                {invoiceByIdData?.data?.currency === "NGN"
                                  ? "₦"
                                  : "$"}{" "}
                                {item?.unitPrice}
                              </td>
                              <td className=" text-xs text-[#11192a] p-2.5">
                                {invoiceByIdData?.data?.currency === "NGN"
                                  ? "₦"
                                  : "$"}{" "}
                                {item?.amount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="flex flex-col gap-3 animate-pulse justify-center items-center w-full py-6 px-4 text-sm font-bold">
                        <PAIconInvoiceIcon />
                        No invoice list available
                      </div>
                    )}
                  </div>

                  <div className="flex py-5 flex-col gap-4">
                    <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                      <h1 className="text-sm font-bold">Attachments</h1>
                    </header>

                    <div className="w-full overflow-x-auto">
                      <table className="w-full overflow-x-auto">
                        <thead className="  bg-subtleGray w-">
                          <tr className="px-4  bg-unusualGray p-2.5 ">
                            <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                              Document Name
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              File Type
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              Upload Date / Time
                            </td>
                            <td className=" px-4 py-2.5 text-xs font-bold">
                              Action
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          <tr className=" py-[3px] px-1.5">
                            <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                              DOC-29384.pdf
                            </td>
                            <td className=" text-xs text-[#11192a] px-4 py-2.5">
                              PDF
                            </td>
                            <td className=" text-xs text-[#11192a] px-4 py-2.5">
                              2021-09-14 13:25
                            </td>
                            <td className="cursor-pointer text-xs text-[#11192a] px-4 py-2.5">
                              <PAIconDownload />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </ViewFundInvoiceModal>
      )}

      <AnimatePresence>
        {acceptPaymentModal && (
          <ViewDocumentModal
            onAction={() => {
              setAcceptPaymentModal(false);
              setMakePaymentModal(false);
              setTransferModal(true);
            }}
            onClose={() => setAcceptPaymentModal(false)}
          >
            <span className=" text-center">
              Are you sure you would like to make payment for this invoice?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {transferModal && (
          <TransferFundsModal
            onAction={() => {
              setTransferModal(false);
              setPinModal(true);
            }}
            onClose={() => setTransferModal(false)}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        <EnterPin isOpen={pinModal} onClose={() => setPinModal(false)} />
      </AnimatePresence>
    </DashboardLayout>
  );
}

export default DisbursementInvoice;
