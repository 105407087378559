import React from "react";

const SettlementReportModal = ({ isOpen, onClose, onContinue }) => {
  const reportItems = [
    { id: "1", file: "Gross Amount", price: "$2500.03" },
    { id: "2", file: "Discount", price: "-$15.66" },
    { id: "3", file: "Processing Fee", price: "-$15.66" },
    { id: "4", file: "Transaction Fee", price: "-$15.66" },
    { id: "5", file: "PayEdge fee", price: "-$15.66" },
    { id: "6", file: "Charge Reversal", price: "-$15.66" },
    { id: "7", file: "Buyer Share", price: "-$15.66" },
    { id: "8", file: "Funder Share", price: "-$15.66" },
  ];

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-[10px] z-[1000]">
        <div className="bg-white  rounded-[10px] max-w-xs md:max-w-lg w-full   lg:min-w-[650px] min:min-h-[500px]  pb-20  ">
          <div className="flex justify-between items-center mb-4 bg-[#FAFAFA] rounded-t-[10px] ">
            <p className=" text-[#7A7A7A] font-sans text-base p-3 px-5 rounded-sm font-thin ">
              Settlement Report
            </p>
            <button
              onClick={onClose}
              className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
            >
              &times; <span className="text-sm mx-2 mt-1">Close</span>
            </button>
          </div>

          <div className=" px-4">
            <div class="w-full h-[39px] justify-between items-start inline-flex flex-wrap">
              <div class="text-neutral-800 text-lg font-bold ">09347T-2090</div>
              <div
                class="p-2.5 bg-amber-600 rounded justify-center items-center gap-2.5 flex"
                onClick={onContinue}
              >
                <div class="text-white text-base font-medium ">
                  Download Report
                </div>
              </div>
            </div>

            <div className=" mt-7 w-full flex flex-row justify-between flex-wrap">
              <div class="w-[200px] h-[69px] px-6 py-3 border border-zinc-300 flex-col justify-center items-start gap-2 inline-flex">
                <div class="text-neutral-800 text-xs font-bold ">
                  Net Amount{" "}
                </div>
                <div class="text-green-600 text-lg font-bold ">$2,000.03</div>
              </div>

              {/* second */}

              <div class="w-[200px] h-[69px] px-6 py-3 border border-zinc-300 flex-col justify-center items-start gap-2 inline-flex">
                <div class="text-neutral-800 text-xs font-bold ">
                  Gross Amount
                </div>
                <div class="text-neutral-800 text-lg font-bold ">$2,500.03</div>
              </div>

              {/* third */}

              <div class="w-[200px] h-[69px] px-6 py-3 border border-zinc-300 flex-col justify-center items-start gap-2 inline-flex">
                <div class="text-neutral-800 text-xs font-bold ">
                  Transaction Date
                </div>
                <div class="text-neutral-800 text-lg font-bold ">
                  Feb 23, 2024{" "}
                </div>
              </div>
            </div>

            <div className=" mt-9 overflow-y-scroll ">
              <div class="text-neutral-800 text-sm font-bold ">
                Primary Record
              </div>
              <div class="w-full h-[41px] px-2 py-3 border-b border-zinc-300 justify-between items-center inline-flex">
                <div class="text-neutral-800 text-sm font-normal ">
                  Capture Date
                </div>
                <div class="text-neutral-800 text-sm font-bold ">
                  Feb 28 15:04 AM
                </div>
              </div>

              {reportItems.map((item) => (
                <div class="w-full h-[41px] px-2 py-3 border-b border-zinc-300 justify-between items-center inline-flex">
                  <div class="text-neutral-800 text-sm font-normal ">
                    {item.file}
                  </div>
                  <div
                    class={` ${
                      item.price.startsWith("-", 0)
                        ? "text-[#A92B23]"
                        : "text-green-600"
                    } text-sm font-bold `}
                  >
                    {item.price}
                  </div>
                </div>
              ))}

              <div class="w-full h-[41px] px-2 py-3 border-b border-zinc-300 justify-between items-center inline-flex">
                <div class="text-neutral-800 text-sm font-bold ">
                  Net Amount
                </div>
                <div class="text-green-600 text-sm font-bold ">$2000.03</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettlementReportModal;
