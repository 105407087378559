import React, { useEffect } from "react";
import {
  PAIconCardPayment,
  PAIconMastercardWhite,
  PAIconPayAssystLight,
} from "assets/images/svgs";
import { useDispatch, useSelector } from "react-redux";
import {
  allVirtualCards,
  eachVirtualCard,
  editVirtualCard,
} from "appstate/wallet/walletSlice";
import Spinner from "shared/Spinner/Spinner";

const CardDetails = ({ isOpen, onClose, onSubmit, editedData }) => {
  const dispatch = useDispatch();
  const { isLoading, virtualCardDetails, virtualCardId } = useSelector(
    (state) => state?.wallet
  );

  useEffect(() => {
    if (isOpen && virtualCardId) {
      dispatch(eachVirtualCard({ cardId: virtualCardId }));
    }
  }, [dispatch, isOpen, virtualCardId]);

  const cardData = [
    {
      cardName: virtualCardDetails?.cardName,
      cardNumber: virtualCardDetails?.cardNumber,
      balance: virtualCardDetails?.balance,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      paymentMethod: editedData?.paymentMethod,
      cardCurrency: editedData?.cardCurrency,
    };

    dispatch(editVirtualCard({ cardId: virtualCardId, body })).then((data) => {
      dispatch(allVirtualCards());
    });

    dispatch(eachVirtualCard({ cardId: virtualCardId })).then((data) => {});
  };

  if (!isOpen) return null;

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   onSubmit();
  // };

  return (
    <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]'>
      <div className='bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5'>
        <div className='flex justify-between items-center mb-4 bg-[#FAFAFA]'>
          <p className='text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic'>
            Edit Card
          </p>
          <button
            onClick={onClose}
            className='text-black font-sans text-xl cursor-pointer flex justify-center items-center'
          >
            &times; <span className='text-sm mx-2 mt-1'>Close</span>
          </button>
        </div>

        {/* FORM */}
        <div>
          <div className='w-full px-7' onSubmit={handleSubmit}>
            <p className='mb-4 text-[#222] font-sans text-sm font-bold'>
              Card Information
            </p>

            <div>
              <div className='relative mt-3 text-white text-xs md:w-[calc(70%-10px)] md:mb-0 mb-3'>
                <div className='bg-[#07593D] p-3 rounded-t-lg cursor-pointer'>
                  <div className='flex items-center justify-between'>
                    <div className='text-white text-xs flex items-center'>
                      <PAIconPayAssystLight className='mr-2' />
                    </div>
                    <div className='cursor-pointer accent-white text-white'>
                      <PAIconCardPayment className='color-white text-white' />
                    </div>
                  </div>
                  <div className='flex items-center justify-between my-3'>
                    <div className='text-white text-xs font-semibold'>
                      {cardData?.cardName || "N/A"}
                    </div>
                    <p className='mt-2 italic'>
                      {editedData?.cardCurrency || "N/A"}
                    </p>
                  </div>
                </div>
                <div className='bg-amber-600 p-3 rounded-b-lg'>
                  <div className='flex items-center justify-between'>
                    <div className='text-white text-xl font-medium'>
                      <p>{editedData?.paymentMethod || "N/A"}</p>
                      <p className='text-white text-[8.83px] font-normal'>
                        Hide balance
                      </p>
                    </div>
                    <div className='text-white text-xl font-medium'>
                      <div className='flex justify-end'>
                        <PAIconMastercardWhite />
                      </div>
                      <p className='text-white text-[8.83px] font-normal'>
                        {editedData?.paymentMethod || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Buttons */}
            <div className='flex justify-end mt-10'>
              <div className='w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-gray300 mx-2'>
                <button
                  type='button'
                  onClick={onClose}
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                >
                  Cancel
                </button>
              </div>
              <div className='w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]'>
                <button
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                  type='submit'
                  onClick={handleSubmit}
                  disabled={isLoading ? isLoading : null}
                >
                  {isLoading ? <Spinner /> : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
