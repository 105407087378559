import { useState } from 'react'
import InvoicesTab from './TabsComponents/InvoicesTab';
import PurchaseOrderTab from './TabsComponents/PurchaseOrderTab';
import LogisticsTab from './TabsComponents/LogisticsTab';
import MatchingTab from './TabsComponents/MatchingTab';
import Tabs from 'shared/Tabs';

const InvoiceTabs = ({ singleInvoice }) => {
  const [open, setOpen] = useState(false)

  const invoicetabs = [
    {
      title: 'Invoices',
      component: <InvoicesTab open={open} setOpen={setOpen} singleInvoice={singleInvoice} />,
    },
    {
      title: 'Purchasing Order',
      component: <PurchaseOrderTab singleInvoice={singleInvoice} />,
    },
    {
      title: 'Logistics',
      component: <LogisticsTab />,
    },
    {
      title: 'Matching',
      component: <MatchingTab />,
    },
  ];

  return (
    <Tabs
      actionComponent
      approved={singleInvoice.status === "Approved"}
      actionComponentTitle="Request"
      tabs={invoicetabs}
      setOpen={setOpen}
    />
  )
}

export default InvoiceTabs