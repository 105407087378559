import {
  PAIconDetailsPlaceholder,
  PAIconArrowLeftGreen,
  PAIconMDISuccess,
  PAIconIconParkSucess,
} from "assets/images/svgs";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import InvoiceModalContainer from "shared/invoiceModal";
import { Link } from "react-router-dom";
import Table from "layout/table/Table";
import TransferInfoModal from "../../components/modal/TranferInfoModal";
import toast from "react-hot-toast";

const Details = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [disputeText, setDisputeText] = useState("");

  const dummyData = [
    {
      bidAmount: 4,
      amount: "-4,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Part Payment",
      invoiceDate: "2023-05-23",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "09347T-2090",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-05-31",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Nestle PLC",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "NGN",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction ID
        </span>
      ),
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Buyer
        </span>
      ),
      selector: (row) => row.funder,
      sortable: true,
      cell: (row) => (
        <>
          <div className=" w-[7rem] flex-wrap flex-col">{row.funder}</div>
        </>
      ),
      // width: '7rem',
    },

    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
      // width: '9rem',
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
      // width: '7rem',
    },

    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      // width: '7rem',
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.amount.startsWith("+", 0) ? (
          <p className="text-success">{row.amount}</p>
        ) : row.amount.startsWith("-", 0) ? (
          <p className="text-red">{row.amount}</p>
        ) : (
          <p>{row.amount}</p>
        ),
      // width: '7.9rem',
    },
  ];

  const onChangeText = (e) => {
    e.preventDefault();
    setDisputeText(e.target.value);
  };

  const disputeTypeData = [
    { id: 1, type: "Failed Transfer" },
    { id: 2, type: "Double Debit" },
    { id: 3, type: "Erroneous Transfer" },
    { id: 4, type: "Unauthorized Transfer" },
    { id: 5, type: "Others" },
  ];

  function handleProceedToImport() {
    setInfoModalOpen(false);
    toast.custom(
      <div className="flex mt-[200px] ">
        <div className="bg-primaryColor  h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
          <PAIconMDISuccess />
        </div>
        <div className="bg-white flex flex-col justify-center items-center  h-[160px] rounded-r-[7px] shadow-lg">
          <PAIconIconParkSucess />
          <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
            Dispute sent successfully
          </p>
        </div>
      </div>
    );
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <TransferInfoModal
        isOpen={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        onConfirm={handleProceedToImport}
        disputeTypeData={disputeTypeData}
        onChange={onChangeText}
        textareaValue={disputeText}
        bodyText={"Describe  issue"}
        labelTitleText={"Dispute Type"}
        placeholderText={"Enter the issue"}
      />
      <InvoiceModalContainer isShown={open} onClose={() => setOpen(false)} />
      <div>
        <div>
          <Link
            onClick={goBack}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full bg-white py-4 rounded-md px-4"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>
        </div>
        <header className="bg-alabasterHeader p-[24px] rounded-t-[10px]">
          <div className="flex gap-x-[395px] flex-wrap md:gap-x-[200px] lg:gap-x-[280px] xl:gap-x-[395px] justify-between w-full">
            <div>
              <div class="text-[#565656] text-sm font-normal ">
                Payment Status
              </div>
              <div class="text-[#07593D] text-sm font-bold ">Completed</div>
            </div>

            <div>
              <div
                class=" px-6 py-2 bg-[#07593D] rounded-[5px] justify-start items-center gap-2.5 inline-flex"
                onClick={() => setInfoModalOpen(true)}
              >
                <div class="text-white text-sm font-medium ">Dispute</div>
              </div>
            </div>
          </div>
        </header>
        <Card className="px-[20px] md:px-[40px] pb-[40px] rounded-b-[10px] 2xl:pl-[100px] flex-1  lg:min-h-[36rem]">
          <div>
            <div class="text-neutral-800 text-sm font-bold ">
              Transaction History
            </div>
            {/* Table section */}
            <div>
              <Table
                columns={columns}
                data={dummyData}
                pointer
                tableHeader
                className="mt-5"
              />
            </div>

            <div className="mt-[48px]">
              <div class="text-neutral-800 text-sm font-bold ">
                Matching Invoice
              </div>
              <div className="mt-[24px] flex gap-x-[24px]">
                <div className="cursor-pointer" onClick={() => setOpen(true)}>
                  <PAIconDetailsPlaceholder />
                  <p className="text-xs font-normal text-textColor">
                    #INV-2012
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Details;
