import request from "apiInstance";
import toast from "react-hot-toast";

export const documentUpload = async (data) => {
  try {
    const formData = new FormData();
    formData.append("document", data.image);
    for (const [key, value] of Object.entries(data.body)) {
      formData.append(key, value);
    }
    const response = await request({
      method: "post",
      url: "/api/v1/documents/upload",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
    });
    if (response?.data) {
      toast.success("Document uploaded successfully");
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateUploadedDocumentById = async (data) => {
  try {
    const formData = new FormData();
    formData.append("document", data.image);
    for (const [key, value] of Object.entries(data.body)) {
      formData.append(key, value);
    }
    const response = await request({
      method: "put",
      url: `/api/v1/documents/update/${data.id}`,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
    });
    if (response?.data) {
      toast.success("Documnet updated successfully");
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const deleteUploadedDocumentById = async (id) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/documents/delete/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getUploadedDocuments = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/documents/view-documents",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getUploadedDocumentById = async (id) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/documents/view-document/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
