import React from "react";
import { PAIconCancelMini } from "assets/images/svgs";
import { AnimatePresence, motion } from "framer-motion";
import CheckMark from "./Checkmark";

function BidLoaderModal({
  onClose,

  onAction,
  statusText,
}) {
  const handleSubmit = () => {
    onAction();
  };

  return (
    <>
      <motion.div>
        <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              opacity: 0,
              transition: {
                delay: 0.1,
              },
            }}
            className="modal-overlay"
          ></motion.div>

          <motion.form
            onSubmit={handleSubmit}
            initial={{
              scale: 0,
            }}
            animate={{
              scale: 1,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              scale: 0,
              transition: {
                delay: 0.1,
              },
            }}
            className="bg-white overflow-auto rounded-[10px] min-w-[40%] max-w-[60%] lg:max-w-[47%]  max-h-[80%] lg:max-h-[70%] min-h-[50%] pb-10 flex flex-col gap-1"
          >
            <header className=" px-6  items-center flex bg-[#FAFAFA] py-5  justify-between">
              <span className=" font-bold">{statusText}</span>

              <span
                onClick={() => onClose()}
                className="flex cursor-pointer items-center gap-2"
              >
                <PAIconCancelMini /> Close
              </span>
            </header>

            <section className="text-[#5F4D61] text-center px-6 mt-4 mb-4">
              Verifying if account is sufficient for liening
            </section>

            <section className="px-6 py-8 h-full justify-center flex flex-col gap-3">
              <AnimatePresence>
                <CheckMark onAction={onAction} />
              </AnimatePresence>
            </section>
          </motion.form>
        </motion.div>
      </motion.div>
    </>
  );
}

export default BidLoaderModal;
