import React from "react";
import DashboardLayout from "layout/dashboardlayout";

import Details from "./details";
const FunderClosedMarketOnGoingBidDetails = () => {
  return (
    <DashboardLayout
      section="Closed Market"
      subHeader="View invoices uploaded to the closed market by your associated buyers"
    >
      <Details />
    </DashboardLayout>
  );
};

export default FunderClosedMarketOnGoingBidDetails;
