import {
  PAIconInventoryArrowLeft,
  PAIconSearchPlaceholder,
} from "assets/images/svgs";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";

const Heading = ({ setCompanyName, search, companyName }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };
  return (
    <div className=" bg-gray700 px-[24px] pt-[24px] pb-[10px] rounded-t-lg flex-wrap">
      <div
        onClick={handleNavigate}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
      >
        <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
      </div>
      <div className="flex justify-between">
        <div>
          <h3 className="text-sm font-medium">Associate with a new funder</h3>
          <p className="text-xs font-normal">List of funders available</p>
        </div>
        <div className="flex justify-between gap-x-[10px]">
          <div>
            <InputField
              className="h-[41px] w-[250px] pl-[30px] border-[.5px] border-silver border-b-[.5px] rounded placeholder:pl-[20px] px-[10px] outline-none md:w-[400px]"
              placeholder="Search buyers"
              type="text"
              id="search"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <PAIconSearchPlaceholder className="relative bottom-7 left-[7px]" />
          </div>
          <Button
            neutral
            onClick={search}
            className="w-[96px] h-[41px] bg-headerbtnBg flex justify-center items-center border-[0.5px] border-gray rounded-[5px] text-sm font-normal !text-black"
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Heading;
