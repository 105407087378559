import { eachVirtualCard } from "appstate/wallet/walletSlice";
import VirtualCard from "features/wallet/createVirtualAccount/step2/virtualCard";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const ViewCardInfo = ({ isOpen, onClose, onSubmit, cardId }) => {

  const dispatch = useDispatch();

  const { virtualCardDetails } = useSelector(
    (state) => state?.wallet
  );
  useEffect(() => {
    dispatch(eachVirtualCard({ cardId: cardId }));
  }, [dispatch, cardId]);

    const [data, setData] = useState(null);

  useEffect(() => {
    setData({
      cardName: virtualCardDetails?.cardName || "N/A",
      cardCurrency: virtualCardDetails?.cardCurrency || "N/A",
      cardNumber: virtualCardDetails?.cardNumber || "N/A",
      expiryDate: virtualCardDetails?.expiryDate || new Date(),
      cvv: virtualCardDetails?.cvv || "N/A"
    });
  }, [virtualCardDetails]);

  // Function to format card number with spaces after every 4 digits
  const formatCardNumber = (cardNumber) => {
    if (!cardNumber) {
      return "";
    }

    const formattedCardNumber = cardNumber.replace(/(\d{4})/g, "$1 ");
    return formattedCardNumber.trim();
  };

  const formatExpiryDate = (expiryDate) => {
    if (!expiryDate) {
      return "";
    }
  
    const options = { month: "2-digit", year: "2-digit" };
    return new Date(expiryDate).toLocaleDateString(undefined, options);
  };


  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Card Information
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        {/* FORM */}
        <div>
          <div className="w-full px-7 flex items-center justify-center py-5">
            <VirtualCard
              name={data?.cardName}
              cardNumber={formatCardNumber(data?.cardNumber)}
              date={formatExpiryDate(data.expiryDate)}
              cvv={data.cvv}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCardInfo;
