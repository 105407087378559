// import TransferInfoReceipt from "features/wallet/withdraw/receipt";
import React, { useState, useRef, useEffect } from "react";

const PinModal = ({
  isOpen,
  onClose,
  onSubmit,
  headerText,
  instructionText,
}) => {
  const [otpInputs, setOtpInputs] = useState(["", "", "", ""]);
  //   const [showReceipt, setShowReceipt] = useState(false);

  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, otpInputs.length);
  }, [otpInputs.length]);

  const handleInputChange = (index, value) => {
    if (!isNaN(value) || value === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index] = value;
      setOtpInputs(newOtpInputs);

      if (value !== "" && index < otpInputs.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otpInputs[index] === "") {
      const newOtpInputs = [...otpInputs];
      newOtpInputs[index - 1] = "";
      setOtpInputs(newOtpInputs);

      inputRefs.current[index - 1].focus();
    }
  };

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    const pin = otpInputs.join("");
    onSubmit(pin);
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
        <div className="bg-white p10 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5 px10">
          <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
            <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
              {/* Withdraw Funds */}
              {headerText}
            </p>
            <button
              onClick={onClose}
              className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
            >
              &times; <span className="text-sm mx-2 mt-1">Close</span>
            </button>
          </div>

          {/* PIN Form */}
          <form className="w-full px-7 pt-7" onSubmit={handleSubmit}>
            <p className="text-[#86888B] font-sans text-xs font-normal mb-5">
              Step 2
            </p>
            <p className="mb-2 flex justify-center text-[#222] font-sans italic text-xl font-extrabold leading-normal">
              Enter your pin
            </p>
            <p className="text-[#86888B] font-sans text-xs font-normal flex justify-center mb-5">
              {/* Enter your transaction PIN to transfer funds to desired account */}
              {instructionText}
            </p>
            <div className="mb-4 flex flex-row justify-center gap-2 mt-7">
              {otpInputs.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  value={value.replace(/./g, "*")} // Replace the typed characters with asterisks
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  maxLength="1"
                  className="border rounded p-2 text-green-900 border-green-900 focus:border-[#222] focus:outline-none w-10 h-10 flex-shrink-0 text-center"
                  ref={(ref) => (inputRefs.current[index] = ref)}
                />
              ))}
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-10">
              <div className="w-[105px] h-[35px] px-6 py-2 rounded-[5px] justify-center items-center gap-2.5 inline-flex mx-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="text-center text-gray text-base font-normal tracking-wide"
                >
                  Cancel
                </button>
              </div>
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]">
                <button
                  className="text-center text-white text-base font-normal tracking-wide"
                  type="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PinModal;
