import Card from 'shared/Card'
import InvoiceTabs from './InvoiceTabs'
import { useSelector } from 'react-redux'
import DashboardLayout from 'layout/dashboardlayout'
import { useNavigate } from 'react-router-dom'
import { PAIconFilledArrowLeft } from 'assets/images/svgs'

const SingleInvoiceContents = () => {
  const navigate = useNavigate()
  const { singleInvoice } = useSelector((state) => state.invoice);

  const goBack = () => {
    navigate(-1);
  }

  return (
    <DashboardLayout section="Invoice" subHeader="Here is an overview of your invoices">
      <Card className="rounded-[10px]">
        <div className='bg-[#FAFAFA] flex max-[500px]:px-4 px-8 py-8 justify-start'>
          <div onClick={goBack} className='flex gap-2 hover:cursor-pointer hover:font-medium'>
            <PAIconFilledArrowLeft />
            <p className='text-[#7A7A7A] text-[14px]'>Go back</p>
          </div>
        </div>
        <InvoiceTabs singleInvoice={singleInvoice?.data} />
      </Card>
    </DashboardLayout>
  )
}

export default SingleInvoiceContents