import { useState } from "react";
// import Button from 'shared/Button';
import { useFormik } from "formik";
// import toast from 'react-hot-toast';
import Spinner from "shared/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// import { createNewInvoice, getAllPlayerInvoices, getPlayerInvoiceById, updateNewInvoice } from 'appstate/invoice/invoiceSlice';
import DeleteIcon from "../../../assets/images/svgs/DeleteIcon.svg";
import InputField from "shared/InputField";
import Button from "shared/Button";

const NewInvoiceTable = ({
  toggleTaxInclusive,
  editing,
  setEditing,
  controls,
  setControls,
  singleInvoice,
}) => {
  const [items, setItems] = useState(
    singleInvoice
      ? singleInvoice.items
      : [
          {
            id: Date.now(),
            itemName: "",
            description: "",
            unitPrice: "",
            taxRate: "",
            quantity: "",
          },
        ]
  );
  const [discount, setDiscount] = useState("");
  const [vat, setVat] = useState("");

  // const dispatch = useDispatch();
  const navigate = useNavigate();

  // const { userDetails } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.invoice);

  // let senderRole;

  // if (userDetails?.data?.role === "SUPPLIER") {
  //   senderRole = "buyer";
  // } else if (userDetails?.data?.role === "BUYER") {
  //   senderRole = "supplier";
  // }

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newItems = [...items];
    newItems[index] = { ...newItems[index], [name]: value };

    if (name === "quantity" || name === "unitPrice" || name === "taxRate") {
      const unitPrice = parseFloat(newItems[index].unitPrice);
      const quantity = parseFloat(newItems[index].quantity);
      const taxRate =
        newItems[index].taxRate === ""
          ? null
          : parseFloat(newItems[index].taxRate);

      if (quantity < 0 || unitPrice < 0 || taxRate < 0) {
        return;
      }
      const tempRate = parseFloat(
        (unitPrice * quantity * (taxRate / 100)).toFixed(2)
      );
      const tempAmount = parseFloat((unitPrice * quantity).toFixed(2));
      newItems[index].amount = tempAmount + tempRate;
    }

    setItems(newItems);
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        id: Date.now(),
        itemName: "",
        description: "",
        unitPrice: "",
        taxRate: "",
        quantity: "",
      },
    ]);
  };

  const handleDeleteItem = (id) => {
    const newItems = items.filter((item) => item.id !== id);
    setItems(newItems);
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    items.forEach((item) => {
      totalPrice += parseFloat(item.amount || 0);
    });
    return totalPrice?.toFixed(2);
  };

  const CalculateGrandTotal = () => {
    const subtotal = parseFloat(calculateTotalPrice());
    const discountAmount = subtotal * (parseFloat(discount) / 100 || 0);
    const vatAmount = subtotal * (parseFloat(vat) / 100 || 0);
    const tempGrandTotal = subtotal + vatAmount - discountAmount;
    return tempGrandTotal?.toFixed(2);
  };

  const formik = useFormik({
    initialValues: {
      dueDate: controls.dueDate,
      currency: controls.currency,
      taxInclusive: controls.taxInclusive,
      items: items,
    },
    onSubmit: () => {
      // const body = {
      //   dueDate: controls.dueDate,
      //   currency: controls.currency,
      //   taxInclusive: controls.taxInclusive,
      //   items: items,
      // };
      if (editing) {
        // dispatch(updateNewInvoice({ body, type: `for-${senderRole}`, invoiceId: singleInvoice.id })).then((data) => {
        //   dispatch(getAllPlayerInvoices({ playerType: userDetails?.data?.role?.toLowerCase() }));
        //   dispatch(getPlayerInvoiceById({ player: userDetails?.data?.role?.toLowerCase(), invoiceId: singleInvoice?.id })).then(() => {
        //     if (data?.payload?.status === 200) {
        //       toast.success('Invoice edited successfully');
        //       let path = `/invoice/${singleInvoice?.id}`;
        //       navigate(path);
        //     }
        //   });
        // });
      } else {
        // dispatch(createNewInvoice({ body, type: `for-${senderRole}`, senderRole: senderRole, senderId: controls.to })).then((data) => {
        //   dispatch(getAllPlayerInvoices({ playerType: userDetails?.data?.role?.toLowerCase() })).then(() => {
        //     if (data?.payload?.status === 200) {
        //       toast.success('Invoice created successfully');
        //       let path = "/invoice/invoice";
        //       navigate(path);
        //     }
        //   });
        // });
      }
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
      <div className="overflow-x-auto">
      <div className="flex justify-between px-7 items-center bg-[#FAFAFA] h-[40px] border-y border-[#CCCCCC]">
        <p className="p-2 flex-1 text-center">Item</p>
        <p className="p-2 flex-1 text-center">Description</p>
        <p className="p-2 flex-1 text-center whitespace-nowrap">Unit Price</p>
        <p className="p-2 flex-1 text-center">Quantity</p>
        {toggleTaxInclusive && (
          <p className="p-2 flex-1 text-center whitespace-nowrap">Tax Rate (in %)</p>
        )}
        <p className="p-2 flex-1 text-center">Amount</p>
        <p className="p-2 w-[50px] text-center"></p>
      </div>

      {items?.map((item, index) => (
        <div key={index} className="flex justify-between items-center px-7 space-x-2">
          <div className="flex-1 p-2">
            <InputField
              type="text"
              id={`name-${index}`}
              name="itemName"
              placeholder="Item"
              value={item.itemName}
              onChange={(event) => handleInputChange(index, event)}
              className="w-full border border-[#CCCCCC] px-2 py-1 h-[60px]"
            />
          </div>
          <div className="flex-1 p-2">
            <InputField
              type="text"
              id={`description-${index}`}
              name="description"
              placeholder="Description"
              value={item.description}
              onChange={(event) => handleInputChange(index, event)}
              className="w-full border border-[#CCCCCC] px-2 py-1 h-[60px]"
            />
          </div>
          <div className="flex-1 p-2">
            <InputField
              type="number"
              id={`price-${index}`}
              name="unitPrice"
              placeholder="0"
              value={item.unitPrice}
              onChange={(event) => handleInputChange(index, event)}
              className="w-full border border-[#CCCCCC] px-2 py-1 h-[60px]"
            />
          </div>
          <div className="flex-1 p-2">
            <InputField
              type="number"
              id={`quantity-${index}`}
              name="quantity"
              placeholder="Qty"
              value={item.quantity}
              onChange={(event) => handleInputChange(index, event)}
              className="w-full border border-[#CCCCCC] px-2 py-1 h-[60px]"
            />
          </div>
          {toggleTaxInclusive && (
            <div className="flex-1 p-2">
              <InputField
                type="number"
                id={`taxRate-${index}`}
                name="taxRate"
                placeholder="1"
                value={item.taxRate}
                onChange={(event) => handleInputChange(index, event)}
                className="w-full border border-[#CCCCCC] px-2 py-1 h-[60px]"
              />
            </div>
          )}
          <div className="flex-1 p-2">
            <InputField
              type="text"
              id={`amount-${index}`}
              name="amount"
              placeholder="Amount"
              value={item.amount || ""}
              readOnly
              className="w-full border border-[#CCCCCC] px-2 py-1 h-[60px]"
            />
          </div>
          <div className="w-[50px] p-2 flex items-center justify-center">
            <img
              style={{ width: "24px", cursor: "pointer" }}
              src={DeleteIcon}
              alt="Delete"
              onClick={() => handleDeleteItem(item.id)}
            />
          </div>
        </div>
      ))}
    </div>

        <div className="flex flex-wrap gap-2 px-[25px] justify-between my-[30px]">
          <div>
            <button
              className="bg-[#EEEEEE] px-[25px]  w-full rounded-[5px] rounded-[5px] h-[41px] py-[6px] text-xs md:text-sm font-medium"
              onClick={handleAddItem}
            >
              Add New Line
            </button>
          </div>
          {/* here */}
          <div className="mt-2">
            <div className="flex justify-between w-[320px] gap-6">
              <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                Subtotal
              </p>
              <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                {calculateTotalPrice()}
              </p>
            </div>
            <div className="flex justify-between w-[320px] gap-6">
              <div className="flex items-center">
                <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                  Includes VAT%
                </p>
                <InputField
                  type="number"
                  placeholder="Enter VAT"
                  value={vat}
                  onChange={(e) => setVat(e.target.value)}
                  className="w-[120px] ml-2 border border-[#CCCCCC] px-2 py-3 h-[8px] text-[12px]"
                />
              </div>
              <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                {(calculateTotalPrice() * (vat / 100)).toFixed(2)}
              </p>
            </div>
            <div className="flex justify-between w-[320px] gap-6 pt-1">
              <div className="flex items-center">
                <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                  Discount%
                </p>
                <InputField
                  type="number"
                  placeholder="Enter Discount"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                  className="w-[120px] ml-2 border border-[#CCCCCC] px-2 py-3 h-[8px] text-[12px]"
                />
              </div>
              <p className="text-[#7A7A7A] font-medium text-[12px] py-[5px]">
                {(calculateTotalPrice() * (discount / 100)).toFixed(2)}
              </p>
            </div>
            <hr />
            <div className="flex justify-between gap-6">
              <p className="text-[#222222] font-medium text-[18px]">Total</p>
              <p className="text-[#222222] font-medium text-[18px]">
                {CalculateGrandTotal()}
              </p>
            </div>
            <hr />
          </div>
          {/* here */}
        </div>
        <div className="border-t flex justify-end">
          <div className="flex flex-wrap mx-2 my-[30px] gap-2">
            <Button
              type="button"
              onClick={() => {
                if (editing) {
                  setEditing(false);
                  setItems([
                    {
                      itemName: "",
                      description: "",
                      unitPrice: "",
                      taxRate: "",
                      quantity: "",
                    },
                  ]);
                  setControls({
                    to: "",
                    dueDate: "",
                    currency: "",
                    taxInclusive: true,
                  });
                } else {
                  navigate("/invoice/invoice");
                }
              }}
              className="!bg-[#edeaea] rounded-[5px] h-[41px] px-[16px] py-[6px] text-xs !text-[black] md:text-sm font-medium shadow-1px border !border-[#CCCCCC]"
            >
              {editing ? "Cancel" : "Discard"}
            </Button>
            <Button
              type="button"
              className={`!bg-[white] rounded-[5px] h-[41px] px-[16px] py-[6px] text-xs md:text-sm font-medium shadow-1px border !border-[#F0870F] !text-[#F0870F] hover:bg-[#F0870F] hover:text-white flex items-center`}
            >
              {isLoading ? <Spinner /> : "Save"}
            </Button>

            <Button
              type="submit"
              className="!bg-[#07593D] rounded-[5px] h-[41px] px-[16px] py-[6px] text-xs md:text-sm text-white font-medium shadow-1px border !border-[#e4dbdb] hover:text-white flex items-center"
            >
              {isLoading ? <Spinner /> : editing ? "Edit" : "Submit"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewInvoiceTable;
