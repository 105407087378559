import React from 'react';
import Forward from "../assets/images/svgs/forward.svg";
import Rewind from "../assets/images/svgs/rewind.svg";

const NewPagination = ({ totalItems = 0, itemsPerPage = 10, currentPage = 1, onPageChange, onItemsPerPageChange }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage) || 1;

    const handleNext = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleItemsPerPageChange = (event) => {
        onItemsPerPageChange(Number(event.target.value));
    };

    return (
        <div>
            <div className="flex justify-between">
                <div className="flex space-x-4 items-center">
                    <span style={{color: "#A1A7B0"}}>Items per page</span>
                    <select 
                        style={{color: "#A1A7B0"}} 
                        className="border rounded-sm px-2 py-1" 
                        onChange={handleItemsPerPageChange} 
                        value={itemsPerPage}
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                    </select>
                    <span style={{color: "#A1A7B0"}}>
                        {totalItems > 0 
                            ? `${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(currentPage * itemsPerPage, totalItems)} of ${totalItems} items`
                            : "0 items"
                        }
                    </span>
                </div>
        
                <div className="flex space-x-4 items-center">
                    <img src={Rewind} alt="Rewind" className="cursor-pointer" onClick={() => onPageChange(1)} />
                    <span style={{color: "#A1A7B0", cursor: "pointer"}} onClick={handlePrevious}>{"<"}</span>
                    <span style={{color: "#A1A7B0"}}>Previous</span>
                    <input 
                        type="text" 
                        style={{color: "#A1A7B0"}}
                        value={currentPage} 
                        readOnly 
                        className="text-center border rounded-sm px-2 py-1 w-12" 
                    />
                    <span style={{color: "#A1A7B0"}}>of {totalPages}</span>
                    <span style={{color: "#A1A7B0", cursor: "pointer"}} onClick={handleNext}>{">"}</span>
                    <span style={{color: "#A1A7B0"}}>Next</span>
                    <img src={Forward} alt="Forward" className="cursor-pointer" onClick={() => onPageChange(totalPages)} />
                </div>
            </div>
        </div>
    );
};

export default NewPagination;
