import { PAIconCancel } from "assets/images/svgs";
import React from "react";

const UpdateBillsModal = ({
  isOpen,
  onClose,
  handleYes,
  discountRate,

  handleSetDiscountRate,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-5 rounded-[10px] shadow-md flex flex-col items-center  md:min-w-[459px]  pt-8">
        <header className="flex justify-between border-b-4 border-whisper200 pb-[16px] w-full">
          <p class="text-[#222222] text-base font-bold ">Bid Details</p>
          <div
            onClick={onClose}
            className="flex justify-between items-center gap-x-[10px] cursor-pointer"
          >
            <PAIconCancel className="w-[8px]" />

            <p
              class="text-[#565656] text-sm
font-semibold"
            >
              Close
            </p>
          </div>
        </header>

        {/* nest */}

        <div className="bg-white rounded-[10px]   w-full">
          {/* <FundDetailSide /> */}
          {/* second */}
          <div className=" pb-5 px-2">
            {/* search section */}
            <div>
              <input
                type="text"
                placeholder="Enter discount rate"
                value={discountRate}
                onChange={handleSetDiscountRate}
                className="px-3 w-full flex items-center text-black focus:outline-none h-[47px]  rounded-[5px] border border-[#07593d] mt-2 text-base font-semibold "
              />
            </div>

            {/* button section */}

            <button
              class="h-[47px] px-2.5 py-[15px] bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex mt-8 w-full cursor-pointer"
              onClick={handleYes}
            >
              <div class="text-white text-base font-semibold ">Update Bid</div>
            </button>
          </div>

          {/* search section */}

          {/* stops here */}
        </div>
      </div>
    </div>
  );
};

export default UpdateBillsModal;
