import RoleManagerDocumentContents from "features/roleManager/Documents";

const RoleManagerDocument = () => {
  return (
    <>
      <RoleManagerDocumentContents />
    </>
  )
}

export default RoleManagerDocument;