import OnboardingLayout from 'features/onboarding/category/components/OnboardingLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/Button';

const NextSteps = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    let path = '/buyer-onboard-supplier/create-account';
    navigate(path);
  };
  return (
    <OnboardingLayout>
      <div className="px-[24px] pt-[40px] pb-[20px] md:px-[80px] h-[100%] overflow-auto md:pt-[100px]">
        <div className="mb-[30px]">
          <h1 className="font-semibold text-[30px] text-primaryColor mb-[30px] ">
            Congratulations
          </h1>
          <p className="font-semibold text-[24px] text-textColor ">
            We have received your request to start using
            <p>PayEdge SCF</p>
          </p>
        </div>

        <p className="font-[500] text-[18px] text-borderColor mb-[30px]">
          Next steps
        </p>
        <Button
          className=" mb-[30px] flex items-center justify-center"
          onClick={handleClick}
        >
          Register
        </Button>
        <p className="font-[500] text-[18px] text-borderColor mb-[30px]">
          While you wait:
        </p>
        <div className="">
          <Button pill className="px-[13px] whitespace-nowrap">
            Learn more about Supplier Pay
          </Button>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default NextSteps;
