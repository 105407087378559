import React, { useState, useEffect } from "react";

import AccountModalContainer from "shared/accountModal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { importInvoice } from "appstate/buyer/buyerSlice";
import BulkTableDirectFund from "../bulk/Table";
import SingleTableDirectFund from "../Single/table";

import InvoiceDisbursementModal from "../../components/modals/InvoiceDisbursementModal";
import { PAIconOption } from "assets/images/svgs";

const SingleBulkOption = ({ route }) => {
  const [activeTab, setActiveTab] = useState("single");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllText, setSelectAllText] = useState("Select All");
  const [paymentText, setPaymentText] = useState("Make Payment");

  const [isDisburseOpen, setIsDisburseOpen] = useState(false);
  const { invoiceImport, pfpId, importLoading } = useSelector(
    (state) => state?.buyer
  );
  const dispatch = useDispatch();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const dummyData = [
    {
      bidAmount: 1,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 2,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 3,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 4,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 5,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 6,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
  ];

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      let allIds = [];
      allIds = dummyData.map((row) => row.bidAmount);
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
  };
  useEffect(() => {
    if (selectedRows.length > 0) {
      setPaymentText("Initiate Disbursement");
    }

    if (selectedRows.length < 1) {
      setPaymentText("Make Payment");
    }
  }, [selectedRows.length]);

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.bidAmount)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref. No.
        </span>
      ),
      selector: (row) => row.RequestRefNo,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.InvoiceNumber,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row.Supplier,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.DueDate,
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row.Requestdate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.Currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.InvoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.InvoiceAmount}</p>,
    },

    {
      name: "Discount Amount",
      selector: (row) => row.DiscountAmount,
      sortable: true,
      cell: (row) => <p className="text-[#F08000]">{row.DiscountAmount}</p>,
    },
    {
      name: "Discount Rate",
      selector: (row) => row.DiscountRate,
      sortable: true,
      cell: (row) => <p className="text-success">{row.DiscountRate}</p>,
    },
    {
      name: "Payment Amount",
      selector: (row) => row.PaymentAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.PaymentAmount}</p>,
    },
    {
      cell: (row) => (
        <div>
          <PAIconOption title="Options" onClick={() => {}} />
        </div>
      ),
      width: "5rem",
    },
  ];

  const openIsDisburseModal = () => {
    setIsDisburseOpen(true);
  };

  const handleImport = () => {
    dispatch(importInvoice({ id: pfpId, body: invoiceImport }));
  };

  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.bidAmount);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.bidAmount);
    } else {
      newSelectedRows = [...selectedRows, row.bidAmount];
    }

    setSelectedRows(newSelectedRows);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "single":
        return <SingleTableDirectFund data={dummyData} columns={columns} />;
      case "bulk":
        return <BulkTableDirectFund />;
      default:
        return null;
    }
  };

  return (
    <>
      <InvoiceDisbursementModal
        isOpen={isDisburseOpen}
        onClose={() => setIsDisburseOpen(false)}
      />
      <div className="mt-3 gap-2">
        <AccountModalContainer
          accontModal
          isShown={open}
          onClose={() => {
            setOpen(false);
            setSuccess(false);
          }}
          isLoading={importLoading}
          success={success}
          handleAssociate={handleImport}
          warning="Are you sure you would like to import this/these invoices to the PFP?"
        />
        <div className="bg-white rounded-xl mt-5">
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[70px] rounded-t-[10px] mt-3">
            <div className="py5">
              <p className="text-black text-lg font-bold">Direct Funding</p>
              <span className="text-xs font-thin">
                List of all direct funding invoices
              </span>
            </div>

            <div className="flex gap-3">
              <button
                className="bg-[#2EA923] py-[8px] px-[16px] rounded-[5px] text-white"
                onClick={handleSelectAll}
              >
                {selectAllText}
              </button>

              <button
                className={` ${
                  paymentText === "Make Payment" ? "bg-gray" : "bg-[#F08000]"
                }  py-[8px] px-[16px] rounded-[5px] text-white`}
                onClick={openIsDisburseModal}
                disabled={
                  paymentText === "Initiate Disbursement" ? false : true
                }
              >
                {paymentText}
              </button>
            </div>
          </div>
          <div className="mt-3 px-[15px] text-sm bgwhite">
            <div className="flex gap-3">
              <p
                className={`cursor-pointer font-bold relative ${
                  activeTab === "single" ? "text-[#F08000]" : "text-[#959595]"
                }`}
                onClick={() => handleTabClick("single")}
              >
                Single requests
                {activeTab === "single" && (
                  <span
                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                  ></span>
                )}
              </p>

              <p
                className={`cursor-pointer font-bold relative ${
                  activeTab === "bulk" ? "text-[#F08000]" : "text-[#959595]"
                }`}
                onClick={() => handleTabClick("bulk")}
              >
                Bulk requests
                {activeTab === "bulk" && (
                  <span
                    className="absolute bottom-0 left-1/2 transform -translate-x-1/2
                 bg-[#F08000] h-0.5 w-5"
                  ></span>
                )}
              </p>
            </div>

            <div className="mt-3">{renderTabContent()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleBulkOption;
