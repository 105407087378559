import React from "react";
import SearchInput from "./SearchInput";

const Header = ({ Title, showSearchInput = true }) => {
  return (
    // TITLE
    <div className="flex px-7 justify-between">
      <div className="text-black font-bold text-xl font-sans leading-normal tracking-wider px7 my-5">
        <p>{Title}</p>
        <div className="w-12 h-[4px] rounded-md bg-[#F08000] shadow-md"></div>
      </div>

      {/* Search Input */}
      {showSearchInput && (
        <SearchInput />
      )}
    </div>
  );
};

export default Header;
