import React, { useEffect, useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { allEarlyPayment } from "appstate/invoice/invoiceSlice";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";

const BulkEarlyPayment = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();

  const bulkEarlyPaymentDetails = () => {
    let path = "/invoice/invoice/bulkearlypaymentdetails/approved";
    navigate(path);
  };

  const { earlyPaymentData, isLoading } = useSelector(
    (state) => state?.invoice
  );

  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const search = "";
  useEffect(() => {
    dispatch(
      allEarlyPayment({
        page,
        pageSize,
        bulk: true,
        search,
      })
    );
  }, [dispatch, page, pageSize]);

  const handleCheckboxChange = (row) => {
    const selectedIndex = selectedRows.indexOf(row.id);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, row.id];
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== row.id);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allIds = earlyPaymentData?.data?.map((row) => row.id);
      setSelectedRows(allIds);
    }
    setSelectAll(!selectAll);
  };

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      selector: "",
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.id)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref.No.
        </span>
      ),
      selector: (row) => row.reference,
      sortable: true,
      width: "140px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row.supplierCompanyName,
      sortable: true,
      width: "100px",
    },
    {
      name: "Request Date",
      selector: (row) => row.newRequestedDate,
      sortable: true,
      width: "120px",
    },
    {
      name: "No. of invoices",
      selector: (row) => row.numberOfInvoices,
      sortable: true,
      width: "110px",
    },
    {
      name: "Tier",
      selector: (row) => row.supplierTier,
      sortable: true,
      width: "70px",
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.invoiceAmount}</p>,
      width: "130px",
    },
    {
      name: "Discount Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-yellow-400">{row.discountAmount}</p>,
      width: "120px",
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discountRate,
      sortable: true,
      cell: (row) => <p className="text-success">{row.discountRate}</p>,
      width: "150px",
    },
  ];

  return (
    <>
      {!earlyPaymentData?.data?.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <p className="text-black text-sm font-medium">
              Approved Early Payment Request
            </p>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="pb-[24px]">
            <Table
              columns={columns}
              data={earlyPaymentData?.data}
              progressPending={isLoading}
              progressComponent={<Loading />}
              onRowClicked={(row) => bulkEarlyPaymentDetails(row.id)}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {earlyPaymentData?.data?.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={earlyPaymentData?.meta?.totalElements}
                />
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default BulkEarlyPayment;
