import DashboardLayout from "layout/dashboardlayout";
import Account from "./components/Account";
import CompanyDetails from "./components/CompanyDetails";
import Security from "./components/Security";
import WorkFlowConfig from "./components/WorkFlowConfig";
import Tabs from "shared/Tabs";
import { useSelector } from "react-redux";
import Documents from "features/documents/Components/Documents";
import NotificationSetting from "./components/NotificationSetting";
import ToolsAgreement from "../ToolsAgreement/ToolsAgreement";
import { Navigate } from "react-router-dom";

const Settings = () => {
  const { currentUser } = useSelector((state) => state.auth);

  const corporateFunderLinks = [
    {
      title: "Company Details",
      component: <CompanyDetails currentUser={currentUser} />,
    },
    {
      title: "Security",
      component: <Security currentUser={currentUser} />,
    },
    {
      title: "Work-flow Config",
      component: <WorkFlowConfig />,
    },
  ];

  const openFunderLinks = [
    {
      title: "My Account",
      component: <Account currentUser={currentUser} />,
    },
    {
      title: "Security",
      component: <Security currentUser={currentUser} />,
    },
    {
      title: "Work-flow Config",
      component: <WorkFlowConfig />,
    },
  ];

  const links = [
    {
      title: "My Account",
      component: <Account currentUser={currentUser} />,
    },
    {
      title: "Company Details",
      component: <CompanyDetails currentUser={currentUser} />,
    },
    {
      title: "Documents",
      component: <Documents currentUser={currentUser} />,
    },
    {
      title: "Security",
      component: <Security currentUser={currentUser} />,
    },
    {
      title: "Work-flow Config",
      component: <WorkFlowConfig />,
    },
    {
      title: "Rules & Configuration",
      component: <Navigate to='/settings/rules&config' />,
    },
    {
      title: "Notification",
      component: <NotificationSetting />,
    },

    {
      title: "Tools & Agreement",
      component: <ToolsAgreement />,
    },
  ];

  const currentLinks =
    currentUser?.role === "CORPORATE_FUNDER"
      ? corporateFunderLinks
      : currentUser?.role === "OPEN_FUNDER"
      ? openFunderLinks
      : links;

  return (
    <DashboardLayout
      section='Settings'
      subHeader='Manage your account all in one place'
    >
      <Tabs
        setWrap
        setContain
        flexTabCol
        setButton
        setContnent
        variant='settings'
        tabs={currentLinks}
      />
    </DashboardLayout>
  );
};

export default Settings;
