import { PAIconFacebook, PAIconInstagram, PAIconTwitter } from 'assets/images/svgs';
import React from 'react';

const Footer = () => {
  return (
    <div className='bg-primaryColor border-t-[15px] py-[50px] border-secondaryColor text-center  px-[42px] lg:pb-[30px] lg:text-justify'>
      <div className='lg:grid grid-cols-5 lg:pb-[20px]'>
        <div className='lg:flex items-center col-span-2 '>
          <h1 className='font-extrabold text-brightGray text-5xl mb-[50px]'>PayEdge</h1>
        </div>
        <div className='mb-[50px]'>
            <ul>
                <li className='font-medium text-lg text-white leading-loose'>Home</li>
                <li className='font-medium text-lg text-white leading-loose'>Services</li>
                <li className='font-medium text-lg text-white leading-loose'>About</li>
            </ul>
        </div>
        <div className='mb-[50px]'>
            <ul>
                <li className='font-medium text-lg text-white leading-loose'>Contact Us</li>
                <li className='font-medium text-lg text-white leading-loose'>Support</li>
                <li className='font-medium text-lg text-white leading-loose'>Solutions</li>
            </ul>
        </div>
        <div className='mb-[50px]'>
            <p className='font-medium text-lg text-white'>Careers</p>
        </div>
      </div>
      <div className='border-t border-borderColor pt-[50px] lg:pt-[30px] lg:grid grid-cols-3 items-center'>
          <p className='font-normal text-base text-white mb-[30px] lg:mb-0'>&copy; 2023 PayEdge</p>
          <p className='font-normal text-base text-white'>Terms of Use | Privacy Policy</p>
          <div className='flex justify-center gap-x-[15px] mt-[50px] lg:mt-0'>
              <PAIconTwitter />
              <PAIconFacebook/>
              <PAIconInstagram />
          </div>
      </div>
    </div>
  );
};

export default Footer;
