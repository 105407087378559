import { Backdrop } from "layout/modal/Modal";
import profileImageOne from "assets/images/svgs/inbox-pic1.png";
import { PAIconTrash } from "assets/images/svgs";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

const DropdownCard = ({ hide }) => {
  const { userDetails } = useSelector((state) => state.auth);
  const userRole = userDetails?.data?.role;
  const buyer = useSelector((state) => state?.buyer);
  const supplier = useSelector((state) => state?.supplier);
  const funder = useSelector((state) => state?.funder);
  const { notifications } =
    userRole === "BUYER"
      ? buyer
      : userRole === "SUPPLIER"
      ? supplier
      : userRole === "FUNDER"
      ? funder
      : null;
  const recentNotifications = notifications?.data?.slice(0, 5);
  function timeFormat(timeString) {
    const suffix = timeString.split("").splice(-2).join("");
    const newTime = timeString.split("").splice(11, 5).join("");
    return `${newTime} ${suffix}`;
  }
  return (
    <>
      <Backdrop onClick={hide} />
      <motion.div
        initial={{
          scale: 0,
        }}
        animate={{
          scale: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          scale: 0,
          transition: {
            delay: 0.05,
          },
        }}
        className='absolute top-14  z-50 '                                                                                                                                                                                             
      >
        <div className='w-0 relative left-1 h-0 border-l-[10px] border-l-transparent border-b-[10px] border-b-white border-r-[10px] border-r-transparent'></div>
        <div className='absolute bg-white rounded-[10px] w-[300px] md:w-[400px] right-[-17px] py-[24px] px-[28px]'>
          <div className='flex justify-between items-center'>
            <h1 className='text-textColor text-lg font-bold'>Notifications</h1>
            <Link to='/notifications'>
              <p className='text-linkBlue text-sm cursor-pointer'>View All</p>
            </Link>
          </div>
          {recentNotifications?.map((message, index) => (
            <div key={index} className='flex gap-x-[12px] mt-[25.5px]'>
              <img
                className='h-[45px] rounded-full'
                src={message?.image ? message?.image : profileImageOne}
                alt='#'
              />
              <div>
                <p className='text-textColor text-xs md:text-sm'>
                  {message?.message}
                </p>
                <p className='text-lightGray text-xs mt-[9px]'>
                  {timeFormat(message?.createdDate)}
                </p>
              </div>
              <div className='self-end absolute right-5'>
                <PAIconTrash />
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export default DropdownCard;
