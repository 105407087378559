import { PAIconCSV, PAIconExcel, PAIconPDF, PAIconWord } from "assets/images/svgs";
import React, { useState } from "react";

const Modal = ({ isOpen, onClose }) => {
  const [activeFile, setActiveFile] = useState("Word");
  if (!isOpen) return null;

  const handleFileClick = (file) => {
    setActiveFile(file);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-5 px5 rounded-md max-w-xs md:max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <p className="text-black font-sans text-base font-semibold">
            Choose File Format
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        <div className="border border-gray my-5"></div>

        {/* FILE BUTTONS */}
        <div className="flex justify-between">
          <button
            className={`p-2 flex items-center justify-center gap-2 font-sans text-xs font-normal ${
              activeFile === "Word"
                ? "border-blue-500"
                : "border-gray"
            } mr-2 border rounded-md`}
            onClick={() => handleFileClick("Word")}
          >
            <PAIconWord /> Word file
          </button>
          <button
            className={`p-2 flex items-center justify-center gap-2 font-sans text-xs font-normal ${
              activeFile === "CSV"
                ? "border-blue-500"
                : "border-gray"
            } mr-2 border rounded-md`}
            onClick={() => handleFileClick("CSV")}
          >
            <PAIconCSV />CSV file
          </button>
          <button
            className={`p-2 flex items-center justify-center gap-2 font-sans text-xs font-normal ${
              activeFile === "Excel"
                ? "border-blue-500"
                : "border-gray"
            } mr-2 border rounded-md`}
            onClick={() => handleFileClick("Excel")}
          >
           <PAIconExcel /> Excel file
          </button>
          <button
            className={`p-2 flex items-center justify-center gap-2 font-sans text-xs font-normal ${
              activeFile === "PDF"
                ? "border-blue-500"
                : "border-gray"
            } mr-2 border rounded-md`}
            onClick={() => handleFileClick("PDF")}
          >
           <PAIconPDF /> PDF file
          </button>
        </div>
        {/* DOWNLOAD BUTTON */}
        <div className="mt-4 flex justify-end items-end">
          <button className="px-4 py-2 bg-[#07593D] text-white rounded-md my-3">
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
