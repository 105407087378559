export default function CardLoading() {
  return (
    <li className='outline-none py-1.5 mb-1.5 w-full mx-auto'>
      <div className='animate-pulse flex space-x-4'>
        <span className='flex w-[35%] items-center gap-2'>
          <div className='rounded-full bg-gray300 h-7 w-7'></div>
          <div className='h-4 bg-gray300 rounded w-24 '></div>
        </span>

        <div className='flex items-center w-full  py-1'>
          {/* <div className='h-4 bg-gray300 rounded w-3/4'></div> */}
          <div className='flex w-full justify-between items-center '>
            <div className='h-4 w-[30%] bg-gray300 rounded'></div>
            <div className='h-4 w-[30%] bg-gray300 rounded'></div>
            <div className='h-4 w-[30%] bg-gray300 rounded'></div>
          </div>
        </div>
      </div>
    </li>
  );
}
