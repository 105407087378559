import Modal from "layout/modal/Modal";
import React from "react";
import BuyerDetails from "./BuyerDetails";

function BuyerDetailsModal({ isShown, onClose, data }) {
  return (
    <Modal isShown={isShown} hide={onClose} accountModal>
      <BuyerDetails onClose={onClose} data={data} />
    </Modal>
  );
}

export default BuyerDetailsModal;
