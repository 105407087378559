import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import DisbursementHeader from "../common/DisbursementHeader";
import Overview from "./overview/Overview";
import DirectFunding from "./directFunding";

import EarlyPaymentDisburesement from "./earlyPayment";

import TransactionHistory from "./transactionHistory/index";
function DisbursementBuyerDashboard() {
  const [activeTab, setActiveTab] = useState(
    parseInt(sessionStorage.getItem("activeTabFunder")) || 1
  );
  return (
    <div className="flex flex-col gap-8">
      <>
        <DisbursementHeader activeTab={activeTab} setActiveTab={setActiveTab} />

        <main className="w-full">
          <AnimatePresence mode="wait">
            {activeTab === 1 ? (
              // <Overview />
              <Overview />
            ) : activeTab === 2 ? (
              <DirectFunding />
            ) : activeTab === 3 ? (
              <div>
                <EarlyPaymentDisburesement />
              </div>
            ) : (
              // <AcceptBid acceptedQuery={acceptedQuery} />
              <TransactionHistory />
            )}
          </AnimatePresence>
        </main>
      </>
    </div>
  );
}

export default DisbursementBuyerDashboard;
