import Modal from "layout/modal/Modal"
import TransactionsModalContents from "./TransactionsModalContents"


const TransactionsModal = ({isShown, onClose}) => {
  return (
    <Modal isShown={isShown} hide={onClose} >
        <TransactionsModalContents onClose={onClose}/>
    </Modal>
  )
}

export default TransactionsModal;