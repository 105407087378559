import React, { useState } from "react";
import Funded from "./funded";
import AllInvoices from "./all";
import Pending from "./pendingfunding";

const DashboardContent = () => {
  const [activeTab, setActiveTab] = useState("myinvoices");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "myinvoices":
        return <AllInvoices />;
      case "requests":
        return <Pending />;
      case "reportsAndAnalysis":
        return <Funded />;

      default:
        return null;
    }
  };

  return (
    <div>
      <div className="mt5 mx6 mb-[17px] w-full cursor-pointer rounded-[10px] bg-white py-2">
        <div className=" flex-col items-center justify-between gap-10 p-2 px-4 md:flex md:flex-row">
          <div className="flex-col gap-10 md:flex md:flex-row">
            <div
              className={
                activeTab === "myinvoices"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("myinvoices")}
            >
              All
            </div>
            <div
              className={
                activeTab === "requests"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 my-2 text-base font-normal md:my-0"
              }
              onClick={() => handleTabClick("requests")}
            >
              Pending Funding
              <span className="bg-[#FFEAD3] rounded-[20px] p-1 px-2 ml-1 text-[#07593d]  ">
                {"2"}
              </span>
            </div>
            <div
              className={
                activeTab === "reportsAndAnalysis"
                  ? "rounded-l-sm rounded-r-sm border-[#07593D] font-bold text-[#07593D] md:mb-[-18px] md:border-b-4"
                  : "text-gray500 text-base font-normal"
              }
              onClick={() => handleTabClick("reportsAndAnalysis")}
            >
              Funded
            </div>
          </div>
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default DashboardContent;
