// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-tab-role {
  color: #07593d;
  font-size: 14px;
  background: #fff;
  border-bottom: 2px solid #07593d;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.inactive-tab-role {
  background: #fff;
  font-size: 14px;
  color: #565656;
  border-bottom: 1px solid #7a7a7a;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}

.tab-button-role {
  cursor: pointer;
}

.tab-content-wrapper-role {
  display: flex;
}

.tab-content-role {
  opacity: 0;
  width: 100%;
}

.tab-content-role.active {
  opacity: 1;
  width: 100%;
}
.shadow-mod {
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.border-activation {
  border: 1px solid rgba(122, 122, 122, 0.5);
}
.modal-shadow {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/features/roleManager/styles/role-manager.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,8CAA8C;AAChD;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,8CAA8C;AAChD;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,+CAA+C;AACjD;;AAEA;EACE,0CAA0C;AAC5C;AACA;EACE,8CAA8C;AAChD","sourcesContent":[".active-tab-role {\n  color: #07593d;\n  font-size: 14px;\n  background: #fff;\n  border-bottom: 2px solid #07593d;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);\n}\n.inactive-tab-role {\n  background: #fff;\n  font-size: 14px;\n  color: #565656;\n  border-bottom: 1px solid #7a7a7a;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);\n}\n\n.tab-button-role {\n  cursor: pointer;\n}\n\n.tab-content-wrapper-role {\n  display: flex;\n}\n\n.tab-content-role {\n  opacity: 0;\n  width: 100%;\n}\n\n.tab-content-role.active {\n  opacity: 1;\n  width: 100%;\n}\n.shadow-mod {\n  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.15);\n}\n\n.border-activation {\n  border: 1px solid rgba(122, 122, 122, 0.5);\n}\n.modal-shadow {\n  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
