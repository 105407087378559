import request from "apiInstance";
import toast from "react-hot-toast";

export const createInvoice = async ({ body, type, senderRole, senderId }) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/invoice/${type}?${senderRole}=${senderId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getPlayerInvoices = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.playerType}/invoices?page=0&size=10`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const updateInvoice = async (data) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/invoice/update/${data.type}?invoice=${data.invoiceId}`,
      data: data.body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoiceById = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.player}/${data.invoiceId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoiceByStatus = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.player}/status/${data.invoiceStatus}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoiceByReference = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/${data.player}/inv-ref/${data.invoiceReference}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllInvoices = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoices?page=0&size=10`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllInvoiceById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/id/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllInvoiceByRef = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/inv-ref/${data.invoiceRef}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllInvoiceByStatus = async (data) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/status/${data.status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const deleteInvoiceById = async (data) => {
  try {
    const response = await request({
      method: "delete",
      url: `/api/v1/invoice/${data.invoiceId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getInvoiceDashboard = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/invoice/supplier/dashboard",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllRecentActivitiesInvoices = async ({page, pageSize}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/supplier/recent-activities?page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllSupplierInvoices = async ({page, pageSize, status}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/supplier/invoices?page=${page}&size=${pageSize}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
export const getBuyerInvoicesByStatus = async ({status}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/buyer/status/${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getFundSectionOverview = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/fund-request/overview",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllOpenMarket = async ({ page, pageSize }) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/open-markets?${page}&${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getEarlyPayments = async ({
  page,
  pageSize,
  bulk,
  status,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/early-payments?page=${page}&size=${pageSize}&bulk=${bulk}&status=${status}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getDirectFundings = async ({
  page,
  pageSize,
  bulk,
  status,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/direct-fundings?page=${page}&size=${pageSize}&bulk=${bulk}&status=${status}&search=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSupplierInvoiceById = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/invoice/supplier/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const approveInvoice = async ({ invoiceId, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/invoice/approve/${invoiceId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const uploadInvoice = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/invoice/upload-invoice",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
