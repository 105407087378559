import request from "apiInstance";
import toast from "react-hot-toast";

export const funderDashboard = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/api/v1/funders/dashboard",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};