import { allVirtualCards, postVirtualCard } from "appstate/wallet/walletSlice";
import VirtualCard from "features/wallet/createVirtualAccount/step2/virtualCard";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const StepOne = ({ isOpen, onClose, onSubmit, account }) => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state?.wallet);

  const formik = useFormik({
    initialValues: {
      cardCurrency: "NGN",
      cardType: "Visa",
      paymentMethod: "Credit Card",
    },
    onSubmit: (values) => {
      const body = {
        cardCurrency: values?.cardCurrency,
        cardType: values?.cardType,
        virtualAccountNumber: account?.accountNumber,
        paymentMethod: values?.paymentMethod,
        bvn: account.bvn,
        cardHolderName: account.name
      };
      dispatch(postVirtualCard(body)).then((data) => {
        if (data?.meta?.requestStatus === "fulfilled") {
          onSubmit(
            values?.paymentMethod,
            values?.bvn,
            values?.cardType,
            values?.cardCurrency,
            values?.cardHolderName,
          );
          dispatch(allVirtualCards());
        }
      });
    },
  });

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Create Virtual Debit Card
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        {/* FORM */}
        <div>
          <form className="w-full px-7" onSubmit={formik.handleSubmit}>
            <p className="text-[#7A7A7A] font-sans text-sm font-normal mb6">
              Step 1
            </p>
            <p className="mb-4 text-[#222] font-sans text-sm font-bold">
              Card Information
            </p>
            <VirtualCard name={account?.name} date="09/25" />
            <div className="my-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                Currency
              </label>
              <select
                className="border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none cursor-pointer"
                // value={currency}
                id="cardCurrency"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.cardCurrency}
              >
                <option value="NGN">NGN</option>
                <option value="USD">USD</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                Payment Methods
              </label>
              <select
                className="border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none cursor-pointer"
                // defaultValue="Credit Card"
                id="paymentMethod"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.paymentMethod}
              >
                <option value="Credit Card">Credit Card</option>
                <option value="Debit Card">Debit Card</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                CardType
              </label>
              <select
                className="border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none cursor-pointer"
                id="cardType"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values?.cardType}
              >
                <option value="Visa">Visa</option>
                <option value="MasterCard">MasterCard</option>
              </select>
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-10">
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-gray300 mx-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                >
                  Cancel
                </button>
              </div>
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]">
                <button
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                  type="submit"
                  disabled={isLoading ? isLoading : null}
                >
                  Confirm
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
