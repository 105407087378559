import React, { useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";

const UpdateHistory = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(row.id)}
          onChange={() => handleRowSelect(row.id)}
          className="w-2.5"
        />
      ),
      width: "2rem",
    },
    {
      name: "Updates",
      selector: (row) => row.updates,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Date",
      selector: (row) => row.Date,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Initiator",
      selector: (row) => row.initiator,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Recipient",
      selector: (row) => row.Recipient,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "7rem",
    },
    {
      selector: (row) => row.option,
      cell: (row) => (
        <div>
          <img src="/option-btn.svg" alt="/" />
        </div>
      ),
      width: "3rem",
    },
  ];

  const data = [
    {
      id: 1,
      Date: "oct 17, 2023",
      updates: "Latest update",
      status: "Approved",
      initiator: "Party A",
      Recipient: "Party B",
    },
    {
      id: 2,
      Date: "oct 17, 2023",
      updates: "update 4",
      status: "Approved",
      initiator: "Party A",
      Recipient: "Party B",
    },
    {
      id: 3,
      Date: "oct 17, 2023",
      updates: "update 3",
      status: "Approved",
      initiator: "Party A",
      Recipient: "Party B",
    },
    {
      id: 4,
      Date: "oct 17, 2023",
      updates: "update 2",
      status: "Approved",
      initiator: "Party A",
      Recipient: "Party B",
    },
    {
      id: 5,
      Date: "oct 17, 2023",
      updates: "update 1",
      status: "Approved",
      initiator: "Party A",
      Recipient: "Party B",
    },
  ];

  const handleRowSelect = (rowId) => {
    const isSelected = selectedRows.includes(rowId);
    setSelectedRows((prevSelectedRows) =>
      isSelected
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };

  return (
    <>
      <Card className="rounded-[10px] mt-[24px]" style={{ width: "100%" }}>
        <div className="px-[24px] pb-[24px]">
          <Table
            columns={columns}
            data={data}
            pointer
            tableHeaderPink
            handleRowSelect={handleRowSelect}
            className="mt-5"
          />
        </div>
      </Card>
    </>
  );
};

export default UpdateHistory;
