import request from "apiInstance";
import toast from "react-hot-toast";

export const createTiers = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: `/admin/tiers/create`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllCreatedTiers = async () => {
  try {
    const response = await request({
      method: "get",
      url: `/admin/tiers/all`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getTierById = async (tierId) => {
  try {
    const response = await request({
      method: "get",
      url: `/admin/tiers/id/${tierId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
}

export const getTierByName = async (tierName) => {
  try {
    const response = await request({
      method: "get",
      url: `/admin/tiers/name/${tierName}`,
    })
    return response?.data
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
}

export const editTiers = async ({ tierId, body }) => {
  try {
    const response = await request({
      method: "put",
      url: `/admin/tiers/update/${tierId}`,
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const changeTierStatus = async (tierId) => {
  try {
    const response = await request({
      method: "put",
      url: `/admin/tiers/changeStatus/${tierId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
}

export const assignTiersToPlayer = async ({ userId, tierId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/accountTier/categorize?id=${userId}&tierId=${tierId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
