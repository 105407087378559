import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  PAIconSmallBrewIcon,
  PAIconSmallCoke,
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconSmallShell,
  PAIconSmallTrust,
} from "assets/images/svgs";

function NumberOfBidsCard({
  supplierCompanyName = "N/A",
  invoiceAmount = "N/A",
  status = "N/A",
}) {
  const navigate = useNavigate();

  return (
    <motion.div
      onClick={() => navigate(-1)}
      whileTap={{ scale: 0.98 }}
      className="flex cursor-pointer justify-between bg-white py-2 px-3 "
    >
      <span className="flex gap-6 items-center ">
        {/* <PAIconCocacola /> */}
        {supplierCompanyName === "Access bank" ? (
          <PAIconSmallNestle />
        ) : supplierCompanyName === "XYZ LTD" ? (
          <PAIconSmallTrust />
        ) : supplierCompanyName === "Dangote" ? (
          <PAIconSmallDangote />
        ) : supplierCompanyName === "Union Bank" ? (
          <PAIconSmallNestle />
        ) : supplierCompanyName === "Unity Bank" ? (
          <PAIconSmallShell />
        ) : supplierCompanyName === "Payassyst" ? (
          <PAIconSmallShell />
        ) : supplierCompanyName === "FirstBank" ? (
          <PAIconSmallBrewIcon />
        ) : (
          <PAIconSmallCoke />
        )}

        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{supplierCompanyName}</span>
          <span className=" text-[#959595] text-[10px]">{"Funder"}</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceAmount}</span>
          <span className=" text-[#959595] text-[10px]">Bid Amount</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{status}</span>
          <span className=" text-[#959595] text-[10px]">Bid Discount</span>
        </div>
      </span>
    </motion.div>
  );
}

export default NumberOfBidsCard;
