import Switch from "./Switch";

function SettingsWithToggle({
  title = "Set Title",
  info = "Provide Info here.",
  isToggled,
  setIsToggled,
}) {
  return (
    <div className=' w-full flex justify-between'>
      <div className='flex w-[70%] flex-col gap-2.5'>
        <div className='font-bold '>{title}</div>
        <div className='text-lightGray text-sm'>{info}</div>
      </div>

      <div>
        <Switch
          isToggled={isToggled}
          onToggle={() => setIsToggled(!isToggled)}
        />
      </div>
    </div>
  );
}

export default SettingsWithToggle;
