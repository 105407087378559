import React, { useState, useRef, useEffect } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import "react-redux";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";

import {
  PAIconDeactivate,
  PAIconEditlight,
  PAIconRoundedCheckYellow,
  PAIconSortDown,
  PAIconSortUp,
  PAIconTestTube,
  PAIconTrashMini,
  PAIconViewMini,
} from "assets/images/svgs";
import ViewDocumentModal from "./modals/DeleteRuleModal";
import ViewModal from "./modals/ViewModal";
import { AnimatePresence } from "framer-motion";
import SavedRuleModal from "./modals/SavedRuleModal";
import EditRuleModal from "./modals/EditRuleModal";
import EditRuleFinalise from "./modals/EditRuleFinalise";
import { useDispatch, useSelector } from "react-redux";
import {
  allRules,
  deleteRule,
  searchRule,
  updateRulePriority,
  updateStatus,
} from "appstate/rulesandconfig/rulesandconfigSlice";
import toast from "react-hot-toast";
import LoadingScreen from "shared/LoadingScreen";
import { useParams } from "react-router-dom";
// import CreateNewRule from "./modals/CreateNewRule";

const RulesConfigTable = ({
  ruleSavedModal,
  setRuleSavedModalOpen,
  searchQuery,
  searchBy,
  setSearchQuery,
}) => {
  const dispatch = useDispatch();
  const { isLoading, allRulesData } = useSelector(
    (state) => state?.rulesAndConfig
  );

  const { ruleType } = useParams();

  const [data, setData] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  // const [isModalOpen, setModalOpen] = useState(false);
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [getId, setGetId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editRuleModal, setEditRuleModal] = useState(false);
  const [editRuleModalFinal, setEditRuleModalFinal] = useState(false);
  const [editSavedModal, setEditSavedModal] = useState(false);
  const [status, setStatus] = useState("");
  const [pendingUpdates, setPendingUpdates] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const totalPages = allRulesData?.meta?.totalElements;

  const dropdownRef = useRef();

  const selectedData = data?.find((el) => el?.id === getId);

  const onRowClicked = (row) => {
    setGetId(row?.id);
    setViewModal(true);
  };

  const handleSelect = (id) => {
    setGetId(id);
  };

  const handleEdit = (row) => {
    handleSelect(row?.id);
    setEditRuleModal(true);
  };

  const handleUpdateStatus = () => {
    const selectedId = getId;
    const activeStatus = status;
    dispatch(
      updateStatus({
        id: selectedId,
        status: activeStatus,
        ruleType,
      })
    ).then((data) => {
      // if (data?.payload?.success === true) {
      if (data?.meta?.requestStatus === "fulfilled") {
        dispatch(
          allRules({
            page: page,
            pageSize: pageSize,
            ruleType,
          })
        );
        setData(allRulesData?.data);
        setDeactivateModalOpen(false);
        toast.success(`Rule ${activeStatus} Successfully`);
      }
    });
  };

  // Handle delete
  const handleDelete = () => {
    const ruleId = getId;
    dispatch(deleteRule({ id: ruleId, ruleType })).then((data) => {
      // if (data?.payload?.success === true) {
      if (data?.meta?.requestStatus === "fulfilled") {
        dispatch(allRules({ page: page, pageSize: pageSize, ruleType }));
        setData(allRulesData?.data);
        setDeleteModalOpen(false);
        toast.success("Rule Deleted Successfully");
      }
    });
  };

  //Handle row move

  const moveRow = (index, direction, row) => {
    // ... (existing code)

    const newData = [...data];
    const newIndex = index + direction;

    if (newIndex >= 0 && newIndex < newData?.length) {
      // Swap the rows
      [newData[index], newData[newIndex]] = [newData[newIndex], newData[index]];
      // setData(newData);

      // Check if an entry with the same ruleId already exists
      const existingUpdateIndex = pendingUpdates.findIndex(
        (update) => update.ruleId === row?.id
      );

      if (existingUpdateIndex !== -1) {
        // If exists, update the offset value
        const updatedPendingUpdates = [...pendingUpdates];
        updatedPendingUpdates[existingUpdateIndex].offset += direction;

        // If the offset becomes zero, remove the entry
        if (updatedPendingUpdates[existingUpdateIndex].offset === 0) {
          updatedPendingUpdates.splice(existingUpdateIndex, 1);
        }

        setPendingUpdates(updatedPendingUpdates);
      } else {
        // If not exists, add a new entry
        const updatedPendingUpdates = [...pendingUpdates];
        updatedPendingUpdates.push({
          ruleId: row?.id,
          offset: direction, // Difference in original and final positions
        });
        setPendingUpdates(updatedPendingUpdates);
      }

      // Clear previous timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout for 15 seconds
      const newTimeoutId = setTimeout(() => {
        // Dispatch the update action with the pendingUpdates array
        dispatch(
          updateRulePriority({
            body: pendingUpdates,
            ruleType,
          })
        );
        dispatch(allRules({ page: page, pageSize: pageSize, ruleType }));
        setData(allRulesData?.data);
        // Clear the pendingUpdates array
        setPendingUpdates([]);
        // Reset the timeoutId state
        setTimeoutId(null);
      }, 5000);

      // Save the new timeoutId in the state
      setTimeoutId(newTimeoutId);
    }
  };

  const moveRowSim = (index, direction) => {
    const newData = [...data];
    const newIndex = index + direction;

    if (newIndex >= 0 && newIndex < newData?.length) {
      // Swap the rows
      [newData[index], newData[newIndex]] = [newData[newIndex], newData[index]];
      setData(newData);
    }
  };

  // Handle Dropdown

  const handleDropdown = (row) => {
    if (dropdown === row.id) {
      setDropdown(null);
    } else {
      setDropdown(row.id);
    }
  };

  useEffect(() => {
    dispatch(allRules({ page: page, pageSize: pageSize, ruleType }));
  }, [dispatch, page, pageSize, ruleType]);

  useEffect(() => {
    // Dispatch the search action when the searchQuery changes
    if (searchBy === "priority" && /^[a-zA-Z]+$/.test(searchQuery)) {
      setSearchQuery("");
      return;
    }
    dispatch(searchRule({ searchBy, name: searchQuery, ruleType })).then(
      (data) => {
        setData(data?.payload?.data);
      }
    );
  }, [dispatch, searchQuery, ruleType, searchBy, setSearchQuery]);

  useEffect(() => {
    setData(allRulesData?.data);
  }, [allRulesData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const columns = [
    {
      name: "RULE NAME",
      selector: (row) => <p>{row?.name}</p>,
      sortable: true,
      width: "12rem",
    },
    {
      name: "STATUS",
      selector: (row) => row?.status,
      sortable: true,
      cell: (row) =>
        row.status === "Activated" ? (
          <p className='text-success'>{row?.status}</p>
        ) : (
          <p className='text-red'>{row?.status}</p>
        ),
      width: "12rem",
    },
    {
      name: "PRIORITY",
      selector: (row) => row?.priority,
      sortable: true,
      cell: (row, index) => (
        <div className='flex gap-2 '>
          <span>
            <PAIconSortUp
              className='transform transition duration-[400ms]  hover:scale-[1.04]'
              onClick={() => {
                moveRow(row?.priority, -1, row);
                moveRowSim(index, -1);
              }}
            />
          </span>
          <span>
            <PAIconSortDown
              className='transform transition duration-[400ms] hover:scale-[1.04]'
              onClick={() => {
                moveRow(row?.priority, 1, row);
                moveRowSim(index, 1);
              }}
            />
          </span>
        </div>
      ),
      width: "12rem",
    },
    {
      name: "LAST MODIFIED",
      selector: (row) => {
        const date = new Date(row?.lastModifiedDate);
        return date.toISOString().split("T")[0];
      },
      sortable: true,
      width: "12rem",
    },

    {
      name: "ACTION",
      selector: (row) => row.option,
      width: "11rem",
      cell: (row) => (
        <div>
          <img
            src='/option-btn.svg'
            alt='/'
            onClick={() => handleDropdown(row)}
          />
          {dropdown === row.id && row && (
            <div className={`text-xs relative`} ref={dropdownRef}>
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && "bottom-[15px]"
                }  absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div
                  onClick={() => {
                    handleSelect(row?.id);
                    setDropdown(false);
                    setViewModal(true);
                  }}
                  className='flex items-center gap-x-[9px] mb[2px]  pl-[20px] pt-[5px] pb-2.5 hover:bg-[#d9d9d94a] '
                >
                  <PAIconViewMini />
                  <p className='font-xs font-normal'>View Rule</p>
                </div>
                <div
                  onClick={() => {
                    handleEdit(row);
                    setDropdown(false);
                  }}
                  className='z-[99999] flex items-center gap-x-[10px] mb[2px] pl-[20px] pt-[5px] pb-2.5 hover:bg-[#d9d9d94a] '
                >
                  <PAIconEditlight />
                  <p className='font-xs font-normal'>Edit Rule</p>
                </div>
                <div className='z-[99999] flex items-center gap-x-[10px] mb[2px] pl-[20px] pt-[5px] pb-2.5 hover:bg-[#d9d9d94a] '>
                  <PAIconTestTube />
                  <p className='font-xs font-normal'>Test Rule</p>
                </div>
                <div className='z-[99999] flex items-center gap-x-[10px] mb-[2px] pl-[20px] pt-[5px] pb-2.5 hover:bg-[#d9d9d94a] '>
                  <PAIconDeactivate />
                  <p
                    className='font-xs  font-normal'
                    onClick={() => {
                      handleSelect(row?.id);
                      setDropdown(false);
                      setStatus(
                        row?.status === "Activated"
                          ? "Deactivated"
                          : "Activated"
                      );
                      setDeactivateModalOpen(true);
                    }}
                  >
                    {row?.status === "Activated"
                      ? "Deactivate Rule"
                      : "Activate Rule"}
                  </p>
                </div>
                <div className='border border-silver'></div>
                <div
                  className='flex items-center gap-x-[10px] mt[10px] pl-[10px] pt-[5px] pb-2.5 hover:bg-[#d9d9d94a] '
                  onClick={() => {
                    handleSelect(row?.id);
                    setDeleteModalOpen(true);
                  }}
                >
                  <span></span>
                  <PAIconTrashMini />
                  <p className='text-[#DE1515]'>Remove Rule</p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Card className='mt-[24px]' style={{ width: "100%" }}>
        <div className='pb-2'>
          {isLoading ? (
            <div>
              <LoadingScreen />
            </div>
          ) : (
            // <div className=' w-full h-full flex justify-center items-center '>
            //   <Loading />
            // </div>
            <Table
              columns={columns}
              data={data}
              pointer
              onRowClicked={onRowClicked}
              progressComponent={<Loading />}
              className='mt-5'
            />
          )}
        </div>
        <div className='flex justify-center gap-x-[8px] mt-3'>
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>

      {/* View Modal */}
      <AnimatePresence>
        {viewModal && (
          <ViewModal
            id={getId}
            closeModal={() => setViewModal(false)}
            setDeleteModalOpen={setDeleteModalOpen}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        )}
      </AnimatePresence>

      {/* Edit Rule Modal */}
      <AnimatePresence>
        {editRuleModal && (
          <EditRuleModal
            id={getId}
            closeModal={() => setEditRuleModal(false)}
            onEdit={handleEdit}
            setEditRuleModalFinal={setEditRuleModalFinal}
          />
        )}
      </AnimatePresence>

      {/* Edit Rule Final Modal */}
      <AnimatePresence>
        {editRuleModalFinal && (
          <EditRuleFinalise
            id={getId}
            closeModal={() => setEditRuleModalFinal(false)}
            setEditRuleModal={setEditRuleModal}
            setRuleSavedModal={setEditSavedModal}
          />
        )}
      </AnimatePresence>

      {/* Deactivate Modal */}
      <AnimatePresence>
        {deactivateModalOpen && (
          <ViewDocumentModal
            onAction={handleUpdateStatus}
            onClose={() => setDeactivateModalOpen(false)}
          >
            <span>
              Would you like to{" "}
              {selectedData?.status === "Activated" ? "deactivate" : "activate"}{" "}
              this rule?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Rules Saved Modal */}
      <AnimatePresence>
        {ruleSavedModal && (
          <SavedRuleModal
            onClose={() => {
              setRuleSavedModalOpen(false);
            }}
          >
            <span>
              Congratulations! Your created rules have been successfully saved.
              You can now easily edit or change the priority of the rule as
              needed. Thank you!
            </span>
          </SavedRuleModal>
        )}
      </AnimatePresence>

      {/* Edit Saved Modal */}
      <AnimatePresence>
        {editSavedModal && (
          <SavedRuleModal
            title='Success: Rule Edited and Saved!'
            icon={<PAIconRoundedCheckYellow />}
            onClose={() => {
              setEditSavedModal(false);
            }}
          >
            <span>
              Fantastic! Your edited rule has been successfully saved. The
              changes are now in effect. Should you need further adjustments or
              have any questions, feel free to reach out. Thank you for using
              our service!
            </span>
          </SavedRuleModal>
        )}
      </AnimatePresence>

      {/* Delete Modal */}
      <AnimatePresence>
        {deleteModalOpen && (
          <ViewDocumentModal
            onAction={handleDelete}
            onClose={() => setDeleteModalOpen(false)}
          >
            <span>
              Are you sure you would like to delete{" "}
              <span className='font-bold text-primaryColor'>
                {selectedData?.name}
              </span>{" "}
              rule?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </>
  );
};

export default RulesConfigTable;
