import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Details from "./details";

const SingleDirectFundingDetails = () => {
  return (
    <DashboardLayout
      section="Invoice"
      subHeader="Here is an overview of your invoices"
    >
      <Details />
    </DashboardLayout>
  );
};

export default SingleDirectFundingDetails;
