import React, { useEffect, useState } from "react";
import {
  PAIconDownload,
  PAIconDropdown,
  PAIconFilter,
  PAIconNotification,
  PAIconOutlineSearch,
  PAIconVieweye,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceBidByStatus } from "appstate/openMarket/openMarketSlice";
import Loading from "shared/Loading";
import { remainder } from "appstate/collections/collectionSlice";
import Pagination from "shared/Pagination";

const OpenMarket = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllText, setSelectAllText] = useState("Select All");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [tableType, setTableType] = useState("funder");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const handleSearch = () => {
    dispatch(getInvoiceBidByStatus({ search: searchQuery }));
  };

  useEffect(() => {
    dispatch(getInvoiceBidByStatus({ page, pageSize, status: "Accepted" }));
  }, [dispatch, page, pageSize]);

  const { getInvoiceBidByStatusData, isLoading } = useSelector(
    (state) => state?.openMarket
  );

  // CONFIRM REMAINDER
  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setSelectedInvoice(null);
    const selectedInvoiceIds = selectedRows.map((id) => id);
    dispatch(remainder({ type: "openmarket", body: selectedInvoiceIds })).then(
      (response) => {
        if (response?.payload?.status === 200 && response?.payload?.success) {
          setIsSuccessModalOpen(true);
          setSuccessMessage(response?.payload?.message);
        }
      }
    );
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const receivablesDetails = (id) => {
    let path = `/collections/open-market/${id}`;
    navigate(path);
  };

  // FILTER CHANGE
  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  // FILTER DROPDOWN
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // SEND REMAINDER BUTTON
  const handleSendReminder = () => {
    setIsAcceptModalOpen(true);
  };

  // NOTIFICATION ICON CLICKED
  const handleNotificationClick = (row) => {
    setSelectedInvoice(row);
    const isSelected = selectedRows.includes(row.invoiceId);
    let newSelectedRows = [];

    if (!isSelected) {
      newSelectedRows = [...selectedRows, row.invoiceId];
    }

    setSelectedRows(newSelectedRows);
    setIsAcceptModalOpen(true);
  };

  // SELECT ALL
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      let allIds = [];
      if (tableType === "buyer") {
        allIds = buyerInvoices.map((row) => row.id);
      } else {
        allIds = getInvoiceBidByStatusData?.data?.map((row) => row.invoiceId);
      }
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.invoiceId);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter((id) => id !== row.invoiceId);
    } else {
      newSelectedRows = [...selectedRows, row.invoiceId];
    }

    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    // console.log(selectedRows);
  }, [selectedRows]);

  const buyerInvoices = [
    {
      id: "1",
      invoiceNumber: "INV-2022001",
      buyer: "ABC Company",
      dueDate: "2024-01-31",
      currency: "USD",
      totalAmount: "$9,000.00",
      status: "PARTIAL PAYMENT",
    },
    {
      id: "2",
      invoiceNumber: "INV-2022002",
      buyer: "XYZ Inc.",
      dueDate: "2024-02-04",
      currency: "USD",
      totalAmount: "$9,000.00",
      status: "PARTIAL PAYMENT",
    },
    {
      id: "3",
      invoiceNumber: "INV-2022003",
      buyer: "123 Company",
      dueDate: "2024-01-31",
      currency: "USD",
      totalAmount: "$2,000.00",
      status: "PARTIAL PAYMENT",
    },
  ];

  const columns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      // selector: "",
      cell: (row) => (
        // <input
        //   type="checkbox"
        //   onChange={() => handleCheckboxChange(row)}
        //   checked={selectedRows.includes(row.id)}
        // />
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.invoiceId)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: tableType === "funder" ? "Buyer" : "Buyer",
      selector: (row) =>
        tableType === "funder" ? row.buyerCompanyName : row.buyer,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.invoiceAmount)}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <p
          className={
            tableType === "funder"
              ? "text-yellow-400"
              : row.status === "PARTIAL PAYMENT"
              ? "text-green-400"
              : "text-yellow-400"
          }
        >
          {tableType === "funder" ? "PENDING FUNDING" : row.buyer}
        </p>
      ),
    },

    {
      name: "Action",
      cell: (row) => (
        <div className="flex gap-2">
          {
            <PAIconVieweye
              className="accent-gray300 w-4"
              onClick={() => receivablesDetails(row.invoiceId)}
            />
          }

          {
            <PAIconNotification
              className="accent-gray300 w-4"
              onClick={() => handleNotificationClick(row)}
            />
          }
          {<PAIconDownload className="accent-gray300 w-4" />}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div>
            {/* FILTER */}
            <div
              className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
              onClick={handleDropdownToggle}
            >
              Filter{" "}
              {selectedFilters.length > 0 && (
                <span className="bg-gray rounded-lg p-1 px-2">
                  {selectedFilters.length}
                </span>
              )}{" "}
              Selected <PAIconDropdown />
            </div>
            {isDropdownOpen && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                <label className="block">
                  <input
                    type="checkbox"
                    value="date"
                    checked={selectedFilters.includes("date")}
                    onChange={handleFilterChange}
                  />
                  Date
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="name"
                    checked={selectedFilters.includes("name")}
                    onChange={handleFilterChange}
                  />
                  Name
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="currency"
                    checked={selectedFilters.includes("currency")}
                    onChange={handleFilterChange}
                  />
                  Currency
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="amount"
                    checked={selectedFilters.includes("amount")}
                    onChange={handleFilterChange}
                  />
                  Amount
                </label>
              </div>
            )}
          </div>

          {/* SORT BY */}
          {/* <div>
            <select className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer">
              <option value="">Sort by</option>
              <option value="date">Date</option>
              <option value="name">Name</option>
            </select>
          </div> */}

          {/* EXPORT */}
          <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 flex items-center justify-center gap-1.5">
            Export {<PAIconDownload className="accent-gray300 w-4" />}
          </button>
        </div>

        {/* SEARCH */}
        <div className="flex md:col-span-1 justify-end">
          <div className="flex items-center justify-between gap-5">
            <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
              <div className="items-center flex bg-white">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for request"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                <PAIconFilter className="bg-[#2FA06A]" />
              </div>
            </div>
            <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
              <PAIconFilter /> Search
            </button>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div>
        {!buyerInvoices.length && !getInvoiceBidByStatusData?.data?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Due Invoices from Direct Funding
              </p>
            </div>
            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] mt-[15px]">
            <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <div>
                <p className="text-black text-lg font-bold">
                  Due Invoices from Open Market
                </p>
                <span className="text-xs font-thin">
                  List of all due invoices from open market channel
                </span>
              </div>
              <div className="flex items-center justify-center gap-3">
                <div
                  className="border border-gray300 rounded-md p-2 text-white bg-[#2FA06A] cursor-pointer"
                  onClick={handleSelectAll}
                >
                  <p>{selectAllText}</p>
                </div>
                <button
                  className={`border border-gray300 rounded-md p-2 text-white ${
                    selectedRows.length === 0
                      ? "cursor-not-allowed bg-gray300"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={selectedRows.length === 0}
                  onClick={handleSendReminder}
                >
                  <p>Send Reminder</p>
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <div className="flex px-[24px] gap-5 my-5">
                {/* <p className="text-sm font-normal">Due Invoices from</p> */}
                {/* <p className="text-sm font-bold">
                  {tableType === "buyer" ? "Buyer" : "Funder"}
                </p> */}
                <div className="flex justify-center items-center px[24px] gap-5 my2">
                  <p className="text-sm font-normal">Due Invoices from</p>
                  <select
                    className="border border-gray300 rounded-md p-2 text-gray800 cursor-pointer"
                    onChange={(e) => setTableType(e.target.value)}
                  >
                    <option value="funder">Funder</option>
                    <option value="buyer">Buyer</option>
                  </select>
                </div>
              </div>
              {tableType === "buyer" ? (
                <Table
                  columns={columns}
                  data={buyerInvoices}
                  onRowClicked={(row) => receivablesDetails(row.id)}
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              ) : (
                <Table
                  columns={columns}
                  data={getInvoiceBidByStatusData?.data}
                  onRowClicked={(row) => receivablesDetails(row.invoiceId)}
                  progressPending={isLoading}
                  progressComponent={<Loading />}
                  pointer
                  tableHeader
                  className="mt-5"
                />
              )}
              <div className="flex justify-center gap-x-[8px] mt-[20px]">
                {getInvoiceBidByStatusData?.data?.length > 0 && (
                  <Pagination
                    page={page}
                    startPage={startPage}
                    setEndPage={setEndPage}
                    endPage={endPage}
                    setStartPage={setStartPage}
                    setPage={setPage}
                    totalPages={getInvoiceBidByStatusData?.meta?.totalElements}
                  />
                )}
              </div>
            </div>
          </Card>
        )}
      </div>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            {selectedRows.length > 1 ? (
              <span>to all selected due invoices?</span>
            ) : (
              <>
                to due invoice with ref.no{" "}
                <span className="text-secondaryColor font-bold">
                  {/* invoice INV-{selectedInvoiceId} */}
                  {selectedInvoice?.invoiceReference}
                </span>
                ?
              </>
            )}
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default OpenMarket;
