import {
  PAIconArrowLeftGreen,
  PAIconCocacola,
  PAIconDownload,
  PAIconSmallNestle,
} from "assets/images/svgs";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Details = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  // Factoring in progress

  const dummyData = [
    {
      index: 1,
      ivnNuM: "INV-2002",
      poNum: "PO-2002",
      itmNum: "ITM3201",
      cpn: "Dangote Cement ",
      qty: "20",
      prQt: "$30",
      amount: "$600",
    },
    {
      index: 2,
      ivnNuM: "INV-2002",
      poNum: "PO-2002",
      itmNum: "ITM3201",
      cpn: "Dangote Cement ",
      qty: "20",
      prQt: "$30",
      amount: "$600",
    },
  ];

  return (
    <>
      <Link
        onClick={goBack}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </Link>

      <div className="grid lg:grid-cols-[60%,40%] gap-5">
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] pt-7">
          <div className="px-6 ">
            <div className="flex flex-col gap-3">
              <div className=" flex justify-between items-center flex-row">
                <h1 className="text-[18px] font-bold">Invoice | INV-2002</h1>
                <div>
                  <div class="h-[37px] p-2.5 rounded-[5px] border border-[#07593d]/20 justify-center items-center gap-2.5 inline-flex">
                    <div class="text-[#07593d] text-sm font-medium ">
                      Posted
                    </div>
                  </div>
                </div>
              </div>

              <main className=" w-full  ">
                <div className="flex flex-col gap-3 w-1/2">
                  <div className="flex gap-x-[5px]">
                    <span>Reference No:</span>
                  </div>
                  {/* starts flex */}
                  {/* <div className=" flex flex-row w-full justify-between bg-red"> */}{" "}
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Created Date:</span>
                      <span className="text-[#979797]  text-xs">
                        23-Dec-2024
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs">Payment Due Date:</span>
                      <span className="text-[#979797]  text-xs">
                        23-Dec-2024
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <div class="text-right text-[#222222] text-base font-bold ">
                      N23,000,000
                    </div>
                    <div class="text-[#979797] text-xs font-normal text-right">
                      Request amount
                    </div>
                  </div>
                </div>
              </main>
            </div>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className="font-bold text-sm">Payment</span>
                <span className="text-[#979797]  text-xs">60 Days</span>
              </div>
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Currency</span>
                <span className="text-[#979797]  text-xs">NGN</span>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] ">
                <h1 className="text-[18px] font-bold">
                  Supplier/buyer details
                </h1>
              </header>
              <div className="flex  justify-between mb-5">
                <div className="flex gap-2">
                  <PAIconCocacola />
                  <div className="flex flex-col">
                    <span className=" text-black text-xs font-normal">
                      Coca Cola
                    </span>
                    <span className="text-[#979797]  text-xs">Supplier</span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <PAIconSmallNestle />
                  <div className="flex flex-col">
                    <span className=" text-black text-xs font-normal">
                      Nestle Limited
                    </span>
                    <span className="text-[#979797]  text-xs">Buyer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Line Items</h1>
              </header>
              <div className=" w-full overflow-x-auto">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-subtleGray w-">
                    <tr className=" bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item
                        <p> Number</p>
                      </td>

                      <td className=" p-2.5 text-xs font-bold">
                        Product/Service
                        <p>Description</p>
                      </td>
                      <td className=" p-2.5 text-xs font-bold">Quantity</td>
                      <td className=" p-2.5 text-xs font-bold">
                        Unit
                        <p> Price</p>
                      </td>
                      <td className=" p-2.5 text-xs font-bold">
                        Total<p>Amount</p>
                      </td>
                    </tr>
                  </thead>

                  {dummyData.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.ivnNuM}
                        </td>

                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.cpn}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.qty}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.prQt}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.amount}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Attachments</h1>
              </header>
              <div className="w-full overflow-x-auto">
                <table className="w-full overflow-x-auto">
                  <thead className="  bg-subtleGray w-">
                    <tr className="px-4  bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                        Document Name
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">
                        File Type
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">
                        Upload Date / Time
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className=" py-[3px] px-1.5">
                      <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                        DOC-29384.pdf
                      </td>
                      <td className=" text-xs text-[#11192A] px-4 py-2.5">
                        PDF
                      </td>
                      <td className=" text-xs text-[#11192A] px-4 py-2.5">
                        2021-09-14 13:25
                      </td>
                      <td className="cursor-pointer text-xs text-[#11192A] px-4 py-2.5">
                        <PAIconDownload />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-[10px] gap-8 ">
          {/* <FundDetailSide /> */}
          <div class="w-full justify-start items-center gap-4 inline-flex ">
            <div class="border-b  border-[#c2c2c2]/30 justify-start items-center gap-2.5 flex w-full px-6  py-3">
              <div class="text-[#07593d] text-base font-bold ">
                Bid details for this invoice
              </div>
            </div>
          </div>

          {/* second */}
          <div className=" px-6">
            <div class=" justify-between  flex flex-row   mt-9 ">
              <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                <div class="flex-col justify-start items-start gap-0.5 flex">
                  <div class="text-[#949494] text-sm font-medium ">
                    Request amount
                  </div>
                </div>
                <div class="flex-col justify-start items-start gap-0.5 flex">
                  <div class="text-black text-base font-medium ">
                    N17,734,000
                  </div>
                </div>
              </div>
              <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex">
                <div class="flex-col justify-start items-start gap-0.5 flex">
                  <div class="text-[#949494] text-sm font-medium ">
                    Request date
                  </div>
                </div>
                <div class="flex-col justify-start items-start gap-0.5 flex">
                  <div class="text-black text-base font-medium ">
                    20/08/2024
                  </div>
                </div>
              </div>
            </div>

            <div class="py-2.5 flex-col justify-start items-start gap-2 inline-flex mt-7  ">
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div class="text-[#949494] text-sm font-medium ">
                  Request date
                </div>
              </div>
              <div class="flex-col justify-start items-start gap-0.5 flex">
                <div class="text-black text-base font-medium ">20/08/2024</div>
              </div>
            </div>
          </div>

          {/* stops here */}
        </div>
      </div>
    </>
  );
};

export default Details;
