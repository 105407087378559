// import { allTransactions } from "appstate/wallet/walletSlice";
import TableHeaderTrans from "features/transactions/components/TableHeaderTrans";
import DashboardLayout from "layout/dashboardlayout";
import {  useSelector } from "react-redux";
import Table from "layout/table/Table";
import {  useState } from "react";
import Loading from "shared/Loading";
import { PAIconInventoryArrowLeft } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import TransactionReceipt from "./TransactionReceipt";
// import Pagination from "shared/Pagination";

const ViewAllTransactions = () => {
  // const [data, setData] = useState([]);
  // const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state?.wallet);
  // const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const navigate = useNavigate();
  const handleNavigate = () => navigate(-1);

  // const [page, setPage] = useState(0);
  // const [startPage, setStartPage] = useState(0);
  // const [endPage, setEndPage] = useState(5);
  // const pageSize = 10;

  // useEffect(() => {
  //   setData(transactions?.data);
  // }, [transactions]);

  const [transactions] = useState([
    {
      transactionId: 1,
      transactionParty: "Party 1",
      transactionType: "Type 1",
      paymentDate: "2022-01-01",
      transactionSign: "Cr",
      currency: "USD",
      amount: "9,000,000",
      status: "SUCCESSFUL",
    },
    {
      transactionId: 2,
      transactionParty: "Party 2",
      transactionType: "Type 2",
      paymentDate: "2022-01-02",
      transactionSign: "Dr",
      currency: "EUR",
      amount: "4,000,000",
      status: "FAILED",
    },
    {
      transactionId: 3,
      transactionParty: "Party 3",
      transactionType: "Type 3",
      paymentDate: "2022-01-03",
      transactionSign: "Cr",
      currency: "GBP",
      amount: "1,000,000",
      status: "PENDING",
    },
  ]);

  // useEffect(() => {
  //   dispatch(allTransactions());
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(allTransactions({ page, pageSize }));
  // }, [dispatch, page, pageSize]);

  const handleRowClick = (row) => {
    // setSelectedTransactionId(row.transactionId);
    setSelectedTransaction(row);
  };

  const options = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Open",
    },
    {
      id: 3,
      name: "Fulfilled",
    },
    {
      id: 4,
      name: "Cancelled",
    },
    {
      id: 5,
      name: "Closed",
    },
  ];

  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row.transactionId,
      sortable: true,
      width: "8.3rem",
    },
    {
      name: "Transaction Party",
      selector: (row) => row.transactionParty,
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Transaction Type",
      selector: (row) => row.transactionType,
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Transaction Date",
      selector: (row) => row.paymentDate,
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Sign",
      selector: (row) => row.transactionSign,
      sortable: true,
      cell: (row) =>
        row.transactionSign === "Cr" ? (
          <p className="text-success">{row.transactionSign}</p>
        ) : (
          <p className="text-red">{row.transactionSign}</p>
        ),
      width: "7rem",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.transactionSign === "Cr" ? (
          <p className="text-success">{row.amount}</p>
        ) : (
          <p className="text-red">{row.amount}</p>
        ),
      width: "7.9rem",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "FAILED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "PENDING" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
      width: "9rem",
    },
  ];

  return (
    <DashboardLayout
      section="Transactions"
      subHeader="Here is an overview of Transactions"
    >
      <div
        onClick={handleNavigate}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
      >
        <PAIconInventoryArrowLeft />
        <p className="mt-[2px] text-sm font-normal text-lightGray">Go Back</p>
      </div>
      <div className="bg-white h-auto rounded-[5px]">
        <TableHeaderTrans
          options={options}
          transactions={true}
          className="mt-5"
        />
        <Table
          columns={columns}
          data={transactions}
          onRowClicked={handleRowClick}
          pointer
          className="mt-5 px-4 min-h-[60vh]"
          // pagination
          progressPending={isLoading}
          progressComponent={<Loading />}
        />
        {/* <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {isLoading && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
            />
          )}
        </div> */}
        {selectedTransaction && (
          <TransactionReceipt
            transferDetails={selectedTransaction}
            onClose={() => setSelectedTransaction(null)}
            paymentAmount="N9,000,000"
            currency="NGN"
            senderDetails={{
              name: "Adewale Favour Adediwura",
              bank: "Access bank plc",
              accountNumber: "23857369282",
            }}
            beneficiaryDetails={{
              name: "Praise Akobundu Ignatius",
              bank: "PayEdge",
              accountNumber: "45678900987",
            }}
            transactionType="Collections"
            transactionReference="87463938573992834746893"
            description="Thanks Money received"
            paymentDate="23-Dec-2024"
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default ViewAllTransactions;
