import React from "react";
import Header from "../Header";

const LandingpageLayout = ({ children }) => {
  return (
    <div className="h-screen flex flex-col">
      <Header />
      <div className="flex flex-1 h-full w-full flex-col overflow-y-auto">
        {children}
      </div>
    </div>
  );
};

export default LandingpageLayout;
