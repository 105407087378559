import React from "react";
// import down from "../../../../assets/images/svgs/carretDown.svg";
import down from "../../../../assets/images/svgs/arrow-down.svg"
import { PAIconAlertIcon, PAIconWallet } from "assets/images/svgs";
import walletGreen from "../../../../assets/images/svgs/walletGreen.svg";

const TransferFundsSetupModal = ({
  isOpen,
  onClose,
  confirm,
  selectedAccount,
  proceed,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      onClick={onClose}
      className="bg-[rgba(0,0,0,0.2)] w-[100%] h-[100vh] flex justify-center items-center absolute left-0 top-0"
    >
      <div>
        <div className="flex flex-col justify-between items-center bg-white rounded-[8px]  w-[600px]">
          <div className="h-[60px] px-[5%] rounded-t-[8px] bg-[#FAFAFA] flex items-center w-[100%] justify-start">
            <h1 className="font-bold text-[20px]">Transfer Funds</h1>
          </div>

          <div className="p-[5%]">
            <div className="flex items-center justify-between w-[100%]">
              <div>
                <h2 className="text-[18px] font-semibold">Accounts</h2>
                <p>Select a virtual account to fund this invoice</p>
              </div>

              <div>
                <button
                  onClick={proceed}
                  className="bg-[#F08000] w-[170px] h-[45px] rounded-[5px] flex items-center justify-center text-[white]"
                >
                  <PAIconWallet className="mr-[3%]" />
                  Select Account
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between border-y-[1px] border-[#D7D5D5] my-[5%] py-[4%]">
              {selectedAccount ? (
                <div
                  className={`border-[1px] p-[3%] w-[50%] rounded-[5px] ml-[2%]`}
                  style={{ backgroundColor: "#F1F9F6", color: "#076344" }}
                >
                  <div className="flex items-center justify-start">
                    <img src={walletGreen} alt="" />

                    <p className="ml-[2%] font-semibold text-[14px]">
                      {selectedAccount.accountName}
                    </p>
                  </div>

                  <div className="mt-[2%]">
                    <p className="font-bold text-[16px] italic">
                      {selectedAccount.accountNumber}
                    </p>
                  </div>

                  <div className="flex items-center justify-between mt-[2%]">
                    <p className="text-[14px] font-light">
                      {selectedAccount.bankName}
                    </p>
                    <p className="text-[13px]">{selectedAccount.accountType}</p>
                  </div>
                </div>
              ) : null}

              <div
                style={
                  selectedAccount === null
                    ? { width: "100%", justifyContent: "space-between" }
                    : { width: "40%", justifyContent: "flex-end" }
                }
                className="flex items-center"
              >
                <h2 className="text-[16px] font-semibold mr-[2%]">
                  Discount Rate
                </h2>
                <div className="flex border-[1px] border-[#8DB3A6] rounded-[5px] px-[3%] py-[2%]">
                  <h3>2%</h3>
                  <img src={down} alt="" />
                </div>
              </div>
            </div>

            <div className="flex items-center w-[100%]">
              <PAIconAlertIcon className="w-[6%] mr-[1%]" />
              <p className="text-left text-[14px]">
                The discount rate offered to suppliers upon request is only a
                benchmark rate. Please choose a different rate if desired.!
              </p>
            </div>

            <div className="mt-[6%] flex items-center justify-between w-[100%]">
              <button
                onClick={onClose}
                className="border-[#EE0816] text-[#EE0816] border-[1px] w-[120px] rounded-[4px] h-[45px]"
              >
                Cancel
              </button>
              {selectedAccount === null ? (
                <button
                  onClick={confirm}
                  disabled
                  className="bg-[#FBDFBF] text-[#fff] w-[120px] rounded-[4px] h-[45px]"
                >
                  Confirm
                </button>
              ) : (
                <button
                  onClick={confirm}
                  className="bg-[#F08000] text-[#fff] w-[120px] rounded-[4px] h-[45px]"
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferFundsSetupModal;
