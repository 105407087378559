import { PAIconCancel, PAIconEmailIcon } from "assets/images/svgs";
import React from "react";

const SuccessModal = ({ onClose, message }) => {
  return (
    <div>
      <div className="px-[27px] py-[38px]">
        <header className="flex justify-between border-b-2 border-whisper200 pb-[16px]">
          <p>Invitation sent</p>
          <div
            onClick={onClose}
            className="flex justify-between items-center gap-x-[10px] cursor-pointer"
          >
            <PAIconCancel className="w-[8px]" />
            <p className="text-sm font-medium text-gray800">Close</p>
          </div>
        </header>
        <div className="flex justify-center mt-[32px] mb-[10px]">
          <PAIconEmailIcon />
        </div>
        <p className="text-center md:px-[56px]">
         {message}
        </p>
      </div>
    </div>
  );
};

export default SuccessModal;
