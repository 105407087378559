// import { PAIconVieweye } from "assets/images/svgs";
import TransactionModal from "features/transactions/transactionDashboard/TransactionModal";
import Table from "layout/table/Table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";

// Dummy data
const dummyTransactions = [
  {
    transactionId: "001",
    buyerFullName: "John Doe",
    paymentDate: "2024-06-25",
    currency: "USD",
    amount: "$500",
    status: "Successfull",
  },
  {
    transactionId: "002",
    buyerFullName: "Jane Smith",
    paymentDate: "2024-06-24",
    currency: "EUR",
    amount: "€300",
    status: "Pending",
  },
  {
    transactionId: "003",
    buyerFullName: "Alice Johnson",
    paymentDate: "2024-06-23",
    currency: "GBP",
    amount: "£200",
    status: "Failed",
  },
  {
    transactionId: "004",
    buyerFullName: "Bob Brown",
    paymentDate: "2024-06-22",
    currency: "NGN",
    amount: "₦150,000",
    status: "Pending",
  },
];

const DashboardTable = () => {
  const navigate = useNavigate();
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  function handleViewMore(transactionId) {
    // setSelectedTransactionId(transactionId);
    let path = `portfolioinvoice/${transactionId}`;
    navigate(path);
  }

  const columns = [
    {
      name: "Invoice Reference",
      selector: (row) => row.transactionId,
      sortable: true,
      width: "100px",
      style: {
        fontSize: "12px",
        fontWeight: 400,
      },
    },
    {
      name: "Buyer",
      selector: (row) => row.buyerFullName,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Successfull" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Failed" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "Pending" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    // {
    //   name: "Details",
    //   selector: (row) => row.view,
    //   cell: (row) => (
    //     <div className="flex" onClick={() => handleViewMore(row.transactionId)}>
    //       <PAIconVieweye height="15px" width="30px" />
    //       <p className="text-[#626679] cursor-pointer">View</p>
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <Card className="rounded-[10px] mt[24px]">
        <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
          <div>
            <p className="text-black text-sm font-medium">Funded Invoices</p>
            <p className="text-[10px]">All funded invoices</p>
          </div>
          <p
            onClick={() => navigate("/funds&portfolio/fundedinvoice")}
            className="text-black text-sm font-medium underline cursor-pointer"
          >
            View All
          </p>
        </div>
        <div className="px-[24px] pb-[24px]">
          <Table
            columns={columns}
            data={dummyTransactions}
            onRowClicked={(row) => handleViewMore(row.transactionId)}
            pointer
            tableHeader
            className="mt-5"
          />
        </div>
      </Card>
      {selectedTransactionId && (
        <TransactionModal
          transactionId={selectedTransactionId}
          onClose={() => setSelectedTransactionId(null)}
        />
      )}
    </>
  );
};

export default DashboardTable;
