import { PAIconCocacola, PAIconInvShop } from "assets/images/svgs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function ContestedInvoiceCard({
  id,
  invoiceNumber = "N/A",
  buyerName = "N/A",
  noOfBids = "N/A",
  invoiceAmount = "N/A",
  dueDate = "N/A",
}) {
  const navigate = useNavigate();

  return (
    <motion.div
      onClick={() => navigate(id)}
      whileTap={{ scale: 0.98 }}
      style={{ boxShadow: "0px 2px 4px 0px rgba(232, 226, 216, 0.36)" }}
      className="flex cursor-pointer justify-between bg-white py-5 px-6 rounded-[10px]"
    >
      <span className="flex gap-6  items-center">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">R3456X89Y</span>
          <span className=" text-[#959595] text-[10px]">Reference Number</span>
        </div>
      </span>
      <span className="flex gap-6  items-center">
        <PAIconInvShop />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">INV-001</span>
          <span className=" text-[#959595] text-[10px]">{invoiceNumber}</span>
        </div>
      </span>
      <span className="flex gap-6 items-center ">
        <PAIconCocacola />
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{buyerName}</span>
          <span className=" text-[#959595] text-[10px]">Buyer</span>
        </div>
      </span>
      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-secondarColor text-sm">
            Bid Active
          </span>
          <span className=" text-[#959595] text-[10px]">Status of Invoice</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{invoiceAmount}</span>
          <span className=" text-[#959595] text-[10px]">Request Amount</span>
        </div>
      </span>

      <span className="flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">4.1%</span>
          <span className=" text-[#959595] text-[10px]">Lock Rate</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 ">
        <div className="flex flex-col justify-center">
          <span className="font-[500] text-sm">{dueDate}</span>
          <span className=" text-[#959595] text-[10px]">Payment Date</span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 justify-center ">
        <div className="flex flex-col items-center justify-center">
          <span className="font-[500] text-sm">{218}</span>
          <span className=" text-[#959595] text-center text-[10px]">
            Number of bidders
          </span>
        </div>
      </span>

      <span className="hidden extra-md:flex gap-6 justify-center ">
        <div className="flex flex-col items-center justify-center">
          <span className="font-[500] text-sm">01d:13h:55m:32s</span>
          <span className=" text-[#959595] text-center text-[10px]">
            Count down to end of bid
          </span>
        </div>
      </span>
      {/* 
      <motion.button
        whileTap={{ scale: 0.9 }}
        className='py-2.5 px-4 rounded-[10px] bg-white border text-sm border-primaryColor'
      >
        View
      </motion.button> */}
    </motion.div>
  );
}

export default ContestedInvoiceCard;
