import { PAIconDownload, PAIconInventoryArrowLeft } from "assets/images/svgs";
import { motion } from "framer-motion";
import DashboardLayout from "layout/dashboardlayout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SingleRequestModal from "./Modal";
import { useSelector } from "react-redux";

const InvoiceDetails = () => {
  const { approvedInvoicesData, approvedInvoiceId } = useSelector(
    (state) => state?.supplier
  );
  const invoiceData = approvedInvoicesData?.data?.find(
    (invoice) => invoice.id === approvedInvoiceId
  );

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  function handleNavigate() {
    let path = -1;
    navigate(path);
  }
  return (
    <DashboardLayout>
      <SingleRequestModal
        data={invoiceData}
        isShown={open}
        onClose={() => setOpen(false)}
      />
      <motion.section
        initial={{
          x: 20,
        }}
        animate={{
          x: 0,
        }}
        exit={{
          x: -20,
        }}
        transition={{
          delay: 0.6,
        }}
        className="flex flex-col gap-8  pb-24  bg-white rounded-[10px]"
      >
        <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
          <div>
            <div
              onClick={handleNavigate}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconInventoryArrowLeft />
              <p className="mt-[2px] text-sm font-normal text-lightGray">
                Go Back
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <h1 className="text-black font-medium underline">Invoices</h1>
            <div
              onClick={() => setOpen(true)}
              className="rounded-[5px] cursor-pointer bg-secondaryColor shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
            >
              Request Early Payment
            </div>
          </div>
        </header>
        <div className="px-6">
          <div className=" flex  flex-col gap-3 ">
            <header>
              <h1 className=" text-[18px] font-bold">
                Invoice | INV-{invoiceData?.invoiceNumber}
              </h1>
            </header>
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex gap-x-[50px]">
                  <span>Reference No:</span>
                  <span>{invoiceData?.invoiceReference}</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.createdDate}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs">Payment Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceData?.dueDate}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <motion.button
                  // onClick={() => setLockModalOpen(true)}
                  whileTap={{ scale: 0.9 }}
                  className="flex bg-[#cdecdb] text-sm justify-center gap-2 text-[#07A04A] rounded-[5px] items-center px-[35px] py-1.5 "
                >
                  {invoiceData?.status}
                  {/* <PAIconLockYellow /> */}
                </motion.button>
              </div>
            </main>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className=" font-bold text-sm">Payment</span>
              <span className="text-[#979797]  text-xs">60 Days</span>
            </div>
            <div className="flex flex-col">
              <span className=" font-bold text-sm">Currency</span>
              <span className="text-[#979797]  text-xs">
                {invoiceData?.currency}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-[18px] font-bold">Supplier/buyer details</h1>
            </header>
            <div className="flex  justify-between">
              <div className="flex gap-2">
                {/* <PAIconCocacola /> */}
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">
                    {invoiceData?.supplierCompanyName}
                  </span>
                  <span className="text-[#979797]  text-xs">Supplier</span>
                </div>
              </div>
              <div className="flex gap-2">
                {/* <PAIconModelOne /> */}
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">
                    {invoiceData?.buyerCompanyName}
                  </span>
                  <span className="text-[#979797]  text-xs">Buyer</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Line Items</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Tax rate
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Item name</td>
                    <td className=" p-2.5 text-xs font-bold">
                      Product/Service Description
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                    <td className=" p-2.5 text-xs font-bold">
                      Line Item Extended Amount
                    </td>
                  </tr>
                </thead>
                {invoiceData?.items?.map((item, index) => (
                  <tbody key={index}>
                    <tr className=" py-[3px] px-1.5">
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.taxRate}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.itemName}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.description}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.quantity}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.unitPrice}
                      </td>
                      <td className=" text-xs text-[#11192A] p-2.5">
                        {item?.amount}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Attachments</h1>
            </header>
            <div className="w-full overflow-x-auto">
              <table className="w-full overflow-x-auto">
                <thead className="  bg-subtleGray w-">
                  <tr className="px-4  bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                      Document Name
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">
                      File Type
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">
                      Upload Date / Time
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                      DOC-29384.pdf
                    </td>
                    <td className=" text-xs text-[#11192A] px-4 py-2.5">PDF</td>
                    <td className=" text-xs text-[#11192A] px-4 py-2.5">
                      2021-09-14 13:25
                    </td>
                    <td className="cursor-pointer text-xs text-[#11192A] px-4 py-2.5">
                      <PAIconDownload />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </motion.section>
    </DashboardLayout>
  );
};

export default InvoiceDetails;
