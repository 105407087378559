import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { PAIconOption } from "assets/images/svgs";
import TableHeaderAccounts from "./components/TableHeaderDocuments";

const RoleManagerDocumentContents = () => {

  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      width: '10rem'
    },
    {
        name: 'Company',
        selector: row => row.company,
        sortable: true,
        width: '9.5rem'
    },
    {
        name: 'Role',
        selector: row => row.role,
        sortable: true,
        width: '9.5rem'
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
        width: '12rem'
    },
    {
        name: 'Phone',
        selector: row => row.phone,
        sortable: true,
        width: '12rem'
    },
    {
        name: 'Amount Tier',
        selector: row => row.amount,
        sortable: true,
        width: '9rem'
    },
    {
      selector: row => row.view,
      cell: () => (
        <div className="flex">
          <PAIconOption height="15px" width="30px" />
        </div>
      )  
    }
  ];
  
  const data = [
    {
      name: 'Eleanor Pena',
      company: 'Coca cola',
      role: 'Buyer',
      email: 'eleanor@gmail.com',
      phone: '(704) 555-0127',
      amount: 'Tier 1',
    },
    {
      name: 'Eleanor Pena',
      company: 'INV-2090',
      role: 'Agba Dev',
      email: 'eleanor@gmail.com',
      phone: '(704) 555-0127',
      amount: 'Tier 1',
    },
    {
      name: 'Eleanor Pena',
      company: 'INV-2090',
      role: 'Agba Dev',
      email: 'eleanor@gmail.com',
      phone: '(704) 555-0127',
      amount: 'Tier 2',
    },
    {
      name: 'Eleanor Pena',
      company: 'INV-2090',
      role: 'Agba Dev',
      email: 'eleanor@gmail.com',
      phone: '(704) 555-0127',
      amount: 'Tier 3',
    },
    
    {
      name: 'Eleanor Pena',
      company: 'INV-2090',
      role: 'Agba Dev',
      email: 'eleanor@gmail.com',
      phone: '(704) 555-0127',
      amount: 'Tier 2',
    },
    {
      name: 'Eleanor Pena',
      company: 'INV-2090',
      role: 'Agba Dev',
      email: 'eleanor@gmail.com',
      phone: '(704) 555-0127',
      amount: 'Tier 3',
    }
  ]

  const options = [
    {
      id: 1,
      name: 'All',
    },
    {
      id: 2,
      name: 'Active',
    },
    {
      id: 3,
      name: 'Unavailable',
    },
    {
      id: 4,
      name: 'Deactivated',
    },
  ];

  return (
    <DashboardLayout ums section="Documents" subHeader="Manage all your document">
      <div className="bg-white p-[24px] rounded-[5px]">
        <TableHeaderAccounts
          options={options}
          support={true}
          buyer={true}
        />
        <Table
          columns={columns}
          data={data}
          selectableRows
          pointer
          tableHeader
          className="mt-5"
          pagination
        />
      </div>
    </DashboardLayout>
  )
}

export default RoleManagerDocumentContents;
;