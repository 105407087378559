import React, { useState } from "react";
import Default from "./Default/Default";
import Saved from "./Saved/Saved";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("default");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "default":
        return <Default />;
      case "saved":
        return <Saved />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="border-b border-gray mb-10">
        <div className="flex">
          <div
            className={`cursor-pointer mr4 ${
              activeTab === "default" ? "text-[#07593D]" : "text-gray500"
            }`}
            onClick={() => handleTabClick("default")}
          >
            Default
            {activeTab === "default" && (
              <div className="border-b-2 border-[#07593D] mt-2"></div>
            )}
          </div>
          <div
            className={`cursor-pointer px-20 ${
              activeTab === "saved" ? "text-[#07593D]" : "text-gray500"
            }`}
            onClick={() => handleTabClick("saved")}
          >
            Saved
            {activeTab === "saved" && (
              <div className="border-b-2 border-[#07593D] mt-2"></div>
            )}
          </div>
          <div
            className={`cursor-pointer px4 ${
              activeTab === "draft" ? "text-[#07593D]" : "text-gray500"
            }`}
            onClick={() => handleTabClick("draft")}
          >
            Draft
            {activeTab === "draft" && (
              <div className="border-b-2 border-[#07593D] mt-2"></div>
            )}
          </div>
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default Tabs;
