import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createInventory,
  deleteProduct,
  filterByStatus,
  getAllInventories,
  searchInventoriesByName,
  updateProduct,
} from 'appstate/api/inventory';

const initialState = {
  isLoading: false,
  inventories: [],
  filterData: [],
};

export const addInventory = createAsyncThunk('addNewInentory', createInventory);
export const allInventories = createAsyncThunk(
  'allInventories',
  getAllInventories
);
export const searchInventories = createAsyncThunk(
  'searchInventories',
  searchInventoriesByName
);

export const productUpdate = createAsyncThunk('productUpdate', updateProduct);

export const filterProductByStatus = createAsyncThunk(
  'filterProductByStatus',
  filterByStatus
);

export const removeProduct = createAsyncThunk('removeProduct', deleteProduct)

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addInventory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addInventory.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addInventory.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allInventories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allInventories.fulfilled, (state, action) => {
      state.inventories = action.payload;
      state.isLoading = false;
    });
    builder.addCase(allInventories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(searchInventories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchInventories.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(searchInventories.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(productUpdate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(productUpdate.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(productUpdate.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(filterProductByStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(filterProductByStatus.fulfilled, (state, action) => {
      state.filterData = action.payload
      state.isLoading = false;
    });
    builder.addCase(filterProductByStatus.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeProduct.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(removeProduct.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default inventorySlice.reducer;
