import { PAIconArrowLeftGreen, PAIconDownload } from "assets/images/svgs";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "shared/LoadingScreen";
// import { allDisbursedInvoice } from "appstate/paymentExtension/paymentExtensionSlice";

const Invoice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { invoiceIds } = useSelector((state) => state?.paymentExtension);
  // const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);
  // const id = invoiceIds;
  const { disbursedInvoiceData, associatedFunderId, isLoading } = useSelector(
    (state) => state?.paymentExtension
  );

  const invoiceIdData = disbursedInvoiceData?.data?.find(
    (invoice) => invoice.funderId === associatedFunderId
  );
  // const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);

  // const id = invoiceIds;
  // const id = associatedFunderId
  const id = invoiceIdData?.invoice?.id

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
    // dispatch(allDisbursedInvoice());
  }, [dispatch, id]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <div className="flex flex-col gap-8  pb-24  bg-white rounded-[10px]">
      {/* first header */}
      <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
        <div>
          <button
            onClick={() => navigate(-1)}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </button>
        </div>
        <div className="flex justify-between items-center">
          <h1 className="text-black font-medium underline">Invoices</h1>
          <div
            //   onClick={() => setOpen(true)}
            className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]"
          >
            Partial Payment
          </div>
        </div>
      </header>

      <div className="px-6">
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <h1 className="text-[18px] font-bold">
              Invoice | INV-{invoiceIdData?.invoice?.invoiceNumber || "0000"}
            </h1>
          </div>
          <main className=" w-full flex justify-between">
            <div className="flex flex-col gap-3 w-1/2">
              <div className="flex gap-x-[5px]">
                <span>Reference No:</span>
                <span>
                  {invoiceIdData?.invoice?.invoiceReference || "REF-0000"}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex gap-x-[50px]">
                  <span className=" text-xs w-[97px]">Created Date:</span>
                  <span className="text-[#979797]  text-xs">
                    {invoiceIdData?.invoice?.createdDate?.slice(0, 10) || "0000"}
                  </span>
                </div>
                <div className="flex gap-x-[50px]">
                  <span className=" text-xs">Payment Due Date:</span>
                  <span className="text-[#979797]  text-xs">
                    {invoiceIdData?.invoice?.dueDate || "0000"}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between">
              <div className="px-[32px] py-[8px] text-sm flex flex-col justify-end items-end px[35px] rounded-md bg-[#CDECDB]">
                <p className="text-[#07A04A]">Not Paid</p>
              </div>
            </div>
          </main>
        </div>

        <div className="flex justify-between my-5">
          <div className="flex flex-col">
            <span className="font-bold text-sm">Payment</span>
            <span className="text-[#979797]  text-xs">
              {calculatePayment(
                invoiceIdData?.invoice?.dueDate,
                invoiceIdData?.invoice?.createdDate
              )}{" "}
            </span>
          </div>
          <div className="flex flex-col">
            <span className=" font-bold text-sm">Currency</span>
            <span className="text-[#979797]  text-xs">
              {invoiceIdData?.invoice?.currency || ""}
            </span>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
            <h1 className="text-[18px] font-bold">Supplier/buyer details</h1>
          </header>
          <div className="flex  justify-between mb-5">
            <div className="flex gap-2">
              {/* <PAIconCocacola /> */}
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Supplier</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceIdData?.invoice?.supplierCompanyName ||
                    "supplier name here"}
                </span>
              </div>
            </div>
            <div className="flex gap-2">
              {/* <PAIconModelOne /> */}
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Buyer</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceIdData?.invoice?.buyerCompanyName || "buyer name here"}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
            <h1 className="text-sm font-bold">Line Items</h1>
          </header>
          <div className=" w-full overflow-x-auto">
            <table className=" w-full overflow-x-auto">
              <thead className="bg-subtleGray w-">
                <tr className=" bg-unusualGray p-2.5 ">
                  <td className="text-[#11192A] p-2.5 text-xs font-bold">
                    Item Name
                  </td>
                  <td className=" p-2.5 text-xs font-bold">PO Number</td>
                  <td className=" p-2.5 text-xs font-bold">
                    Product/Service Number
                  </td>
                  <td className=" p-2.5 text-xs font-bold">
                    Product/Service Description
                  </td>
                  <td className=" p-2.5 text-xs font-bold">Quantity</td>
                  <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                  <td className=" p-2.5 text-xs font-bold">
                    Line Item Extended Amount
                  </td>
                </tr>
              </thead>
              <tbody>
                {invoiceIdData?.invoice?.items?.map((item, index) => (
                  <tr key={index} className="py-[3px] px-1.5">
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.itemName}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.poNumber}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.productNumber}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.description}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {item.quantity}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {formatAmount(item.unitPrice)}
                    </td>
                    <td className="text-xs text-[#11192A] p-2.5">
                      {formatAmount(item.amount)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex flex-col gap-4 mt-5">
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
            <h1 className="text-sm font-bold">Attachments</h1>
          </header>
          <div className="w-full overflow-x-auto">
            <table className="w-full overflow-x-auto">
              <thead className="  bg-subtleGray w-">
                <tr className="px-4  bg-unusualGray p-2.5 ">
                  <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                    Document Name
                  </td>
                  <td className=" px-4 py-2.5 text-xs font-bold">File Type</td>
                  <td className=" px-4 py-2.5 text-xs font-bold">
                    Upload Date / Time
                  </td>
                  <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                </tr>
              </thead>
              <tbody>
                <tr className=" py-[3px] px-1.5">
                  <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                    DOC-29384.pdf
                  </td>
                  <td className=" text-xs text-[#11192A] px-4 py-2.5">PDF</td>
                  <td className=" text-xs text-[#11192A] px-4 py-2.5">
                    2021-09-14 13:25
                  </td>
                  <td className="cursor-pointer text-xs text-[#11192A] px-4 py-2.5">
                    <PAIconDownload />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
