import {
  PAIconArrowLeftGreen,
  PAIconDownload,
  PAIconHappy,
  PAIconModelOne,
} from "assets/images/svgs";
import RejectModal from "features/ToolsAgreement/components/Agreement/AgreementDetails/Modal/RejectModal";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import toast from "react-hot-toast";
import FeedbackModal from "../../FeedbackModal";
import { useDispatch, useSelector } from "react-redux";
import { approveOrDeclineDirectFunding } from "appstate/funder/funderSlice";

const Details = () => {
  const [open, setOpen] = useState(false);
  const [reject, setReject] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [openFeedback, setOpenFeedbackModal] = useState(false);
  const [response, setResponse] = useState([
    { id: 1, name: "Unreliable History" },
    { id: 2, name: "Financial Instability" },
    { id: 3, name: "Repeated Extensions Requests" },
    { id: 4, name: "Violation of Terms" },
  ]);
  const [selectedResponse, setSelectedResponse] = useState([]);

  const {
    newDirectFundingData,
    directFundingInvoiceId,
    directFundingApproveLoading,
  } = useSelector((state) => state?.funder);

  const invoiceDetails = newDirectFundingData?.data?.find(
    (invoiceId) => invoiceId?.id === directFundingInvoiceId
  );

  const dispatch = useDispatch();

  const handleResponseClick = (res) => {
    const itemIndex = response.findIndex((i) => i.id === res.id);
    if (itemIndex !== -1) {
      const updatedItems = [...response];
      updatedItems.splice(itemIndex, 1);
      setResponse(updatedItems);

      setSelectedResponse([...selectedResponse, res]);
    } else {
      const selectedItemIndex = selectedResponse.findIndex(
        (i) => i.id === res.id
      );
      if (selectedItemIndex !== -1) {
        const updatedSelectedItems = [...selectedResponse];
        updatedSelectedItems.splice(selectedItemIndex, 1);
        setSelectedResponse(updatedSelectedItems);

        setResponse([...response, res]);
      }
    }
  };

  const comments = selectedResponse.map((selected) => selected.name).join(", ");

  function handleCloseModal() {
    setOpen(false);
    setShowRejectModal(false);
    setOpenFeedbackModal(false);
  }

  const handleAcceptModalOpen = () => {
    setOpen(true);
    setReject(false);
  };

  const handleOpenFeedbackModal = () => {
    setOpenFeedbackModal(true);
    setOpen(false);
  };

  const handleOpenRejectModal = () => {
    setOpen(true);
    setReject(true);
  };

  const handleConfirm = () => {
    dispatch(
      approveOrDeclineDirectFunding({
        id: directFundingInvoiceId,
        comment: "",
        status: "APPROVED",
      })
    ).then((data) => {
      if (data?.payload?.success) {
        setOpen(false);
        toast.custom(
          <div className="bg-white shadow-lg w-[529px] p-[27px] rounded-[10px] mt-[50px]">
            <header>
              <h1 className="text-textColor text-base font-bold">
                Direct Funding request accepted
              </h1>
            </header>
            <div className="flex justify-center my-[24px]">
              <PAIconHappy />
            </div>
            <p className="text-textColor text-base ">
              Congratulations! The Direct Funding request has been approved.
              Your understanding and cooperation are greatly appreciated. Thank
              you.
            </p>
          </div>
        );
      }
    });
  };

  const handleRejectDirectFunding = () => {
    dispatch(
      approveOrDeclineDirectFunding({
        id: directFundingInvoiceId,
        comment: comments,
        status: "DECLINED",
      })
    ).then((data) => {
      if (data?.payload?.success) {
        setShowRejectModal(true);
        setOpen(false);
        setOpenFeedbackModal(false);
      }
    });
  };

  return (
    <div className="grid lg:grid-cols-[60%,40%] gap-5">
      <div className="flex flex-col gap-8  pb-24  bg-white rounded-[10px]">
        <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
          <div>
            <Link
              to="/invoice/invoice"
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconArrowLeftGreen />
              <span className="flex items-center text-primaryColor">
                Go Back
              </span>
            </Link>
          </div>
          <div className="flex justify-between items-center">
            <h1 className="text-black font-medium underline">Invoices</h1>
          </div>
        </header>

        <div className="px-6">
          <div className="flex flex-col gap-3">
            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex gap-x-[5px]">
                  <span>Reference No:</span>
                  <span>{invoiceDetails?.reference}</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceDetails?.invoiceDate.split("T")[0]}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs">Payment Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceDetails?.invoiceDueDate}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="text-sm flex flex-col justify-end items-end px-[35px] italic">
                  <p className="italic font-bold">New Invoice Amount</p>
                  <p className="text-[#07A04A] font-bold">
                    NGN {invoiceDetails?.newRequestedAmount}
                  </p>
                  <p className="text-secondaryColor font-semibold line-through">
                    NGN {invoiceDetails?.invoiceAmount}
                  </p>
                  <p className="text-secondaryColor">
                    +{invoiceDetails?.discountRate}%
                  </p>
                </div>
              </div>
            </main>
          </div>

          <div className="flex justify-between my-5">
            <div className="flex flex-col">
              <span className="font-bold text-sm">Payment</span>
              <span className="text-[#979797]  text-xs">60 Days</span>
            </div>
            <div className="flex flex-col">
              <span className=" font-bold text-sm">Currency</span>
              <span className="text-[#979797]  text-xs">
                {invoiceDetails?.currency}
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-[18px] font-bold">Supplier/buyer details</h1>
            </header>
            <div className="flex  justify-between mb-5">
              <div className="flex gap-2">
                {/* <PAIconCocacola /> */}
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">Supplier</span>
                  <span className="text-[#979797]  text-xs">Coca Cola</span>
                </div>
              </div>
              <div className="flex gap-2">
                {/* <PAIconModelOne /> */}
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">Buyer</span>
                  <span className="text-[#979797]  text-xs">ABC Limited</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Line Items</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item Number
                    </td>
                    <td className=" p-2.5 text-xs font-bold">PO Number</td>
                    <td className=" p-2.5 text-xs font-bold">
                      Product/Service Number
                    </td>
                    <td className=" p-2.5 text-xs font-bold">
                      Product/Service Description
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                    <td className=" p-2.5 text-xs font-bold">
                      Line Item Extended Amount
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#11192A] p-2.5">INV-2002</td>
                    <td className=" text-xs text-[#11192A] p-2.5">PO-2002</td>
                    <td className=" text-xs text-[#11192A] p-2.5">ITM3201</td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      Dangote Cement
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">20</td>
                    <td className=" text-xs text-[#11192A] p-2.5">$30</td>
                    <td className=" text-xs text-[#11192A] p-2.5">$600</td>
                  </tr>
                  <tr className=" py-1.5 px-1.5">
                    <td className=" text-xs text-[#11192A] p-2.5">INV-2002</td>
                    <td className=" text-xs text-[#11192A] p-2.5">PO-2002</td>
                    <td className=" text-xs text-[#11192A] p-2.5">ITM3201</td>
                    <td className=" text-xs text-[#11192A] p-2.5">
                      Dangote Cement
                    </td>
                    <td className=" text-xs text-[#11192A] p-2.5">20</td>
                    <td className=" text-xs text-[#11192A] p-2.5">$30</td>
                    <td className=" text-xs text-[#11192A] p-2.5">$600</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-5">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Attachments</h1>
            </header>
            <div className="w-full overflow-x-auto">
              <table className="w-full overflow-x-auto">
                <thead className="  bg-subtleGray w-">
                  <tr className="px-4  bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                      Document Name
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">
                      File Type
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">
                      Upload Date / Time
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                      DOC-29384.pdf
                    </td>
                    <td className=" text-xs text-[#11192A] px-4 py-2.5">PDF</td>
                    <td className=" text-xs text-[#11192A] px-4 py-2.5">
                      2021-09-14 13:25
                    </td>
                    <td className="cursor-pointer text-xs text-[#11192A] px-4 py-2.5">
                      <PAIconDownload />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-[10px] gap-8 px-6 pt-7 pb-24">
        <div>
          <h2 className="font-semibold text-lg leading-[30px] mb-4">
            Early Payment Details
          </h2>

          <div className="flex justify-between my-5">
            <div className="flex gap-2">
              <PAIconModelOne />
              <div className="flex flex-col">
                <span className="text-sm">Supplier</span>
                <span className="text-[#979797]  text-xs"> John Doe</span>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-sm">{invoiceDetails?.discountRate}</span>
              <span className="text-[#979797]  text-xs">Discount rate</span>
            </div>
          </div>

          <div className="flex justify-between mt-5 border-b-[5px] border-b-[#F6F5FA] pb-10">
            <div className="flex gap-2">
              <div className="flex flex-col">
                <span className="text-sm">
                  N {invoiceDetails?.invoiceAmount}
                </span>
                <span className="text-[#979797]  text-xs">Discount Amount</span>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-sm">
                {invoiceDetails?.newRequestedDate}(90days)
              </span>
              <span className="text-[#979797]  text-xs">
                Early Payment Request Date
              </span>
            </div>
          </div>

          <div className="border-b-[5px] border-b-[#F6F5FA] pb-5">
            <h2 className="font-medium text-base my-3">Note from Supplier:</h2>
            <p className="text-[#959595] text-sm">
              Seeking Early payment due to unexpected challenges. Facing
              temporary financial constraints and need a bit more time to ensure
              a smooth transaction. Appreciate your understanding. Thank you.
              Best regards, ABC Limited
            </p>
          </div>

          <div className="pb-3">
            <h2 className="font-medium text-base leading-[30px] my-2">
              Your decision:
            </h2>
            <p className="text-[#959595] text-sm">
              Early Payment Request: Please review and take action. Accept if
              approved, or Reject if not suitable. Thank you
            </p>
          </div>

          <div className="flex gap-5 mt-5">
            <button
              className="rounded-md bg-[#07593D] text-white px-12 py-2"
              onClick={handleAcceptModalOpen}
            >
              Accept
            </button>
            <button
              className="rounded-md bg-white border border-[#F08000] text-[#F08000] px-12 py-2"
              onClick={handleOpenRejectModal}
            >
              Reject
            </button>
          </div>
          <AcceptModal
            message={
              reject
                ? "Would you like to proceed with Rejecting this Direct Funding request?"
                : "Would you like to proceed with Approving this Direct Funding request?"
            }
            isOpen={open}
            onClose={handleCloseModal}
            onConfirm={!reject ? handleConfirm : handleOpenFeedbackModal}
            loading={directFundingApproveLoading}
          />
          <FeedbackModal
            setOpenFeedbackModal={setOpenFeedbackModal}
            setShowRejectModal={setShowRejectModal}
            isOpen={openFeedback}
            onClose={handleCloseModal}
            loading={directFundingApproveLoading}
            response={response}
            selectedResponse={selectedResponse}
            handleSubmit={handleRejectDirectFunding}
            handleSelect={handleResponseClick}
          />
          <RejectModal isOpen={showRejectModal} onClose={handleCloseModal} />
        </div>
      </div>
    </div>
  );
};

export default Details;
