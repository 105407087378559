import React from 'react';
import WalletAdd from '../../../../../../assets/images/svgs/wallet-add.svg';

const ReceivingFundModal = ({ handleShowVirtualAccountSelection, handleCloseVirtualAccount }) => {
    return (
        <>
        <div className="w-[900px] fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
            <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
                <div>
                    <h2>Receiving Of Fund</h2>
                </div>
                <br />
                <div>
                    <h2 style={{ fontSize: "18px", lineHeight: "21px" }}>Accounts</h2>
                </div>
                <div className='flex justify-between'>
                    <p style={{ fontSize: "12px", fontWeight: "600px", lineHeight: "14px", color: "#858285" }}>
                        This virtual account would be used to receive this early payment request
                    </p>
                    <button 
                        onClick={handleShowVirtualAccountSelection} 
                        style={{ width: '200px', backgroundColor: "#F08000", color: "white", borderRadius: "8px" }} 
                        className="flex items-center justify-center"
                    >
                        <img src={WalletAdd} alt="Wallet Add Icon" className="w-5 h-5 mr-2" />
                        Select Account
                    </button>
                </div>
                <br />
                <hr className="my-4 border-t border-[#F3F2F2]" />
                <br />
                <div className='flex justify-between'>
                    <button 
                        onClick={handleCloseVirtualAccount} 
                        style={{ 
                            borderColor: "#DE1515", 
                            color: "#DE1515", 
                            borderWidth: "1px", 
                            borderStyle: "solid", 
                            borderRadius: "8px",
                            padding: "8px 16px"
                        }}
                    >
                        Cancel
                    </button>
                    <button className="py-2 px-8" style={{ backgroundColor: "#F08000", color: "white" }}>Confirm</button>
                </div>
            </div>
        </div>
        </>
    );
};

export default ReceivingFundModal;
