import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  PAIconCardOutline,
  PAIconPaymnet,
} from "assets/images/svgs";
import VirtualAccountModal from "features/wallet/createVirtualAccount/modal";
import VirtualBvnModal from "features/wallet/createVirtualAccount/bvn";
import CongratsModal from "features/wallet/linkExternalAccount/congrats/congratsModal";

const ExternalSource = ({ isOpen, onClose, onSubmit, onSelectAccount }) => {
  const [isCreateVirtualAccountOpen, setCreateVirtualAccountOpen] =
  useState(false);
  const [showVirtualAccountBvn, setShowVirtualAccountBvn] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const { allVirtualAccountsData } = useSelector((state) => state?.wallet);

  const toggleCreateVirtualAccount = () => {
    setCreateVirtualAccountOpen(!isCreateVirtualAccountOpen);
  };

  const handleVirtualAccountSubmit = () => {
    setCreateVirtualAccountOpen(false);
    setShowVirtualAccountBvn(true);
  };

  const handleVirtualBvnSubmit = () => {
    setShowVirtualAccountBvn(false);
    setShowCongratsModal(true);
  };

  const handleClose = () => {
    setCreateVirtualAccountOpen(false);
    setShowVirtualAccountBvn(false);
    setShowCongratsModal(false);

  
  };

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Transfer Funds
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        <div>
          <div className="w-full px-7">
            <p className="text-zinc-600 text-sm font-extrabold flex justify-center">
              Select which account to fund
            </p>
            <h1 className="text-zinc-600 text-base font-extrabold mt-5">
              Accounts
            </h1>

            <div className="md:flex md:gap-5 flex-wrap max-h-56 overflow-auto">
              {allVirtualAccountsData?.data?.map((account) => (
                <div
                  key={account?.id}
                  className="bg-[#07593D] p-3 mt-3 cursor-pointer text-white text-xs md:w-[calc(50%-10px)] md:mb-0 mb-3 rounded relative"
                  onClick={() => {
                    onSelectAccount(account);
                    handleSubmit();
                  }}
                >
                  <div className="flex justify-between">
                    <div
                      className="text-white text-xs flex items-center"
                    >
                      <PAIconPaymnet className="mr-2" />{" "}
                      <p>{account?.accountName}</p>
                    </div>
                  </div>
                  <p
                    className="mt-2"
                  >
                    {account?.accountNumber}
                  </p>
                  <div
                    className="flex items-center justify-between mt-2 text-[10px]"
                  >
                    <p>{account?.bankName}</p>
                    <p>{account?.accountType}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-end mt-7 mb-3" onClick={toggleCreateVirtualAccount}>
              <button className="bg-[#F08000] flex items-center rounded p-2 text-xs text-white">
                <PAIconCardOutline className="mr-2 w-4" />
                <p>Add new account</p>
              </button>
            </div>
          </div>
        </div>
        <VirtualAccountModal
        isOpen={isCreateVirtualAccountOpen}
        onClose={() => handleClose()}
        handleSubmit={handleVirtualAccountSubmit}
      />
       <VirtualBvnModal
        isOpen={showVirtualAccountBvn}
        onClose={() => handleClose()}
        onSubmit={handleVirtualBvnSubmit}
      />
       <CongratsModal
        isOpen={showCongratsModal}
        onClose={() => handleClose(false)}
        message="Your Virtual Account has been created"
      />
      </div>
    </div>
  );
};

export default ExternalSource;
