import {
  PAIconAddGreen,
  PAIconArrowLeftGreen,
  PAIconSmallClose,
} from "assets/images/svgs";
import RightSideModal from "shared/RightSideModal";
// import ConditionTable from "../table/ConditionTable";
// import Switch from "features/settings/components/NotificationSetting/components/Switch";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allRules,
  editRule,
} from "appstate/rulesandconfig/rulesandconfigSlice";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
// import toast from "react-hot-toast";

function EditRuleFinalise({
  closeModal,
  setEditRuleModal,
  setRuleSavedModal,
  id,
}) {
  const { earlyPaymentRuleByIdData } = useSelector(
    (state) => state?.rulesAndConfig
  );
  const { ruleType } = useParams();
  const data = earlyPaymentRuleByIdData?.data;

  const [selectedActions, setSelectedActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState("none");
  const [inputValues, setInputValues] = useState("");
  const [uniqueId, setUniqueId] = useState(0);
  const actions = [
    "Payment method",
    "Discount Expiry Date",
    "Minimum invoice amount",
  ];
  const [isDropdownVisible, setDropdownVisible] = useState(true);
  const dispatch = useDispatch();
  const formData = useSelector((state) => state?.earlyPaymentFormData);

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;

    if (!selectedAction.includes(selectedValue)) {
      setSelectedAction(selectedValue);
      setSelectedActions([...selectedActions, selectedValue]);
      setInputValues({
        ...inputValues,
        [selectedValue]: "",
      });
      setUniqueId(uniqueId + 1);
      setDropdownVisible(false);
    }
  };

  const handleInputChange = (selectedValue, value) => {
    setInputValues({
      ...inputValues,
      [selectedValue]: value,
    });
  };

  const handleAddAction = () => {
    setDropdownVisible(true);
  };

  const handleSubmit = () => {
    // Handle submit logic here
    const body = { ...formData };

    dispatch(editRule({ id, body, ruleType }))
      .then((data) => {
        if (data?.payload?.success) {
          dispatch(allRules({ page: 0, pageSize: 20, ruleType }));
          setTimeout(() => {
            setRuleSavedModal(true);
          }, 600);

          closeModal();
        }
      })
      .catch((error) => {
        // Error callback
        toast.error(error?.message);
      });
  };

  return (
    <>
      <RightSideModal closeModal={closeModal}>
        <div className='flex flex-col gap-3 py-4'>
          <header className='flex px-[4%] pb-6 border-b-[3.25px] border-[#F6F5FA]   items-center justify-between'>
            <span className='font-bold text-xl'>
              Edit Rule: {` `}
              <span className='font-normal text-primaryColor text-xl'>
                {data?.name}
              </span>
            </span>

            <span
              onClick={closeModal}
              className=' cursor-pointer flex gap-2 items-center'
            >
              <PAIconSmallClose />

              <span>Close</span>
            </span>
          </header>
          <main className='py-3 flex flex-col gap-3'>
            <section className='px-[4%] flex flex-col gap-2'>
              <header className=' flex flex-col gap-6 w-full'>
                <div
                  onClick={() => {
                    setEditRuleModal(true);
                    closeModal();
                  }}
                  className='flex gap-1.5 cursor-pointer text-sm '
                >
                  <PAIconArrowLeftGreen />
                  <span className='flex items-center text-primaryColor'>
                    Go Back
                  </span>
                </div>
              </header>
            </section>

            <section className='py-5 px-[4%] flex flex-col gap-6'>
              <div className='flex items-start justify-between'>
                <div className='flex flex-col gap-3'>
                  <span className='text-xl'>Set additional settings</span>
                  <span className='text-[#7A7A7A] text-xs italic font-light  '>
                    Choose the settings you wish to incorporate into your rule
                    from the provided list.{" "}
                    <span className=' font-normal not-italic text-primaryColor text-xs'>
                      (Optional)
                    </span>
                  </span>
                </div>
              </div>

              <div className='w-full flex flex-col gap-4'>
                {selectedActions.map((selectedAction, index) => (
                  <div
                    className='flex justify-between items-center'
                    key={index}
                    disabled
                  >
                    <span>{selectedAction}</span>
                    <input
                      className='px-2.5  text-xs w-[30%]  rounded-[5px] border outline-none border-[#E6E6E6] py-2.5 w-ful'
                      type='text'
                      value={inputValues[selectedAction] || ""}
                      onChange={(e) =>
                        handleInputChange(selectedAction, e.target.value)
                      }
                      placeholder={`Enter ${selectedAction}`}
                    />
                  </div>
                ))}

                {isDropdownVisible && (
                  <select
                    className='px-5 text-sm text-[#bababa]  rounded-[5px] border outline-none border-[#07593D75] py-4 w-full'
                    value={selectedAction}
                    onChange={handleDropdownChange}
                  >
                    <option
                      className='text-sm text-[#B1B1B1]'
                      value='none'
                      // disabled
                    >
                      Select settings
                    </option>
                    {actions.map((option) => (
                      <option
                        className='cursor-pointer text-black hover:bg-primaryColor hover:text-white'
                        key={option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </select>
                )}

                {!isDropdownVisible && (
                  <div className='py-4  flex justify-end'>
                    <button
                      className=' flex justify-center items-center gap-2 text-primaryColor text-xs'
                      onClick={handleAddAction}
                    >
                      <PAIconAddGreen />
                      <span>Add Setting</span>
                    </button>
                  </div>
                )}
              </div>
            </section>

            <footer className='px-[4%] pt-10'>
              <div className='flex justify-end'>
                <div className='flex gap-3'>
                  <button
                    onClick={() => closeModal()}
                    className=' border border-primaryColor text-primaryColor text-xs py-2.5 px-5 rounded-[5px]'
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleSubmit()}
                    className='bg-primaryColor text-white   text-xs py-2.5 px-5 rounded-[5px]'
                  >
                    Save rule
                  </button>
                </div>
              </div>
            </footer>
          </main>
        </div>
      </RightSideModal>
    </>
  );
}

export default EditRuleFinalise;
