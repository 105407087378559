import { useKeycloak } from "@react-keycloak/web";
import {
  buyerDataById,
  funderDataById,
  supplierDataById,
} from "appstate/auth/authSlice";
import {
  PAIconCheck,
  PAIconCheckPass,
  PAIconNewArrowRight,
  PAIconOnboardArrowLeftIcon,
  PAIconPrimaryColorArrowRight,
} from "assets/images/svgs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import OnboardingLayout from "../category/components/OnboardingLayout";
const Getstarted = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { player } = useParams();

  const skipSupplier = () => {
    dispatch(supplierDataById({ supplierId: keycloak?.tokenParsed?.sub })).then(
      () => {
        navigate("/dashboard");
      }
    );
  };

  const skipFunder = () => {
    dispatch(funderDataById({ funderId: keycloak?.tokenParsed?.sub })).then(
      () => {
        navigate("/dashboard");
      }
    );
  };

  const skipBuyer = () => {
    dispatch(buyerDataById({ buyerId: keycloak?.tokenParsed?.sub })).then(
      () => {
        navigate("/dashboard");
      }
    );
  };

  const handleSkip = () => {
    player === "supplier"
      ? skipSupplier()
      : player === "funder"
      ? skipFunder()
      : skipBuyer();
  };

  const {
    bankDetailsUploaded,
    kycDocumentUploaded,
    agreementDocumentAccepted,
  } = useSelector((state) => state?.verificationSupplier);

  let canProceed =
    bankDetailsUploaded && kycDocumentUploaded && agreementDocumentAccepted;

  const handleAddBankDetails = () => {
    let path = `/onboarding/${player}/banking-details`;
    navigate(path);
  };

  const handleAddKYCDocuments = () => {
    let path = `/onboarding/${player}/kyc`;
    navigate(path);
  };

  const handleSignFundingAgreement = () => {
    let path = `/onboarding/${player}/sign-funding-agreement`;
    navigate(path);
  };

  const icon = <PAIconCheck />;
  const getStatedDatas = [
    {
      id: 1,
      title: "Enter business account detail  for verification",
      info: "Provide a valid bank account to receive payment.",
      settings: "Submit account details",
      icon: icon,
      click: () => {
        handleAddBankDetails();
      },
      non: () => {
        return;
      },
      validator: bankDetailsUploaded,
    },
    {
      id: 2,
      title: "Review and accept our funding agreement  ",
      info: "Please review and sign the funding agreement",
      settings: "Review funding agreement",
      icon: icon,
      click: () => {
        handleSignFundingAgreement();
      },
      non: () => {
        return;
      },
      validator: agreementDocumentAccepted,
    },
    {
      id: 3,
      title: "Complete KYC ",
      info: "Upload the required document for verification ",
      settings: "Upload documents",
      icon: icon,
      click: () => {
        handleAddKYCDocuments();
      },
      non: () => {
        return;
      },
      validator: kycDocumentUploaded,
    },
  ];
  return (
    <OnboardingLayout nohelp>
      <div className=" px-[18px] lg:px-[42px]  mt-6">
        <PAIconOnboardArrowLeftIcon
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>{" "}
      <div className="pt-[40px] px-[20px] h-[100%] overflow-auto pb-[35px] md:flex flex-col items-center md:px-0">
        <div className="mb-[50px] mt-[30px] flex justify-center items-center gap-x-[20px] lg:gap-x-[50px]">
          <div>
            <div className="w-[100px] h-[2px] bg-[#f08000] rounded-[100px] md:w-[220px]"></div>
          </div>
          <div>
            <div className="w-[100px] h-[2px] bg-[#f08000] rounded-[100px] md:w-[220px]"></div>
          </div>
          <div>
            <div className="w-[100px] h-[2px]  bg-[#f08000]  rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[13px] md:bottom-[15px]">
            <p className="font-normal text-xs text-textColor mb-[10px] md:text-sm">
              Get started
            </p>
            <div className="w-[100px] h-[2px] bg-[#f08000] rounded-[100px] md:w-[220px]"></div>
          </div>
        </div>
        {/* <h1 className="font-semibold text-2xl text-center text-primaryColor mb-[30px]">
          Let’s get started
        </h1> */}
        <div class="w-[200px] text-[#222222] text-2xl font-medium text-center mt-3">
          Let’s get started
        </div>
        <div className=" pb-[20px] rounded-[5px] border border-gray500 md:w-[722px] mb-[30px]  mt-[30px]">
          <div className="h-[47px] flex items-center pl-[30px]  bg-[#f8f8f8]   rounded-t-[5px]">
            {/* <p className="font-semibold text-sm text-primaryColor">
              Complete Setup
            </p> */}
            <div class="text-[#222222] text-sm font-medium ">
              Complete Setup
            </div>
          </div>
          <div className="h-[47px]  flex items-center pl-[30px] border-b border-gray500">
            {/* <p className="font-semibold text-sm text-textColor">
              Before you start using Payedge, please complete the following
              steps.
            </p> */}
            <div class="text-[#222222] text-xs font-medium ">
              Before you start using Payedge, please complete the following
              steps.
            </div>
          </div>
          <div className="px-[30px]">
            {getStatedDatas.map((data) => (
              <div
                key={data.id}
                className={`pt-[30px] md:flex justify-between pb-[20px] ${
                  data.id !== 4 && "border-b"
                } border-[#86888B]`}
              >
                <div className="flex">
                  <span className="mr-[20px]">
                    {data?.validator ? <PAIconCheckPass /> : data.icon}
                  </span>
                  <div>
                    <p className="font-semibold text-xs mb-[10px]">
                      {data.title}
                    </p>
                    <p className="font-normal text-xs md:w-[310px]">
                      {data.info}
                    </p>
                  </div>
                </div>
                <div
                  onClick={!data?.validator ? data.click : data?.non}
                  className="w-[179px] h-[31px] px-2 bg-[#F0EFF1] rounded-[5px] flex items-center cursor-pointer mt-[20px]"
                >
                  <p className="font-medium text-[10px] w-[160px] pl-[5px]">
                    {data.settings}
                  </p>
                  <PAIconNewArrowRight />
                </div>
              </div>
            ))}
          </div>
        </div>

        {
          <div>
            {/* <button
              className={`underline ${
                canProceed ? "text-primaryColor" : "text-gray500"
              }  font-medium text-xs flex
          gap-x-[6.33px] items-center mt-[33px] hover:cursor-pointer`}
              onClick={handleSkip}
            >
              Continue to Dashboard
              <PAIconNewArrowRight />
            </button> */}

            <button
              className={`group relative justify-center p-0.5 mb-2 mr2 text-xs text-gray-900 rounded-lg flex items-center mt-[33px] hover:cursor-pointer`}
            >
              {canProceed ? (
                <span
                  onClick={handleSkip}
                  class="relative font-semibold  flex text-primaryColor gap-[6.33px] px-5 py-2.5 bg-white underline"
                >
                  Continue to Dashboard <PAIconPrimaryColorArrowRight />
                </span>
              ) : (
                <span class="relative font-semibold underline flex text-gray500 gap-[6.33px] px-5 py-2.5">
                  Continue to Dashboard <PAIconNewArrowRight />
                </span>
              )}

              {!canProceed && (
                <div class="hidden group-hover:block">
                  <div class="group absolute -top-12 left-1/2 z-50 flex -translate-x-1/2 flex-col items-center text-center text-sm text-slate-300 before:-top-2">
                    <div class="rounded-sm bg-black py-1 px-2">
                      <p class="whitespace-nowrap">
                        Kindly complete onboarding requirements to proceed
                      </p>
                    </div>
                    <div class="h-0 w-fit border-l-8 border-r-8 border-t-8 border-transparent border-t-black"></div>
                  </div>
                </div>
              )}
            </button>
          </div>
        }
      </div>
    </OnboardingLayout>
  );
};

export default Getstarted;
