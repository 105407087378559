import { COLORS } from "constants/colors";
import { FONTSIZES, FONTWEIGHTS } from "constants/font-spec";
import styled from "styled-components";

export const HeaderContainer = styled.div.attrs({
  className: "border-b border-primaryColor",
})`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TabName = styled.p.attrs({
  className: "text-primaryColor text-[16px] font-[600] capitalize",
})``;

export const ProfileWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  margin-right: 33px;
  cursor: pointer;
`;

export const Info = styled.p`
  font-size: ${FONTSIZES.xsmall};
  font-weight: ${FONTWEIGHTS.normal};
  color: ${COLORS.mortar};
`;
