import React, { useState } from "react";

import FundersInvited from "./moreinvoicebidscreens/FundersInvited";
import NumberOfBids from "./moreinvoicebidscreens/NumberOfBids";
import ViewByFunders from "./moreinvoicebidscreens/ViewByFunders";

const MoreInvoiceIdSegment = () => {
  const [activeTab, setActiveTab] = useState("NumberOfBids");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "NumberOfBids":
        return <NumberOfBids />;
      case "FundersInvited":
        return <FundersInvited />;
      case "ViewByFunders":
        return <ViewByFunders />;
      default:
        return null;
    }
  };

  return (
    <div className=" gap-2">
      <div className="  ">
        <div className="text-sm ">
          <div className="flex gap-3  px-5  flex-row">
            <div className=" py-4">
              <p
                className={`cursor-pointer  py-1 ${
                  activeTab === "NumberOfBids"
                    ? " border-[#F08000]   text-amber-600 text-xs font-bold border-b-2 "
                    : "text-gray500  text-neutral-400 text-xs font-normal"
                }`}
                onClick={() => handleTabClick("NumberOfBids")}
              >
                Number of bids: 9
              </p>
            </div>

            <div className="py-4">
              <p
                className={`cursor-pointer  py-1 ${
                  activeTab === "FundersInvited"
                    ? " border-[#F08000]   text-amber-600 text-xs font-bold border-b-2 "
                    : "text-gray500  text-neutral-400 text-xs font-normal"
                }`}
                onClick={() => handleTabClick("FundersInvited")}
              >
                Funders invited: 14
              </p>
            </div>

            <div className="py-4">
              <p
                className={`cursor-pointer  py-1 ${
                  activeTab === "ViewByFunders"
                    ? " border-[#F08000]   text-amber-600 text-xs font-bold border-b-2 "
                    : "text-gray500  text-neutral-400 text-xs font-normal"
                }`}
                onClick={() => handleTabClick("ViewByFunders")}
              >
                Viewed by funders: 11
              </p>
            </div>
          </div>

          <div className="mt-3">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default MoreInvoiceIdSegment;
