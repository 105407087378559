import toast from 'react-hot-toast';
import { PAIconAlert } from 'assets/images/svgs'

const RequestConfirmationComp = ({ onClose }) => {
  return (
    <div className='pb-4 px-8 flex justify-center items-center'>
      <div className='w-4/5 flex flex-col gap-4 justify-center items-center'>
        <PAIconAlert />
        <p className='text-[16px] text-center text-[#7A7A7A]'>Are you sure you would like to request for an early payment for this invoice?</p>
        <div className='flex justify-between gap-4'>
          <p onClick={() => { toast.success('An email has been successfully sent!'); onClose() }} className='cursor-pointer py-4  px-6 border border-[#07593D] text-[#07593D] hover:bg-[#07593D] hover:text-white'>Yes</p>
          <p onClick={onClose} className='cursor-pointer py-4  px-6 border border-[#EE0816] text-[#EE0816] hover:bg-[#EE0816] hover:text-white'>No</p>
        </div>
      </div>
    </div>
  )
}

export default RequestConfirmationComp