import {
  PAIconArticleImage,
} from "assets/images/svgs";
import React from "react";
import RouteHeader from "../RouteHeader";

const posts = [
  {
    id: 1,
    image: "/download.jpeg",
    title: "Streamlining Supplier Onboarding",
    timeAgo: 15,
  },
  {
    id: 2,
    image: "/download.jpeg",
    title: "Navigating the Financial Supply Chain",
    timeAgo: 30,
  },
  {
    id: 3,
    image: "/download.jpeg",
    title: "The Role of Blockchain in Supply Chain Finance",
    timeAgo: 15,
  },
  {
    id: 4,
    image: "/download.jpeg",
    title: "Unraveling the Cash Flow Mystery",
    timeAgo: 30,
  },
  {
    id: 5,
    image: "/download.jpeg",
    title: "The Role of Blockchain in Supply Chain Finance",
    timeAgo: 15,
  },
];

const PostDetails = ({goBack}) => {

  return (
    <>
      <div className="px-7 my-3 w-full">
        <RouteHeader
          Route1="Resources"
          Heading="Streamlining Supplier Onboarding"
          handleBack={goBack}
        />
        <div className="lg:flex mt-5 gap-5">
          {/* SECTION 1 */}
          <section className="w-full lg:w-[60%] overflow-y-auto max-h-[500px] pr-5">
            <div className="mt10">
              <h1 className="text-black font-sans font-bold text-2xl leading-[137.836%] pt-5">
                Streamlining Supplier Onboarding
              </h1>
              <PAIconArticleImage className="mt-5 w-full " />
              <div className="my-5 text-[#F08000] font-sans text-xs font-normal leading-[129.836%]">
                October 13, 2023
              </div>
              <p className="text-[#9ca3af] font-sans text-base font-normal leading-normal text-justify">
                Effective supplier onboarding is a crucial aspect of supply
                chain management. When done right, it can lead to increased
                efficiency, reduced costs, and improved collaboration with your
                suppliers. To streamline supplier onboarding processes, consider
                the following key practices:
              </p>
            </div>
            <h1 className="text-black font-sans font-bold text-2xl leading-[137.836%] mt-5">
              The Basics of Supplier Onboarding
            </h1>
            <p className="text-[#9ca3af] font-sans text-base font-normal leading-normal mt-2 text-justify mb-10">
              Supplier onboarding is the process by which a company welcomes new
              suppliers into its network. It involves a series of steps, from
              due diligence checks to documentation exchanges, aimed at ensuring
              that the new supplier aligns with the company's requirements and
              values. Supplier onboarding is the process by which a company
              welcomes new suppliers into its network. It involves a series of
              steps, from due diligence checks to documentation exchanges, aimed
              at ensuring that the new supplier aligns with the company's
              requirements and values. Supplier onboarding is the process by
              which a company welcomes new suppliers into its network. It
              involves a series of steps, from due diligence checks to
              documentation exchanges, aimed at ensuring that the new supplier
              aligns with the company's requirements and values. Supplier
              onboarding is the process by which a company welcomes new
              suppliers into its network. It involves a series of steps, from
              due diligence checks to documentation exchanges, aimed at ensuring
              that the new supplier aligns with the company's requirements and
              values. Supplier onboarding is the process by which a company
              welcomes new suppliers into its network. It involves a series of
              steps, from due diligence checks to documentation exchanges, aimed
              at ensuring that the new supplier aligns with the company's
              requirements and values. Supplier onboarding is the process by
              which a company welcomes new suppliers into its network. It
              involves a series of steps, from due diligence checks to
              documentation exchanges, aimed at ensuring that the new supplier
              aligns with the company's requirements and values.
            </p>
          </section>

          {/* SECTION 2 */}
          <section className="w-full lg:w-[40%] mt-10 lg:mt-0 rounded-md shadow-sm">
            <div className="leading-[137.836%] hauto">
              <h1 className="text-black font-sans text-lg font-bold leading-normal tracking-wider px-3 py-5">
                Related Articles
              </h1>
              {/* Map through posts and render related articles */}
              {posts.map((post) => (
                <div
                  key={post.id}
                  className="w-full flex items-center p3 borderb px-3 pb-5"
                >
                  <div className="mr-3 w-[40%] rounded-md cursor-pointer">
                    {/* <img src={post.image} className=" w-2 " alt="/" /> */}
                    <img
                      src={post.image}
                      alt={post.title}
                      className="w-full h-auto rounded-md"
                    />
                  </div>
                  <div className="w-[60%]">
                    <h2 className="text-black font-sans font-bold text-base leading-[137.836%]">
                      {post.title}
                    </h2>
                    <p className="text-[#9ca3af]font-sans text-xs font-normal leading-[129.836%] mt-2">
                      {`${post.timeAgo} mins ago`}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PostDetails;
