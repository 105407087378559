import React from "react";
import { PAIconIosArrow } from "assets/images/svgs";

const RouteHeader = ({ Route1, icon, Route2, Heading, handleBack }) => {

  return (
    <div className="font-sans italic font-semibold text-base leading-normal mt-3">
      <button
        onClick={handleBack}
        className="text-black hover:underline cursor-pointer italic"
      >
        {Route1}
      </button>
      {icon}
      <button
        onClick={handleBack}
        className="text-black hover:underline cursor-pointer italic"
      >
        {Route2}
      </button>
      <PAIconIosArrow className="inline-block ml-2" />
      <span
        onClick={() => {}}
        className="text-orange-500 font-sans italic font-semibold text-base leading-normal ml-2"
      >
        {Heading}
      </span>
    </div>
  );
};

export default RouteHeader;
