import React from "react";
import {
  PAIconSmallDangote,
  PAIconSmallNestle,
  PAIconSmallShell,
  PAIconSmallTrust,
  PAIconSmallBrewIcon,
  PAIconSmallCoke,
} from "assets/images/svgs";
import { motion } from "framer-motion";

const DetailCard = ({
  funderName = "N/A",
  discountRate = "N/A",
  statusOfInvoice = "N/A",
  biddingTime = "N/A",
}) => {
  return (
    <div>
      {" "}
      <motion.div
        whileTap={{ scale: 0.98 }}
        className="flex  justify-between bg-white py-5 px-3 w-full sm:w-full sm:space-x-2 md:w-full   "
      >
        {/* button stops here */}
        <span className="flex gap-3 items-center ">
          {funderName === "ABC Limited" ? (
            <PAIconSmallCoke />
          ) : funderName === "Shell PLC" ? (
            <PAIconSmallCoke />
          ) : funderName === "Dangote" ? (
            <PAIconSmallDangote />
          ) : funderName === "Union Bank" ? (
            <PAIconSmallNestle />
          ) : funderName === "Access bank" ? (
            <PAIconSmallNestle />
          ) : funderName === "Defi Ltd." ? (
            <PAIconSmallShell />
          ) : funderName === "Unity Bank" ? (
            <PAIconSmallShell />
          ) : funderName === "FirstBank" ? (
            <PAIconSmallTrust />
          ) : (
            <PAIconSmallBrewIcon />
          )}{" "}
          <div className="flex flex-col justify-center">
            <span className="text-black text-xs font-normal">{funderName}</span>
            <span className=" text-[#949494] text-[10px]">Funder</span>
          </div>
        </span>
        <span className="hidden extra-md:flex gap-6 ">
          <div className="flex flex-col justify-center">
            <span className="text-black text-xs font-normal">
              {discountRate}
            </span>
            <span className=" text-[#949494] text-[10px]">Discount Rate</span>
          </div>
        </span>
        <span className="hidden extra-md:flex gap-6 ">
          <div className="flex flex-col justify-center">
            <span
              className={`font-[500] text-sm ${
                statusOfInvoice === "Locked" ? "text-[#f08000]" : "text-black"
              }`}
            >
              {statusOfInvoice}
            </span>
            <span className=" text-[#959595] text-[10px]">Status</span>
          </div>
        </span>

        <span className="hidden extra-md:flex gap-6 ">
          <div className="flex flex-col justify-center">
            <span className="font-[500] text-sm">{biddingTime}</span>
            <span className=" text-[#959595] text-[10px]">Bidding Time</span>
          </div>
        </span>
      </motion.div>
    </div>
  );
};

export default DetailCard;
