import React, { useState, useEffect } from "react";
import {
  PAIconVieweye,
  PAIconFilterIcon,
  PAIconArrowLeftGreen,
} from "assets/images/svgs";
import Table from "layout/table/Table";
import Loading from "shared/Loading";

import DashboardLayout from "layout/dashboardlayout";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ViewReconciliationHistory = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const options = [
    { id: 1, name: "All" },
    { id: 2, name: "Open" },
    { id: 3, name: "Fulfilled" },
    { id: 4, name: "Cancelled" },
    { id: 5, name: "Closed" },
  ];

  const dummyTransactions = [
    {
      id: "1",
      transactionId: "04347T-2090",
      funderFullName: "Nestle PLC",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-01",
      currency: "USD",
      amount: "+9,000,000",
      status: "SUCCESSFUL",
    },
    {
      id: "2",
      transactionId: "04347T-2090",
      funderFullName: "Nestle PLC",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-02",
      currency: "USD",
      amount: "+4,000,000",
      status: "FAILED",
    },
    {
      id: "3",
      transactionId: "04347T-2090",
      funderFullName: "Nestle PLC",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-03",
      currency: "USD",
      amount: "+2,000,000",
      status: "PENDING",
    },
    {
      id: "4",
      transactionId: "04347T-2090",
      funderFullName: "Nestle PLC",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-03",
      currency: "USD",
      amount: "+2,000,000",
      status: "PENDING",
    },
    {
      id: "5",
      transactionId: "04347T-2090",
      funderFullName: "Nestle PLC",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-03",
      currency: "USD",
      amount: "+2,000,000",
      status: "PENDING",
    },
    {
      id: "6",
      transactionId: "04347T-2090",
      funderFullName: "Nestle PLC",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-03",
      currency: "USD",
      amount: "+2,000,000",
      status: "PENDING",
    },
    {
      id: "7",
      transactionId: "04347T-2090",
      funderFullName: "Nestle PLC",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-03",
      currency: "USD",
      amount: "+2,000,000",
      status: "PENDING",
    },
    {
      id: "8",
      transactionId: "04347T-2090",
      funderFullName: "Nestle PLC",
      invoiceDate: "2023-05-03",
      paymentDate: "2023-05-03",
      currency: "USD",
      amount: "+2,000,000",
      status: "PENDING",
    },
  ];

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Transaction ID
        </span>
      ),
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Buyer
        </span>
      ),

      selector: (row) => row.funderFullName,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
    },
    {
      name: "Payment Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.amount === "+9,000,000" ? (
          <p className="text-success">{row.amount}</p>
        ) : row.amount === "+4,000,000" ? (
          <p className="text-red-600">{row.amount}</p>
        ) : (
          <p>{row.amount}</p>
        ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "FAILED" ? (
          <p className="text-red-600">{row.status}</p>
        ) : row.status === "PENDING" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
    {
      //   name: "Details",
      selector: (row) => row.view,
      cell: (row) => (
        <div className="flex">
          <PAIconVieweye height="15px" width="30px" />
          <p className="text-[#626679] cursor-pointer">View</p>
        </div>
      ),
    },
  ];

  const goBack = () => {
    navigate(-1);
  };
  const navigateToDetail = (id) => {
    navigate(`/settlement/detail/reconciliationdetail/${id}`);
  };

  useEffect(() => {
    setData(dummyTransactions);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardLayout
      section="Settlement  & Reconciliation"
      subHeader="Here is an overview of your outstanding transactions"
    >
      <div>
        <div>
          <Link
            onClick={goBack}
            className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full bg-white py-4 rounded-md px-4"
          >
            <PAIconArrowLeftGreen />
            <span className="flex items-center text-primaryColor">Go Back</span>
          </Link>
        </div>

        <div className="bg-white h-auto rounded-[10px]">
          {/* first div */}

          <div className="flex items-center justify-between pt-4  bg-[#FAFAFA]   py-6 rounded-t-[10px] ">
            <div className="flex-col pl-[20px] ">
              <p className="font-bold">All Transactions</p>
              <p className="text-[13px] text-[#626679]">Transactions summary</p>
            </div>
            <div className="mr-[20px] ">
              <div className="w-full flex flex-col justify-center">
                <label
                  htmlFor={""}
                  className="font-medium text-sm text-textColor mb-[2px]"
                >
                  {}
                </label>

                <div className="relative flex items-center justify-center">
                  <div className="flex gap-2 pr-2">
                    <PAIconFilterIcon className="my-auto" />
                    Filter Transactions:
                  </div>

                  <select
                    disabled={false}
                    onChange={() => {}}
                    defaultValue={"default"}
                    className="appearance-none w-[180px] h-[40px] pl-3 font-medium text-sm text-gray-500 bg-white rounded-[5px]  outline-none focus:outline-none"
                  >
                    <option value="default" disabled>
                      filter by..
                    </option>
                    {options.map((option) => (
                      <option key={option.id}>{option.name}</option>
                    ))}
                  </select>

                  <div className="absolute inset-y-0 right-0 flex items-center px-2 top-[8px] pointer-events-none">
                    <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                      <path d="M10 12.5l-6-6 1.41-1.41L10 9.68l4.59-4.59L16 6.5l-6 6z"></path>
                    </svg>
                  </div>
                </div>
              </div>

              {/* ENDS HERE */}
            </div>
          </div>
          <Table
            columns={columns}
            data={data}
            pointer
            className="mt-5 px-4"
            progressPending={false}
            onRowClicked={(row) => navigateToDetail(row.id)}
            progressComponent={<Loading />}
          />
        </div>
        {/* first section */}
        <div></div>
      </div>
    </DashboardLayout>
  );
};

export default ViewReconciliationHistory;
