// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-tab {
  background: #07593d;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
.inactive-tab {
  background: #fff;
  color: var(--Text-color---D, #565656);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}

.tab-button {
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for the button */
}

.tab-content-wrapper {
  display: flex;
}

.tab-content {
  opacity: 0;
  width: 100%;
  transition: opacity 0.5s ease;
}

.tab-content.active {
  opacity: 1;
  width: 100%;
  transition: opacity 0.5s ease;
}
.shadow-mod {
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.border-activation {
  border: 1px solid rgba(122, 122, 122, 0.5);
}
.modal-shadow {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/features/dashboard/buyerdashboard/components/styles/tabbedinvoice.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,8CAA8C;AAChD;AACA;EACE,gBAAgB;EAChB,qCAAqC;EACrC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,8CAA8C;AAChD;;AAEA;EACE,eAAe;EACf,yBAAyB,EAAE,qCAAqC;AAClE;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,WAAW;EACX,6BAA6B;AAC/B;AACA;EACE,+CAA+C;AACjD;;AAEA;EACE,0CAA0C;AAC5C;AACA;EACE,8CAA8C;AAChD","sourcesContent":[".active-tab {\n  background: #07593d;\n  color: #fff;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);\n}\n.inactive-tab {\n  background: #fff;\n  color: var(--Text-color---D, #565656);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);\n}\n\n.tab-button {\n  cursor: pointer;\n  transition: all 0.3s ease; /* Smooth transition for the button */\n}\n\n.tab-content-wrapper {\n  display: flex;\n}\n\n.tab-content {\n  opacity: 0;\n  width: 100%;\n  transition: opacity 0.5s ease;\n}\n\n.tab-content.active {\n  opacity: 1;\n  width: 100%;\n  transition: opacity 0.5s ease;\n}\n.shadow-mod {\n  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.15);\n}\n\n.border-activation {\n  border: 1px solid rgba(122, 122, 122, 0.5);\n}\n.modal-shadow {\n  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
