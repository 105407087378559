import { PAIconInboxActive, PAIconSent, PAIconNewSupport, PAIconSearch } from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import InputField from "shared/InputField";
import styled from "styled-components";
import TableHeaderSelect from "shared/tableHeader/TableHeaderSelect";
import Button from "shared/Button";

const TableHeaderSupport = ({
  handleChange,
  search,
  productName,
  setProductName,
  path,
  options,
  support
}) => {

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(path);
  };

  return (
    <div className="mb-[33px]">

      {support && (
        <div>
          <div className="flex items-center justify-between">
            <div className="flex text-center items-center gap-6">
              <TabContainerInbox>
                <PAIconInboxActive />
                <span className="text-[#F08000]">Inbox</span>
                <div className="w-[13px] h-[14px] rounded-lg">
                  <div className="w-[14px] h-[14px] [font-family:'Inter-SemiBold',_Helvetica] font-semibold text-orange-500 text-[10px] bg-orange-100 whitespace-nowrap rounded-3xl">
                    12
                  </div>
                </div>
              </TabContainerInbox>
              <TabContainer><PAIconSent /><span>Sent</span></TabContainer>
            </div>
            <div className="justify-end">
              <Button
                neutral
                onClick={handleNavigate}
                className="flex w-[157px] h-[44px] hover:!bg-[#317a62] border-[1px] border-[#317a62] rounded-[5px] justify-center items-center bg-DeepSeaGreen "
              >
                <PAIconNewSupport />
                <p className="text-sm pl-2 font-normal text-white">New Complaint</p>
              </Button>
            </div>
          </div>

          <hr className="block w-[100%] my-3 border-t-2 bg-[#ccc] border-silver" />
        </div>
      )}

      <div className="flex flex-col gap-y-[10px] md:gap-x-[10px] md:flex-row md:flex-wrap pt-[12px]">
        <div className="flex items-center">
          <InputField
            placeholder={"Search inbox"}
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            className="block px-8 w-full border rounded py-2 text-gray-700 focus:outline-none"
          ><PAIconSearch /></InputField>
        </div>
        <SelectContainer>
          <TableHeaderSelect options={options} onChange={handleChange} />
        </SelectContainer>
        <Button
          neutral
          onClick={search}
          className="w-[96px] h-[41px] bg-headerbtnBg flex justify-center items-center border-[0.5px] border-gray rounded-[5px] text-sm font-normal text-black"
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default TableHeaderSupport;

const SelectContainer = styled.div`
    min-width: 180px;
    @media screen and (max-width: 470px) {
      min-width: auto;
    }
  `;

const TabContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 4rem;
    gap: 6px;  
    cursor: pointer;
`

const TabContainerInbox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 5rem;
    gap: 6px;  
    cursor: pointer;
`