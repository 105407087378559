import React, { useState } from "react";
import EarlyPaymentComponent from "./EarlyPaymentComponent";
import DirectFundingComponent from "./DirectFundingComponent";

const RecentRequest = () => {
  const [activeTab, setActiveTab] = useState("earlyPayment");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "earlyPayment":
        return <EarlyPaymentComponent />;
      case "directFunding":
        return <DirectFundingComponent />;
      default:
        return null;
    }
  };

  return (
    <div className="mt-3 gap-2">
      <div>
        <h1 className="text-black font-bold text-lg">Recent Requests</h1>
        <div className="mt-3 text-sm">
          <div className="flex gap-3">
            <p
              className={`cursor-pointer ${
                activeTab === "earlyPayment" ? "border-b-2" : ""
              }`}
              onClick={() => handleTabClick("earlyPayment")}
            >
              Early Payment Request
            </p>
            <p
              className={`cursor-pointer ${
                activeTab === "directFunding" ? "border-b-2" : ""
              }`}
              onClick={() => handleTabClick("directFunding")}
            >
              Direct Funding
            </p>
          </div>

          {/* Render the tab content */}
          <div className="mt-3">{renderTabContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default RecentRequest;
