import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import TableHeader from "shared/tableHeader/TableHeader";
import PayableFinanceModal from "./components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { allBuyerPfp, getPfpId } from "appstate/buyer/buyerSlice";
import Pagination from "shared/Pagination";

const AllPayableFinance = () => {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const { allBuyerPfpData } = useSelector((state) => state.buyer);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const onRowClicked = (row) => {
    let path = "payablefinancedetails";
    dispatch(getPfpId(row?.pfpId));
    navigate(path);
    // console.log(row.pfpId);
    // console.log(row);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allBuyerPfp({ page, pageSize }));
  }, [dispatch, page, pageSize]);
  useEffect(() => {
    setData(allBuyerPfpData?.data);
  }, [allBuyerPfpData]);
  const columns = [
    {
      name: "Name",
      selector: (row) => row.buyerCompanyName,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Financing Type",
      selector: (row) => row.financingOptions,
      sortable: true,
      width: "20rem",
    },
    {
      name: "Start Date",
      selector: (row) => row.startDate,
      sortable: true,
      width: "15rem",
    },
    {
      name: "End Date",
      selector: (row) => row.endDate,
      sortable: true,
      width: "15rem",
    },
    {
      name: "Tier",
      selector: (row) => row.tierCategory,
      sortable: true,
      width: "15rem",
    },
  ];
  const totalPages = allBuyerPfpData?.meta?.totalElements;
  let path = "/payablefinancing/payablefinancedetail/createpayablefinance";
  return (
    <DashboardLayout>
      <PayableFinanceModal isShown={open} hide={() => setOpen(false)} />
      <Card className="p-[24px] rounded-[10px]">
        <div>
          <TableHeader
            total="All PFP"
            details="Access details of your payable finance program"
            btnName="New PFP"
            path={path}
            payableFinance
          />
        </div>
        <Table
          columns={columns}
          data={data}
          onRowClicked={onRowClicked}
          //   progressPending={isLoading}
          //   progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default AllPayableFinance;
