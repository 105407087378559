/* eslint-disable react-hooks/exhaustive-deps */
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Heading from "shared/heading/Heading";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { useDispatch, useSelector } from "react-redux";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import { extractDate } from "utilities/helper/dateFormater";
import { getApprovedInvoiceId } from "appstate/supplier/supplierSlice";

const DirectFundingSingleRequestInvoiceList = () => {
  const isLoading = false;
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const dummyData = [
    { id: 1, invoiceReference: "INV001", buyerCompanyName: "Buyer A", dueDate: "2023-08-01", createdDate: "2023-07-01", currency: "USD", total: "$1000", status: "Approved" },
    { id: 2, invoiceReference: "INV002", buyerCompanyName: "Buyer B", dueDate: "2023-08-02", createdDate: "2023-07-02", currency: "USD", total: "$2000", status: "Approved" },
    { id: 3, invoiceReference: "INV003", buyerCompanyName: "Buyer C", dueDate: "2023-08-03", createdDate: "2023-07-03", currency: "USD", total: "$3000", status: "Approved" },
  ];

  useEffect(() => {
    setData(dummyData);
  }, []);

  /*
  useEffect(() => {
    dispatch(allApprovedInvoices({ page, pageSize, status: "Approved" }));
  }, [dispatch, page, pageSize]);
  */

  const navigate = useNavigate();

  const onRowClicked = (row) => {
    dispatch(getApprovedInvoiceId(row?.id));
    let path = "/invoice/fundingSection/directsinglerequestinvoicelist/directinvoicedetails";
    navigate(path);
  };

  const totalPages = Math.ceil(dummyData.length / pageSize);

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className="pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyerCompanyName,
      sortable: true,
    },
    {
      name: "Invoice Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => extractDate(row.createdDate),
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.total,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Approved" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Rejected request" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "Updated request" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "Pending request" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  return (
    <DashboardLayout>
      <Card className="rounded-lg">
        <div>
          <Heading heading="78 Approved Invoices" info="Available for payment" />
          <div className="rounded-b-lg px-[24px] pb-[24px]">
            <Table
              columns={columns}
              data={data}
              onRowClicked={onRowClicked}
              pointer
              progressPending={isLoading}
              progressComponent={<Loading />}
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {!isLoading && totalPages > 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              )}
            </div>
          </div>
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default DirectFundingSingleRequestInvoiceList;

