import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDisbursedInvoice,
  getAllPaymentExtension,
  getApproval,
  getBuyerOverview,
  getFunderOverview,
  getSinglePaymentExtension,
  paymentExtension,
} from "appstate/api/paymentExtension";

const initialState = {
  isLoading: false,
  funderOverviewData: null,
  buyerOverviewData: null,
  paymentExtensionData: [],
  approvalData: null,
  singlePaymentExtensionData: [],
  disbursedInvoiceData: [],
  associatedFunderId: null,
  paymentExtensionMessage: null,
  selectedRowId: null,
  invoiceIds: []
};

export const funderOverview = createAsyncThunk(
  "funderOverview",
  getFunderOverview
);

export const buyerOverview = createAsyncThunk(
  "buyerOverview",
  getBuyerOverview
);

export const allPaymentExtension = createAsyncThunk(
  "allPaymentExtension",
  getAllPaymentExtension
);

export const approvalStatus = createAsyncThunk("approvalStatus", getApproval);

export const allSinglePaymentExtension = createAsyncThunk(
  "allSinglePaymentExtension",
  getSinglePaymentExtension
);

export const allDisbursedInvoice = createAsyncThunk(
  "allDisbursedInvoice",
  fetchDisbursedInvoice
);

export const createPaymentExtension = createAsyncThunk(
  "createPaymentExtension",
  paymentExtension
);

const paymentExtensionSlice = createSlice({
  name: "paymentExtension",
  initialState,
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    getAssociatedFunderId: (state, action) => {
      state.associatedFunderId = action.payload;
    },
    setSelectedRowId: (state, action) => {
      state.selectedRowId = action.payload;
    },
    getInvoiceId: (state, action) => {
      state.invoiceIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    // funder overview
    builder.addCase(funderOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.funderOverviewData = action.payload;
    });
    builder.addCase(funderOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // buyer overview
    builder.addCase(buyerOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.buyerOverviewData = action.payload;
    });
    builder.addCase(buyerOverview.rejected, (state) => {
      state.isLoading = false;
    });
    // all payment extension
    builder.addCase(allPaymentExtension.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allPaymentExtension.fulfilled, (state, action) => {
      state.isLoading = false;
      state.paymentExtensionData = action.payload;
    });
    builder.addCase(allPaymentExtension.rejected, (state) => {
      state.isLoading = false;
    });
    // approval status
    builder.addCase(approvalStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(approvalStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.approvalData = action.payload;
    });
    builder.addCase(approvalStatus.rejected, (state) => {
      state.isLoading = false;
    });
    // single payment extension
    builder.addCase(allSinglePaymentExtension.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allSinglePaymentExtension.fulfilled, (state, action) => {
      state.isLoading = false;
      state.singlePaymentExtensionData = action.payload;
    });
    builder.addCase(allSinglePaymentExtension.rejected, (state) => {
      state.isLoading = false;
    });
    // disbursed invoice
    builder.addCase(allDisbursedInvoice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allDisbursedInvoice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.disbursedInvoiceData = action.payload;
    });
    builder.addCase(allDisbursedInvoice.rejected, (state) => {
      state.isLoading = false;
    });
    // create payment extension
    builder.addCase(createPaymentExtension.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createPaymentExtension.fulfilled, (state, action) => {
      state.isLoading = false;
      state.paymentExtensionMessage = action.payload;
    });
    builder.addCase(createPaymentExtension.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const { getAssociatedFunderId, setSelectedRowId, getInvoiceId } =
  paymentExtensionSlice.actions;
export default paymentExtensionSlice.reducer;
