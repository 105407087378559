import React, { useState } from "react";

const countryCodes = [
  { code: "+234", country: "NG" },
  { code: "+1", country: "US" },
  { code: "+44", country: "UK" },
  { code: "+61", country: "AU" },
  { code: "+91", country: "IN" },
];

const PhoneNumber = ({
  text = "Phone Number",
  onChange,
  onBlur,
  value,
  name,
  id,
  className,
  disabled,
  defaultValue,
  onCountryCodeChange,
}) => {
  const defaultClass =
    "w-full border-gray-300 border-b outline-none block pl-2 pr-10 py-2 text-xs";

  const [countryCode, setCountryCode] = useState("+234");

  const handleCountryChange = (e) => {
    const selectedCountryCode = e.target.value;
    setCountryCode(selectedCountryCode);
    onCountryCodeChange(selectedCountryCode);
  };

  return (
    <div>
      <div className="flex flex-col py-[5px]">
        <label
          htmlFor="phone"
          className="font-normal text-xs text-textColor mb-[10px]"
        >
          {text}
        </label>
        <div className="mt-1 flex relative">
          <div className="flex border-gray-300 border-b mr-1 items-center">
            <label htmlFor="phone-country" className="sr-only">
              Country
            </label>
            <select
              disabled={disabled}
              id="phone-country"
              name="phone-country"
              className="outline-none h-full py-2 w-fit cursor-pointer border-transparent bg-transparent text-gray-500 text-xs"
              onChange={handleCountryChange}
              value={countryCode}
            >
              {countryCodes.map(({ code, country }) => (
                <option key={code} value={code} className="cursor-pointer">
                  {country} ({code})
                </option>
              ))}
            </select>
          </div>
          <input
            defaultValue={defaultValue}
            disabled={disabled}
            type="tel"
            name={name}
            id={id}
            className={`${defaultClass} ${className}`}
            placeholder="9012345678"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
