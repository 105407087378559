import ArrowIcon from "features/ToolsAgreement/components/Tools/ArrowIcon";
import VirtualBvnModal from "features/wallet/createVirtualAccount/bvn";
import VirtualAccountModal from "features/wallet/createVirtualAccount/modal";
import CongratsModal from "features/wallet/linkExternalAccount/congrats/congratsModal";
import React, { useState } from "react";

const NewVirtualAccount = () => {
  const [isCreateVirtualAccountOpen, setCreateVirtualAccountOpen] =
    useState(false);
  const [showVirtualAccountBvn, setShowVirtualAccountBvn] = useState(false);
  const [showCongratsModal, setShowCongratsModal] = useState(false);

  const toggleCreateVirtualAccount = () => {
    setCreateVirtualAccountOpen(!isCreateVirtualAccountOpen);
  };

  const handleVirtualAccountSubmit = () => {
    setCreateVirtualAccountOpen(false);
    setShowVirtualAccountBvn(true);
  }

  const handleVirtualBvnSubmit = () => {
    setShowVirtualAccountBvn(false);
    setShowCongratsModal(true);
  };

  const handleClose = () => {
    setCreateVirtualAccountOpen(false);
    setShowVirtualAccountBvn(false);
    setShowCongratsModal(false)
  }

  return (
    <>
      <div className="bg-[#07593D] p-5 rounded cursor-pointer mt-5" onClick={toggleCreateVirtualAccount}>
        <p className="text-white flex items-center justify-center">
          Create new virtual account
        </p>
        <div className="flex items-center justify-end text-white mb-[-20px]">
          <ArrowIcon />
        </div>
      </div>
      <VirtualAccountModal
        isOpen={isCreateVirtualAccountOpen}
        onClose={() => handleClose()}
        handleSubmit={handleVirtualAccountSubmit}
      />
      <VirtualBvnModal
        isOpen={showVirtualAccountBvn}
        onClose={() => handleClose()}
        onSubmit={handleVirtualBvnSubmit}
      />
       <CongratsModal
        isOpen={showCongratsModal}
        onClose={() => handleClose(false)}
        message="Your Virtual Account has been created"
      />
    </>
  );
};

export default NewVirtualAccount;
