import request from "apiInstance";
import toast from "react-hot-toast";

export const getNewDirectFunding = async ({
  page,
  pageSize,
  bulk,
  search,
  status,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/fund-request/direct-fundings?page=${page}&size=${pageSize}&bulk=${bulk}&search=${search}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const directFundingApproveOrDecline = async ({
  id,
  comment,
  status,
}) => {
  try {
    const response = await request({
      method: "put",
      url: `/fund-request/approve-direct-funding/${id}?comment=${comment}&status=${status}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
