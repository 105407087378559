import React from "react";
import Overview from "../overview/Overview";
import HeaderTabs from "shared/HeaderTabs";
import BulkExtensionRequest from "../bulk";
import SingleExtensionRequest from "../single";

const BuyerPaymentExtensionDashboard = () => {
  return (
    <HeaderTabs
      tabs={[
        { key: "overview", label: "Overview", content: <Overview /> },
        {
          key: "singleExtensionRequest",
          label: "Single Extension Requests",
          content: <SingleExtensionRequest />,
        },
        {
          key: "bulkExtensionRequest",
          label: "Bulk Extension Requests",
          content: <BulkExtensionRequest />,
        },
      ]}
      hasPaddingY={true}
    />
  )
}

export default BuyerPaymentExtensionDashboard