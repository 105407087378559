import React from 'react'

// Modal component
const ToolsModal = ({ message, onClose }) => {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex items-center shadow-2xl justify-center bg-gray800 mx-auto bg-opacity-50">
        <div className="bg-white p-8 rounded-md">
          <p className="text-lg font-semibold">{message}</p>
          <button
            className="mt-4 bg-[#FFBE55] rounded-sm p-[10px] text-white hover:bg-yellow-200"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  };

export default ToolsModal;