import React, { useEffect, useState } from "react";
import walletcardinner from "assets/images/walletcardinner1.png";
import walletcard from "assets/images/walletcard1.png";
import {
  PAIconGroup,
  PAIconKitEyeclose,
  PAIconMastercardWhite,
  PAIconPayedgeWallet,
} from "assets/images/svgs";

const NewDebitCard = () => {
  const [showBalance, setShowBalance] = useState(
    localStorage.getItem("showBalance") === "true"
  );

  useEffect(() => {
    localStorage.setItem("showBalance", showBalance);
  }, [showBalance]);

  const handleToggleBalance = () => {
    setShowBalance(!showBalance);
  };

  return (
    <div className="relative w-full h-full">
      {/* Background Image */}
      <img
        src={walletcard}
        alt="Debit card Background"
        className="absolute inset-0 rounded-md w-full h-full object-cover"
      />
      {/* Text at top left of the background image */}
      <div className="absolute top-0 left-0 p-3 text-white">
        <span>
          <p className="font-semibold">Debit Card</p>
          <p className="text-sm">Transaction summary</p>
        </span>
      </div>
      {/* Overlay Image with text */}
      <div className="absolute inset-0 flex items-center justify-center mt-7">
        <div className="relative w-[70%] h-auto">
          <img
            src={walletcardinner}
            alt="Overlay"
            className="w-full h-full object-contain"
          />
          <div className="absolute top-0 p-4 w-full text-white h-full flex flex-col justify-between">
            <div className="flex justify-between items-center">
              <PAIconPayedgeWallet />
              <PAIconGroup />
            </div>
            <div className="flex justify-between items-center">
              <span>
                <p className="text-xs">Name</p>
                <p className="text-sm">Abubker Aaj</p>
              </span>
              <span>
                <p className="text-xs flex items-center justify-end">Number</p>
                <p className="text-sm">**** **** 2243</p>
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span>
                {showBalance ? (
                  <p className="font-semibold">₦340,090</p>
                ) : (
                  <p className="font-semibold">*******</p>
                )}
                <p
                  className="text-xs flex items-center justify-center cursor-pointer"
                  onClick={handleToggleBalance}
                >
                  {showBalance ? "Hide balance" : "Show balance"}
                  <PAIconKitEyeclose className="ml-2" />
                </p>
              </span>
              <span>
                <p className="text-xs flex items-center justify-end">
                  <PAIconMastercardWhite />
                </p>
                <p className="text-xs">Mastercard</p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDebitCard;
