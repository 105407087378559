// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-not-tab {
  color: #f08000;
  font-size: 14px;
}
.inactive-not-tab {
  color: var(--Text-color---D, #565656);
  font-size: 12px;
}

.not-tab-button {
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for the button */
}

.tab-content-wrapper {
  display: flex;
}

.tab-content {
  opacity: 0;
  width: 100%;
  transition: opacity 0.5s ease;
}

.tab-content.active {
  opacity: 1;
  width: 100%;
  transition: opacity 0.5s ease;
}
`, "",{"version":3,"sources":["webpack://./src/features/notification/components/styles/notificationTab.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,qCAAqC;EACrC,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,yBAAyB,EAAE,qCAAqC;AAClE;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,WAAW;EACX,6BAA6B;AAC/B","sourcesContent":[".active-not-tab {\n  color: #f08000;\n  font-size: 14px;\n}\n.inactive-not-tab {\n  color: var(--Text-color---D, #565656);\n  font-size: 12px;\n}\n\n.not-tab-button {\n  cursor: pointer;\n  transition: all 0.3s ease; /* Smooth transition for the button */\n}\n\n.tab-content-wrapper {\n  display: flex;\n}\n\n.tab-content {\n  opacity: 0;\n  width: 100%;\n  transition: opacity 0.5s ease;\n}\n\n.tab-content.active {\n  opacity: 1;\n  width: 100%;\n  transition: opacity 0.5s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
