import React, { useEffect, useState, useRef } from "react";
import {
  PAIconFilter,
  PAIconInvoiceIcon,
  PAIconOutlineSearch,
  PAIconDropdown,
  PAIconOption,
} from "assets/images/svgs";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import * as XLSX from "xlsx";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import Table from "layout/table/Table";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { useDispatch } from "react-redux";
import { allDirectFunding } from "appstate/invoice/invoiceSlice";
import { remainder } from "appstate/collections/collectionSlice";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";

import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import InvoiceDisbursementModal from "../components/modals/DisbursementInvoiceModal";

const DirectFunding = () => {
  const [selectedTab, setSelectedTab] = useState("single");

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [selectAllText, setSelectAllText] = useState("Select All");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectDownloadType, setSelectDownloadType] = useState(false);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [bulkbuyermodal, setBulkBuyermodal] = useState(false);

  const directFundingData = {
    data: [
      {
        id: 1,
        refNo: "R3456X89Y",
        invoiceNumber: "2090",
        buyerCompanyName: "Access Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invoiceAmount: 9000000,
        discountAmount: 950000,
        discountRate: "1.5%",
        fundableAmount: 8150000,
      },

      {
        id: 2,
        refNo: "R3456Y89Z",
        invoiceNumber: "2080",
        buyerCompanyName: "Providus Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invoiceAmount: 9000000,
        discountAmount: 90000,
        discountRate: "1.5%",
        fundableAmount: 1350000,
      },

      {
        id: 3,
        refNo: "R3456X89Y",
        invoiceNumber: "2090",
        buyerCompanyName: "Access Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invoiceAmount: 9000000,
        discountAmount: 950000,
        discountRate: "1.5%",
        fundableAmount: 8150000,
      },

      {
        id: 4,
        refNo: "R3456X89Y",
        invoiceNumber: "2090",
        buyerCompanyName: "Access Bank",
        dueDate: "2023-01-12",
        paymentDate: "2023-07-12",
        currency: "NGN",
        invoiceAmount: 9000000,
        discountAmount: 950000,
        discountRate: "1.5%",
        fundableAmount: 8150000,
      },
    ],
  };

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dispatch = useDispatch();

  const [searchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;

  const handleBulkConfirm = () => {
    setBulkBuyermodal(false);
  };

  useEffect(() => {
    dispatch(
      allDirectFunding({
        page,
        pageSize,
        bulk: "",
        status: "APPROVED",
        search: searchQuery,
      })
    );
  }, [dispatch, page, pageSize, searchQuery]);

  const formatAmount = (amount) => {
    if (!amount) return "0.00";
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount)) return "0.00";
    return parsedAmount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleConfirm = () => {
    setIsAcceptModalOpen(false);
    setSelectedInvoice(null);
    const selectedInvoiceIds = selectedRows.map((id) => id);
    dispatch(
      remainder({ type: "directfunding", body: selectedInvoiceIds[0] })
    ).then((response) => {
      if (response?.payload?.status === 200 && response?.payload?.success) {
        setIsSuccessModalOpen(true);
        setSuccessMessage(response?.payload?.message);
      }
    });
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  // SEND REMAINDER BUTTON
  const handleSendReminder = () => {
    setBulkBuyermodal(true);
  };

  // CHECKBOX
  const handleCheckboxChange = (row) => {
    const isSelected = selectedRows.includes(row.bidAmount);
    let newSelectedRows = [];

    if (isSelected) {
      newSelectedRows = selectedRows.filter(
        (bidAmount) => bidAmount !== row.bidAmount
      );
    } else {
      newSelectedRows = [...selectedRows, row.bidAmount];
    }

    setSelectedRows(newSelectedRows);
  };

  const dummyData = [
    {
      bidAmount: 1,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 2,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 3,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 4,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 5,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 6,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "INV-2090",
      Supplier: "XYZ Ventures",

      DueDate: "2023-05-23",
      Requestdate: "2023-05-31",
      Currency: "NGN",
      InvoiceAmount: "9,000,000",
      DiscountAmount: "950,000",
      DiscountRate: "1.5%",
      PaymentAmount: "8,150,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
  ];

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
      setSelectAllText("Select All");
    } else {
      let allIds = [];
      allIds = dummyData.map((row) => row.bidAmount);
      setSelectedRows(allIds);
      setSelectAllText("Deselect All");
    }
    setSelectAll(!selectAll);
  };

  // starts from here

  const [dropdown, setDropdown] = useState(false);

  const dropdownRef = useRef();

  const bulkdummyData = [
    {
      bidAmount: 1,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "1",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 2,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "2",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 3,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "2",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 4,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "1",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 5,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "3",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },

    {
      bidAmount: 6,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "3",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 7,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "1",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
    {
      bidAmount: 8,
      RequestRefNo: "R3456X89Y",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      InvoiceNumber: "XYZ Ventures",
      Supplier: "2023-05-23",

      DueDate: "5",
      Requestdate: "2",
      Currency: "56,037,700",
      InvoiceAmount: "12,384,48",
      DiscountAmount: "1.4%",
      DiscountRate: "1.5%",
      PaymentAmount: "44,870,000",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Agba Dev",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",
    },
  ];
  const navigate = useNavigate();

  const bulkColumns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref. No.
        </span>
      ),
      selector: (row) => row.RequestRefNo,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Funder
        </span>
      ),
      selector: (row) => row.InvoiceNumber,
      sortable: true,
    },
    {
      name: <span className="whitespace-nowrap">Request Date</span>,
      selector: (row) => row.Supplier,
      sortable: true,
    },
    {
      name: "No. of invoices",
      selector: (row) => row.DueDate,
      sortable: true,
    },
    {
      name: "Tier",
      selector: (row) => row.Requestdate,
      sortable: true,
    },
    {
      name: "Invoice amount",
      selector: (row) => row.Currency,
      sortable: true,
      cell: (row) => <p className="text-success">{row.Currency}</p>,
    },
    {
      name: "Discount amount",
      selector: (row) => row.InvoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-[#F08000]">{row.InvoiceAmount}</p>,
    },

    {
      name: "Discount Rate",
      selector: (row) => row.DiscountAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.DiscountAmount}</p>,
    },

    {
      name: "Payment Amount",
      selector: (row) => row.PaymentAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.PaymentAmount}</p>,
    },
    {
      cell: (row) => (
        <div>
          <PAIconOption title="Options" onClick={() => {}} />
        </div>
      ),
      width: "5rem",
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
      } else {
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  //ends here

  const singleRequestColumns = [
    {
      name: (
        <input type="checkbox" onChange={handleSelectAll} checked={selectAll} />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(row)}
          checked={selectedRows.includes(row.bidAmount)}
        />
      ),
      width: "50px",
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Request Ref. No.
        </span>
      ),
      selector: (row) => row.RequestRefNo,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.InvoiceNumber,
      sortable: true,
    },
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr1 inline" src="/group-icon.svg" alt="" />
          Supplier
        </span>
      ),
      selector: (row) => row.Supplier,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.DueDate,
      sortable: true,
    },
    {
      name: "Request Date",
      selector: (row) => row.Requestdate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.Currency,
      sortable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.InvoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.InvoiceAmount}</p>,
    },

    {
      name: "Discount Amount",
      selector: (row) => row.DiscountAmount,
      sortable: true,
      cell: (row) => <p className="text-[#F08000]">{row.DiscountAmount}</p>,
    },
    {
      name: "Discount Rate",
      selector: (row) => row.DiscountRate,
      sortable: true,
      cell: (row) => <p className="text-success">{row.DiscountRate}</p>,
    },
    {
      name: "Payment Amount",
      selector: (row) => row.PaymentAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.PaymentAmount}</p>,
    },
    {
      cell: (row) => (
        <div>
          <PAIconOption title="Options" onClick={() => {}} />
        </div>
      ),
      width: "5rem",
    },
  ];

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      directFundingData.data.map((row) => ({
        "Program Ref No": row?.refNo,
        "Invoice Number": `INV-${row?.invoiceNumber}`,
        Buyer: row?.buyerCompanyName,
        "Due Date": row?.dueDate,
        "Payment Date": row?.paymentDate,
        Currency: row?.currency,
        "Invoice Amount": formatAmount(row?.invoiceAmount),
        "Discount Amount": formatAmount(row?.discountAmount),
        "Discount Rate": formatAmount(row?.discountRate),
        "Fundable Amount": formatAmount(row?.fundableAmount),
      }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Direct Funding");
    XLSX.writeFile(wb, "DirectFundingData.xlsx");
  };

  return (
    <>
      {/* top search layer starts */}
      <InvoiceDisbursementModal
        isOpen={bulkbuyermodal}
        onConfirm={handleBulkConfirm}
      />
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div>
            <div
              className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-2 text-sm focus:outline-none cursor-pointer"
              onClick={handleDropdownToggle}
            >
              Filter{" "}
              {selectedFilters.length > 0 && (
                <span className="bg-gray rounded-lg p-1 px-2">
                  {selectedFilters.length}
                </span>
              )}{" "}
              Selected <PAIconDropdown />
            </div>
            {isDropdownOpen && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                <label className="block">
                  <input
                    type="checkbox"
                    value="date"
                    checked={selectedFilters.includes("date")}
                    onChange={handleFilterChange}
                  />
                  Date
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="name"
                    checked={selectedFilters.includes("name")}
                    onChange={handleFilterChange}
                  />
                  Name
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="currency"
                    checked={selectedFilters.includes("currency")}
                    onChange={handleFilterChange}
                  />
                  Currency
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="amount"
                    checked={selectedFilters.includes("amount")}
                    onChange={handleFilterChange}
                  />
                  Amount
                </label>
              </div>
            )}
          </div>
          <div>
            <select
              className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
              onChange={(e) => {}}
            >
              <option value="">Sort by</option>
              <option value="date">Date</option>
              <option value="name">Name</option>
            </select>
          </div>
          <button className="py-2 px-4 rounded-[5px] bg-white border-[#DEDEDE] shadow-sm mr-2 ">
            Export
          </button>
        </div>
        <div className="flex md:col-span-1 justify-end">
          {/* <SearchInput /> */}
          {/* starts from here */}
          <div>
            <div className="flex items-center justify-between gap-5">
              <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                <div className="items-center flex bg-white">
                  <PAIconOutlineSearch />
                  <input
                    className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                    type="text"
                    placeholder="Search for due invoices awaiting collection"
                  />
                </div>
                <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                  <PAIconFilter className="bg-[#2FA06A]" />
                </div>
              </div>
              <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                <PAIconFilter /> Filter
              </button>
            </div>
          </div>

          {/* ends here */}
        </div>
      </div>

      <div>
        {!directFundingData?.data?.length ? (
          <div>
            <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
              <p className="text-black text-sm font-medium">
                Direct Early Payment Invoices
              </p>
            </div>

            <TableEmptyState dashboard />
          </div>
        ) : (
          <Card className="rounded-[10px] min-h-[80vh] mt-[15px]">
            <div className="flex justify-between items-center p-[20px] bg-alabasterHeader rounded-t-[10px]">
              <div className="flex gap-6 items-center">
                <div>
                  <p className="text-black text-lg font-bold">
                    Direct Early Payment Invoices
                  </p>
                  <span className="text-xs font-thin">
                    List of all Early Payment invoices from your supplier
                  </span>
                </div>
              </div>

              <div className="flex items-center justify-center gap-3">
                <div
                  className=" text-xs rounded-md py-2.5 px-4 text-white bg-[#2EA923] cursor-pointer"
                  onClick={handleSelectAll}
                  // checked={selectAll}
                >
                  <p>{selectAllText}</p>
                </div>
                <button
                  style={{ boxShadow: "2px 3px 10px 0px rgba(0, 0, 0, 0.10)" }}
                  className={`text-xs  rounded-md py-2.5 px-4 text-white ${
                    selectedRows.length === 0
                      ? "cursor-not-allowed bg-[#C2C0C0]"
                      : "cursor-pointer bg-secondaryColor"
                  }`}
                  disabled={selectedRows.length === 0}
                  onClick={handleSendReminder}
                >
                  <p>Make Payment</p>
                </button>
              </div>
            </div>
            <div className="pb-[24px]">
              <div className="pb-[24px]">
                <div className="flex px-[24px] gap-5 my-5">
                  <p
                    className={`cursor-pointer text-sm font-bold relative ${
                      selectedTab === "single"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => setSelectedTab("single")}
                  >
                    Single Request
                    {selectedTab === "single" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>

                  <p
                    className={`cursor-pointer text-sm font-bold relative ${
                      selectedTab === "bulk"
                        ? "text-[#F08000]"
                        : "text-[#959595]"
                    }`}
                    onClick={() => setSelectedTab("bulk")}
                  >
                    Bulk Request
                    {selectedTab === "bulk" && (
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#F08000] h-0.5 w-5"></span>
                    )}
                  </p>
                </div>
                {selectedTab === "bulk" ? (
                  <Table
                    columns={bulkColumns}
                    data={bulkdummyData}
                    onRowClicked={(row) => {
                      navigate(
                        `/disbursement/detail/disbursementbulkdirectfundingpagedetails/${row.bidAmount}`
                      );
                    }}
                    progressComponent={<Loading />}
                    pointer
                    tableHeader
                    className="mt-5"
                  />
                ) : (
                  <Table
                    columns={singleRequestColumns}
                    data={dummyData}
                    onRowClicked={(row) =>
                      navigate(
                        `/disbursement/detail/disbursementdirectfundingdetails/${row.bidAmount}`
                      )
                    }
                    progressComponent={<Loading />}
                    pointer
                    tableHeader
                    className="mt-5"
                  />
                )}
                <div className="flex justify-center gap-x-[8px] mt-[20px]">
                  {directFundingData?.data?.length > 0 ||
                    (directFundingData?.data?.length > 0 && (
                      <Pagination
                        page={page}
                        startPage={startPage}
                        setEndPage={setEndPage}
                        endPage={endPage}
                        setStartPage={setStartPage}
                        setPage={setPage}
                        totalPages={directFundingData?.length}
                      />
                    ))}
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>

      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Would you like to proceed with sending a{" "}
              <span className="font-bold text-center text-primaryColor">
                Pay
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      <AcceptModal
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        message={
          <>
            Would you like to proceed with sending a <br />
            <span className="text-[#07593D] font-bold">
              Payment reminder
            </span>{" "}
            {selectedRows.length > 1 ? (
              <span>to all selected due invoices?</span>
            ) : (
              <>
                to due{" "}
                <span className="text-secondaryColor font-bold">
                  invoice {selectedInvoice?.invoiceReference}
                </span>
                ?
              </>
            )}
          </>
        }
        onConfirm={handleConfirm}
      />

      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={handleCloseSuccessModal}
        message={successMessage}
      />

      {/* Export Invoice List */}
      <AnimatePresence>
        {selectDownloadType && (
          <ViewDocumentModal
            icon={<PAIconInvoiceIcon className=" animate-pulse" />}
            onAction={exportToExcel}
            onClose={() => setSelectDownloadType(false)}
          >
            <span className=" text-center">
              Are you sure you want to{" "}
              <span className="font-bold text-center text-primaryColor">
                Export
              </span>{" "}
              this invoice list
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </>
  );
};

export default DirectFunding;
