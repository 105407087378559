import DashboardLayout from 'layout/dashboardlayout'
import React from 'react'
import Invoice from './invoice'

const BulkEarlyPaymentDetailsInvoice = () => {
  return (
    <DashboardLayout
      section='Invoice'
      subHeader='See all invoices'
    >
      <Invoice />
    </DashboardLayout>
  )
}

export default BulkEarlyPaymentDetailsInvoice