import { PAIconTransferFund } from "assets/images/svgs";
import React from "react";
import FundCard from "./card";

const FundWalletDetails = ({ isOpen, onClose, account }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb4">
          <p className="text-black font-sans text-base font-bold p-3 px-5 rounded-sm italic">
            Fund Account
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        <div className="border border-gray mb-5 px-5"></div>

        <div className="flex flex-col items-center justify-center">
          <PAIconTransferFund />
          <div className="text-neutral-800 text-sm font-bold">
            Fund Wallet Through Bank Transfer
          </div>
          <div className="text-center text-neutral-500 text-xs mt-5 mb-7">
            Transfer fund to your wallet by sending money to the account below
          </div>
          <FundCard
            accountName={account?.accountName}
            bankName={account?.bankName}
            accountNumber={account?.accountNumber}
            // accountType={account?.accountType}
          />
        </div>
      </div>
    </div>
  );
};

export default FundWalletDetails;
