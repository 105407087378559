import PayEdge from "../../assets/images/svgs/PayEdge13.svg";
import Illustration from "../../assets/images/svgs/IllustrationM.svg";
import Button from "shared/Button";
import { useState, useEffect } from "react";
import { ResendVerificationEmail } from "appstate/api/auth/onboarding";
import toast from "react-hot-toast";

const ResendVerificationLink = () => {
    const [user, setUser] = useState({ email: "", fullName: "" });

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const email = params.get("email");
        const fullName = params.get("fullName");
        setUser({ email, fullName });
    }, []);

    const handleResendLink = async () => {
        try {
            const response = await ResendVerificationEmail(user.email);
            setUser(response?.data);
        } catch (error) {
            toast.error("Error resending verification email");
        }
    };

    return (
        <div className="w-full">
            <div className="flex justify-center">
                <img src={PayEdge} alt='' />
            </div>
            <div className="bg-[#07593D] p-[30px] mt-[50px]">
                <h2 className="text-center text-white font-bold text-xl">Welcome to Payedge</h2>
            </div>
            <div className="mt-20 flex justify-center">
               <img src={Illustration} alt='' />
            </div>
            <div className="mt-10 w-[95%] mx-auto">
                <h3 className="font-bold text-black text-xl">Hi {user?.fullName},</h3>
                <p className="text-xl text-[#565656]">
                    The verification link has expired; however, we are ready to resend it promptly. 
                    Click on the button below so we can send you a verification link.
                </p>
            </div>
            <div className="mt-20 flex justify-center">
                <Button onClick={handleResendLink}>Resend verification link</Button>
            </div>
            <div className="mt-10">
                <h2 className="font-bold text-black text-center text-xl">The PayEdge Team.</h2>
            </div>
        </div>
    );
};

export default ResendVerificationLink;
