import React from "react";

const SignModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg">
      <div className="bg-white p-5 px5 rounded-md max-w-xs md:max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <p className="text-black font-sans text-base font-semibold">
            Agreement Signed
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        <div className="border border-gray my-5"></div>

        {/* FILE TEXT */}   
        <div className="w-full">
            <img src="/undraw_happy_announcement.png" alt="/" className="mx-auto my-3" />
          <div className="text-[#222] font-sans text-sm font-normal tracking-wide">
          Thank you for choosing to sign this agreement. Your electronic signature signifies your commitment. You've successfully signed the agreement. Thank you
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignModal;
