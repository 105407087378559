// import { PAIconCancelRound } from "assets/images/svgs";
import { motion } from "framer-motion";
// import toast from "react-hot-toast";

function RightSideModal({ closeModal, children }) {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          delay: 0.3,
        },
      }}
      onClick={() => closeModal()}
      className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[9999] flex justify-end items-center'
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        // initial={{
        //   scale: 0,
        // }}
        initial={{
          x: 20,
          opacity: 0,
          transition: {
            duration: 0.3,
          },
        }}
        animate={{
          x: 0,
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{
          x: 20,
          opacity: 0,
          transition: {
            duration: 0.3,
          },
        }}
        className={`bg-white w-full md:w-[65%] lg:w-[45%] h-full overflow-auto  z-[9999]`}
      >
        <motion.div>
          <motion.div
            exit={{
              x: 20,
              opacity: 0,
              transition: {
                duration: 0.3,
              },
            }}
            className='z-[9999] h-full flex flex-col justify-start w-full gap-8 pt-[3%] pb-[6%] '
          >
            {children}
            {/* <div className=' h-[20%] flex flex-col'>
              <header className='h-[50%] sticky bg-white top-0 z-[999999] flex py-[32px] justify-between'>
                <span className='flex items-center font-bold '>{title}</span>
                <span
                  onClick={() => closeModal()}
                  className='cursor-pointer gap-2 flex items-center justify-center '
                >
                  <PAIconCancelRound />
                </span>
              </header>

              <div className='h-[80%]'>
                <main className='h-[85%] overflow-auto'>{children}</main>

                <footer className=' h-[15%] text-white flex justify-end '>
                  <button
                    onClick={() => handleSave()}
                    className=' px-4 py-2 bg-[#07593D] rounded-[5px] border-none cursor-pointer'
                  >
                    {button}
                  </button>
                </footer>
              </div>
            </div> */}
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}

export default RightSideModal;
