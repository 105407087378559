import React from 'react';
import WalletAdd from '../../../../../../assets/images/svgs/wallet-add.svg';

const VirtualSelectedAccount = ({ selectedAccount, onClose, handleChangeAccount }) => {
    
    return (
        <div className="w-[858px] fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
            <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
                <div className='flex justify-between mb-4'>
                    <h2 className="text-xl font-semibold">Transfer Funds</h2>
                </div>
                <div className='flex justify-between mb-4'>
                    <div>
                       <h2 style={{fontSize: "18px", fontWeight: "600px", lineHeight: "21px"}} className="text-xl font-semibold">Accounts</h2>
                       <p style={{fontSize: '12px', fontWeight: '600px', lineHeight: "14px", color: '#858258'}}>This virtual account would be used to fund this early payment</p>
                    </div>
                    <button 
                        onClick={handleChangeAccount}
                        style={{ 
                            borderColor: "#DE1515", 
                            color: "#DE1515", 
                            borderWidth: "1px", 
                            borderStyle: "solid", 
                            borderRadius: "8px",
                            padding: "2px 4px"
                        }}
                        className="flex items-center justify-center"
                    >
                        <img src={WalletAdd} alt="Wallet Add Icon" className="w-5 h-5 mr-2" />
                        <span className='pr-2' style={{textAlign: "center"}}>Change Account</span>
                    </button>
                </div>
                <div className="mb-4">
                    <h2 className="text-gray-600">You have selected the following virtual account for funding:</h2>
                </div>
                <div className="space-y-4">
                    <div className="flex-1 p-4 border rounded-lg bg-green-50 border-green-500">
                        <h3 className="font-medium text-[#076344]">
                            {selectedAccount.accountName}
                        </h3>
                        <p className="text-[#076344]">
                            {selectedAccount.accountNumber}
                        </p>
                        <div className="flex justify-between text-[#0a8555]">
                            <span>{selectedAccount.bank}</span>
                            <span>{selectedAccount.accountType}</span>
                        </div>
                    </div>
                </div>
                <br />
                <div className='flex justify-between'>
                    <button
                        className="py-2 px-8"
                        style={{ 
                            borderColor: "#DE1515", 
                            color: "#DE1515", 
                            borderWidth: "1px", 
                            borderStyle: "solid", 
                            borderRadius: "8px",
                            padding: "8px 16px"
                        }}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button 
                        className="py-2 px-8" 
                        style={{ backgroundColor: "#F08000", color: "white" }}
                        onClick={onClose}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VirtualSelectedAccount;
