// src/features/kyc/kycActions.js
import { setBankDetailsUploaded, setKycDocumentUploaded, setAgreementDocumentAccepted } from './verificationSlice';

// Action to update bank details status
export const updateUploadBankDetails = (status) => (dispatch) => {
  dispatch(setBankDetailsUploaded(status));
};

// Action to update KYC document upload status
export const updateUploadKycDocument = (status) => (dispatch) => {
  dispatch(setKycDocumentUploaded(status));
};

// Action to update agreement document  status
export const updateAgreementDocumentAccepted = (status) => (dispatch) => {
  dispatch(setAgreementDocumentAccepted(status));
};
