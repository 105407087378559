import Disassociate from "../../../../assets/images/svgs/disassociate.svg";
import UserAdd from "../../../../assets/images/svgs/userdis.svg";
import Edit from "../../../../assets/images/svgs/edit-main.svg";

const CardDisassociation = () => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-3 w-48">
      <div className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 p-2 rounded">
        <img src={UserAdd} alt="View Details" />
        <span>View Details</span>
      </div>
      <div className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 p-2 rounded">
        <img src={Edit} alt="Change Tier" />
        <span>Change Tier</span>
      </div>
      <hr className="my-2" />
      <div className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 p-2 rounded">
        <img src={Disassociate} alt="Disassociate" />
        <span style={{color: "#DE1515"}}>Disassociate</span>
      </div>
    </div>
  );
};

export default CardDisassociation;
