import Modal from "layout/modal/Modal";
import React from "react";
import DeclineModalContent from ".";

function DeclineModal({
  isShown,
  onClose,
  handleAssociate,
  warning,
  isLoading,
  handleChange,
  success,
  info,
}) {
  return (
    <Modal contrast isShown={isShown} hide={onClose} accountModal>
      <DeclineModalContent
        isLoading={isLoading}
        success={success}
        info={info}
        hide={onClose}
        handleAssociate={handleAssociate}
        handleChange={handleChange}
        warning={warning}
      />
    </Modal>
  );
}

export default DeclineModal;
