import DashboardLayout from "layout/dashboardlayout";
import { PAIconArrowLeftGreen, PAIconDownload } from "assets/images/svgs";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { extractDate } from "utilities/helper/dateFormater";

function NewBulkDirectFundingInvoiceDetails() {
  const { newDirectFundingData, directFundingInvoiceId } = useSelector(
    (state) => state?.funder
  );
  const invoiceDetails = newDirectFundingData?.data[0]?.invoice?.find(
    (invoiceId) => invoiceId.id === directFundingInvoiceId
  );
  return (
    <DashboardLayout>
      <div className="flex flex-col gap-8  pb-24  bg-white rounded-[10px]">
        <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
          <div>
            <Link
              to="/invoice/invoice"
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconArrowLeftGreen />
              <span className="flex items-center text-primaryColor">
                Go Back
              </span>
            </Link>
          </div>
          <div className="flex justify-between items-center">
            <h1 className="text-black font-medium underline">Invoices</h1>
          </div>
        </header>

        <div className="px-6">
          <div className="flex flex-col gap-3">
            <header className="flex justify-between items-center">
              <h1 className="text-[18px] font-bold">
                Invoice | INV-{invoiceDetails?.invoiceNumber}
              </h1>
              <div className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]">
                Partial Payment
              </div>
            </header>

            <main className=" w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex gap-x-[5px]">
                  <span>Reference No:</span>
                  <span>{invoiceDetails?.invoiceReference}</span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {extractDate(invoiceDetails?.createdDate)}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs">Payment Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceDetails?.dueDate}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="text-sm flex flex-col justify-end items-end px-[35px] italic">
                  <p className="italic font-bold">New Invoice Amount</p>
                  <p className="text-[#07A04A] font-bold">
                    NGN {invoiceDetails?.subTotal}
                  </p>
                  <p className="text-secondaryColor font-semibold line-through">
                    NGN {invoiceDetails?.total}
                  </p>
                  <p className="text-secondaryColor">+1.67%</p>
                </div>
              </div>
            </main>
          </div>

          <div className="flex justify-between my-5">
            <div className="flex flex-col">
              <span className="font-bold text-sm">Payment</span>
              <span className="text-[#979797]  text-xs">60 Days</span>
            </div>
            <div className="flex flex-col">
              <span className=" font-bold text-sm">Currency</span>
              <span className="text-[#979797]  text-xs">
                {invoiceDetails?.currency}
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-[18px] font-bold">Supplier/buyer details</h1>
            </header>
            <div className="flex  justify-between mb-5">
              <div className="flex gap-2">
                {/* <PAIconCocacola /> */}
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">Supplier</span>
                  <span className="text-[#979797]  text-xs">
                    {invoiceDetails?.supplierCompanyName}
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                {/* <PAIconModelOne /> */}
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">Buyer</span>
                  <span className="text-[#979797]  text-xs">
                    {invoiceDetails?.buyerCompanyName}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Line Items</h1>
            </header>
            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Line Items</h1>
              </header>
              <div className=" w-full overflow-x-auto">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-subtleGray w-">
                    <tr className=" bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Tax rate
                      </td>
                      <td className=" p-2.5 text-xs font-bold">Item name</td>
                      <td className=" p-2.5 text-xs font-bold">
                        Product/Service Description
                      </td>
                      <td className=" p-2.5 text-xs font-bold">Quantity</td>
                      <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                      <td className=" p-2.5 text-xs font-bold">
                        Line Item Extended Amount
                      </td>
                    </tr>
                  </thead>
                  {invoiceDetails?.items?.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.taxRate}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.itemName}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.description}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.quantity}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.unitPrice}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item?.amount}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-5">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Attachments</h1>
            </header>
            <div className="w-full overflow-x-auto">
              <table className="w-full overflow-x-auto">
                <thead className="  bg-subtleGray w-">
                  <tr className="px-4  bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                      Document Name
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">
                      File Type
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">
                      Upload Date / Time
                    </td>
                    <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className=" py-[3px] px-1.5">
                    <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                      DOC-29384.pdf
                    </td>
                    <td className=" text-xs text-[#11192A] px-4 py-2.5">PDF</td>
                    <td className=" text-xs text-[#11192A] px-4 py-2.5">
                      2021-09-14 13:25
                    </td>
                    <td className="cursor-pointer text-xs text-[#11192A] px-4 py-2.5">
                      <PAIconDownload />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default NewBulkDirectFundingInvoiceDetails;
