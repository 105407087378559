import { PAIconCancel, PAIconPayEdgeLogo } from "assets/images/svgs";
import React from "react";
import { useNavigate } from "react-router-dom";

const Heading = ({ cancel }) => {
  const navigate = useNavigate();
  return (
    <div className="flex sticky top-0 justify-between px-[18px] lg:px-[42px]  py-4  min-h-[60px] z-[99999] items-center border-b border-primaryColor bg-[#F1F1F1]">
      <div onClick={() => navigate("/")} className="cursor-pointer text-2xl">
        <PAIconPayEdgeLogo />
      </div>
      {cancel ? (
        <PAIconCancel onClick={cancel} className="cursor-pointer" />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Heading;
