// import { PAIconVieweye } from "assets/images/svgs";
import {
  PAIconFiller,
  PAIconFilter,
  PAIconOutlineSearch,
} from "assets/images/svgs";
import TransactionModal from "features/transactions/transactionDashboard/TransactionModal";
import Table from "layout/table/Table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";

// Dummy data
const dummyTransactions = [
  {
    name: "John Doe",
    company: "Tech Solutions",
    email: "john.doe@example.com",
    phone: "+1234567890",
    tier: "Gold",
  },
  {
    name: "Jane Smith",
    company: "Innovate Inc.",
    email: "jane.smith@example.com",
    phone: "+1234567891",
    tier: "Silver",
  },
  {
    name: "Alice Johnson",
    company: "Creative Minds",
    email: "alice.johnson@example.com",
    phone: "+1234567892",
    tier: "Bronze",
  },
  {
    name: "Bob Brown",
    company: "Enterprise Corp.",
    email: "bob.brown@example.com",
    phone: "+1234567893",
    tier: "Platinum",
  },
];

const ConnectedPlayersTable = () => {
  const navigate = useNavigate();
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

//   function handleViewMore(transactionId) {
//     navigate(`/funds&portfolio/portfolioinvoice/${transactionId}`);
//   }
function handleViewMore() {
    navigate(`/funder-suppliers/supplierdetails`);
  }

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.company,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Account Teir",
      selector: (row) => row.tier,
      sortable: true,
    },
  ];

  return (
    <>
      <Card className="rounded-[10px] mt[24px]">
        <div className="flex flex-col md:flex-row justify-between items-center px-[24px] bg-alabasterHeader p-2 h-auto lg:h[60px] rounded-t-[10px]">
          <div className="flex flexrow lg:flex-row flex-wrap items-center lg:items-center justify-between gap3 lg:gap-0">
            <div>
              <p className="text-black text-sm font-medium">Funded Invoices</p>
              <p className="text-[10px]">All funded invoices</p>
            </div>
            <div className="border border-[#7A7A7A] text-[14px] px-4 py-2 rounded-md flex items-center justify-center cursor-pointer shadow-md ml-5">
              <span className="mr-2">
                <PAIconFiller />
              </span>
              Suppliers
            </div>
          </div>
          {/* SEARCH */}
          <div className="flex justify-end mt-4 md:mt-0 w-full md:w-auto">
            <div className="flex flex-col lg:flex-row items-center justify-between gap-3 lg:gap-5 w-full lg:w-auto">
              <div className="border border-transparent lg:border-gray lg:shadow-sm rounded-[5px] px-2 w-full lg:w-[300px] bg-white flex items-center">
                <PAIconOutlineSearch />
                <input
                  className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                  type="text"
                  placeholder="Search for request"
                  // value={searchQuery}
                  // onChange={(e) => setSearchQuery(e.target.value)}
                  // onKeyPress={(e) => {
                  //   if (e.key === "Enter") {
                  //     handleSearch();
                  //   }
                  // }}
                />
                <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                  <PAIconFilter className="bg-[#2FA06A]" />
                </div>
              </div>
              <button className="hidden lg:flex bg-[#F2F2F2] border border-1 border-[#CCC] rounded-md py-2 px-4 items-center gap-2">
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="px-[24px] pb-[24px]">
          <Table
            columns={columns}
            data={dummyTransactions}
            onRowClicked={(row) => handleViewMore(row.transactionId)}
            pointer
            tableHeader
            className="mt-5"
          />
        </div>
      </Card>
      {selectedTransactionId && (
        <TransactionModal
          transactionId={selectedTransactionId}
          onClose={() => setSelectedTransactionId(null)}
        />
      )}
    </>
  );
};

export default ConnectedPlayersTable;
