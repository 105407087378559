import React from "react";
import { PAIconCautionClear } from "assets/images/svgs";
import { motion } from "framer-motion";

const DeleteDocumentModal = ({
  onCloseDeleteModal,
  text,
  deleteDoc,
}) => {
  const handleCloseDeleteModal = () => {
    deleteDoc();
    onCloseDeleteModal();
  };

  const handleDelete = () => {
    onCloseDeleteModal();
  };

  return (
    <motion.div>
      <motion.div className="bg-slate-600/20 fixed inset-0 flex items-center justify-center z-50">
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-overlay"
        ></motion.div>
        <motion.div
          onClick={(e) => e.stopPropagation()}
          initial={{
            scale: 0,
          }}
          animate={{
            scale: 1,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              delay: 0.1,
            },
          }}
          className="modal-container ml-16 bg-white p-4 rounded-lg shadow-lg z-50 w-2/6"
        >
          <div className="modal-header mx-4 mb-6 mt-6 flex justify-center items-center">
            <PAIconCautionClear />
          </div>
          <div className="text-center">
            <p className="text-[#7A7A7A] text-base ">{text}</p>
            <div className="modal-footer text-center pt-12">
              <button
                onClick={() => handleCloseDeleteModal()}
                className="btn bg-[#07593D] text-white mr-4 rounded-md px-6 py-2"
              >
                Yes
              </button>
              <button
                onClick={() => handleDelete()}
                className="btn border border-red-500 text-[red] rounded-md px-6 py-2"
              >
                No
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default DeleteDocumentModal;
