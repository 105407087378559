import { PAIconCancelRound } from "assets/images/svgs";
import Drawer from "features/openMarket/supplier/components/modals/Drawer";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { allPermissions } from "appstate/roleManager/roleManagerSlice";
function EditAssignedProcessesModal({ isOpen, setIsOpen }) {
  const dispatch = useDispatch();
  const testData = [
    {
      name: "Invoice Management",
      children: [
        { name: "Child 1.1" },
        { name: "Child 1.2" },
        { name: "Child 1.3" },
      ],
    },
    {
      name: "Payment Management",
      children: [{ name: "Child 2.1" }, { name: "Child 2.2" }],
    },
  ];

  useEffect(() => {
    dispatch(allPermissions());
  }, [dispatch]);

  const [parentCheckboxes, setParentCheckboxes] = useState(
    testData?.map(() => false)
  );
  const [childCheckboxes, setChildCheckboxes] = useState(
    testData?.map((parent) => parent.children.map(() => false))
  );

  // Function to handle parent checkbox change
  const handleParentCheckboxChange = (parentIndex) => {
    const newParentCheckboxes = [...parentCheckboxes];
    newParentCheckboxes[parentIndex] = !newParentCheckboxes[parentIndex];
    setParentCheckboxes(newParentCheckboxes);

    const newChildCheckboxes = [...childCheckboxes];
    newChildCheckboxes[parentIndex] = newChildCheckboxes[parentIndex]?.map(
      () => newParentCheckboxes[parentIndex]
    );
    setChildCheckboxes(newChildCheckboxes);
  };

  // Function to handle individual child checkbox change
  const handleChildCheckboxChange = (parentIndex, childIndex) => {
    const newChildCheckboxes = [...childCheckboxes];
    newChildCheckboxes[parentIndex][childIndex] =
      !newChildCheckboxes[parentIndex][childIndex];
    setChildCheckboxes(newChildCheckboxes);

    const allChildrenChecked = newChildCheckboxes[parentIndex]?.every(
      (checkbox) => checkbox
    );
    const newParentCheckboxes = [...parentCheckboxes];
    newParentCheckboxes[parentIndex] = allChildrenChecked;
    setParentCheckboxes(newParentCheckboxes);
  };

  // Generate the array based on the current checkbox state
  // const currentStateArray = testData.map((parent, parentIndex) => ({
  //   name: parent.name,
  //   children: parent.children.map((child, childIndex) => ({
  //     name: child.name,
  //     isChecked: childCheckboxes[parentIndex][childIndex],
  //   })),
  // }));

  // useEffect(() => {
  //   console.log(currentStateArray);
  // }, [currentStateArray]);
  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='flex flex-col'>
        <header className='flex justify-between p-6'>
          <span className=' font-bold text-[18px] text-[#222]'>
            Edit assigned processes
          </span>
          <PAIconCancelRound
            className=' cursor-pointer'
            onClick={() => setIsOpen(false)}
          />
        </header>

        <main className='flex flex-col gap-[19px] px-7 text-sm text-[#7A7A7A]'>
          <section>
            Assign a workflow to a process by selecting the process
          </section>

          <section className=' flex flex-col gap-3'>
            <header className='text-sm text-[#565656] font-bold'>
              All Processes
            </header>

            <div className=' py-7 px-5 bg-[#F9F9F9] overflow-auto min-h-[64vh]  max-h-[64vh]'>
              <div className='flex flex-col gap-3'>
                {testData?.map((parent, parentIndex) => (
                  <div key={parentIndex} className='flex flex-col '>
                    <label className='flex gap-2 items-center border-t text-sm text-[#222] font-bold border-b border-[#DADADA] py-1 px-2'>
                      <input
                        type='checkbox'
                        checked={parentCheckboxes[parentIndex]}
                        onChange={() => handleParentCheckboxChange(parentIndex)}
                      />
                      {parent?.name}
                    </label>

                    {parent?.children?.map((child, childIndex) => (
                      <label
                        className='flex items-center gap-2 text-[#565656] text-xs py-1 px-8'
                        key={childIndex}
                      >
                        <input
                          type='checkbox'
                          checked={childCheckboxes[parentIndex][childIndex]}
                          onChange={() =>
                            handleChildCheckboxChange(parentIndex, childIndex)
                          }
                        />
                        {child?.name}
                      </label>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </section>

          <div className=' '>
            <motion.button
              whileTap={{ scale: 0.9 }}
              className=' bg-primaryColor text-white py-2 px-4 text-sm rounded-[5px]'
            >
              Save
            </motion.button>
          </div>
        </main>
      </div>
    </Drawer>
  );
}

export default EditAssignedProcessesModal;
