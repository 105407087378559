import { PAIconArrowLeft, PAIconEye, PAIconVieweye } from "assets/images/svgs";
import OnboardingLayout from "features/onboarding/category/components/OnboardingLayout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import PasswordLength from "shared/PasswordLength";
import { useFormik } from "formik";
import { corporatePasswordValidationSchema } from "validation/funder/CorporatePasswordSchema";
import { corporateFunderSetPassword } from "appstate/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "shared/Spinner/Spinner";

const CooperateFunderPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, funderId } = useSelector((state) => state?.auth);
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const calculateStrength = (value) => {
    let strength = 0;
    // Check if the password contains at least one number
    if (/\d/.test(value)) {
      strength++;
    }
    // Check if the password contains at least one special character
    if (/[!@#$%^&*]/.test(value)) {
      strength++;
    }
    // Check if the password contains at least one uppercase letter
    if (/[A-Z]/.test(value)) {
      strength++;
    }
    // Check if the password length is between 6 and 8 characters
    if (value.length >= 6) {
      strength++;
    }
    return strength;
  };

  const formik = useFormik({
    initialValues: {
      value: "",
      confirmPassword: "",
    },
    validationSchema: corporatePasswordValidationSchema,
    onSubmit: (values) => {
      const body = {
        value: values.value,
      };
      dispatch(corporateFunderSetPassword({ body, userId: funderId })).then(
        (data) => {
          if (data?.payload?.status === 200) {
            let path = "/funder/Cooperate-Funder/terms";
            navigate(path);
          }
        }
      );
    },
  });

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const goback = () => {
    let path = -1;
    navigate(path, { state: funderId });
  };

  return (
    <OnboardingLayout>
      <div className="pt-[30px] px-[60px] h-[100%] lg:px-[50px] overflow-auto">
        <PAIconArrowLeft className="cursor-pointer" onClick={goback} />
        <div className="flex flex-col h-[90%] items-center justify-center">
          <h1 className="font-bold text-[28px] text-primaryColor text-center">
            Welcome to PayEdge Co-operate Funder Onboarding
          </h1>
          <p className="font-semibold text-[20px] text-textColor text-center  mt-[10px]">
            Before you get started
          </p>
          <p className="font-normal text-sm text-textColor text-center">
            Create a password
          </p>
          <form
            onSubmit={formik.handleSubmit}
            className="flex items-center flex-col"
          >
            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none  md:w-[424px] placeholder:text-[40px]"
                placeholder="........"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="value"
                name="value"
                onChange={formik.handleChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                value={formik.values.value}
                autoComplete="new-password"
              />
              {showPassword ? (
                <PAIconVieweye
                  onClick={toggleShowPassword}
                  className="float-right mr-[6px] mt-[-30px] relative z-2 hover:cursor-pointer"
                />
              ) : (
                <PAIconEye
                  onClick={toggleShowPassword}
                  className="float-right mr-[6px] mt-[-30px] relative z-2 hover:cursor-pointer"
                />
              )}
              <div className="flex justify-between mt-[10px]">
                <PasswordLength
                  className={`strength-bar ${isFocused ? "block" : "hidden"} ${
                    calculateStrength(formik.values.value) === 1
                      ? "bg-red"
                      : calculateStrength(formik.values.value) === 2
                        ? "bg-yellow-500"
                        : calculateStrength(formik.values.value) === 3
                          ? "bg-yellow-500"
                          : calculateStrength(formik.values.value) >= 4
                            ? "bg-green-500"
                            : "bg-red-500"
                  }`}
                />
                <PasswordLength
                  className={`strength-bar ${isFocused ? "block" : "hidden"} ${
                    calculateStrength(formik.values.value) === 2
                      ? "bg-yellow-500"
                      : calculateStrength(formik.values.value) === 3
                        ? "bg-yellow-500"
                        : calculateStrength(formik.values.value) >= 4
                          ? "bg-green-500"
                          : ""
                  }`}
                />
                <PasswordLength
                  className={`strength-bar ${isFocused ? "block" : "hidden"} ${
                    calculateStrength(formik.values.value) === 3
                      ? "bg-yellow-500"
                      : calculateStrength(formik.values.value) >= 4
                        ? "bg-green-500"
                        : ""
                  }`}
                />
                <PasswordLength
                  className={`strength-bar ${isFocused ? "block" : "hidden"} ${
                    calculateStrength(formik.values.value) >= 4
                      ? "bg-green-500"
                      : ""
                  }`}
                />
              </div>
              {formik.touched.value && formik.errors.value ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.value}
                </p>
              ) : null}
            </div>
            <div className="mb-[30px]">
              <InputField
                className="w-[300px] py-[13px] outline-none  md:w-[424px] placeholder:text-[40px]"
                placeholder="........"
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              {showConfirmPassword ? (
                <PAIconVieweye
                  onClick={toggleShowConfirmPassword}
                  className="float-right mr-[6px] mt-[-30px] relative z-2 hover:cursor-pointer"
                />
              ) : (
                <PAIconEye
                  onClick={toggleShowConfirmPassword}
                  className="float-right mr-[6px] mt-[-30px] relative z-2 hover:cursor-pointer"
                />
              )}
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.confirmPassword}
                </p>
              ) : null}
            </div>
            <Button
              type="submit"
              auth
              disabled={isLoading}
              className="mb-[30px] w-full"
            >
              {isLoading ? <Spinner /> : "Next"}
            </Button>
          </form>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default CooperateFunderPassword;
