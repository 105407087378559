import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  assignRolePermission,
  createNewRole,
  deleteMember,
  getAllMembers,
  getAllPermissions,
  getAllPermissionsAssigned,
  getAllRoles,
  getAllSubPermissions,
  inviteTeamMember,
  onboardNewInvite,
  roleUpdate,
} from "appstate/api/roleManager";

const initialState = {
  isLoading: false,
  updateRoleLoading: false,
  allRolesData: [],
  allPermissionAssignedData: [],
  allPermissionsData: [],
  allSubPermissionsData: [],
  roleId: null,
  saveInviteData: [],
  memberId: null,
  allMembersData: [],
};

export const createRole = createAsyncThunk(
  "rolemanager/createRole",
  createNewRole
);

export const allRoles = createAsyncThunk(
  "rolemanager/getAllRoles",
  getAllRoles
);

export const assignPermission = createAsyncThunk(
  "rolemanager/assignPermission",
  assignRolePermission
);

export const allPermissionAssigned = createAsyncThunk(
  "rolemanager/allPermissionAssigned",
  getAllPermissionsAssigned
);

export const allPermissions = createAsyncThunk(
  "rolemanager/allPermissions",
  getAllPermissions
);

export const allSubPermissions = createAsyncThunk(
  "rolemanager/allSubPermissions",
  getAllSubPermissions
);

export const teamMemberInvite = createAsyncThunk(
  "rolemanager/teamMemberInvite",
  inviteTeamMember
);

export const newInviteOnboard = createAsyncThunk(
  "rolemanager/newInviteOnboard",
  onboardNewInvite
);

export const memberDelete = createAsyncThunk(
  "rolemanager/memberDelete",
  deleteMember
);

export const updateRole = createAsyncThunk(
  "rolemanager/updateRole",
  roleUpdate
);

export const allMembers = createAsyncThunk(
  "rolemanager/allMembers",
  getAllMembers
);

export const roleManagerSlice = createSlice({
  name: "rolemanager",
  initialState,
  reducers: {
    getRoleId: (state, action) => {
      state.roleId = action.payload;
    },
    getMemberId: (state, action) => {
      state.memberId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createRole.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createRole.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createRole.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allRoles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allRoles.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allRolesData = action.payload;
    });
    builder.addCase(allRoles.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignPermission.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignPermission.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignPermission.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allPermissionAssigned.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allPermissionAssigned.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPermissionAssignedData = action.payload;
    });
    builder.addCase(allPermissionAssigned.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allPermissions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allPermissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allPermissionsData = action.payload;
    });
    builder.addCase(allPermissions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(allSubPermissions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allSubPermissions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allSubPermissionsData = action.payload;
    });
    builder.addCase(allSubPermissions.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(teamMemberInvite.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(teamMemberInvite.fulfilled, (state, action) => {
      state.isLoading = false;
      state.saveInviteData = action.payload;
    });
    builder.addCase(teamMemberInvite.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(newInviteOnboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(newInviteOnboard.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(newInviteOnboard.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(memberDelete.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(memberDelete.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(memberDelete.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateRole.pending, (state) => {
      state.updateRoleLoading = true;
    });
    builder.addCase(updateRole.fulfilled, (state) => {
      state.updateRoleLoading = false;
    });
    builder.addCase(updateRole.rejected, (state) => {
      state.updateRoleLoading = false;
    });
    builder.addCase(allMembers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allMembers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allMembersData = action.payload;
    });
    builder.addCase(allMembers.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const { getRoleId, getMemberId } = roleManagerSlice.actions;
export default roleManagerSlice.reducer;
