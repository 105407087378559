import {
  PAIconBasilOutline,
  PAIconBasilOutlineWhite,
  PAIconFluentBoxBrown,
  PAIconFluentBoxPurple,
  PAIconLockOutline,
  PAIconOutlineFunds,
  PAIconOutlineFundsWhite,
  PAIconSolarWalletOutline,
  PAIconSquareArrowUp,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React from "react";
import Table from "./table";
import LockedInvoice from "./lockedInvoice";
import { useNavigate } from "react-router-dom";

const FundsAndPortfolioDashboard = () => {

  const navigate = useNavigate();

  return (
    <DashboardLayout
      section="Portfolio Dashboard"
      subHeader="Here is an overview of your invoices"
    >
      <div className="rounded-lg bg-white shadow-md">
        {/* FIRST DATA */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 py-3 px-5 gap-3">
          <div className="my2">
            <h5 className="text-[#7A7A7A] text-base font-medium mb-1">
              Portfolio Value
            </h5>
            <span className="text-sm font-medium text-[#4B4B4B]">
              Wallet Balance
            </span>
            <h2 className="text-[36px] font-bold my2 text-[#07593D]">
              N1,775,224 <br />
              <span className="text-[#7A7A7A] text-sm mt-[-7px] mb5 block">
                $1,222
              </span>
            </h2>
            <span className="inline-flex items-center text-[#07593D] text-sm border rounded px-2 py-1 mt-2">
              view analysis <PAIconSquareArrowUp className="ml-2" />
            </span>
          </div>

          <div className="my-2 rounded-md bg-[rgba(172,131,84,0.20)] p-3">
            <div className="flex justify-between items-center">
              <PAIconOutlineFunds />
              <span className="ml-2 cursor-pointer">
                <PAIconBasilOutline />
              </span>
            </div>
            <h5 className="text-sm py-2">Funded Invoices</h5>
            <h5 className="text-[40px] font-bold leading-normal">
              235 <span className="text-[10px]">invoices</span>
            </h5>
            {/* <div className="flex justify-end">
              <div className="w-1/4 h-2 bg-green-500 rounded-full"></div>
            </div> */}
          </div>

          <div className="my-2 rounded-md bg-[#4B4EAC33] p-3">
            <div className="flex justify-between items-center">
              <PAIconSolarWalletOutline />
              <span className="ml-2 cursor-pointer">
                <PAIconBasilOutline />
              </span>
            </div>
            <h5 className="text-sm py-2">Total Investment Amount</h5>
            <h5 className="text-[36px] font-bold leading-normal">N2,345,800</h5>
            {/* <div className="flex justify-end">
              <div className="w-1/4 h-2 bg-green-500 rounded-full"></div>
            </div> */}
          </div>

          <div className="my-2 rounded-md bg-[#1CAE5F33] p-3">
            <div className="flex justify-between items-center">
              <PAIconLockOutline />
              <span className="ml-2 cursor-pointer">
                <PAIconBasilOutline />
              </span>
            </div>
            <h5 className="text-sm py-2">Locked Invoices</h5>
            <h5 className="text-[40px] font-bold leading-normal">
              14 <span className="text-[10px]">invoices</span>
            </h5>
            {/* <div className="flex justify-end">
              <div className="w-1/4 h-2 bg-green-500 rounded-full"></div>
            </div> */}
          </div>
        </div>
      </div>

      {/* SECOND DATA */}
      <div className="grid md:grid-cols-2 gap-3 py-3">
        <div className="bg-[#07593D] rounded-md text-white">
          <div className="px-5 py-3 flex items-center justify-between">
            <div className="flex items-center justify-between w-full">
              <PAIconOutlineFundsWhite />
              <span className="ml-2 cursor-pointer">
                <PAIconBasilOutlineWhite />
              </span>
            </div>
          </div>
          {/* TOTAL REVENUE */}
          <div className="px-5 py-3 grid grid-cols-2 gap-7">
            <div className="w-full border-r border-gray-300 pr-5">
              <div className="flex items-center justify-between">
                <header>Total Revenue</header>
                <select className="border border-white bg-[#07593D] text-white rounded px2 cursor-pointer w-16">
                  {[
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "June",
                    "July",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ].map((month, index) => (
                    <option key={index} value={index + 1}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              <h5 className="text-[20px] md:text-[23px] lg:text-[32px] font-bold leading-normal">
                N2,345,800
              </h5>
            </div>
            <div>
              <p>Pending ROI</p>
              <h5 className="text-[20px] md:text-[23px] lg:text-[32px] font-bold leading-normal">
                N347,000
              </h5>
              <h5 className="text-[16px] font-bold leading-normal mt-[-7px]">
                14 <span className="text-[10px] font-normal">invoices</span>
              </h5>
            </div>
          </div>
        </div>

        {/* portfolio manangement */}
        <div className="grid grid-cols-2 gap-5">
          <div className="bg-[#656669] rounded-md p-3">
            <div
              className="bg-white p-3 mt-3 text-white flex justify-between cursor-pointer"
              // onClick={handleSubmit}
              onClick={() => navigate("/funds&portfolio/portfoliomanagement")}
            >
              <p className="text-[#656669] text-xs">
                Go to Portfolio management
              </p>
              <br />
              <div className="mt-2">
                <PAIconFluentBoxPurple />
              </div>
            </div>

            <div
              className="bg-white p-3 mt-3 text-white flex justify-between cursor-pointer"
              onClick={() => navigate("/funds&portfolio/connectedplayers")}
            >
              <p className="text-[#656669] text-xs">View Connected Players</p>
              <br />
              <div className="mt-2">
                <PAIconFluentBoxPurple />
              </div>
            </div>
          </div>

          {/* export data */}
          <div className="bg-[#6F5F48] rounded-md p-3">
            <div
              className="bg-white p-3 mt-3 text-white flex justify-between cursor-pointer"
              // onClick={handleSubmit}
            >
              <p className="text-[#656669] text-xs">
                Download Report or <br />
                <span className="font-semibold">Export Data</span>
              </p>
              <br />
              <div className="mt-2">
                <PAIconFluentBoxBrown />
              </div>
            </div>

            <div
              className="bg-white p-3 mt-3 text-white flex justify-between cursor-pointer"
              onClick={() => navigate("/open-market")}
            >
              <p className="text-[#6F5F48] text-xs">Go to Open Market</p>
              <br />
              <div className="mt-2">
                <PAIconFluentBoxBrown />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TABLE */}
      <div
        className="grid grid-cols-2 gap-3"
        style={{ gridTemplateColumns: "60% 40%" }}
      >
        <Table />
        <LockedInvoice />
      </div>
    </DashboardLayout>
  );
};

export default FundsAndPortfolioDashboard;
