import React, { useState, useEffect } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import Loading from "shared/Loading";
import { useNavigate } from "react-router-dom";
import walletEmpty from "assets/images/walletEmpty.png";
import TransactionReceipt from "./TransactionReceipt";

const TransactionTable = () => {
  const navigate = useNavigate();

  const [transactions] = useState([
    {
      transactionId: 1,
      transactionParty: "Party 1",
      transactionType: "Type 1",
      paymentDate: "2022-01-01",
      transactionSign: "Cr",
      currency: "USD",
      amount: "9,000,000",
      status: "SUCCESSFUL",
    },
    {
      transactionId: 2,
      transactionParty: "Party 2",
      transactionType: "Type 2",
      paymentDate: "2022-01-02",
      transactionSign: "Dr",
      currency: "EUR",
      amount: "4,000,000",
      status: "FAILED",
    },
    {
      transactionId: 3,
      transactionParty: "Party 3",
      transactionType: "Type 3",
      paymentDate: "2022-01-03",
      transactionSign: "Cr",
      currency: "GBP",
      amount: "1,000,000",
      status: "PENDING",
    },
  ]);

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // a delay to mimic the loading state
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row.transactionId,
      sortable: true,
      width: "8.3rem",
    },
    {
      name: "Transaction Party",
      selector: (row) => row.transactionParty,
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Transaction Type",
      selector: (row) => row.transactionType,
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Transaction Date",
      selector: (row) => row.paymentDate,
      sortable: true,
      maxWidth: "100%",
    },
    {
      name: "Sign",
      selector: (row) => row.transactionSign,
      sortable: true,
      cell: (row) =>
        row.transactionSign === "Cr" ? (
          <p className="text-success">{row.transactionSign}</p>
        ) : (
          <p className="text-red">{row.transactionSign}</p>
        ),
      width: "7rem",
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "7rem",
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      cell: (row) =>
        row.transactionSign === "Cr" ? (
          <p className="text-success">{row.amount}</p>
        ) : (
          <p className="text-red">{row.amount}</p>
        ),
      width: "7.9rem",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "SUCCESSFUL" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "FAILED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "PENDING" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
      width: "9rem",
    },
  ];

  const handleViewMore = (transaction) => {
    setSelectedTransaction(transaction);
  };

  return (
    <>
      <Card className="rounded-[10px] mt-[24px]">
        <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
          <div>
            <p className="text-black text-sm font-medium">
              Recent Transactions
            </p>
            <p className="text-[10px]">Transactions Summary</p>
          </div>
          {transactions.length > 0 ? (
            <p
              onClick={() => navigate("/wallet/transactions")}
              className="text-black text-sm font-medium underline cursor-pointer"
            >
              View All
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="px-[24px] pb-[24px]">
          {transactions.length > 0 ? (
            <Table
              columns={columns}
              data={transactions}
              progressPending={isLoading}
              progressComponent={<Loading />}
              onRowClicked={(row) => handleViewMore(row.transactionId)}
              pointer
              tableHeader
              className="mt-5 min-h[60vh]"
            />
          ) : (
            <img
              src={walletEmpty}
              className="max-w-[200px] max-h-[200px] mx-auto"
              alt="No transactions found"
            />
          )}
        </div>
      </Card>
      {selectedTransaction && (
        <TransactionReceipt
          transferDetails={selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
          paymentAmount="N9,000,000"
          currency="NGN"
          senderDetails={{
            name: "Adewale Favour Adediwura",
            bank: "Access bank plc",
            accountNumber: "23857369282",
          }}
          beneficiaryDetails={{
            name: "Praise Akobundu Ignatius",
            bank: "PayEdge",
            accountNumber: "45678900987",
          }}
          transactionType="Collections"
          transactionReference="87463938573992834746893"
          description="Thanks Money received"
          paymentDate="23-Dec-2024"
        />
      )}
    </>
  );
};

export default TransactionTable;
