export const currencies = [
  {
    id: 1,
    country: 'Algeria',
    title: 'Algerian Dinar (DZD)',
    currency: 'Algerian Dinar (DZD)',
  },
  {
    id: 2,
    country: 'Egypt',
    title: 'Egyptian Pound (EGP)',
    currency: 'Egyptian Pound (EGP)',
  },
  {
    id: 3,
    country: 'Kenya',
    title: 'Kenyan Shilling (KES)',
    currency: 'Kenyan Shilling (KES)',
  },
  {
    id: 4,
    country: 'Morocco',
    title: 'Moroccan Dirham (MAD)',
    currency: 'Moroccan Dirham (MAD)',
  },
  {
    id: 5,
    country: 'Nigeria',
    title: 'Nigerian Naira (NGN)',
    currency: 'Nigerian Naira (NGN)',
  },
  {
    id: 6,
    country: 'South Africa',
    title: 'South African Rand (ZAR)',
    currency: 'South African Rand (ZAR)',
  },
  {
    id: 7,
    country: 'Tanzania',
    title: 'Tanzanian Shilling (TZS)',
    currency: 'Tanzanian Shilling (TZS)',
  },
  {
    id: 8,
    country: 'Uganda',
    title: 'Ugandan Shilling (UGX)',
    currency: 'Ugandan Shilling (UGX)',
  },
  {
    id: 9,
    country: 'Ethiopia',
    title: 'Ethiopian Birr (ETB)',
    currency: 'Ethiopian Birr (ETB)',
  },
  {
    id: 10,
    country: 'Ghana',
    title: 'Ghanaian Cedi (GHS)',
    currency: 'Ghanaian Cedi (GHS)',
  },

  {
    country: 'Algeria',
    currency: 'Algerian Dinar (DZD)',
  },
  {
    country: 'Egypt',
    currency: 'Egyptian Pound (EGP)',
  },
  {
    country: 'Kenya',
    currency: 'Kenyan Shilling (KES)',
  },
  {
    country: 'Morocco',
    currency: 'Moroccan Dirham (MAD)',
  },
  {
    country: 'Nigeria',
    currency: 'Nigerian Naira (NGN)',
  },
  {
    country: 'South Africa',
    currency: 'South African Rand (ZAR)',
  },
  {
    country: 'Tanzania',
    currency: 'Tanzanian Shilling (TZS)',
  },
  {
    country: 'Uganda',
    currency: 'Ugandan Shilling (UGX)',
  },
  {
    country: 'Ethiopia',
    currency: 'Ethiopian Birr (ETB)',
  },
  {
    country: 'Ghana',
    currency: 'Ghanaian Cedi (GHS)',
  },
];
