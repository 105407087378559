import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InvoiceDetails from "shared/InvoiceDetails";
import LoadingScreen from "shared/LoadingScreen";

const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoiceIdData, isLoading } = useSelector(
    (state) => state?.invoice
  );

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  const data = {
    invoiceNumber: `INV-${invoiceIdData?.data?.invoiceNumber}` || "",
    referenceNumber: invoiceIdData?.data?.invoiceReference || "",
    createdDate: invoiceIdData?.data?.createdDate?.slice(0, 10) || "",
    dueDate: invoiceIdData?.data?.dueDate || "",
    payment: calculatePayment(
      invoiceIdData?.data?.dueDate,
      invoiceIdData?.data?.createdDate
    ),
    currency: invoiceIdData?.data?.currency || "",
    supplier: invoiceIdData?.data?.supplierCompanyName || "",
    buyer: invoiceIdData?.data?.buyerCompanyName || "",
    lineItems:
      invoiceIdData?.data?.items?.map((item) => ({
        itemNumber: item?.itemNumber || "",
        poNumber: item.poNumber || "",
        productNumber: item.productNumber || "",
        productDescription: item.productDescription || "",
        quantity: item.quantity || 0,
        unitPrice: item.unitPrice || "",
        extendedAmount: item.extendedAmount || "",
      })) || [],
    attachments: [
      {
        name: "DOC-29384.pdf",
        fileType: "PDF",
        uploadDateTime: "2021-09-14 13:25",
        downloadUrl: "https://example.com/doc-29384.pdf",
      },
    ],
  };

  return <InvoiceDetails data={data} />;
};

export default Details;
