import { PAIconCancelMini, PAIconInventoryArrowLeft } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
// import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { disputeResolution } from "appstate/helpandsupport/helpandsupportSlice";
import toast, { LoaderIcon } from "react-hot-toast";

const CreateComplaint = () => {
  const { userId } = useSelector((state) => state.auth);
  const roleIndex = userId?.realm_access?.roles?.findIndex((arr) => {
    return (
      arr === "SUPPLIER" ||
      arr === "BUYER" ||
      arr === "FUNDER" ||
      arr === "ADMIN"
    );
  });
  const userRole = userId?.realm_access?.roles[roleIndex]?.toLowerCase();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ticketID, setTicketId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [category, setCategory] = useState("#");
  const [subject, setSubject] = useState("Regarding Invoice Payment");
  const [ticketDetails, setTicketDetails] = useState("");
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const generateTicketId = () => {
    const timestamp = Date.now(); // Current timestamp in milliseconds
    const randomNum = Math.floor(Math.random() * 1000000); // Random number
    const uniqueId = `TICKET-${timestamp}-${randomNum}`;
    setTicketId(uniqueId);
  };

  // console.log(file);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files;
    setFile(selectedFile);
    setFileName(selectedFile[0]?.name);
  };

  useEffect(() => {
    generateTicketId();
  }, []);

  // Modal State
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const handleNavigate = () => {
    navigate("/support");
  };

  const handleSubmitComplaint = (e) => {
    e.preventDefault();
    setSaveModalOpen(true);
  };

  const handleSubmitDispute = () => {
    if (file.length < 1) {
      alert("Please select a file first!");
      return;
    }

    if (category === "#") {
      alert("Please select a complaint category!");
      return;
    }

    setLoading(true);

    const formData = new FormData();

    formData.append("customer", customerName);
    formData.append("category", category);
    formData.append("subject", subject);
    formData.append("ticketDetails", ticketDetails);

    for (let i = 0; i < file.length; i++) {
      formData.append("document", file[i]);
    }

    // console.log(body);
    dispatch(disputeResolution({ body: formData, player: userRole })).then(
      (response) => {
        if (response?.payload?.success) {
          toast.success("Dispute ticket created sucessfully");
          setLoading(false);
          setTicketId("");
          setCustomerName("");
          setCategory("");
          setSubject("");
          setTicketDetails("");
          setFile([]);
          setFileName("");
        }
      }
    );
  };

  return (
    <DashboardLayout section="Support" subHeader="Having problems - help">
      <div className=" min-h-full w-full pb-8">
        <div className="flex min-h-full flex-col gap-3 w-full">
          <div
            onClick={() => handleNavigate()}
            className="flex items-center gap-1 w-fit"
          >
            <PAIconInventoryArrowLeft />
            <span className=" text-[#7a7a7a] text-sm">back</span>
          </div>

          <form
            onSubmit={handleSubmitComplaint}
            className="rounded-[10px]  flex flex-col gap-8 w-full min-min-h-full bg-white"
          >
            <header className="bg-[#fafafa] px-11 rounded-t-[10px] py-7">
              <span className="text-[18px] font-bold">Create Ticket </span>
            </header>

            <main className="flex flex-col  gap-7 px-11 text-sm">
              <section className="flex flex-col gap-4 md:justify-between md:flex-row w-full">
                <div className="flex flex-col w-full md:w-[30%] gap-1 text-[#7A7A7A]">
                  <span>
                    Ticket ID <span className="text-red">*</span>
                  </span>

                  <div className="flex w-full">
                    <input
                      className="bg-[#EFEFEF] w-full rounded-[5px]  outline-none px-5 py-3"
                      placeholder="#940224"
                      type="text"
                      onChange={(e) => setTicketId(e.target.value)}
                      value={ticketID}
                    />
                  </div>
                </div>

                <div className="flex flex-col w-full md:w-[30%] gap-1 text-[#7A7A7A]">
                  <span>
                    Customer <span className="text-red">*</span>
                  </span>

                  <div className="flex w-full">
                    <input
                      className="bg-[#EFEFEF] w-full rounded-[5px]  outline-none px-5 py-3"
                      placeholder="Customer Detail"
                      type="text"
                      onChange={(e) => {
                        setCustomerName(e.target.value);
                      }}
                      value={customerName}
                    />
                  </div>
                </div>

                <div className="flex flex-col w-full md:w-[30%] gap-1 text-[#7A7A7A]">
                  <span>
                    Category <span className="text-red">*</span>
                  </span>

                  <div className="flex w-full">
                    <select
                      className="w-full rounded-[5px] text-black border border-[#F0F0F0] cursor-pointer  outline-none px-5 py-3"
                      name="category"
                      id=""
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                      value={category}
                    >
                      <option disabled value="#">
                        Select Category
                      </option>
                      <option className="cursor-pointer" value="1">
                        1
                      </option>
                      <option className="cursor-pointer" value="2">
                        2
                      </option>
                      <option className="cursor-pointer" value="3">
                        3
                      </option>
                    </select>
                  </div>
                </div>
              </section>

              <section className="flex flex-col gap-4 md:justify-between md:flex-row w-full">
                <div className="flex flex-col w-full md:w-[65%] gap-1 text-[#7A7A7A]">
                  <span>
                    Subject <span className="text-red">*</span>
                  </span>

                  <div className="flex w-full">
                    <input
                      className="w-full rounded-[5px] text-black border border-[#F0F0F0] cursor-pointer  outline-none px-5 py-3"
                      placeholder="Subject"
                      type="text"
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                      value={subject}
                    />
                  </div>
                </div>

                <div className="flex flex-col w-full md:w-[30%] gap-1 text-[#7A7A7A]">
                  <span>Attach Document</span>

                  <div className="flex w-full">
                    <label
                      htmlFor="fileInput"
                      className="min-w-[60%] w-fit rounded-[5px] flex gap-1 text-black border border-[#F0F0F0] cursor-pointer  outline-none px-5 py-3"
                    >
                      {fileName ? (
                        <span className="flex gap-1.5 items-center text-[#7A7A7A]">
                          {file?.length >= 1
                            ? `${fileName} and ${file?.length - 1} others`
                            : ""}{" "}
                          <input
                            type="file"
                            name=""
                            multiple
                            id="fileInput"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setFile([]);
                              setFileName("");
                            }}
                          >
                            <PAIconCancelMini />
                          </button>
                        </span>
                      ) : (
                        <div className="flex">
                          <input
                            type="file"
                            name=""
                            multiple
                            id="fileInput"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                          <svg
                            width="24"
                            height="21"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 8.50098V6.50098C8 4.29184 9.79086 2.50098 12 2.50098C14.2091 2.50098 16 4.29184 16 6.50098V18.501C16 20.7101 14.2091 22.501 12 22.501C9.79086 22.501 8 20.7101 8 18.501V14.001C8 12.6203 9.11929 11.501 10.5 11.501C11.8807 11.501 13 12.6203 13 14.001V16.501"
                              stroke="#141B34"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <span className="font-[500]">Upload</span>
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </section>

              <section className="flex flex-col gap-4 md:justify-between md:flex-row w-full">
                <div className="flex flex-col w-full gap-1 text-[#7A7A7A]">
                  <span>
                    Ticket Details <span className="text-red">*</span>
                  </span>

                  <div className="flex w-full">
                    <textarea
                      className="w-full placeholder:text-[#BFBFBF] rounded-[5px]  text-black border border-[#F0F0F0] cursorpointer  outline-none px-5 py-3"
                      placeholder="Enter ticket details"
                      type="text"
                      rows={10}
                      onChange={(e) => {
                        setTicketDetails(e.target.value);
                      }}
                      value={ticketDetails}
                    />
                  </div>
                </div>
              </section>
            </main>

            <section className="py-8 px-11 flex justify-end gap-4 bg-[#fafafa] mb-6">
              <motion.button
                whileTap={{ scale: 0.9 }}
                onClick={() => handleNavigate()}
                className="bg-[#F8F8F8] border border-[#ccc] py-3 px-6 rounded-[5px]"
              >
                Discard
              </motion.button>

              <motion.button
                type="submit"
                whileTap={{ scale: 0.9 }}
                className="bg-primaryColor text-white py-3 px-6 rounded-[5px]"
              >
                {loading ? <LoaderIcon /> : "Save"}
              </motion.button>
            </section>
          </form>
        </div>
      </div>

      {/* Save Modal */}
      <AnimatePresence>
        {saveModalOpen && (
          <ViewDocumentModal
            onAction={handleSubmitDispute}
            onClose={() => {
              setSaveModalOpen(false);
            }}
          >
            <span className="text-[#7A7A7A]">
              Are you sure you would like to create this ticket?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
    </DashboardLayout>
  );
};
export default CreateComplaint;
