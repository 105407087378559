import * as Yup from "yup";

export const virtualAccountValidationSchema = Yup.object({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  bank: Yup.string()
    .required("This field is required")
    .matches(/^[a-zA-Z]+$/, "Must only contain alphabetical characters"),
  currency: Yup.string().required("This field is required"),
});
