import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eachVirtualAccount } from "appstate/wallet/walletSlice";
import Spinner from "shared/Spinner/Spinner";

const EditAccountModal = ({ isOpen, onClose, onSubmit, accountId }) => {
  const { isLoading, virtualAccountDetails, virtualAccountId } = useSelector(
    (state) => state?.wallet
  );

  const dispatch = useDispatch();

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    setFormData({
      accountName: virtualAccountDetails?.accountName || "",
      bvn: virtualAccountDetails?.bvn || "",
      accountNumber: virtualAccountDetails?.accountNumber || "",
    });
  }, [virtualAccountDetails]);

  useEffect(() => {
    dispatch(eachVirtualAccount({ accountId: virtualAccountId }));
  }, [dispatch, virtualAccountId]);


  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white p5 px5 rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Edit Virtual Account
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        {/* FORM */}
        {isLoading ? (
          <Spinner color />
        ) : (
          <form className="w-full px-7" onSubmit={handleSubmit}>
            <p className="text-[#7A7A7A] font-sans text-sm font-normal mb-6">
              Step 1
            </p>
            <p className="mb-4 text-[#222] font-sans text-sm font-bold">
              Account Information
            </p>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                Account Name
              </label>
              <input
                type="text"
                name="accountName"
                value={formData?.accountName}
                onChange={handleChange}
                className="border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                BVN
              </label>
              <input
                type="number"
                name="bvn"
                value={formData?.bvn}
                onChange={handleChange}
                minLength={10}
                maxLength={11}
                className="border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none"
              />
            </div>
            <div className="mb-4">
              <label className="text-[#222] font-sans text-sm font-normal mb-2">
                Account Number
              </label>
              <input
                type="number"
                name="accountNumber"
                value={formData?.accountNumber}
                onChange={handleChange}
                className="border w-full py-2 px-3 border-t-0 border-x-0 focus:border-[#222] focus:outline-none text-gray"
                disabled
              />
            </div>

            {/* Buttons */}
            <div className="flex justify-end mt-10">
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-gray300 mx-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                >
                  Cancel
                </button>
              </div>
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]">
                <button
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                  type="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditAccountModal;
