import React, { useEffect, useState } from "react";
import Recipient1 from "./Recipient1";
import Recipient2 from "./Recipient2";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const Tab = ({ onEmailClick, setGetName, recipientName }) => {
  const [activeTab, setActiveTab] = useState("recipient1");
  const [headerState, setHeaderState] = useState([]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const { userDetails } = useSelector((state) => state.auth);
  const userRole = userDetails?.data?.role;

  useEffect(() => {
    switch (userRole) {
      case "SUPPLIER":
        setHeaderState([{ routeOne: "Buyers", routeTwo: "Funders" }]);
        break;
      case "BUYER":
        setHeaderState([{ routeOne: "Suppliers", routeTwo: "Funders" }]);
        break;
      case "FUNDER":
        setHeaderState([{ routeOne: "Buyers", routeTwo: "Suppliers" }]);
        break;
      default:
        toast.error("Invalid user role:", userRole);
    }
  }, [userRole]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "recipient1":
        return (
          <Recipient1 onEmailClick={onEmailClick} setGetName={setGetName} recipientName={recipientName} />
        );
      case "recipient2":
        return (
          <Recipient2 onEmailClick={onEmailClick} setGetName={setGetName} recipientName={recipientName} />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="border-b border-gray mb-5">
        {headerState.map((tab, index) => (
          <div key={index} className="flex itemscenter justify-between">
            <div
              className={`cursor-pointer mr4 ${
                activeTab === "recipient1" ? "text-[#07593D]" : "text-gray500"
              }`}
              onClick={() => handleTabClick("recipient1")}
            >
              <p className="px-10">{tab?.routeOne}</p>
              {activeTab === "recipient1" && (
                <div className="border-b-2 border-[#07593D] mt-2"></div>
              )}
            </div>
            <div
              className={`cursor-pointer px20 ${
                activeTab === "recipient2" ? "text-[#07593D]" : "text-gray500"
              }`}
              onClick={() => handleTabClick("recipient2")}
            >
              <p className="px-10">{tab?.routeTwo}</p>
              {activeTab === "recipient2" && (
                <div className="border-b-2 border-[#07593D] mt-2"></div>
              )}
            </div>
          </div>
        ))}
      </div>
      {renderTabContent()}
    </div>
  );
};

export default Tab;
