import { PAIconFilter, PAIconOutlineSearch } from "assets/images/svgs";
import Table from "layout/table/Table";
import React from "react";
import Button from "shared/Button";

function StepThree({ handlePreviousPage, handleNextPage }) {
  const column = [
    {
      name: "Invoice Reference",
      selector: (row) => row?.invoiceReference,
    },
    {
      name: "Supplier",
      selector: (row) => row?.supplier,
    },
    {
      name: "Currency",
      selector: (row) => row?.currency,
    },
    {
      name: "Due Date",
      selector: (row) => row?.dueDate,
    },
    {
      name: "Payment Date",
      selector: (row) => row?.paymentDate,
    },
    {
      name: "Invoiced Amount",
      selector: (row) => row?.invoiceAmount,
    },
    {
      name: "Discounted Amt",
      selector: (row) => row?.discountedAmount,
    },
    {
      name: "Payable Amt",
      selector: (row) => row?.payableAmount,
    },
  ];
  const data = [
    {
      invoiceReference: "INV-2090",
      supplier: "NNPC",
      currency: "NGN",
      dueDate: "2023-05-31",
      paymentDate: "2023-01-20",
      invoiceAmount: "9,000,000",
      discountedAmount: "180,000",
      payableAmount: "8,820,000",
    },
    {
      invoiceReference: "INV-2090",
      supplier: "NNPC",
      currency: "NGN",
      dueDate: "2023-05-31",
      paymentDate: "2023-01-20",
      invoiceAmount: "9,000,000",
      discountedAmount: "180,000",
      payableAmount: "8,820,000",
    },
    {
      invoiceReference: "INV-2090",
      supplier: "NNPC",
      currency: "NGN",
      dueDate: "2023-05-31",
      paymentDate: "2023-01-20",
      invoiceAmount: "9,000,000",
      discountedAmount: "180,000",
      payableAmount: "8,820,000",
    },
    {
      invoiceReference: "INV-2090",
      supplier: "NNPC",
      currency: "NGN",
      dueDate: "2023-05-31",
      paymentDate: "2023-01-20",
      invoiceAmount: "9,000,000",
      discountedAmount: "180,000",
      payableAmount: "8,820,000",
    },
    {
      invoiceReference: "INV-2090",
      supplier: "NNPC",
      currency: "NGN",
      dueDate: "2023-05-31",
      paymentDate: "2023-01-20",
      invoiceAmount: "9,000,000",
      discountedAmount: "180,000",
      payableAmount: "8,820,000",
    },
  ];
  return (
    <div className="mx-[3.5rem]">
      <header className="flex justify-between">
        <h1 className="text-textColor text-sm font-bold">
          Number of Suppliers in campaign:{" "}
          <span className="font-medium">20</span>
        </h1>
        <p className="text-textColor text-sm underline">View Members</p>
      </header>
      <p className="text-textColor text-sm mt-[28px]">
        Invoices from Suppliers in campaign
      </p>
      <p className="text-[#BFBFBF] text-xs mt-[8px]">
        Select invoices for the campaign
      </p>
      <div className="border border-brightGray rounded-[0.5rem] px-[1.69rem] pb-[1.69rem] mt-[8px]">
        <div className="flex justify-between">
          <div className="flex items-center gap-x-[8px]">
            <div className="flex gap-x-[8px] border border-silver py-[0.625rem] px-[1.25rem] rounded text-sm">
              <p>Filter</p>
              <span>5</span>
              <p>Selected</p>
            </div>
            <div>
              <select
                className="border border-silver py-[0.625rem] px-[1.25rem] rounded text-sm text-textColor"
                name=""
                id=""
              >
                <option value="">Sort by</option>
              </select>
            </div>
            <Button
              neutral
              // onClick={setOpen ? () => setOpen(true) : handleNavigate}
              className="flex !text-[#565656] gap-2 text-xs font-medium items-center mb-[10px] md:mb-0 border border-[#ccc] md:px-[5px] bg-white py-[12px] px-[24px] rounded-[5px] gap-x-[4px] lg:px-[15px] xl:px-[24px]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.75 14.75H2.25C1.97344 14.75 1.75 14.5266 1.75 14.25V2.75C1.75 2.47344 1.97344 2.25 2.25 2.25H7.875C7.94375 2.25 8 2.30625 8 2.375V3.25C8 3.31875 7.94375 3.375 7.875 3.375H2.875V13.625H13.125V8.625C13.125 8.55625 13.1812 8.5 13.25 8.5H14.125C14.1938 8.5 14.25 8.55625 14.25 8.625V14.25C14.25 14.5266 14.0266 14.75 13.75 14.75ZM12.0448 3.61142L11.2292 2.7958C11.1558 2.72236 11.1995 2.5958 11.3027 2.5833L14.1058 2.25517C14.1855 2.2458 14.2542 2.31298 14.2448 2.39423L13.9167 5.19736C13.9042 5.30048 13.7777 5.34423 13.7042 5.2708L12.8855 4.45205L8.88234 8.45517C8.83391 8.50361 8.75422 8.50361 8.70578 8.45517L8.04328 7.79267C7.99484 7.74423 7.99484 7.66455 8.04328 7.61611L12.0448 3.61142Z"
                  fill="#565656"
                />
              </svg>
              <p className="whitespace-nowrap">Export</p>
            </Button>
          </div>
          <div>
            <div className="flex md:col-span-1 justify-end">
              <div className="flex items-center justify-between gap-5">
                <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                  <div className="items-center flex bg-white">
                    <PAIconOutlineSearch />
                    <input
                      className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                      type="text"
                      placeholder="Search for request"
                      // value={searchQuery}
                      // onChange={(e) => setSearchQuery(e.target.value)}
                      // onKeyPress={(e) => {
                      //   if (e.key === "Enter") {
                      //     handleSearch();
                      //   }
                      // }}
                    />
                  </div>
                  <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                    <PAIconFilter className="bg-[#2FA06A]" />
                  </div>
                </div>
                <button className="hidden lg:flex bg-[#2FA06A] rounded-md my-4 py-2 px-6 mx3 items-center gap-2 text-white">
                  <PAIconFilter /> Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={column}
          data={data}
          pointer
          tableHeader
          className="mt-5"
        />
      </div>
      <div className="flex gap-x-[2.5rem] mt-[1.75rem]">
        <div className="flex gap-x-[5px] items-center">
          <p className="text-lightGray text-[0.875rem]">
            Total Discounted Amount:
          </p>
          <h1 className="text-primaryColor text-[1.125rem] font-bold">
            NGN 1,080,000
          </h1>
        </div>
        <div className="flex gap-x-[5px] items-center">
          <p className="text-lightGray text-[0.875rem]">
            Total Payable Amount:
          </p>
          <h1 className="text-primaryColor text-[1.125rem] font-bold">
            NGN 52,920,000
          </h1>
        </div>
      </div>
      <div className="flex justify-between mt-[1.88rem]">
        <Button
          neutral
          className=" py-[0.75rem] px-[1.5rem] !text-lightGray !text-[0.875rem] bg-alabasterHeader rounded-[0.25rem]"
          onClick={handlePreviousPage}
        >
          Previous
        </Button>

        <Button
          neutral
          className=" py-[0.75rem] px-[1.5rem] !text-white !text-[0.875rem] bg-primaryColor rounded-[0.25rem]"
          onClick={handleNextPage}
        >
          Preview
        </Button>
      </div>
    </div>
  );
}

export default StepThree;
