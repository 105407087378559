import { PAIconCancel, PAIconDoc } from "assets/images/svgs";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";

const UploadModalContent = ({ name, handleRemove }) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    let path = "/invoice/invoice/fundingSection/invoiceupload/validation";
    navigate(path);
  };
  return (
    <div>
      <header className="flex justify-between items-center pb-[20px] px-[30px] pt-[20px]">
        <p>Invoice upload</p>
        <PAIconCancel className="w-[15px]" />
      </header>
      <div className="px-[30px] my-[28px]">
        <div className="flex justify-between items-center bg-bgHeader px-[17px] py-[16px] border-silver border-[.5px] rounded-[4px] ">
          <div className="flex gap-x-[12px] items-center">
            <PAIconDoc />
            <p className="text-sm text-black">{name}</p>
          </div>
          <PAIconCancel onClick={handleRemove} className="w-[9px] cursor-pointer" />
        </div>
      </div>
      <div className="flex justify-end border-t-[1px] border-silver px-[30px] py-[32px]">
        <div>
          <Button
            neutral
            className="bg-[#F8F8F8] border-[1px] border-silver rounded-[5px] py-[12px] px-[24px] !text-black font-medium !text-sm"
          >
            Save for later
          </Button>

          <Button
            neutral
            onClick={handleNavigate}
            className="bg-primaryColor border-[1px] border-silver rounded-[5px] py-[12px] px-[24px] !text-white font-medium !text-sm ml-[10px]"
          >
            Validate
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UploadModalContent;
