import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AcceptModal from "shared/AcceptModal";
import SuccessModal from "shared/SuccessModal";
import { PAIconArrowLeftGreen, PAIconDownload } from "assets/images/svgs";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingScreen from "shared/LoadingScreen";
import { approvalStatus } from "appstate/paymentExtension/paymentExtensionSlice";

const SinglePending = () => {
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isRequestCancelled, setIsRequestCancelled] = useState(false);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  // const { isLoading } = useSelector((state) => state?.paymentExtension);

  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoiceIdData, isLoading } = useSelector((state) => state?.invoice);

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };

  // FORMAT AMOUNT
  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleRemainderClick = () => {
    setIsAcceptModalOpen(true);
  };

  const handleConfirm = () => {
    dispatch(approvalStatus({ id: id, status: "Rejected", comment: "" })).then(
      (response) => {
        if (response?.payload?.status === 200 && response?.payload?.success) {
          // setIsSuccessModalOpen(true);
          setIsAcceptModalOpen(false);
          setIsSuccessModalOpen(true);
          setSuccessMessage("Payment extension request cancelled");
          setIsRequestCancelled(true);
        }
      }
    );
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const dummyData = {
    attachments: [
      {
        name: "Attachment 1",
        fileType: "PDF",
        uploadDateTime: "2024-03-25 10:00 AM",
        downloadUrl: "https://example.com/attachment1.pdf",
      },
    ],
  };

  const { attachments } = dummyData;

  return (
    <DashboardLayout
      section="Payment Extension"
      subHeader="Send and track payment extension processes"
    >
      {isLoading && <LoadingScreen />}

      <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px]">
        <header className="px-6 border-b pt-[17px] rounded-t-[10px] border-[#B1A6B2] pb-[14px] bg-alabasterHeader">
          <div>
            <Link
              onClick={goBack}
              className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer"
            >
              <PAIconArrowLeftGreen />
              <span className="flex items-center text-primaryColor">
                Go Back
              </span>
            </Link>
          </div>
          <div className="flex justify-between items-center">
            <h1 className="text-black font-medium underline">Invoices</h1>
            <div className="flex gap-5">
              {/* Cancel request */}
              {/* <div
                onClick={handleRemainderClick}
                className="rounded-[5px] cursor-pointer border border-red shadow-md text-xs font-medium py-[10px] px-[20px]"
              >
                Payment extension requested.{" "}
                <span className="text-red font-semibold">Cancel?</span>
              </div> */}
              {!isRequestCancelled ? (
                <div
                  onClick={handleRemainderClick}
                  className="rounded-[5px] cursor-pointer border border-red shadow-md text-xs font-medium py-[10px] px-[20px]"
                >
                  Payment extension requested.{" "}
                  <span className="text-red font-semibold">Cancel?</span>
                </div>
              ) : (
                <div
                  className="rounded-[5px] cursor-pointer bg-orange-500 shadow-md text-[#FFF] text-xs font-medium py-[10px] px-[20px]"
                  onClick={() => {}}
                >
                  Payment Extension
                </div>
              )}
              {/* partial payment */}
              <div className="rounded-[5px] cursor-pointer bg-[#07593D] shadow-md text-[#F9F9F9] text-xs font-medium py-[10px] px-[20px]">
                Partial Payment
              </div>
            </div>
          </div>
        </header>

        <div className="px-6">
          <div className="flex flex-col gap-3">
            <header className="flex justify-between items-center">
              <h1 className="text-[18px] font-bold">
                Invoice | INV-{invoiceIdData?.data?.invoiceNumber || "0000"}
              </h1>
              <div className="rounded-md bg-[#CDECDB] py-1.5 px-6">
                <p className="text-[#07A04A] text-center text-xs font-normal">
                  Not Paid
                </p>
              </div>
            </header>

            <main className="w-full flex justify-between">
              <div className="flex flex-col gap-3 w-1/2">
                <div className="flex gap-x-[5px]">
                  <span>Reference No:</span>
                  <span>
                    {invoiceIdData?.data?.invoiceReference || "REF-0000"}
                  </span>
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs w-[97px]">Created Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceIdData?.data?.createdDate?.slice(0, 10) || "0000"}
                    </span>
                  </div>
                  <div className="flex gap-x-[50px]">
                    <span className=" text-xs">Payment Due Date:</span>
                    <span className="text-[#979797]  text-xs">
                      {invoiceIdData?.data?.dueDate || "0000"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center"></div>
            </main>

            <div className="flex justify-between my-5">
              <div className="flex flex-col">
                <span className="font-bold text-sm">Payment</span>
                <span className="text-[#979797]  text-xs">
                  {calculatePayment(
                    invoiceIdData?.data?.dueDate,
                    invoiceIdData?.data?.createdDate
                  )}{" "}
                </span>
              </div>
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Currency</span>
                <span className="text-[#979797]  text-xs">
                  {invoiceIdData?.data?.currency || ""}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-[18px] font-bold">Supplier/buyer details</h1>
            </header>
            <div className="flex  justify-between mb-5">
              <div className="flex gap-2">
                {/* <PAIconCocacola /> */}
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">Supplier</span>
                  <span className="text-[#979797]  text-xs">
                    {invoiceIdData?.data?.supplierCompanyName ||
                      "supplier name here"}
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                {/* <PAIconModelOne /> */}
                <div className="flex flex-col">
                  <span className=" font-bold text-sm">Buyer</span>
                  <span className="text-[#979797]  text-xs">
                    {invoiceIdData?.data?.buyerCompanyName || "buyer name here"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-[18px] font-bold">Line Items</h1>
            </header>
            <div className=" w-full overflow-x-auto">
              <table className=" w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className=" bg-unusualGray p-2.5 ">
                    <td className="text-[#11192A] p-2.5 text-xs font-bold">
                      Item Name
                    </td>
                    <td className=" p-2.5 text-xs font-bold">PO Number</td>
                    <td className=" p-2.5 text-xs font-bold">
                      Product/Service Number
                    </td>
                    <td className=" p-2.5 text-xs font-bold">
                      Product/Service Description
                    </td>
                    <td className=" p-2.5 text-xs font-bold">Quantity</td>
                    <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                    <td className=" p-2.5 text-xs font-bold">
                      Line Item Extended Amount
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {invoiceIdData?.data?.items?.map((item, index) => (
                    <tr key={index} className="py-[3px] px-1.5">
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.itemName}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.poNumber}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.productNumber}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.description}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {item.quantity}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {formatAmount(item.unitPrice)}
                      </td>
                      <td className="text-xs text-[#11192A] p-2.5">
                        {formatAmount(item.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
              <h1 className="text-sm font-bold">Attachments</h1>
            </header>
            <div className="w-full overflow-x-auto">
              <table className="w-full overflow-x-auto">
                <thead className="bg-subtleGray w-">
                  <tr className="px-4 bg-unusualGray p-2.5 ">
                    <td className="px-4 py-2.5 text-xs font-bold">
                      Document Name
                    </td>
                    <td className="px-4 py-2.5 text-xs font-bold">File Type</td>
                    <td className="px-4 py-2.5 text-xs font-bold">
                      Upload Date / Time
                    </td>
                    <td className="px-4 py-2.5 text-xs font-bold">Action</td>
                  </tr>
                </thead>
                <tbody>
                  {attachments.map((attachment, index) => (
                    <tr key={index} className="py-[3px] px-1.5">
                      <td className="text-xs text-[#0076EC] px-4 py-2.5">
                        {attachment.name}
                      </td>
                      <td className="px-4 py-2.5 text-xs">
                        {attachment.fileType}
                      </td>
                      <td className="px-4 py-2.5 text-xs">
                        {attachment.uploadDateTime}
                      </td>
                      <td className="cursor-pointer text-xs px-4 py-2.5">
                        <a
                          href={attachment.downloadUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <PAIconDownload />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <AcceptModal
          isOpen={isAcceptModalOpen}
          onClose={() => setIsAcceptModalOpen(false)}
          message={
            <p>Please confirm if you'd like to cancel the payment extension</p>
          }
          onConfirm={handleConfirm}
        />
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleCloseSuccessModal}
          message={successMessage}
        />
      </div>
    </DashboardLayout>
  );
};

export default SinglePending;
