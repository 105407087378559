import { PAIconUndrawAddFiles } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import React, { useEffect, useState } from "react";
import Button from "shared/Button";
import UploadModal from "./Modals/uploadModal";
import { useDispatch } from "react-redux";
import { getCsvFileData } from "appstate/invoice/invoiceSlice";

const UploadInvoice = () => {
  const [open, setOpen] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;

      const dataArray = text.split("\n").map((row) => row.split(","));

      const headers = dataArray[0];

      const csvData = dataArray.slice(1).map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });
      dispatch(getCsvFileData(csvData));
      setCsvData(csvData);
    };

    reader.readAsText(file);
  };

  const removeFile = () => {
    setCsvData(null);
    setFileName("");
    document.getElementById("fileUpload").value = "";
  };

  useEffect(() => {
    if (csvData) {
      setOpen(true);
    }
  }, [csvData]);

  const downloadCSV = () => {
    const csvData =
      "From, To, Date, Due Date, Item,  Qty, Unit Price, Amount, ";

    const blob = new Blob([csvData], { type: "text/csv" });

    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "invoice.csv";

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  };

  return (
    <DashboardLayout>
      <UploadModal
        name={fileName}
        isShown={open}
        onClose={() => setOpen(false)}
        handleRemove={removeFile}
      />
      <header className="bg-alabasterHeader py-[16px] px-[24px]">
        <h1>Import Invoice</h1>
      </header>
      <div className="px-[24px] pt-[24px] pb-[236px] bg-white border-b border-silver">
        <div className="h-[300px] bg-[#F8FAFB]  border-[1.5px] p-[25px] border-[#E4E7EC] border-dashed flex justify-center items-center">
          <div>
            <PAIconUndrawAddFiles className="mb-[30px]" />
            <div>
              <p className="text-textColor text-xs">Drag file here to </p>
            </div>
            <span className="text-xs text-textColor">upload or </span>
            <label
              for="fileUpload"
              className="relative cursor-pointer text-xs font-medium  border-b border-secondaryColor text-secondaryColor hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span>Upload File</span>
              <input
                id="fileUpload"
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                className="sr-only"
              />
            </label>
          </div>
        </div>
        <div className="flex justify-between items-center gap-x-[23px] py-[34px]">
          <div className="border-b border-silver w-full"></div>
          <p>OR</p>
          <div className="border-b border-silver w-full"></div>
        </div>
        <p className="text-black text-xs mb-[12px]">Upload from URL</p>
        <div className="flex items-center py-[12px] px-[10px] bg-alabasterHeader border border-silver rounded-[5px]">
          <input
            className="w-full h-[30px] outline-none bg-alabasterHeader"
            placeholder="add file URl"
            type="text"
          />
          <Button className="!text-textColor text-xs py-[8px] px-[20px] border-[.5px] border-silver bg-white rounded-[2.5px]">
            Upload
          </Button>
        </div>
      </div>
      <div className="flex gap-x-[16px] py-[32px] px-[17px] bg-white">
        <Button className="!text-black text-xs py-[12px] px-[24px] border-[.5px] border-silver !bg-[#f8f8f8] rounded-[5px] font-medium">
          Download Single Template
        </Button>
        <Button
          onClick={downloadCSV}
          className="!text-black text-xs py-[12px] px-[24px] border-[.5px] border-silver !bg-[#f8f8f8] rounded-[5px] font-medium"
        >
          Download CSV. Template
        </Button>
      </div>
    </DashboardLayout>
  );
};

export default UploadInvoice;
