import request from "apiInstance";
import toast from "react-hot-toast";

export const getFunderOverview = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/payment-extension/funder-overview",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getBuyerOverview = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/payment-extension/buyer-overview",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllPaymentExtension = async ({
  pageSize,
  page,
  status,
  search,
  bulk,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/payment-extensions?size=${pageSize}&page=${page}&status=${status}&bulk=${bulk}&search=${
        search || ""
      }`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getApproval = async ({ id, status, comment }) => {
  try {
    const response = await request({
      method: "get",
      url: `/payment-extension/${id}?status=${status}&comment=${comment}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getSinglePaymentExtension = async ({ id }) => {
  try {
    const response = await request({
      method: "get",
      url: `/payment-extension/${id}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const fetchDisbursedInvoice = async () => {
  try {
    const response = await request({
      method: "get",
      url: "/fund-request/disbursements/invoices",
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const paymentExtension = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/payment-extension",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};