import React, { useState } from "react";
import { PAIconContract } from "assets/images/svgs";
import RouteHeader from "../../RouteHeader";
import SearchInput from "../../SearchInput";
import TemplateDetails from "./TemplateDetails";
import Card from "../ContractCards";

const ContractTemplate = ({ goBack }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  function handleGoBack() {
    setSelectedCard(null);
  }
  const cardItems = [
    {
      id: 1,
      title: "Loan Agreement",
      date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
    },
    {
      id: 2,
      title: "Purchase Contract",
      date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
    },
    {
      id: 3,
      title: "Loan Agreement",
      date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
    },
    {
      id: 4,
      title: "Purchase Contract",
      date: new Date(Date.now()),
    },
    {
      id: 5,
      title: "Loan Agreement",
      date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
    },
    {
      id: 6,
      title: "Purchase Contract",
      date: new Date(Date.now()),
    },
  ];

  const handleCardClick = (id) => {
    setSelectedCard(id);
  };

  // const header = (
  //   <div className="flex grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap4 items-center justify-between">
  //     <RouteHeader Route1="Tools" Heading="All Templates" />
  //     <SearchInput />
  //   </div>
  // );

  const content = selectedCard ? (
    <div className="p4">
      {/* Render content for the selected card */}
      <TemplateDetails goBack={handleGoBack} card={selectedCard} />
    </div>
  ) : (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 lg:grid-cols4 xl:grid-cols4 gap-4 mt-3">
      {/* Render Card component for each item */}
      {cardItems.map((item) => (
        <div
          key={item.id}
          className="w-full cursor-pointer"
          onClick={() => handleCardClick(item.id)}
        >
          <Card
            item={item}
            image="/A4.png"
            icon={<PAIconContract />}
            backgroundColor="bg-[#FFF8F3]"
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className="px-5">
      {selectedCard ? null : (
        <div className="flex grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap4 items-center justify-between">
          <RouteHeader
            Route1="Tools"
            Heading="All Templates"
            handleBack={goBack}
          />
          <SearchInput />
        </div>
      )}
      {content}
    </div>
  );
};

export default ContractTemplate;
