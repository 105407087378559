/* eslint-disable react-hooks/exhaustive-deps */
import {
  allApprovedInvoices,
  getApprovedInvoiceId,
} from "appstate/supplier/supplierSlice";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import Heading from "shared/heading/Heading";
import { extractDate } from "utilities/helper/dateFormater";

const SingleRequestInvoiceList = () => {
  const { approvedInvoicesData, isLoading } = useSelector(
    (state) => state?.supplier
  );
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const [data, setData] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const dispatch = useDispatch();

  const status = "Approved";

  const dummyData = [
    {
      id: 1,
      invoiceReference: "INV001",
      buyerCompanyName: "Company A",
      dueDate: "2024-07-30",
      createdDate: "2024-07-01",
      currency: "USD",
      total: 1000,
      status: "Approved",
    },
    {
      id: 2,
      invoiceReference: "INV002",
      buyerCompanyName: "Company B",
      dueDate: "2024-08-15",
      createdDate: "2024-07-10",
      currency: "EUR",
      total: 2000,
      status: "Pending",
    },
   
  ];

  useEffect(() => {
    setData(dummyData);
    dispatch(allApprovedInvoices({ page, pageSize, status }));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    if (approvedInvoicesData?.data) {
      setData(approvedInvoicesData.data);
    }
  }, [approvedInvoicesData]);

  const navigate = useNavigate();

  const onRowClicked = (row) => {
    dispatch(getApprovedInvoiceId(row?.id));
    let path =
      "/invoice/fundingSection/singlerequestinvoicelist/invoicedetails";
    navigate(path);
  };

  const totalPages = approvedInvoicesData?.meta?.totalElements;

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className=" pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceReference,
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row.buyerCompanyName,
      sortable: true,
    },
    {
      name: "Invoice Due Date",
      selector: (row) => row.dueDate,
      sortable: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => extractDate(row.createdDate),
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.total,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Open" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "Rejected request" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "Updated request" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "Pending request" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  const handleSearch = () => {
    const search = companyName;
    dispatch(allApprovedInvoices({ page, pageSize, status, search })).then(
      (data) => {
        setData(data?.payload?.data);
      }
    );
  };

  return (
    <DashboardLayout>
      <Card className="rounded-lg">
        <div>
          <Heading
            heading={`${data?.length} Approved Invoices`}
            info="Available for payment"
            companyName={companyName}
            setCompanyName={setCompanyName}
            search={handleSearch}
          />
          <div className="rounded-b-lg px-[24px] pb-[24px]">
            <Table
              columns={columns}
              data={data}
              onRowClicked={onRowClicked}
              pointer
              progressPending={isLoading}
              progressComponent={<Loading />}
              tableHeader
              className="mt-5"
            /> 
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {!isLoading && totalPages > 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              )}
            </div>
          </div>
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default SingleRequestInvoiceList;
