import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import InputField from "shared/InputField";
import { useDispatch } from "react-redux";
import { creatNewPfp } from "appstate/buyer/buyerSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const StepOne = ({
  handleNextPage,
  formData,
  errors,
  handleInputChange,
  dynamic,
  reverse,
  handleReverse,
  handleDynamic,
  setuserPfpId,
  isNextDisabled,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function handleNavigate() {
    navigate(-1);
  }

  const handleSubmit = async () => {
    const body = {
      programName: formData.programName,
      startDate: formData.startDate,
      endDate: formData.endDate,
      // tierCategory: formData.tiers,
      financingOptions: formData.financingOptions,
      discountRate: parseFloat(formData.discount),
      minimumAmount: parseFloat(formData.minAmount),
      maximumAmount: parseFloat(formData.maxAmount),
      // funderPercentage: parseFloat(formData.buyerShare),
      // funderIds: formData.funderIds,
    };
    try {
      const resultAction = await dispatch(creatNewPfp(body));
      const result = unwrapResult(resultAction);
      const pfpId = result.data.id;
      setuserPfpId(pfpId);
      handleNextPage();
    } catch (error) {
      // console.error("Failed to create PFP or make the next API call:", error);
    }
  };

  return (
    <div className="w-[31.25rem]">
      <div className="mb-[20px]">
        <InputField
          labelFontSize
          type="text"
          label="Program Name:"
          id="programName"
          name="programName"
          placeholder="Enter name of program"
          className="border border-[#7A7A7A] rounded-[4px] px-[16px] py-[12px] outline-none w-[500px] height-[50px]"
          onChange={handleInputChange}
          value={formData.programName}
        />
        {errors.programName && (
          <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
            {errors.programName}
          </p>
        )}
      </div>
      <div>
        <div className="relative mb-[20px]">
          <div>
            <InputField
              labelFontSize
              id="dynamic"
              type="checkbox"
              label="Select financing option"
              placeholder="Enter name of program"
              onChange={handleDynamic}
              checked={dynamic}
              disabled={reverse}
              className=" h-[16px] absolute top-3  outline-none mb-[10px]"
              value="DYNAMIC_DISCOUNTING"
            />
            <label
              htmlFor="dynamic"
              className="absolute top-6 left-5 text-xs text-gray800"
            >
              Dynamic Discounting
            </label>
          </div>

          <div>
            <InputField
              labelFontSize
              id="reverse"
              type="checkbox"
              onChange={handleReverse}
              checked={reverse}
              value="REVERSE_FACTORING"
              placeholder="Enter name of program"
              disabled={dynamic}
              className=" h-[16px] absolute top-3 left-[150px] outline-none mb-[20px]"
            />
            <label
              htmlFor="reverse"
              className="absolute top-6 left-[170px] text-xs text-gray800"
            >
              Reverse Factoring
            </label>
          </div>
        </div>
        {!dynamic && !reverse && (
          <>
            {errors.financingOptions && (
              <p className="text-red text-xs w-[300px] md:w-[424px] relative bottom-[5px]">
                {errors.financingOptions}
              </p>
            )}
          </>
        )}
      </div>
      <div className="mb-[20px]">
        <InputField
          labelFontSize
          type="date"
          label="Start Date:"
          id="startDate"
          name="startDate"
          placeholder="Enter name of program"
          className="border rounded-[8px] px-[16px] py-[12px] outline-none"
          onChange={handleInputChange}
          value={formData.startDate}
        />
        {errors.startDate && (
          <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
            {errors.startDate}
          </p>
        )}
      </div>
      <div className="mb-[20px]">
        <InputField
          labelFontSize
          type="date"
          label="End Date:"
          id="endDate"
          name="endDate"
          placeholder="Enter name of program"
          className="border rounded-[8px] px-[16px] py-[12px] outline-none "
          onChange={handleInputChange}
          value={formData.endDate}
        />
        {errors.endDate && (
          <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
            {errors.endDate}
          </p>
        )}
      </div>

      {dynamic ? (
        <div>
          <InputField
            labelFontSize
            type="number"
            label="Discount Rate"
            id="discount"
            name="discount"
            placeholder="Discount rate"
            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border rounded-[8px] px-[16px] py-[12px]  outline-none"
            onChange={handleInputChange}
            value={formData.discount}
          />
          {errors.discount && (
            <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
              {errors.discount}
            </p>
          )}
        </div>
      ) : reverse ? (
        <div>
          <InputField
            labelFontSize
            type="number"
            label="Discount Rate"
            id="discount"
            name="discount"
            placeholder="Discount rate"
            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border rounded-[8px] px-[16px] py-[12px]  outline-none"
            onChange={handleInputChange}
            value={formData.discount}
          />
          {errors.discount && (
            <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
              {errors.discount}
            </p>
          )}
        </div>
      ) : null}
      <div className="mt-[10px]">
        <label
          htmlFor=""
          className="font-normal text-sm text-textColor mb-[8px]"
        >
          Amount Range
        </label>
        <div className="flex justify-between">
          <div className="mb-[20px] w-[235px]">
            <InputField
              labelFontSize
              type="number"
              label="Minimum Amount"
              id="minAmount"
              name="minAmount"
              placeholder="min"
              onChange={handleInputChange}
              value={formData.minAmount}
              className="border [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none rounded-[8px] px-[16px] py-[12px]  outline-none w-full"
            />
            {errors.minAmount && (
              <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                {errors.minAmount}
              </p>
            )}
          </div>
          <div className="mb-[20px] w-[235px]">
            <InputField
              labelFontSize
              type="number"
              label="Maximum Amount"
              id="maxAmount"
              name="maxAmount"
              placeholder="max"
              onChange={handleInputChange}
              value={formData.maxAmount}
              className="border [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none rounded-[8px] px-[16px] py-[12px]  outline-none w-full"
            />
            {errors.maxAmount && (
              <p className="text-red text-xs  w-[300px] md:w-[424px] mt-[8px]">
                {errors.maxAmount}
              </p>
            )}
          </div>
        </div>
      </div>
      <p className="text-gray800 text-sm font-[300]">
        <span className="text-[#F08000] text-sm font-semibold">Note</span>: A
        service charge/convivence fee would be add to this program of 25%. The
        service charge is Net discount amount - Service charge
      </p>
      <div className="flex justify-between mt-[1.88rem]">
        <Button
          onClick={handleNavigate}
          type="Button"
          neutral
          className="!text-lightGray text-base !font-normal"
        >
          Cancel
        </Button>
        <Button
          type="Button"
          neutral
          onClick={handleSubmit}
          //   disabled={isNextDisabled}
          className="!text-white !font-normal bg-primaryColor py-[8px] px-[24px] rounded-[5px]"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default StepOne;
