import JsPDF from 'jspdf';
import Card from 'shared/Card'
import toast from 'react-hot-toast';
import Table from 'layout/table/Table'
import { useNavigate } from 'react-router-dom'
import { PAIconDownload } from 'assets/images/svgs';
import RequestConfirmationModal from 'features/invoice/InvoiceModal/RequestConfirmation'
import { INVOICETABCOLUMN } from 'features/invoice/utilities/tableData'

const InvoicesTab = ({ open, setOpen, singleInvoice }) => {

  const generatePDF = () => {
    const invoicePDF = new JsPDF('portrait', 'pt', 'a2');
    invoicePDF.html(document.querySelector('#invoice')).then(() => {
      invoicePDF.save(`${singleInvoice.invoiceReference}.pdf`);
    });
  }

  const INVOICEATTACHMENTCOLUMN = [
    {
      name: 'Invoice Reference',
      selector: row => row.invoiceReference,
      cell: (row) => (
        <p onClick={generatePDF} className="text-[#0076EC] text-[12px] underline hover:cursor-pointer">{`${row.invoiceReference}.pdf`}</p>
      )
    },
    {
      name: 'File Type',
      selector: () => "PDF",
      cell: () => (
        <p>PDF</p>
      )
    },
    {
      name: 'Invoice Due Date',
      selector: row => row.dueDate,
      cell: (row) => (
        <p>{row.dueDate}</p>
      )
    },
    {
      name: 'Action',
      cell: () => (
        <PAIconDownload onClick={generatePDF} className="hover:cursor-pointer" />
      ),
    },
  ]


  const dateDiff = (a, b) => {
    return Math.round((b - a) / (1000 * 60 * 60 * 24))
  }

  function parseDate(str) {
    var mdy = str.split('-');
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);
  }

  const paymentDays = dateDiff(parseDate(singleInvoice.createdDate.split(" ")[0]), parseDate(singleInvoice.dueDate))

  const navigate = useNavigate()

  const handleEditNavigate = () => {
    if (singleInvoice.status === "Paid" || singleInvoice.status === "Cancelled" || singleInvoice.status === "Expired") {
      toast.error(`Cannot edit ${singleInvoice.status} invoice`)
      return
    } else {
      const state = {
        editing: true,
        singleInvoice: singleInvoice,
      };
      let path = `/invoice/edit-invoice/${singleInvoice.id}`;
      navigate(path, { state: state })
    }
  }

  return (
    <div>
      <Card id="invoice" className="px-12 max-[500px]:px-4 py-4 flex flex-col gap-6">
        <RequestConfirmationModal isShown={open} onClose={() => setOpen(false)} />
        <div className='flex flex-col gap-6'>
          <div className='flex justify-between flex-wrap'>
            <p className='text-[#222222] text-[18px] mb-2 font-semibold whitespace-nowrap'>Invoice | INV-{singleInvoice.invoiceNumber}</p>
            <p onClick={handleEditNavigate} className={"rounded-[5px] py-2 px-8 text-[12px] font-medium bg-[#EEE] hover:bg-[#7A7A7A]"}>Edit</p>
          </div>
          <div className='flex flex-wrap max-[560px]:flex-col justify-between items-center gap-6'>
            <div className='flex flex-col max-[560px]:items-center w-1/5'>
              <p className='text-[#222222] text-[18px] mb-2 whitespace-nowrap'>Reference No: {singleInvoice.invoiceReference}</p>
              <div className='flex justify-between items-center gap-4'>
                <p className='text-[#222222 text-[12px] whitespace-nowrap'>Created Date: </p>
                <p className='text-[#7A7A7A] text-[12px] whitespace-nowrap'>{singleInvoice.createdDate.split(" ")[0]}</p>
              </div>
              <div className='flex justify-between items-center gap-4'>
                <p className='text-[#222222 text-[12px] whitespace-nowrap'>Payment Due Date: </p>
                <p className='text-[#7A7A7A] text-[12px] whitespace-nowrap'>{singleInvoice.dueDate}</p>
              </div>
            </div>
            <div>
              <p className={"rounded-[5px] py-2 px-8 text-[12px] " + (singleInvoice.status === "Paid" ? "bg-[#CDECDB] font-medium text-[#07A04A]" : singleInvoice.status === "Cancelled" ? "bg-red-300 font-medium text-red-900" : singleInvoice.status === "Open" ? "bg-[#574D61] font-medium text-white" : singleInvoice.status === "Approved" ? "bg-[#CDECDB] font-medium text-[#07A04A]" : singleInvoice.status === "Expired" ? "bg-red-300 font-medium text-red-900" : "bg-slate-300 font-medium")}>{singleInvoice.status}</p>
            </div>
          </div>
          <div className='flex flex-wrap justify-between'>
            <div className='mr-8'>
              <p className='font-semibold'>Payment</p>
              <p className='text-[#7A7A7A] text-[12px]'>{paymentDays} days</p>
            </div>
            <div className='mr-8'>
              <p className='font-semibold'>Currency</p>
              <p className='text-[#7A7A7A] text-[12px]'>{singleInvoice.currency}</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='border-b-[5px] border-[#F6F6FA]'>
            <p className='text-[18px] font-semibold mb-[5px]'>Supplier/buyer details</p>
          </div>
          <div className='flex flex-wrap justify-between mt-2'>
            <div>
              <p className='text-[14px] font-medium'>Supplier</p>
              <p className='text-[#7A7A7A] text-[12px]'>{singleInvoice.supplier.fullName}</p>
            </div>
            <div className='flex justify-end'>
              <div className='mr-4'>
                <p className='text-[14px] font-semibold'>Buyer</p>
                <p className='text-[#7A7A7A] text-[12px]'>{singleInvoice.buyer.fullName}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='border-b-[5px] border-[#F6F6FA]'>
            <p className='text-[18px] font-semibold mb-[5px]'>Line items</p>
          </div>
          <Table
            columns={INVOICETABCOLUMN}
            data={singleInvoice.items}
            className="mt-4"
          />
        </div>
      </Card>
      <Card className="px-12 max-[500px]:px-4 py-4 flex flex-col gap-6">
        <div className='flex flex-col'>
          <div className='border-b-[5px] border-[#F6F6FA]'>
            <p className='text-[18px] font-semibold mb-[5px]'>Attachments</p>
          </div>
          <Table
            columns={INVOICEATTACHMENTCOLUMN}
            data={[singleInvoice]}
            className="mt-4"
          />
        </div>
      </Card>
    </div>
  )
}

export default InvoicesTab