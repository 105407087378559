import {
  PAIconEditLight,
  PAIconFilledArrowLeft,
  PAIconTrashMini,
} from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ViewDocumentModal from "features/RulesConfig/common/components/modals/DeleteRuleModal";
import toast from "react-hot-toast";
import EditAssignedProcessesModal from "./components/EditAssignedProcessesModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllWorkflow,
  getWorkflowById,
  modifyWorkflowStatus,
} from "appstate/workflow/workflowSlice";
import LoadingScreen from "shared/LoadingScreen";

const ViewDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { isLoading, getWorkflowByIdData } = useSelector(
    (state) => state?.workflow
  );

  const { allRolesData, allMembersData } = useSelector(
    (state) => state?.roleManager
  );

  // .log(getWorkflowByIdData);

  const [, setData] = useState([]);

  // const [toggleEnable, setToggleEnable] = useState(false);
  const [disableWorkflow, setDisableWorkflow] = useState(false);
  const [enableWorkflow, setEnableWorkflow] = useState(false);
  const [deleteWorkflow, setDeleteWorkflow] = useState(false);
  const [editAssignedModal, setEditAssignedModal] = useState(false);
  const [status, setStatus] = useState(false);
  const [workflowName, setWorkflowName] = useState("");

  const [workflowDescription, setWorkflowDescription] = useState("");
  const [approvals, setApprovals] = useState([
    { teamMember: "", role: "", finalApprover: false },
  ]);

  useEffect(() => {
    const getData = async () => {
      const result = await dispatch(getWorkflowById(id));
      setData(result?.payload?.data);
      setWorkflowName(result?.payload?.data?.workflowName);
      setWorkflowDescription(result?.payload?.data?.workflowDescription);
      setStatus(result?.payload?.data?.enabled);

      // if (result?.payload?.data?.enabled) {
      //   setEnableWorkflow(true);
      // } else {
      //   setDisableWorkflow(true);
      // }
    };
    getData();
  }, [dispatch, id]);

  const handleDisable = (id, status) => {
    dispatch(
      modifyWorkflowStatus({
        id,
        status,
      })
    ).then((data) => {
      if (data?.payload?.message === "Workflow updated successfully") {
        const getData = async () => {
          const result = await dispatch(getWorkflowById(id));
          setData(result?.payload?.data);
          setWorkflowName(result?.payload?.data?.workflowName);
          setWorkflowDescription(result?.payload?.data?.workflowDescription);
          setStatus(result?.payload?.data?.enabled);

          // if (result?.payload?.data?.enabled) {
          //   setEnableWorkflow(true);
          // } else {
          //   setDisableWorkflow(true);
          // }
        };
        getData();
        dispatch(getAllWorkflow());
        toast.success("Workflow updated successfully");
      }
    });
  };

  // const removeApproval = (index) => {
  //   const newApprovals = [...approvals];
  //   newApprovals.splice(index, 1);
  //   setApprovals(newApprovals);
  // };

  // const handleFinalApproverChange = (index, value) => {
  //   const newApprovals = [...approvals];
  //   newApprovals[index].finalApprover = value;
  //   setApprovals(newApprovals);
  // };

  return (
    <DashboardLayout
      section="Settings"
      subHeader="Manage your account all in one place"
    >
      <div className="bg-white flex flex-col gap-8 min-h-full py-8 px-6 rounded-[10px]">
        <header>
          <div
            onClick={() => {
              navigate(-1);
            }}
            className=" w-fit text-[#7A7A7A] text-sm cursor-pointer flex gap-1 items-center "
          >
            <span>
              <PAIconFilledArrowLeft />
            </span>
            back
          </div>
        </header>
        <main className=" w-full">
          <section className=" w-full">
            <div className=" w-full font-bold text-[15px] pb-5 border-b border-[#F0F0F0]">
              View workflow
            </div>

            {isLoading ? (
              <LoadingScreen />
            ) : (
              <div className="py-5 flex flex-col gap-5">
                <div className="flex text-sm flex-col gap-2">
                  <span className="text-[#7A7A7A]">Name</span>
                  <span className=" w-full">
                    <input
                      type="text"
                      className=" text-black rounded-[5px] outline-none border border-[#F0F0F0] w-full py-3 px-5"
                      placeholder="Payment"
                      onChange={(e) => {
                        setWorkflowName(e.target.value);
                      }}
                      value={workflowName}
                    />
                  </span>
                </div>

                <div className="flex text-sm flex-col gap-2">
                  <span className="text-[#7A7A7A]">Description</span>
                  <span className=" w-full">
                    <textarea
                      className=" text-black rounded-[5px] outline-none border border-[#F0F0F0] w-full py-3 px-5"
                      placeholder="Description"
                      name="description"
                      id="desc"
                      cols="10"
                      rows="5"
                      value={workflowDescription}
                      onChange={(e) => {
                        setWorkflowDescription(e.target.value);
                      }}
                    ></textarea>
                  </span>
                </div>

                <div className="flex text-sm flex-col gap-2">
                  <span className="text-[#7A7A7A]">Status</span>
                  <span className=" w-full">
                    {status ? "Enabled" : "Disabled"}
                  </span>
                </div>

                <div className="flex text-sm flex-col gap-3">
                  <div className="flex justify-between items-center">
                    <span className="font-bold">Processes Assigned To</span>

                    <span
                      onClick={() => setEditAssignedModal(true)}
                      className="flex gap-2 items-center w-fit cursor-pointer text-[#F08000]"
                    >
                      <PAIconEditLight />
                      Edit Process
                    </span>
                  </div>

                  <div className=" w-full flex flex-wrap gap-3">
                    <span className="bg-[#ECF2F0] cursor-pointer py-2 px-4 rounded-[100px]">
                      Invoices
                    </span>

                    <span className="bg-[#ECF2F0] cursor-pointer py-2 px-4 rounded-[100px]">
                      Open market
                    </span>
                  </div>
                </div>

                <div className="flex text-sm flex-col gap-3">
                  <span className=" font-bold ">Levels of Approval</span>
                  {getWorkflowByIdData?.data?.teamMemberRoles?.map(
                    (approval, index) => (
                      <div key={index} className="w-full flex justify-between">
                        <div className="flex flex-col gap-2 w-[45%]">
                          <span className="text-sm text-[#7A7A7A] w-fit">
                            Role
                          </span>
                          <select
                            style={{ background: "rgba(246, 246, 246, 0.96)" }}
                            name="role"
                            disabled={true}
                            className="cursor-pointer outline-none rounded-[5px] px-5 py-3"
                            value={
                              getWorkflowByIdData?.data?.teamMemberRoles[index]
                            }
                            onChange={(e) => {
                              const newApprovals = [...approvals];
                              newApprovals[index].role = e.target.value;
                              newApprovals[index].teamMember = "";
                              setApprovals(newApprovals);
                            }}
                          >
                            <option className="py-3" value="">
                              Select Role
                            </option>
                            {allRolesData?.data?.data?.map((role) => (
                              <option
                                className="py-4"
                                key={role?.id}
                                value={role?.id}
                              >
                                {role?.roleName}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="flex justify-center flex-col gap-2 ">
                          <span className=" text-white">car</span>
                          <span className="text-sm flex items-center w-fit">
                            or
                          </span>
                        </div>

                        <div className="flex flex-col gap-2 w-[45%]">
                          <span className="text-sm text-[#7A7A7A] w-fit">
                            User
                          </span>
                          <select
                            style={{ background: "rgba(246, 246, 246, 0.96)" }}
                            name="user"
                            disabled={true}
                            className="cursor-pointer outline-none rounded-[5px] px-5 py-3"
                            value={
                              getWorkflowByIdData?.data?.teamMemberIds[index] ||
                              ""
                            }
                            onChange={(e) => {
                              const newApprovals = [...approvals];
                              newApprovals[index].teamMember = e.target.value;
                              newApprovals[index].role = "";
                              setApprovals(newApprovals);
                            }}
                          >
                            <option className="py-3" value="">
                              Select User
                            </option>
                            {allMembersData?.data?.data?.map((user, index) => (
                              <option
                                className="py-4"
                                key={user?.id}
                                value={
                                  getWorkflowByIdData?.data?.teamMemberIds[
                                    index
                                  ]
                                }
                              >
                                {user?.email}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="flex flex-col justify-end">
                          {/* <div className=" text-sm text-white">ee</div> */}
                          {/* <div className="flex">
                            <button
                              className="text-sm flex items-center px-5 py-3  text-red-600"
                              onClick={() => removeApproval(index)}
                            >
                              <PAIconTrashMini />
                            </button>
                          </div> */}
                        </div>
                      </div>
                    )
                  )}
                </div>

                <div className=" flex justify-between">
                  <div className=" w-fit flex gap-5">
                    {status && (
                      <motion.button
                        onClick={() => {
                          setDisableWorkflow(true);
                        }}
                        whileTap={{ scale: 0.9 }}
                        className=" text-sm rounded-[5px] bg-[#D11D1D] text-white py-3 px-6"
                      >
                        Disable
                      </motion.button>
                    )}
                    {!status && (
                      <motion.button
                        onClick={() => {
                          setEnableWorkflow(true);
                        }}
                        whileTap={{ scale: 0.9 }}
                        className=" text-sm rounded-[5px] bg-primaryColor text-white py-3 px-6"
                      >
                        Enable
                      </motion.button>
                    )}
                    <motion.button
                      onClick={() => setDeleteWorkflow(true)}
                      whileTap={{ scale: 0.9 }}
                      className="flex gap-1 items-center justify-center border-[0.1px] border-red text-sm rounded-[5px] text-[#D11D1D] shadow-sm bg-white py-3 px-6"
                    >
                      <PAIconTrashMini /> Delete
                    </motion.button>
                    .
                  </div>

                  <div className=" w-fit flex gap-5">
                    <motion.button
                      whileTap={{ scale: 0.9 }}
                      onClick={() => navigate(-1)}
                      className=" text-sm rounded-[5px] shadow-sm bg-[white] border-[0.1px] border-[#7A7A7A] text-[#7A7A7A] py-3 px-6"
                    >
                      Cancel
                    </motion.button>

                    <motion.button
                      whileTap={{ scale: 0.9 }}
                      onClick={() =>
                        toast.success("Workflow saved successfully ")
                      }
                      className="flex gap-1 items-center justify-center  text-sm rounded-[5px] text-[white]  bg-primaryColor py-3 px-6"
                    >
                      Save
                    </motion.button>
                  </div>
                </div>
              </div>
            )}
          </section>
        </main>
      </div>
      {/* Disable Workflow Modal */}
      <AnimatePresence>
        {disableWorkflow && (
          <ViewDocumentModal
            onAction={() => {
              handleDisable(id, !status);
              // setToggleEnable(true);
              // toast.success("Workflow disabled successfully");
            }}
            onClose={() => setDisableWorkflow(false)}
          >
            <span className=" text-[#979797] text-center">
              Are you sure you would like to disable this workflow?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Enable Workflow Modal */}
      <AnimatePresence>
        {enableWorkflow && (
          <ViewDocumentModal
            onAction={() => {
              handleDisable(id, !status);
              // setToggleEnable(false);
              // toast.success("Workflow enabled successfully");
            }}
            onClose={() => setEnableWorkflow(false)}
          >
            <span className=" text-[#979797] text-center">
              Are you sure you would like to enable this workflow?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>

      {/* Delete Workflow Modal */}
      <AnimatePresence>
        {deleteWorkflow && (
          <ViewDocumentModal
            onAction={() => toast.success("Workflow deleted successfully")}
            onClose={() => setDeleteWorkflow(false)}
          >
            <span className=" text-[#979797] text-center">
              Are you sure you would like to delete this workflow?
            </span>
          </ViewDocumentModal>
        )}
      </AnimatePresence>
      <EditAssignedProcessesModal
        isOpen={editAssignedModal}
        setIsOpen={setEditAssignedModal}
      />
    </DashboardLayout>
  );
};

export default ViewDetails;
