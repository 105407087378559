import React from "react";

const FundersEvaluation = () => {
  return (
    <div className="px-[37px]  h-full ">
      <h2 className="text-sm font-semibold text-black mt-[32px] mb-[24px]">
        Personal Information
      </h2>
      <div className=" flex flex-row  items-center justify-between mr-14 ">
        <div class=" flex-col justify-start items-start gap-2 inline-flex">
          <div class="text-neutral-500 text-sm font-normal ">
            No. of Accepted bids
          </div>
          <div class="text-neutral-800 text-sm font-normal ">40%</div>
        </div>

        <div class="flex-col justify-start items-start gap-2 inline-flex">
          <div class="text-neutral-500 text-sm font-normal ">Discount Rate</div>
          <div class="text-neutral-800 text-sm font-normal f">30%</div>
        </div>

        <div class=" flex-col justify-start items-start gap-2 inline-flex">
          <div class="text-neutral-500 text-sm font-normal ">Response Time</div>
          <div class="text-neutral-800 text-sm font-normal ">30%</div>
        </div>
      </div>

      <h2 className="text-sm font-semibold text-black mt-[32px] mb-[24px]">
        Company Information
      </h2>
      <div className="flex flex-col gap-7">
        <div class="flex-col justify-start items-start gap-2 inline-flex  pb-2  border-b-[1.5px] border-stone-300">
          <div class="text-[#7A7A7A]  text-sm font-normal ">
            Number of Accepted Bid
          </div>
          <div class="self-stretch justify-between items-start inline-flex">
            <div class="w-[139px] text-neutral-800 text-sm font-normal ">
              33 bids accepted{" "}
            </div>
            <div class="w-[30px] text-neutral-800 text-sm font-normal ">
              90%
            </div>
          </div>
        </div>
        <div class="flex-col justify-start items-start gap-2 inline-flex  pb-2  border-b-[1.5px] border-stone-300">
          <div class="text-[#7A7A7A]  text-sm font-normal ">
            Number of Accepted Bid
          </div>
          <div class="self-stretch justify-between items-start inline-flex">
            <div class="w-[139px] text-neutral-800 text-sm font-normal ">
              33 bids accepted{" "}
            </div>
            <div class="w-[30px] text-neutral-800 text-sm font-normal ">
              90%
            </div>
          </div>
        </div>
        <div class=" flex-col justify-start items-start gap-2 inline-flex   pb-2  border-b-[1.5px] border-stone-300">
          <div class="text-[#7A7A7A] text-sm font-normal ">
            Low Discount Rates
          </div>
          <div class="self-stretch justify-between items-start inline-flex">
            <div class=" text-neutral-800 text-sm font-normal ">
              Average Discount rate of 5.7%
            </div>
            <div class="w-[30px] text-neutral-800 text-sm font-normal ">
              82%
            </div>
          </div>
        </div>
        <div class=" flex-col justify-start items-start gap-2 inline-flex   pb-2  border-b-[1.5px] border-stone-300">
          <div class="text-[#7A7A7A] text-sm font-normal ">Response Time</div>
          <div class="self-stretch justify-between items-start inline-flex">
            <div class=" text-neutral-800 text-sm font-normal ">
              Places offers within 24hrs
            </div>
            <div class="w-[30px] text-neutral-800 text-sm font-normal ">
              82%
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default FundersEvaluation;
