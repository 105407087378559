import { setActiveTab } from "appstate/collections/collectionSlice";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const HeaderTabs = ({ tabs, hasPaddingY }) => {
  const activeTab = useSelector((state) => state?.collection?.activeTab);
  const dispatch = useDispatch();

  useEffect(() => {
    // Set the initial active tab to the first tab if not already set
    if (!activeTab && tabs.length > 0) {
      dispatch(setActiveTab(tabs[0].key));
    }
  }, [activeTab, dispatch, tabs]);

  const handleTabClick = (tabKey) => {
    dispatch(setActiveTab(tabKey));
  };

  const renderTabContent = () => {
    const activeTabContent = tabs.find((tab) => tab.key === activeTab)?.content;
    return activeTabContent || null;
  };

  return (
    <div>
      <div className={`rounded-md w-full bg-white mt5 mx6 mb-[17px] cursor-pointer ${hasPaddingY ? 'py-2' : ''}`}>
        <div className="md:flex md:flex-row flex-col gap1 p-2 px-7 justify-between gap-10 items-center">
          <div className="md:flex md:flex-row flex-col gap-10">
            {tabs.map((tab) => (
              <div
                key={tab.key}
                className={
                  activeTab === tab.key
                    ? "text-[#07593D] text-sm md:border-b-4 rounded-l-sm rounded-r-sm font-bold border-[#07593D] md:mb-[-18px]"
                    : "text-gray500 font-normal text-sm"
                }
                onClick={() => handleTabClick(tab.key)}
              >
                {tab.label}
              </div>
            ))}
          </div>
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

HeaderTabs.defaultProps = {
  hasPaddingY: true,
};

export default HeaderTabs;
