import * as Yup from "yup";

export const addBankValidationSchema = Yup.object().shape({
  bankName: Yup.string().required("Bank name is required"),
  countryOfBank: Yup.string().required("Country of bank is required"),
  currency: Yup.string().required("Currency is required"),
  accountNumber: Yup.string()
    .matches(/^[0-9]+$/, "Account number must be a number")
    .length(10, "Account number must be exactly 10 digits")
    .required("Account number is required"),
  accountHolder: Yup.string().required("Account holder name is required"),
  bvn: Yup.string()
    .matches(/^[0-9]+$/, "BVN must be a number")
    .length(11, "BVN must be 11 digits")
    .required("BVN is required"),
});
