import { PAImageEmpty } from "assets/images/svgs";

const EmptyPage = () => {
  return (
    <div className='w-full flex justify-center bg-white hfull py-[80px]'>
      <PAImageEmpty />
    </div>
  );
};

export default EmptyPage;
