import React, { useEffect } from "react";
import HeaderTabs from "shared/HeaderTabs";
import Overview from "../overview";
import DirectFunding from "../directFunding";
import OpenMarket from "../openMarket";
import TransactionHistory from "../transactionHistory";
import ClosedMarket from "../closedMarket";
import EarlyPaymentProgram from "../earlyPaymentProgram";
import { useDispatch } from "react-redux";
import { setActiveTab } from "appstate/collections/collectionSlice";

const FunderCollectionDashboard = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab("overview"));
  }, [dispatch]);

  return (
    <HeaderTabs
      tabs={[
        { key: "overview", label: "Overview", content: <Overview /> },
        {
          key: "directFunding",
          label: "Direct Funding",
          content: <DirectFunding />,
        },
        {
          key: "openMarket",
          label: "Open Market",
          content: <OpenMarket />,
        },
        {
          key: "closedMarket",
          label: "Closed Market",
          content: <ClosedMarket />,
        },
        {
          key: "earlyPaymentProgram",
          label: "Early Payment Program",
          content: <EarlyPaymentProgram />,
        },
        {
          key: "TransactionHistory",
          label: "Transaction History",
          content: <TransactionHistory />,
        },
      ]}
      hasPaddingY={true}
    />
  );
};

export default FunderCollectionDashboard;
