import {
  allFunderPfp,
  getPfpId,
  requestToLeavePfp,
} from "appstate/funder/funderSlice";
import { PAIconViewMini } from "assets/images/svgs";
import DashboardLayout from "layout/dashboardlayout";
import Table from "layout/table/Table";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import Button from "shared/Button";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import AccountModalContainer from "shared/accountModal/Modal";
import TableHeader from "shared/tableHeader/TableHeader";

const FunderPayableFinance = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const [success, setSuccess] = useState(false);
  const [getId, setGetId] = useState(null);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;

  const dropdownRef = useRef();

  const { allFunderPfpData, isLoading, requestToLeaveLoading } = useSelector(
    (state) => state?.funder
  );
  const [data, setData] = useState([]);
  // console.log(data);
  const dispatch = useDispatch();
  const onRowClicked = (row) => {
    let path = "funderpayablefinancedetails";
    navigate(path);
    dispatch(getPfpId(row?.pfpId));
  };

  const handleDropdown = (row) => {
    if (dropdown === row.pfpId) {
      setDropdown(null);
    } else {
      setDropdown(row.pfpId);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  useEffect(() => {
    dispatch(allFunderPfp({ page, pageSize }));
    setGetId(null);
  }, [dispatch, page, pageSize]);
  useEffect(() => {
    setData(allFunderPfpData?.data);
  }, [allFunderPfpData]);
  const handleLeaveProgram = () => {
    const pfpId = getId;
    dispatch(requestToLeavePfp({ pfpId })).then((data) => {
      if (data?.payload?.success) {
        setSuccess(true);
        toast.success(data?.payload?.message);
        dispatch(allFunderPfp({ page, pageSize }));
      }
    });
  };
  // function handleOpenModal(row) {
  //   setGetId(row.pfpId);
  //   setOpen(true);
  //   setSuccess(false);
  // }
  const columns = [
    {
      name: "Program Name",
      selector: (row) => row.programName,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Buyer",
      selector: (row) => row.buyerCompanyName,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Due Date",
      selector: (row) => row.pfpEndDate,
      sortable: true,
      width: "10rem",
    },
    {
      name: "No of Invoices",
      selector: (row) => row.totalNumberOfInvoices,
      sortable: true,
      width: "10rem",
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmountInAllInvoices,
      sortable: true,
      width: "10rem",
    },

    {
      name: "ACTION",
      selector: (row) => row?.pfpId,
      width: "8rem",
      cell: (row) => (
        <div>
          <img
            src="/option-btn.svg"
            alt="/"
            onClick={() => handleDropdown(row)}
          />
          {dropdown === row?.pfpId && row && (
            <div
              style={{ zIndex: 9999 }}
              className={`text-xs z-[9999] relative`}
              ref={dropdownRef}
            >
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && "bottom-[150px]"
                }  absolute z-[9999] drop-shadow-md rounded-md py-[12px]`}
              >
                <div
                  onClick={() => {
                    onRowClicked(row);
                    setDropdown(false);
                  }}
                  className="flex items-center gap-x-[9px] mb[2px]  pl-[20px] pt-[5px] pb-2.5 hover:bg-[#d9d9d94a] "
                >
                  <PAIconViewMini />
                  <p className="font-xs font-normal">View Rule</p>
                </div>
                <div className="border border-silver"></div>
                <div
                  className="flex items-center gap-x-[10px] mt[10px] pl-[10px] pt-[5px] pb-2.5 hover:bg-[#d9d9d94a] "
                  onClick={() => {}}
                >
                  <span></span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="8"
                    viewBox="0 0 16 8"
                    fill="none"
                  >
                    <path
                      d="M5 4H11M5.75 1H3.5C2.70435 1 1.94129 1.31607 1.37868 1.87868C0.816071 2.44129 0.5 3.20435 0.5 4C0.5 4.79565 0.816071 5.55871 1.37868 6.12132C1.94129 6.68393 2.70435 7 3.5 7H5.75M10.25 1H12.5C13.2956 1 14.0587 1.31607 14.6213 1.87868C15.1839 2.44129 15.5 3.20435 15.5 4C15.5 4.79565 15.1839 5.55871 14.6213 6.12132C14.0587 6.68393 13.2956 7 12.5 7H10.25"
                      stroke="#DE1515"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p className="text-[#DE1515]">Leave PFP</p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
    },
    // {
    //   name: "Action",
    //   cell: (row) => {
    //     return (
    //       <div className='flex gap-x-[12px]'>
    //         <Button
    //           onClick={() => handleOpenModal(row)}
    //           neutral
    //           className='flex px-[16px] py-[8px] border-[1px] border-gray rounded-[5px] bg-red'
    //         >
    //           <p className='text-xs font-normal text-white whitespace-nowrap'>
    //             Leave Program
    //           </p>
    //         </Button>
    //       </div>
    //     );
    //   },
    // },
  ];
  const totalPages = allFunderPfpData?.meta?.totalElements;
  const path = "pfppendingrequest";

  
  return (
    <DashboardLayout>
      <AccountModalContainer
        isShown={open}
        onClose={() => setOpen(false)}
        success={success}
        handleAssociate={handleLeaveProgram}
        isLoading={requestToLeaveLoading}
        warning="Are you sure you would like to leave this Payable Financing Program?"
        info="An email has been sent. Request pending buyer's approval"
        pfp
      />
      <Card className="p-[24px] min-h-full rounded-[10px]">
        <div>
          <TableHeader
            total={`${data?.length} Payable finance program`}
            btnName="Pending Invites"
            path={path}
            payableFinance
          />
        </div>
        <Table
          columns={columns}
          data={data}
          onRowClicked={onRowClicked}
          progressPending={isLoading}
          progressComponent={<Loading />}
          pointer
          tableHeader
          className="mt-5"
        />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {!isLoading && totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </Card>
    </DashboardLayout>
  );
};

export default FunderPayableFinance;
