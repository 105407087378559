import React, { useState } from "react";
import { PAIconMore } from "assets/images/svgs";

const Card = ({ item, image, icon, backgroundColor, handleMoreClick }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="max-w-xl mb-4 rounded-md shadow-sm cursor-pointer relative">
      <div className={`${backgroundColor} p-6 flex items-center justify-center rounded-md`}>
        <img src={image} alt="CardImage" className="max-h-16 overflow-hidden" />
      </div>
      <div className="bg-white p-4 flex justify-between items-center mb-[-10px] mt-[-20px]">
        <div className="flex items-center">
          {icon}
          <span className="font-semibold ml-2">{item.title}</span>
        </div>
        <div className="flex items-center space-x-2 justify-end cursor-pointer" onClick={toggleDropdown}>
          {handleMoreClick && (
            <PAIconMore onClick={() => handleMoreClick(item.id)} />
          )}
        </div>
        {isDropdownOpen && (
          <div className="absolute top-full right-5 mt-[-40px] bg-white rounded-md shadow-md">
            {/* Dropdown Content */}
            <ul className="p-3">
              <li className="py-2 px-4 hover:shadow-sm cursor-pointer text-black font-inter text-base font-normal">
                Preview
              </li>
              <li className="py-2 px-4 hover:shadow-sm cursor-pointer">Print</li>
              <li className="py-2 px-4 hover:shadow-sm cursor-pointer">Download</li>
              <li className="py-2 px-4 hover:shadow-sm cursor-pointer">Share</li>
              <li className="py-2 px-4 hover:shadow-sm cursor-pointer">Export Data</li>
            </ul>
          </div>
        )}
      </div>
      <p className="text-[#959595] font-sans text-xs font-normal px-4 mb-5 pb-10">
        Last Updated: {item.date.toDateString()}
      </p>
    </div>
  );
};

export default Card;
