import { PAIconCancel, PAIconIconAcceptedBid } from "assets/images/svgs";
import React from "react";

function InvoiceLockedModal({ onClose, isOpen }) {
  if (!isOpen) {
    return null;
  }
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
        <div className="bg-white p-5 rounded-[10px] shadow-md flex flex-col items-center  md:w-[459px]  pt-8">
          <header className="flex justify-between border-b-4 border-whisper200 pb-[16px] w-full">
            <p class="text-[#222222] text-base font-bold ">Invoice locked</p>
            <div
              onClick={onClose}
              className="flex justify-between items-center gap-x-[10px] cursor-pointer"
            >
              <PAIconCancel className="w-[8px]" />

              <p
                class="text-[#565656] text-sm
font-semibold"
              >
                Close
              </p>
            </div>
          </header>
          <div className=" flex flex-col flex-1 w-full pb-6">
            <div className="  flex justify-center items-center p-5">
              <PAIconIconAcceptedBid />
            </div>

            <div class=" text-neutral-800 text-center font-normal  tracking-wide text-[#949494] ">
              You've successfully updated your bid on this request. Thanks
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoiceLockedModal;
