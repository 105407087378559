
const TransactionsModalContents = ({ onClose }) => {
  return (
    <div className="max-w-[40rem] px-4 md:px-[32px] mx-auto">
      {/* <!-- Row --> */}
      <div className="mb-4 flex justify-between items-center">
        <div>
          <h2 className="text-[1.225rem] font-bold text-gray-800 ">Payment Receipt</h2>
        </div>
        <div className="flex">
          <button 
          className="bg-white text-red-600 font-bold py-2 px-8 rounded-md text-xs border-2 border-red-600 hover:bg-red-100"
          onClick={() => {onClose()}}
          >
            Create Dispute
          </button>
        </div>
      </div>
      {/* <!-- Row --> */}

      <p className="text-[0.965rem] font-bold">Invoice | INV-2002</p>

      {/* <!-- Refrence No --> */}
      <div className="mb-4 mt-4 flex flex-col">
        
        <p className="text-[0.965rem]">Refrence No:</p>

        <div className="flex justify-between">
          <div>
            <div className="flex">
              <div className="pr-5">
                <h2 className="text-[0.695rem] text-gray-800 tracking-wide">Invoice Date:</h2>
              </div>
              <div>
                <h2 className="text-[0.695rem] text-gray-800 tracking-wide">23-Dec-2024</h2>
              </div>
            </div>
            <div className="flex">
              <div className="pr-3">
                <h2 className="text-[0.695rem] text-gray-800 tracking-wide">Payment Date:</h2>
              </div>
              <div>
                <h2 className="text-[0.695rem] text-gray-800 tracking-wide">23-Dec-2024</h2>
              </div>
            </div>
          </div>
          <div className="flex">
            <button className="bg-greenIce text-greenHaze font-bold py-2 px-8 rounded-md text-xs">
              Completed
            </button>
          </div>
        </div>
      </div>
      {/* <!-- Refrence No --> */}

      
      {/*Description */}
        <div>
          <h2 className="text-[0.865rem] font-bold">Description</h2>
          <h3 className="text-[#626679] text-[0.695rem]">Add description</h3>
        </div>
      {/*Description End */}


      {/*Payment Amount */}
      <div className="grid grid-cols-2 pt-5 space-x-40">
        <div>
          <h2 className="text-[0.865rem] font-bold">Payment Amount</h2>
          <h3 className="text-[#2EA923] font-semibold text-[1.1rem]">9,000,000 - Credit</h3>
        </div>
        <div className="align-middle"> 
          <h2 className="text-[0.865rem] font-bold">Currency</h2>
          <h3 className="text-[#626679] text-xs">NGN</h3>
        </div>
      </div>

      {/* Supplier Details */}
      <div className="w-full flex flex-col mt-5">
        <h3 className="text-[0.965rem] font-bold">Supplier / Buyer details</h3>

        <div className="h-[5px] w-full bg-[#F6F5FA] mt-[8px]"></div>

        <div className="flex justify-between pt-3">
          <div>
            <h2 className="font-semibold text-[0.775rem] mb-1">Supplier</h2>
            <h3 className="text-[#626679] text-[0.675rem] tracking-wide">ABC Cooperation</h3>
          </div>
          <div>
            <h2 className="font-semibold text-[0.775rem] mb-1">Buyer</h2>
            <h3 className="text-[#626679] text-[0.675rem] tracking-wide">Big Buyer Coop</h3>
          </div>
          <div>
            <span className="text-[0.675rem] text-[#F08000] align-middle">View More Info</span>
          </div>
        </div>
      </div>
      {/* Supplier buyer details */}

      <h1 className="mb-[4px] mt-[28px] font-bold text-md">List Items</h1>

      <div className="h-[5px] w-full bg-[#F6F5FA] mt-[1px] mb-[12px]"></div>

      {/* <!-- Table --> */}
      <div className="flex flex-col items-start relative">
      <div className="flex flex-col items-start gap-[10px] px-0 py-[3px] relative self-stretch w-full flex-[0_0_auto] bg-[#efefef] border-t [border-top-style:solid] border-b [border-bottom-style:solid] border-[#eeeeee]">
        <div className="flex items-center justify-between pl-[6px] pr-0 py-[3px] relative self-stretch w-full flex-[0_0_auto]">
          <div className="flex w-[80px] items-center justify-center gap-[10px] p-[10px] relative">
            <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Bold',Helvetica] font-bold text-[#11192a] text-[11px] tracking-[0] leading-[normal]">
              Item <br />
              Number
            </div>
          </div>
          <div className="flex w-[80px] items-center justify-center gap-[10px] p-[10px] relative">
            <div className="ml-[-4.50px] mr-[-4.50px] [font-family:'SF_Pro_Text-Bold',Helvetica] font-bold relative w-fit mt-[-1.00px] text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              PO Number
            </div>
          </div>
          <div className="flex w-[110px] items-center justify-center gap-[10px] p-[10px] relative">
            <div className="relative w-fit mt-[-1.00px] ml-[-4.00px] mr-[-4.00px] [font-family:'SF_Pro_Text-Bold',Helvetica] font-bold text-[#11192a] text-[11px] tracking-[0] leading-[normal]">
              Product/Service
              <br />
              Number
            </div>
          </div>
          <div className="w-[110px] items-center justify-center flex gap-[10px] p-[10px] relative">
            <div className="relative w-fit mt-[-1.00px] ml-[-4.00px] mr-[-4.00px] [font-family:'SF_Pro_Text-Bold',Helvetica] font-bold text-[#11192a] text-[11px] tracking-[0] leading-[normal]">
              Product/Service
              <br />
              Description
            </div>
          </div>
          <div className="w-[76px] items-start flex gap-[10px] p-[10px] relative">
            <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Bold',Helvetica] font-bold text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              Quantity
            </div>
          </div>
          <div className="w-[80px] items-start flex gap-[10px] p-[10px] relative">
            <div className="relative w-[40px] mt-[-1.00px] [font-family:'SF_Pro_Text-Bold',Helvetica] font-bold text-[#11192a] text-[11px] tracking-[0] leading-[normal]">
              Unit Price
            </div>
          </div>
          <div className="w-[80px] items-start flex gap-[10px] p-[10px] relative">
            <div className="relative w-[60px] mt-[-1.00px] [font-family:'SF_Pro_Text-Bold',Helvetica] font-bold text-[#11192a] text-[11px] tracking-[0] leading-[normal]">
              Line Item
              <br />
              Extended Amount
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start gap-[10px] px-0 py-[4px] relative self-stretch w-full">
        <div className="flex items-center justify-between pl-[6px] pr-0 py-[3px] relative self-stretch w-full">
          <div className="flex w-[80px] items-center justify-center gap-[10px] p-[10px] relative">
            <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              INV-2002
            </div>
          </div>
          <div className="flex w-[80px] items-center justify-center gap-[10px] p-[10px] relative">
            <div className="[font-family:'SF_Pro_Text-Regular',Helvetica] font-normal relative w-fit mt-[-1.00px] text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              PO-2002
            </div>
          </div>
          <div className="flex w-[110px] items-center justify-center gap-[10px] p-[10px] relative">
            <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              ITM3201
            </div>
          </div>
          <div className="w-[110px] items-center justify-center flex gap-[10px] p-[10px] relative">
            <div className="relative w-fit mt-[-1.00px] ml-[-3.00px] mr-[-3.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              Dangote Cement
            </div>
          </div>
          <div className="w-[76px] items-start flex gap-[10px] p-[10px] relative">
            <div className="relative mx-auto w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              20
            </div>
          </div>
          <div className="w-[80px] items-start flex gap-[10px] p-[10px] relative">
            <div className="relative w-[40px] mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal]">
              $30
            </div>
          </div>
          <div className="w-[80px] items-start flex gap-[10px] p-[10px] relative">
            <div className="relative w-[60px] mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal]">
              $600
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start gap-[10px] px-0 py-[4px] relative self-stretch w-full flex-[0_0_auto]">
        <div className="flex items-center justify-between pl-[6px] pr-0 py-[3px] relative self-stretch w-full flex-[0_0_auto]">
          <div className="flex w-[80px] items-center justify-center gap-[10px] p-[10px] relative">
            <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              INV-2002
            </div>
          </div>
          <div className="flex w-[80px] items-center justify-center gap-[10px] p-[10px] relative">
            <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              PO-2002
            </div>
          </div>
          <div className="flex w-[110px] items-center justify-center gap-[10px] p-[10px] relative">
            <div className="relative w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              ITM3201
            </div>
          </div>
          <div className="w-[110px] items-center justify-center flex gap-[10px] p-[10px] relative">
            <div className="relative w-fit mt-[-1.00px] ml-[-3.00px] mr-[-3.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              Dangote Cement
            </div>
          </div>
          <div className="w-[76px] items-start flex gap-[10px] p-[10px] relative">
            <div className="relative mx-auto w-fit mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal] whitespace-nowrap">
              20
            </div>
          </div>
          <div className="w-[80px] items-start flex gap-[10px] p-[10px] relative">
            <div className="relative w-[40px] mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal]">
              $30
            </div>
          </div>
          <div className="w-[80px] items-start flex gap-[10px] p-[10px] relative">
            <div className="relative w-[60px] mt-[-1.00px] [font-family:'SF_Pro_Text-Regular',Helvetica] font-normal text-[#11192a] text-[11px] tracking-[0] leading-[normal]">
              $600
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- End Table --> */}
    </div>
  );
};

export default TransactionsModalContents;
