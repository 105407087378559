import React, { useState } from "react";
import { PAIconModelOne } from "assets/images/svgs";
import AcceptModal from "../acceptModal";
import RejectModal from "../rejectModal";

import AcceptedBidSuccessfulModal from "../modalcomponent/AcceptedBidSuccessfulModal";
import ReasonRequiredModal from "../modalcomponent/ReasonRequiredModal";
import FundBidAcceptedModal from "../modalcomponent/FundBidAcceptedModal";

const FunderDetailsSegment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [viewInvoiceDetailModal, setViewInvoiceDetailModal] = useState(false);
  const [reasonRequiredModal, setReasonRequiredModal] = useState(false);
  const [fundacceptedmodal, setfundacceptedmodal] = useState(false);

  const handleAccept = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setRejectModalOpen(false);
  };

  const handleReject = () => {
    setRejectModalOpen(true);
  };
  const handleAccepted = () => {
    setViewInvoiceDetailModal(true);
    setIsModalOpen(false);
  };
  const handleRequired = () => {
    setReasonRequiredModal(true);
    setRejectModalOpen(false);
  };
  const handleSetSubmit = () => {
    setReasonRequiredModal(false);
    setfundacceptedmodal(true);
  };
  return (
    <div className="">
      <AcceptedBidSuccessfulModal
        isShown={viewInvoiceDetailModal}
        onClose={() => setViewInvoiceDetailModal(false)}
      />
      <ReasonRequiredModal
        isShown={reasonRequiredModal}
        onClose={() => setReasonRequiredModal(false)}
        handleSubmit={handleSetSubmit}
      />
      <FundBidAcceptedModal
        isShown={fundacceptedmodal}
        onClose={() => setfundacceptedmodal(false)}
      />

      <div className="flex justify-between my-5 px-6 mt-10">
        <div className="flex gap-2">
          <PAIconModelOne />
          <div className="flex flex-col">
            <span className="text-sm">ABC Limited</span>
            <span className="text-[#979797]  text-xs">{"Funder"} </span>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-sm">56 mins ago</span>
          <span className="text-[#979797]  text-xs ">Time</span>
        </div>
      </div>

      <div className=" border-b-[1px]  border-b-[#C1C1C1] pb-7 mt-11">
        {/* flex section */}
        <div className="flex justify-between ">
          <div className="flex gap-2 px-6">
            <div className="flex flex-col">
              <span className="text-[#979797]  text-xs">Discount rate</span>
              <span className="text-sm">1.5%</span>
            </div>
          </div>
          <div className="flex flex-col px-6">
            <span className="text-[#979797]  text-xs">Discount amount</span>
            <span className="text-sm">N234,000</span>
          </div>
        </div>

        {/* add */}
        <div className="flex flex-col px-6 mt-10">
          <span className="text-[#979797]  text-xs">Note from Funder</span>
          <span className="text-sm mt-3">
            Our competitive offer signifies our dedication to fostering a
            fruitful collaboration. We look forward to your consideration. Thank
            you.
          </span>
        </div>
      </div>

      <div className="pb-3 px-6 mt-5">
        <h2 className="font-medium text-base leading-[30px] my-2">
          Action Buttons
        </h2>
        <p className="text-[#959595] text-sm">
          Accept or Reject funding bids from funders either With Recourse or
          Without Recourse
        </p>
      </div>

      <div className="flex gap-5 mt-5 w-full justify-between px-6 ">
        <button
          className="rounded-md bg-[#07593D] text-white px-16 py-3"
          onClick={handleAccept}
        >
          Accept
        </button>
        <button
          className="rounded-md bg-white border border-[#F08000] text-[#F08000]  py-3  px-16"
          onClick={handleReject}
        >
          Reject
        </button>
      </div>
      <AcceptModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        handleYes={handleAccepted}
      />
      <RejectModal
        isOpen={isRejectModalOpen}
        onClose={handleCloseModal}
        handleYes={handleRequired}
      />
    </div>
  );
};

export default FunderDetailsSegment;
