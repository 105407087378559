import React from "react";
import Card from "shared/Card";
import Table from "layout/table/Table";
import {
  PAIconOutlineSearch,
  PAIconFilter,
  PAIconArrowDownThin,
  PAIconClearSelect,
} from "assets/images/svgs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/Pagination";

const Pending = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    let updatedFilters = [...selectedFilters];

    if (updatedFilters.includes(value)) {
      updatedFilters = updatedFilters.filter((filter) => filter !== value);
    } else {
      updatedFilters.push(value);
    }

    setSelectedFilters(updatedFilters);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const navigate = useNavigate();

  const dummyData = [
    {
      bidAmount: 3,
      amount: "9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Pending",
      invoiceDate: "2023-05-23",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "INV-2090",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-05-21",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Supplier A",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "NGN",
    },

    {
      bidAmount: 4,
      amount: "9,000,000",
      buyerId: "0618f8fc-8097-41be-bed2-ccd1bfdeb1f2",
      discountRate: 3,
      funderId: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      invoiceAmount: 32164082.78,
      status: "Pending",
      invoiceDate: "2023-05-23",
      invoiceId: "65dfc0942b607753910b022e",
      transactionId: "INV-2090",
      lockedBy: "4bddd358-5412-4d62-b0ed-b34e2c3129fa",
      lockedDate: "2024-03-07",
      paymentDate: "2023-05-21",
      offerId: "65e1eda9e789120b67af15b4",
      numberofBidders: "235,490",
      funder: "Supplier A",
      supplierId: "9420b5f3-5153-44dd-9162-c1cdc648c380",

      currency: "NGN",
    },
  ];
  const totalPages = dummyData.length;

  const columns = [
    {
      name: <span className="whitespace-nowrap">Invoice Reference</span>,
      selector: (row) => row.transactionId,
      sortable: true,
    },
    {
      name: <span className="whitespace-nowrap">Supplier</span>,
      selector: (row) => row.funder,
      sortable: true,
    },

    {
      name: "Invoice Date",
      selector: (row) => row.invoiceDate,
      sortable: true,
    },
    {
      name: "Due Date",
      selector: (row) => row.paymentDate,
      sortable: true,
    },

    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
    },
    {
      name: "Invoiced Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Bid Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "Pending" ? (
          <p className="text-[#F08000]">{row.status}</p>
        ) : row.status === "Accepted" ? (
          <p className="text-[#2EA923]">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];
  const toDetail = (id) => {
    navigate(`/factoring/facdirectfundingpendingdetail/${id}`);
  };
  return (
    <>
      <div className="my-2 grid md:grid-cols-2 items-center">
        <div className="gap-3 flex relative">
          <div>
            <div
              className="flex items-center gap-[4px] border border-[#DEDEDE] bg-white shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
              onClick={handleDropdownToggle}
            >
              Filter{" "}
              {selectedFilters.length > 0 && (
                <span className="bg-[#7b61ff] rounded-lg p-1 px-2">
                  {selectedFilters.length}
                </span>
              )}{" "}
              Selected <PAIconArrowDownThin />
              {selectedFilters.length > 0 ? (
                <span>
                  <PAIconClearSelect onClick={() => setSelectedFilters([])} />
                </span>
              ) : (
                <></>
              )}
            </div>
            {isDropdownOpen && (
              <div className="absolute top-full mt1 wfull border border-[#DEDEDE] shadow-lg rounded-b-[5px] px-4 py-3 bg-white">
                <label className="block">
                  <input
                    type="checkbox"
                    value="date"
                    checked={selectedFilters.includes("date")}
                    onChange={handleFilterChange}
                  />
                  Date
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="name"
                    checked={selectedFilters.includes("name")}
                    onChange={handleFilterChange}
                  />
                  Name
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="currency"
                    checked={selectedFilters.includes("currency")}
                    onChange={handleFilterChange}
                  />
                  Currency
                </label>
                <label className="block">
                  <input
                    type="checkbox"
                    value="amount"
                    checked={selectedFilters.includes("amount")}
                    onChange={handleFilterChange}
                  />
                  Amount
                </label>
              </div>
            )}
          </div>
          <div>
            <select
              className="border border-[#DEDEDE] shadow-sm rounded-[5px] px-4 py-3 text-sm focus:outline-none cursor-pointer"
              onChange={(e) => {}}
            >
              <option value="">Sort by</option>
              <option value="date">Date</option>
              <option value="name">Name</option>
            </select>
          </div>
        </div>
        <div className="flex md:col-span-1 justify-end">
          <div>
            <div className="flex items-center justify-between gap-5">
              <div className="border border-transparent md:border-gray md:shadow-sm my-4 rounded-[5px] px-2 lg:w-[300px] bg-white">
                <div className="items-center flex bg-white">
                  <PAIconOutlineSearch />
                  <input
                    className="bg-transparent p-2 w-full focus:outline-none text-sm bg-white"
                    type="text"
                    placeholder="Search for invoices"
                  />
                </div>
                <div className="lg:hidden flex items-center p-2.5 rounded bg-[#2FA06A] cursor-pointer">
                  <PAIconFilter className="bg-[#2FA06A]" />
                </div>
              </div>

              <div class=" px-6 py-2 bg-[#f2f2f2]/95 rounded-[5px] border border-[#cccccc] justify-start items-center gap-2.5 inline-flex">
                <div class="text-black text-sm font-medium ">Search</div>
              </div>
            </div>
          </div>

          {/* ends here */}
        </div>
      </div>

      <Card className="rounded-[10px] mt-[24px] md:min-h-[752px] lg:min-h-[752px]">
        <div className="flex  items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
          <div class="text-black text-sm font-medium ">{"2"} Invoices</div>
        </div>
        <div className="px-[24px] pb-[24px]">
          <Table
            columns={columns}
            data={dummyData}
            onRowClicked={(row) => toDetail(row.bidAmount)}
            pointer
            tableHeader
            className="mt-5"
          />
        </div>
        <div className="flex justify-center gap-x-[8px]  mt-12">
          {totalPages > 1 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>{" "}
      </Card>
    </>
  );
};

export default Pending;
