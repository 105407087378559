import DashboardLayout from "layout/dashboardlayout";
import DashboardContent from "./dashboardContent";

const BuyerOpenMarketDashBoard = () => {
  return (
    <DashboardLayout
      section="Open market"
      subHeader="Monitor your invoices in the open market"
    >
      <DashboardContent />
    </DashboardLayout>
  );
};

export default BuyerOpenMarketDashBoard;
