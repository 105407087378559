import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "./searchInput";
import { setActiveButton } from "appstate/invoice/invoiceSlice";

const Headers = () => {
  const activeButton = useSelector((state) => state?.invoice?.activeButton);
  const dispatch = useDispatch();

  const handleButtonClick = (buttonType) => {
    dispatch(setActiveButton(buttonType));
  };

  return (
    <div className="my-2 grid md:grid-cols-2 items-center">
      <div className="gap-5">
        <button
          className={`py-2 px-4 rounded-md border-[#DEDEDE] shadow-sm mr-2 ${
            activeButton === "single"
              ? "bg-[#07593D] text-white"
              : "bg-white text-black"
          }`}
          onClick={() => handleButtonClick("single")}
        >
          Single Requests
        </button>
        <button
          className={`py-2 px-4 rounded-md border-[#DEDEDE] shadow-sm ${
            activeButton === "bulk"
              ? "bg-[#07593D] text-white"
              : "bg-white text-black"
          }`}
          onClick={() => handleButtonClick("bulk")}
        >
          Bulk Requests
        </button>
      </div>
      <div className="flex md:col-span-1 justify-end">
        <SearchInput />
      </div>
    </div>
  );
};

export default Headers;
