import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  CompanyDetails,
  addBank,
  addBuyerBank,
  addFunderBank,
  associateBuyer,
  buyerResendEmail,
  corporateFunderResendEmail,
  createBuyer,
  createFunder,
  createFunderCompany,
  createSupplier,
  funderResendEmail,
  getAllSuppliers,
  getBuyerById,
  getBuyers,
  getBuyersAssociatedWithSupplier,
  getFunderByUserId,
  getFunderDataById,
  getPlayerById,
  getPlayerByUserId,
  getSupplierById,
  getSuppliersAssociatedWithBuyer,
  searchBuyer,
  setCorporateFunderPassword,
  supplierResendEmail,
} from "appstate/api/auth/onboarding";

const initialState = {
  isLoading: false,
  userId: null,
  currentUser: null,
  supplierId: null,
  associateBuyerId: null,
  buyerId: null,
  allBuyersData: [],
  isAuthenticated: false,
  token: null,
  funderId: null,
  allSuppliers: [],
  currentUserBuyers: [],
  currentUserSuppliers: [],
  hasCompleted2FA: false,
  userDetails: [],
  redirect: false,
};

export const createNewSupplier = createAsyncThunk(
  "auth/createNewSupplier",
  createSupplier
);

export const getAllBuyers = createAsyncThunk("auth/getAllBuyers", getBuyers);

export const associateWithBuyer = createAsyncThunk(
  "auth/associateWithBuyer",
  associateBuyer
);

export const addBankDetails = createAsyncThunk("auth/addBankDetails", addBank);

export const createNewBuyer = createAsyncThunk(
  "auth/createNewBuyer",
  createBuyer
);
export const addCompanyDetails = createAsyncThunk(
  "auth/addCompanyDetails",
  CompanyDetails
);

export const addBuyerBankDetails = createAsyncThunk(
  "auth/addBuyerBankDetails",
  addBuyerBank
);

export const buyerSearch = createAsyncThunk("auth/buyerSearch", searchBuyer);

export const funderCompany = createAsyncThunk(
  "auth/funderCompany ",
  createFunderCompany
);
export const createNewFunder = createAsyncThunk(
  "auth/createNewFunder",
  createFunder
);
export const addFunderBankDetails = createAsyncThunk(
  "auth/addFunderBankDetails",
  addFunderBank
);
export const corporateFunderSetPassword = createAsyncThunk(
  "auth/corporateFunderSetPassword",
  setCorporateFunderPassword
);

export const getPlayerBykeycloakId = createAsyncThunk(
  "auth/getPlayerBykeycloakId",
  getPlayerByUserId
);

export const getFunderBykeycloakId = createAsyncThunk(
  "auth/getFunderBykeycloakId",
  getFunderByUserId
);

export const getPlayerByMongoId = createAsyncThunk(
  "auth/getPlayerByMongoId",
  getPlayerById
);

export const getSuppliersAll = createAsyncThunk(
  "auth/getSuppliersAll",
  getAllSuppliers
);

export const getAllBuyersAssociatedWithSupplier = createAsyncThunk(
  "auth/getAllBuyersAssociatedWithSupplier",
  getBuyersAssociatedWithSupplier
);

export const getAllSuppliersAssociatedWithBuyer = createAsyncThunk(
  "auth/getAllSuppliersAssociatedWithBuyer",
  getSuppliersAssociatedWithBuyer
);

export const resendEmailSupplier = createAsyncThunk(
  "auth/resendEmailSupplier",
  supplierResendEmail
);

export const resendEmailBuyer = createAsyncThunk(
  "auth/resendEmailBuyer",
  buyerResendEmail
);

export const resendEmailFunder = createAsyncThunk(
  "auth/resendEmailFunder",
  funderResendEmail
);

export const resendEmailCorporateFunder = createAsyncThunk(
  "auth/resendEmailCorporateFunder",
  corporateFunderResendEmail
);

export const supplierDataById = createAsyncThunk(
  "auth/supplierDataById",
  getSupplierById
);

export const buyerDataById = createAsyncThunk(
  "auth/buyerDataById",
  getBuyerById
);

export const funderDataById = createAsyncThunk(
  "auth/funderDataById",
  getFunderDataById,
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getUserId: (state, action) => {
      state.userId = action.payload;
    },
    getSupplierId: (state, action) => {
      state.supplierId = action.payload;
    },
    getAssociateBuyerId: (state, action) => {
      state.associateBuyerId = action.payload;
    },
    getBuyerId: (state, action) => {
      state.buyerId = action.payload;
    },
    getKeycloakInstance(state, { payload }) {
      state.token = payload.token;
      state.isAuthenticated = payload.authenticated;
    },
    logOut: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.currentUser = null;
      state.userId = null;
      state.supplierId = null;
      state.associateBuyerId = null;
      state.buyerId = null;
      state.funderId = null;
      state.userDetails = [];
    },    
    getFunderId: (state, action) => {
      state.funderId = action.payload;
    },
    getCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setHasCompleted2FA: (state) => {
      state.hasCompleted2FA = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewSupplier.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createNewSupplier.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createNewSupplier.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAllBuyers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBuyers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allBuyersData = action.payload;
    });
    builder.addCase(getAllBuyers.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getSuppliersAll.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSuppliersAll.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allSuppliers = action.payload;
    });
    builder.addCase(getSuppliersAll.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(associateWithBuyer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(associateWithBuyer.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(associateWithBuyer.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addBankDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addBankDetails.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addBankDetails.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createNewFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createNewFunder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createNewFunder.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderCompany.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderCompany.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderCompany.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addFunderBankDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addFunderBankDetails.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addFunderBankDetails.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(corporateFunderSetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(corporateFunderSetPassword.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(corporateFunderSetPassword.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createNewBuyer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createNewBuyer.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createNewBuyer.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addCompanyDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addCompanyDetails.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addCompanyDetails.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addBuyerBankDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addBuyerBankDetails.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addBuyerBankDetails.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerSearch.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerSearch.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(buyerSearch.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPlayerBykeycloakId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPlayerBykeycloakId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentUser = action.payload?.data;
    });
    builder.addCase(getPlayerBykeycloakId.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFunderBykeycloakId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunderBykeycloakId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentUser = action?.payload?.data;
    });
    builder.addCase(getFunderBykeycloakId.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPlayerByMongoId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPlayerByMongoId.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getPlayerByMongoId.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAllBuyersAssociatedWithSupplier.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllBuyersAssociatedWithSupplier.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.currentUserBuyers = action.payload;
      }
    );
    builder.addCase(getAllBuyersAssociatedWithSupplier.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getAllSuppliersAssociatedWithBuyer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllSuppliersAssociatedWithBuyer.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.currentUserSuppliers = action.payload;
      }
    );
    builder.addCase(getAllSuppliersAssociatedWithBuyer.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resendEmailSupplier.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resendEmailSupplier.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resendEmailSupplier.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resendEmailBuyer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resendEmailBuyer.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resendEmailBuyer.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(resendEmailFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resendEmailFunder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resendEmailFunder.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(resendEmailCorporateFunder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resendEmailCorporateFunder.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resendEmailCorporateFunder.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(supplierDataById.pending, (state) => {
      state.isLoading = true;
      state.redirect = true;
    });
    builder.addCase(supplierDataById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.redirect = false;
      state.userDetails = action.payload;
    });
    builder.addCase(supplierDataById.rejected, (state) => {
      state.redirect = true;
      state.isLoading = false;
    });
    builder.addCase(buyerDataById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(buyerDataById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userDetails = action.payload;
    });
    builder.addCase(buyerDataById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(funderDataById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(funderDataById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userDetails = action.payload;
    });
    builder.addCase(funderDataById.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const {
  getBuyerId,
  getUserId,
  getSupplierId,
  getAssociateBuyerId,
  getKeycloakInstance,
  logOut,
  getFunderId,
  getCurrentUser,
  setHasCompleted2FA,
} = authSlice.actions;
export default authSlice.reducer;
