import { eachVirtualCard } from "appstate/wallet/walletSlice";
import VirtualCard from "features/wallet/createVirtualAccount/step2/virtualCard";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const StepTwo = ({ isOpen, onClose, onSubmit, account, currency }) => {
  const dispatch = useDispatch();

  const { virtualCardDetails, virtualCardId } = useSelector(
    (state) => state?.wallet
  );

  useEffect(() => {
    dispatch(eachVirtualCard({ cardId: virtualCardId }));
  }, [dispatch, virtualCardId]);

  const [, setData] = useState(null);

  useEffect(() => {
    setData({
      cardName: virtualCardDetails?.cardName || "",
      cardCurrency: virtualCardDetails?.cardCurrency || "",
    });
  }, [virtualCardDetails]);

  if (!isOpen) return null;
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            Create Account
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>

        {/* FORM */}
        <div>
          <div className="w-full px-7">
            <p className="text-[#7A7A7A] font-sans text-sm font-normal mb6">
              Step 2
            </p>

            <p className="my-4 text-[#222] font-sans text-sm font-bold">
              Account Information
            </p>
            <div className="mb-10">
              <div className="text-neutral-800 text-[13px] font-normal font-['SF Pro Display']">
                Name : {account?.name}
              </div>
              <div className="text-neutral-800 text-[13px] font-normal font-['SF Pro Display']">
                Virtual Account Number : 7465938273
              </div>
              <div className="text-neutral-800 text-[13px] font-normal font-['SF Pro Display']">
                BVN Number : {account?.bvn}
              </div>
              <div className="text-neutral-800 text-[13px] font-normal font-['SF Pro Display']">
                Account Currency : {currency}
              </div>
            </div>
            <div className="text-amber-600 text-xs font-bold font-['SF Pro Display'] mb-5 flex justify-end cursor-pointer">
              Edit Account Info
            </div>

            <p className="mb-4 text-[#222] font-sans text-sm font-bold">
              Card Information
            </p>
            <VirtualCard
              name={account?.name}
              // cardNum={data?.cardCurrency}
              // date="09/25"
            />

            {/* Buttons */}
            <div className="flex justify-end mt-10">
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-gray300 mx-2">
                <button
                  type="button"
                  onClick={onClose}
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                >
                  Cancel
                </button>
              </div>
              <div className="w-[105px] h-[35px] px-6 py-2 bg-emerald-800 rounded-[5px] justify-center items-center gap-2.5 inline-flex bg-[#07593D]">
                <button
                  className="text-center text-white text-base font-normal font-['SF Pro Display'] tracking-wide"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
