import axios from "axios";

let store;

export const injectStore = (_store) => {
  store = _store;
};

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const reFreshToken = ({ refreshToken }) => {
  return axios({
    method: "POST",
    url: "",
    headers: { Authorization: `Bearer ${refreshToken}` },
  });
};

request.interceptors.request.use((config) => {
  const {
    auth: { token },
  } = store.getState();
  if (!token) {
    return config;
  } else {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  }
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error) {
      const {
        auth: { token },
      } = store.getState();
      if (error.response.status === 401 && token) {
        const {
          auth: {
            keyclaokInstance: { refreshToken },
          },
        } = store.getState();
        const newRefreshToken = await reFreshToken({ refreshToken });
        const newConfig = {
          ...error.config,
          headers: { ...error.config.headers },
          Authorization: `Bearer ${newRefreshToken}`,
        };
        return newConfig;
      }
    }
  }
);

export default request;
