import React from "react";
import { Link } from "react-router-dom";

const FunderOpenmarket = () => {
  return (
    <div className='flex flex-col md:flex-row mt-5'>
      <div className='flex-1 p-5 px-7 pb3 md:pb3 mb-3 rounded-lg bg-white shadow-md flex items-center justify-between'>
        <div className='flex flex-col items-center'>
          <p className=' text-primaryColor text-base font-bold mb1'>
            Open Market
          </p>
          <div className='w-4 h-[2px] rounded-md bg-[#07593D] shadow-md'></div>
        </div>

        <Link
          to='/open-market'
          className='text-gray-500 text-sm font-normal underline'
        >
          View all
        </Link>
      </div>
    </div>
  );
};

export default FunderOpenmarket;
