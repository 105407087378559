import Table from "layout/table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "shared/Card";
import Loading from "shared/Loading";
import Pagination from "shared/Pagination";
import AccountModalContainer from "shared/accountModal/Modal";
import Heading from "shared/heading/Heading";
import { allEarlyPayments } from "appstate/buyer/buyerSlice";
import { getInvoiceForApprove } from "appstate/invoice/invoiceSlice";
import { useNavigate } from "react-router-dom";

function All() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const { allEarlyPAymentsData, isLoading } = useSelector(
    (state) => state?.buyer
  );

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(10);
  const pageSize = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(allEarlyPayments({ page, pageSize }));
  }, [dispatch, page, pageSize]);

  const formatAmount = (amount) => {
    if (typeof amount !== "number") return "0.00";
    return amount.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const InvoiceDetails = (id) => {
    let path = `/invoice/buyer-invoice-details/${id}`;
    navigate(path);
  };

  const columns = [
    { name: "Invoice Reference", selector: (row) => row.invoiceRef },
    { name: "Supplier", selector: (row) => row.supplier },
    { name: "Invoice Date", selector: (row) => row.invoiceDate },
    { name: "Due Date", selector: (row) => row.dueDate },
    { name: "Currency", selector: (row) => row.currency },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoicedAmount,
      cell: (row) => (
        <p className="text-success">{formatAmount(row.invoicedAmount)}</p>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        const successStatuses = ["CREATED", "APPROVED", "PAID", "FULLY PAID"];
        const rejectedStatuses = ["PARTIALLY PAID", "REJECTED"];
        const awaitingStatuses = [
          "PENDING",
          "AWAITING APPROVAL",
          "AWAITING PAYMENT",
        ];
        return successStatuses.includes(row.status) ? (
          <p className="text-success">{row.status}</p>
        ) : rejectedStatuses.includes(row.status) ? (
          <p className="text-red">{row.status}</p>
        ) : awaitingStatuses.includes(row.status) ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        );
      },
    },
  ];

  const totalPages = (allEarlyPAymentsData?.invoices?.meta?.totalElements)

  const handleRowSelect = (row) => {
    const checkedRows = row.selectedRows.map((curr) => curr.id);
    dispatch(getInvoiceForApprove(checkedRows.toString()));
  };

  return (
    <>
      <AccountModalContainer
        accontModal
        isShown={open}
        onClose={() => {
          setOpen(false);
          setSuccess(false);
        }}
        success={success}
        warning="Are you sure you would like to approve this/these invoice upload?"
      />
      <Card className="rounded-lg">
        <Heading
          setCompanyName={setCompanyName}
          heading={
            !isLoading &&
            `${totalPages} Invoices`
          }
          companyName={companyName}
          invoice
        />
        <div className="rounded-b-lg px-[24px] pb-[24px] min-h-[70vh]">
          <Table
            columns={columns}
            data={allEarlyPAymentsData?.invoices?.data}
            onRowClicked={(row) => InvoiceDetails(row.id)}
            pointer
            progressPending={isLoading}
            handleRowSelect={handleRowSelect}
            progressComponent={<Loading />}
            tableHeader
            className="mt-5"
          />
          <div className="flex justify-center gap-x-[8px] mt-[20px]">
            {!isLoading &&
              allEarlyPAymentsData?.invoices?.data?.length >= 10 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setStartPage={setStartPage}
                  endPage={endPage}
                  setEndPage={setEndPage}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              )}
          </div>
        </div>
      </Card>
    </>
  );
}

export default All;
