import Modal from "layout/modal/Modal";
import React from "react";
import BuyerDetails from "./BuyerDetails";

function BuyerDetailsModal({ isShown, onClose, getId, isPendingRequest }) {
  return (
    <Modal isShown={isShown} hide={onClose} accountModal>
      <BuyerDetails
        onClose={onClose}
        id={getId}
        isPendingRequest={isPendingRequest}
      />
    </Modal>
  );
}

export default BuyerDetailsModal;
