import React, { useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
import { useNavigate } from "react-router-dom";

import Loading from "shared/Loading";
import Pagination from "shared/Pagination";

const SingleTableDirectFund = ({ data, columns }) => {
  const navigate = useNavigate();

  const singleEarlyPaymentDetails = () => {
    let path = "/reinbursement/singledirectfundingdetail";
    navigate(path);
  };

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);

  return (
    <>
      {!data.length ? (
        <div>
          <div className="flex justify-between items-center px-[15px] bg-alabasterHeader h-[60px] rounded-t-[10px]"></div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[15px]">
          <div className="px[15px] pb-[24px]">
            <Table
              columns={columns}
              data={data}
              onRowClicked={(row) => singleEarlyPaymentDetails(row.id)}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {data.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={data.length}
                />
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default SingleTableDirectFund;
