import { PAIconDownload, PAIconSearch, PAIconStep1 } from "assets/images/svgs";
import React from "react";

const TemplateDownloadModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const agreementData = [
    { type: "Loan Agreement", date: "2 days ago" },
    { type: "Financial Agreement", date: "2 days ago" },
    { type: "Partnership Agreement", date: "2 days ago" },
    { type: "Service Agreement", date: "2 days ago" },
    { type: "Sales Agreement", date: "2 days ago" },
    { type: "Lease Agreement", date: "2 days ago" },
    { type: "Employment Agreement", date: "2 days ago" },
  ];

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg">
      <div className="bg-white p-5 px-5 rounded-md max-w-xs md:max-w-sm w-full">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <PAIconSearch className="ml-2 w-5 h-5" />
            <input
              type="text"
              placeholder="Search for template"
              className="outline-none px-3 py-1 rounded-md"
            />
          </div>
          <button
            onClick={onClose}
            className="text-black font-sans text-2xl cursor-pointer flex justify-center items-center"
          >
            &times;
          </button>
        </div>
        <div className="border border-gray"></div>
        <div className="text-black text-lg font-bold font-['SF Pro Display'] mt-2 mb-5">
          Download contract templates
        </div>
        <div className="max-h-[300px] overflow-y-auto px2">
          {agreementData.map((agreement, index) => (
            <div key={index} className="flex items-center justify-between mb-3">
              <div className="flex items-center">
                <PAIconStep1 className="mr-2" />
                <div className="flex flex-col">
                  <p className="text-black font-sans font-medium">
                    {agreement.type}
                  </p>
                  <p className="text-gray500 font-sans text-sm">
                    Last updated: {agreement.date}
                  </p>
                </div>
              </div>
              <div className="text-green-500 fill-blue-500 pr-2">
                <PAIconDownload className="w-5 h-5 cursor-pointer" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TemplateDownloadModal;
