/* eslint-disable react-hooks/exhaustive-deps */
import {
  addBankDetails,
  addBuyerBankDetails,
  addFunderBankDetails,
} from "appstate/auth/authSlice";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import InputField from "shared/InputField";
import Select from "shared/Select";
import Spinner from "shared/Spinner/Spinner";
import { countries } from "utilities/Countries";
import { currencies } from "utilities/Currencies-updated";
import { addBankValidationSchema } from "validation/Addbank/AddbankSchema";
import OnboardingLayout from "../category/components/OnboardingLayout";
import { updateUploadBankDetails } from "./supplierVerifcationSlice/verificationActions";
import {
  getBuyerById,
  getFunderDataById,
  getSupplierById,
} from "appstate/api/auth/onboarding";
import { PAIconArrowLeft } from "assets/images/svgs";
import BvnInputField from "shared/BvnInputField";

const Addbank = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { player } = useParams();
  const { isLoading, userDetails } = useSelector((state) => state.auth);

  const banks = [
    { id: "1", name: "Zenith Bank" },
    { id: "2", name: "GTBank" },
    { id: "3", name: "First Bank of Nigeria" },
    { id: "4", name: "Access Bank" },
    { id: "5", name: "United Bank for Africa" },
    { id: "6", name: "Fidelity Bank" },
    { id: "7", name: "Ecobank Nigeria" },
  ];

  const formikRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      bankName: "",
      countryOfBank: "",
      currency: "",
      accountHolder: "",
      accountNumber: "",
      bvn: "",
    },
    validationSchema: addBankValidationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      const body = {
        bankName: values.bankName,
        bankCountry: values.countryOfBank,
        currency: values.currency,
        accountHolder: values.accountHolder,
        accountNumber: values.accountNumber,
        bvn: values.bvn,
      };
      console.log("==BODY==", body);

      try {
        const response = await (player === "supplier"
          ? dispatch(addBankDetails({ body }))
          : player === "funder"
          ? dispatch(addFunderBankDetails({ body }))
          : dispatch(addBuyerBankDetails({ body })));
        console.log("===SUPPLIERRESPONSE===", response);

        if (response?.payload?.status === 200) {
          toast.success("Bank details verified successfully");
          navigate(`/onboarding/${player}/get-started`);
          dispatch(updateUploadBankDetails(true));
        } else {
          console.log(response?.payload);
          toast.error("Failed to update bank details");
        }
      } catch (error) {
        console.error("Error updating bank details:", error);
        toast.error("An error occurred while updating bank details");
      }
    },
  });

  formikRef.current = formik;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData =
          player === "supplier"
            ? await getSupplierById({
                userId: userDetails?.data?.userId,
              })
            : player === "funder"
            ? await getFunderDataById({
                funderId: userDetails?.data?.userId,
              })
            : await getBuyerById({
                buyerId: userDetails?.data?.userId,
              });
        if (userData) {
          formikRef.current.setValues((prevValues) => ({
            ...prevValues,
            bankName: userData?.data?.bankName || "",
            countryOfBank: userData?.data?.bankCountry || "",
            currency: userData?.data?.currency || "",
            accountHolder: userData?.data?.accountHolder || "",
            accountNumber: userData?.data?.accountNumber || "",
            bvn: userData?.data?.bvn || "",
          }));
        }
      } catch (error) {
        toast.error(error?.response?.data?.message + "!");
      }
    };

    if (userDetails?.data?.userId) {
      fetchUserData();
    }
  }, [userDetails?.data?.userId]);

  const handleClick = () => {
    navigate(-1);
  };

  const goback = () => {
    navigate(-1);
  };

  return (
    <OnboardingLayout cancel={handleClick}>
      <PAIconArrowLeft className="cursor-pointer mx-4  my-4" onClick={goback} />

      <>
        {(player === "funder" || player === "buyer") && !formik.isValid && (
          <div className="mb-[25px] mt-[20px] flex justify-center items-center gap-x-[20px] lg:gap-x-[50px]">
            <div className="relative bottom-[15px]">
              <div className="w-[100px] h-[2px] bg-[#F08000]  rounded-[100px] md:w-[220px]"></div>
            </div>

            <div className="relative bottom-[15px]">
              <div className="w-[100px] h-[2px] bg-[#F08000] rounded-[100px] md:w-[220px]"></div>
            </div>
            <div className="relative bottom-[28px] md:bottom-[30px]">
              <div class="text-[#222222] text-sm font-medium pb-1">
                Add a bank account
              </div>
              <div className="w-[100px] h-[2px] bg-[#F08000]  rounded-[100px] md:w-[220px]"></div>
            </div>
          </div>
        )}
      </>

      <div className="pt-[100px] grid place-content-center h-[100%] overflow-auto md:pt-0 lg:pt-[150px] xl:pt-0">
        {/* <h1 className="font-semibold text-[28px] text-primaryColor text-center mb-[50px]">
          Add a bank account
        </h1> */}

        <div class="text-[#2d0432] text-2xl font-bold text-center mb-[50px] ">
          Add a bank account
        </div>
        <span
          // onClick={() => handleBack()}
          className="left-[3%] cursor-pointer absolute"
          style={{ top: "1rem" }}
        >
          <PAIconArrowLeft />
        </span>
        <form
          onSubmit={formik.handleSubmit}
          className="flex justify-center flex-col"
        >
          <div className="mb-[30px]">
            <Select
              label="Country of bank"
              datas={countries}
              id="countryOfBank"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.countryOfBank}
            />
            {formik.touched.countryOfBank && formik.errors.countryOfBank ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.countryOfBank}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <Select
              label="Bank Name"
              datas={banks}
              id="bankName"
              value={formik.values.bankName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.bankName && formik.errors.bankName ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.bankName}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <Select
              label="Currency"
              datas={currencies}
              id="currency"
              value={formik.values.currency}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.currency && formik.errors.currency ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.currency}
              </p>
            ) : null}
          </div>

          <div className="mb-[30px]">
            <InputField
              className="w-[300px] py-[13px] outline-none md:w-[424px]"
              placeholder=""
              label="Account Number"
              id="accountNumber"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.accountNumber}
            />
            {formik.touched.accountNumber && formik.errors.accountNumber ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.accountNumber}
              </p>
            ) : null}
          </div>

          {formik.values.accountNumber && (
            <div className="mb-[30px]">
              <InputField
                editable
                className="w-[300px] py-[13px] outline-none md:w-[424px]"
                placeholder="Account Name"
                label="Account Name"
                id="accountHolder"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.accountHolder}
              />
              {formik.touched.accountHolder && formik.errors.accountHolder ? (
                <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                  {formik.errors.accountHolder}
                </p>
              ) : null}
            </div>
          )}

          <div className="mb-[30px]">
            <BvnInputField
              className="w-[300px] py-[13px] outline-none md:w-[424px] bg-[#f6f6f6]"
              placeholder="12345678901"
              label="BVN Number"
              id="bvn"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bvn}
              validNin={/^\d{11}$/.test(formik.values.bvn)}
            />
            {formik.touched.bvn && formik.errors.bvn ? (
              <p className="text-red text-xs mt-[8px] w-[300px] md:w-[424px]">
                {formik.errors.bvn}
              </p>
            ) : null}
          </div>

          <button
            type="submit"
            auth
            disabled={isLoading || !formik.isValid}
            className={`my-[30px]   md:w-[424px]  rounded-[5px] text-sm font-medium h-[45px] w-full text-[#222222] border-none  ${
              !formik.isValid
                ? "bg-[#D8D8D8] text-[#7a7a7a]"
                : "  bg-primaryColor text-white"
            }`}
          >
            {isLoading ? <Spinner /> : "Submit"}
          </button>
        </form>
      </div>
    </OnboardingLayout>
  );
};

export default Addbank;
