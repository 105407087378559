import { PAIconEmail } from "assets/images/svgs";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, ResendLinkWrapper } from "./styles";
import toast from "react-hot-toast";
import {  ResendVerificationEmail } from "appstate/api/auth/onboarding";

function ResendVerification() {
  const location = useLocation();
  // eslint-disable-next-line
  const [user, setUser] = useState({ email: "", fullName: "" });
  const handleResendLink = async () => {
    try {
        const response = await ResendVerificationEmail(location?.state);
        setUser(response?.data);
    } catch (error) {
        toast.error("Error resending verification email");
    }
};

  return (
    <Container>
      <ResendLinkWrapper>
        <div className="resend-verification">
          <h1 className="resend-verification__heading">
            Check your inbox to verify your account
          </h1>
          <div className="resend-verification__image-wrapper">
            <PAIconEmail className="resend-verification__image-wrapper--image" />
          </div>
          <p className="resend-verification__info">
            To complete the sign up and log in, click the verification link in
            the email we have sent to
          </p>

          <div className="resend-verification__email">{location?.state}</div>

          <p className="resend-verification__link" onClick={handleResendLink}>
            Resend verification email
          </p>
        </div>
      </ResendLinkWrapper>
    </Container>
  );
}

export default ResendVerification;
