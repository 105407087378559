import { PAIconCaretDown } from "assets/images/svgs";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "shared/Button";
import PAIconBannerImage from "assets/images/svgs/img.png";

const Banner = () => {
  const navigate = useNavigate();
  function handleNavigate() {
    let path = "/category";
    navigate(path);
  }
  return (
    <div className="bg-primaryColor">
      <div className="flex gap-x-20 py-[10px] border-b border-borderColor pl-[24px] lg:pl-[42px] xl:pl-[75px]">
        <div className="flex gap-x-2 items-center">
          <span style={{color: "white"}} className="text-sm md:text-base font-medium">
            Our Solutions
          </span>
          <PAIconCaretDown className="mt-1" />
        </div>
        <div>
          <span style={{color: "white"}} className="text-sm md:text-base font-medium">
            Why PayEdge
          </span>
        </div>
      </div>
      <div className="px-[24px] pb-[100px]  pt-[60px] md:flex md:pt-[10px] md:gap-x-[20px] md:items-center lg:px-[42px] lg:justify-between xl:px-[75px]">
        <div className="lg:mt-[80px]">
          <h1 className="font-black text-[38px] text-white leading-tight md:mt-[40px] lg:mt-0">
            Endless possibilities for
          </h1>
          <h1 className="font-black text-[38px] text-white leading-tight pb-[30px]">
            every business
          </h1>
          <p className="text-lg text-white  font-normal">
            Dedicated to providing businesses with innovative financial
          </p>
          <p className="text-lg text-white  font-normal mb-[30px]">
            solutions that optimize their supply chain operations.
          </p>
          <Button onClick={handleNavigate} gradient>
            Get Started
          </Button>
        </div>
        <div className="mt-[30px] md:mt-0">
          <div className="bg-secondaryColor py-[20px] text-center w-[246px] relative top-36 ml-[5px] lg:w-[291px]  xl:left-[-120px] lg:top-28">
            <p className="text-[20px] font-medium text-white">
              Dynamic discounting
            </p>
          </div>
          <div className="xl:mr-[77px]">
            <img src={PAIconBannerImage} alt="#" className="w-full" />
          </div>
          <div className="flex justify-end relative bottom-32  lg:bottom-28">
            <div className="bg-secondaryColor py-[20px] text-center w-[246px] absolute mr-[15px]">
              <p className="text-[20px] font-medium text-white">
                Reduced financial risk
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
