import React from "react";
import InvoiceReference from "./InvoiceReference";
import Bidinvoice from "./Bidinvoice";
import DashboardLayout from "layout/dashboardlayout";

const PayableInvoiceDetails = () => {
  return (
    <DashboardLayout>
      <div className="flex gap-x-[24px]">
        <div className="w-1/2">
          <InvoiceReference />
        </div>
        <div className="w-1/2">
          <Bidinvoice />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PayableInvoiceDetails;
