import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InvoiceDetails from "shared/InvoiceDetails";
import LoadingScreen from "shared/LoadingScreen";
import { allInvoiceById } from "appstate/invoice/invoiceSlice";

const Details = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { invoiceIdData, isLoading } = useSelector(
    (state) => state?.invoice
  );

  useEffect(() => {
    dispatch(allInvoiceById({ id }));
  }, [dispatch, id]);

  // Calculate the payment based on the due date and created date
  const calculatePayment = (dueDate, createdDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const dueDateObj = new Date(dueDate);
    const createdDateObj = new Date(createdDate?.split(" ")[0]);
    const diffDays = Math.round(
      Math.abs((dueDateObj - createdDateObj) / oneDay)
    );
    return `${diffDays} Days`;
  };
  

  if (isLoading) {
    return <LoadingScreen />;
  }

  const detailsData = [
    {
      label: "Transfer from",
      value: "Elo",
    },
    {
      label: "Transfer to",
      value: "Wale",
    },
    {
      label: "Status",
      value: "Not Paid",
      statusColor: "text-[#07593D]",
    },
    {
      label: "Payment Date",
      value: "Oct 21, 2024 | 02:43PM",
      statusColor: "text-[#000]",
    },
    {
      label: "Transaction ID",
      value: "DbuibYYBH878huhi8UUI89ygt2132h",
      statusColor: "text-[#0076EC]",
    },
    {
      label: "Transaction Type",
      value: "Payment",
    },
    {
      label: "Payment Method",
      value: "PayEdge Wallet",
      statusColor: "text-[#07593D]",
    },
    {
      label: "Payment Channel",
      value: "Open Market",
      statusColor: "text-[#F08000]",
    },
    {
      label: "Description from Sender",
      value: "Payment sent. Appreciate our ongoing collaboration. Regards,",
    },
  ];

  const data = {
    invoiceNumber: `INV-${invoiceIdData?.data?.invoiceNumber}` || "",
    referenceNumber: invoiceIdData?.data?.invoiceReference || "",
    createdDate: invoiceIdData?.data?.createdDate?.slice(0, 10) || "",
    dueDate: invoiceIdData?.data?.dueDate || "",
    payment: calculatePayment(
      invoiceIdData?.data?.dueDate,
      invoiceIdData?.data?.createdDate
    ),
    currency: invoiceIdData?.data?.currency || "",
    supplier: invoiceIdData?.data?.supplierCompanyName || "",
    buyer: invoiceIdData?.data?.buyerCompanyName || "",
    lineItems:
      invoiceIdData?.data?.items?.map((item) => ({
        itemNumber: item?.itemNumber || "",
        poNumber: item.poNumber || "",
        productNumber: item.productNumber || "",
        productDescription: item.productDescription || "",
        quantity: item.quantity || 0,
        unitPrice: item.unitPrice || "",
        extendedAmount: item.extendedAmount || "",
      })) || [],
    attachments: [
      {
        name: "DOC-29384.pdf",
        fileType: "PDF",
        uploadDateTime: "2021-09-14 13:25",
        downloadUrl: "https://example.com/doc-29384.pdf",
      },
    ],
  };

  return (
    <>
      <div className="grid lg:grid-cols-[60%,40%] space-x-5">
        {/* 60% */}
        <InvoiceDetails data={data} showSendReminderButton={false} />
        {/* 40% */}
        <div className="bg-white rounded-[10px] gap-8 px-6 pt-7 pb-24">
          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="font-semibold text-lg leading-[30px]">
                Collection Transaction Details
              </h2>
              <button className="border border-gray300 rounded px-3 py-1.5">
                Export
              </button>
            </div>

            <div className="flex justify-between my-3 border-b-[3px] border-b-[#F6F5FA] pb-5">
              <div>
                <h5 className="text-base font-normal">Amount</h5>
                <h2 className="text-[#07593D] text-2xl font-normal">
                  +9,000,000 NGN
                </h2>
              </div>
            </div>
            <div className="mt-8">
              <div className="flex flex-col gap-3">
                {detailsData.map((item, index) => (
                  <div key={index} className="mb-4 flex justify-between">
                    <div>
                      <p className="text-[#222] font-medium text-sm">
                        {item.label}
                      </p>
                    </div>
                    <div className="flex-1 ml-4 max-h-[50px]">
                      <p className="text-[#979797] font-medium text-sm flex justify-end text-right">
                        <span className={item.statusColor}>{item.value}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
