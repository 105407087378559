import { allBuyerAssociatedFunders } from "appstate/buyer/buyerSlice";
import { getFunderAssociatedSuppliers } from "appstate/funder/funderSlice";
import { getAllSupplierAssociatedFunder } from "appstate/supplier/supplierSlice";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "shared/Pagination";

const Recipient2 = ({ onEmailClick, setGetName }) => {
  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const pageSize = 10;
  const { userDetails } = useSelector((state) => state.auth);
  const userRole = userDetails?.data?.role;

  const { funderAssociatedSuppliersData } = useSelector(
    (state) => state?.funder
  );

  const { buyerAssociatedFundersData } = useSelector((state) => state?.buyer);

  const { supplierAssociatedFundersData } = useSelector(
    (state) => state?.supplier
  );

  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const search = "";
    switch (userRole) {
      case "SUPPLIER":
        dispatch(getAllSupplierAssociatedFunder({ page, pageSize, search }));
        break;
      case "BUYER":
        dispatch(allBuyerAssociatedFunders({ page, pageSize, search }));
        break;
      case "FUNDER":
        dispatch(getFunderAssociatedSuppliers({ page, pageSize, search }));
        break;
      default:
        toast.error("Invalid user role:", userRole);
    }
  }, [dispatch, userRole, page]);

  useEffect(() => {
    switch (userRole) {
      case "SUPPLIER":
        setData(supplierAssociatedFundersData?.data);
        break;
      case "BUYER":
        setData(buyerAssociatedFundersData?.data);
        break;
      case "FUNDER":
        setData(funderAssociatedSuppliersData?.data);
        break;
      default:
        toast.error("Invalid user role:", userRole);
    }
  }, [
    userRole,
    supplierAssociatedFundersData,
    buyerAssociatedFundersData,
    funderAssociatedSuppliersData,
  ]);

  const totalPagesToRender =
    userRole === "SUPPLIER"
      ? supplierAssociatedFundersData
      : userRole === "BUYER"
      ? buyerAssociatedFundersData
      : funderAssociatedSuppliersData;

  const totalPages = totalPagesToRender?.meta?.totalElements;

  const handleEmailClick = (associate) => {
    if (onEmailClick) {
      onEmailClick(associate?.email);
      setGetName(associate?.fullName);
    }
  };

  return (
    <div className="max-h-[300px] overflow-y-auto px2">
      {data?.map((associate, index) => (
        <div
          key={index}
          className="flex items-center justify-between mb-3 cursor-pointer pl-5"
          onClick={() => handleEmailClick(associate)}
        >
          <div className="flex items-center">
            <div className="flex flex-col">
              <p className="text-black font-sans font-medium">
                {associate.fullName}
              </p>
              <p className="text-gray500 font-sans text-sm">
                Email: {associate.email}
              </p>
            </div>
          </div>
        </div>
      ))}
      {totalPages > 10 && (
        <Pagination
          page={page}
          startPage={startPage}
          setEndPage={setEndPage}
          endPage={endPage}
          setStartPage={setStartPage}
          setPage={setPage}
          totalPages={totalPages}
        />
      )}
    </div>
  );
};

export default Recipient2;
