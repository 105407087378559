import {
  PAIconArrowLeftGreen,
  PAIconCocacola,
  PAIconDownload,
  PAIconSmallBrewIcon,
  PAIconLockCloseIcon,
  PAIconSmallCoke,
  PAIconSmallShell,
} from "assets/images/svgs";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DetailCard from "../card/DetailCard";
import UpdateBillsModal from "../../modals/UpdateBillsModal";
import OngoingAcceptUpdateBidModal from "../../modals/AccepUpdateBidModal";
import InvoiceLockedModal from "../../modals/InvoiceLockedModal";
import OngoingCancelBidModal from "../../modals/OngoingCancelBidModal";
import OngoingBidCancelledSuccessModal from "../../modals/OngoingBidsCancelSuccessful";

const Details = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ongoingAcceptUpdateBidModal, setOngoingAcceptUpdateBidModal] =
    useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [ongoingCancelBidModal, setOngoingCancelBidModal] = useState(false);
  const [ongoingBidCancelledSuccessModal, setOngoingBidCancelledSuccessModal] =
    useState(false);
  const goBack = () => {
    navigate(-1);
  };
  const handleOpenUpdateBid = () => {
    setIsModalOpen(true);
  };

  const handleUpdateBid = () => {
    setIsModalOpen(false);
    setOngoingAcceptUpdateBidModal(true);
  };

  const dummyData = [
    {
      index: 1,
      ivnNuM: "INV-2002",
      poNum: "PO-2002",
      itmNum: "ITM3201",
      cpn: "Dangote Cement ",
      qty: "20",
      prQt: "$30",
      amount: "$600",
    },
    {
      index: 2,
      ivnNuM: "INV-2002",
      poNum: "PO-2002",
      itmNum: "ITM3201",
      cpn: "Dangote Cement ",
      qty: "20",
      prQt: "$30",
      amount: "$600",
    },
  ];

  const rawData = [
    {
      funderName: "ABC Limited",
      discountRate: "2.5%",
      status: "Locked",
      biddingTime: "01:45:11:34",
    },
    {
      funderName: "Defi LTD",
      discountRate: "3.6%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
    {
      funderName: "Union Bank",
      discountRate: "4.3%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
  ];

  const bidrawData = [
    {
      funderName: "Shell PLC",
      discountRate: "5.8%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
    {
      funderName: "Tecna LTD",
      discountRate: "8%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
    {
      funderName: "Access bank",
      discountRate: "9.1%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
    {
      funderName: "FirstBank",
      discountRate: "10%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
    {
      funderName: "Dangote",
      discountRate: "12.3%",
      status: "Unlocked",
      biddingTime: "01:45:11:34",
    },
  ];

  const handleAccept = () => {
    setIsInvoiceModalOpen(true);
    setOngoingAcceptUpdateBidModal(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // setRejectModalOpen(false);
  };
  const handleInvoiceModalClose = () => {
    setIsInvoiceModalOpen(false);
    goBack();
  };
  const handleOnCancelSuccessClose = () => {
    setOngoingBidCancelledSuccessModal(false);
    goBack();
  };
  const handleOnCancelBidTrue = () => {
    setOngoingCancelBidModal(false);
    setOngoingBidCancelledSuccessModal(true);
  };
  return (
    <>
      <UpdateBillsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        handleYes={handleUpdateBid}
      />
      <OngoingAcceptUpdateBidModal
        isOpen={ongoingAcceptUpdateBidModal}
        onClose={() => setOngoingAcceptUpdateBidModal(false)}
        refNum={"R3456X89Y"}
        handleYes={handleAccept}
      />
      <InvoiceLockedModal
        isOpen={isInvoiceModalOpen}
        onClose={handleInvoiceModalClose}
      />
      <OngoingCancelBidModal
        isOpen={ongoingCancelBidModal}
        onClose={() => setOngoingCancelBidModal(false)}
        refNum={"R3456X89Y"}
        handleYes={handleOnCancelBidTrue}
      />
      <OngoingBidCancelledSuccessModal
        isOpen={ongoingBidCancelledSuccessModal}
        onClose={handleOnCancelSuccessClose}
        message={"Your bid has been cancelled"}
      />
      <Link
        onClick={goBack}
        className="flex items-center gap-x-[3px] mb-[20px] cursor-pointer w-full"
      >
        <PAIconArrowLeftGreen />
        <span className="flex items-center text-primaryColor">Go Back</span>
      </Link>

      <div className="grid lg:grid-cols-[60%,40%] gap-5">
        <div className="flex flex-col gap-8 pb-24 bg-white rounded-[10px] py-12">
          <div className="px-6 ">
            <div className="flex flex-col gap-1">
              <div class="h-[21px] justify-start items-center inline-flex">
                <div class="text-[#222222] text-lg font-semibold">
                  Reference No: R3456X89Y
                </div>
              </div>
              <div className=" flex justify-between items-center flex-row">
                <h1 className="text-[18px] font-bold">Invoice | INV-2002</h1>
                <div>
                  <div class="text-center text-[#f08000] text-xs font-semibold ">
                    Approved
                  </div>
                </div>
              </div>

              <main className=" w-full  ">
                <div className="flex flex-col gap-3 w-1/2">
                  {/* starts flex */}
                  {/* <div className=" flex flex-row w-full justify-between bg-red"> */}{" "}
                  <div className="flex flex-col gap-1">
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs w-[97px]">Created Date:</span>
                      <span className="text-[#979797]  text-xs">
                        23-Dec-2024
                      </span>
                    </div>
                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs text-[#222222] ">
                        Payment Due Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        23-Dec-2024
                      </span>
                    </div>

                    <div className="flex gap-x-[50px]">
                      <span className=" text-xs text-[#222222] ">
                        Early Payment Date:
                      </span>
                      <span className="text-[#979797]  text-xs">
                        23-Dec-2024
                      </span>
                    </div>
                  </div>
                  {/* seconded here */}
                </div>
                {/* the  next starts here */}

                <div>
                  <div>
                    <div class="text-right text-[#222222] text-base font-bold ">
                      N23,000,000
                    </div>
                    <div class="text-[#979797] text-xs font-normal text-right">
                      Request amount
                    </div>
                  </div>
                </div>

                {/* stops here */}
                {/* </div> */}
              </main>
            </div>

            <div className="flex justify-between ">
              <div className="flex flex-col">
                <span className="font-bold text-sm">Payment</span>
                <span className="text-[#979797]  text-xs">60 Days</span>
              </div>
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Currency</span>
                <span className="text-[#979797]  text-xs">NGN</span>
              </div>
            </div>

            <div className="flex flex-col gap-4 my-7">
              <header className="border-b-[5px] border-b-[#F6F5FA] ">
                <h1 className="text-[18px] font-bold">
                  Supplier/buyer details
                </h1>
              </header>
              <div className="flex  justify-between mb-5">
                <div className="flex gap-2">
                  <PAIconCocacola />
                  <div className="flex flex-col">
                    <span class="text-black text-xs font-semibold ">
                      Coca Cola
                    </span>
                    <span className="text-[#979797]  text-xs">Supplier</span>
                  </div>
                </div>
                <div className="flex gap-2">
                  <PAIconSmallBrewIcon />
                  <div className="flex flex-col">
                    <span class="text-black text-xs font-semibold ">
                      ABC Limited
                    </span>
                    <span className="text-[#979797]  text-xs">Buyer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <header className="border-b-[5px] border-b-[#F6F5FA] ">
                <h1 className="text-sm font-bold">Line Items</h1>
              </header>
              <div className=" w-full overflow-x-auto mt-1">
                <table className=" w-full overflow-x-auto">
                  <thead className="bg-subtleGray w-">
                    <tr className=" bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] p-2.5 text-xs font-bold">
                        Item Number
                      </td>
                      <td className=" p-2.5 text-xs font-bold">PO Number</td>
                      <td className=" p-2.5 text-xs font-bold">
                        Product/Service Number
                      </td>
                      <td className=" p-2.5 text-xs font-bold">
                        Product/Service Description
                      </td>
                      <td className=" p-2.5 text-xs font-bold">Quantity</td>
                      <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                      <td className=" p-2.5 text-xs font-bold">
                        Line Item Extended Amount
                      </td>
                    </tr>
                  </thead>

                  {dummyData.map((item, index) => (
                    <tbody key={index}>
                      <tr className=" py-[3px] px-1.5">
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.ivnNuM}
                        </td>

                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.poNum}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.itmNum}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.cpn}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.qty}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.prQt}
                        </td>
                        <td className=" text-xs text-[#11192A] p-2.5">
                          {item.amount}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-5">
              <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
                <h1 className="text-sm font-bold">Attachments</h1>
              </header>
              <div className="w-full overflow-x-auto">
                <table className="w-full overflow-x-auto">
                  <thead className="  bg-subtleGray w-">
                    <tr className="px-4  bg-unusualGray p-2.5 ">
                      <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                        Document Name
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">
                        File Type
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">
                        Upload Date / Time
                      </td>
                      <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className=" py-[3px] px-1.5">
                      <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                        DOC-29384.pdf
                      </td>
                      <td className=" text-xs text-[#11192A] px-4 py-2.5">
                        PDF
                      </td>
                      <td className=" text-xs text-[#11192A] px-4 py-2.5">
                        2021-09-14 13:25
                      </td>
                      <td className="cursor-pointer text-xs text-[#11192A] px-4 py-2.5">
                        <PAIconDownload />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-[10px] gap-8 ">
          {/* <FundDetailSide /> */}
          {/* second */}
          <div className=" py-8 px-6 border-b border-[#c1c1c1]/60">
            {/* first flebox */}
            <div className=" flex flex-row justify-between w-full">
              <div class="text-black text-xl font-semibold ">Bid Invoice</div>
              <button
                class=" p-2.5 bg-white rounded-[5px] border border-[#bb0c0c] justify-center items-center gap-2 inline-flex"
                onClick={() => setOngoingCancelBidModal(true)}
              >
                <div className=" border-r-[1.5px] flex gap-3  pr-2">
                  {" "}
                  <div class="text-[#bb0c0c] text-sm font-medium items-center ">
                    Cancel
                  </div>
                  <div class="justify-center items-center flex">
                    <div class=" relative">
                      <PAIconLockCloseIcon />
                    </div>
                  </div>
                </div>
                <div class="justify-start items-center gap-2 flex">
                  {/* <div class="w-[2px] h-full border border-[#222222]"></div> */}
                  <div class="text-[#222222] text-sm font-normal ">
                    00h:56m:23s
                  </div>
                </div>
              </button>
            </div>

            {/* button section */}

            <button
              class="h-[47px] px-2.5 py-[15px] bg-[#07593d] rounded-[5px] justify-center items-center gap-2.5 inline-flex mt-8 w-full cursor-pointer"
              onClick={handleOpenUpdateBid}
            >
              <div class="text-white text-base font-semibold ">Update Bid</div>
            </button>
          </div>

          {/* Last section */}
          <div className="py-8 px-6  gap-8 flex flex-col ">
            {/*  Winning Bid section */}
            <div>
              {/* first outline card */}
              <div className=" bg-[#07593d]/5 rounded-[5px] border border-[#07593d] relative ">
                <div class="text-black text-xs font-semibold absolute -left-0.5 -top-2.5 z-1111 bg-white px-1 ">
                  Winning Bid
                </div>
                <div class=" justify-between  flex px-2  flex-row  items-center  ">
                  <div class=" items-center flex gap-3">
                    <PAIconSmallCoke />
                    <div class="py-2.5 flex-col justify-start items-start gap-0.5 inline-flex">
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div className="text-black text-xs font-normal">
                          ABC Limited
                        </div>
                      </div>
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        {/* <div className=" text-[#949494] text-[10px]">
                          Funder
                        </div> */}
                        <div class="text-[#949494] text-[10px] font-normal ">
                          Funder
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-2.5 flex-col gap-0.5 inline-flex">
                    <div class="flex-col  justify-start items-start gap-0.5 flex">
                      <div className="text-black text-xs font-normal">2.5%</div>
                    </div>
                    <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                      <div className=" text-[#949494] text-[10px]">
                        Discount Rate{" "}
                      </div>
                    </div>
                  </div>
                  <div class=" p-2.5 flex-col  gap-0.5 inline-flex">
                    <div class="flex-col justify-center items-center gap-0.5 flex">
                      <div className={`font-[500] text-sm ${"text-[#f08000]"}`}>
                        Locked
                      </div>
                    </div>
                    <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                      <div className=" text-[#959595] text-[10px]">Status</div>
                    </div>
                  </div>
                  <div class="w-[129px] p-2.5 flex-col justify-start items-end gap-0.5 inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div className="font-[500] text-sm">01:45:11:34</div>
                    </div>
                    <div class="flex-col justify-start items-end gap-0.5 flex">
                      <div className=" text-[#959595] text-[10px]">
                        Bidding Time
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {rawData.map((item, idx) => {
                return (
                  <DetailCard
                    key={idx}
                    funderName={item.funderName}
                    discountRate={item.discountRate}
                    statusOfInvoice={item.status}
                    biddingTime={item.biddingTime}
                  />
                );
              })}
            </div>

            {/* Your bid seCTION */}
            <div>
              <div className=" bg-[#f08000]/5 rounded-[5px] border border-[#f08000] relative ">
                <div class="text-black text-xs font-semibold absolute -left-0.5 -top-2.5 z-1111 bg-white px-1 ">
                  Your Bid
                </div>
                <div class=" justify-between  flex px-2  flex-row  items-center  ">
                  <div class=" items-center flex gap-3">
                    <PAIconSmallShell />
                    <div class="py-2.5 flex-col justify-start items-start gap-0.5 inline-flex">
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div className="text-black text-xs font-normal">
                          Unity Bank
                        </div>
                      </div>
                      <div class="flex-col justify-start items-start gap-0.5 flex">
                        <div class="text-[#949494] text-[10px] font-normal ">
                          Funder
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-2.5 flex-col gap-0.5 inline-flex">
                    <div class="flex-col  justify-start items-start gap-0.5 flex">
                      <div className="text-black text-xs font-normal">4.5%</div>
                    </div>
                    <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                      <div className=" text-[#949494] text-[10px]">
                        Discount Rate{" "}
                      </div>
                    </div>
                  </div>
                  <div class=" flex-col  gap-0.5 inline-flex">
                    <div class="flex-col justify-center items-center  flex">
                      <div className={`font-[500] text-sm `}>Unlocked</div>
                    </div>
                    <div class="h-3 flex-col justify-start items-start gap-0.5 flex">
                      <div className=" text-[#959595] text-[10px]">Status</div>
                    </div>
                  </div>
                  <div class="w-[129px] p-2.5 flex-col justify-start items-end gap-0.5 inline-flex">
                    <div class="flex-col justify-start items-start gap-0.5 flex">
                      <div className="font-[500] text-sm">01:45:11:34</div>
                    </div>
                    <div class="flex-col justify-start items-end gap-0.5 flex">
                      <div className=" text-[#959595] text-[10px]">
                        Bidding Time
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {bidrawData.map((item, idx) => {
                return (
                  <DetailCard
                    key={idx}
                    funderName={item.funderName}
                    discountRate={item.discountRate}
                    statusOfInvoice={item.status}
                    biddingTime={item.biddingTime}
                  />
                );
              })}
            </div>

            {/* stop it */}
          </div>

          {/* search section */}

          {/* stops here */}
        </div>
      </div>
    </>
  );
};

export default Details;
