/* eslint-disable react-hooks/exhaustive-deps */
import {
  PAIconConfirm,
  PAIconInfo,
  PAIconMDISuccess,
} from "assets/images/svgs";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Button from "shared/Button";
import InputField from "shared/InputField";
import ConfirmModal from "./ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { directFundingRequest } from "appstate/supplier/supplierSlice";

const SingleRequestModalContent = ({ onClose, data }) => {
  const dispatch = useDispatch();
  const { directFundingLoading, approvedInvoiceId, associatedFunderId } =
    useSelector((state) => state?.supplier);

  const mockData = {
    requestAmount: "10000",
    newPaymentDate: "2024-12-31",
    description: "Requesting direct funding due to project deadlines.",
  };

  const [enableDiscount, setEnableDiscount] = useState(false);
  const [applied, setApplied] = useState(false);
  const [amount, setAmount] = useState(mockData.requestAmount);
  const [discountedAmount, setDiscountedAmount] = useState("");
  const [open, setOpen] = useState(false);
  const [newPaymentDate, setNewPaymentDate] = useState(mockData.newPaymentDate);
  const [description, setDescription] = useState(mockData.description);

  const discountRate = 1.67;

  useEffect(() => {
    handleAmountChange({ target: { value: amount } });
    setEnableDiscount(true);
  }, []);

  const handleAmountChange = (event) => {
    const value = event.target.value;
    setAmount(value);

    const parsedAmount = parseFloat(value);
    if (!isNaN(parsedAmount)) {
      const discountedValue = parsedAmount - (parsedAmount * discountRate) / 100;
      setDiscountedAmount(discountedValue.toFixed(2));
    } else {
      setDiscountedAmount("");
    }
  };

  const handleSetNewAmount = () => {
    setApplied(false);
    setAmount("");
    setDiscountedAmount("");
    setEnableDiscount(false);
  };

  function handleSendRequest() {
    const body = {
      invoiceIds: [approvedInvoiceId],
      discountRate: discountRate,
      description: description,
      newPaymentDate: newPaymentDate,
      requestedAmount: parseFloat(amount),
      funderId: associatedFunderId,
    };
    // console.log(body);
    dispatch(directFundingRequest(body)).then((data) => {
      if (data?.payload?.success) {
        setOpen(false);
        toast.custom(
          <div className="flex mt-[200px]">
            <div className="bg-primaryColor w-[147.791px] h-[160px] flex flex-col items-center justify-center rounded-l-[7px] shadow-lg">
              <PAIconMDISuccess />
            </div>
            <div className="bg-white flex flex-col justify-center items-center w-[394.538px] h-[160px] rounded-r-[7px] shadow-lg">
              <PAIconConfirm />
              <p className="text-dustyGray text-[13px] text-center mt-[17.6px]">
                Direct funding request successfully sent.
              </p>
            </div>
          </div>
        );
        onClose();
      }
    });
  }

  function handleOpenModal() {
    if (!description || !newPaymentDate) {
      toast.error("Description or new payment date are required.");
      return;
    }
    setOpen(true);
  }

  return (
    <>
      {open && (
        <div className="absolute mt-[20px] top-1/2 left-2/3 transform -translate-x-1/2 -translate-y-1/2 bg-white w-[614px] h-[254px] z-10 rounded-[10px] shadow-lg">
          <ConfirmModal
            hide={() => setOpen(false)}
            isLoading={directFundingLoading}
            handleAssociate={handleSendRequest}
            warning="Would you like to proceed with sending the Fund request?"
          />
        </div>
      )}
      <div className="p-[39px]">
        <div className="flex justify-between">
          <div className="flex-1">
            <div className="flex items-center gap-x-[23px]">
              <h1 className="text-textColor text-[20px] font-bold">
                Invoice | INV-{data?.invoiceNumber || "INV-2002"}
              </h1>
              <p className="text-textColor text-sm">
                Reference No: {data?.invoiceReference || "123484BH"}
              </p>
            </div>
            <div>
              <span className="text-textColor text-xs mr-[10px]">
                Created Date:{" 23 Dec 2024"}
              </span>
              <span className="text-lightGray text-xs border-r pr-[10px]">
                {data?.createdDate}
              </span>
              <span className="text-textColor text-xs ml-[10px]">
                Payment Due Date:{" 23-Dec-2024"}
              </span>
              <span className="text-lightGray text-xs ml-[10px]">
                {data?.dueDate}
              </span>
            </div>
          </div>
          <Button
            neutral
            type="Button"
            disabled={!amount}
            onClick={handleOpenModal}
            className="border flex justify-center items-center border-[#AFAFAF] rounded-[5px] text-xs text-[#616161] w-[135px]  h-[30px]"
          >
            Send Request
          </Button>
        </div>
        <div className="mt-[25px]">
          <p className="text-textColor font-extrabold text-base italic">
            Invoice Amount
          </p>
          <h1 className="text-primaryColor text-2xl font-extrabold">
            NG-{data?.total || "340000"}
          </h1>
          <h1 className="text-textColor text-sm italic font-extrabold mt-[19px]">
            Fund Request
          </h1>
        </div>
        <div className="mt-[20px]">
          <div className="flex justify-between">
            <InputField
              label="New Payment Date:"
              type="date"
              value={newPaymentDate}
              onChange={(e) => setNewPaymentDate(e.target.value)}
              className=" w-[369px] h-[39px] rounded-[5px] outline-none !border border-[#AFAFAF] px-[5px]"
            />
            <div>
              {!applied ? (
                <>
                  {" "}
                  <InputField
                    label="Set Request Amount"
                    placeholder="₦"
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    className=" w-[369px] h-[39px] rounded-[5px] !border border-[#AFAFAF] px-[5px] outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                  {!enableDiscount && (
                    <Button
                      onClick={() => setEnableDiscount(true)}
                      typeof="Button"
                      disabled={!amount}
                      neutral
                      className="!text-secondaryColor text-sm mt-[5px] float-right"
                    >
                      Check for available discount
                    </Button>
                  )}
                  {enableDiscount && (
                    <div className="mt-[10px] flex justify-between">
                      <div>
                        <p className="text-textColor text-xs font-medium border-b border-[#AFAFAF] w-[84px]">
                          Discount:{" "}
                          <span className="text-[#17191C] text-xs">
                            {discountRate}%
                          </span>
                        </p>
                        <div className="flex items-center mt-[2px]">
                          <PAIconInfo />
                          <p className="text-[#F08000] text-[10px] font-medium">
                            Tap to view more
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-textColor text-xs">
                          Discounted Request Amount:{" "}
                          <span className="text-primaryColor text-xs font-extrabold border-b border-[#AFAFAF] pb-[2px]">
                            {discountedAmount && "N" + discountedAmount}
                          </span>
                        </p>
                        <div className="flex justify-end mt-[5px]">
                          <Button
                            neutral
                            // onClick={() => setApplied(true)}
                            disabled={!amount}
                            type="Button"
                            className="w-[87px] h-[24px] text-white bg-secondaryColor text-xs rounded-[5px]"
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex justify-end">
                  <div>
                    <p className="text-xs text-primaryColor">
                      NG-{discountedAmount}
                    </p>
                    <Button
                      onClick={handleSetNewAmount}
                      typeof="Button"
                      className="border border-[#AFAFAF] rounded-[5px] text-xs text-[#616161] w-[111px]  h-[30px] bg-white mt-[5px]"
                    >
                      Set new amount
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-[27px]">
            <p className="text-textColor text-xs font-medium mb-[5px]">
              Description
            </p>
            <textarea
              className="w-full border border-[#AFAFAF] rounded-[5px] outline-none px-[5px]"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="4"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleRequestModalContent;

