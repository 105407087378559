import { PAIconGlobal } from 'assets/images/svgs'
import React from 'react'

const ServicesCard = ({heading, text}) => {
  return (
    <div className='flex flex-col items-center bg-brightGray100 px-[30px] pt-[30px] lg:px-[15px] xl:px-[30px] pb-[40px] 2xl:w-[320px] rounded-[36px] mb-[30px] hover:scale-105 ease-in-out duration-300 lg:h-[297.82px]'>
        <h3 className='font-normal text-2xl text-textColor mb-[30px] text-center'>{heading}</h3>
        <PAIconGlobal className='mb-[30px]' />
        <p className='font-normal text-lg text-center'>{text}</p>
    </div>
  )
}

export default ServicesCard