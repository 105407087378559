/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Table from "layout/table/Table";
import Card from "shared/Card";
import TableEmptyState from "shared/TableEmptyState";
// import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";
import Pagination from "shared/Pagination";
import Loading from "shared/Loading";

const DirectFundingTable = () => {
  const activeButton = useSelector((state) => state?.invoice?.activeButton);
  const isLoading = false;
  // const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const [data, setData] = useState([]);
  const pageSize = 10;

  const dummyData = [
    { id: 1, invoiceNumber: "INV001", funderCompanyName: "Funder A", invoiceDate: "2023-07-01", newRequestedDate: "2023-07-10", currency: "USD", invoiceAmount: "$1000", status: "APPROVED" },
    { id: 2, invoiceNumber: "INV002", funderCompanyName: "Funder B", invoiceDate: "2023-07-02", newRequestedDate: "2023-07-11", currency: "USD", invoiceAmount: "$2000", status: "PENDING" },
    { id: 3, invoiceNumber: "INV003", funderCompanyName: "Funder C", invoiceDate: "2023-07-03", newRequestedDate: "2023-07-12", currency: "USD", invoiceAmount: "$3000", status: "REJECTED" },
  ];

  useEffect(() => {
    setData(dummyData);
  }, []);

  // Disabling the data fetching effect
  /*
  useEffect(() => {
    dispatch(
      allDirectFunding({
        page,
        pageSize,
        bulk: activeButton === "single" ? false : true,
      })
    );
  }, [dispatch, page, pageSize, activeButton]);
  */

  const columns = [
    {
      name: (
        <span className="whitespace-nowrap">
          <img className="pr-1 inline" src="/group-icon.svg" alt="" />
          Invoice Number
        </span>
      ),
      selector: (row) => row.invoiceNumber,
      sortable: true,
    },
    {
      name: "Funder",
      selector: (row) => row.funderCompanyName,
      sortable: true,
    },
    {
      name: "Invoice Due Date",
      selector: (row) => {
        const invoiceDate = new Date(row.invoiceDate);
        return invoiceDate.toISOString().split("T")[0];
      },
      sortable: true,
    },
    {
      name: "New Request Date",
      selector: (row) => row.newRequestedDate,
      sortable: true,
    },
    {
      name: "Currency",
      selector: (row) => row.currency,
      sortable: true,
      width: "6.2rem",
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmount,
      sortable: true,
      cell: (row) => <p className="text-success">{row.invoiceAmount}</p>,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row.status === "APPROVED" ? (
          <p className="text-success">{row.status}</p>
        ) : row.status === "REJECTED" ? (
          <p className="text-red">{row.status}</p>
        ) : row.status === "UPDATED" ? (
          <p className="text-blue-300">{row.status}</p>
        ) : row.status === "PENDING" ? (
          <p className="text-yellow-400">{row.status}</p>
        ) : (
          <p>{row.status}</p>
        ),
    },
  ];

  const totalPages = Math.ceil(dummyData.length / pageSize);

  return (
    <>
      {!dummyData.length ? (
        <div className="ml-3">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <div>
              <p className="text-black text-lg font-bold">Direct funding request</p>
              <span className="text-xs font-thin">
                {activeButton === "single"
                  ? "List of single payment extension requests you've made"
                  : "List of bulk payment extension requests you've made"}
              </span>
            </div>
          </div>
          <TableEmptyState dashboard />
        </div>
      ) : (
        <Card className="rounded-[10px] mt-[24px] ml-3">
          <div className="flex justify-between items-center px-[24px] bg-alabasterHeader h-[60px] rounded-t-[10px]">
            <div>
              <p className="text-black text-lg font-bold">Direct funding request</p>
              <span className="text-xs font-thin">
                {activeButton === "single"
                  ? "List of single payment extension requests you've made"
                  : "List of bulk payment extension requests you've made"}
              </span>
            </div>
          </div>
          <div className="px-[24px] pb-[24px]">
            <Table
              columns={columns}
              data={data}
              progressPending={isLoading}
              progressComponent={<Loading />}
              pointer
              tableHeader
              className="mt-5"
            />
            <div className="flex justify-center gap-x-[8px] mt-[20px]">
              {dummyData.length > 0 && (
                <Pagination
                  page={page}
                  startPage={startPage}
                  setEndPage={setEndPage}
                  endPage={endPage}
                  setStartPage={setStartPage}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default DirectFundingTable;
