import {
  PAISelectPaymentOptionLogo,
  PAISelectBoxFilled,
} from "assets/images/svgs";
import React from "react";

import Button from "shared/Button";
import Spinner from "shared/Spinner/Spinner";

const SelectPaymentModal = ({
  isOpen,
  onClose,
  message,
  onConfirm,
  loading,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]">
      <div className="bg-white p-5 rounded-md shadow-md flex flex-col items-center px-10 ">
        <PAISelectPaymentOptionLogo />
        {message && (
          <p className="text-[#979797] text-center  text-base font-normal leading-normal tracking-wide mb-3 my-5">
            {message}
          </p>
        )}
        <div className="flex  mt-3  gap-6 w-full">
          <Button
            neutral
            disabled={loading}
            className="flex  justify-center items-center    !text-white bg-primaryColor border !border-[#07593D] rounded-md md:w-1/2 "
            onClick={onConfirm}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div className=" flex flex-row px-3 justify-between gap-3 md:min-w-[210px] py-2">
                <div class="flex flex-col ">
                  <span className="text-white text-xs font-normal ">
                    Make payment via{" "}
                  </span>
                  <span className="text-white text-xs font-bold ">Wallet</span>
                </div>
                <div className=" flex  items-baseline justify-end">
                  {" "}
                  <PAISelectBoxFilled />
                </div>
              </div>
            )}
          </Button>

          <Button
            neutral
            disabled={loading}
            className="flex  justify-center items-center    !text-white bg-primaryColor border !border-[#07593D] rounded-md md:w-1/2 "
            onClick={onConfirm}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div className=" flex flex-row px-3 justify-between gap-3 md:min-w-[210px] py-2">
                <div class="flex flex-col ">
                  <span className="text-white text-xs font-normal ">
                    Make payment via
                  </span>
                  <span className="text-white text-xs font-bold ">
                    Third Party Funding
                  </span>
                </div>
                <div className=" flex  items-baseline justify-end">
                  <PAISelectBoxFilled />
                </div>
              </div>
            )}
          </Button>
        </div>
        <div className="flex w-full flex-row justify-end items-end mt-12">
          <button
            class="px-7 py-1 rounded-md border border-[#EE0816] justify-center items-center gap-2.5 inline-flex"
            onClick={onClose}
          >
            <div class="text-center text-[#EE0816] text-base font-normal  ">
              Cancel
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectPaymentModal;
