function OverviewHeader({ toggleModalOn, activateButton }) {
  return (
    <div className='rounded-md w-full bg-white mt5 mx6 mb-[17px]'>
      <div className='flex gap-4 p-4 px-7 justify-between '>
        <div className='flex items-center text-black font-medium text-base'>
          Overview
        </div>

        <div className=' flex gap-5'>
          <div className='flex items-center gap-4'>
            {/* chat */}
            <div className='relative cursor-pointer'>
              <div className='absolute -top-2.5 -left-1 bg-[#07593D] text-white rounded-full w-4 h-4 flex items-center justify-center text-xs font-thin'>
                3
              </div>
              <img src='/chat.svg' alt='Chat' />
            </div>
            {/* Request  */}
            <div className='relative cursor-pointer'>
              <div className='absolute -top-2.5 -left-2 bg-[#07593D] text-white rounded-full w-4 h-4 flex items-center justify-center text-[15px] font-thin'>
                <span className=' text-xs'>8</span>
              </div>
              <span className='flex items-center gap-1 text-orange-500 text-[15px] font-semibold'>
                <img src='/outline.svg' alt='Request' />{' '}
                <span className=' text-[15.114px]'>Request</span>
              </span>
            </div>
          </div>

          {activateButton && (
            <div>
              <button
                onClick={() => toggleModalOn()}
                className=' text-xs py-[9px] px-[11px] rounded border border-activation transition duration-500 transform hover:scale-105 ease-in-out hover:bg-whisper200'
              >
                <span>
                  <span className='hidden md:inline '>Activate</span>{' '}
                  <span>Payable Financing</span>
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OverviewHeader;
