import request from "apiInstance";
import toast from "react-hot-toast";

export const buyersAssociatePendingRequest = async ({
  page,
  pageSize,
  sender,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/buyers/pending-association-requests?sender=${sender}&search=${search}&page=${page}&size=${pageSize}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerAcceptSupplierRequest = async (notificationId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/association-request/accept/${notificationId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBuyerAssociatedSuppliers = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/accountTier/buyer-associated-suppliers?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerSendOnboardingInviteToSupplier = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/buyers/onboarding-invites/send",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerSendRequestToFunder = async (funderId) => {
  try {
    const response = await request({
      method: "post",
      url: `/api/v1/buyers/associate-with-funder/${funderId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerSendRequestToSupplier = async (supplierId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/association-request/accept/${supplierId}`
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
}

export const buyerSendOnboardingInviteToFunder = async (body) => {
  try {
    const response = await request({
      method: "post",
      url: "/api/v1/buyers/onboarding-invites/send",
      data: body,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const getAllBuyerAssociatedFunders = async ({
  page,
  pageSize,
  search,
}) => {
  try {
    const response = await request({
      method: "get",
      url: `/api/v1/accountTier/buyer-associated-funders?page=${page}&size=${pageSize}&search=${search}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const disassociateBuyerSupplier = async (supplierId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/dissociate-supplier/${supplierId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const disassociateBuyerFunder = async (funderId) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/dissociate-funder/${funderId}`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};

export const buyerRejectSupplierRequest = async ({ notificationId }) => {
  try {
    const response = await request({
      method: "put",
      url: `/api/v1/buyers/association-request/reject/${notificationId}?note=Not trusted`,
    });
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message + "!");
  }
};
