export const industries = [
  "AGRICULTURE",
  "AUTOMOTIVE",
  "BANKING",
  "CONSTRUCTION",
  "EDUCATION",
  "ENERGY",
  "ENTERTAINMENT",
  "FINANCE",
  "HEALTHCARE",
  "HOSPITALITY",
  "INFORMATION_TECHNOLOGY",
  "INSURANCE",
  "MANUFACTURING",
  "MEDIA",
  "PHARMACEUTICAL",
  "REAL_ESTATE",
  "RETAIL",
  "TELECOMMUNICATIONS",
  "TRANSPORTATION",
  "UTILITIES",
];
