import { allMembers, getMemberId } from "appstate/roleManager/roleManagerSlice";
import {
  PAIconEditlight,
  PAIconTrashMini,
  PAIconViewMini,
} from "assets/images/svgs";
import Table from "layout/table/Table";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "shared/Card";
import Pagination from "shared/Pagination";

const RoleManagerTable = ({
  setEditActive,
  setToggleViewModal,
  toggleDeleteModalOn,
  page,
  pageSize,
  setPage,
}) => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);
  const { allMembersData } = useSelector((state) => state?.roleManager);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const handleDropdown = (row) => {
    if (dropdown === row.id) {
      setDropdown(null);
    } else {
      setDropdown(row.id);
    }
  };

  useEffect(() => {
    dispatch(allMembers({ page, pageSize }));
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    setData(allMembersData?.data?.data);
    const handleClickOutside = (event) => {
      if (
        dropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };

    const handleDocumentClick = (e) => {
      const screenHeight = window.innerHeight;
      const clickY = e.clientY;
      const bottomThreshold = 200;
      if (screenHeight - clickY <= bottomThreshold) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown, allMembersData]);

  const totalPages = allMembersData?.meta?.totalElements;

  const handleViewClick = (row) => {
    setEditActive(false);
    setToggleViewModal(true);
    dispatch(getMemberId(row?.id));
  };

  const handleEditClick = (row) => {
    setEditActive(true);
    setToggleViewModal(true);
    dispatch(getMemberId(row?.id));
  };

  const handleDeleteClick = (row) => {
    toggleDeleteModalOn();
    dispatch(getMemberId(row?.id));
  };

  const columns = [
    {
      name: <span className=" text-xs font-[500] text-[#7A7A7A]">NAME</span>,
      selector: (row) => row.fullName,
      sortable: true,
    },

    {
      name: <span className=" text-xs font-[500] text-[#7A7A7A]">EMAIL</span>,
      selector: (row) => row.email,
      sortable: true,
    },

    {
      name: <span className=" text-xs font-[500] text-[#7A7A7A]">PHONE</span>,
      selector: (row) => row.phoneNumber,
      sortable: true,
    },
    {
      name: <span className=" text-xs font-[500] text-[#7A7A7A]">ROLE</span>,
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: <span className=" text-xs font-[500] text-[#7A7A7A]">ACTION</span>,
      selector: (row) => row.id,
      cell: (row) => (
        <div>
          <img
            onClick={() => handleDropdown(row)}
            src="/option-btn.svg"
            alt=""
          />

          {dropdown === row.id && row && (
            <div className={`text-xs relative`}>
              <Card
                className={`w-[160px] right-[-10px] ${
                  atBottom && "bottom-[15px]"
                }  absolute z-10 drop-shadow-md rounded-md py-[12px]`}
              >
                <div
                  onClick={() => handleViewClick(row)}
                  className="flex items-center gap-x-[10px] mb-[12px]  pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                >
                  <span>
                    <PAIconViewMini />
                  </span>
                  <p className="font-xs font-normal">View User</p>
                </div>
                <div
                  onClick={() => handleEditClick(row)}
                  className="z-[99999] flex items-center gap-x-[10px] mb-[12px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                >
                  <span>
                    <PAIconEditlight />
                  </span>
                  <p className="font-xs font-normal">Edit User</p>
                </div>
                <div className="border border-silver"></div>
                <div
                  onClick={() => handleDeleteClick(row)}
                  className="flex items-center gap-x-[10px] mt-[10px] pl-[20px] py-[5px] hover:bg-[#d9d9d94a] hover:py-[5px]"
                >
                  <span>
                    <PAIconTrashMini />
                  </span>
                  <p className="text-[#DE1515]">Remove User</p>
                </div>
              </Card>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="pb-6">
        <Table columns={columns} data={data} pointer tableHeader />
        <div className="flex justify-center gap-x-[8px] mt-[20px]">
          {totalPages > 10 && (
            <Pagination
              page={page}
              startPage={startPage}
              setEndPage={setEndPage}
              endPage={endPage}
              setStartPage={setStartPage}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RoleManagerTable;
