import React from "react";

import DirectFundTable from "../components/DirectFundTable";

import { useNavigate } from "react-router-dom";
const DirectFunding = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full ">
      <div className="w-full  mt-2 bg-white ">
        <div className="bg-[#EBEBEB]">
          <div className=" py-4 grid md:grid-cols-[49%,49%] gap-5">
            <div className=" flex flex-col   gap-4 rounded-[10px] cursor-pointer text-white  bg-white ">
              <div className=" flex bg-[#07593D] rounded-t-[10px] px-6 py-[8px]  ">
                <div className="flex flex-row justify-between w-full">
                  <div className=" flex items-center justify-center">
                    <span class="text-white text-sm font-medium  uppercase">
                      Direct funding
                    </span>
                  </div>
                  <div
                    class="text-[#222222] text-sm font-medium  bg-stone-50 rounded p-2.5"
                    on
                    onClick={() => navigate("reinbursementdirectfundinvoice")}
                  >
                    Make Payment
                  </div>
                </div>
              </div>

              <div className="px-6  ">
                <div class=" flex-col justify-start items-start gap-2 inline-flex py-4 border-b-[1.5px] border-stone-300 w-full pb-7 ">
                  <div class="text-[#7A7A7A] text-base font-normal ">
                    Due Payment Amount
                  </div>
                  <div class="justify-start items-center gap-[3px] inline-flex">
                    <div class="justify-start items-center flex">
                      <div class="text-[#222222] text-4xl font-bold ">
                        1,340,090.00
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
              {/* AFETER */}
              <div className="flex cursor-pointer justify-between bg-white py-5  rounded-[10px] px-6 w-[82%]">
                <div class="flex-col justify-start items-start gap-2 inline-flex">
                  <div class="text-[#7A7A7A] text-xl font-normal ">
                    Total Invoices
                  </div>
                  <div class="justify-start items-center gap-[3px] inline-flex">
                    <div class="justify-start items-center flex">
                      <div>
                        <span className="text-[#222222] text-[32px] font-bold ">
                          32
                        </span>
                        <span className="text-neutral-800 text-xl font-bold ">
                          {" "}
                        </span>
                        <span className="text-[#7A7A7A] text-sm font-normal ">
                          Invoices
                        </span>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div className="flex  gap-9">
                  <div class=" h-[49px] flex-col justify-center  items-center inline-flex ml-7">
                    <div class="text-[black] text-lg font-medium ">-</div>
                  </div>
                  <div class="flex-col justify-start items-start gap-2 inline-flex">
                    <div class="text-[#7A7A7A] text-xl font-normal ">
                      Total Funders
                    </div>
                    <div class="justify-start items-center gap-[3px] inline-flex">
                      <div class="justify-start items-center flex">
                        <div>
                          <span className="text-[#222222] text-[32px] font-bold ">
                            12
                          </span>
                          <span className="text-neutral-800 text-xl font-bold ">
                            {" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>

            <div className="  flex flex-col   gap-4 rounded-[10px] cursor-pointer text-white  bg-white ">
              <div className=" flex bg-[#F08000CC] rounded-t-[10px] px-6 py-[8px]  ">
                <div className="flex flex-row justify-between w-full">
                  <div className=" flex items-center justify-center">
                    <span class="text-white text-sm font-medium  uppercase">
                      Open Market
                    </span>
                  </div>
                  <div
                    class="text-[#222222] text-sm font-medium  bg-stone-50 rounded p-2.5"
                    on
                    onClick={() =>
                      navigate(
                        "/reimbursement/detail/openmarketselectabledetail"
                      )
                    }
                  >
                    Make Payment
                  </div>
                </div>
              </div>
              {/* yellow part ends here */}
              <div className="px-6  ">
                <div class=" flex-col justify-start items-start gap-2 inline-flex py-4 border-b-[1.5px] border-stone-300 w-full pb-7 ">
                  <div class="text-[#7A7A7A] text-base font-normal ">
                    Due Payment Amount
                  </div>
                  <div class="justify-start items-center gap-[3px] inline-flex">
                    <div class="justify-start items-center flex">
                      <div class="text-[#222222] text-4xl font-bold ">
                        3,310,990.00
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
              {/* AFETER */}
              <div className="flex cursor-pointer justify-between bg-white py-5  rounded-[10px] px-6 w-[82%]">
                <div class="flex-col justify-start items-start gap-2 inline-flex">
                  <div class="text-[#7A7A7A] text-xl font-normal ">
                    Total Invoices
                  </div>
                  <div class="justify-start items-center gap-[3px] inline-flex">
                    <div class="justify-start items-center flex">
                      <div>
                        <span className="text-[#222222] text-[32px] font-bold ">
                          93
                        </span>
                        <span className="text-neutral-800 text-xl font-bold "></span>
                        <span className="text-[#7A7A7A] text-sm font-normal ">
                          Invoices
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex  gap-9">
                  <div class=" h-[49px] flex-col justify-center  items-center inline-flex ml-7">
                    <div class="text-[black] text-lg font-medium ">-</div>
                  </div>
                  <div class="flex-col justify-start items-start gap-2 inline-flex">
                    <div class="text-[#7A7A7A] text-xl font-normal ">
                      Total Funders
                    </div>
                    <div class="justify-start items-center gap-[3px] inline-flex">
                      <div class="justify-start items-center flex">
                        <div>
                          <span className="text-[#222222] text-[32px] font-bold ">
                            12
                          </span>
                          <span className="text-neutral-800 text-xl font-bold ">
                            {" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>

          <DirectFundTable />
        </div>
      </div>
    </div>
  );
};

export default DirectFunding;
