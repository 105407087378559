import { PAIconDownload } from "assets/images/svgs";
import { motion } from "framer-motion";
import React from "react";

const InvoiceReference = () => {
  return (
    <div>
      <motion.section
        initial={{
          x: 20,
        }}
        animate={{
          x: 0,
        }}
        exit={{
          x: -20,
        }}
        transition={{
          delay: 0.6,
        }}
        className="flex flex-col gap-8 px-6 pt-10 pb-24  bg-white rounded-[10px]"
      >
        <div className=" flex flex-col gap-3 ">
          <header>
            <h1 className=" text-[18px] font-bold">Invoice | INV-2002</h1>
          </header>
          <main className=" w-full flex justify-between">
            <div className="flex flex-col gap-3 w-1/2">
              <div className="flex justify-between">
                <span>Reference No:</span>
                <span>45678JHGF</span>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex justify-between">
                  <span className=" text-xs">Created Date:</span>
                  <span className="text-[#979797]  text-xs">23-Dec-2024</span>
                </div>
                <div className="flex justify-between">
                  <span className=" text-xs">Payment Due Date:</span>
                  <span className="text-[#979797]  text-xs">23-Dec-2024</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between">
              <motion.button
                // onClick={() => setLockModalOpen(true)}
                whileTap={{ scale: 0.9 }}
                className="flex border border-primaryColor text-sm justify-center gap-2 bg-white text-primaryColor rounded-[5px] items-center px-2.5 py-1.5 "
              >
                Lock Invoice
                {/* <PAIconLockYellow /> */}
              </motion.button>
              <span className="flex justify-end text-xs text-[#F08000]">
                Approved
              </span>
            </div>
          </main>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <span className=" font-bold text-sm">Payment</span>
            <span className="text-[#979797]  text-xs">60 Days</span>
          </div>
          <div className="flex flex-col">
            <span className=" font-bold text-sm">Currency</span>
            <span className="text-[#979797]  text-xs">NGN</span>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
            <h1 className="text-[18px] font-bold">Supplier/buyer details</h1>
          </header>
          <div className="flex  justify-between">
            <div className="flex gap-2">
              {/* <PAIconCocacola /> */}
              <div className="flex flex-col">
                <span className=" font-bold text-sm">Coca Cola</span>
                <span className="text-[#979797]  text-xs">Supplier</span>
              </div>
            </div>
            <div className="flex gap-2">
              {/* <PAIconModelOne /> */}
              <div className="flex flex-col">
                <span className=" font-bold text-sm">ABC Limited</span>
                <span className="text-[#979797]  text-xs">Buyer</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
            <h1 className="text-sm font-bold">Line Items</h1>
          </header>
          <div className=" w-full overflow-x-auto">
            <table className=" w-full overflow-x-auto">
              <thead className="bg-subtleGray w-">
                <tr className=" bg-unusualGray p-2.5 ">
                  <td className="text-[#11192A] p-2.5 text-xs font-bold">
                    Item Number
                  </td>
                  <td className=" p-2.5 text-xs font-bold">PO Number</td>
                  <td className=" p-2.5 text-xs font-bold">
                    Product/Service Number
                  </td>
                  <td className=" p-2.5 text-xs font-bold">
                    Product/Service Description
                  </td>
                  <td className=" p-2.5 text-xs font-bold">Quantity</td>
                  <td className=" p-2.5 text-xs font-bold">Unit Price</td>
                  <td className=" p-2.5 text-xs font-bold">
                    Line Item Extended Amount
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr className=" py-[3px] px-1.5">
                  <td className=" text-xs text-[#11192A] p-2.5">INV-2002</td>
                  <td className=" text-xs text-[#11192A] p-2.5">PO-2002</td>
                  <td className=" text-xs text-[#11192A] p-2.5">ITM3201</td>
                  <td className=" text-xs text-[#11192A] p-2.5">
                    Dangote Cement
                  </td>
                  <td className=" text-xs text-[#11192A] p-2.5">20</td>
                  <td className=" text-xs text-[#11192A] p-2.5">$30</td>
                  <td className=" text-xs text-[#11192A] p-2.5">$600</td>
                </tr>
                <tr className=" py-1.5 px-1.5">
                  <td className=" text-xs text-[#11192A] p-2.5">INV-2002</td>
                  <td className=" text-xs text-[#11192A] p-2.5">PO-2002</td>
                  <td className=" text-xs text-[#11192A] p-2.5">ITM3201</td>
                  <td className=" text-xs text-[#11192A] p-2.5">
                    Dangote Cement
                  </td>
                  <td className=" text-xs text-[#11192A] p-2.5">20</td>
                  <td className=" text-xs text-[#11192A] p-2.5">$30</td>
                  <td className=" text-xs text-[#11192A] p-2.5">$600</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        11:29
        <div className="flex flex-col gap-4">
          <header className="border-b-[5px] border-b-[#F6F5FA] pb-3">
            <h1 className="text-sm font-bold">Attachments</h1>
          </header>
          <div className="w-full overflow-x-auto">
            <table className="w-full overflow-x-auto">
              <thead className="  bg-subtleGray w-">
                <tr className="px-4  bg-unusualGray p-2.5 ">
                  <td className="text-[#11192A] px-4 py-2.5 text-xs font-bold">
                    Document Name
                  </td>
                  <td className=" px-4 py-2.5 text-xs font-bold">File Type</td>
                  <td className=" px-4 py-2.5 text-xs font-bold">
                    Upload Date / Time
                  </td>
                  <td className=" px-4 py-2.5 text-xs font-bold">Action</td>
                </tr>
              </thead>
              <tbody>
                <tr className=" py-[3px] px-1.5">
                  <td className=" text-xs text-[#0076EC] px-4 py-2.5">
                    DOC-29384.pdf
                  </td>
                  <td className=" text-xs text-[#11192A] px-4 py-2.5">PDF</td>
                  <td className=" text-xs text-[#11192A] px-4 py-2.5">
                    2021-09-14 13:25
                  </td>
                  <td className="cursor-pointer text-xs text-[#11192A] px-4 py-2.5">
                    <PAIconDownload />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </motion.section>
    </div>
  );
};

export default InvoiceReference;
