import React, { useState } from "react";
import RouteHeader from "../../RouteHeader";
import ToolsModal from "../ToolsModal";

const ROICalculator = ({ goBack }) => {
  const [rangeValue, setRangeValue] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [ROI, setROI] = useState(0);
  const [profitEarned, setProfitEarned] = useState(0);
  const [timePeriod, setTimePeriod] = useState("");
  const [fundedAmount, setFundedAmount] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRangeChange = (e) => {
    setRangeValue(e.target.value);
  };

  const handleCalculate = () => {
    if (!timePeriod || !rangeValue || !fundedAmount) {
      setIsModalOpen(true);
      return;
    }

    // CALCULATIONS HERE
    const calculatedReturnOnInvestment = 1.67;
    const calculatedProfitEarned = 7500;

    setROI(calculatedReturnOnInvestment);
    setProfitEarned(calculatedProfitEarned);

    setShowResults(true);
  };

  const handleReset = () => {
    setShowResults(false);
    setFundedAmount("");
    setTimePeriod("");
    setRangeValue(0);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='px-5'>
      <RouteHeader
        Route1='Tools'
        Heading='ROI Calculators'
        handleBack={goBack}
      />
      <div className='mt-10'>
        <div>
          <h1 className='text-black font-sans text-lg font-semibold leading-normal tracking-wide'>
            ROI Calculator
          </h1>
          <p className='text-gray font-sans text-base font-normal leading-normal mt-3'>
            This tool helps funders (investors or lenders) assess the potential
            return on investment for funding supply chain finance programs
          </p>
          <div className='border border-gray my-7'></div>

          <div className='w-full flex flex-wrap lg:flexrow'>
            {/* FIRST SECTION */}
            <section className='w-full md:w-1/2 flex flex-col md:flex-col gap-4 justify-between pr-5'>
              {/* FIRST INPUT */}
              <div className='mb-4 md:mb-0 w-full'>
                <label
                  htmlFor='totalFundedAmount'
                  className='block text-sm font-semibold text-gray-600 mb-2 mt-2'
                >
                  Total Funded Amount
                </label>
                <input
                  type='number'
                  id='totalFundedAmount'
                  name='totalFundedAmount'
                  value={fundedAmount}
                  onChange={(e) => setFundedAmount(e.target.value)}
                  className='w-full p-2 border border-[#FCD596] rounded-md focus:outline-none focus:border-green-500'
                  placeholder='100,000'
                />
              </div>
              {/* SECOND INPUT */}
              <div className='mb-4 md:mb-0 w-full'>
                <label
                  htmlFor='timePeriod'
                  className='block text-sm font-semibold text-gray-600 mb-2 mt-2'
                >
                  Time Period
                </label>
                <input
                  type='number'
                  id='timePeriod'
                  name='timePeriod'
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(e.target.value)}
                  className='w-full p-2 border border-[#FCD596] rounded-md focus:outline-none focus:border-green-500'
                  placeholder='40 days'
                />
              </div>
            </section>

            {/* SECOND SECTION */}
            <section className='w-full md:w-1/2'>
              <div className='mb-4 md:mb-0 flex items-center gap-4 justify-between'>
                <label
                  htmlFor='profitMargin'
                  className='block text-sm font-semibold text-gray-600 mb-2'
                >
                  Profit Margin
                </label>
                <input
                  type='text'
                  id='profitMargin'
                  name='profitMargin'
                  value={`${rangeValue}%`}
                  readOnly
                  className='w-[20%] p-2 border border-[#FCD596] rounded-md focus:outline-none focus:border-green-500'
                  placeholder='0%'
                />
              </div>
              {/* RANGE */}
              <div className='mt-1'>
                <input
                  type='range'
                  id='rangeValue'
                  name='rangeValue'
                  min='0'
                  max='100'
                  step='1'
                  value={rangeValue}
                  onChange={handleRangeChange}
                  className='w-full slider-thumb accent-[#2889E3]'
                />
                <div className='flex justify-between text-gray text-xs'>
                  <span>0%</span>
                  <span>100%</span>
                </div>
              </div>
            </section>

            {/* Calculate and Reset Buttons */}
            <div className='flex gap-4 mt-4'>
              <button
                onClick={handleCalculate}
                className='bg-[#FFBE55] rounded-sm p-[10px] text-white w-[182px] hover:bg-yellow-200'
              >
                Calculate
              </button>
              {showResults && (
                <button
                  onClick={handleReset}
                  className='border border-[#FFBE55] bg-white rounded-sm p-[10px] text-[#FFBE55] w-[182px] hover:bg-yellow-200'
                >
                  Reset
                </button>
              )}
            </div>

            {/* Results */}
            {showResults && (
              <div className='flex mt-10 w-full  justify-center text-black font-sans text-base font-medium'>
                <div className='mr-4'>
                  <p>Discount Percentage: {ROI}%</p>
                </div>
                <div className='mr-4'>
                  <span>
                    Discount Price:{" "}
                    <span className='text-[#07593D]'>NG {profitEarned}</span>{" "}
                  </span>
                </div>
              </div>
            )}

            {/* Modal */}
            {isModalOpen && (
              <ToolsModal
                message='Input fields are empty. Please fill in the required fields.'
                onClose={closeModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ROICalculator;
