import React, { useState } from "react";
import { PAIconTemplateEmail } from "assets/images/svgs";
import { PAIconMore } from "assets/images/svgs";
import DeleteConfirmationModal from "./DeleteModal";
import SuccessModal from "./SuccessModal";

const Saved = () => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const toggleDropdown = (itemId) => {
    setSelectedItemId(selectedItemId === itemId ? null : itemId);
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteConfirm = () => {
    closeDeleteModal();
    setSuccessModalOpen(true);
  };

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const cardItems = [
    {
      id: 1,
      title: "PayEdge - Default template",
      date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
    },
    {
      id: 2,
      title: "PayEdge - Default template",
      date: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 lg:grid-cols4 xl:grid-cols4 gap-4 mt-3">
      {cardItems.map((item) => (
        <div key={item.id} className="w-full cursor-pointer">
          <div className="max-w-xl mb-4 rounded-md shadow-md cursor-pointer relative">
            <div className="bg-[#07593D] py-10 flex items-center justify-center rounded-md">
              <img src="/payAssyst-04.svg" alt="CardImage" />
            </div>
            <div className="bg-white p-4 flex justify-between items-center mb-[-10px] mt-[-20px]">
              <div className="flex items-center">
                <PAIconTemplateEmail />
                <span className="font-semibold ml-2 text-sm">{item.title}</span>
              </div>
              <div className="flex items-center space-x-2 justify-end cursor-pointer">
                <PAIconMore onClick={() => toggleDropdown(item.id)} />
              </div>
              {selectedItemId === item.id && (
                <div className="absolute top-full right-5 mt-[-40px] bg-white rounded-md shadow-md">
                  {/* Dropdown Content */}
                  <ul className="p-3">
                    <li className="py-2 px-4 hover:shadow-md cursor-pointer text-black font-sans text-xs font-normal">
                      View Template
                    </li>
                    <li className="py-2 px-4 hover:shadow-md cursor-pointer text-black font-sans text-xs font-normal">
                      Edit Template
                    </li>
                    <li className="py-2 px-4 hover:shadow-md cursor-pointer text-black font-sans text-xs font-normal">
                      Set as Default
                    </li>
                    <div className="border-b border-gray mb10" />
                    <li
                      className="py-2 px-4 hover:shadow-md cursor-pointer text-[#DE1515] font-sans text-xs font-normal"
                      onClick={openDeleteModal}
                    >
                      Delete Template
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <p className="text-[#959595] font-sans text-xs font-normal px-4 mb-5 pb-5">
              Last Updated: {item.date.toDateString()}
            </p>
          </div>
        </div>
      ))}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDeleteConfirm}
      />
      <SuccessModal isOpen={isSuccessModalOpen} onClose={closeSuccessModal} />
    </div>
  );
};

export default Saved;
