// import Transactions from "features/transactions/recentTransaction";
import ViewAllTransactions from "features/wallet(new)/transactions/ViewAllTransaction";

const TransactionPage = () => {
  return (
    <>
      {/* <Transactions /> */}
      <ViewAllTransactions />
    </>
  );
};

export default TransactionPage;
