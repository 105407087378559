import { eachVirtualAccount } from "appstate/wallet/walletSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "shared/Spinner/Spinner";

const VIewAccountModal = ({ isOpen, onClose, accountId }) => {
  const dispatch = useDispatch();

  const { isLoading, virtualAccountDetails } = useSelector(
    (state) => state?.wallet
  );
  useEffect(() => {
    dispatch(eachVirtualAccount({ accountId: accountId }));
  }, [dispatch, accountId]);
  if (!isOpen) return null;
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center shadow-lg rounded-md z-[1000]">
      <div className="bg-white rounded-md max-w-xs md:max-w-lg w-full pb-5">
        <div className="flex justify-between items-center mb-4 bg-[#FAFAFA]">
          <p className="text-black font-sans text-base font-semibold p-3 px-5 rounded-sm italic">
            View Virtual Account
          </p>
          <button
            onClick={onClose}
            className="text-black font-sans text-xl cursor-pointer flex justify-center items-center"
          >
            &times; <span className="text-sm mx-2 mt-1">Close</span>
          </button>
        </div>
        {isLoading ? (
          <Spinner color />
        ) : (
          <div className="w-full px-7  items-center justify-center py-3">
            <h1 className="my-3">Name: {virtualAccountDetails?.accountName}</h1>
            <h1>Account number:  {virtualAccountDetails?.accountNumber}</h1>
            <h1 className="my-3">Balance: ₦{virtualAccountDetails?.balance}</h1>
            <h1>BVN: {virtualAccountDetails?.bvn}</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default VIewAccountModal;
