import { useState } from "react";
import { useNavigate } from "react-router-dom";

import OnboardingLayout from "../category/components/OnboardingLayout";
import { PAIconOnboardArrowLeftIcon } from "assets/images/svgs";

const TermsAndCondition = () => {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (checked) {
      let path = "/onboarding/supplier/get-started";
      navigate(path);
    }
  };
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  const goback = () => {
    let path = -1;
    navigate(path);
  };

  return (
    <OnboardingLayout>
      <div className=" px-[18px] lg:px-[42px]  mt-6">
        <PAIconOnboardArrowLeftIcon onClick={goback} />
      </div>{" "}
      <div className="px-[20px] h-[100%] pb-[30px]  pt-[40px] overflow-auto">
        <div className="mb-[30px] mt-[30px] flex justify-center items-center gap-x-[20px]">
          <div className="relative bottom-[15px]">
            <div className="w-[100px] h-[2px]   bg-[#F08000]  rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[28px] md:bottom-[30px]">
            <p className="font-normal text-xs text-textColor mb-[10px] md:text-sm">
              Terms & Conditions
            </p>
            <div className="w-[100px] h-[2px] bg-[#F08000]  rounded-[100px] md:w-[220px]"></div>
          </div>
          <div className="relative bottom-[15px]">
            <div className="w-[100px] h-[2px] bg-[#D9D9D9]  rounded-[100px] md:w-[220px]"></div>
          </div>
        </div>
        {/* <h1 className=" text-[24px] text-[#222222] text-center font-semibold">
          Review and agree
        </h1> */}
        <div class="text-[#222222] text-2xl font-medium text-center ">
          Review and agree
        </div>
        <div className=" w-full justify-center flex ">
          <p className=" text-base text-[#7A7A7A] mt-[15px]  font-normal">
            Please review and agree to the terms of agreement to register to the
            PayEdge Program
          </p>
        </div>

        <div className="md:flex justify-center">
          <div className="h-[100%] md:w-[722px]">
            <div className="border h-[650px] rounded-[5px] border-gray500 mt-[29px] pb-[50px]">
              <div className="h-[47px] flex items-center py-3 bg-[#f8f8f8] px-8 rounded-t-[5px]">
                <p className=" text-[#222222]  text-sm font-semibold ">
                  PayEdge Terms and Conditions
                </p>
              </div>
              <div className=" px-[15px] h-[100%] py-[20px] overflow-y-scroll md:px-[30px]  lg:py-[30px] lg:px-20">
                <div
                  class="
                text-[#424242] text-xs font-bold "
                >
                  Terms and Conditions ("Terms")
                  <br />
                  Our Terms and Conditions were last updated on [DATE].
                  <br />
                  Please read these terms and conditions carefully before using
                  Our Services on payEdge.
                  <br />
                  Interpretation and Definitions
                  <br />
                  Interpretation
                  <br />
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                  <br />
                  Definitions
                  <br />
                  For the purposes of these Terms and Conditions:
                  <br />● “Affiliate” means an entity that controls, is
                  controlled by or is under common control with a party, where
                  "control" means ownership of 50% or more of the shares, equity
                  interest or other securities entitled to vote for election of
                  directors or other managing authority.
                  <br />● “Account” means a unique account created for You to
                  access our Service or parts of our Service.
                  <br />● “Company” (referred to as either "the Company", "We",
                  "Us" or "Our" in this Agreement) refers to
                  [COMPANY_INFORMATION].
                  <br />● “Country” refers to [COMPANY_COUNTRY].
                  <br />● “Content” refers to content such as text, images, or
                  other information that can be posted, uploaded, linked to or
                  otherwise made available by You, regardless of the form of
                  that content.
                  <br />● “Device” means any device that can access the Service
                  such as a computer, a cellphone or a digital tablet.
                  <br />● “Feedback” means feedback, innovations or suggestions
                  sent by You regarding the attributes, performance or features
                  of our Service.
                  <br />● “Products” refer to the products or items offered for
                  sale on the Service.
                  <br />● “Orders” mean a request by You to purchase Products
                  from Us.
                  <br />● “Promotions” refer to contests, sweepstakes or other
                  promotions offered through the Service.
                  <br />● “Service” refers to the Website.
                  <br />● “Terms and Conditions” (also referred as "Terms") mean
                  these Terms and Conditions that form the entire agreement
                  between You and the Company regarding the use of the Service.
                  This Terms and Conditions Agreement was generated by TermsFeed
                  Terms and Conditions Generator.
                  <br />● “Third-party Social Media Service” means any services
                  or content (including data, information, products or services)
                  provided by a third-party that may be displayed, included or
                  made available by the Service.
                  <br />● “Website” refers to [WEBSITE_NAME], accessible from
                  [WEBSITE_URL]
                  <br />● “You” means the individual accessing or using the
                  Service, or the company, or other legal entity on behalf of
                  which such individual is accessing or using the Service, as
                  applicable.
                  <br />
                  Acknowledgment
                  <br />
                  These are the Terms and Conditions governing the use of this
                  Service and the agreement that operates between You and the
                  Company. These Terms and Conditions set out the rights and
                  obligations of all users regarding the use of the Service.
                  <br />
                  Your access to and use of the Service is conditioned on Your
                  acceptance of and compliance with these Terms and Conditions.
                  These Terms and Conditions apply to all visitors, users and
                  others who access or use the Service.
                  <br />
                  By accessing or using the Service You agree to be bound by
                  these Terms and Conditions. If You disagree with any part of
                  these Terms and Conditions then You may not access the
                  Service.
                  <br />
                  You represent that you are over the age of 18. The Company
                  does not permit those under 18 to use the Service.
                  <br />
                  Your access to and use of the Service is also conditioned on
                  Your acceptance of and compliance with the Privacy Policy of
                  the Company. Our Privacy Policy describes Our policies and
                  procedures on the collection, use and disclosure of Your
                  personal information when You use the Application or the
                  Website and tells You about Your privacy rights and how the
                  law protects You. Please read Our Privacy Policy carefully
                  before using Our Service.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[30px] md:flex flex-col items-center">
          <div>
            <label
              onClick={handleChange}
              className="font-medium text-sm text-textColor flex  cursor-pointer"
            >
              {/* <input type="checkbox" className="accent-[#F08000] mr-[10px]" /> */}
              <input
                type="checkbox"
                className="accent-[#F08000] mr-[10px] border border-gray  checked:border-none  "
                onClick={handleChange}
              />

              <p className="text-xs text-textColor text-normal">
                I agree to the PayEdge Terms and Conditions
              </p>
            </label>

            <button
              onClick={handleNavigate}
              disabled={!checked}
              auth
              className={`my-[30px]   md:w-[424px]  rounded-[5px] text-sm font-medium h-[45px] w-full text-[#222222] border-none  ${
                !checked
                  ? "bg-[#D8D8D8] text-[#7a7a7a]"
                  : "  bg-primaryColor text-white"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default TermsAndCondition;
