import { PAIconCancel } from "assets/images/svgs";
import React, { useState } from "react";

import Modal from "layout/modal/Modal";

import FundersDetail from "./details/moreinvoicebidscreens/FundersDetail";
import FundersEvaluation from "./details/moreinvoicebidscreens/FundersEvaluation";

function ViewMetricScoreModal({ isShown, onClose, getId, handleProceed }) {
  const [activeTab, setActiveTab] = useState("NumberOfBids");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "NumberOfBids":
        return <FundersEvaluation />;
      case "FundersInvited":
        return <FundersDetail />;

      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        isShown={isShown}
        hide={onClose}
        accountModal
        className={"mx-auto"}
      >
        <div className="rounded-md">
          {/* Static header */}
          <div className="sticky top-0 bg-[#FFFFFF] pt-[38px] px-[37px] pb-2">
            {" "}
            <header className="flex justify-between border-b-4 border-whisper200 pb-[16px] ">
              <p class="text-neutral-800 text-base font-bold font-['SF Pro Display']">
                Funder’s Metric Score
              </p>
              <div
                onClick={onClose}
                className="flex justify-between items-center gap-x-[10px] cursor-pointer"
              >
                <PAIconCancel className="w-[8px]" />
                <p className="text-sm font-medium text-gray800">Close</p>
              </div>
            </header>
            <div className="flex gap-6   flex-row">
              {/* first div */}
              <div className=" py-4">
                <p
                  className={`cursor-pointer  py-1 ${
                    activeTab === "NumberOfBids"
                      ? " border-[#F08000]   text-amber-600 text-xs font-bold border-b-2 "
                      : "text-gray500  text-neutral-400 text-xs font-normal"
                  }`}
                  onClick={() => handleTabClick("NumberOfBids")}
                >
                  Funders’s Evaluation
                </p>
              </div>

              <div className="py-4">
                <p
                  className={`cursor-pointer  py-1 ${
                    activeTab === "FundersInvited"
                      ? " border-[#F08000]   text-amber-600 text-xs font-bold border-b-2 "
                      : "text-gray500  text-neutral-400 text-xs font-normal"
                  }`}
                  onClick={() => handleTabClick("FundersInvited")}
                >
                  Funder’s details
                </p>
              </div>
            </div>
          </div>

          <div className="mt-3">{renderTabContent()}</div>

          <div
            className=" px-[37px] sticky bottom-0 pb-[38px] pt-4 w-full bg-[#FFFFFF] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]"
            onClick={handleProceed}
          ></div>
        </div>
      </Modal>
    </>
  );
}

export default ViewMetricScoreModal;
