import { PAIconArrowLeftGreen, PAIconBasilOutline } from "assets/images/svgs";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Account from "./management/account/account";
import Cards from "./management/cards/cards";
import RequestVirtualCard from "./virtualCard/virtualDebitCard/requestVirtualCard";
import NewVirtualAccount from "./virtualCard/createVirtualAccount/newVirtualAccount";
import TransferFund from "features/wallet/withdraw/transferFund/transferFund";
import FundAccount from "features/wallet/fund/fundAccount";
import TransferOption from "features/wallet/fund/fundAccount/transferOption/transferOption";
import TransferInfo from "features/wallet/fund/fundAccount/transferInfo/transferInfo";
import TransferInfoPin from "features/wallet/fund/fundAccount/pin/pin";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedAccounts,
  allExternalAccounts,
  allVirtualCards,
} from "appstate/wallet/walletSlice";
import ExternalSource from "features/wallet/fund/externalSource";
import FundWalletDetails from "features/wallet/fund/externalSource/fundWallet";
import WithdrawPin from "features/wallet/withdraw/pin";
import WithdrawOption from "features/wallet/withdraw/withdrawOption";

const AccountManagementDashboard = () => {
  const [isFundAccountOpen, setFundAccountOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [transferInfoValues, setTransferInfoValues] = useState(null);
  const [isTransferOptionOpen, setTransferOptionOpen] = useState(false);
  const [transferOptionInfo, setTransferOptionInfo] = useState(false);
  const [transferInfoPin, setTransferInfoPin] = useState(false);
  const [isTransferFund, setShowTransferFund] = useState(false);
  const [isFundWalletOpen, setFundWalletOpen] = useState(false);
  const [isFundWalletDetailsOpen, setFundWalletDetailsOpen] = useState(false);
  const [isWithdrawOpen, setWithdrawOpen] = useState(false);
  const [transferPin, setTransferPin] = useState(false);

  const {
    allExternalAccountsData,
    walletOverviewData,
    allVirtualCardsData,
    allVirtualAccountsData,
    transactions,
  } = useSelector((state) => state?.wallet);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(allExternalAccounts());
    dispatch(allVirtualCards());
  }, [dispatch]);

  const cardData = allVirtualCardsData?.data;

  const accountData = allVirtualAccountsData?.data;

  // Calculate the total number of cards
  const totalCards = cardData?.length;

  // Calculate the total number of accounts
  const totalExternalAccounts = allExternalAccountsData?.data?.length;
  const totalVirtualAccounts = accountData?.length;
  const totalAccounts = totalExternalAccounts + totalVirtualAccounts;

  // Calculate the total balance

  const totalVirtualAccountBalance = accountData?.reduce(
    (accumulator, account) => accumulator + (account?.balance || 0),
    0
  );

  const totalExternalAccountBalance = allExternalAccountsData?.data?.reduce(
    (accumulator, account) => accumulator + (account?.balance || 0),
    0
  );

  const totalAccountBalance =
    totalVirtualAccountBalance + totalExternalAccountBalance;

  // Calculate the total number of transactions
  let totalTransactions = transactions?.data?.length;

  // Count the number of cards for each currency type (NGN or USD)
  let ngnCards = 0;
  let usdCards = 0;
  cardData.forEach((card) => {
    if (card?.cardCurrency === "NGN") {
      ngnCards++;
    } else if (card?.cardCurrency === "USD") {
      usdCards++;
    }
  });

  // Calculate the total balance for all cards
  const totalBalance = cardData?.reduce(
    (accumulator, card) => accumulator + card?.balance,
    0
  );

  const { netExpenditure } = walletOverviewData || {};

  const toggleFund = (account) => {
    setSelectedAccount(account);
    setFundAccountOpen(!isFundAccountOpen);
  };

  // virtual account click
  const handleFundVirtualAccountClick = () => {
    setFundAccountOpen(false);
    setTransferOptionOpen(true);
  };

  const handleTransferOptionSubmit = (selectedAccounts) => {
    setTransferOptionOpen(false);
    setTransferOptionInfo(true);
    dispatch(setSelectedAccounts(selectedAccounts));
  };

  const handleTransferInfoSubmit = (values) => {
    setTransferOptionInfo(false);
    setTransferInfoPin(true);
    setTransferInfoValues(values);
  };

  const handleTransferInfoPinSubmit = () => {
    setTransferInfoPin(false);
  };

  const handleFundWalletSubmit = () => {
    setFundAccountOpen(false);
    setFundWalletOpen(true);
  };

  const handleExternalSourceSubmit = () => {
    setFundWalletOpen(false);
    setFundWalletDetailsOpen(true);
  };

  const handleFundWalletDetailsSubmit = () => {
    setFundWalletDetailsOpen(false);
  };

  // const handleTransferFundSubmit = () => {
  //   setShowTransferFund(false);
  //   // setTransferPin(true);
  // };

  const handleWithdrawOptionSubmit = (selectedAccounts) => {
    setWithdrawOpen(false);
    setShowTransferFund(true);
    dispatch(setSelectedAccounts(selectedAccounts));
  };

  const handleTransferFundSubmit = (values) => {
    setShowTransferFund(false);
    setTransferPin(true);
    setTransferInfoValues(values);
  };

  const handleTransferPinSubmit = () => {
    setTransferPin(false);
  };

  const toggleWithdraw = () => {
    setWithdrawOpen(!isWithdrawOpen);
  };

  const handleClose = () => {
    setWithdrawOpen(false);
    setFundAccountOpen(false);
    setShowTransferFund(false);
    setTransferPin(false);
    setFundWalletOpen(false);
    setFundWalletDetailsOpen(false);
    setTransferOptionOpen(false);
    setTransferOptionInfo(false);
    setTransferInfoPin(false);
  };

  return (
    <>
      <div className='w-full bg-white mt5 p-5'>
        <Link to='/wallet' className='flex gap-1.5 text-sm'>
          <PAIconArrowLeftGreen />
          <span className='flex items-center text-primaryColor'>Go Back</span>
        </Link>
        <div className='flex flex-col md:flex-row items-center justify-between'>
          <div>
            <p className='text-zinc-900 text[20px] md:text-xl font-semibold '>
              Account Managements
            </p>
          </div>
          <div className='flex gap-2 md:gap-5 mt-3 md:mt-0'>
            <button
              className='text-center text-white text-xs md:text-base w-auto h-auto px-4 md:px-6 py-1 md:py-2 rounded-[5px] justify-center items-center gap-1.5 inline-flex bg-[#07593D]'
              onClick={toggleFund}
            >
              Fund Account
            </button>
            <button
              className='text-center text-white text-xs md:text-base w-auto h-auto px-4 md:px-6 py-1 md:py-2 rounded-[5px] justifycenter items-center gap-1.5 inline-flex bg-[#07593D]'
              onClick={toggleWithdraw}
            >
              Withdraw
            </button>
          </div>
        </div>

        {/* ACCOUNTS */}
        <div className='lg:flex gap-2 mt-5'>
          {/* FIRST GRID */}
          <div className='p-3 w-full lg:w-[60%] bg-[#FAFAFAFB] rounded-md'>
            {/* HEADER */}
            <div className='flex itemscenter justify-between'>
              <div className='text-[#064731] text-sm font-bold'>
                <p>My Account</p>
                <div className='text-[10px] font-normal'>Account Summary</div>
              </div>
              <div className='cursor-pointer'>
                <PAIconBasilOutline />
              </div>
            </div>
            {/* CARDS */}
            <div className='grid grid-cols-3  mt-3 gap-2'>
              <div className='bg-white w[300px] py-[18px] px-[20px] rounded-[5.184px] shadow-md'>
                <p className='text-[10px] text-ebony'>
                  Accounts Available Balance
                </p>
                <h1 className='text-[24px] font-semibold py-[13px]'>
                  {totalAccountBalance ? totalAccountBalance : 0}
                </h1>
                <p className='text-[8px] text-gray800'>
                  <span className='text-ebony text-[ 7.714px] font-semibold'>
                    +0% Increase since January
                  </span>
                </p>
              </div>

              <div className='bg-white w[300px] py-[18px] px-[20px] rounded-[5.184px] shadow-md'>
                <p className='text-[10px] text-ebony'>
                  Cards Available Balance
                </p>
                <h1 className='text-[24px] font-semibold py-[13px]'>
                  {totalBalance ? totalBalance : 0}
                </h1>
                <p className='text-[8px] text-gray800'>
                  <span className='text-ebony text-[ 7.714px] font-semibold'>
                    +0% Increase since January
                  </span>
                </p>
              </div>

              <div className='bg-white w[300px] py-[18px] px-[20px] rounded-[5.184px] shadow-md'>
                <p className='text-[10px] text-ebony'>Net Expenditure</p>
                <h1 className='text-[24px] font-semibold py-[13px]'>
                  {netExpenditure ? netExpenditure : 0}
                </h1>
                <p className='text-[8px] text-gray800'>
                  <span className='text-ebony text-[ 7.714px] font-semibold'>
                    +0% Increase since January
                  </span>
                </p>
              </div>

              <div className='bg-white w[300px] py-[18px] px-[20px] rounded-[5.184px] shadow-md'>
                <p className='text-[10px] text-ebony'>Total Transactions</p>
                <h1 className='text-[24px] font-semibold py-[13px] text-[#F08000]'>
                  {totalTransactions}
                  <span className='text-[10px] text-gray800'>Processes</span>
                </h1>
                <p className='text-[8px] text-gray800'>
                  <span className='text-ebony text-[ 7.714px] font-semibold'>
                    +0% Increase since January
                  </span>
                </p>
              </div>

              <div className='bg-white w[300px] py-[18px] px-[20px] rounded-[5.184px] shadow-md'>
                <p className='text-[10px] text-ebony flex justify-center'>
                  Total Available Accounts
                </p>
                <h1 className='text-[24px] font-semibold py-[13px] text-green-500 flex justify-center'>
                  {totalAccounts}
                </h1>
                <div className='flex items-center justify-between'>
                  <p className='text-[10px]'>{totalVirtualAccounts} Virtual </p>
                  <p className='text-[10px]'>
                    {totalExternalAccounts} External
                  </p>
                </div>
              </div>

              <div className='bg-white w[300px] py-[18px] px-[20px] rounded-[5.184px] shadow-md'>
                <p className='text-[10px] text-ebony flex justify-center'>
                  Total Available Cards
                </p>
                <h1 className='text-[24px] font-semibold py-[13px] text-red flex justify-center'>
                  {totalCards ? totalCards : 0}
                </h1>
                <div className='flex items-center justify-between'>
                  <p className='text-[10px]'>{ngnCards} NGN </p>
                  <p className='text-[10px]'>{usdCards} USD</p>
                </div>
              </div>
            </div>
          </div>
          {/* SECOND GRID */}
          <div className='w-full lg:w-[40%] bg-[#FAFAFAFB] rounded-md p-3'>
            <RequestVirtualCard />
            <NewVirtualAccount />
          </div>
        </div>

        {/* MANAGEMENT */}
        <div className='mt-5'>
          <div className='text-zinc-900 text-base font-bold'>Management</div>
          {/* ACCOUNT MANAGEMENT */}
          <div className='text-zinc-900 text-[13px] font-medium'>Accounts</div>
          <Account />
          {/* CARDS MANAGEMENT */}
          <div className='text-zinc-900 text-[13px] font-medium mt-5'>
            Cards
          </div>
          <Cards />
        </div>
      </div>
      <FundAccount
        isOpen={isFundAccountOpen}
        onClose={() => handleClose(false)}
        onSubmit={handleFundWalletSubmit}
        virtualClicked={handleFundVirtualAccountClick}
      />
      <TransferOption
        isOpen={isTransferOptionOpen}
        onClose={() => handleClose(false)}
        onSubmit={(selectedAccounts) =>
          handleTransferOptionSubmit(selectedAccounts)
        }
      />
      <TransferInfo
        isOpen={transferOptionInfo}
        onClose={() => handleClose(false)}
        onSubmit={handleTransferInfoSubmit}
      />
      <TransferInfoPin
        isOpen={transferInfoPin}
        onClose={() => handleClose(false)}
        onSubmit={handleTransferInfoPinSubmit}
        transferInfoValues={transferInfoValues}
      />
      <TransferFund
        isOpen={isTransferFund}
        onClose={() => handleClose(false)}
        onSubmit={handleTransferFundSubmit}
      />
      <ExternalSource
        isOpen={isFundWalletOpen}
        onClose={() => handleClose(false)}
        onSubmit={handleExternalSourceSubmit}
        onSelectAccount={toggleFund}
        externalAccounts={allExternalAccountsData}
      />
      <FundWalletDetails
        isOpen={isFundWalletDetailsOpen}
        onClose={() => handleClose(false)}
        onSubmit={handleFundWalletDetailsSubmit}
        account={selectedAccount}
      />

      <WithdrawOption
        isOpen={isWithdrawOpen}
        onClose={() => handleClose(false)}
        onSubmit={(selectedAccounts) =>
          handleWithdrawOptionSubmit(selectedAccounts)
        }
      />
      <TransferFund
        isOpen={isTransferFund}
        onClose={() => handleClose(false)}
        onSubmit={handleTransferFundSubmit}
      />
      <WithdrawPin
        isOpen={transferPin}
        onClose={() => handleClose(false)}
        onSubmit={handleTransferPinSubmit}
        transferInfoValues={transferInfoValues}
      />
    </>
  );
};

export default AccountManagementDashboard;
